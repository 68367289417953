import { Button, Flex, Suspender, Text } from '@mm/company-ui'
import { HistoryIcon, LoadingIcon } from '@mm/company-ui-icons'
import React from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { PageInfo } from '../../../gen/graphql/documents'
import { TypedDocumentNode, useQuery } from '../../apollo'
import { useShowRequestForComments } from '../../decisions/UserMadeCommentContext'
import { CommentThread } from './CommentThread'
import { DecisionRequestForComment } from './DecisionRequestForComment'

export type CommentsListProps<T, V> = {
  query: TypedDocumentNode<T, V>
  variables: V
  extractPage: (data: T) =>
    | {
        edges: Array<{
          node: { id: string }
        }>
        pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      }
    | null
    | undefined
  className?: string

  order?: 'newest' | 'oldest'
  fixedAndScroll?: boolean
  fixedComposer?: boolean
  composer?: React.ReactNode
}

export const CommentsList = <T, V extends { after?: string | null }>({
  query,
  variables,
  extractPage,
  className,
  order = 'oldest',
  fixedAndScroll = true,
  fixedComposer,
  composer,
}: CommentsListProps<T, V>) => {
  const { showRequestForComments, decisionId } = useShowRequestForComments()
  const { data, loading, fetchMore, error } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
  })

  const page = data && extractPage(data)

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: page?.pageInfo.hasNextPage ?? false,
    disabled: !fixedAndScroll,
    onLoadMore: () => {
      void fetchMore({
        variables: {
          after: page?.pageInfo.endCursor,
        },
      })
    },
  })

  const handleLoadMore = () => {
    void fetchMore({
      variables: {
        after: page?.pageInfo.endCursor,
      },
    })
  }

  if (page == null) {
    if (loading) {
      return <Suspender />
    } else if (error) {
      throw error
    } else {
      return <>Access Denied</>
    }
  }

  const isEmpty = !loading && page?.edges.length === 0

  return (
    <Flex
      column
      reverse={order === 'oldest'}
      grow
      gap={1}
      sx={{
        height: fixedAndScroll ? '100%' : 'auto',
      }}
      className={className}
    >
      {fixedComposer && <div sx={{ px: 2 }}>{composer}</div>}
      <Flex
        column
        grow
        reverse={order === 'oldest'}
        sx={{
          overflow: fixedAndScroll ? 'auto' : undefined,
        }}
      >
        {showRequestForComments && <DecisionRequestForComment id={decisionId} sx={{ marginBottom: 1 }} />}

        {!fixedComposer && composer}
        {page?.edges.map(({ node }) => (
          <CommentThread key={node.id} id={node.id} cached />
        ))}
        {(loading || (page?.pageInfo.hasNextPage && fixedAndScroll)) && (
          <Flex justify="center" align="center" ref={sentryRef} sx={{ py: 2 }}>
            <LoadingIcon />
          </Flex>
        )}

        {!fixedAndScroll && page?.pageInfo.hasNextPage && (
          <Flex sx={{ paddingTop: 2, paddingLeft: 2 }}>
            <Button onClick={handleLoadMore} size="small" variant="muted" startIcon={<HistoryIcon />}>
              Load Older
            </Button>
          </Flex>
        )}
        {isEmpty && fixedAndScroll && (
          <Flex
            column
            alignSelf="center"
            justify="center"
            align="center"
            gap={1.5}
            sx={{ flex: 1, color: 'text-disabled-medium' }}
          >
            <Text variant="small">No comments here, yet</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

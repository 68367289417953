import React from 'react'
import { Flex } from '../Flex'
import { Text } from '../Text'

export type ModalLabelProps = {
  id?: string
  label: string
  icon?: React.ReactElement
}

export const ModalLabel = ({ id, icon, label }: ModalLabelProps) => {
  return (
    <Flex
      row
      align="center"
      gap={1.5}
      sx={{
        minWidth: 160,
        color: 'text-light',
        cursor: 'default',
      }}
      id={id}
    >
      {icon ? React.cloneElement(icon, { width: 2.5, height: 2.5 }) : null}
      <Text className="label">{label}</Text>
    </Flex>
  )
}

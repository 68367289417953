import { Avatar, formatUserDisplayName, Tag } from '@mm/company-ui'
import React from 'react'
import { ActionWaitingForTagSelectFragmentFragmentDoc } from '../../../gen/graphql/documents'
import { useFragment_experimental } from '../../apollo'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { editActionWaitingForCommand } from '../commands/editActionWaitingForCommand'

export type ActionWaitingForTagSelectProps = {
  actionId: string
}

export const ActionWaitingForTagSelect = ({ actionId }: ActionWaitingForTagSelectProps) => {
  const editActionWaitingFor = useCommand(editActionWaitingForCommand)

  const { data } = useFragment_experimental({
    fragment: ActionWaitingForTagSelectFragmentFragmentDoc,
    fragmentName: 'ActionWaitingForTagSelectFragment',
    from: {
      __typename: 'Action',
      id: actionId,
    },
  })

  if (!data) {
    // eslint-disable-next-line no-console
    console.log(`Fragment ActionWaitingForTagSelect returned no data, make sure a parent is querying for it`)
    return null
  }

  const handlePickerSelectUser = (value: string) => {
    void editActionWaitingFor.execute({ actionId, waitingForId: value })
  }

  return (
    <UserSelectWithCompanyMembers aria-label="Waiting For" value={data.waitingFor?.id} onAdd={handlePickerSelectUser}>
      {({ value, multiple: _, withName: __, ...restProps }) => {
        const [user] = value

        if (user) {
          return (
            <Tag {...restProps}>
              Waiting for
              <Avatar name={formatUserDisplayName(user.node)} />
            </Tag>
          )
        }
        return null
      }}
    </UserSelectWithCompanyMembers>
  )
}

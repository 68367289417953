import React, { useCallback, useMemo } from 'react'
import {
  CreateActionInput,
  CreateActionOnGoodThingDocument,
  FeedbackAuthorDocument,
  GoodThingActionsDocument,
} from '../../../gen/graphql/documents'
import { ActionsDashboardFlat, ActionsUpdatedTopic, createDataSource, markActionAsCreatedHere } from '../../actions'
import { capture } from '../../analytics'
import { useMutation, useQuery } from '../../apollo'
import { useEmitEventBusEvent } from '../../eventbus'
import { useFeatureFlags } from '../../featureFlags'
import { useMeetingContext } from '../../meetings'

export type GoodThingActionsProps = {
  id: string
  skipRegisteringCapabilities?: boolean
}

export const GoodThingActions = ({ id, skipRegisteringCapabilities = false }: GoodThingActionsProps) => {
  const { actionsOnFeedbackDefaultWaitingForIsAuthor } = useFeatureFlags()
  const emit = useEmitEventBusEvent(ActionsUpdatedTopic)
  const dataSource = useGoodThingActionsDataSource(id)

  const { data } = useQuery(FeedbackAuthorDocument, {
    variables: {
      id,
    },
  })

  const [addAction] = useMutation(CreateActionOnGoodThingDocument, {
    update: (cache, { data }) => {
      const action = data?.createActionOnGoodThing
      if (action?.__typename === 'Action') {
        capture('Action Created', {
          parent: 'GoodThing',
          parentMeetingId: meeting?.id,
        })
        markActionAsCreatedHere(cache, action)
        emit({ type: 'upserted', action })
      }
    },
  })

  const meeting = useMeetingContext()

  const handleAddAction = useCallback(
    async (actionPayload: CreateActionInput) => {
      await addAction({
        variables: {
          goodThingId: id,
          data: {
            ...actionPayload,
            parentMeetingId: actionPayload.parentMeetingId ?? meeting?.id,
          },
        },
      })
    },
    [id, addAction, meeting?.id],
  )

  return (
    <ActionsDashboardFlat
      dataSource={dataSource}
      onCreateAction={handleAddAction}
      newActionDefaults={{
        defaultWaitingFor:
          data?.prototypeFeedback != null && actionsOnFeedbackDefaultWaitingForIsAuthor && data.prototypeFeedback.author
            ? data.prototypeFeedback.author.id
            : undefined,
      }}
      skipRegisteringCapabilities={skipRegisteringCapabilities}
    />
  )
}

const useGoodThingActionsDataSource = (goodThingId: string) =>
  useMemo(
    () =>
      createDataSource({
        query: GoodThingActionsDocument,
        extractActions: (data) => data?.goodThing?.actions,
        matchAction: (action) => action.parent?.__typename === 'GoodThing' && action.parent.id === goodThingId,
        variables: {
          id: goodThingId,
        },
      }),
    [goodThingId],
  )

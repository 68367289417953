import { Flex, formatDateDisplay, Suspender, Tag, UserSelectTarget } from '@mm/company-ui'
import React from 'react'
import { GoalMetadataSummaryDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { GoalPrivacyTag } from './GoalPrivacyTag'
import { GoalStatusPicker } from './GoalStatusPicker'

export type GoalMetadataSummaryProps = {
  goalId: string
}

export const GoalMetadataSummary = ({ goalId }: GoalMetadataSummaryProps) => {
  const { data, loading, error } = useQuery(GoalMetadataSummaryDocument, {
    variables: {
      id: goalId,
    },
  })

  const goal = data?.goal

  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Goal not found')
  }

  return (
    <Flex row gap={2} align="center">
      <GoalStatusPicker cached goalId={goalId} />

      {goal.assignee ? (
        <Tag>
          Assignee
          <UserSelectTarget value={{ node: goal.assignee }} disabled />
        </Tag>
      ) : null}

      <Tag>
        Due
        {formatDateDisplay(goal.dueAt)}
      </Tag>

      <GoalPrivacyTag goalId={goalId} />
    </Flex>
  )
}

import { GenericTopic } from '@mm/graphql-helpers/gen/graphql/graphql'
import {
  DecisionRowDataFragment,
  MeetingGenericTopicsDataFragment,
  MeetingPreReadSectionsFragment,
  MeetingTopicsDataFragment,
  TopicRowDataFragment,
} from '../../../gen/graphql/documents'

type navType = Lowercase<NonNullable<GenericTopic['__typename']>>

interface GetGenericNavProps {
  sectionId: string
  sections:
    | MeetingGenericTopicsDataFragment['sections']
    | MeetingTopicsDataFragment['sections']
    | MeetingPreReadSectionsFragment['sections']
  topicId: string
  displayNext: (type: navType, id: string) => void
  displayPrevious: (type: navType, id: string) => void
  filterGenericTopics?: (value: TopicRowDataFragment | DecisionRowDataFragment) => boolean
}

export const getGenericTopicDrawerNav = ({
  sectionId,
  sections,
  topicId,
  displayNext,
  displayPrevious,
  filterGenericTopics,
}: GetGenericNavProps) => {
  const section = [...sections].find((section): section is typeof section & { __typename: 'MeetingTopicSection' } => {
    return section.id === sectionId
  })
  if (section == null) return null
  if (sectionId && 'genericTopics' in section) {
    const genericTopics = section.genericTopics.filter(filterGenericTopics ? filterGenericTopics : () => true)
    const itemIndex = genericTopics.findIndex(({ id }) => id === topicId)
    const nextItem = genericTopics[itemIndex + 1]
    const previousItem = genericTopics[itemIndex - 1]
    const nextItemType = nextItem?.__typename === 'Topic' ? 'topic' : 'decision'
    const previousItemType = previousItem?.__typename === 'Topic' ? 'topic' : 'decision'
    return {
      itemIndex,
      totalItemsCount: genericTopics.length,
      hasMoreItems: false,
      displayNext:
        nextItem &&
        (() => {
          displayNext(nextItemType, nextItem.id)
        }),
      displayPrevious:
        previousItem &&
        (() => {
          displayPrevious(previousItemType, previousItem.id)
        }),
    }
  }
  return null
}

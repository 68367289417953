import { Suspender } from '@mm/company-ui'
import { LightbulbIdeaFeedbackIcon, LinkIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import {
  ActionMetadataPrototypeFeedbackDocument,
  RemoveActionFeedbackContextDocument,
} from '../../../gen/graphql/documents'
import { useQuery, useMutation } from '../../apollo'
import { PanelMetadataItemChips } from '../../components'
import { PanelMetadataLinkChips } from '../../components/PanelMetadataLinkChips'

export const ActionMetadataFeedback = ({ actionId, isLink }: { actionId: string; isLink?: boolean }) => {
  const { data, loading, error, fetchMore } = useQuery(ActionMetadataPrototypeFeedbackDocument, {
    variables: {
      id: actionId,
    },
  })

  const [removeActionContext] = useMutation(RemoveActionFeedbackContextDocument)

  const feedback = data?.action?.prototypeFeedback

  const handleRemove = useCallback(
    async (id: string) => {
      await removeActionContext({
        variables: {
          id: actionId,
          contextRef: id,
        },
        optimisticResponse: {
          removeActionContext: {
            id: actionId,
            __typename: 'Action',
            prototypeFeedback: {
              __typename: 'PrototypeFeedbackConnection',
              edges: feedback?.edges.filter(({ node }) => node.id !== id) || [],
              pageInfo: {
                hasNextPage: feedback?.pageInfo.hasNextPage ?? false,
                endCursor: feedback?.pageInfo.endCursor ?? '',
                __typename: feedback?.pageInfo.__typename,
              },
            },
          },
        },
      })
    },
    [actionId, feedback, removeActionContext],
  )

  if (error) {
    throw error
  }

  if (loading) {
    return <Suspender />
  }

  if (isLink) {
    return feedback?.edges.length ? (
      <PanelMetadataLinkChips
        icon={<LightbulbIdeaFeedbackIcon color="text-light" />}
        chips={feedback.edges.map(({ node }) => {
          const label = node.to ? (node.to.__typename === 'User' ? node.to.firstName : node.to.title) : ''

          return {
            id: node.id,
            label: `Feedback to ${label}`,
            href: { pathname: '/feedback/[id]', query: { id: node.id } },
            type: 'Feedback',
          }
        })}
        hasNextPage={feedback.pageInfo.hasNextPage}
        onLoadMore={() => fetchMore({ variables: { after: feedback.pageInfo.endCursor } })}
        onRemove={handleRemove}
      />
    ) : null
  }

  return feedback?.edges.length ? (
    <PanelMetadataItemChips
      title="Feedback"
      icon={<LinkIcon />}
      chips={feedback.edges.map(({ node }) => {
        const label = node.to ? (node.to.__typename === 'User' ? node.to.firstName : node.to.title) : ''

        return {
          id: node.id,
          label: `Feedback to ${label}`,
          href: { pathname: '/feedback/[id]', query: { id: node.id } },
        }
      })}
      hasNextPage={feedback.pageInfo.hasNextPage}
      onLoadMore={() => fetchMore({ variables: { after: feedback.pageInfo.endCursor } })}
    />
  ) : null
}

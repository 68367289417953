import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CrossSquareFilledIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="cross-square-filled" viewBox="0 0 20 20" {...props}>
    <rect width={20} height={20} rx={4} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.615 8.17062C12.832 7.95366 12.832 7.60191 12.615 7.38495C12.398 7.16799 12.0463 7.16799 11.8293 7.38495L9.99995 9.21433L8.17056 7.38495C7.9536 7.16799 7.60184 7.16799 7.38489 7.38495C7.16793 7.60191 7.16793 7.95366 7.38489 8.17062L9.21427 10L7.38489 11.8294C7.16793 12.0463 7.16793 12.3981 7.38489 12.6151C7.60184 12.832 7.9536 12.832 8.17056 12.6151L9.99995 10.7857L11.8293 12.6151C12.0463 12.832 12.398 12.832 12.615 12.6151C12.832 12.3981 12.832 12.0463 12.615 11.8294L10.7856 10L12.615 8.17062Z"
      fill="white"
    />
  </IconBase>
)

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const AddIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="add" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1.8H16C17.215 1.8 18.2 2.78497 18.2 4V16C18.2 17.215 17.215 18.2 16 18.2H4C2.78497 18.2 1.8 17.215 1.8 16V4C1.8 2.78497 2.78497 1.8 4 1.8ZM0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4ZM10 5.00909C10.4971 5.00909 10.9 5.41203 10.9 5.90909V9.1H14.091C14.5881 9.1 14.991 9.50294 14.991 10C14.991 10.4971 14.5881 10.9 14.091 10.9H10.9V14.0909C10.9 14.588 10.4971 14.9909 10 14.9909C9.50295 14.9909 9.10001 14.588 9.10001 14.0909V10.9H5.90919C5.41213 10.9 5.00919 10.4971 5.00919 10C5.00919 9.50294 5.41213 9.1 5.90919 9.1H9.10001V5.90909C9.10001 5.41203 9.50295 5.00909 10 5.00909Z"
    />
  </IconBase>
)

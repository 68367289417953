import { BaseLink, Flex, Suspender } from '@mm/company-ui'
import React from 'react'
import { DecisionTitleSectionDocument } from '../../../gen/graphql/documents'
import { CreatedContext } from '../../actions/components/CreatedContext'
import { useQuery } from '../../apollo'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'
import { DecisionTitle } from './DecisionTitle'

export type DecisionTitleSectionProps = {
  decisionId: string
}

export const DecisionTitleSection = React.forwardRef<HTMLDivElement, DecisionTitleSectionProps>(
  ({ decisionId }, ref) => {
    const { data, loading, error } = useQuery(DecisionTitleSectionDocument, {
      variables: {
        id: decisionId,
      },
    })

    const decision = data?.decision
    const { commentsLabel } = useTotalComments(decision)
    const { actionsLabel } = useTotalActions(decision)

    if (decision == null) {
      if (loading) {
        return <Suspender />
      }
      throw error ?? new Error('Decision not found')
    }

    return (
      <Flex column ref={ref} sx={{ padding: 1 }}>
        <CreatedContext createdAt={decision.createdAt} parent={decision.parent ?? undefined} />
        <DecisionTitle id={decisionId} sx={{ fontSize: 2 }} />

        <Flex gap={2}>
          <BaseLink href="#comments" sx={{ fontSize: 0, color: 'text-light' }}>
            {commentsLabel}
          </BaseLink>
          <BaseLink href="#actions" sx={{ fontSize: 0, color: 'text-light' }}>
            {actionsLabel}
          </BaseLink>
        </Flex>
      </Flex>
    )
  },
)

DecisionTitleSection.displayName = 'DecisionTitleSection'

import { keyframes } from '@emotion/react'
import { useFeatureFlags, FastTable } from '@mm/company-ui'
import React, { useState } from 'react'
import { ActionRowAnimateInDecoratorDocument } from '../../../../gen/graphql/documents'
import { useQuery } from '../../../apollo'

export const ActionRowAnimateInDecorator: React.FC<{
  id: string
  children: React.ReactElement
  noBorders: boolean
}> = ({ id, children, noBorders }) => {
  const { animateActionsAppearance } = useFeatureFlags()
  const { data } = useQuery(ActionRowAnimateInDecoratorDocument, {
    variables: { id },
    skip: !animateActionsAppearance,
    fetchPolicy: 'cache-only',
  })
  const [now] = useState(() => Date.now())

  const action = data?.action
  if (!animateActionsAppearance || !action?._createdHereAt || action._createdHereAt < now - 1000) {
    return children
  }

  return (
    <div
      sx={{
        ...(noBorders ? {} : FastTable.baseRowBorderStyle),
        animation: `${animateBG} 1s ease-in`,
      }}
    >
      {children}
    </div>
  )
}

const animateBG = keyframes({
  from: { backgroundColor: '#ECECF8' },
  to: { backgroundColor: 'transparent' },
})

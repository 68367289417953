import { ActionStatus, closedActionStatuses, openActionStatuses } from '@mm/common/src'
import { Flex, Suspender, SuspenseLoader, Text, UserSelect, UserSelectUser, formatDateDisplay } from '@mm/company-ui'
import { ActionsIcon, UserProfileIcon } from '@mm/company-ui-icons/src'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { MeetingParticipantsDocument, ActionOrderBy } from '../../../../gen/graphql/documents'
import { useQuery } from '../../../apollo'
import { ActionSectionDisplayOptions, InitialActionSectionState } from '../../../displayMenu/DisplayConfigs'
import { DisplayFilter, Filter, FilterType } from '../../../displayMenu/DisplayFilter'
import { DisplayFilterProvider, isActionStatusArray, useDisplayFilter } from '../../../displayMenu/DisplayFilterContext'
import { DisplayMenu } from '../../../displayMenu/DisplayMenu'
import {
  DisplaySettingsProvider,
  useDisplaySettings,
  useSortOrderApiField,
} from '../../../displayMenu/DisplayMenuContext'
import { StatusFilter } from '../../../displayMenu/StatusFilter'
import { useMeetingContext } from '../../../meetings'
import { ActionsDashboardDataSource } from '../../types'
import { BaseActionsDashboard, defaultRenderEmptyPlaceholder } from './BaseActionsDashboard'
import { useFetchActionsDashboardData } from './DataSource'
import { ActionsDashboardData, ActionsDashboardProps } from './types'

// TODO merge into ActionDashboardWithFilterAndDisplay after prototype test

export const ActionSectionWithFilterAndDisplay = ({
  dataSource,
  pageKey,
  ...rest
}: ActionsDashboardProps<{
  assigneeId?: string
  pageKey: string
  dataSource: ActionsDashboardDataSource
  renderEmptyPlaceholder?: () => React.ReactElement
}>) => {
  const meeting = useMeetingContext()
  const { data: meetingData } = useQuery(MeetingParticipantsDocument, {
    variables: {
      id: meeting?.id || '',
    },
    fetchPolicy: 'cache-only',
    skip: !meeting,
  })
  const participants = meetingData?.meeting?.participants?.edges ?? []
  const initialFilters = {
    assignee: [],
    status: ['ACTIVE'],
  }
  const renderStatusSelect: Filter['inputRenderFn'] = (value, onChange) => {
    if (value !== undefined && !isActionStatusArray(value)) return <></>
    return (
      <StatusFilter
        sx={{ px: 1 }}
        value={value || []}
        onChange={(values) => {
          onChange?.(values)
        }}
      />
    )
  }

  const renderUserSelect: Filter['inputRenderFn'] = (value, onChange) => {
    return <UserSelect options={participants} value={value} onChange={onChange} multiple placeHolderAvatarSize={1} />
  }
  const availableFilters: Filter[] = [
    {
      label: 'Assignee',
      icon: <UserProfileIcon />,
      filterType: FilterType.UserPicker,
      fieldName: 'assignee',
      inputRenderFn: renderUserSelect,
      unRemovable: true,
    },

    {
      label: 'Status',
      icon: <ActionsIcon />,
      filterType: FilterType.Status,
      fieldName: 'status',
      inputRenderFn: renderStatusSelect,
      unRemovable: true,
    },
  ]
  return (
    <DisplayFilterProvider pageKey={pageKey} initialFilters={initialFilters}>
      <DisplaySettingsProvider
        pageKey={pageKey}
        config={ActionSectionDisplayOptions}
        initialState={InitialActionSectionState}
      >
        <Flex row justify="space-between" sx={{ paddingBottom: 1 }}>
          <DisplayFilter availableFilters={availableFilters} sx={{ height: 4 }} />
          <DisplayMenu sx={{ height: 4 }} />
        </Flex>
        <SuspenseLoader>
          <div
            sx={{
              border: '1px solid',
              borderColor: 'border',
              borderRadius: 'medium',
            }}
          >
            <MeetingSectionActionDashboardWithFilter
              dataSource={dataSource}
              participants={participants}
              {...rest}
              renderEmptyPlaceholder={() => (
                <Flex align="center" justify="center">
                  <Text color="text-light" sx={{ justifyContent: 'center', textAlign: 'center' }}>
                    No actions
                  </Text>
                </Flex>
              )}
            />
          </div>
        </SuspenseLoader>
      </DisplaySettingsProvider>
    </DisplayFilterProvider>
  )
}

const MeetingSectionActionDashboardWithFilter = ({
  dataSource,
  renderEmptyPlaceholder = defaultRenderEmptyPlaceholder,
  newActionDefaults,
  ...rest
}: ActionsDashboardProps<{
  participants: UserSelectUser[]
  dataSource: ActionsDashboardDataSource
  renderEmptyPlaceholder?: () => React.ReactElement
}>) => {
  const {
    state: { grouping: displayGrouping, sortingField: displaySorting, sortingOrder: displaySortingOrder },
  } = useDisplaySettings<typeof ActionSectionDisplayOptions>()
  const { filter } = useDisplayFilter()
  const assigneeFilter = typeof filter['assignee'] === 'string' ? [filter['assignee']] : filter['assignee']
  const assignees = assigneeFilter?.length ? assigneeFilter : undefined

  const sortOrderBy = useSortOrderApiField()
  const {
    data: actions,
    hasMore,
    fetchMore,
    loading,
  } = useFetchActionsDashboardData(dataSource, {
    pageSize: 20,
    overrideFilters: { status: filter['status'] as ActionStatus[], assignees },
    orderBy: sortOrderBy as ActionOrderBy,
    skip: displayGrouping === 'Status',
  })

  // TODO simplify this type check
  const openFilteredStatuses: ActionStatus[] =
    filter['status'] != null && isActionStatusArray(filter['status'])
      ? filter['status']?.filter((status) => openActionStatuses.includes(status))
      : []
  const closedFilteredStatuses =
    filter['status'] != null && isActionStatusArray(filter['status'])
      ? filter['status']?.filter((status) => closedActionStatuses.includes(status))
      : []

  const skipTodoActionQuery = openFilteredStatuses.length === 0 && !!filter['status']?.length
  const useDefaultOpenStatus = !skipTodoActionQuery && openFilteredStatuses.length == 0
  const {
    data: toDoActions,
    hasMore: hasMoreToDoActions,
    fetchMore: fetchMoreToDoActions,
    loading: loadingToDoActions,
  } = useFetchActionsDashboardData(dataSource, {
    pageSize: 10,
    overrideFilters: {
      status: useDefaultOpenStatus ? openActionStatuses : openFilteredStatuses,
      assignees,
    },
    orderBy: sortOrderBy as ActionOrderBy,
    skip: skipTodoActionQuery,
  })

  const skipDoneActionQuery = closedFilteredStatuses.length == 0 && !!filter['status']?.length
  const useDefaultClosedStatus = !skipDoneActionQuery && closedFilteredStatuses.length == 0

  const {
    data: doneActions,
    hasMore: hasMoreDoneActions,
    fetchMore: fetchMoreDoneActions,
    loading: loadingDoneActions,
  } = useFetchActionsDashboardData(dataSource, {
    pageSize: 10,
    overrideFilters: {
      status: useDefaultClosedStatus ? closedActionStatuses : closedFilteredStatuses,
      assignees,
    },
    orderBy: sortOrderBy as ActionOrderBy,
    skip: skipDoneActionQuery,
  })
  const [thisWeek] = useState(() => DateTime.now().startOf('week'))
  const data = useMemo<ActionsDashboardData>(() => {
    const nextWeek = thisWeek.plus({ weeks: 1 })
    if (displayGrouping === 'None') {
      return [
        {
          id: 'actions',
          title: 'Actions',
          hideTitle: true,
          rows:
            _(actions)
              .orderBy((action) => {
                const fieldName = displaySorting?.value || 'dueAt'
                return action[fieldName]
              }, displaySortingOrder?.value)
              .value() ?? [],
          renderEmptyPlaceholder,
          hasMore,
          fetchMore,
          loadingMore: loading,
          newActionDefaults,
        },
        {
          id: 'new action',
          title: 'New Action',
          hideTitle: true,
          rows: [],
          hasAddMore: true,
          newActionDefaults,
          nonCollapsible: true,
        },
      ]
    }

    if (displayGrouping === 'Status') {
      return [
        {
          id: 'todo actions',
          title: 'TO DO',
          rows:
            _(toDoActions)
              .orderBy((action) => {
                const fieldName = displaySorting?.value || 'dueAt'
                return action[fieldName]
              }, displaySortingOrder?.value)
              .value() ?? [],
          hasMore: hasMoreToDoActions,
          fetchMore: fetchMoreToDoActions,
          loadingMore: loadingToDoActions,
        },
        {
          id: 'done actions',
          title: 'DONE',
          rows:
            _(doneActions)
              .orderBy((action) => {
                const fieldName = displaySorting?.value || 'dueAt'
                return action[fieldName]
              }, displaySortingOrder?.value)
              .value() ?? [],
          hasMore: hasMoreDoneActions,
          fetchMore: fetchMoreDoneActions,
          loadingMore: loadingDoneActions,
        },
        {
          id: 'new action',
          title: 'New Action',
          hideTitle: true,
          rows: [],
          hasAddMore: true,
          newActionDefaults,
          nonCollapsible: true,
        },
      ]
    }

    const actionGroups = _(actions)
      .orderBy((action) => {
        const fieldName = displaySorting?.value || 'dueAt'
        return action[fieldName]
      }, displaySortingOrder?.value)
      .groupBy((action) => {
        const fieldName = displaySorting?.value || 'dueAt'
        const fieldValue = action[fieldName]
        const week = DateTime.fromMillis(fieldValue || action.dueAt || action.createdAt).startOf('week')
        return week.toISODate()
      })
      .map((rows, weekISO) => {
        const week = DateTime.fromISO(weekISO)

        return {
          id: weekISO,
          title: week.hasSame(thisWeek, 'week')
            ? `${formatDateDisplay(week)} \u2013 ${formatDateDisplay(week.endOf('week'))} (This week)`
            : week.hasSame(nextWeek, 'week')
            ? `${formatDateDisplay(week)} \u2013 ${formatDateDisplay(week.endOf('week'))} (Next week)`
            : `${formatDateDisplay(week)} \u2013 ${formatDateDisplay(week.endOf('week'))}`,
          rows,
        }
      })
      .value()
    return [
      ...actionGroups.map((group, index) => {
        if (index === actionGroups.length - 1) return { ...group, hasMore, fetchMore, loadingMore: loading }
        return group
      }),
      {
        id: 'new action',
        title: 'New Action',
        hideTitle: true,
        rows: [],
        hasAddMore: true,
        newActionDefaults,
        nonCollapsible: true,
      },
    ]
  }, [
    thisWeek,
    displayGrouping,
    actions,
    displaySortingOrder?.value,
    renderEmptyPlaceholder,
    hasMore,
    fetchMore,
    loading,
    newActionDefaults,
    toDoActions,
    hasMoreToDoActions,
    fetchMoreToDoActions,
    loadingToDoActions,
    doneActions,
    hasMoreDoneActions,
    fetchMoreDoneActions,
    loadingDoneActions,
    displaySorting?.value,
  ])

  if (actions == null && (loading || loadingToDoActions || loadingDoneActions)) {
    return <Suspender />
  }

  return <BaseActionsDashboard {...rest} data={data} newActionDefaults={newActionDefaults} />
}

import { LoadingIcon } from '@mm/company-ui-icons'
import React, { Suspense } from 'react'
import { Flex } from '../Flex'

export const SuspenseLoader = ({ children }: { children: React.ReactChild }) => (
  <Suspense
    fallback={
      <Flex align="center" justify="center" sx={{ height: '100%', minHeight: 60 }}>
        <LoadingIcon width={20} height={20} color="text-disabled" />
      </Flex>
    }
  >
    {children}
  </Suspense>
)

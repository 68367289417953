import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const LightbulbIdeaFeedbackIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="lightbulb-idea-feedback" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0132 0C8.38876 0 8.6932 0.304446 8.6932 0.68V2.01333C8.6932 2.38889 8.38876 2.69333 8.0132 2.69333C7.63765 2.69333 7.3332 2.38889 7.3332 2.01333V0.68C7.3332 0.304446 7.63765 0 8.0132 0ZM3.30888 2.34717C3.04332 2.08161 2.61277 2.08161 2.34721 2.34717C2.08166 2.61272 2.08166 3.04327 2.34721 3.30883L3.28988 4.2515C3.55544 4.51705 3.98599 4.51705 4.25155 4.2515C4.5171 3.98594 4.5171 3.55539 4.25155 3.28983L3.30888 2.34717ZM0.68 7.33334C0.304446 7.33334 0 7.63778 0 8.01334C0 8.38889 0.304446 8.69334 0.68 8.69334H2.01333C2.38889 8.69334 2.69333 8.38889 2.69333 8.01334C2.69333 7.63778 2.38889 7.33334 2.01333 7.33334H0.68ZM13.6797 3.30883C13.9452 3.04327 13.9452 2.61272 13.6797 2.34717C13.4141 2.08161 12.9836 2.08161 12.718 2.34717L11.7753 3.28983C11.5098 3.55539 11.5098 3.98594 11.7753 4.2515C12.0409 4.51705 12.4714 4.51705 12.737 4.2515L13.6797 3.30883ZM13.3332 8.01334C13.3332 7.63778 13.6376 7.33334 14.0132 7.33334H15.3465C15.7221 7.33334 16.0265 7.63778 16.0265 8.01334C16.0265 8.38889 15.7221 8.69334 15.3465 8.69334H14.0132C13.6376 8.69334 13.3332 8.38889 13.3332 8.01334ZM8.0132 3.33334C5.42832 3.33334 3.3332 5.42845 3.3332 8.01334C3.3332 9.87819 4.4251 11.4825 5.99987 12.234V14.68C5.99987 15.0556 6.30432 15.36 6.67987 15.36H9.34654C9.72209 15.36 10.0265 15.0556 10.0265 14.68V12.234C11.6013 11.4825 12.6932 9.87819 12.6932 8.01334C12.6932 5.42845 10.5981 3.33334 8.0132 3.33334ZM4.6932 8.01334C4.6932 6.17956 6.17942 4.69334 8.0132 4.69334C9.84698 4.69334 11.3332 6.17956 11.3332 8.01334C11.3332 9.45572 10.4105 10.6829 9.11973 11.1396C8.84811 11.2357 8.66654 11.4926 8.66654 11.7807V14H7.35987V11.7807C7.35987 11.4926 7.1783 11.2357 6.90668 11.1396C5.61592 10.6829 4.6932 9.45572 4.6932 8.01334Z"
    />
  </IconBase>
)

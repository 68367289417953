import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const EyeViewOpen = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="eye-view-open" viewBox="0 0 20 20" {...props}>
      <path d="M2.6501 10C2.6501 9.8677 2.6906 9.73857 2.76615 9.62997C3.32894 8.83346 4.29186 7.63223 5.55644 6.63739C6.82399 5.64023 8.32995 4.90001 9.99986 4.90001C11.6698 4.90001 13.1757 5.64023 14.4433 6.63739C15.7079 7.63223 16.6708 8.83347 17.2336 9.62998C17.3091 9.73858 17.3496 9.8677 17.3496 10C17.3496 10.1323 17.3091 10.2615 17.2336 10.37C16.6708 11.1666 15.7079 12.3678 14.4433 13.3626C13.1757 14.3598 11.6698 15.1 9.99986 15.1C8.32995 15.1 6.82399 14.3598 5.55644 13.3626C4.29186 12.3678 3.32895 11.1666 2.76615 10.3701C2.6906 10.2615 2.6501 10.1323 2.6501 10ZM9.99986 3.10001C7.78402 3.10001 5.89472 4.08104 4.44351 5.22269C2.99042 6.36583 1.91164 7.71978 1.29448 8.59353L1.29448 8.59352L1.2921 8.59692C1.0044 9.00824 0.850098 9.49806 0.850098 10C0.850098 10.502 1.0044 10.9918 1.2921 11.4031L1.29209 11.4031L1.29448 11.4065C1.91164 12.2803 2.99042 13.6342 4.44351 14.7773C5.89472 15.919 7.78402 16.9 9.99986 16.9C12.2157 16.9 14.105 15.919 15.5562 14.7773C17.0093 13.6342 18.0881 12.2803 18.7052 11.4065L18.7076 11.4031C18.9953 10.9918 19.1496 10.502 19.1496 10C19.1496 9.49807 18.9953 9.00825 18.7076 8.59692L18.7052 8.59353C18.0881 7.71978 17.0093 6.36583 15.5562 5.22269C14.105 4.08104 12.2157 3.10001 9.99986 3.10001ZM7.89961 10C7.89961 8.84021 8.83981 7.90001 9.99961 7.90001C11.1594 7.90001 12.0996 8.84021 12.0996 10C12.0996 11.1598 11.1594 12.1 9.99961 12.1C8.83981 12.1 7.89961 11.1598 7.89961 10ZM9.99961 6.10001C7.8457 6.10001 6.09961 7.8461 6.09961 10C6.09961 12.1539 7.8457 13.9 9.99961 13.9C12.1535 13.9 13.8996 12.1539 13.8996 10C13.8996 7.8461 12.1535 6.10001 9.99961 6.10001Z" />
    </IconBase>
  )
}

import { NotFoundBanner, Suspender } from '@mm/company-ui'
import React from 'react'
import { SingleDecisionDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useSyncActiveCompanyWithResource } from '../../companies'
import { SingleDecisionPage } from './SingleDecisionPage'

export type SingleDecisionProps = {
  id: string
}

export const SingleDecision = ({ id }: SingleDecisionProps) => {
  const { data, loading } = useQuery(SingleDecisionDocument, {
    variables: {
      id,
    },
  })

  useSyncActiveCompanyWithResource(data?.decision?.company?.id)

  if (data?.decision == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return <SingleDecisionPage decisionId={id} />
}

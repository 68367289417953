import { Button, ButtonProps, Flex, Text } from '@mm/company-ui'
import { useRouter } from 'next/router'
import React from 'react'
import { useMeetingPastDueActionsForUser } from '../hooks/useMeetingPastDueActionsForUser'

type MeetingUpdateRequestBannerProps = {
  meetingId: string
}

export function MeetingUpdateRequestBanner({ meetingId }: MeetingUpdateRequestBannerProps) {
  const router = useRouter()
  const pastDueActions = useMeetingPastDueActionsForUser(meetingId)

  const handleClick = () => {
    void router.push({ pathname: '/meetings/[id]/[section]', query: { id: meetingId, section: 'update' } })
  }

  return pastDueActions.length ? (
    <Banner
      label="You have past due actions for this meeting"
      action={<Button onClick={handleClick}>Write an update</Button>}
      sx={{ margin: 2, marginBottom: 0 }}
    />
  ) : null
}

export function Banner({
  className,
  label,
  action,
}: {
  className?: string
  label: string
  action?: React.ReactElement<ButtonProps>
}) {
  return (
    <Flex
      justify="space-between"
      align="center"
      sx={{ padding: 1, paddingLeft: 2, borderRadius: 'default', backgroundColor: 'accent-background-light' }}
      className={className}
    >
      <Text color="accent-text">{label}</Text>
      {action && React.cloneElement(action, { size: 'small', variant: 'accent' })}
    </Flex>
  )
}

import {
  Button,
  ConfirmPopover,
  Flex,
  Flyout,
  FlyoutDivider,
  FlyoutItem,
  toast,
  useConfirmPopover,
  useFeatureFlags,
} from '@mm/company-ui'
import {
  ChevronRightIcon,
  CircleCheckmarkIcon,
  DeleteIcon,
  DuplicateIcon,
  ExpandMaximizeIcon,
} from '@mm/company-ui-icons'
import Router from 'next/router'
import { route } from 'nextjs-routes'
import React, { useState } from 'react'
import { capture } from '../../analytics'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { deleteActionCommand } from '../commands/deleteActionCommand'
import { duplicateAndAssignActionCommand } from '../commands/duplicateAndAssignActionCommand'
import { ActionStatusMenu } from './ActionStatusMenu'

const DuplicateItem = ({ id }: ActionMoreMenuProps) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const { execute, loading } = useCommand(duplicateAndAssignActionCommand)

  const button = (
    <Button
      variant="accent"
      size="small"
      loading={loading}
      disabled={selectedUsers.length === 0}
      onClick={async () => {
        try {
          await execute({
            actionId: id,
            data: { assignees: selectedUsers },
          })
          capture('More Menu Selected', {
            menuItem: 'Duplicate & Assign',
            parent: 'Action',
          })
          toast(`${selectedUsers.length} actions created`)
        } finally {
          setSelectedUsers([])
        }
      }}
    >
      {selectedUsers.length > 0 ? `Create ${selectedUsers.length}` : 'Create'}
    </Button>
  )

  return (
    <UserSelectWithCompanyMembers
      placement="right-end"
      multiple
      value={selectedUsers}
      onChange={setSelectedUsers}
      decorateMenu={(menu) => (
        <Flex column>
          {menu}
          <FlyoutDivider sx={{ mt: 0 }} />
          <Flex justify="flex-end" sx={{ px: 2, pb: 1 }}>
            {button}
          </Flex>
        </Flex>
      )}
    >
      {({ value: _value, withName: _withName, multiple: _multiple, ...props }) => (
        <FlyoutItem startIcon={<DuplicateIcon />} endIcon={<ChevronRightIcon />} {...props} role="menuitem">
          Duplicate & Assign
        </FlyoutItem>
      )}
    </UserSelectWithCompanyMembers>
  )
}

export type ActionMoreMenuProps = {
  id: string
  onActionDeleted?: () => void
}

export const ActionMoreMenu = ({ id, onActionDeleted }: ActionMoreMenuProps) => {
  const { confirmBeforeDelete } = useFeatureFlags()
  const { triggerConfirm, confirmControlProps } = useConfirmPopover()
  const deleteAction = useCommand(deleteActionCommand)

  // TODO: Generate Menu Items from Command Objects
  return (
    <>
      <FlyoutItem
        onClick={() => {
          void Router.push(route({ pathname: '/actions/[id]', query: { id } }))
          capture('More Menu Selected', {
            parent: 'Action',
            menuItem: 'View Full Screen',
          })
        }}
        startIcon={<ExpandMaximizeIcon />}
      >
        View Full Screen
      </FlyoutItem>

      <Flyout position="right-start" content={<ActionStatusMenu id={id} />} sx={{ width: '100%' }}>
        <FlyoutItem startIcon={<CircleCheckmarkIcon />} endIcon={<ChevronRightIcon />}>
          Status
        </FlyoutItem>
      </Flyout>

      <DuplicateItem id={id} />

      <FlyoutItem
        onClick={async () => {
          if (!confirmBeforeDelete || (await triggerConfirm())) {
            await deleteAction.execute({ actionId: id, onActionDeleted })
          }
        }}
        startIcon={<DeleteIcon />}
      >
        Delete
      </FlyoutItem>

      <ConfirmPopover
        {...confirmControlProps}
        zIndex={10000}
        title="Permanently delete the Action?"
        description="This action cannot be reversed."
        confirmLabel="Delete permanently"
      />
    </>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const InfoIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="info" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.66667C5.39763 1.66667 1.66667 5.39763 1.66667 10C1.66667 14.6024 5.39763 18.3333 10 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6024 1.66667 10 1.66667ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM9.99984 8.33333C10.4601 8.33333 10.8332 8.70642 10.8332 9.16666V14.1667C10.8332 14.6269 10.4601 15 9.99984 15C9.5396 15 9.1665 14.6269 9.1665 14.1667V9.16666C9.1665 8.70642 9.5396 8.33333 9.99984 8.33333ZM10.8332 5.83333C10.8332 6.29357 10.4601 6.66667 9.99984 6.66667C9.5396 6.66667 9.1665 6.29357 9.1665 5.83333C9.1665 5.3731 9.5396 5 9.99984 5C10.4601 5 10.8332 5.3731 10.8332 5.83333Z"
    />
  </IconBase>
)

import { Suspender, useFeatureFlags } from '@mm/company-ui/src'
import React, { useCallback, useMemo } from 'react'
import {
  CreateActionInput,
  DecisionActionsDocument,
  DecisionActionsWaitingForDocument,
} from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { createActionOnDecisionCommand } from '../commands/createActionOnDecision'
import { ActionsDashboardFlat, createDataSource } from './ActionsDashboard'

export type DecisionActionsProps = {
  id: string
  renderEmptyPlaceholder?: () => React.ReactElement
}

export const DecisionActions = ({ id, renderEmptyPlaceholder }: DecisionActionsProps) => {
  const createActionOnDecision = useCommand(createActionOnDecisionCommand)
  const dataSource = useDecisionActionsDataSource(id)

  const { data, loading, error } = useQuery(DecisionActionsWaitingForDocument, {
    variables: {
      id,
    },
  })

  const handleAddAction = useCallback(
    (actionPayload: CreateActionInput) =>
      createActionOnDecision.execute({
        decisionId: id,
        data: actionPayload,
      }),
    [createActionOnDecision, id],
  )

  if (data?.decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Decision not found')
  }

  const decisionMaker = data?.decision?.decisionMaker

  return (
    <div sx={{ height: '100%' }}>
      <ActionsDashboardFlat
        renderEmptyPlaceholder={renderEmptyPlaceholder}
        dataSource={dataSource}
        onCreateAction={handleAddAction}
        newActionDefaults={{
          defaultWaitingFor: decisionMaker.id,
        }}
      />
    </div>
  )
}

const useDecisionActionsDataSource = (decisionId: string) => {
  const { updateActionContext } = useFeatureFlags()
  return useMemo(
    () =>
      createDataSource({
        query: DecisionActionsDocument,
        extractActions: (data) => data?.decision?.actions,
        matchAction: (action) =>
          (updateActionContext && action.decisions?.edges.some(({ node }) => node.id === decisionId)) ||
          (action.parent?.__typename === 'Decision' && action.parent.id === decisionId),
        variables: {
          id: decisionId,
        },
      }),
    [decisionId, updateActionContext],
  )
}

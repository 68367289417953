import React, { useCallback } from 'react'
import { CreateDecisionMutationInput, CreateDecisionOnGoalDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { isGraphQLError, useMutation } from '../../apollo'
import { useEmitEventBusEvent } from '../../eventbus'
import { DecisionsUpdatedTopic } from '../subscriptions/GlobalDecisionsSubscription'
import { CreateDecisionModal, CreateDecisionModalProps } from './CreateDecisionModal'

export type CreateGoalDecisionModalProps = Omit<CreateDecisionModalProps, 'onSubmit'> & {
  goalId: string
  onComplete?: (id: string) => void
}

export const CreateGoalDecisionModal = ({
  goalId,
  onComplete,
  onRequestClose,
  ...props
}: CreateGoalDecisionModalProps) => {
  const emit = useEmitEventBusEvent(DecisionsUpdatedTopic)
  const [createDecision] = useMutation(CreateDecisionOnGoalDocument, {
    onCompleted: ({ createDecisionOnGoal }) => {
      if (createDecisionOnGoal.__typename === 'Decision') {
        capture('Decision Created', {
          parent: 'Goal',
        })
        onRequestClose?.()
        onComplete?.(createDecisionOnGoal.id)
      } else if (isGraphQLError(createDecisionOnGoal)) {
        alert(`Issue creation failed: ${createDecisionOnGoal.message ?? 'unknown error'}`)
      } else {
        alert('Issue creation failed: unknown error')
      }
    },
    update: (cache, { data }) => {
      if (data?.createDecisionOnGoal?.__typename === 'Decision') {
        emit({ type: 'upserted', decision: data.createDecisionOnGoal })
      }
    },
  })

  const handleCreateDecision = useCallback(
    async (data: CreateDecisionMutationInput) => {
      await createDecision({
        variables: {
          goalId,
          data,
        },
      })
    },
    [createDecision, goalId],
  )

  return <CreateDecisionModal {...props} onSubmit={handleCreateDecision} onRequestClose={onRequestClose} />
}

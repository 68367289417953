import { NotFoundBanner, Suspender } from '@mm/company-ui'
import React from 'react'
import { SingleGoodThingDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useSyncActiveCompanyWithResource } from '../../companies'
import { SingleGoodThingPage } from './SingleGoodThingPage'

export type SingleGoodThingProps = {
  goodThingId: string
}

export const SingleGoodThing = ({ goodThingId }: SingleGoodThingProps) => {
  const { data, loading } = useQuery(SingleGoodThingDocument, {
    variables: {
      goodThingId,
    },
  })

  useSyncActiveCompanyWithResource(data?.goodThing?.company?.id)

  if (data?.goodThing == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return <SingleGoodThingPage goodThingId={goodThingId} cached />
}

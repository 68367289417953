import { Tag, TagProps } from '@mm/company-ui'
import Link from 'next/link'
import type { Route } from 'nextjs-routes'
import React from 'react'

export function TagLink({ href, ...props }: TagProps & { href: Route }) {
  return (
    <Link href={href} passHref={true}>
      <Tag as="a" {...props} />
    </Link>
  )
}

import {
  Avatar,
  Card,
  Flex,
  formatDateDisplay,
  formatUserDisplayName,
  NotFoundBanner,
  Suspender,
  Text,
  Button,
  useFeatureFlags,
  HotkeyTooltip,
} from '@mm/company-ui'
import { ActionsIcon, DeleteIcon, GoalsIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useState } from 'react'
import {
  UpdateItemActionUpdateDataFragment,
  UpdateUpdateDocument,
  UpdateViewDocument,
} from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../../modules/apollo'
import { EditUpdateForm } from '../../accountability/components/EditUpdateForm'
import { UpdateComments } from '../../accountability/components/UpdateComments'
import { UpdateCommentsComposer } from '../../accountability/components/UpdateCommentsComposer'
import { LoomVideo } from '../../accountability/components/UpdatesListTextItem'
import { useSyncActiveCompanyWithResource } from '../../companies'
import { DrawerView } from '../../components/DrawerView'
import { RecordLoomButton } from '../../components/RecordLoomButton'
import { VerticalDivider } from '../../components/VerticalDivider'
import { ActiveResourceContextProvider } from '../../hooks/useActiveResource'
import { useActiveUserRequired } from '../../users'
import { UpdateActions } from './UpdateActions'
import { UpdateEmojiReactions } from './UpdateEmojiReactions'
import { UpdateMoreMenu } from './UpdateMoreMenu'
import { UpdatePrivacyTag } from './UpdatePrivacyTag'
import { UpdateUserReviews } from './UpdateUserReviews'
import { ActionViewActionUpdate } from './UpdateViewActionUpdate'
import { UpdateViewGoalUpdate } from './UpdateViewGoalUpdate'

export type UpdateViewProps = {
  updateId: string
  inDrawer?: boolean
}

const ACTIONS_PER_PAGE = 0

export function UpdateView({ updateId }: UpdateViewProps) {
  const activeUser = useActiveUserRequired()
  const { userReviews, editUpdates, flipComments } = useFeatureFlags()
  const { data, loading } = useQuery(UpdateViewDocument, {
    variables: {
      updateId: updateId,
    },
  })

  const [isEditing, setIsEditing] = useState(false)
  const [isActionsExpanded, setIsActionsExpanded] = useState(false)
  const [updateUpdate, { loading: loomUpdateRecordingLoading }] = useMutation(UpdateUpdateDocument)

  useSyncActiveCompanyWithResource(data?.update?.company?.id)

  const update = data?.update

  const handleInsertLoom = useCallback(
    (goalsLoomLink: string) => {
      void updateUpdate({
        variables: {
          updateId: update?.id ?? '',
          data: {
            goalsLoomLink,
          },
        },
      })
    },
    [update?.id, updateUpdate],
  )

  if (update == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  const week = DateTime.fromMillis(update.reportAt).startOf('week')

  const goalIds = update.goals.map((goalUpdate) => goalUpdate.goal.id)

  const actionsSplitByGoals = update.actionUpdates.reduce<Record<string, Array<UpdateItemActionUpdateDataFragment>>>(
    (acc, actionUpdate) => {
      const parent = actionUpdate.action.parent

      if (parent?.__typename === 'Goal' && goalIds.includes(parent.id)) {
        return {
          ...acc,
          [parent.id]: [...(acc[parent.id] ?? []), actionUpdate],
        }
      }

      acc['other']?.push(actionUpdate)
      return acc
    },
    {
      other: [],
    },
  )

  return (
    <ActiveResourceContextProvider value={{ type: 'Update', id: updateId, privacy: update.privacy }}>
      <DrawerView
        resourceId={updateId}
        resourceType="updates"
        renderExtraMenuItems={
          <>
            {editUpdates && isEditing && (
              <Button
                variant="muted"
                size="small"
                onClick={() => {
                  setIsEditing(false)
                }}
              >
                Cancel Editing
              </Button>
            )}

            {userReviews && (
              <HotkeyTooltip shortcutKey="R" description="Mark as reviewed">
                <UpdateUserReviews updateId={update.id} withButton markAsReviewedOnOpen />
              </HotkeyTooltip>
            )}
            <VerticalDivider />
            <UpdatePrivacyTag />
          </>
        }
        renderTitle={
          <Flex row gap={1} sx={{ marginTop: 1 }}>
            <Avatar name={formatUserDisplayName(update.author)} sx={{ marginTop: 0.5 }} />
            <Flex column>
              <Text as="h3" variant="h3">
                {`${formatUserDisplayName(update.author, 'FIRST_NAME_WITH_FALLBACK')}'s update for ${formatDateDisplay(
                  week,
                  'SHORT_MONTH_WITH_DATE',
                )}`}
              </Text>
              <Text color="text-light">Created {formatDateDisplay(update.reportAt)}</Text>
            </Flex>
          </Flex>
        }
        renderMetadata={
          <>
            {isEditing ? (
              <EditUpdateForm
                updateId={updateId}
                userId={update.author.id}
                initialValues={{
                  actions: update.actionUpdates,
                  goals: update.goals,
                }}
                onSubmitSuccess={() => {
                  setIsEditing(false)
                }}
              />
            ) : (
              <>
                {update.goalsLoomLink ? (
                  <Flex justify="center" sx={{ paddingY: 2, position: 'relative' }}>
                    <LoomVideo url={update.goalsLoomLink} />

                    <Button
                      startIcon={<DeleteIcon />}
                      textHidden
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      onClick={() => {
                        void updateUpdate({
                          variables: {
                            updateId: update.id,
                            data: {
                              goalsLoomLink: '',
                            },
                          },
                        })
                      }}
                    >
                      Delete Loom
                    </Button>
                  </Flex>
                ) : update.author.id === activeUser.id ? (
                  <Flex justify="center" sx={{ paddingY: 2 }}>
                    <Flex
                      column
                      justify="center"
                      align="center"
                      sx={{ width: 500, height: 300, backgroundColor: 'background-light', borderRadius: 'medium' }}
                    >
                      <Flex gap={1}>
                        <RecordLoomButton loading={loomUpdateRecordingLoading} onInserted={handleInsertLoom} />
                      </Flex>

                      <Text sx={{ marginTop: 2, width: '70%', textAlign: 'center', color: 'text-medium' }}>
                        A video walkthrough is essential for communicating additional context and details to your team.
                      </Text>
                    </Flex>
                  </Flex>
                ) : null}

                {update.goals.length ? (
                  <div>
                    <Flex row align="center" gap={1} sx={{ paddingY: 2, paddingTop: 4 }}>
                      <GoalsIcon />
                      <Text variant="h5">Goals Review</Text>
                    </Flex>
                    <Flex column gap={2}>
                      {update.goals?.map((goalUpdate) => (
                        <UpdateViewGoalUpdate
                          key={goalUpdate.goal.id}
                          goalUpdate={goalUpdate}
                          actionUpdates={actionsSplitByGoals[goalUpdate.goal.id]}
                        />
                      ))}
                    </Flex>
                  </div>
                ) : null}

                {actionsSplitByGoals['other']?.length ? (
                  <div>
                    <Flex row align="center" gap={1} sx={{ paddingY: 2, paddingTop: 4 }}>
                      <ActionsIcon />
                      <Text variant="h5">Additional actions</Text>
                    </Flex>

                    {(
                      isActionsExpanded
                        ? actionsSplitByGoals['other'].length
                        : _.take(actionsSplitByGoals['other'], ACTIONS_PER_PAGE).length
                    ) ? (
                      <Card sx={{ padding: 0, borderRadius: 'medium' }} variant="flat">
                        <Flex column>
                          {(isActionsExpanded
                            ? actionsSplitByGoals['other']
                            : _.take(actionsSplitByGoals['other'], ACTIONS_PER_PAGE)
                          ).map((actionUpdate, i) => (
                            <div
                              key={actionUpdate.action.id}
                              sx={i !== 0 ? { borderTop: '1px solid', borderTopColor: 'border' } : {}}
                            >
                              <ActionViewActionUpdate actionUpdate={actionUpdate} />
                            </div>
                          ))}
                        </Flex>
                      </Card>
                    ) : null}

                    {actionsSplitByGoals['other'].length > ACTIONS_PER_PAGE ? (
                      <Button
                        sx={{ width: '100%', marginTop: 2 }}
                        onClick={() => {
                          setIsActionsExpanded(!isActionsExpanded)
                        }}
                      >
                        {isActionsExpanded ? 'Hide actions' : 'Show actions'}
                      </Button>
                    ) : null}
                  </div>
                ) : null}
              </>
            )}
          </>
        }
        renderDescription={
          <div sx={{ py: 1, px: 1 }}>
            <UpdateEmojiReactions id={updateId} cached />
          </div>
        }
        renderActions={<UpdateActions id={update.id} />}
        renderComments={<UpdateComments updateId={update.id} />}
        renderCommentComposer={flipComments ? <UpdateCommentsComposer updateId={update.id} /> : null}
        renderMoreMenu={({ hide }) => (
          <UpdateMoreMenu
            updateId={update.id}
            showEditOption={activeUser?.id === update.author.id}
            onEditClick={() => {
              hide()
              setIsEditing(true)
            }}
          />
        )}
      />
    </ActiveResourceContextProvider>
  )
}

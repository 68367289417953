import { Suspender, Flex, Tag } from '@mm/company-ui'
import _ from 'lodash'
import React from 'react'
import { SinglePrototoypeFeedbackDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { FeedbackPrivacySelect } from './FeedbackPrivacySelect'
import { FeedbackToChip } from './FeedbackToChip'

export type FeedbackSummaryMetadataProps = {
  prototypeFeedbackId: string
}

export const FeedbackSummaryMetadata = ({ prototypeFeedbackId }: FeedbackSummaryMetadataProps) => {
  const { data, loading, error } = useQuery(SinglePrototoypeFeedbackDocument, {
    variables: {
      id: prototypeFeedbackId,
    },
  })

  const prototypeFeedback = data?.prototypeFeedback

  if (prototypeFeedback == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Feedback not found')
  }

  return (
    <Flex row gap={2} align="center">
      {prototypeFeedback.author ? (
        <Tag>
          From
          <FeedbackToChip {...prototypeFeedback.author} sx={{ paddingRight: 0.5 }} />
        </Tag>
      ) : null}

      {prototypeFeedback.to ? (
        <Tag>
          To
          <FeedbackToChip {...prototypeFeedback.to} sx={{ paddingRight: 0.5 }} />
        </Tag>
      ) : null}

      <FeedbackPrivacySelect feedbackId={prototypeFeedbackId} />
    </Flex>
  )
}

import { AddWaitingForRowData, AddWaitingForRow, useFeatureFlags } from '@mm/company-ui'
import React, { useCallback } from 'react'
import { AddGoalRowCreateGoalDocument, MeetingPrivacyLevelDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { ApolloError, useMutation, useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'
import { UserSelectWithCompanyMembers, UserSelectWithMeetingParticipants } from '../../components/UserSelectWithQuery'
import { useMeetingContext } from '../../meetings'
import { useAddGoalToCache } from '../hooks/useAddGoalToCache'
import { useSuggestedGoalDueDates } from '../hooks/useSuggestedGoalDueDates'
import { GoalPrivacyMenuUi } from './GoalPrivacyMenu'
import { GoalPrivacyTagUi } from './GoalPrivacyTag'

export type AddGoalRowProps = {
  userId?: string
  focusOnRender?: boolean
  hideClear?: boolean
  forcedOpen?: boolean
  onCreated?: (info: { id: string }) => void
  onCancel?: () => void
  defaultDueDate?: Date
  hasAssigneePicker?: boolean
}

export const AddGoalRow = ({
  userId,
  focusOnRender = false,
  hideClear,
  forcedOpen,
  onCreated,
  onCancel,
  defaultDueDate,
  hasAssigneePicker,
}: AddGoalRowProps) => {
  const { activeCompanyId } = useActiveCompanyId()
  const suggestedDates = useSuggestedGoalDueDates()
  const meeting = useMeetingContext()
  const company = useActiveCompanyId()
  const { publicGoalsOnly } = useFeatureFlags()

  const addGoalToCache = useAddGoalToCache()

  const { data: meetingData } = useQuery(MeetingPrivacyLevelDocument, {
    variables: {
      id: meeting?.id || '',
    },
    skip: !meeting?.id,
  })
  const isParentMeetingPublic = meetingData?.meeting?.privacyLevel == 'PUBLIC'

  const [createGoal] = useMutation(AddGoalRowCreateGoalDocument, {
    onCompleted: ({ createGoal }) => {
      if (createGoal.__typename === 'Goal') {
        capture('Goal Created', {
          assignee: createGoal.assignee,
        })
        onCreated?.(createGoal)
      }
    },
    update: (cache, { data }) => {
      if (data?.createGoal.__typename === 'Goal') {
        addGoalToCache(cache, data.createGoal)
      }
    },
  })

  const handleOnAdd = useCallback(
    async ({ title, description, dueAt, assignee, privacy }: AddWaitingForRowData) => {
      const goalPrivacy = publicGoalsOnly ? 'PUBLIC' : privacy
      const { data, errors } = await createGoal({
        variables: {
          companyId: activeCompanyId,
          data: {
            title,
            assigneeId: assignee,
            descriptionHtml: description,
            dueAt: dueAt?.getTime(),
            parentMeetingId: meeting ? meeting.id : undefined,
            privacy: goalPrivacy,
          },
        },
      })
      if (data?.createGoal.__typename !== 'Goal') {
        throw errors?.length ? new ApolloError({ graphQLErrors: errors }) : new Error('Failed to create a goal')
      }
    },
    [publicGoalsOnly, createGoal, activeCompanyId, meeting],
  )

  return (
    <AddWaitingForRow
      titlePlaceholder="Give it a title..."
      onAdd={handleOnAdd}
      autofocus={focusOnRender}
      hasDueAt
      hasPrivacy
      renderPrivacy={({ value, onChange }) => (
        <GoalPrivacyTagUi
          sx={{ background: 'none' }}
          hasLabel={false}
          isPrivate={value === 'PRIVATE'}
          isParentMeetingPublic={isParentMeetingPublic}
          content={
            <GoalPrivacyMenuUi
              privacyLevel={value || 'PUBLIC'}
              companyName={company.activeCompanyName || 'the company.'}
              onChange={onChange}
            />
          }
        />
      )}
      defaultPrivacy={publicGoalsOnly ? 'PUBLIC' : isParentMeetingPublic ? 'PUBLIC' : 'PRIVATE'}
      defaultAssignee={userId}
      hasAssignee={!!meeting || hasAssigneePicker}
      renderUserPicker={(props) =>
        meeting ? (
          <UserSelectWithMeetingParticipants meetingId={meeting.id} {...props} />
        ) : (
          <UserSelectWithCompanyMembers {...props} />
        )
      }
      defaultDueAt={defaultDueDate ?? suggestedDates[0]?.date}
      dueAtSuggestedDates={suggestedDates}
      hasClear={!hideClear}
      forcedOpen={forcedOpen}
      hasCancel
      onCancel={onCancel}
    />
  )
}

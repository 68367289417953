import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const EllipsisIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="ellipsis" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33317 11.6666C4.25365 11.6666 4.99984 10.9205 4.99984 9.99998C4.99984 9.0795 4.25365 8.33331 3.33317 8.33331C2.4127 8.33331 1.6665 9.0795 1.6665 9.99998C1.6665 10.9205 2.4127 11.6666 3.33317 11.6666ZM10.0002 11.6666C10.9206 11.6666 11.6668 10.9205 11.6668 9.99998C11.6668 9.0795 10.9206 8.33331 10.0002 8.33331C9.07969 8.33331 8.3335 9.0795 8.3335 9.99998C8.3335 10.9205 9.07969 11.6666 10.0002 11.6666ZM18.3333 9.99998C18.3333 10.9205 17.5871 11.6666 16.6667 11.6666C15.7462 11.6666 15 10.9205 15 9.99998C15 9.0795 15.7462 8.33331 16.6667 8.33331C17.5871 8.33331 18.3333 9.0795 18.3333 9.99998Z"
    />
  </IconBase>
)

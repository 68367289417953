import { Flex, Suspender } from '@mm/company-ui'
import React from 'react'
import { SinglePrototoypeFeedbackDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { DocumentEditor } from '../../editor'
import { editFeedbackScoreCommand } from '../commands/editFeedbackScoreCommand'
import { FeedbackReactions } from './FeedbackReactions'
import { RatingDots } from './RatingDots'

export type FeedbackDescriptionProps = {
  prototypeFeedbackId: string
  padding?: number
}

export const FeedbackDescription = ({ prototypeFeedbackId, padding = 3 }: FeedbackDescriptionProps) => {
  const { data, loading, error } = useQuery(SinglePrototoypeFeedbackDocument, {
    variables: {
      id: prototypeFeedbackId,
    },
  })
  const editScore = useCommand(editFeedbackScoreCommand)

  const prototypeFeedback = data?.prototypeFeedback
  if (prototypeFeedback == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Feedback not found')
  }

  if (prototypeFeedback.description == null && prototypeFeedback.score == null) {
    return <>Access Denied</>
  }

  return (
    <Flex column gap={1}>
      <RatingDots
        sx={{ padding, borderBottom: '1px solid', borderColor: 'border' }}
        score={prototypeFeedback.score || undefined}
        onRatingSelection={(score) => {
          void editScore.execute({
            prototypeFeedbackId,
            score,
          })
        }}
      />

      <div sx={{ padding: padding }}>
        <DocumentEditor
          key={prototypeFeedback.description?.id}
          documentId={prototypeFeedback.description?.id}
          placeholder="Enter Feedback here..."
          mode="compact"
          sx={{
            '&': {
              display: 'flex',
              flexDirection: 'column',
            },
            '.ProseMirror': {
              margin: 0,
              padding: 0,
              minHeight: 'auto',
            },
          }}
        />
        <FeedbackReactions id={prototypeFeedbackId} />
      </div>
    </Flex>
  )
}

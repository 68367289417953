import { utils } from '@mm/common'
import { Card, Editable, Flex, Text, formatDateDisplay, Tag } from '@mm/company-ui'
import { ArrowRightIcon, CheckIcon, CloseIcon, ExclamationCircleIcon, FlagIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import React from 'react'
import { UpdateItemActionUpdateDataFragment, UpdateItemGoalUpdateDataFragment } from '../../../gen/graphql/documents'
import { useContextualDrawers } from '../../navigation'
import { ActionViewActionUpdate, stripHtml } from './UpdateViewActionUpdate'

export type UpdateViewGoalUpdateProps = {
  goalUpdate: UpdateItemGoalUpdateDataFragment
  actionUpdates?: Array<UpdateItemActionUpdateDataFragment>
}

export function UpdateViewGoalUpdate({ goalUpdate, actionUpdates }: UpdateViewGoalUpdateProps) {
  const { showDrawer } = useContextualDrawers()

  const actions = _.uniqBy(
    [
      ...(goalUpdate.nextActions?.map((action) =>
        action
          ? ({
              action,
              status: 'ACTIVE',
            } as const)
          : null,
      ) || []),
      ...(actionUpdates || []),
    ].filter(utils.isNonNil),
    (actionUpdate) => actionUpdate.action.id,
  )

  return (
    <Card sx={{ padding: 2, borderRadius: 'medium' }} variant="flat">
      <Flex column gap={0.5} align="flex-start">
        {goalUpdate.status === 'ON_TRACK' ? (
          <Tag value="ON_TRACK" variant="success" data-testid="TagSelect__selection">
            <ArrowRightIcon />
            On Track
          </Tag>
        ) : goalUpdate.status === 'AT_RISK' ? (
          <Tag value="AT_RISK" variant="alert" data-testid="TagSelect__selection">
            <FlagIcon />
            At risk
          </Tag>
        ) : goalUpdate.status === 'BEHIND' ? (
          <Tag value="BEHIND" variant="warning" data-testid="TagSelect__selection">
            <ExclamationCircleIcon />
            Off Track
          </Tag>
        ) : goalUpdate.status === 'DONE' ? (
          <Tag value="DONE" variant="accent" data-testid="TagSelect__selection">
            <CheckIcon />
            Completed
          </Tag>
        ) : goalUpdate.status === 'CANCELLED' ? (
          <Tag value="CANCELLED" variant="default" data-testid="TagSelect__selection">
            <CloseIcon />
            Cancelled
          </Tag>
        ) : null}

        <Flex
          sx={{ paddingY: 1 }}
          column
          onClick={(event) => {
            showDrawer('goal', goalUpdate.goal.id, event)
          }}
        >
          <Text
            bold
            sx={{
              flex: 1,
            }}
          >
            {goalUpdate.goal.priority != undefined ? (
              <Tag variant="flat" data-clickable="true" sx={{ fontWeight: 500, fontSize: 1 }}>
                #{goalUpdate.goal.priority + 1}
              </Tag>
            ) : null}
            {goalUpdate.goal.title}
          </Text>

          <Text color="text-light" variant="small" sx={{ flexShrink: 0 }}>
            By {formatDateDisplay(goalUpdate.goal.dueAt)}
          </Text>
        </Flex>

        <Editable mode="compact" initialValue={goalUpdate.reasonHtml} editable={false} />

        {goalUpdate.nextHtml && stripHtml(goalUpdate.nextHtml) ? (
          <>
            <Text bold>Ideas for next actions</Text>
            <Editable mode="compact" initialValue={goalUpdate.nextHtml} editable={false} />
          </>
        ) : null}

        {actions?.length ? (
          <>
            <Text bold sx={{ marginTop: 1 }}>
              Next actions
            </Text>
            <Card sx={{ padding: 0, borderRadius: 'medium', width: '100%' }} variant="flat">
              <Flex column>
                {actions?.map((actionUpdate, i) => (
                  <div
                    key={actionUpdate.action.id}
                    sx={i !== 0 ? { borderTop: '1px solid', borderTopColor: 'border' } : {}}
                  >
                    <ActionViewActionUpdate actionUpdate={actionUpdate} hasParent={false} />
                  </div>
                ))}
              </Flex>
            </Card>
          </>
        ) : null}
      </Flex>
    </Card>
  )
}

import invariant from 'invariant'
import React from 'react'
import { useApolloClient } from '../../apollo'
import { CommandCenterContext } from '../context/CommandCenter'
import { logger } from '../helpers'
import { Command } from '../types'

export const useCommand = <Args>(command: Command<Args>) => {
  const apolloClient = useApolloClient()
  const [loading, setLoading] = React.useState(false)
  const commandCenter = React.useContext(CommandCenterContext)
  const helpers = React.useMemo(() => ({ apolloClient }), [apolloClient])

  // this is not a conditional hook execution as long as useCommand is always called with unconditional command
  const executeCommand = command.useExecute?.() ?? command.execute

  invariant(executeCommand != null, 'either useExecute or execute must be specified')

  const execute = React.useCallback(
    async (args: Args) => {
      if (await command.canExecute(args, helpers)) {
        logger('execute', command.id, commandCenter.history, args)
        setLoading(true)
        try {
          const undoCommand = await executeCommand(args, helpers)
          if (undoCommand) {
            commandCenter.pushCommandResult({
              ...undoCommand,
              origin: command,
            })
          }
        } finally {
          setLoading(false)
        }
      }
    },
    [command, executeCommand, commandCenter, helpers],
  )

  return {
    execute,
    loading,
  }
}

import styled from '@emotion/styled'
import React from 'react'
import { css } from 'theme-ui'
import { variant } from '../../helpers'
import { Pressable } from '../Pressable'

export type TagProps = {
  className?: string
  as?: keyof React.ReactHTML
  variant?:
    | 'default'
    | 'warning'
    | 'warning-inverted'
    | 'success'
    | 'success-inverted'
    | 'alert'
    | 'alert-inverted'
    | 'accent'
    | 'accent-inverted'
    | 'flat'
    | 'pastel-purple'
    | 'pastel-blue'
    | 'pastel-orange'
    | 'pastel-green'
    | 'heavy'
  children?: React.ReactNode
}

export const Tag = styled(Pressable)<TagProps>(
  css({
    display: 'inline-flex',
    paddingX: 1,
    height: 3,
    gap: 0.5,
    borderRadius: 'full',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    fontSize: 0,

    // For any TagLinks to prevent underline.
    textDecoration: 'none',
  }),

  variant({
    prop: 'variant',
    variants: {
      flat: {
        backgroundColor: 'transparent',
        color: 'text-light',
        //   '&:hover': onClick && {
        //     backgroundColor: 'background-medium',
        //   },
      },
      default: {
        backgroundColor: 'background-light-alpha',
        color: 'text-light',
        //   '&:hover': onClick && {
        //     backgroundColor: 'background-medium',
        //   },
      },
      alert: {
        backgroundColor: 'system-background-alert-light',
        color: 'system-text-alert-light',
        //   '&:hover': onClick && {
        //     backgroundColor: 'system-background-alert-medium',
        //   },
      },
      'alert-inverted': {
        backgroundColor: 'system-background-alert',
        color: 'system-text-alert',
        //   '&:hover': onClick && {
        //     backgroundColor: 'system-background-success-medium',
        //   },
      },
      warning: {
        backgroundColor: 'system-background-warning-light',
        color: 'system-text-warning',
        //   '&:hover': onClick && {
        //     backgroundColor: 'system-background-warning-medium',
        //   },
      },
      'warning-inverted': {
        backgroundColor: 'system-background-warning',
        color: 'text-inverted',
        //   '&:hover': onClick && {
        //     backgroundColor: 'system-background-success-medium',
        //   },
      },
      success: {
        backgroundColor: 'system-background-success-light',
        color: 'system-text-success',
        //   '&:hover': onClick && {
        //     backgroundColor: 'system-background-success-medium',
        //   },
      },
      'success-inverted': {
        backgroundColor: 'system-background-success',
        color: 'text-inverted',
        //   '&:hover': onClick && {
        //     backgroundColor: 'system-background-success-medium',
        //   },
      },
      accent: {
        backgroundColor: 'accent-background-light-hover',
        color: 'accent-text',
        //   '&:hover': onClick && {
        //     backgroundColor: 'accent-background-medium-light',
        //   },
      },
      'accent-inverted': {
        backgroundColor: 'accent-background',
        color: 'text-inverted',
        //   '&:hover': onClick && {
        //     backgroundColor: 'accent-background-medium-light',
        //   },
      },
      'pastel-purple': {
        backgroundColor: '#7e57c215',
        color: '#7e57c2',
        //   '&:hover': onClick && {
        //     backgroundColor: 'accent-background-medium-light',
        //   },
      },
      'pastel-blue': {
        backgroundColor: '#42a5f515',
        color: '#42a5f5',
        //   '&:hover': onClick && {
        //     backgroundColor: 'accent-background-medium-light',
        //   },
      },
      'pastel-orange': {
        backgroundColor: '#ff704315',
        color: '#ff7043',
        //   '&:hover': onClick && {
        //     backgroundColor: 'accent-background-medium-light',
        //   },
      },
      'pastel-green': {
        backgroundColor: '#66bb6a15',
        color: '#66bb6a',
        //   '&:hover': onClick && {
        //     backgroundColor: 'accent-background-medium-light',
        //   },
      },
      heavy: {
        backgroundColor: 'background-heavy',
        color: 'text-inverted',
        //   '&:hover': onClick && {
        //     backgroundColor: 'accent-background-medium-light',
        //   },
      },
    },
  }),
)

Tag.defaultProps = {
  variant: 'default',
}

import { closedActionStatuses, openActionStatuses } from '@mm/common'
import {
  AnimateOpen,
  Button,
  Card,
  FastTable,
  Flex,
  formatDateDisplay,
  Popover,
  Suspender,
  useFeatureFlags,
} from '@mm/company-ui'
import { ChevronIcon, CircleCheckmarkIcon, ExpandMaximizeIcon, LoadingIcon } from '@mm/company-ui-icons/src'
import { AnimatePresence } from 'framer-motion'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { Suspense, useCallback, useMemo, useState } from 'react'
import { ActionsDashboardRowDataFragment } from '../../../../gen/graphql/documents'
import { CapabilitiesProvider, QueuedCapabilitiesInterceptor, RegisterCapability } from '../../../capabilities'
import { FOCUS_AND_QUOTE_COMMENT } from '../../../comments/capabilities'
import { CREATE_ISSUE } from '../../../decisions/capabilities'
import { useMeetingContext } from '../../../meetings'
import { ADD_TOPIC } from '../../../topics/capabilities'
import { ActionRowsGroup, ActionsDashboardDataSource } from '../../types'
import { ActionAssigneeCellContent } from '../ActionAssigneeCell'
import { ActionComments } from '../ActionComments'
import { ActionDatepicker } from '../ActionDatepicker'
import { ActionDescription } from '../ActionDescription'
import { ActionDoneNotDoneButtons } from '../ActionDoneNotDoneButtons'
import { ActionInlineView } from '../ActionInlineView'
import { ActionStatusCheckbox } from '../ActionStatusCheckbox'
import { ActionTitle } from '../ActionTitle'
import { ActionWaitingForCellContent } from '../ActionWaitingForCell'
import { CreatedContext } from '../CreatedContext'
import { QuickAddAction } from '../QuickAddAction'
import { BaseActionsDashboard, defaultRenderEmptyPlaceholder } from './BaseActionsDashboard'
import { useFetchActionsDashboardData } from './DataSource'
import { ActionsDashboardData, ActionsDashboardProps } from './types'

export type ActionsDashboardNewOrderProps = React.ComponentPropsWithoutRef<typeof ActionsDashboardNewOrder>

export const ActionsDashboardNewOrder = ({
  dataSource,
  renderEmptyPlaceholder = defaultRenderEmptyPlaceholder,
  showDoneActions = true,
  ...rest
}: ActionsDashboardProps<{
  dataSource: ActionsDashboardDataSource
  renderEmptyPlaceholder?: () => React.ReactElement
  onActionUpdated?: (action: ActionsDashboardRowDataFragment) => void
  showDoneActions?: boolean
}>) => {
  const {
    actionsDashboardContext,
    actionsDashboardWithDoneNotDone,
    dangerouslyAllowForClickingOnContextInActionTable,
    addActionInTableHeader,
    toggleOpenActionsInline,
    actionsEditableInlineWithPersistentExpand,
    moveActionNotDoneToDrawer,
    hideActionStatusToggle,
  } = useFeatureFlags()

  const [inlineActionOpen, setInlineActionOpen] = React.useState<Record<string, boolean>>({})
  const meeting = useMeetingContext()

  const {
    data: actions,
    hasMore,
    fetchMore,
    loading,
  } = useFetchActionsDashboardData(dataSource, {
    filters: {
      status: openActionStatuses,
    },
    orderBy: 'DUE_AT_ASC',
  })
  const {
    data: doneActions,
    hasMore: hasMoreDoneActions,
    fetchMore: fetchMoreDoneActions,
    loading: loadingDoneActions,
  } = useFetchActionsDashboardData(dataSource, {
    pageSize: 50,
    filters: {
      status: closedActionStatuses,
    },
    orderBy: 'DATE_COMPLETED_DESC',
  })

  const [thisWeek] = useState(() => DateTime.now().startOf('week'))

  const decorateRow = useCallback(
    (children: React.ReactElement, row: ActionsDashboardRowDataFragment) => {
      const inlineOpen = inlineActionOpen[row.id] === true

      if (actionsEditableInlineWithPersistentExpand) {
        return (
          <div sx={{ backgroundColor: inlineOpen ? 'background-light' : undefined }}>
            <CapabilitiesProvider>
              <QueuedCapabilitiesInterceptor
                capability={FOCUS_AND_QUOTE_COMMENT}
                onQueued={() => {
                  setInlineActionOpen({ ...inlineActionOpen, [row.id]: true })
                }}
              >
                {children}
                <AnimatePresence initial={false}>
                  {inlineOpen && (
                    <AnimateOpen>
                      <RegisterCapability
                        capability={ADD_TOPIC}
                        handler={() => Promise.resolve()}
                        skip={!meeting?.id}
                      />
                      <RegisterCapability
                        capability={CREATE_ISSUE}
                        handler={() => Promise.resolve()}
                        skip={!meeting?.id}
                      />
                      <div sx={{ borderBottom: '1px solid', borderBottomColor: 'border', pl: '100px' }}>
                        <Suspense
                          fallback={
                            <Flex align="center" justify="center" sx={{ height: '100%', minHeight: 60 }}>
                              <LoadingIcon width={20} height={20} color="text-disabled" />
                            </Flex>
                          }
                        >
                          <ActionInlineView actionId={row.id} />
                        </Suspense>
                      </div>
                    </AnimateOpen>
                  )}
                </AnimatePresence>
              </QueuedCapabilitiesInterceptor>
            </CapabilitiesProvider>
          </div>
        )
      }

      if (toggleOpenActionsInline) {
        return (
          <>
            {children}
            <AnimatePresence initial={false}>
              {inlineOpen && (
                <AnimateOpen>
                  <CapabilitiesProvider>
                    <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meeting?.id} />
                    <RegisterCapability
                      capability={CREATE_ISSUE}
                      handler={() => Promise.resolve()}
                      skip={!meeting?.id}
                    />
                    <div sx={{ padding: 1, paddingTop: 0, borderBottom: '1px solid', borderBottomColor: 'border' }}>
                      <Suspense
                        fallback={
                          <Card variant="flat" sx={{ padding: 0 }}>
                            <Flex align="center" justify="center" sx={{ height: '100%', minHeight: 60 }}>
                              <LoadingIcon width={20} height={20} color="text-disabled" />
                            </Flex>
                          </Card>
                        }
                      >
                        <Card variant="flat" sx={{ padding: 0 }}>
                          <div sx={{ paddingX: 2, paddingBottom: 1 }}>
                            <Flex gap={1}>
                              <ActionTitle cached id={row.id} sx={{ flex: 1, fontSize: 1 }} />
                            </Flex>
                            <CreatedContext
                              createdAt={row.createdAt}
                              parent={row.followUpTo ?? row.parent ?? undefined}
                              parentMeeting={row.parentMeeting}
                              overflow="default"
                            />
                          </div>
                          <div
                            sx={{
                              borderTop: '1px solid',
                              borderTopColor: 'border',
                            }}
                          >
                            <ActionDescription actionId={row.id} fillHeight={false} padding={1} />
                          </div>
                          <div
                            sx={{
                              borderTop: '1px solid',
                              borderTopColor: 'border',
                              padding: 1,
                            }}
                          >
                            <ActionComments
                              actionId={row.id}
                              fixedComposer={false}
                              order="newest"
                              fixedAndScroll={false}
                            />
                          </div>
                        </Card>
                      </Suspense>
                    </div>
                  </CapabilitiesProvider>
                </AnimateOpen>
              )}
            </AnimatePresence>
          </>
        )
      }

      return children
    },
    [inlineActionOpen, toggleOpenActionsInline, meeting?.id, actionsEditableInlineWithPersistentExpand],
  )

  const data = useMemo<ActionsDashboardData>(() => {
    const lastWeek = thisWeek.minus({ weeks: 1 })
    const nextWeek = thisWeek.plus({ weeks: 1 })

    const groups: Array<ActionRowsGroup> = [
      {
        id: 'open-actions',
        title: 'To do',
        hasAddMore: true,
        groups: _(actions)
          .sortBy((action) => action.dueAt ?? action.createdAt) // New actions may not be sorted correctly
          .groupBy((action) => {
            const week = DateTime.fromMillis(action.dueAt ?? action.createdAt).startOf('week')
            return (week > thisWeek ? week : thisWeek).toISODate()
          })
          .thru((groups) => ({
            [thisWeek.toISODate()]: [],
            ...groups,
          }))
          .map((rows, weekISO) => {
            const week = DateTime.fromISO(weekISO)

            return {
              id: weekISO,
              title: week.hasSame(thisWeek, 'week')
                ? `${formatDateDisplay(week)} \u2013 ${formatDateDisplay(week.endOf('week'))} (This week)`
                : week.hasSame(nextWeek, 'week')
                ? `${formatDateDisplay(week)} \u2013 ${formatDateDisplay(week.endOf('week'))} (Next week)`
                : `${formatDateDisplay(week)} \u2013 ${formatDateDisplay(week.endOf('week'))}`,
              rows,
            }
          })
          .value(),
        renderEmptyPlaceholder,
        hasMore,
        fetchMore,
        loadingMore: loading,
      },
    ]

    if (showDoneActions) {
      groups.push({
        id: 'closed-actions',
        title: 'Done',
        groups: _(doneActions)
          .orderBy((action) => action.dateCompleted ?? action.createdAt, 'desc') // New actions may not be sorted correctly
          .groupBy((action) =>
            DateTime.fromMillis(action.dateCompleted ?? action.createdAt)
              .startOf('week')
              .toISODate(),
          )
          .map((rows, weekISO) => {
            const week = DateTime.fromISO(weekISO)

            return {
              id: weekISO,
              title: week.hasSame(thisWeek, 'week')
                ? `${formatDateDisplay(week)} \u2013 ${formatDateDisplay(week.endOf('week'))} (This week)`
                : week.hasSame(lastWeek, 'week')
                ? `Last week (${formatDateDisplay(week)} \u2013 ${formatDateDisplay(week.endOf('week'))} (Last week)`
                : `${formatDateDisplay(week)} \u2013 ${formatDateDisplay(week.endOf('week'))}`,
              rows,
            }
          })
          .value(),
        hasMore: hasMoreDoneActions || false,
        fetchMore: fetchMoreDoneActions,
        loadingMore: loadingDoneActions,
      })
    }
    return groups
  }, [
    thisWeek,
    actions,
    renderEmptyPlaceholder,
    hasMore,
    fetchMore,
    loading,
    doneActions,
    hasMoreDoneActions,
    fetchMoreDoneActions,
    loadingDoneActions,
    showDoneActions,
  ])

  const [displayFullContext, setDisplayFullContext] = React.useState<Record<string, boolean>>({})

  const columns = useMemo<FastTable.TableColumn<ActionsDashboardRowDataFragment>[]>(
    () => [
      {
        header: addActionInTableHeader ? (
          <Popover placement="bottom-start" key="action" render={({ hide }) => <QuickAddAction onDone={hide} />}>
            {({ getReferenceProps }) => (
              <Button startIcon={<CircleCheckmarkIcon />} size="small" {...getReferenceProps()} sx={{ marginLeft: -1 }}>
                Add Action
              </Button>
            )}
          </Popover>
        ) : (
          ''
        ),
        renderCell: ({ id }) =>
          actionsDashboardContext ? (
            <Flex column sx={{ paddingY: 1.5, height: '100%' }} justify="center">
              <ActionStatusCheckbox cached id={id} />
            </Flex>
          ) : (
            <ActionStatusCheckbox cached id={id} />
          ),
        width: hideActionStatusToggle ? 30 : 44,
        disableResizing: true,
      },
      {
        header: '',
        renderCell: (row) => {
          const { titleHtml, createdAt, parent, followUpTo, dueAt, status } = row
          const isPastDue = !!dueAt && DateTime.fromMillis(dueAt).startOf('day') <= DateTime.now().startOf('day')
          const shouldRenderPastDue = isPastDue && status === 'ACTIVE' && actionsDashboardWithDoneNotDone
          const inlineOpen = inlineActionOpen[row.id] === true

          if (actionsEditableInlineWithPersistentExpand) {
            return (
              <Flex row gap={1} sx={{ width: '100%' }}>
                <Flex column shrink sx={{ paddingBottom: 1, width: '100%' }}>
                  <ActionTitle
                    cached
                    id={row.id}
                    sx={{
                      flex: 1,
                      fontSize: 1,
                      fontWeight: 'body',
                      fontFamily: 'body',
                      '.ProseMirror': { paddingBottom: '2px', height: 30, overflow: 'hidden' },
                      '.ProseMirror-focused': { height: 'auto', overflow: 'initial' },
                      ...(inlineOpen
                        ? { '.ProseMirror': { paddingBottom: '2px', height: 'auto', overflow: 'initial' } }
                        : {}),
                    }}
                  />
                  <CreatedContext
                    highlightLinks
                    overflow={inlineOpen ? 'default' : 'clip'}
                    createdAt={createdAt}
                    parent={followUpTo ?? parent ?? undefined}
                    parentMeeting={row.parentMeeting}
                  />
                </Flex>

                <Flex row gap={1} sx={{ paddingTop: 2, flexShrink: 0 }}>
                  <Button
                    size="small"
                    textHidden
                    startIcon={<ChevronIcon sx={{ transform: `rotate(${!inlineOpen ? 0 : -180}deg)` }} />}
                    onClick={() => {
                      setInlineActionOpen({ ...inlineActionOpen, [row.id]: !inlineActionOpen[row.id] })
                    }}
                    sx={{ paddingX: 0.5, height: 24 }}
                  >
                    Expand Action Inline
                  </Button>

                  {shouldRenderPastDue && !moveActionNotDoneToDrawer ? (
                    <div sx={{ flexShrink: 0 }}>
                      <ActionDoneNotDoneButtons {...row} />
                    </div>
                  ) : null}
                </Flex>
              </Flex>
            )
          }

          if (toggleOpenActionsInline) {
            return (
              <>
                <Flex
                  row
                  align="center"
                  gap={1}
                  sx={{
                    width: '100%',
                    '&:hover .open-drawer-button': {
                      opacity: 1,
                    },
                  }}
                >
                  <Flex
                    column
                    shrink
                    sx={{ paddingY: 1, width: '100%' }}
                    onClick={() => {
                      setInlineActionOpen({ ...inlineActionOpen, [row.id]: !inlineActionOpen[row.id] })
                    }}
                  >
                    <FastTable.RichTextCell value={titleHtml} clickable={false} />
                    <CreatedContext
                      withoutLink
                      createdAt={createdAt}
                      parent={followUpTo ?? parent ?? undefined}
                      parentMeeting={row.parentMeeting}
                    />
                  </Flex>

                  <div data-clickable="true" className="open-drawer-button" sx={{ opacity: 0 }}>
                    <Button size="small" textHidden startIcon={<ExpandMaximizeIcon />}>
                      Open Action Drawer
                    </Button>
                  </div>

                  {shouldRenderPastDue && !moveActionNotDoneToDrawer ? (
                    <div sx={{ flexShrink: 0 }}>
                      <ActionDoneNotDoneButtons {...row} />
                    </div>
                  ) : null}
                </Flex>
              </>
            )
          }

          const titleNode = <FastTable.RichTextCell value={titleHtml} />

          return actionsDashboardContext ? (
            <Flex row align="center" gap={1} sx={{ width: '100%' }}>
              {dangerouslyAllowForClickingOnContextInActionTable ? (
                <Flex column shrink sx={{ paddingY: 1, width: '100%' }}>
                  {titleNode}
                  <CreatedContext
                    withoutLink
                    onClick={() => {
                      setDisplayFullContext({ ...displayFullContext, [row.id]: !displayFullContext[row.id] })
                    }}
                    createdAt={createdAt}
                    parent={followUpTo ?? parent ?? undefined}
                    parentMeeting={row.parentMeeting}
                    overflow={displayFullContext[row.id] === true ? 'default' : 'clip'}
                  />
                </Flex>
              ) : (
                <Flex column shrink sx={{ paddingY: 1, width: '100%' }} data-clickable="true">
                  {titleNode}
                  <CreatedContext
                    withoutLink
                    createdAt={createdAt}
                    parent={followUpTo ?? parent ?? undefined}
                    parentMeeting={row.parentMeeting}
                  />
                </Flex>
              )}

              {shouldRenderPastDue && !moveActionNotDoneToDrawer ? (
                <div sx={{ flexShrink: 0 }}>
                  <ActionDoneNotDoneButtons {...row} />
                </div>
              ) : null}
            </Flex>
          ) : (
            titleNode
          )
        },
        width: 100,
      },
      {
        header: 'Due',
        renderCell: ({ id }) =>
          actionsDashboardContext ? (
            <Flex column sx={{ paddingY: 1, height: '100%' }}>
              <ActionDatepicker
                id={id}
                showIcon={false}
                textAlign="left"
                redIfPastDue={true}
                showRescheduledPrefix={true}
                cached
              />
            </Flex>
          ) : (
            <ActionDatepicker
              id={id}
              showIcon={false}
              textAlign="left"
              redIfPastDue={true}
              showRescheduledPrefix={true}
              cached
            />
          ),
        width: 100,
        disableResizing: true,
      },
      {
        header: 'DRI',
        renderCell: ({ id, assignee }) =>
          actionsDashboardContext ? (
            <Flex column sx={{ paddingY: 1, height: '100%' }}>
              <ActionAssigneeCellContent assigneeId={assignee.id} actionId={id} avatarOnly />
            </Flex>
          ) : (
            <ActionAssigneeCellContent assigneeId={assignee.id} actionId={id} avatarOnly />
          ),
        width: 40,
        disableResizing: true,
      },
      {
        header: 'Waiting',
        renderCell: ({ id, waitingFor }) =>
          waitingFor ? (
            actionsDashboardContext ? (
              <Flex column sx={{ paddingY: 1, height: '100%' }}>
                <ActionWaitingForCellContent waitingForId={waitingFor?.id} actionId={id} avatarOnly />
              </Flex>
            ) : (
              <ActionWaitingForCellContent waitingForId={waitingFor?.id} actionId={id} avatarOnly />
            )
          ) : null,
        width: 40,
        disableResizing: true,
      },
    ],
    [
      actionsDashboardContext,
      actionsDashboardWithDoneNotDone,
      displayFullContext,
      dangerouslyAllowForClickingOnContextInActionTable,
      addActionInTableHeader,
      inlineActionOpen,
      setInlineActionOpen,
      toggleOpenActionsInline,
      actionsEditableInlineWithPersistentExpand,
      moveActionNotDoneToDrawer,
      hideActionStatusToggle,
    ],
  )

  if ((actions == null && loading) || (doneActions == null && loadingDoneActions)) {
    return <Suspender />
  }

  return (
    <BaseActionsDashboard
      {...rest}
      data={data}
      columns={columns}
      getRowOffset={(n) => 2 + 2 * n}
      decorateRow={decorateRow}
      renderRowGroupHeader={(rowGroup, toggleProps) => (
        <>
          <FastTable.RowGroupHeaderTitle rowGroup={rowGroup} sx={{ marginLeft: 0 }} />
          {!rowGroup.nonCollapsible && <FastTable.RowGroupHeaderToggle {...toggleProps} />}
        </>
      )}
    />
  )
}

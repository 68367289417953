import { Flex, formatDateDisplay, Text, useFeatureFlags } from '@mm/company-ui'
import React from 'react'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'
import { DecisionUserReviews } from './DecisionUserReviews'

export type DecisionRowContext = {
  decisionId: string
  dueAt?: number
  updatedAt?: number
  createdAt?: number
}

export function DecisionRowContext({ decisionId, dueAt, createdAt, updatedAt }: DecisionRowContext) {
  const { showDecisionCreatedAtUpdateAt, userReviews } = useFeatureFlags()
  const { commentsLabel, latestCommentDate } = useTotalComments({ __typename: 'Decision', id: decisionId })
  const { actionsLabel, latestActionDate } = useTotalActions({ __typename: 'Decision', id: decisionId })

  const latestUpdatedAt = Math.max(updatedAt || 0, latestCommentDate || 0, latestActionDate || 0)

  return (
    <Flex row gap={2}>
      {userReviews && <DecisionUserReviews decisionId={decisionId} size="small" />}
      {showDecisionCreatedAtUpdateAt && createdAt && (
        <Text variant="small" color="text-light">
          Created {formatDateDisplay(createdAt, 'RELATIVE')}
        </Text>
      )}
      {showDecisionCreatedAtUpdateAt && !!latestUpdatedAt && createdAt !== latestUpdatedAt && (
        <Text variant="small" color="text-light">
          Updated {formatDateDisplay(latestUpdatedAt, 'RELATIVE')}
        </Text>
      )}
      {dueAt && (
        <Text variant="small" color="text-light">
          Due {formatDateDisplay(dueAt)}
        </Text>
      )}
      <Text variant="small" color="text-light">
        {actionsLabel}
      </Text>
      <Text variant="small" color="text-light">
        {commentsLabel}
      </Text>
    </Flex>
  )
}

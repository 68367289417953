import { EditableRef, Suspender } from '@mm/company-ui'
import React, { useEffect, useRef } from 'react'
import { EditCommentBoxDocument, EditCommentBoxSaveDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { CommentComposer } from './CommentComposer'

export type EditCommentBoxProps = {
  id: string
  onSaved?: () => void
  onCancel?: () => void
  cached?: boolean
}

export const EditCommentBox = ({ id, onSaved, onCancel, cached }: EditCommentBoxProps) => {
  const editableRef = useRef<EditableRef>(null)
  const { data, loading, error } = useQuery(EditCommentBoxDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: { id },
  })

  const [saveComment] = useMutation(EditCommentBoxSaveDocument, {
    onCompleted: (data) => {
      if (data.updateComment.__typename === 'Comment') {
        onSaved?.()
      }
    },
  })

  const comment = data?.comment

  const loaded = !!comment
  useEffect(() => {
    if (loaded) {
      editableRef.current?.commands.focus('end')
    }
  }, [loaded])

  if (comment == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('comment not found')
  }

  return (
    <CommentComposer
      ref={editableRef}
      user={comment.author}
      initialHtmlBody={comment.htmlBody}
      initialAuthor={comment.author.id}
      placeholder="Edit the comment..."
      submitLabel="Save"
      showCancel
      onCancel={onCancel}
      onSubmit={async ({ htmlBody, author }) => {
        const { data } = await saveComment({
          variables: {
            id,
            htmlBody,
            author,
          },
        })
        if (data?.updateComment.__typename !== 'Comment') {
          throw new Error('failed to update comment')
        }
      }}
    />
  )
}

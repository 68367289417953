import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { Flex } from '../Flex'
import { Text } from '../Text'
import { Tooltip } from './Tooltip'

type HotkeyTooltipProps = {
  children: React.ReactNode
  shortcutKey: string | React.ReactElement
  description?: string
  sx?: ThemeUIStyleObject
}

export const HotkeyTooltip = ({ children, shortcutKey, description, ...props }: HotkeyTooltipProps) => {
  const shortcut = typeof shortcutKey === 'string' ? shortcutKey.toUpperCase() : shortcutKey
  return (
    <Tooltip
      delay={500}
      position="bottom"
      sx={{ p: 0, margin: 0 }}
      description={
        <Flex row gap={0.5} align="center" sx={{ p: 0.5 }}>
          {description}
          <Flex
            align="center"
            justify="center"
            sx={{
              border: '1px solid',
              borderRadius: 'default',
              borderColor: 'border',
              backgroundColor: 'background',
              color: 'text',
            }}
          >
            <Text sx={{ px: 0.5, fontSize: 0 }}>{shortcut}</Text>
          </Flex>
        </Flex>
      }
      {...props}
    >
      {children}
    </Tooltip>
  )
}

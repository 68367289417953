import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const LinkIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="link" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.898 3.44777L9.75593 5.58982C9.4305 5.91526 8.90286 5.91526 8.57742 5.58982C8.25198 5.26439 8.25198 4.73675 8.57742 4.41131L10.7304 2.25832C11.6661 1.34512 12.9217 0.833984 14.2292 0.833984C15.5366 0.833984 16.7922 1.34517 17.7279 2.25836L17.1458 2.85473L17.7422 2.27268C18.6554 3.20834 19.1666 4.46396 19.1666 5.7714C19.1666 7.07884 18.6554 8.33446 17.7422 9.27012L17.7351 9.27737L15.5893 11.4232C15.2638 11.7486 14.7362 11.7486 14.4108 11.4232C14.0853 11.0977 14.0853 10.5701 14.4108 10.2446L16.5528 8.10259C17.1601 7.47861 17.4999 6.64223 17.4999 5.7714C17.4999 4.90235 17.1615 4.06762 16.5565 3.44404C15.933 2.83912 15.0982 2.50065 14.2292 2.50065C13.3583 2.50065 12.522 2.8405 11.898 3.44777ZM5.58924 8.57797C5.91468 8.90341 5.91468 9.43104 5.58924 9.75648L3.44719 11.8985C2.83992 12.5225 2.50007 13.3589 2.50007 14.2297C2.50007 15.0988 2.83854 15.9335 3.44346 16.5571C4.06704 17.162 4.90177 17.5005 5.77082 17.5005C6.64165 17.5005 7.47803 17.1606 8.10201 16.5534L10.2441 14.4113C10.5695 14.0859 11.0971 14.0859 11.4226 14.4113C11.748 14.7367 11.748 15.2644 11.4226 15.5898L9.26958 17.7428C8.33392 18.656 7.07826 19.1671 5.77082 19.1671C4.46338 19.1671 3.20776 18.656 2.2721 17.7428L2.85415 17.1464L2.25778 17.7284C1.34459 16.7928 0.833405 15.5372 0.833405 14.2297C0.833405 12.9223 1.34459 11.6667 2.25778 10.731L2.26485 10.7238L4.41073 8.57797C4.73617 8.25253 5.26381 8.25253 5.58924 8.57797ZM13.9226 7.25648C14.248 6.93105 14.248 6.40341 13.9226 6.07797C13.5972 5.75253 13.0695 5.75253 12.7441 6.07797L6.07742 12.7446C5.75198 13.0701 5.75198 13.5977 6.07742 13.9231C6.40286 14.2486 6.9305 14.2486 7.25593 13.9231L13.9226 7.25648Z"
    />
  </IconBase>
)

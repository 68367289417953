import { Flex, Text, useFeatureFlags } from '@mm/company-ui'
import React from 'react'
import { ActionAssigneeTagSelect } from './ActionAssigneeTagSelect'
import { ActionDatepicker } from './ActionDatepicker'
import { ActionMetadataContextTags } from './ActionMetadataContextTag'
import { ActionMetadataLinkTags } from './ActionMetadataLinkTags'
import { ActionStatusTagSelect } from './ActionStatusTagSelect'
import { ActionWaitingForTagSelect } from './ActionWaitingForTagSelect'
import { AddActionContextButton } from './AddActionContextButton'

export function ActionCollapsibleMetadata({ actionId }: { actionId: string }) {
  const { updateActionContext } = useFeatureFlags()
  return (
    <Flex row gap={1} wrap>
      <div data-targetid="status">
        <ActionStatusTagSelect actionId={actionId} />
      </div>
      <div data-targetid="assignee">
        <ActionDatepicker id={actionId} prefix={<Text>Due</Text>} showRescheduledPrefix redIfPastDue variant="tag" />
      </div>
      <div data-targetid="assignee">
        <ActionAssigneeTagSelect actionId={actionId} />
      </div>
      <div data-targetid="waitingFor">
        <ActionWaitingForTagSelect actionId={actionId} />
      </div>
      {/* <div data-targetid="privacy">
        <ActionPrivacyTag actionId={actionId} />
      </div> */}
      {updateActionContext ? (
        <>
          <ActionMetadataContextTags actionId={actionId} data-targetid="links" cached />
          <AddActionContextButton actionId={actionId} isTagButton />
        </>
      ) : (
        <ActionMetadataLinkTags actionId={actionId} data-targetid="links" />
      )}
    </Flex>
  )
}

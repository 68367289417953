import {
  CheckCircleFilledIcon,
  CheckCircleIcon,
  CheckSquareFilledIcon,
  CheckSquareIcon,
  CrossCircleFilledIcon,
  CrossSquareFilledIcon,
  ExclamationCircleIcon as BaseExclamationCircleIcon,
  ExclamationSquareIcon,
  IconComponent,
} from '@mm/company-ui-icons'
import React from 'react'

export const statusCheckboxValidValues = ['active', 'resolved', 'on-hold', 'canceled'] as const

export type StatusCheckboxValue = (typeof statusCheckboxValidValues)[number]

export type Shape = 'circle' | 'square'

const ExclamationCircleIcon: IconComponent = (props) => <BaseExclamationCircleIcon secondaryColor="white" {...props} />

export const iconByStatus: Record<Shape, Record<StatusCheckboxValue, IconComponent>> = {
  circle: {
    active: CheckCircleIcon,
    resolved: CheckCircleFilledIcon,
    'on-hold': ExclamationCircleIcon,
    canceled: CrossCircleFilledIcon,
  },
  square: {
    active: CheckSquareIcon,
    resolved: CheckSquareFilledIcon,
    'on-hold': ExclamationSquareIcon,
    canceled: CrossSquareFilledIcon,
  },
}

export const colorByStatus: Record<StatusCheckboxValue, string> = {
  active: 'background-strong',
  resolved: 'system-background-success',
  'on-hold': 'system-background-warning',
  canceled: 'text-medium',
}

export const hoverColorByStatus: Record<StatusCheckboxValue, string> = {
  active: 'system-background-success',
  resolved: 'system-background-success',
  'on-hold': 'system-background-warning',
  canceled: 'text-medium',
}

export const actionLabelByStatus: Record<StatusCheckboxValue, string> = {
  active: 'To do',
  resolved: 'Done',
  'on-hold': 'Blocked',
  canceled: 'Canceled',
}

export const topicLabelByStatus: Record<StatusCheckboxValue, string> = {
  active: 'Active',
  resolved: 'Resolved',
  'on-hold': 'Blocked',
  canceled: 'Canceled',
}

// Icon shadow filter
export const filter = 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))'

import { Editor, EditorEvents } from '@tiptap/react'
import { useEffect } from 'react'

type CallbackParams<N extends keyof EditorEvents> = EditorEvents[N] extends any[] ? EditorEvents[N] : [EditorEvents[N]]

export const useEditorEvent = <N extends keyof EditorEvents>(
  editor: Editor,
  eventName: N,
  handler: (...args: CallbackParams<N>) => void,
) => {
  useEffect(() => {
    editor.on(eventName, handler)
    return () => {
      editor.off(eventName, handler)
    }
  }, [editor, eventName, handler])
}

import { useEffect, useRef } from 'react'
import { WebrtcProvider } from 'y-webrtc'
import * as Y from 'yjs'

// usage of WebRTC provides low-latency real-time collaboration and cursors with no effort.
// however it has severe security implications when using public signalling servers without password protection:
// anyone can pretend to have access to a document just by providing its ID.
// password protection is a bit tricky though: for better security passwords must be rotated regularly and every time
// a user loses access to a document, otherwise they will still be able to connect to the document's WebRTC room
// TODO[MM-238] this must be secured by using private signalling servers with authentication
// TODO[MM-238] or migrating to a secure websocket provider

// Create the WebrtcProvider inside the useEffect hook and store it as a ref to prevent re-rendering. React guarentees
// that useEffect will not be fired if the dependencies havent changed, unlike useMemo.
export function useWebRtcProvider(yDoc: Y.Doc, documentId?: string): WebrtcProvider | undefined {
  const webRtcProvider = useRef<WebrtcProvider>()
  useEffect(() => {
    if (documentId != null && process.browser) {
      webRtcProvider.current = new WebrtcProvider(documentId, yDoc)
    }

    return () => {
      webRtcProvider.current?.disconnect()
      webRtcProvider.current?.destroy()
    }
  }, [yDoc, documentId])

  return webRtcProvider.current
}

import { useCallback, useEffect } from 'react'

// useBeforeUnload from react-use does not work for some reason ¯\_(ツ)_/¯
/**
 * Registers a beforeunload event handler
 * See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
 * and https://developer.mozilla.org/en-US/docs/web/api/beforeunloadevent
 */
export const useBeforeUnload = (enabled: boolean | (() => boolean) = true) => {
  const handler = useCallback(
    (event: WindowEventMap['beforeunload']) => {
      const effectivelyEnabled = typeof enabled === 'function' ? enabled() : enabled
      if (!effectivelyEnabled) return

      // the message is actually ignored, however according to the standards it must not be empty ¯\_(ツ)_/¯
      const message = 'Do you want to leave the site? Changes you made may not be saved.'
      // using all 3 ways to activate the dialog for best compatibility
      event.preventDefault()
      event.returnValue = message
      return message
    },
    [enabled],
  )

  useEffect(() => {
    if (enabled === false) {
      return
    }
    addEventListener('beforeunload', handler)
    return () => {
      removeEventListener('beforeunload', handler)
    }
  }, [enabled, handler])
}

import { Flex, ModalLabel } from '@mm/company-ui'
import { useId } from '@react-aria/utils'
import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { CreateDecisionData } from '../types'

export type CreateDecisionDeciderProps = {
  control: Control<CreateDecisionData>
}
export const CreateDecisionDecider = ({ control }: CreateDecisionDeciderProps) => {
  const labelId = useId()

  return (
    <Flex>
      <ModalLabel id={labelId} label="Decision maker" />
      <Controller
        name="decisionMaker"
        control={control}
        render={({ field }) => {
          return (
            <UserSelectWithCompanyMembers
              aria-labelledby={labelId}
              zIndexOverride={1000}
              value={field.value}
              onAdd={(value) => {
                field.onChange(value)
              }}
              withName
            />
          )
        }}
      />
    </Flex>
  )
}

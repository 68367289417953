import styled from '@emotion/styled'
import { Text, TextProps } from '@mm/company-ui'
import _ from 'lodash'
import { DateTime } from 'luxon'
import Link from 'next/link'
import React from 'react'
import { css } from 'theme-ui'

const StyledLink = styled('a')(
  css({
    cursor: 'pointer',
    color: 'inherit',
    textDecoration: 'none',
    ':hover': { textDecoration: 'underline' },
  }),
)

type ParentType = 'Action' | 'Goal' | 'GoodThing' | 'Meeting' | 'Topic' | 'Decision' | 'PrototypeFeedback' | 'Update'

type CreatedContextProps = {
  highlightLinks?: boolean
  createdAt: number
  parent?: {
    __typename: ParentType
    id: string
    title?: string | null
    descriptionText?: string | null
  }
  parentMeeting?: {
    __typename?: 'Meeting'
    id: string
    title: string
  } | null
  withoutLink?: boolean
  overflow?: TextProps['overflow']
  onClick?: () => void
}

function parentTypeToName(type: ParentType) {
  switch (type) {
    case 'Action':
      return 'Action'
    case 'Goal':
      return 'Goal'
    case 'GoodThing':
      return 'Good Things'
    case 'Meeting':
      return 'Meeting'
    case 'Topic':
      return 'Topic'
    case 'Decision':
      return 'Issue'
    case 'PrototypeFeedback':
      return 'Feedback'
    case 'Update':
      return 'Update'
    default:
      return ''
  }
}

export const CreatedContext = ({
  highlightLinks,
  createdAt,
  parent,
  parentMeeting,
  withoutLink,
  overflow = 'clip',
  onClick,
}: CreatedContextProps) => {
  const date = DateTime.fromMillis(createdAt)
  const formatted = date.toLocaleString({
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    year: date.year !== DateTime.now().year ? 'numeric' : undefined,
  })

  if (!parent) {
    return (
      <Text variant="small" color="text-light" onClick={onClick}>
        Created on {formatted}
      </Text>
    )
  }

  const pathname = (
    {
      Action: '/actions/[id]',
      Decision: '/decisions/[id]',
      Goal: '/goals/[id]',
      GoodThing: '/goodThings/[id]',
      Meeting: '/meetings/[id]',
      Topic: '/topics/[id]',
      PrototypeFeedback: '/feedback/[id]',
      Update: '/updates/[id]',
    } as const
  )[parent.__typename]

  // shortened parent title
  const title = parent.title && parent.title.length > 80 ? `${parent.title.slice(0, 80)}...` : parent?.title
  const titleWithPrefix = title
    ? `${parentTypeToName(parent.__typename)}: ${title}`
    : parentTypeToName(parent.__typename)

  const parentMeetingTitleWithPrefix = `Meeting: ${_.truncate(parentMeeting?.title || '', { length: 80 })}`

  return (
    <Text variant="small" color="text-light" overflow={overflow} onClick={onClick}>
      {parent.__typename === 'Action' ? 'Follow-up action created from ' : 'Created in '}
      {withoutLink ? (
        titleWithPrefix
      ) : (
        <Link passHref href={{ pathname, query: { id: parent.id } }}>
          <StyledLink
            sx={
              highlightLinks
                ? {
                    color: 'text-medium',
                    backgroundColor: 'background-light-alpha',
                    paddingX: 0.25,
                    borderRadius: 'default',
                  }
                : {}
            }
          >
            {titleWithPrefix}
          </StyledLink>
        </Link>
      )}
      {parent.descriptionText ? `: ${parent.descriptionText}` : ''}
      {parentMeeting ? ' in ' : ''}
      {parentMeeting ? (
        withoutLink ? (
          parentMeetingTitleWithPrefix
        ) : (
          <Link passHref href={{ pathname: '/meetings/[id]', query: { id: parentMeeting.id } }}>
            <StyledLink
              sx={
                highlightLinks
                  ? {
                      color: 'text-medium',
                      backgroundColor: 'background-light-alpha',
                      paddingX: 0.25,
                      borderRadius: 'default',
                    }
                  : {}
              }
            >
              {parentMeetingTitleWithPrefix}
            </StyledLink>
          </Link>
        )
      ) : (
        ''
      )}
      {` on ${formatted}`}
    </Text>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ExpandMaximizeIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="expand-maximize" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9002 1.99998C18.9002 1.70743 18.7606 1.44748 18.5444 1.2831L18.0003 1.09998C18.1363 1.1 18.2723 1.13068 18.3971 1.19201C18.2774 1.13309 18.1427 1.09999 18.0003 1.09998H17.9999H11.6002C11.1031 1.09998 10.7002 1.50292 10.7002 1.99998C10.7002 2.49703 11.1031 2.89998 11.6002 2.89998H15.8273L11.3637 7.36358C11.0122 7.71505 11.0122 8.2849 11.3637 8.63637C11.7152 8.98784 12.285 8.98784 12.6365 8.63637L17.1002 4.17267V8.39998C17.1002 8.89703 17.5031 9.29998 18.0002 9.29998C18.4973 9.29998 18.9002 8.89703 18.9002 8.39998V1.99998ZM8.63649 11.3636C8.98797 11.7151 8.98797 12.2849 8.63649 12.6364L4.17288 17.1H8.4001C8.89715 17.1 9.3001 17.5029 9.3001 18C9.3001 18.497 8.89715 18.9 8.4001 18.9H2.0001C1.87515 18.9 1.75615 18.8745 1.64801 18.8285C1.55199 18.7877 1.46161 18.7295 1.3817 18.6539C1.36954 18.6424 1.3577 18.6305 1.34619 18.6184C1.19364 18.4571 1.1001 18.2395 1.1001 18C1.1001 17.9999 1.1001 17.9999 1.1001 17.9998V11.6C1.1001 11.1029 1.50304 10.7 2.0001 10.7C2.49715 10.7 2.9001 11.1029 2.9001 11.6V15.8272L7.3637 11.3636C7.71517 11.0121 8.28502 11.0121 8.63649 11.3636Z"
    />
  </IconBase>
)

import { useEffect } from 'react'
import { useActiveCompanyId } from './useActiveCompanyId'

export const useSyncActiveCompanyWithResource = (resourceCompanyId?: string) => {
  const { activeCompanyId, setActiveCompanyId } = useActiveCompanyId()

  useEffect(() => {
    if (resourceCompanyId && resourceCompanyId !== activeCompanyId) {
      setActiveCompanyId(resourceCompanyId)
    }
  }, [resourceCompanyId, activeCompanyId, setActiveCompanyId])

  return
}

import { useModal } from '@mm/company-ui'
import React, { useMemo } from 'react'
import { DoneNotDoneModal, DoneNotDoneModalProps } from './components/DoneNotDoneModal'

type ContextValue = {
  show: (props: DoneNotDoneModalProps) => void
}

export const useDoneNotDone = (): ContextValue => {
  const { showModal } = useModal()
  return useMemo(
    () => ({
      show: (props) => {
        showModal(({ onRequestClose }) => <DoneNotDoneModal {...props} onRequestClose={onRequestClose} />)
      },
    }),
    [showModal],
  )
}

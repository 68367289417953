import { AvatarProps, Suspender } from '@mm/company-ui'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { GetDecisionMakerDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { useActiveUserRequired } from '../../users'
import { editDecisionMakerCommand } from '../commands/editDecisionMaker'
import { DecisionWarningRemoval } from './DecisionWarningRemoval'

export type DecisionMakerCellProps = {
  decisionId: string
  withName?: boolean
  cached?: boolean
  size?: AvatarProps['size']
}

export const DecisionMakerCell = ({ decisionId, withName, cached, size }: DecisionMakerCellProps) => {
  const router = useRouter()
  const [confirmUserId, setConfirmUserId] = useState('')
  const activeUser = useActiveUserRequired()
  const editDecisionMaker = useCommand(editDecisionMakerCommand)

  const { data, loading, error } = useQuery(GetDecisionMakerDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id: decisionId,
    },
  })

  const decision = data?.decision
  const decisionObserversIds = decision?.observers?.edges.map((edge) => edge.node.id) || []
  const decisionContributorsIds = decision?.contributors?.edges.map((edge) => edge.node.id) || []
  const activeUserId = activeUser.id

  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('decision or company users not found, id: ' + decisionId)
  }

  const handleSelect = (decisionMakerId: string) => {
    if (
      decisionMakerId !== activeUserId &&
      !decisionObserversIds.includes(activeUserId) &&
      !decisionContributorsIds.includes(activeUserId) &&
      decision.isPrivate
    ) {
      setConfirmUserId(decisionMakerId)
      return
    }

    setConfirmUserId('')
    void editDecisionMaker.execute({ decisionId: decisionId, decisionMakerId })
  }

  return (
    <>
      <UserSelectWithCompanyMembers
        aria-label="Decision Maker"
        value={decision?.decisionMaker.id}
        onAdd={handleSelect}
        withName={withName}
        targetProps={{ size }}
      />

      {confirmUserId && (
        <DecisionWarningRemoval
          loading={editDecisionMaker.loading}
          onCancel={() => {
            setConfirmUserId('')
          }}
          onConfirmation={() => {
            setConfirmUserId('')
            void editDecisionMaker.execute({ decisionId: decisionId, decisionMakerId: confirmUserId })
            void router.push('/decisions')
          }}
        />
      )}
    </>
  )
}

import { isGraphQLError } from '@mm/graphql-helpers/src'
import { AddDecisionCommentCommandDocument, MarkDecisionCommentCommandDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type AddDecisionCommentCommandValue = {
  decisionId: string
  htmlBody: string
  author: string
  onDecisionCommentAdded?: () => void
}

export const addDecisionCommentCommand: Command<AddDecisionCommentCommandValue> = {
  id: 'addDecisionCommentCommand',
  name: 'Add Decision Comment',
  canExecute: () => true,
  execute: async ({ htmlBody, author, decisionId, onDecisionCommentAdded }, { apolloClient }) => {
    const { data } = await apolloClient.mutate({
      mutation: AddDecisionCommentCommandDocument,
      variables: {
        decisionId,
        author,
        htmlBody,
      },
    })

    if (data?.createCommentOnDecision && isGraphQLError(data?.createCommentOnDecision)) {
      alert(`Add decision comment: ${data?.createCommentOnDecision.message ?? 'unknown error'}`)
      return
    }
    if (data?.createCommentOnDecision.__typename != 'Comment') {
      alert('Add decision comment: unknown error')
      return
    }

    const commentId = data?.createCommentOnDecision.id

    // TODO add optimistic response or use the capability execute here.(OS-1118)
    const { data: markDecisionData } = await apolloClient.mutate({
      mutation: MarkDecisionCommentCommandDocument,
      variables: {
        commentId,
        decisionId,
      },
    })

    if (markDecisionData?.markOrUnmarkDecision && isGraphQLError(markDecisionData?.markOrUnmarkDecision)) {
      alert(`Mark decision comment: ${markDecisionData?.markOrUnmarkDecision.message ?? 'unknown error'}`)
      return
    }
    if (markDecisionData?.markOrUnmarkDecision.__typename !== 'Decision') {
      alert('Mark decision comment: unknown error')
      return
    }
    onDecisionCommentAdded?.()
  },
}

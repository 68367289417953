import styled from '@emotion/styled'
import { FlyoutDivider, RadioButton, RadioGroup, Suspender } from '@mm/company-ui'
import React from 'react'
import { css } from 'theme-ui'
import { DecisionPrivacyMenuDocument, DecisionPrivacyMenuUpdatePrivacyDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'

export type DecisionPrivacyMenuProps = {
  decisionId: string

  cached?: boolean
}

export const DecisionPrivacyMenu = ({ decisionId, cached }: DecisionPrivacyMenuProps) => {
  const { data, loading, error } = useQuery(DecisionPrivacyMenuDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id: decisionId,
    },
  })

  const [updatePrivacy] = useMutation(DecisionPrivacyMenuUpdatePrivacyDocument, {
    optimisticResponse: ({ id, privacy }) =>
      ({
        updateDecision: {
          __typename: 'Decision',
          id: id,
          privacy,
        },
      } as const),
  })

  const decision = data?.decision

  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('decision not found, id: ' + decisionId)
  }

  return (
    <RadioGroup
      shape="tick"
      value={decision.privacy ?? undefined}
      onChange={(privacy) => {
        void updatePrivacy({
          variables: {
            id: decisionId,
            privacy,
          },
        })
      }}
      aria-label="Privacy level"
      sx={{ width: '350px' }}
    >
      <MenuItem value="PUBLIC" description={<>Visible to everyone at {decision.company.name}</>}>
        Public
      </MenuItem>
      <FlyoutDivider sx={{ my: 0 }} />
      <MenuItem
        value="PRIVATE"
        description="Visible to participants of this issue and participants of any meeting this issue is linked to"
      >
        Private
      </MenuItem>
    </RadioGroup>
  )
}

const MenuItem = styled(RadioButton)(
  css({
    padding: 2,
    ':hover': {
      bg: 'background-light',
    },
  }),
)

import React, { createContext, useContext } from 'react'

type ContextValue = {
  showRequestForComments: boolean
  decisionId: string
}

const UserMadeCommentContextContext = createContext<ContextValue>({ showRequestForComments: false, decisionId: '' })

type UserMadeContextContextProps = {
  children?: React.ReactNode
  value: ContextValue
}

export const UserMadeCommentContextProvider = ({
  children,
  value = { showRequestForComments: false, decisionId: '' },
}: UserMadeContextContextProps) => {
  return <UserMadeCommentContextContext.Provider value={value}>{children}</UserMadeCommentContextContext.Provider>
}

export const useShowRequestForComments = () => {
  return useContext(UserMadeCommentContextContext)
}

import { Editor } from '@tiptap/core'
import React from 'react'
import { Flex } from '../../Flex'
import { standardActions, StandardActions } from './standardActions'
import { ToolbarAction, ToolbarActionItem } from './ToolbarAction'

export type ToolbarItem = keyof StandardActions | ToolbarAction

export type SelectionToolbarProps = {
  editor: Editor
  items: ToolbarItem[][]
}

export const defaultInlineItems: ToolbarItem[][] = [['bold', 'italic', 'underline', 'link']]
export const defaultItems: ToolbarItem[][] = [['block'], ['bold', 'italic', 'underline', 'link']]

export function SelectionToolbar({ editor, items }: SelectionToolbarProps): React.ReactElement | null {
  const actions = items.map((item) =>
    item.map((item) => (typeof item === 'string' ? standardActions[item] : item)).map((factory) => factory(editor)),
  )
  const hasVisibleActions = actions.some((actionGroups) => actionGroups.some((action) => !action.hidden))
  if (!hasVisibleActions) {
    return null
  }

  return (
    <Flex
      row
      sx={{
        display: 'inline-flex',
        background: 'white',
        border: '1px solid',
        borderColor: 'border',
        borderRadius: 'default',
        boxShadow: 'default',
      }}
    >
      {actions.map((actionGroups, index) => (
        <Flex
          row
          key={index}
          align="center"
          sx={{ paddingX: 0.5, borderLeft: index !== 0 ? '1px solid' : '', borderColor: 'border' }}
        >
          {actionGroups.map((action, index) => (
            <ToolbarActionItem key={index} {...action} />
          ))}
        </Flex>
      ))}
    </Flex>
  )
}

import { Theme } from '@emotion/react'
import { FastTable, Flex, formatDateDisplay, Input, Text, useDebouncedValue, useFeatureFlags } from '@mm/company-ui'
import { LoadingIcon, SearchIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { MeetingTopicsArchiveDocument, MeetingTopicsArchiveRowDataFragment } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useContextualDrawers } from '../../navigation'
import { TopicStatusCheckbox } from '../../topics/components/TopicStatusCheckbox'
import { EmptyPlaceholder } from './EmptyPlaceholder'
import { MeetingTopicsArchiveBanner } from './MeetingTopicsArchiveBanner'

export type MeetingTopicsArchiveProps = {
  meetingId: string
}

const columns: FastTable.TableColumn<MeetingTopicsArchiveRowDataFragment>[] = [
  {
    width: 36,
    disableResizing: true,
    renderCell: ({ node: { id } }) => <TopicStatusCheckbox cached id={id} withDropdown={false} />,
  },
  {
    header: 'Title',
    renderCell: ({ node: { title }, highlights }) => {
      let display: React.ReactNode = title
      const titleHighlight = highlights?.find(({ path }) => path === 'title')
      if (titleHighlight != null) {
        const { spans } = titleHighlight
        display = spans.map(({ isHit, text }, idx) => (
          <Text bold={isHit} key={idx}>
            {text}
          </Text>
        ))
      }
      return <FastTable.TextCell>{display}</FastTable.TextCell>
    },
  },
  {
    header: 'Section',
    renderCell: ({ section }) => (
      <FastTable.TextCell textProps={{ color: section == null ? 'text-disabled' : undefined }}>
        {section == null ? '[deleted]' : section.title || '\u2014'}
      </FastTable.TextCell>
    ),
    width: 100,
    disableResizing: true,
  },
  {
    header: 'Resolve date',
    renderCell: ({ node: { statusUpdatedAt } }) => (
      <FastTable.TextCell>{statusUpdatedAt ? formatDateDisplay(statusUpdatedAt) : ''}</FastTable.TextCell>
    ),
    width: 100,
    disableResizing: true,
  },
]

const getRowId = ({ node }: MeetingTopicsArchiveRowDataFragment) => node.id

export const MeetingTopicsArchive = ({ meetingId }: MeetingTopicsArchiveProps) => {
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 500)
  const { hideTableHeaders } = useFeatureFlags()

  const searching = debouncedSearchQuery.trim() !== ''
  const { data, loading, fetchMore, client } = useQuery(MeetingTopicsArchiveDocument, {
    variables: {
      searchQuery: searching ? debouncedSearchQuery : null,
      meetingId,
    },
  })

  useEffect(
    () => () => {
      // cleanup search results once they are no longer needed
      const { cache } = client
      if (searching) {
        cache.evict({
          id: cache.identify({ __typename: 'Meeting', id: meetingId }),
          fieldName: 'archivedTopics',
          args: { searchQuery: debouncedSearchQuery },
        })
      }
    },
    [meetingId, searching, debouncedSearchQuery, client],
  )

  const hasNextPage = data?.meeting?.archivedTopics?.pageInfo.hasNextPage ?? false
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => {
      void fetchMore({
        variables: {
          after: data?.meeting?.archivedTopics?.pageInfo?.endCursor ?? '',
        },
      })
    },
  })

  const archivedTopics = data?.meeting?.archivedTopics?.edges

  const tableData: FastTable.TableRowGroup<MeetingTopicsArchiveRowDataFragment>[] = useMemo(() => {
    return _(archivedTopics)
      .filter(({ node }) => node.isArchived === true)
      .groupBy(({ node }) =>
        DateTime.fromMillis(node.statusUpdatedAt ?? 0)
          .startOf('week')
          .toMillis(),
      )
      .entries()
      .orderBy(([week]) => +week, 'desc')
      .map(([week, rows]) => {
        const title =
          +week === DateTime.now().startOf('week').toMillis()
            ? 'This week'
            : +week === DateTime.now().startOf('week').minus({ weeks: 1 }).toMillis()
            ? 'Last week'
            : `${formatDateDisplay(+week)} - ${formatDateDisplay(DateTime.fromMillis(+week).endOf('week'))}`
        return {
          id: week,
          title,
          rows,
        }
      })
      .value()
  }, [archivedTopics])

  const { showDrawer } = useContextualDrawers()

  return (
    <Flex column sx={{ height: '100%' }}>
      <Input
        variant="underline"
        prefix={<SearchIcon />}
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder="Search archive..."
        sx={{
          // TODO migrate to SearchBox component
          px: 3,
          boxShadow: (theme) => `inset 0 -1px 0 0 ${(theme as Theme).colors['border']} !important`,
          fontSize: 1,
          fontFamily: 'body',
        }}
      />
      <Flex column grow sx={{ overflowY: 'auto' }}>
        <FastTable.Table
          stickyHeader
          extraHeader={<MeetingTopicsArchiveBanner />}
          noBorders
          noHeaders={hideTableHeaders}
          columns={columns}
          data={tableData}
          getRowId={getRowId}
          onRowClick={(row, event) => {
            showDrawer('topic', row.node.id, event)
          }}
        />
        {tableData.length === 0 &&
          (loading ? (
            <Flex grow align="center" justify="center">
              <LoadingIcon />
            </Flex>
          ) : searching ? (
            <div sx={{ paddingLeft: 3, paddingY: 1 }}>
              <Text variant="small" color="text-light">
                No results found.
              </Text>
            </div>
          ) : (
            <Flex grow align="center" justify="center" sx={{ paddingBottom: 2 }}>
              <EmptyPlaceholder>
                No topics in the archive, yet. Resolved topics will be archived automatically every 12 hours.
              </EmptyPlaceholder>
            </Flex>
          ))}
        {hasNextPage && (
          <Flex justify="center" align="center" ref={sentryRef} sx={{ pb: 2 }}>
            <LoadingIcon />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

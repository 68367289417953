import { useFeatureFlags } from '@mm/company-ui/src'
import React from 'react'
import { AddTopicCommentBox, TopicCommentsList } from '../../comments'

export type TopicCommentsProps = {
  topicId: string
  fixedComposer?: boolean
  order?: 'newest' | 'oldest'
  fixedAndScroll?: boolean
}

export const TopicComments = ({
  topicId,
  fixedComposer = true,
  order = 'oldest',
  fixedAndScroll = true,
}: TopicCommentsProps) => {
  const { flipComments } = useFeatureFlags()

  return (
    <TopicCommentsList
      topicId={topicId}
      order={order}
      composer={!flipComments ? <AddTopicCommentBox topicId={topicId} /> : null}
      fixedAndScroll={fixedAndScroll}
      fixedComposer={fixedComposer}
    />
  )
}

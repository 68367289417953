import {
  EditPrototypeFeedbackPrivacyCommandDocument,
  PrototypeFeedbackPrivacyStatus,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditFeedbackPrivacyCommandValue = {
  prototypeFeedbackId: string
  privacy: PrototypeFeedbackPrivacyStatus
}

export const editFeedbackPrivacyCommand: Command<EditFeedbackPrivacyCommandValue> = {
  id: 'editFeedbackPrivacyCommand',
  name: 'Edit Feedback Privacy',
  canExecute: () => true,
  execute: async ({ prototypeFeedbackId, privacy }, { apolloClient }) => {
    await apolloClient.mutate({
      mutation: EditPrototypeFeedbackPrivacyCommandDocument,
      variables: {
        prototypeFeedbackId,
        privacy,
      },
      optimisticResponse: {
        updatePrototypeFeedback: {
          __typename: 'PrototypeFeedback',
          id: prototypeFeedbackId,
          privacy,
        },
      },
    })

    return
  },
}

import { PanelMetadataItem, Tag, Flex } from '@mm/company-ui'
import { ArrowBackRewindResetIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import { Route } from 'nextjs-routes'
import React from 'react'
import { RemovableTag } from '../actions/components/ActionMetadataContextTag'
import { TagLink } from './TagLink'

export type PanelMetadataItemChipsProps = {
  title: string
  icon?: React.ReactElement
  chips: Array<{ id: string; label: string; href: Route; __typename?: string }>
  hasNextPage?: boolean
  onLoadMore?: () => void
  onRemove?: (id: string) => void
  isRemovable?: boolean
}

export const PanelMetadataItemChips = ({
  title,
  icon,
  chips,
  hasNextPage,
  onLoadMore,
  onRemove,
  isRemovable,
}: PanelMetadataItemChipsProps) => {
  return (
    <PanelMetadataItem title={title} icon={icon}>
      <Flex
        gap={0.5}
        sx={{ flexWrap: 'wrap', padding: 0, marginY: 0.5 }}
        as="ul"
        aria-labelledby={title}
        aria-label={title}
      >
        {chips.map(({ id, label, href, __typename }) =>
          isRemovable ? (
            <RemovableTag key={id} id={id} type={__typename} onRemove={onRemove}>
              <TagLink sx={{ background: 'transparent' }} href={href}>
                {_.truncate(label, { length: 50 })}
              </TagLink>
            </RemovableTag>
          ) : (
            <TagLink key={id} href={href}>
              {_.truncate(label, { length: 50 })}
            </TagLink>
          ),
        )}
        {hasNextPage ? (
          <Tag onClick={onLoadMore}>
            <ArrowBackRewindResetIcon />
            Show more
          </Tag>
        ) : null}
      </Flex>
    </PanelMetadataItem>
  )
}

import styled from '@emotion/styled'
import { Flex, variant, Text } from '@mm/company-ui/src'
import React, { useEffect, useState } from 'react'
import { css } from 'theme-ui'

const ratings = [1, 2, 3, 4, 5]
const DEFAULT_DOT_COLOR = { backgroundColor: 'background-light', color: 'text' }

type DotColor = {
  backgroundColor: string
  color: string
  borderColor?: string
  border?: string
}
const getDotColorHovered = (activeRating: number): DotColor => {
  switch (activeRating) {
    case 1:
      return {
        backgroundColor: 'system-background-warning-light',
        color: 'system-text-warning',
        border: '1px solid',
        borderColor: 'system-border-warning',
      }
    case 2:
      return {
        backgroundColor: 'system-background-alert-light',
        color: 'system-text-alert',
        border: '1px solid',
        borderColor: 'system-border-alert',
      }
    default:
      return {
        backgroundColor: 'accent-background-light',
        color: 'text',
        border: '1px solid',
        borderColor: 'accent-border',
      }
  }
}
const getDotColorNormal = (activeRating: number): DotColor => {
  switch (activeRating) {
    case 1:
      return {
        backgroundColor: 'system-background-warning',
        color: 'text-inverted',
      }
    case 2:
      return {
        backgroundColor: 'system-background-alert-strong',
        color: 'text',
      }
    default:
      return {
        backgroundColor: 'accent-background',
        color: 'text-inverted',
      }
  }
}

const getDotColor = (isHovered = false, dotValue: number, activeRating?: number): DotColor => {
  if (!activeRating || dotValue > activeRating) return DEFAULT_DOT_COLOR

  return isHovered ? getDotColorHovered(activeRating) : getDotColorNormal(activeRating)
}

const Dot = styled(Flex)<{ isHovered?: boolean; activeRating?: number; value: number; variant?: 'small' }>(
  ({ isHovered, value, activeRating }) =>
    css({
      ...getDotColor(isHovered, value, activeRating),
      borderRadius: '24px',
      width: 4,
      height: 4,
      gap: 0.5,
      padding: 0.5,
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    }),
  variant({
    prop: 'variant',
    variants: {
      small: {
        width: 1,
        height: 1,
      },
    },
  }),
)

export const RatingDotsSummary = ({ score, className }: { score: number; className?: string }) => {
  return (
    <Flex align="center" gap={1} className={className}>
      <div>
        <Text bold>
          Rating: {score}/{ratings[ratings.length - 1]}
        </Text>
      </div>

      <ReadOnlyRatingDots score={score} />
    </Flex>
  )
}

export const RatingDotsInlineSummary = ({ score, className }: { score: number; className?: string }) => {
  return (
    <Flex align="center" gap={1} className={className}>
      <div>
        <Text variant="small">{score}</Text>
      </div>
      <ReadOnlyRatingDots score={score} />
    </Flex>
  )
}

const ReadOnlyRatingDots = ({ score }: { score: number }) => {
  return (
    <Flex gap={0.5}>
      {ratings.map((rating) => (
        <Dot value={rating} variant="small" activeRating={score} key={rating} />
      ))}
    </Flex>
  )
}

const getRatingDescription = (rating: number | undefined) => {
  switch (rating) {
    case 1:
      return 'Fails to meet expectations'
    case 2:
      return 'Meet some but not all expectations'
    case 3:
      return 'Meets all Expectations'
    case 4:
      return 'Exceeds some expectations(meets all)'
    case 5:
      return 'Exceeds all expectations'
    default:
      return ''
  }
}

export interface RatingDotProps {
  score?: number
  className?: string
  onRatingSelection?: (rating: number) => void
}
export const RatingDots = ({ score, className, onRatingSelection }: RatingDotProps) => {
  const [hoveredRating, setHoveredRating] = useState<number | undefined>()
  const [selectedRating, setSelectedRating] = useState<number | undefined>()

  useEffect(() => {
    setSelectedRating(score)
  }, [score])

  return (
    <Flex justify="space-between" align="center" className={className}>
      <Flex
        gap={2}
        onMouseLeave={() => {
          setHoveredRating(undefined)
        }}
      >
        {ratings.map((rating) => (
          <Dot
            value={rating}
            isHovered={!!hoveredRating}
            activeRating={hoveredRating || selectedRating}
            data-testid={`RatingDot-${rating}`}
            onMouseEnter={() => {
              setHoveredRating(rating)
            }}
            onClick={() => {
              setHoveredRating(undefined)
              setSelectedRating(rating)
              onRatingSelection?.(rating)
            }}
            key={rating}
          >
            {rating}
          </Dot>
        ))}
      </Flex>
      {(hoveredRating || selectedRating) && (
        <div data-testid="Rating-description">
          {hoveredRating || selectedRating} = {getRatingDescription(hoveredRating || selectedRating)}
        </div>
      )}
    </Flex>
  )
}

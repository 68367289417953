import styled from '@emotion/styled'
import {
  Avatar,
  Editable,
  EditableRef,
  Flex,
  formatDateDisplay,
  formatUserDisplayName,
  Suspender,
  Text,
  useFeatureFlags,
} from '@mm/company-ui'
import { DecisionIcon } from '@mm/company-ui-icons'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { css } from 'theme-ui'
import { CommentViewerDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useGetDecidedComments } from '../../decisions/DecidedCommentContext'
import { useComplexToolbar } from '../../editor/hooks/useComplexToolbar'
import { CommentEmojiReactions } from './CommentEmojiReactions'
import { CommentMenu, CommentMenuProps } from './CommentMenu'

export type CommentViewerProps = {
  id: string
  showDecisionMark?: boolean

  onEdit?: () => void
  cached?: boolean
}

export const CommentViewer = ({ id, onEdit, cached, showDecisionMark }: CommentViewerProps) => {
  const { quickDecisions, decisionBoxInDrawerView } = useFeatureFlags()
  const items = useComplexToolbar()
  const [decidedComments] = useGetDecidedComments()
  const { data, loading, error } = useQuery(CommentViewerDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })

  const [deletionMenuVisible, setDeletionMenuVisible] = useState(false)
  const [submenuVisible, setSubmenuVisible] = useState(false)
  const editableRef = useRef<EditableRef>(null)

  const comment = data?.comment

  useEffect(() => {
    if (comment?.htmlBody != null) {
      editableRef.current?.commands.setContent(comment.htmlBody)
    }
  }, [comment])

  const matchedDecidedComment = useMemo(() => {
    if (!showDecisionMark && decisionBoxInDrawerView) {
      return null
    }
    return decidedComments?.find((decidedComment) => decidedComment.comment.id === id)
  }, [decidedComments, id, showDecisionMark, decisionBoxInDrawerView])

  if (comment == null) {
    if (loading) {
      return <Suspender />
    }
    if (showDecisionMark) return null
    throw error || new Error('Comment not found')
  }

  const createdAt = DateTime.fromMillis(comment.createdAt)

  return (
    <CommentContainer
      deleting={deletionMenuVisible}
      isDecision={matchedDecidedComment != null}
      submenuVisible={submenuVisible}
      data-testid="CommentViewer"
    >
      <div sx={{ position: 'relative' }}>
        <StyledCommentMenu
          cached
          id={id}
          onEditComment={onEdit}
          onDeletionMenuVisibilityChange={setDeletionMenuVisible}
          onSubmenuVisibilityChange={setSubmenuVisible}
        />
      </div>
      <div sx={{ padding: 1 }}>
        <Flex direction="column" gap={1}>
          {matchedDecidedComment?.user && matchedDecidedComment?.decidedAt && (
            <Flex
              gap={1}
              sx={{
                borderRadius: '8px',
                backgroundColor: 'accent-background-medium-light',
                padding: 1,
                cursor: quickDecisions ? 'pointer' : undefined,
              }}
              onClick={() => {
                if (quickDecisions && matchedDecidedComment != null) {
                  void onEdit?.()
                }
              }}
            >
              <Text variant="small" bold color="accent-text-medium">
                <Flex align="center" gap={0.5}>
                  <DecisionIcon />
                  {`Marked as decision by ${matchedDecidedComment.user.firstName}`}
                </Flex>
              </Text>
              <Text variant="small" sx={{ color: 'accent-text' }}>
                {formatDateDisplay(matchedDecidedComment.decidedAt, 'MED_WITHOUT_YEAR')}
              </Text>
            </Flex>
          )}
          <Flex row align="center" gap={1.5}>
            <Avatar name={formatUserDisplayName(comment.author)} />
            <Flex row gap={2} align="center">
              <Text bold>{formatUserDisplayName(comment.author)}</Text>
              <Text variant="small" color="text-light" title={formatDateDisplay(createdAt, 'MED_WITH_WEEKDAY')}>
                {formatDateDisplay(createdAt, 'RELATIVE')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex column sx={{ pl: '36px', pt: 0.5 }} gap={1}>
          <Editable
            ref={editableRef}
            initialValue={comment.htmlBody}
            mode="compact"
            editable={false}
            selectionToolbar={items}
          />
          <CommentEmojiReactions id={id} cached />
        </Flex>
      </div>
    </CommentContainer>
  )
}

const getCommentContainerBackgroundStyles = ({ deleting = false, submenuVisible = false, isDecision = false }) => {
  if (deleting) {
    return 'system-background-warning-light'
  } else if (isDecision) {
    return 'accent-background-light'
  } else if (submenuVisible) {
    return 'background-light'
  } else {
    return
  }
}

const StyledCommentMenu = styled((props: CommentMenuProps) => <CommentMenu {...props} />)(
  css({
    position: 'absolute',
    right: 1,
    top: 0,
    opacity: 0,
    transform: 'translateY(-50%)',
    zIndex: 1,
  }),
)

const CommentContainer = styled.div<{ deleting?: boolean; submenuVisible?: boolean; isDecision?: boolean }>(
  ({ theme, deleting, submenuVisible, isDecision }) =>
    css({
      borderRadius: '8px',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      [`:hover${deleting || submenuVisible ? ',&' : ''} ${StyledCommentMenu}`]: {
        opacity: 1,
        transition: `opacity ${theme.transition.default}`,
      },
      ':hover': {
        bg: isDecision ? 'accent-background-light' : 'background-light',
      },
      bg: getCommentContainerBackgroundStyles({ deleting, submenuVisible, isDecision }),
    }),
)

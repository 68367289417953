import React from 'react'
import { UserDisplay, formatUserDisplayName } from '../../helpers/formatUserDisplayName'
import { Avatar, AvatarProps } from '../Avatar'
import { Flex } from '../Flex'
import { Text, TextProps } from '../Text'

export type UserCellProps = {
  user: UserDisplay
  textProps?: TextProps
  showText?: boolean
  showAvatar?: boolean
  size?: AvatarProps['size']
  className?: string
}

export const UserCell = ({ user, textProps, showText, size, className, showAvatar = true }: UserCellProps) => {
  return (
    <Flex row align="center" gap={1.5} className={className}>
      {showAvatar && <Avatar size={size} name={formatUserDisplayName(user)} outlineOnHover />}
      {showText && (
        <Text overflow="ellipsis" {...textProps} sx={{ py: 1.5, ...textProps?.sx }}>
          {formatUserDisplayName(user)}
        </Text>
      )}
    </Flex>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CalendarIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="calendar" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.62422 0.999976C7.62422 0.502919 7.22127 0.0999756 6.72422 0.0999756C6.22716 0.0999756 5.82422 0.502919 5.82422 0.999976V2.59998H3.40911C1.91794 2.59998 0.709106 3.80881 0.709106 5.29998V16.7C0.709106 18.1911 1.91794 19.4 3.40911 19.4H16.5909C18.0821 19.4 19.2909 18.1911 19.2909 16.7V7.33811V5.29998C19.2909 3.80881 18.0821 2.59998 16.5909 2.59998H14.2909V0.999976C14.2909 0.502919 13.8879 0.0999756 13.3909 0.0999756C12.8938 0.0999756 12.4909 0.502919 12.4909 0.999976V2.59998H7.62422V0.999976ZM13.3909 4.39998H6.72422L3.40911 4.39998C2.91205 4.39998 2.50911 4.80292 2.50911 5.29998V6.43811H17.4909V5.29998C17.4909 4.80292 17.0879 4.39998 16.5909 4.39998L13.3909 4.39998ZM17.4909 8.23811H2.50911V16.7C2.50911 17.197 2.91205 17.6 3.40911 17.6H16.5909C17.0879 17.6 17.4909 17.197 17.4909 16.7V8.23811ZM5.05756 11.5C5.05756 10.6716 5.72913 10 6.55756 10C7.38598 10 8.05756 10.6716 8.05756 11.5C8.05756 12.3284 7.38598 13 6.55756 13C5.72913 13 5.05756 12.3284 5.05756 11.5Z"
    />
  </IconBase>
)

import { closedGoalStatuses, openActionStatuses, openGoalStatuses } from '@mm/common'
import { Flex, NotFoundBanner, Suspender, TabBar, Text, formatDateDisplay, formatUserDisplayName } from '@mm/company-ui'
import _ from 'lodash'
import { DateTime } from 'luxon'
import Link from 'next/link'
import React, { useState } from 'react'
import { UserViewDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../../modules/apollo'
import { useActiveCompanyId } from '../../companies'
import { CloseDrawerButton } from '../../components'
import { ActionRow } from './ActionRow'
import { GoalRow } from './GoalRow'
import { UpdateListTextItem } from './UpdatesListTextItem'

export type UserViewProps = {
  userId: string
  inDrawer?: boolean
}

export function UserView({ userId, inDrawer }: UserViewProps) {
  const [activeTab, setActiveTab] = useState<'updates' | 'goals' | 'actions'>('updates')
  const { activeCompanyId } = useActiveCompanyId()
  const { data, loading } = useQuery(UserViewDocument, {
    variables: {
      id: userId,
      companyId: activeCompanyId,
      status: openActionStatuses,
    },
  })

  const [thisWeek] = useState(() => DateTime.now().startOf('week'))
  const user = data?.user
  const actions = data?.user?.actions?.edges.map(({ node }) => node) || []
  const groups = _(actions)
    // .filter(({ status }) => openActionStatuses.includes(status))
    .sortBy((action) => action.dueAt ?? action.createdAt)
    .groupBy((action) => {
      const week = DateTime.fromMillis(action.dueAt ?? action.createdAt).startOf('week')
      return (week > thisWeek ? week : thisWeek).toISODate()
    })
    .thru((groups) => ({
      // [thisWeek.toISODate()]: [],
      ...groups,
    }))
    .map((rows, weekISO) => {
      const week = DateTime.fromISO(weekISO)

      return {
        id: weekISO,
        title: formatDateDisplay(week),
        actions: rows,
      }
    })
    .value()

  const goals = data?.user?.goals?.edges.map(({ node }) => node) || []
  const goalGroups = _(goals)
    .filter(({ status }) => openGoalStatuses.includes(status))
    .groupBy((goal) => {
      return closedGoalStatuses.includes(goal.status) ? 'Complete' : 'Active'
    })
    .thru((groups) => ({
      Active: [],
      // Complete: [],
      ...groups,
    }))
    .map((items: typeof goals, title) => {
      return {
        id: title,
        title: title,
        goals: _.orderBy(items, ['title']),
      }
    })
    .value()

  const updates = data?.user?.updates?.edges.map(({ node }) => node) || []

  if (user == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return (
    <>
      {inDrawer ? (
        <Flex row sx={{ height: 7, gap: 1, paddingX: 2.5 }} align="center">
          <CloseDrawerButton resourceType="accountability/team" />
        </Flex>
      ) : (
        <Flex row sx={{ height: 7, gap: 1, paddingX: 2.5 }} align="center">
          <Link href="/accountability/team">
            <Text
              color="text-light"
              variant="small"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Team
            </Text>
          </Link>
          <Text color="text-light">›</Text>
          <Text color="text-light" variant="small" sx={{ maxWidth: 150 }} overflow="ellipsis">
            {formatUserDisplayName(user)}
          </Text>
        </Flex>
      )}

      <div sx={{ width: '100%', maxWidth: '800px', paddingX: 2, marginX: 'auto' }}>
        <Flex column gap={1} sx={{ paddingTop: 1, paddingBottom: 2 }}>
          <Text variant="h4">{formatUserDisplayName(user)}</Text>
          <Text color="text-light">{user.email}</Text>
        </Flex>

        <Flex sx={{ borderBottom: '1px solid', borderBottomColor: 'border' }}>
          <TabBar
            activeTabKey={activeTab}
            onTabChange={setActiveTab}
            tabs={[
              {
                label: 'Updates',
                key: 'updates',
              },
              {
                label: 'Goals',
                key: 'goals',
              },
              {
                label: 'Actions',
                key: 'actions',
              },
            ]}
          />
        </Flex>

        {activeTab === 'actions' ? (
          <Flex
            column
            gap={1}
            sx={{
              paddingTop: 3,
              paddingBottom: 3,
            }}
          >
            {/* <Text bold>Actions</Text> */}
            {groups.map((group) => (
              <div key={group.id} sx={{ paddingBottom: 1 }}>
                <Text color="text-light" bold sx={{ display: 'block', paddingBottom: 1 }}>
                  {group.title}
                </Text>

                <div sx={{ marginX: -1 }}>
                  {group.actions.map((action) => (
                    <ActionRow key={action.id} action={action} />
                  ))}
                </div>
              </div>
            ))}
          </Flex>
        ) : null}

        {activeTab === 'goals' ? (
          <Flex
            column
            gap={1}
            sx={{
              paddingTop: 3,
              paddingBottom: 3,
            }}
          >
            {/* <Text bold>Goals</Text> */}
            {goalGroups.map((group) => (
              <div key={group.id} sx={{ paddingBottom: 1 }}>
                <Text color="text-light" bold sx={{ display: 'block', paddingBottom: 1 }}>
                  {group.title}
                </Text>

                <div sx={{ marginX: -1 }}>
                  {group.goals.map((goal) => (
                    <GoalRow key={goal.id} goal={goal} />
                  ))}
                </div>
              </div>
            ))}
          </Flex>
        ) : null}

        {activeTab === 'updates' ? (
          <Flex
            column
            gap={6}
            sx={{
              paddingTop: 3,
              paddingBottom: 3,
            }}
          >
            {/* <Text bold>Updates</Text> */}
            {updates.map((update) => (
              <div key={update.id} sx={{ paddingBottom: 1 }}>
                <UpdateListTextItem update={update} />
              </div>
            ))}
            {updates.length === 0 ? <Text color="text-disabled">No Updates</Text> : null}
          </Flex>
        ) : null}
      </div>
    </>
  )
}

import { openGoalStatuses } from '@mm/common'
import {
  Button,
  ButtonGroup,
  FastTable,
  Flex,
  formatDateDisplay,
  Suspender,
  Tag,
  Text,
  toast,
  Toggler,
  useFeatureFlags,
} from '@mm/company-ui'
import { AddIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { UserGoalsDashboardDocument, UserGoalsDashboardRowDataFragment } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { useActiveCompanyId } from '../../companies'
import { useMeetingContext } from '../../meetings'
import { useContextualDrawers, useRegisterDrawerNavigationProvider } from '../../navigation'
import { editGoalStatusCommand } from '../commands/editGoalStatusCommand'
import { useSetGoalPriority } from '../hooks/useSetGoalPriority'
import { AddGoalRow } from './AddGoalRow'
import { EmptyGoals } from './EmptyGoals'
import { GoalMoreMenu } from './GoalMoreMenu'
import { GoalPrivacyTag } from './GoalPrivacyTag'
import { GoalRowContext } from './GoalRowContext'
import { GoalStatusPicker } from './GoalStatusPicker'

export type UserGoalsDashboardProps = {
  userId: string
  groupBy?: 'status' | 'quarter' | 'active'
  showAddRow?: boolean
  showCompleted?: boolean
  hasBorders?: boolean
  decorateRow?: (children: React.ReactElement, row: UserGoalsDashboardRowDataFragment) => React.ReactElement
  overrideColumns?: Array<FastTable.TableColumn<UserGoalsDashboardRowDataFragment>>
}

type RowGroup = FastTable.TableRowGroup<UserGoalsDashboardRowDataFragment> & {
  renderEmptyPlaceholder?: () => React.ReactNode
  defaultDueDate?: Date
}

const getRowId = ({ id }: UserGoalsDashboardRowDataFragment) => id

export const UserGoalsDashboard = ({
  userId,
  groupBy = 'status',
  showCompleted = false,
  showAddRow = true,
  hasBorders = false,
  decorateRow,
  overrideColumns,
}: UserGoalsDashboardProps) => {
  const { goalRowContext, hideTableHeaders, tableRowDetails, prioritizeGoals, showPrivacyInTable } = useFeatureFlags()
  const meeting = useMeetingContext()

  const sortGoals = useCallback(
    (goals: UserGoalsDashboardRowDataFragment[]) =>
      prioritizeGoals
        ? _.orderBy(goals, [
            ({ status }) => (openGoalStatuses.includes(status) ? 0 : 1), // open statuses first
            'priority', // then ordered by priority
          ])
        : _.orderBy(goals, [
            ({ status }) => (openGoalStatuses.includes(status) ? 0 : 1), // open statuses first
            'title', // then ordered by title
          ]),
    [prioritizeGoals],
  )

  const { activeCompanyId } = useActiveCompanyId()
  const { data, loading, error } = useQuery(UserGoalsDashboardDocument, {
    variables: {
      userId,
      companyId: activeCompanyId,
      meetingId: meeting?.id,
    },
  })

  const columns = useMemo<FastTable.TableColumn<UserGoalsDashboardRowDataFragment>[]>(
    () =>
      overrideColumns ?? [
        ...(prioritizeGoals
          ? [
              {
                header: 'Status',
                renderCell: ({ priority }: UserGoalsDashboardRowDataFragment) => {
                  return priority != undefined ? (
                    <Tag variant="flat" data-clickable="true" sx={{ fontWeight: 500, fontSize: 1 }}>
                      #{priority + 1}
                    </Tag>
                  ) : null
                },
                width: 48,
                disableResizing: true,
              },
            ]
          : []),
        {
          renderCell: ({ id, titleHtml, dueAt }) => {
            const titleCell = <FastTable.RichTextCell value={titleHtml} forceSingleLine={false} />
            return goalRowContext ? (
              <Flex column shrink sx={{ paddingY: 1.5, width: '100%' }} data-clickable="true">
                {titleCell}
                <GoalRowContext goalId={id} dueAt={dueAt} />
              </Flex>
            ) : (
              titleCell
            )
          },
        },
        {
          header: 'Status',
          renderCell: ({ id }) => {
            if (tableRowDetails) {
              return (
                <Flex justify="flex-end" sx={{ width: '100%' }}>
                  <GoalStatusPicker goalId={id} cached />
                </Flex>
              )
            }
            return goalRowContext ? (
              <Flex column sx={{ paddingY: 1, height: '100%' }}>
                <GoalStatusPicker goalId={id} cached />
              </Flex>
            ) : (
              <GoalStatusPicker goalId={id} cached />
            )
          },
          width: 150,
          disableResizing: true,
        },

        ...(showPrivacyInTable
          ? [
              {
                header: 'Access',
                renderCell: ({ id }: UserGoalsDashboardRowDataFragment) => (
                  <GoalPrivacyTag goalId={id} sx={{ background: 'none' }} cached />
                ),
                disableResizing: true,
                width: 101,
              },
            ]
          : []),

        ...(!goalRowContext
          ? [
              {
                header: 'Due',
                renderCell: ({ dueAt }: UserGoalsDashboardRowDataFragment) => (
                  <FastTable.TextCell>{formatDateDisplay(dueAt)}</FastTable.TextCell>
                ),
                width: 100,
                disableResizing: true,
              },
            ]
          : []),
        {
          header: 'DRI',
          renderCell: ({ assignee }) =>
            goalRowContext && !tableRowDetails ? (
              <Flex column sx={{ paddingY: 1, height: '100%' }}>
                {assignee && <FastTable.UserCell user={assignee} />}
              </Flex>
            ) : (
              assignee && <FastTable.UserCell user={assignee} size={tableRowDetails ? 'large' : undefined} />
            ),
          width: tableRowDetails ? 32 : 24,
          disableResizing: true,
        },
      ],
    [overrideColumns, prioritizeGoals, showPrivacyInTable, goalRowContext, tableRowDetails],
  )

  const tableData = useMemo<RowGroup[]>(() => {
    if (data?.user?.goals == null) {
      return []
    }

    switch (groupBy) {
      case 'active': {
        const [openGoals] = _(data.user.goals.edges)
          .map(({ node }) => node)
          .partition((goal) => openGoalStatuses.includes(goal.status))
          .value()
        return [
          {
            id: 'open',
            title: 'Open',
            hideTitle: true,
            rows: sortGoals(openGoals),
            renderEmptyPlaceholder: () => <EmptyGoals />,
          },
        ]
      }
      case 'status': {
        const [openGoals, doneGoals] = _(data.user.goals.edges)
          .map(({ node }) => node)
          .partition((goal) => openGoalStatuses.includes(goal.status))
          .value()
        return [
          {
            id: 'open',
            title: 'Open',
            rows: sortGoals(openGoals),
            renderEmptyPlaceholder: () => <EmptyGoals />,
          },
          {
            id: 'done',
            title: 'Completed',
            rows: sortGoals(doneGoals),
          },
        ]
      }
      case 'quarter': {
        const now = DateTime.now()
        const goalsByQuarter = _(data.user.goals.edges)
          .map(({ node }) => node)
          .filter(({ status }) => showCompleted || openGoalStatuses.includes(status))
          .groupBy(({ dueAt }) => DateTime.fromMillis(dueAt).startOf('quarter').toMillis())
          .value()
        const defaultGroups = _(_.range(0, 4))
          .keyBy((quarter) => now.plus({ quarter }).startOf('quarter').toMillis())
          .mapValues(() => [])
          .value()
        return _({ ...defaultGroups, ...goalsByQuarter })
          .entries()
          .orderBy(0, 'asc')
          .map(([timestamp, rows]) => {
            const date = DateTime.fromMillis(parseInt(timestamp))
            const thisYear = date.year === now.year
            return {
              id: `${date.toMillis()}`,
              title: thisYear ? date.toFormat("'Q'q") : date.toFormat("yy'Q'q"),
              rows: sortGoals(rows),
              defaultDueDate: date.endOf('quarter').toJSDate(),
              renderEmptyPlaceholder: () => (
                <div sx={{ pl: 6 }}>
                  <Text variant="small" color="text-disabled">
                    No goals for this period
                  </Text>
                </div>
              ),
            }
          })
          .value()
      }
    }
  }, [data, groupBy, showCompleted, sortGoals])

  const { showDrawer } = useContextualDrawers()
  useRegisterDrawerNavigationProvider(
    'goal',
    useCallback(
      (id) => {
        const group = tableData.find((group) => group.rows?.some((row) => row.id === id))
        if (group?.rows == null) return null

        const itemIndex = group.rows.findIndex((row) => row.id === id)

        const prevItem = group.rows[itemIndex - 1]
        const nextItem = group.rows[itemIndex + 1]

        return {
          itemIndex,
          hasMoreItems: false,
          totalItemsCount: group.rows.length,
          displayNext:
            nextItem &&
            (() => {
              showDrawer('goal', nextItem.id)
            }),
          displayPrevious:
            prevItem &&
            (() => {
              showDrawer('goal', prevItem.id)
            }),
        }
      },
      [tableData, showDrawer],
    ),
  )

  const renderRowGroupFooter = useCallback(
    ({ id, defaultDueDate }: RowGroup) => {
      if (id === 'done') {
        return null
      }
      return (
        <div sx={{ px: 6 }}>
          <Toggler
            hideControlsWhenOpen={true}
            controls={({ setSelected }) => (
              <ButtonGroup gap={1} size="small" variant="muted" containerProps={{ sx: { marginLeft: -3 } }}>
                {/** Negative margin aligns the text of the first button with the rest of the content on the page */}

                <Button
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setSelected(true)
                  }}
                >
                  New Goal
                </Button>
              </ButtonGroup>
            )}
          >
            {({ setSelected }) => (
              <AddGoalRow
                userId={userId}
                focusOnRender
                hideClear
                onCancel={() => {
                  setSelected(false)
                }}
                onCreated={() => {
                  setSelected(false)
                }}
                defaultDueDate={defaultDueDate}
              />
            )}
          </Toggler>
        </div>
      )
    },
    [userId],
  )

  const renderRowMenu = useCallback(({ id }: UserGoalsDashboardRowDataFragment) => <GoalMoreMenu id={id} />, [])

  const handleRowClick = useCallback(
    ({ id }: UserGoalsDashboardRowDataFragment, event: React.MouseEvent) => {
      showDrawer('goal', id, event)
    },
    [showDrawer],
  )

  const editGoalStatus = useCommand(editGoalStatusCommand)
  const setGoalPriorityFn = useSetGoalPriority()

  const handleGoalDragEnd = useCallback(
    async (dropResult: DropResult) => {
      if (!dropResult) return
      const { draggableId, source, destination } = dropResult
      if (destination == null || source == null) {
        return
      }

      const goalId = draggableId
      const sourceSection = dropResult.source.droppableId as 'open' | 'done'
      const destinationSection = destination.droppableId as 'open' | 'done'
      const destinationPosition = destination.index

      if (sourceSection === 'open' && destinationSection === 'open') {
        setGoalPriorityFn({
          goalId,
          destinationPosition,
          surroundingGoals: data?.user?.goals?.edges.map((edge) => edge.node),
        })
      } else if (sourceSection === 'open' && destinationSection === 'done') {
        void editGoalStatus.execute({
          goalId: goalId,
          status: 'DONE',
          skipNotDoneFlow: true,
        })
      } else if (sourceSection === 'done' && destinationSection === 'open') {
        await editGoalStatus.execute({
          goalId: goalId,
          status: 'ON_TRACK',
          skipNotDoneFlow: true,
        })
        setGoalPriorityFn({
          goalId,
          destinationPosition,
          surroundingGoals: data?.user?.goals?.edges.map((edge) => edge.node),
        })
      } else if (sourceSection === 'done' && destinationSection === 'done') {
        toast('Completed goals cannot be reordered', { type: 'warning' })
      }
    },
    [editGoalStatus, data, setGoalPriorityFn],
  )

  if (data?.user == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Goals not found')
  }

  return (
    <FastTable.Table
      draggable={prioritizeGoals ? 'rows' : false}
      data={tableData}
      columns={columns}
      getRowId={getRowId}
      noHeaders={hideTableHeaders}
      renderRowGroupFooter={showAddRow ? renderRowGroupFooter : () => null}
      onRowDragEnd={prioritizeGoals ? handleGoalDragEnd : undefined}
      renderRowMenu={renderRowMenu}
      decorateRow={decorateRow}
      renderRowGroupEmptyPlaceholder={({ renderEmptyPlaceholder }) => renderEmptyPlaceholder?.()}
      onRowClick={handleRowClick}
      noBorders={!hasBorders}
      tableStyles={{ padding: 0 }}
      rowStyles={{
        paddingX: 2,
        '& > button': { marginLeft: '-28px', cursor: 'move', '&:hover': { background: 'transparent' } },
      }}
    />
  )
}

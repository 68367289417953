import {
  Button,
  ButtonGroup,
  DRAWER_SECTION_INNER_PADDING,
  DrawerSection,
  Flex,
  Flyout,
  HotkeyTooltip,
  Suspender,
  Text,
  useFeatureFlags,
} from '@mm/company-ui'
import { ActionsIcon, CommentChatIcon, EllipsisIcon, FilePageTemplateIcon, PlusIcon } from '@mm/company-ui-icons'
import Router from 'next/router'
import { route } from 'nextjs-routes'
import React, { useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { TopicViewDocument } from '../../../gen/graphql/documents'
import { TopicActions } from '../../actions'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { AddTopicCommentBox } from '../../comments'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { DrawerView } from '../../components/DrawerView'
import { VerticalDivider } from '../../components/VerticalDivider'
import { useDynamicModules } from '../../hooks/useDynamicModules'
import { ContextualDrawerNavigation, useContextualDrawers } from '../../navigation'
import { editTopicStatusCommand } from '../commands/editTopicStatusCommand'
import { TopicCollapsibleMetadata } from './TopicCollapsibleMetadata'
import { TopicComments } from './TopicComments'
import { TopicCreatedContext } from './TopicCreatedContext'
import { TopicDescription } from './TopicDescription'
import { TopicEmojiReactions } from './TopicEmojiReactions'
import { TopicLoomEditor } from './TopicLoomEditor'
import { TopicMoreMenu } from './TopicMoreMenu'
import { TopicPrivacyTag } from './TopicPrivacyTag'
import { TopicTitle } from './TopicTitle'
import { TopicUserReviews } from './TopicUserReviews'

export type TopicViewProps = {
  topicId: string
  meetingId?: string
  noScroll?: boolean
}

export const TopicView = ({ topicId, meetingId, noScroll }: TopicViewProps) => {
  const {
    contextualDrawerNavigation,
    nonBlockingDrawer,
    reconfigureDrawerViews,
    userReviews,
    topicEmojiReactions,
    flipComments,
  } = useFeatureFlags()
  const { active, hideDrawer } = useContextualDrawers()
  const { data, loading, error } = useQuery(TopicViewDocument, {
    variables: {
      topicId,
    },
  })
  const editTopicStatus = useCommand(editTopicStatusCommand)

  useHotkeys(
    'd',
    () => {
      void editTopicStatus.execute({
        topicId,
        status: data?.topic?.status == 'DISCUSSED' ? 'UNDISCUSSED' : 'DISCUSSED',
      })
    },
    [topicId, data?.topic?.status],
  )

  const { hasComments, hasDescription, hasActions, handleShowComments, handleShowDescription, handleShowActions } =
    useDynamicModules({
      id: topicId,
      module: 'Topic',
    })

  const onTopicDeleted = useCallback(() => {
    if (!meetingId) {
      void Router.push(route({ pathname: '/meetings' }))
      return
    }
    if (active) hideDrawer()
  }, [active, hideDrawer, meetingId])

  if (data?.topic == null) {
    if (loading) {
      return <Suspender />
    }

    if (error) {
      throw error
    }
    // eslint-disable-next-line no-console
    console.error('Topic section not found')
    return <></>
  }

  if (reconfigureDrawerViews) {
    return (
      <DrawerView
        noScroll={noScroll}
        resourceId={topicId}
        resourceType="topics"
        renderMoreMenu={() => (
          <TopicMoreMenu
            id={topicId}
            meetingId={meetingId}
            topicSectionId={active?.sectionId}
            onTopicDeleted={onTopicDeleted}
          />
        )}
        renderExtraMenuItems={
          <>
            {userReviews && (
              <HotkeyTooltip shortcutKey="R" description="Mark as reviewed">
                <TopicUserReviews topicId={topicId} withButton markAsReviewedOnOpen />
              </HotkeyTooltip>
            )}
            <VerticalDivider />
            <div data-targetid="privacy">
              <TopicPrivacyTag topicId={topicId} sx={{ background: 'none' }} cached />
            </div>
          </>
        }
        renderTitle={<TopicTitle id={topicId} sx={{ fontSize: 2 }} />}
        renderMetadata={<TopicCollapsibleMetadata topicId={topicId} />}
        renderDescription={
          <>
            <TopicDescription topicId={topicId} fillHeight={false} padding={1} />
            <div sx={{ paddingX: 1 }}>
              <TopicLoomEditor topicId={topicId} url={data.topic.loomRecordingLink ?? undefined} />
            </div>
            <div sx={{ py: 1, px: 1 }}>{topicEmojiReactions && <TopicEmojiReactions id={topicId} cached />}</div>
          </>
        }
        renderActions={<TopicActions id={topicId} renderEmptyPlaceholder={() => <></>} />}
        renderComments={
          <TopicComments
            topicId={topicId}
            fixedComposer={false}
            order={flipComments ? 'oldest' : 'newest'}
            fixedAndScroll={false}
          />
        }
        renderCommentComposer={flipComments ? <AddTopicCommentBox topicId={topicId} /> : null}
      />
    )
  }

  return (
    <div sx={{ backgroundColor: nonBlockingDrawer ? 'white' : 'background-light', height: '100%', overflowY: 'auto' }}>
      <Flex row gap={2} align="start" sx={{ padding: 1, paddingBottom: 0 }}>
        {contextualDrawerNavigation && <ContextualDrawerNavigation sx={{ padding: 0.5 }} />}

        <TopicTitle
          id={topicId}
          sx={{
            width: '100%',
            // Very brittle, should build a real component for it
            fontSize: 18,
            '&:hover': {
              boxShadow: 'none',
            },
            '&:focus-within': {
              boxShadow: 'none',
            },
            '&:hover > .ProseMirror': {
              backgroundColor: 'background-light',
            },
            '> .ProseMirror': { padding: 1, borderRadius: 'medium', transition: 'background-color 200ms' },
            '> .ProseMirror-focused': { backgroundColor: 'background-light' },
          }}
        />

        <ButtonGroup size="small" textHidden variant="muted" containerProps={{ sx: { padding: 0.5 } }}>
          <CloseDrawerButton resourceType="topics" />

          <ExpandCollapseToggle resourceType="topics" id={topicId} />

          <Flyout
            position="bottom-start"
            content={
              <TopicMoreMenu
                id={topicId}
                topicSectionId={active?.sectionId}
                meetingId={meetingId}
                onTopicDeleted={onTopicDeleted}
              />
            }
          >
            <Button variant="muted" startIcon={<EllipsisIcon />} textHidden size="small">
              Open menu
            </Button>
          </Flyout>
        </ButtonGroup>
      </Flex>

      <Flex column gap={2} sx={{ paddingX: 2 }}>
        <div>
          <Text variant="small" uppercase color="#66bb6a" sx={{ fontWeight: 500 }}>
            Topic
          </Text>{' '}
          <TopicCreatedContext topicId={topicId} />
        </div>

        <TopicCollapsibleMetadata topicId={topicId} />

        <ButtonGroup sx={{ marginBottom: 2 }}>
          {!hasDescription && (
            <Button startIcon={<PlusIcon />} size="small" onClick={handleShowDescription}>
              Add Description
            </Button>
          )}
          {!hasActions && (
            <Button startIcon={<PlusIcon />} size="small" onClick={handleShowActions}>
              Add Action
            </Button>
          )}
          {!hasComments && (
            <Button startIcon={<PlusIcon />} size="small" onClick={handleShowComments}>
              Add Comment
            </Button>
          )}
        </ButtonGroup>
      </Flex>

      <Flex column gap={1} sx={{ paddingX: 2 }}>
        {hasDescription && (
          <DrawerSection
            title="Description"
            icon={<FilePageTemplateIcon />}
            dangerouslyCustomizeChildrenPadding={{
              padding: 0, // Since content is a textarea, padding should be handled by that
            }}
          >
            <TopicDescription topicId={topicId} fillHeight={false} padding={DRAWER_SECTION_INNER_PADDING} />
          </DrawerSection>
        )}

        {hasActions && (
          <DrawerSection
            title="Actions"
            icon={<ActionsIcon />}
            dangerouslyCustomizeChildrenPadding={{
              padding: 0, // Since action table has borders that are full-width, it handles its own padding
            }}
          >
            <TopicActions id={topicId} />
          </DrawerSection>
        )}

        {hasComments && (
          <DrawerSection
            id="comments"
            title="Comments"
            icon={<CommentChatIcon />}
            dangerouslyCustomizeChildrenPadding={{
              padding: DRAWER_SECTION_INNER_PADDING - 1, // Since comments have extra padding for hover effects, offset here
            }}
          >
            <TopicComments topicId={topicId} fixedComposer={false} order="newest" fixedAndScroll={false} />
          </DrawerSection>
        )}
      </Flex>
    </div>
  )
}

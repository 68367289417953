import { UpdateDecisionStatusDocument, GetDecisionStatusDocument, DecisionStatus } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditDecisionStatusCommandValue = {
  id: string
  status: DecisionStatus
}

export const editDecisionStatusCommand: Command<EditDecisionStatusCommandValue> = {
  id: 'editDecisionStatusCommand',
  name: 'Edit Issue Status',
  canExecute: async ({ id, status }, { apolloClient }) => {
    const { data } = await apolloClient.query({
      query: GetDecisionStatusDocument,
      variables: {
        id,
      },
    })
    return data.decision?.status !== status
  },
  execute: async ({ id, status }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const currentDecision = await apolloClient.query({
      query: GetDecisionStatusDocument,
      variables: { id },
    })
    const currentStatus = currentDecision.data?.decision?.status

    await apolloClient.mutate({
      mutation: UpdateDecisionStatusDocument,
      variables: {
        id,
        data: {
          status,
        },
      },
      optimisticResponse: {
        updateDecision: {
          __typename: 'Decision',
          id: id,
          status,
        },
      },
    })

    if (currentStatus) {
      return {
        args: {
          id,
          status: currentStatus,
        },
        execute: async ({ id, status }, { apolloClient }) => {
          await apolloClient.mutate({
            mutation: UpdateDecisionStatusDocument,
            variables: {
              id,
              data: {
                status,
              },
            },
            optimisticResponse: {
              updateDecision: {
                __typename: 'Decision',
                id: id,
                status,
              },
            },
          })
        },
      }
    }

    return
  },
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const PlusIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="plus" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8333 2.16659C10.8333 1.70635 10.4602 1.33325 9.99998 1.33325C9.53974 1.33325 9.16665 1.70635 9.16665 2.16659V9.66659H1.66665C1.20641 9.66659 0.833313 10.0397 0.833313 10.4999C0.833313 10.9602 1.20641 11.3333 1.66665 11.3333H9.16665V18.8333C9.16665 19.2935 9.53974 19.6666 9.99998 19.6666C10.4602 19.6666 10.8333 19.2935 10.8333 18.8333V11.3333H18.3333C18.7935 11.3333 19.1666 10.9602 19.1666 10.4999C19.1666 10.0397 18.7935 9.66659 18.3333 9.66659H10.8333V2.16659Z"
      />
    </IconBase>
  )
}

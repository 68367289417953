import {
  autoUpdate,
  FloatingNode,
  FloatingOverlay,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import _ from 'lodash'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Button, ButtonGroup, ButtonProps } from '../Button'
import { BaseModal, ModalHeader, ModalSection } from '../Modal'
import { Heading } from '../Text'
import { FloatingPortal } from './FloatingPortal'
import { floatingTreeNode } from './floatingTreeNode'
import { useFloatingTreeNode } from './useFloatingTreeNode'

export type UseConfirmPopoverProps<D> = {
  triggerConfirm: (data?: D) => Promise<unknown>
  confirmControlProps: {
    open: boolean
    data: D | null
    onClose: () => void
    onConfirm: () => void
  }
}

export function useConfirmPopover<D>(): UseConfirmPopoverProps<D> {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<D | null>(null)
  const fn = useRef<(choice: boolean) => void>()

  // Confirm context returns a promise that resolves when the user confirms/cancels within the dialog similar to `window.confirm`
  const triggerConfirm = useCallback((data?: D) => {
    return new Promise((resolve) => {
      setOpen(true)

      if (data) {
        setData(data)
      }
      fn.current = (choice: boolean) => {
        resolve(choice)
        setOpen(false)
        setData(null)
      }
    })
  }, [])

  return {
    triggerConfirm,
    confirmControlProps: useMemo(
      () => ({
        open,
        data,
        onClose: () => {
          fn.current?.(false)
        },
        onConfirm: () => {
          fn.current?.(true)
        },
      }),
      [open, data, fn],
    ),
  }
}

type ConfirmPopoverProps = {
  title?: string | React.ReactElement
  subSection?: string | React.ReactElement
  description?: string | React.ReactElement
  cancelLabel?: string
  confirmLabel?: string
  confirmVariant?: ButtonProps['variant']
  zIndex?: number // Provides override when mixing with old tippy modals/tooltips
  ariaLabel?: string
  open: boolean
  renderControlsLeft?: React.ReactElement | null
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmPopover = floatingTreeNode<ConfirmPopoverProps>(
  ({
    title,
    subSection,
    description,
    confirmLabel = 'Confirm',
    confirmVariant = 'warning',
    cancelLabel = 'Cancel',
    open,
    zIndex,
    ariaLabel,
    renderControlsLeft,
    onClose,
    onConfirm,
  }) => {
    const nodeId = useFloatingNodeId()
    useFloatingTreeNode({
      // Dont close Confirm Popover if nested nodes are closed
      onOpenChange: _.noop,
    })

    const {
      refs: { setFloating },
      context,
    } = useFloating({
      nodeId,
      open,
      onOpenChange: handleOpenChange,
      whileElementsMounted: autoUpdate,
    })

    const id = useId()
    const labelId = `${id}-label`
    const descriptionId = `${id}-description`

    const { getFloatingProps } = useInteractions([useRole(context), useDismiss(context)])

    function handleOpenChange(open: boolean) {
      if (!open) {
        onClose()
      }
    }

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          {open ? (
            <FloatingOverlay sx={{ zIndex, backgroundColor: 'background-hover' }}>
              <BaseModal
                modalVariant="default"
                {...getFloatingProps({
                  ref: setFloating,
                  'aria-label': ariaLabel,
                  'aria-labelledby': ariaLabel ? undefined : labelId,
                  'aria-describedby': descriptionId,
                })}
                sx={{
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                {title ? (
                  <ModalHeader>
                    <Heading level={4} id={labelId}>
                      {title}
                    </Heading>
                  </ModalHeader>
                ) : null}

                {subSection ? <ModalSection>{subSection}</ModalSection> : null}
                {description ? <ModalSection>{description}</ModalSection> : null}

                <ModalSection align="center" sx={{ paddingY: 1 }}>
                  <div sx={{ flex: 1 }}>{renderControlsLeft}</div>
                  <ButtonGroup>
                    <Button onClick={onClose}>{cancelLabel}</Button>
                    <Button variant={confirmVariant} onClick={onConfirm}>
                      {confirmLabel}
                    </Button>
                  </ButtonGroup>
                </ModalSection>
              </BaseModal>
            </FloatingOverlay>
          ) : null}
        </FloatingPortal>
      </FloatingNode>
    )
  },
)

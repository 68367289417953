import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const HistoryIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="history" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82111 19.8295C11.2246 20.1178 13.6504 19.5154 15.6399 18.1364C17.6294 16.7573 19.0446 14.6971 19.6181 12.3453C20.1916 9.99347 19.8835 7.51305 18.7521 5.37299C17.6207 3.23293 15.7445 1.58156 13.4781 0.731094C11.2117 -0.119371 8.71222 -0.109989 6.45227 0.757475C5.05116 1.29528 3.80232 2.13979 2.78971 3.21597L2.90561 1.31857C2.93592 0.822443 2.55829 0.395682 2.06216 0.365377C1.56603 0.335073 1.13927 0.712699 1.10896 1.20883L0.815154 6.01884C0.797973 6.30012 0.913532 6.57321 1.12739 6.75672C1.34126 6.94024 1.62873 7.01298 1.90414 6.95329L6.70804 5.91199C7.19382 5.80669 7.50225 5.32753 7.39696 4.84176C7.29166 4.35598 6.8125 4.04754 6.32673 4.15284L3.89125 4.68076C4.75434 3.68961 5.85235 2.91579 7.0973 2.43793C8.94634 1.72819 10.9914 1.72051 12.8457 2.41635C14.7 3.11218 16.2351 4.46331 17.1608 6.21426C18.0865 7.96522 18.3385 9.99465 17.8694 11.9189C17.4002 13.8431 16.2422 15.5287 14.6144 16.657C12.9867 17.7853 11.0019 18.2782 9.03544 18.0423C7.06895 17.8065 5.25701 16.8583 3.9422 15.3771C3.13999 14.4734 2.55677 13.4094 2.22372 12.2673C2.08458 11.7901 1.58495 11.5161 1.10777 11.6552C0.630584 11.7943 0.356547 12.294 0.495689 12.7712C0.902709 14.167 1.61554 15.4675 2.59604 16.5721C4.20302 18.3824 6.41762 19.5413 8.82111 19.8295ZM14.6016 8.53913C14.9714 8.20693 15.0018 7.6379 14.6696 7.26815C14.3374 6.8984 13.7684 6.86796 13.3986 7.20016L8.52066 11.5827L6.68513 9.42875C6.36273 9.05043 5.79469 9.00509 5.41637 9.32749C5.03805 9.64988 4.99271 10.2179 5.31511 10.5962L7.74989 13.4534C7.90736 13.6382 8.13281 13.7515 8.37505 13.7676C8.6173 13.7838 8.85579 13.7014 9.03639 13.5391L14.6016 8.53913Z"
      />
    </IconBase>
  )
}

import React from 'react'
import { TopicCreatedContextFragmentFragmentDoc } from '../../../gen/graphql/documents'
import { CreatedContext } from '../../actions/components/CreatedContext'
import { useFragment_experimental } from '../../apollo'

export function TopicCreatedContext({ topicId }: { topicId: string }) {
  const { data } = useFragment_experimental({
    fragment: TopicCreatedContextFragmentFragmentDoc,
    fragmentName: 'TopicCreatedContextFragment',
    from: {
      __typename: 'Topic',
      id: topicId,
    },
  })

  if (!data) {
    // eslint-disable-next-line no-console
    console.log('Fragment returned no data, make sure a parent of TopicCreatedContext is querying the fragment')
    return null
  }

  return <CreatedContext createdAt={data.createdAt} parent={data.parent ?? undefined} />
}

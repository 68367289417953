import { getSelectionHtml, getSelectionText, ToolbarItem } from '@mm/company-ui'
import { CircleCheckmarkIcon, CommentChatIcon, DecisionIcon, FilePageTemplateIcon } from '@mm/company-ui-icons'
import { Editor } from '@tiptap/core'
import React, { useCallback, useState } from 'react'
import { useEvent } from 'react-use'
import { ADD_ACTION } from '../../actions/capabilities'
import { QuickAddAction } from '../../actions/components/QuickAddAction'
import { capture } from '../../analytics'
import { useCapabilities } from '../../capabilities'
import { FOCUS_AND_QUOTE_COMMENT, FOCUS_AND_QUOTE_COMMENT_AS_DECISION } from '../../comments/capabilities'
import { CREATE_ISSUE } from '../../decisions/capabilities'
import { useFeatureFlags } from '../../featureFlags'
import { CreateMeetingDecisionModal, useMeetingContext } from '../../meetings'
import { QuickCreateTopic } from '../../topics'
import { ADD_TOPIC } from '../../topics/capabilities'

export const useComplexToolbar = (
  baseItems: readonly ToolbarItem[][] = [],
  renderSelectItems?: Array<'comment' | 'add-action' | 'make-decision' | 'add-topic' | 'create-issue'>,
): React.ReactElement | ToolbarItem[][] => {
  const { quickCreateTopic, quickCreateIssue, decisionBoxInDrawerView } = useFeatureFlags()
  const { isRegistered, execute } = useCapabilities()
  const [selectedDescription, setSelectedDescription] = useState<string | null>(null)
  const [resourceToCreate, setResourceToCreate] = useState<string | null>(null)
  const [selectedText, setSelectedText] = useState<string | null>(null)
  const [editor, setEditor] = useState<Editor>()

  useEvent(
    'selectionUpdate',
    () => {
      setSelectedText(null)
      setSelectedDescription(null)
      setResourceToCreate(null)
    },
    editor ?? null,
  )

  const clearSelection = useCallback(() => {
    editor?.commands.setTextSelection(editor?.state.selection.from)
  }, [editor])

  const meeting = useMeetingContext()

  if (selectedText) {
    if (resourceToCreate === 'action') {
      return (
        <QuickAddAction
          defaultTitle={selectedText}
          defaultDescription={selectedDescription ?? ''}
          onDone={clearSelection}
        />
      )
    } else if (resourceToCreate === 'topic' && meeting?.id) {
      return (
        <QuickCreateTopic
          meetingId={meeting.id}
          defaults={{ title: selectedText, description: selectedDescription ?? '' }}
          onComplete={clearSelection}
          onRequestClose={clearSelection}
        />
      )
    } else if (resourceToCreate === 'issue' && meeting?.id) {
      return (
        <CreateMeetingDecisionModal
          meetingId={meeting?.id}
          defaultIssueText={selectedText}
          onComplete={clearSelection}
          onRequestClose={clearSelection}
        />
      )
    }
  }

  const items: Array<Array<ToolbarItem>> = [
    ...baseItems,
    [
      (editor) => ({
        type: 'button',
        label: 'Comment',
        icon: <CommentChatIcon />,
        onClick: () => {
          capture('Editor Toolbar Item Selected', { target: 'Create Comment' })
          execute(FOCUS_AND_QUOTE_COMMENT, getSelectionHtml(editor))
        },
        hidden:
          !isRegistered(FOCUS_AND_QUOTE_COMMENT) ||
          (renderSelectItems != null && !renderSelectItems.includes('comment')),
      }),
      (editor) => ({
        type: 'button',
        label: 'Add Action',
        icon: <CircleCheckmarkIcon />,
        onClick: () => {
          capture('Editor Toolbar Item Selected', { target: 'Create Action' })
          const title = getSelectionHtml(editor)

          setResourceToCreate('action')
          setEditor(editor)

          const hasMultipleBlocks = getSelectionText(editor, 'BLOCK_SEPERATOR').includes('BLOCK_SEPERATOR')
          if (hasMultipleBlocks) {
            setSelectedDescription(getSelectionHtml(editor))
          }
          setSelectedText(title)
        },
        hidden: !isRegistered(ADD_ACTION) || (renderSelectItems != null && !renderSelectItems.includes('add-action')),
      }),
      (editor) => ({
        type: 'button',
        label: decisionBoxInDrawerView ? 'Add To Decision' : 'Make Decision',
        icon: <DecisionIcon />,
        onClick: () => {
          capture('Editor Toolbar Item Selected', {
            target: decisionBoxInDrawerView ? 'Add To Decision' : 'Make Decision',
          })
          execute(FOCUS_AND_QUOTE_COMMENT_AS_DECISION, getSelectionHtml(editor))
        },
        hidden:
          !isRegistered(FOCUS_AND_QUOTE_COMMENT_AS_DECISION) ||
          (renderSelectItems != null && !renderSelectItems.includes('make-decision')),
      }),
      (editor) => ({
        type: 'button',
        label: 'Create Topic',
        icon: <FilePageTemplateIcon />,
        onClick: () => {
          capture('Editor Toolbar Item Selected', { target: 'Create Topic' })
          setResourceToCreate('topic')
          const title = getSelectionText(editor)

          setEditor(editor)

          const hasMultipleBlocks = getSelectionText(editor, 'BLOCK_SEPERATOR').includes('BLOCK_SEPERATOR')
          if (hasMultipleBlocks) {
            setSelectedDescription(getSelectionHtml(editor))
          }
          setSelectedText(title)
        },
        hidden:
          !quickCreateTopic ||
          !isRegistered(ADD_TOPIC) ||
          (renderSelectItems != null && !renderSelectItems.includes('add-topic')) ||
          !meeting?.id,
      }),
      (editor) => ({
        type: 'button',
        label: 'Create Issue',
        icon: <DecisionIcon />,
        onClick: () => {
          capture('Editor Toolbar Item Selected', { target: 'Create Issue' })
          setResourceToCreate('issue')
          const title = getSelectionText(editor)

          setEditor(editor)
          setSelectedText(title)
        },
        hidden:
          !quickCreateIssue ||
          !isRegistered(CREATE_ISSUE) ||
          (renderSelectItems != null && !renderSelectItems.includes('create-issue')) ||
          !meeting?.id,
      }),
    ],
  ]

  return items
}

import { score } from 'fuzzaldrin'
import React, { useState } from 'react'
import { Popover } from '../FloatingUI'
import { FlyoutItem } from '../Flyout'
import { Input } from '../Input'
import { PopupWrapper } from '../Popup'
import { Tag, TagProps } from '../Tag/Tag'

export type TagSelectProps<V extends string> = {
  value: V
  onChange: (change: V) => void
  renderEmpty?: (props: Record<string, unknown>) => React.ReactNode
  children: Array<React.ReactElement<TagProps & { value: V; hidden?: boolean }> | null>
  variant?: 'default' | 'searchable' | 'searchable-with-add'
}

export const TagSelect = <V extends string>({
  children,
  value,
  variant,
  renderEmpty = () => null,
  onChange,
}: TagSelectProps<V>) => {
  const [searchValue, setSearchValue] = useState('')
  return (
    <Popover
      placement="bottom-start"
      render={({ hide }) => {
        return (
          <PopupWrapper sx={{ padding: 0, minWidth: 200 }}>
            {variant === 'searchable' || variant == 'searchable-with-add' ? (
              <Input
                placeholder="Search options..."
                value={searchValue}
                onChange={setSearchValue}
                autoFocus
                variant="muted"
                sx={{
                  width: '100%',
                  borderBottom: '1px solid',
                  borderBottomColor: 'border',
                  borderBottomLeftRadius: '0px',
                  borderBottomRightRadius: '0px',
                  marginBottom: 1,
                  '&:focus-within': { boxShadow: 'none' },
                }}
              />
            ) : null}
            {React.Children.map(children, (child) => {
              return child && !child.props.hidden && (score(child.props.value, searchValue) > 0 || !searchValue) ? (
                <FlyoutItem
                  as="div"
                  onClick={() => {
                    hide()
                    onChange(child.props.value)
                  }}
                >
                  {child}
                </FlyoutItem>
              ) : null
            })}
            {variant === 'searchable-with-add' && searchValue ? (
              <FlyoutItem
                as="div"
                onClick={() => {
                  hide()
                  onChange(searchValue as V)
                }}
              >
                Create{' '}
                <Tag value={searchValue} variant="default">
                  {searchValue}
                </Tag>
              </FlyoutItem>
            ) : null}
          </PopupWrapper>
        )
      }}
    >
      {({ getReferenceProps }) => {
        const selected = React.Children.toArray(children).find((child) => {
          if (React.isValidElement<{ value: V }>(child)) {
            return value === child.props.value
          }
          return false
        })

        return selected && React.isValidElement(selected)
          ? React.cloneElement(selected, getReferenceProps({ 'data-testid': 'TagSelect__target' }))
          : renderEmpty?.(getReferenceProps())
      }}
    </Popover>
  )
}

import {
  Button,
  ButtonGroup,
  DRAWER_SECTION_INNER_PADDING,
  DrawerLayout,
  DrawerSection,
  Popover,
  PopupWrapper,
  Suspender,
  Text,
  Flex,
} from '@mm/company-ui'
import { ActionsIcon, CommentChatIcon, EllipsisIcon, FilePageTemplateIcon, InfoIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { SinglePrototoypeFeedbackDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { RegisterCapability } from '../../capabilities'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { DrawerView } from '../../components/DrawerView'
import { CREATE_ISSUE } from '../../decisions/capabilities'
import { useFeatureFlags } from '../../featureFlags'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'
import { useMeetingContext } from '../../meetings'
import { ContextualDrawerNavigation, useContextualDrawers } from '../../navigation'
import { ADD_TOPIC } from '../../topics/capabilities'
import { AddFeedbackCommentBox } from './AddFeedbackCommentBox'
import { FeedbackActions } from './FeedbackActions'
import { FeedbackComments } from './FeedbackComments'
import { FeedbackDescription } from './FeedbackDescription'
import { FeedbackMetadata } from './FeedbackMetadata'
import { FeedbackSummaryMetadata } from './FeedbackMetadataSummary'
import { SingleFeedbackMoreMenu } from './FeedbackMoreMenu'
import { FeedbackPrivacySelect } from './FeedbackPrivacySelect'
import { FeedbackTitleSection } from './FeedbackTitleSection'
import { RatingDotsInlineSummary } from './RatingDots'

export type SinglePrototypeFeedbackPageProps = {
  prototypeFeedbackId: string
}

export const SinglePrototypeFeedbackPage = ({ prototypeFeedbackId }: SinglePrototypeFeedbackPageProps) => {
  const { metadataSummary, commentsActionsInDrawersCollapsedWhenEmpty, reconfigureDrawerViews, flipComments } =
    useFeatureFlags()

  const { data, loading, error } = useQuery(SinglePrototoypeFeedbackDocument, {
    variables: {
      id: prototypeFeedbackId,
    },
  })
  const { active, hideDrawer } = useContextualDrawers()

  const onFeedbackDeleted = useCallback(() => {
    if (active) hideDrawer()
  }, [active, hideDrawer])

  const prototypeFeedback = data?.prototypeFeedback

  const meeting = useMeetingContext()

  const { commentsLabel, hasComments } = useTotalComments(prototypeFeedback)
  const { actionsLabel, hasActions } = useTotalActions(prototypeFeedback)

  if (prototypeFeedback == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Feedback not found')
  }

  // Access Denied
  if (prototypeFeedback.score == null && prototypeFeedback.description == null) {
    return (
      <>
        <DrawerView
          resourceId={prototypeFeedbackId}
          resourceType="feedback"
          renderExtraMenuItems={
            <FeedbackPrivacySelect feedbackId={prototypeFeedbackId} sx={{ background: 'none' }} cached />
          }
          renderTitle={
            <Text variant="h4" sx={{ paddingY: 1, marginBottom: '2px' }}>
              Feedback to{' '}
              {prototypeFeedback.to
                ? prototypeFeedback.to.__typename === 'Meeting'
                  ? prototypeFeedback.to.title
                  : prototypeFeedback.to.firstName
                : ''}
            </Text>
          }
          renderMetadata={<FeedbackMetadata prototypeFeedbackId={prototypeFeedbackId} />}
          renderDescription={
            <Flex
              sx={{ backgroundColor: 'background-light', borderRadius: 'medium', height: 300 }}
              align="center"
              justify="center"
            >
              <Text color="text-medium">You do not have access to view this feedback.</Text>
            </Flex>
          }
        />
      </>
    )
  }

  if (reconfigureDrawerViews) {
    return (
      <>
        <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meeting?.id} />
        <RegisterCapability capability={CREATE_ISSUE} handler={() => Promise.resolve()} skip={!meeting?.id} />
        <DrawerView
          resourceId={prototypeFeedbackId}
          resourceType="feedback"
          renderMoreMenu={() => <SingleFeedbackMoreMenu id={prototypeFeedbackId} onSelect={onFeedbackDeleted} />}
          renderExtraMenuItems={
            <FeedbackPrivacySelect feedbackId={prototypeFeedbackId} sx={{ background: 'none' }} cached />
          }
          renderTitle={
            <Text variant="h4" sx={{ paddingY: 1, marginBottom: '2px' }}>
              Feedback to{' '}
              {prototypeFeedback.to
                ? prototypeFeedback.to.__typename === 'Meeting'
                  ? prototypeFeedback.to.title
                  : prototypeFeedback.to.firstName
                : ''}
            </Text>
          }
          renderMetadata={<FeedbackMetadata prototypeFeedbackId={prototypeFeedbackId} />}
          renderDescription={<FeedbackDescription prototypeFeedbackId={prototypeFeedbackId} padding={1} />}
          renderActions={<FeedbackActions id={prototypeFeedbackId} />}
          renderComments={<FeedbackComments prototypeFeedbackId={prototypeFeedbackId} />}
          renderCommentComposer={
            flipComments ? <AddFeedbackCommentBox prototypeFeedbackId={prototypeFeedbackId} /> : null
          }
        />
      </>
    )
  }

  return (
    <DrawerLayout
      title={`Feedback to ${
        prototypeFeedback.to
          ? prototypeFeedback.to.__typename === 'Meeting'
            ? prototypeFeedback.to.title
            : prototypeFeedback.to.firstName
          : ''
      }`}
      navigation={<ContextualDrawerNavigation />}
      renderTitleButtonGroup={(props) => (
        <ButtonGroup {...props}>
          <CloseDrawerButton resourceType="feedback" />

          <ExpandCollapseToggle resourceType="feedback" id={prototypeFeedbackId} />

          <Popover
            placement="bottom-start"
            render={() => (
              <PopupWrapper sx={{ px: 0, py: 1 }}>
                <SingleFeedbackMoreMenu id={prototypeFeedbackId} onSelect={onFeedbackDeleted} />
              </PopupWrapper>
            )}
          >
            {({ getReferenceProps }) => (
              <Button variant="muted" startIcon={<EllipsisIcon />} textHidden size="small" {...getReferenceProps()}>
                Open menu
              </Button>
            )}
          </Popover>
        </ButtonGroup>
      )}
      renderTitleSection={(props) => <FeedbackTitleSection prototypeFeedbackId={prototypeFeedbackId} {...props} />}
    >
      <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meeting?.id} />
      <RegisterCapability capability={CREATE_ISSUE} handler={() => Promise.resolve()} skip={!meeting?.id} />

      <DrawerSection
        title="About"
        icon={<InfoIcon />}
        isClosedOnRender={metadataSummary}
        right={metadataSummary ? <FeedbackSummaryMetadata prototypeFeedbackId={prototypeFeedbackId} /> : null}
        dangerouslyCustomizeChildrenPadding={{
          paddingY: DRAWER_SECTION_INNER_PADDING - 1, // To Account for inconsistent Y padding of Metadata
        }}
      >
        <FeedbackMetadata prototypeFeedbackId={prototypeFeedbackId} />
      </DrawerSection>

      <DrawerSection
        title="Feedback"
        icon={<FilePageTemplateIcon />}
        right={prototypeFeedback.score ? <RatingDotsInlineSummary score={prototypeFeedback.score} /> : undefined}
        dangerouslyCustomizeChildrenPadding={{
          padding: 0, // Since content is a textarea, padding should be handled by that
        }}
      >
        <FeedbackDescription prototypeFeedbackId={prototypeFeedbackId} padding={2} />
      </DrawerSection>

      <DrawerSection
        id="actions"
        title="Actions"
        icon={<ActionsIcon />}
        isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasActions}
        right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{actionsLabel}</Text> : null}
        dangerouslyCustomizeChildrenPadding={{
          padding: 0, // Since action table has borders that are full-width, it handles its own padding
        }}
      >
        <FeedbackActions id={prototypeFeedbackId} />
      </DrawerSection>

      <DrawerSection
        id="comments"
        title="Comments"
        icon={<CommentChatIcon />}
        isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasComments}
        right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{commentsLabel}</Text> : null}
        dangerouslyCustomizeChildrenPadding={{
          padding: DRAWER_SECTION_INNER_PADDING - 1, // Since comments have extra padding for hover effects, offset here
        }}
      >
        <FeedbackComments prototypeFeedbackId={prototypeFeedbackId} />
      </DrawerSection>
    </DrawerLayout>
  )
}

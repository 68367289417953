import { onboardingEmptyEmbedding } from '@mm/common'
import { Flex, Heading, Text, isTestingEnvironment, useFeatureFlags } from '@mm/company-ui'
import React from 'react'
import { IntroCard } from '../../onboarding'
import { useActiveUser } from '../../users'

export function ActionOnboarding() {
  const { onboardingContent } = useFeatureFlags()
  const activeUser = useActiveUser()

  if (!onboardingContent || !activeUser) {
    return <></>
  }

  const embedUrl = !isTestingEnvironment
    ? 'https://www.loom.com/embed/660efa7fbaeb483594530d38b9f29104?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true'
    : onboardingEmptyEmbedding

  return (
    <Flex column sx={{ width: '100%' }} justify="space-between">
      <IntroCard contentId="action-onboarding" embedUrl={embedUrl}>
        <Heading level={4}>
          Welcome, {activeUser.firstName}! 👋
          <br />
          To get you up to speed quickly, we prepared this short (3 min.) video. Check it out.
        </Heading>
        <Text>
          We’ve also prepared a checklist of things you can do to quickly master companyOS and get the best out of it{' '}
          <span sx={{ whiteSpace: 'nowrap' }}>(see below👇)</span>
        </Text>
      </IntroCard>
    </Flex>
  )
}

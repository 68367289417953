import { BoldIcon, ItalicIcon, LinkIcon, UnderlineIcon } from '@mm/company-ui-icons'
import { Node as ProsemirrorNode } from 'prosemirror-model'
import React from 'react'
import { ToolbarAction } from './ToolbarAction'

const bold: ToolbarAction = (editor) => ({
  type: 'button',
  label: 'bold',
  icon: <BoldIcon />,
  textHidden: true,
  isActive: editor.isActive('bold'),
  onClick: () => {
    editor.chain().focus().toggleBold().run()
  },
  disabled: !editor.can().toggleBold(),
  hidden: !editor.isEditable,
})

const italic: ToolbarAction = (editor) => ({
  type: 'button',
  label: 'italic',
  icon: <ItalicIcon />,
  textHidden: true,
  isActive: editor.isActive('italic'),
  onClick: () => {
    editor.chain().focus().toggleItalic().run()
  },
  disabled: !editor.can().toggleItalic(),
  hidden: !editor.isEditable,
})

const underline: ToolbarAction = (editor) => ({
  type: 'button',
  label: 'underline',
  icon: <UnderlineIcon />,
  textHidden: true,
  isActive: editor.isActive('underline'),
  onClick: () => {
    editor.chain().focus().toggleUnderline().run()
  },
  disabled: !editor.can().toggleUnderline(),
  hidden: !editor.isEditable,
})

const link: ToolbarAction = (editor) => ({
  type: 'button',
  label: 'link',
  icon: <LinkIcon />,
  textHidden: true,
  isActive: editor.isActive('link'),
  onClick: () => {
    if (editor.isActive('link')) {
      editor.chain().focus().unsetLink().run()
    } else {
      const href = window.prompt('URL')
      if (href != null) {
        editor.chain().focus().setLink({ href }).run()
      }
    }
  },
  disabled: !editor.can().toggleLink({ href: '' }),
  hidden: !editor.isEditable,
})

const block: ToolbarAction = (editor) => {
  const { from, to } = editor.state.selection
  const nodes: ProsemirrorNode[] = []
  editor.state.doc.nodesBetween(from, to, (node, _, parent) => {
    if (node.isBlock) {
      nodes.push(node)
      if (parent && nodes.indexOf(parent) !== -1) {
        nodes.splice(nodes.indexOf(parent), 1)
      }
    }
  })
  const [label = '', ...rest] = nodes
    .map((node) => {
      switch (node.type.name) {
        case 'heading':
          if (node.attrs['level'] === 1) return 'Heading'
          if (node.attrs['level'] === 2) return 'Subheading'
          return 'Text'
        case 'codeBlock':
          return 'Code'
        default:
          if (editor.isActive('bulletList')) return 'Bullet'
          if (editor.isActive('orderedList')) return 'Numbered'
          if (editor.isActive('blockquote')) return 'Quote'
          return 'Text'
      }
    })
    .filter((label, idx, labels) => !labels.includes(label, idx + 1))

  return {
    type: 'select',
    label: rest.length > 0 ? 'Mixed' : label,
    options: [
      {
        value: 'text',
        label: 'Text',
        onSelect: () => {
          editor.chain().focus().setParagraph().run()
        },
      },
      {
        value: `heading-1`,
        label: `Heading`,
        onSelect: () => {
          editor.chain().focus().setHeading({ level: 1 }).run()
        },
        disabled: !editor.can().setHeading({ level: 1 }),
      },
      {
        value: `heading-2`,
        label: `Subheading`,
        onSelect: () => {
          editor.chain().focus().setHeading({ level: 2 }).run()
        },
        disabled: !editor.can().setHeading({ level: 2 }),
      },
      {
        value: 'bulletList',
        label: 'Bullet',
        onSelect: () => {
          editor.chain().focus().toggleBulletList().run()
        },
        disabled: !editor.can().toggleBulletList(),
      },
      {
        value: 'orderedList',
        label: 'Numbered',
        onSelect: () => {
          editor.chain().focus().toggleOrderedList().run()
        },
        disabled: !editor.can().toggleOrderedList(),
      },
      {
        value: 'codeBlock',
        label: 'Code',
        onSelect: () => {
          editor.chain().focus().setCodeBlock().run()
        },
        disabled: !editor.can().setCodeBlock(),
      },
      {
        value: 'blockquote',
        label: 'Quote',
        onSelect: () => {
          editor.chain().focus().setBlockquote().run()
        },
        disabled: !editor.can().setBlockquote(),
      },
    ],
    hidden: !editor.isEditable,
  }
}

// const BulletListIcon = ToolbarIcon.withComponent(BsListUl)
// const bulletList: ToolbarAction = (editor) => ({
//   type: 'old',
//   label: <BulletListIcon />,
//   isActive: editor.isActive('bulletList'),
//   onClick: () => {
//     editor.chain().focus().toggleBulletList().run()
//   },
//   disabled: !editor.can().toggleBulletList(),
//   hidden: !editor.isEditable,
// })

// const OrderedListIcon = ToolbarIcon.withComponent(BsListOl)
// const orderedList: ToolbarAction = (editor) => ({
//   type: 'old',
//   label: <OrderedListIcon />,
//   isActive: editor.isActive('orderedList'),
//   onClick: () => {
//     editor.chain().focus().toggleOrderedList().run()
//   },
//   disabled: !editor.can().toggleOrderedList(),
//   hidden: !editor.isEditable,
// })

// const BlockquoteIcon = ToolbarIcon.withComponent(BsBlockquoteLeft)
// const blockquote: ToolbarAction = (editor) => ({
//   type: 'old',
//   label: <BlockquoteIcon />,
//   isActive: editor.isActive('blockquote'),
//   onClick: () => {
//     editor.chain().focus().toggleBlockquote().run()
//   },
//   disabled: !editor.can().toggleBlockquote(),
//   hidden: !editor.isEditable,
// })

export const standardActions = {
  bold,
  italic,
  underline,
  link,
  block,
  // 'bullet-list': bulletList,
  // 'ordered-list': orderedList,
  // blockquote,
}

export type StandardActions = typeof standardActions

import { ApolloCache } from '@apollo/client/cache'
import { gql } from '../../apollo'

export const markActionAsCreatedHere = (cache: ApolloCache<any>, action: { id: string }) => {
  cache.writeFragment({
    id: cache.identify({ __typename: 'Action', id: action.id }),
    fragment: gql`
      fragment markActionAsCreatedHere on Action {
        _createdHereAt @client
      }
    `,
    data: { _createdHereAt: Date.now() },
  })
}

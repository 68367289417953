import { init } from 'emoji-mart'
import React, { useEffect } from 'react'
import { emojisData } from './EmojisData'

export type EmojiProps = React.ComponentPropsWithoutRef<'em-emoji'>

export const Emoji = (props: EmojiProps) => {
  useEffect(() => {
    init({ data: emojisData }).catch((e) => {
      // eslint-disable-next-line no-console
      console.error('Failed to init emoji-mart', e)
    })
  }, [])

  return <em-emoji {...props} />
}

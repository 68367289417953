import { Flex, formatDateDisplay, Text } from '@mm/company-ui'
import React from 'react'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'

export type GoalRowContext = {
  goalId: string
  dueAt: number
}

export function GoalRowContext({ goalId, dueAt }: GoalRowContext) {
  const { commentsLabel } = useTotalComments({ __typename: 'Goal', id: goalId })
  const { actionsLabel } = useTotalActions({ __typename: 'Goal', id: goalId })

  return (
    <Flex row gap={2}>
      <Text variant="small" color="text-light">
        Due {formatDateDisplay(dueAt)}
      </Text>
      <Text variant="small" color="text-light">
        {actionsLabel}
      </Text>
      <Text variant="small" color="text-light">
        {commentsLabel}
      </Text>
    </Flex>
  )
}

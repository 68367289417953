import { Mention, MentionOptions } from '@tiptap/extension-mention'
import { v4 as uuid } from 'uuid'

export type MentionsExtensionOptions = MentionOptions & {
  readonly: boolean
}

export const MentionsExtension = Mention.extend<MentionsExtensionOptions>({
  addOptions() {
    // addOptions is called twice due to a bug in tiptap
    // first time with parent=null, second time with correct parent
    if (this.parent == null) return null as never
    const parent = this.parent()
    return {
      ...parent,
      suggestion: {
        ...parent.suggestion,
        command: ({ editor, range, props }) => {
          parent.suggestion.command?.({
            editor,
            range,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            props: {
              ...props,
              // each mention gets a unique random key so that we can distinguish new mentions from moved ones
              key: uuid(),
            },
          })
        },
      },
    }
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      key: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-key'),
        renderHTML: (attributes) => {
          if (!attributes['key']) {
            return {}
          }

          return {
            'data-key': attributes['key'] as string,
          }
        },
      },
    }
  },
  addProseMirrorPlugins() {
    return this.options.readonly ? [] : this.parent?.() ?? []
  },
})

import { ContextMenu, PopupWrapper } from '@mm/company-ui'
import React, { useState } from 'react'
import { ActionMoreMenu } from '../../actions/components/ActionMoreMenu'
import { useContextualDrawers } from '../../navigation'

export type ActionLinkWithMenuProps = {
  type?: 'a-action' | 'action'
  actionId: string
  hasHover?: boolean
  children: React.ReactNode
}

export function ActionLinkWithMenu({
  type = 'a-action',
  actionId,
  hasHover = true,
  children,
}: ActionLinkWithMenuProps) {
  const [menuVisible, setmenuVisible] = useState(false)
  const { showDrawer } = useContextualDrawers()

  return (
    <ContextMenu
      render={() => (
        <PopupWrapper sx={{ px: 0, py: 1 }}>
          <ActionMoreMenu id={actionId} />
        </PopupWrapper>
      )}
      onOpenChange={setmenuVisible}
    >
      {({ getReferenceProps }) => (
        <div
          {...getReferenceProps({
            onClick: (event) => {
              if (
                event.target instanceof HTMLButtonElement &&
                (event.target.dataset['testid'] === 'TagSelect__target' ||
                  event.target.dataset['testid'] === 'TagSelect__selection')
              ) {
                // Dont Open Drawer if clicking Status Picker
                return
              }
              showDrawer(type, actionId, event)
            },
          })}
          sx={{
            transition: '200ms background-color',
            cursor: 'pointer',
            borderRadius: 'medium',

            '&:hover': hasHover
              ? {
                  backgroundColor: 'background-light',
                }
              : {},

            ...(menuVisible
              ? {
                  backgroundColor: 'background-light',
                }
              : {}),
          }}
        >
          {children}
        </div>
      )}
    </ContextMenu>
  )
}

import { Editor } from '@tiptap/react'
import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

export type EditableFormControllerProps = UseFormRegisterReturn & {
  children: (props: {
    ref?: React.Ref<Editor>
    onChange?: (value: string) => void
    onBlur?: () => void
  }) => React.ReactElement
}

type FakeInputRef = {
  value: string
  focus: () => void
}

/**
 * Binds react-hook-form to TipTap API.
 */
export const EditableFormController = React.forwardRef<FakeInputRef, EditableFormControllerProps>(
  ({ name, onChange, onBlur, children }, ref) => {
    const [editor, setEditor] = useState<Editor | null>(null)
    const refAdapter = useMemo<FakeInputRef | null>(
      () =>
        editor && {
          get value() {
            return editor.getHTML()
          },
          set value(value: string) {
            editor.commands.setContent(value)
          },
          focus: () => {
            editor.commands.focus()
          },
        },
      [editor],
    )

    useImperativeHandle<FakeInputRef | null, FakeInputRef | null>(ref, () => refAdapter, [refAdapter])

    const handleChange = useCallback(
      (value: string) =>
        onChange({
          target: {
            value,
            name,
          },
          type: 'change',
        }),
      [onChange, name],
    )
    const handleBlur = useCallback(
      () =>
        onBlur({
          target: {
            value: editor?.getHTML() ?? '',
            name,
          },
          type: 'blur',
        }),
      [editor, onBlur, name],
    )

    return children({
      ref: setEditor,
      onChange: handleChange,
      onBlur: handleBlur,
    })
  },
)

EditableFormController.displayName = 'EditableFormController'

import { AddWaitingForRowData, Suspender, useFeatureFlags } from '@mm/company-ui'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { TopicDescriptionDocument } from '../../../gen/graphql/documents'
import { ADD_ACTION } from '../../actions/capabilities'
import { createActionOnTopicCommand } from '../../actions/commands/createActionOnTopic'
import { useQuery } from '../../apollo'
import { CapabilitiesProvider, RegisterCapability } from '../../capabilities'
import { useCommand } from '../../commands'
import { DocumentEditor } from '../../editor'

export type TopicDescriptionProps = {
  topicId: string
  fillHeight?: boolean
  padding?: number
}

export const TopicDescription = ({ topicId, fillHeight = true, padding = 3 }: TopicDescriptionProps) => {
  const { descriptionActions } = useFeatureFlags()
  const addAction = useCommand(createActionOnTopicCommand)
  const { data, loading, error } = useQuery(TopicDescriptionDocument, {
    variables: {
      id: topicId,
    },
  })

  const handleOnAddAction = useCallback(
    async (data: AddWaitingForRowData) => {
      void addAction.execute({
        topicId,
        data: {
          title: data.title,
          assignee: data.assignee,
          dateRescheduled: false,
          description: data.description,
          dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
          waitingFor: data.waitingFor,
          repeat: data.repeat,
        },
      })
    },
    [topicId, addAction],
  )

  const topic = data?.topic
  if (topic == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Topic not found')
  }

  // console.log(yDocStateToHtml(toByteArray(topic.description.byteContent)))

  return (
    <CapabilitiesProvider>
      <RegisterCapability capability={ADD_ACTION} handler={handleOnAddAction} skip={!descriptionActions} />

      <DocumentEditor
        key={topic.description.id}
        documentId={topic.description.id}
        placeholder="Enter description here..."
        mode="compact"
        sx={{
          '&': {
            display: 'flex',
            flexDirection: 'column',

            ...(fillHeight && {
              height: '100%',
            }),
          },
          '.ProseMirror': {
            margin: 0,
            padding,
            minHeight: 'auto',

            ...(fillHeight && {
              padding: 5,
              overflowY: 'scroll',
              height: '100%',
              minHeight: '100%',
            }),
          },
        }}
      />
    </CapabilitiesProvider>
  )
}

import { BaseLink, Flex, Suspender } from '@mm/company-ui'
import React from 'react'
import { ActionTitleSectionDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useTotalComments } from '../../hooks/useTotalComments'
import { ActionTitle } from './ActionTitle'
import { CreatedContext } from './CreatedContext'

export type ActionTitleSectionProps = {
  actionId: string
}

export const ActionTitleSection = React.forwardRef<HTMLDivElement, ActionTitleSectionProps>(({ actionId }, ref) => {
  const { data, loading, error } = useQuery(ActionTitleSectionDocument, {
    variables: {
      actionId,
    },
  })

  const action = data?.action
  const { commentsLabel } = useTotalComments(action)

  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Action not found')
  }

  return (
    <Flex column ref={ref} sx={{ padding: 1 }}>
      <CreatedContext createdAt={action.createdAt} parent={action.followUpTo ?? action.parent ?? undefined} />
      <ActionTitle id={actionId} cached />

      <Flex gap={2}>
        <BaseLink href="#comments" sx={{ fontSize: 0, color: 'text-light' }}>
          {commentsLabel}
        </BaseLink>
      </Flex>
    </Flex>
  )
})

ActionTitleSection.displayName = 'ActionTitleSection'

import EmojiMartPicker from '@emoji-mart/react'
import { useTheme } from '@emotion/react'
import { hexToRgb } from '@mm/company-ui'
import React from 'react'
import { emojisData } from './EmojisData'

export type EmojiPickerProps = {
  onSelect: (emoji: string) => void
}

export const EmojiPicker = ({ onSelect }: EmojiPickerProps) => {
  const theme = useTheme()

  return (
    <div
      sx={{
        'em-emoji-picker': {
          '--rgb-color': hexToRgb(theme.colors['text'])?.join(', '),
          '--rgb-accent': hexToRgb(theme.colors['accent-border'])?.join(', '),
          '--font-family': theme.fonts.body,
          '--font-size': `${theme.fontSizes[1]}px`,
          '--shadow': theme.shadows.default,
          border: '1px solid',
          borderColor: theme.colors['border'],
        },
      }}
    >
      <EmojiMartPicker
        data={emojisData}
        autoFocus
        emojiButtonRadius="8px"
        emojiButtonColors={[theme.colors['background-medium-alpha']]}
        onEmojiSelect={(emoji) => {
          onSelect(emoji.shortcodes)
        }}
        theme="light"
      />
    </div>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ContractMinimizeIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="contract-minimize" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9697 1.03027C19.3212 1.38174 19.3212 1.95159 18.9697 2.30306L12.3031 8.96972C11.9516 9.3212 11.3817 9.3212 11.0303 8.96972C10.6788 8.61825 10.6788 8.04841 11.0303 7.69693L17.6969 1.03027C18.0484 0.678795 18.6183 0.678795 18.9697 1.03027Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 1.59999C12.1637 1.59999 12.5667 2.00293 12.5667 2.49999V7.43332H17.5C17.9971 7.43332 18.4 7.83627 18.4 8.33332C18.4 8.83038 17.9971 9.23332 17.5 9.23332H11.6667C11.1696 9.23332 10.7667 8.83038 10.7667 8.33332V2.49999C10.7667 2.00293 11.1696 1.59999 11.6667 1.59999Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.96972 11.0303C9.3212 11.3817 9.3212 11.9516 8.96972 12.3031L2.30306 18.9697C1.95159 19.3212 1.38174 19.3212 1.03027 18.9697C0.678795 18.6183 0.678795 18.0484 1.03027 17.6969L7.69693 11.0303C8.04841 10.6788 8.61825 10.6788 8.96972 11.0303Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.59998 11.6667C1.59998 11.1696 2.00292 10.7667 2.49998 10.7667H8.33331C8.83037 10.7667 9.23331 11.1696 9.23331 11.6667V17.5C9.23331 17.9971 8.83037 18.4 8.33331 18.4C7.83625 18.4 7.43331 17.9971 7.43331 17.5V12.5667H2.49998C2.00292 12.5667 1.59998 12.1637 1.59998 11.6667Z"
    />
  </IconBase>
)

import { isGraphQLError } from '@mm/graphql-helpers'
import { DeletePrototypeFeedbackCommandDocument } from '../../../gen/graphql/documents'
import { evictCache } from '../../apollo'
import { Command } from '../../commands'

type DeleteFeedbackCommandValue = {
  prototypeFeedbackId: string
  onFeedbackDeleted?: () => void
}

export const deleteFeedbackCommand: Command<DeleteFeedbackCommandValue> = {
  id: 'deleteFeedbackCommand',
  name: 'Delete Feedback',
  canExecute: () => true,
  execute: async ({ prototypeFeedbackId, onFeedbackDeleted }, { apolloClient }) => {
    const { data } = await apolloClient.mutate({
      mutation: DeletePrototypeFeedbackCommandDocument,
      variables: {
        prototypeFeedbackId,
      },

      update: (cache, { data }) => {
        if (data?.deletePrototypeFeedback.__typename === 'Success') {
          evictCache(cache, 'PrototypeFeedback', prototypeFeedbackId)
          onFeedbackDeleted?.()
        }
      },
      optimisticResponse: {
        deletePrototypeFeedback: {
          __typename: 'Success',
        },
      },
    })
    if (data?.deletePrototypeFeedback && isGraphQLError(data?.deletePrototypeFeedback)) {
      alert(`Delete feedback failed: ${data?.deletePrototypeFeedback.message ?? 'unknown error'}`)
    } else if (data?.deletePrototypeFeedback.__typename !== 'Success') {
      alert('Delete feedback failed: unknown error')
    }
  },
}

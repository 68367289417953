import { StatusMenu } from '@mm/company-ui'
import React, { useCallback } from 'react'
import { useEditAction } from '../../DoneNotDone'
import { statusCheckboxToActionStatus } from '../helpers'

export type ActionStatusMenuProps = {
  id: string
  withLabel?: boolean
  className?: string
}

const actionStatuses = ['active', 'resolved', 'on-hold', 'canceled'] as const

export const ActionStatusMenu = ({ id }: ActionStatusMenuProps) => {
  const editAction = useEditAction(id)

  const handleChange = useCallback(
    (checkboxStatus: (typeof actionStatuses)[number]) => {
      const status = statusCheckboxToActionStatus(checkboxStatus)
      void editAction?.({ status })
    },
    [editAction],
  )

  return <StatusMenu type="action" options={actionStatuses} shape="circle" onChange={handleChange} />
}

import { InputRule } from '@tiptap/core'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { canJoin, findWrapping } from 'prosemirror-transform'

const inputRegex = /^(\d+)\.\s$/

export const OrderedListExtension = OrderedList.extend({
  addInputRules() {
    return [
      new InputRule({
        find: inputRegex,
        handler: ({ state, range, match }) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const start = +match[1]!
          const { tr } = state

          const before = tr.doc.resolve(range.from - 1).nodeBefore

          let shouldJoin = false
          if (start > 1) {
            if (
              before &&
              before.type === this.type &&
              before.childCount + (before.attrs['start'] as number) === start
            ) {
              shouldJoin = true
            } else {
              return
            }
          }

          const doWrap = (dryRun?: boolean) => {
            const $start = tr.doc.resolve(range.from)
            const blockRange = $start.blockRange()
            const wrapping = blockRange && findWrapping(blockRange, this.type, { start })
            if (blockRange && wrapping) {
              if (!dryRun) tr.wrap(blockRange, wrapping)
              return true
            } else {
              return false
            }
          }

          if (!doWrap(true)) {
            return
          }

          tr.delete(range.from, range.to)
          doWrap()

          if (shouldJoin && canJoin(tr.doc, range.from - 1)) {
            tr.join(range.from - 1)
          }
        },
      }),
    ]
  },
})

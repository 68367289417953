import styled from '@emotion/styled'
import { Flyout, FlyoutDivider, RadioButton, RadioGroup, Tooltip } from '@mm/company-ui'
import React, { useState } from 'react'
import { css } from 'theme-ui'
import { DecisionPrivacyStatus } from '../../../gen/graphql/documents'
import { PrivacyChip } from '../../privacy'

export type CreateDecisionPrivacyProps = {
  privacyLevel: DecisionPrivacyStatus
  setPrivacyLevel: React.Dispatch<React.SetStateAction<DecisionPrivacyStatus>>
  isParentMeetingPublic?: boolean
}

export const CreateDecisionPrivacy = ({
  privacyLevel,
  setPrivacyLevel,
  isParentMeetingPublic,
}: CreateDecisionPrivacyProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const DecisionPrivacyMenu = () => {
    return (
      <RadioGroup
        shape="tick"
        value={privacyLevel}
        onChange={(privacy) => {
          setPrivacyLevel(privacy)
          setIsVisible(false)
        }}
        aria-label="Privacy level"
        sx={{ width: '350px' }}
      >
        <MenuItem value="PUBLIC" description={<>Visible to everyone at the company</>}>
          Public
        </MenuItem>
        <FlyoutDivider sx={{ my: 0 }} />
        <MenuItem
          value="PRIVATE"
          description="Visible to participants of this issue and participants of any meeting this issue is linked to"
        >
          Private
        </MenuItem>
      </RadioGroup>
    )
  }

  return (
    <Flyout
      disabled={isParentMeetingPublic}
      position="bottom-start"
      content={<DecisionPrivacyMenu />}
      contentWrapperProps={{ sx: { py: 0 } }}
      visible={isVisible}
      onClickOutside={() => {
        setIsVisible(false)
      }}
      onClick={() => {
        setIsVisible((state) => !state)
      }}
    >
      <Tooltip
        position="bottom-start"
        description={
          isParentMeetingPublic
            ? "This issue is public because it's part of a public Meeting. To change its visibility, update the privacy setting of the Meeting"
            : privacyLevel === 'PRIVATE'
            ? 'Private - Visible to participants of this issue and participants of any meeting this issue is linked to'
            : 'Public - Visible to everyone at the company'
        }
        sx={{ maxWidth: '300px', fontSize: 1, lineHeight: 1.5 }}
      >
        <PrivacyChip privacyLevel={privacyLevel} />
      </Tooltip>
    </Flyout>
  )
}

const MenuItem = styled(RadioButton)(
  css({
    padding: 2,
    ':hover': {
      bg: 'background-light',
    },
  }),
)

import styled from '@emotion/styled'
import { oembed } from '@loomhq/loom-embed'
import {
  Avatar,
  Button,
  defaultItems,
  Editable,
  EditableRef,
  Flex,
  formatUserDisplayName,
  MotionFlex,
  Text,
  toast,
  Tooltip,
  useMergeRefs,
  UserDisplay,
} from '@mm/company-ui'
import { HTMLContent } from '@tiptap/core'
import React, { useCallback, useRef, useState } from 'react'
import { css } from 'theme-ui'
import uploadcare, { FileInfo } from 'uploadcare-widget/uploadcare.api'
import { RecordLoomButton } from '../../components/RecordLoomButton'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { useComplexToolbar } from '../../editor/hooks/useComplexToolbar'
import { useFeatureFlags } from '../../featureFlags'
import { useActiveUser } from '../../users'

export type CommentComposerSubmitData = {
  htmlBody: string
  author?: string
}

export type CommentComposerProps = {
  user: UserDisplay
  placeholder: string
  initialHtmlBody?: string
  initialAuthor?: string
  collapsible?: boolean
  showCancel?: boolean
  submitLabel: string
  onSubmit?: (data: CommentComposerSubmitData) => Promise<void> | void
  onBlur?: () => void
  onCancel?: () => void
  onChange?: (content: HTMLContent) => void
}

export const CommentComposer = React.forwardRef<EditableRef, CommentComposerProps>(
  (
    {
      user,
      placeholder,
      initialHtmlBody,
      initialAuthor,
      collapsible,
      showCancel,
      submitLabel,
      onSubmit,
      onCancel,
      onBlur,
      onChange,
    },
    ref,
  ) => {
    const items = useComplexToolbar(defaultItems, ['add-action'])
    const { addActionWhenComposingComment, editorImageUpload, canCreateOnBehalfOfOtherUsers, recordLoomOnComments } =
      useFeatureFlags()
    const editableRef = useRef<EditableRef>(null)
    const forwardRef = useMergeRefs([ref, editableRef])
    const [focusedEl, setFocusedEl] = useState<HTMLElement>()
    const [hasText, setHasText] = useState(!!initialHtmlBody)
    const activeUser = useActiveUser()
    const [author, setAuthor] = React.useState(initialAuthor || activeUser?.id || '')
    const [isRecording, setIsRecording] = useState(false)

    const [submitting, setSubmitting] = useState(false)
    const [onlyWhiteSpaces, setOnlyWhiteSpaces] = useState(isOnlyWhiteSpaces(initialHtmlBody || ''))

    const handleSubmit = useCallback(() => {
      if (onlyWhiteSpaces) {
        return
      }
      void (async () => {
        try {
          setSubmitting(true)
          editableRef.current?.commands.blur()
          await onSubmit?.({
            htmlBody: editableRef.current?.getHTML() ?? '',
            author,
          })
          editableRef.current?.commands.clearContent(true)
          setFocusedEl(undefined)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('failed to submit a comment', e)
        } finally {
          setSubmitting(false)
        }
      })()
    }, [onSubmit, onlyWhiteSpaces, author])

    const handleCancel = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        onCancel?.()
        if (editableRef.current?.isEmpty) {
          event.currentTarget.blur()
        }
      },
      [onCancel],
    )

    const handleUpload = useCallback(
      async (image: File) => {
        if (editorImageUpload) {
          return await new Promise<string>((resolve, reject) => {
            uploadcare
              .fileFrom('object', image, {
                publicKey: process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY,
              })
              // .progress((uploadInfo) => {
              //   console.log('Upload progress: ' + uploadInfo.progress)
              // })
              .done((fileInfo: FileInfo) => {
                resolve(fileInfo.originalUrl)
              })
              .fail((_errorType: string, _fileInfo?: FileInfo, error?: Error | XMLHttpRequest) => {
                const isNetworkError = error && 'readyState' in error

                // eslint-disable-next-line no-console
                console.error(error)
                toast(isNetworkError ? 'Networking error uploading image' : 'Error uploading image')
                reject()
              })
          })
        }

        return await Promise.reject()
      },
      [editorImageUpload],
    )
    const handleInsertLoom = useCallback(async (loomUrl: string) => {
      const embed = await oembed(loomUrl, { width: 500, height: 300 })
      const htmlWithDataAttr = embed.html
        .replace('<iframe', '<p></p><iframe data-loom')
        .replace('</iframe>', '</iframe><p></p>')
      editableRef.current?.chain().focus('end').insertContent(htmlWithDataAttr).focus('end').run()
      setOnlyWhiteSpaces(false)
    }, [])

    const onRecordLoomStart = useCallback(() => {
      setIsRecording(true)
    }, [])
    const onRecordLoomEnd = useCallback(() => {
      setIsRecording(false)
    }, [])

    const active = focusedEl != null || hasText || !collapsible
    return (
      <Flex row gap={1.5} align="flex-start" sx={{ px: 1 }}>
        <Avatar name={formatUserDisplayName(user)} sx={{ marginTop: 1 }} />
        <Container
          column
          grow
          active={active}
          onFocus={(ev) => {
            setFocusedEl(ev.target)
          }}
          onBlur={(ev) => {
            setFocusedEl((el) => (el === ev.target ? undefined : el))
            onBlur?.()
          }}
          initial={{
            height: 40,
          }}
          animate={{
            height: active ? 'auto' : 40,
          }}
          transition={{ type: 'tween' }}
        >
          <Editable
            ref={forwardRef}
            mode="compact"
            placeholder={placeholder}
            hidePlaceholderWhenFocused
            initialValue={initialHtmlBody}
            onChange={onChange}
            onChangeRaw={({ editor }) => {
              setHasText(!editor.isEmpty)
              setOnlyWhiteSpaces(isOnlyWhiteSpaces(editor.getText()))
            }}
            hotkeys={{
              'Meta-Enter': () => {
                handleSubmit()
                return true
              },
            }}
            sx={{
              minHeight: 10,
              px: 1.5,
              py: 1,
              maxHeight: '70vh',
              overflowY: 'auto',
            }}
            editable={!submitting}
            selectionToolbar={addActionWhenComposingComment ? items : undefined}
            onUpload={handleUpload}
          />
          <Flex row gap={1.5} sx={{ borderTop: '1px solid', borderColor: 'border', p: 1 }}>
            <div sx={{ flex: 1 }}>
              {canCreateOnBehalfOfOtherUsers && (
                <Flex align="center" gap={1}>
                  <Text color="text-light" variant="small">
                    Author:
                  </Text>
                  <UserSelectWithCompanyMembers
                    withName
                    value={author}
                    onAdd={setAuthor}
                    targetProps={{ size: 'small' }}
                  />
                </Flex>
              )}
            </div>

            {showCancel && (
              <Button size="small" onClick={handleCancel}>
                Cancel
              </Button>
            )}

            {recordLoomOnComments ? (
              <Flex gap={1}>
                <RecordLoomButton
                  onClick={(e) => {
                    setFocusedEl(e.target as HTMLElement)
                  }}
                  onInserted={handleInsertLoom}
                  onStart={onRecordLoomStart}
                  onComplete={onRecordLoomEnd}
                />
              </Flex>
            ) : null}

            {isRecording ? (
              <Tooltip position="top" description="Please finish recording before adding a comment.">
                <Button
                  variant="accent"
                  size="small"
                  onClick={handleSubmit}
                  loading={submitting}
                  disabled={onlyWhiteSpaces || isRecording}
                >
                  {submitLabel}
                </Button>
              </Tooltip>
            ) : (
              <Button
                variant="accent"
                size="small"
                onClick={handleSubmit}
                loading={submitting}
                disabled={onlyWhiteSpaces || isRecording}
              >
                {submitLabel}
              </Button>
            )}
          </Flex>
        </Container>
      </Flex>
    )
  },
)

CommentComposer.displayName = 'CommentComposer'

export const Container = styled(MotionFlex)<{ active?: boolean }>(
  ({ theme }) =>
    css({
      border: '1px solid',
      borderColor: 'transparent',
      borderRadius: 'default',
      boxShadow: 'defaultTransparent',
      transition: theme.transition.default,
      transitionProperty: 'border, boxShadow',
      overflow: 'hidden',
      width: 'min-content',
    }),
  ({ active }) =>
    css(
      active
        ? {
            borderColor: 'border',
            boxShadow: 'default',
          }
        : {
            ':hover': {
              backgroundColor: 'background-light',
            },
          },
    ),
)

export const isOnlyWhiteSpaces = (text: string) => !/\S/.test(text)

import { useFeatureFlags } from '@mm/company-ui/src'
import React from 'react'
import { UpdateCommentsDocument } from '../../../gen/graphql/documents'
import { CommentsList, CommentsListProps } from '../../comments'
import { UpdateCommentsComposer } from './UpdateCommentsComposer'

export type UpdateCommentsProps = Omit<CommentsListProps<unknown, unknown>, 'query' | 'variables' | 'extractPage'> & {
  updateId: string
}

export const UpdateComments = ({ updateId }: UpdateCommentsProps) => {
  const { flipComments } = useFeatureFlags()

  return (
    <CommentsList
      query={UpdateCommentsDocument}
      variables={{ updateId }}
      extractPage={(data) => data.update?.comments}
      order={flipComments ? 'oldest' : 'newest'}
      composer={!flipComments ? <UpdateCommentsComposer updateId={updateId} /> : null}
      fixedComposer={false}
      fixedAndScroll={false}
    />
  )
}

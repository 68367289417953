import { ActionStatus, allActionStatuses } from '@mm/common/src'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { useActiveCompanyId } from '../companies'

export type FilterValue = string | string[] | ActionStatus[]
export const isActionStatusArray = (value: any): value is ActionStatus[] => {
  return Array.isArray(value) && value.every((v) => allActionStatuses.includes(v as ActionStatus))
}

export type FilterConfig = Record<string, FilterValue>
interface FilterContextType {
  filter: FilterConfig
  setFilter: React.Dispatch<React.SetStateAction<Record<string, FilterValue>>>
}

const DisplayFilterContext = createContext<FilterContextType>({
  filter: {},
  setFilter: () => {
    // do nothing
  },
})

export const useDisplayFilter = () => {
  const { filter, setFilter } = useContext(DisplayFilterContext)

  return { filter, setFilter }
}

type FilterLocalStorage = Record<string, Record<string, FilterConfig>>

type DisplayFilterProviderProps = {
  pageKey: string
  initialFilters?: FilterConfig
  skipLocalStorage?: boolean
}

export const DisplayFilterProvider: React.FC<DisplayFilterProviderProps> = ({
  children,
  pageKey,
  initialFilters,
  skipLocalStorage,
}) => {
  const { activeCompanyId } = useActiveCompanyId()
  const [storedFilters, setStoredFilters] = useLocalStorage<FilterLocalStorage>('displayFilter', {})

  const [filter, setFilter] = useState<FilterConfig>(() => {
    if (skipLocalStorage) return initialFilters || {}
    return storedFilters?.[activeCompanyId]?.[pageKey] || initialFilters || {}
  })

  useEffect(() => {
    if (skipLocalStorage) return
    setStoredFilters((prevFilters) => ({
      ...prevFilters,
      [activeCompanyId]: {
        ...prevFilters?.[activeCompanyId],
        [pageKey]: filter,
      },
    }))
  }, [activeCompanyId, filter, pageKey, setStoredFilters, skipLocalStorage])

  return <DisplayFilterContext.Provider value={{ filter, setFilter }}>{children}</DisplayFilterContext.Provider>
}

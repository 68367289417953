import { BaseLink, Flex, Suspender } from '@mm/company-ui'
import React from 'react'
import { TopicTitleSectionDocument } from '../../../gen/graphql/documents'
import { CreatedContext } from '../../actions/components/CreatedContext'
import { useQuery } from '../../apollo'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'
import { TopicTitle } from './TopicTitle'

export type TopicTitleSectionProps = {
  topicId: string
}

export const TopicTitleSection = React.forwardRef<HTMLDivElement, TopicTitleSectionProps>(({ topicId }, ref) => {
  const { data, loading, error } = useQuery(TopicTitleSectionDocument, {
    variables: {
      topicId,
    },
  })

  const topic = data?.topic

  const { commentsLabel } = useTotalComments(topic)
  const { actionsLabel } = useTotalActions(topic)

  if (topic == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Topic not found')
  }

  return (
    <Flex column ref={ref} sx={{ padding: 1 }}>
      <CreatedContext createdAt={topic.createdAt} parent={topic.parent ?? undefined} />
      <TopicTitle cached id={topicId} />

      <Flex gap={2}>
        <BaseLink href="#comments" sx={{ fontSize: 0, color: 'text-light' }}>
          {commentsLabel}
        </BaseLink>
        <BaseLink href="#actions" sx={{ fontSize: 0, color: 'text-light' }}>
          {actionsLabel}
        </BaseLink>
      </Flex>
    </Flex>
  )
})

TopicTitleSection.displayName = 'TopicTitleSection'

import { useCallback } from 'react'
import { CreateActionInput, CreateActionOnTopicDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { Command } from '../../commands'
import { useEmitEventBusEvent } from '../../eventbus'
import { useMeetingContext } from '../../meetings'
import { ActionsUpdatedTopic } from '../subscriptions/GlobalActionsSubscription'
import { markActionAsCreatedHere } from './markAsCreatedHere'

type CreateActionOnTopicCommandValue = {
  topicId: string
  data: CreateActionInput
}

export const createActionOnTopicCommand: Command<CreateActionOnTopicCommandValue> = {
  id: 'createActionOnTopic',
  name: 'Create Action on Topic',
  canExecute: () => {
    return true
  },
  useExecute: () => {
    const meeting = useMeetingContext()
    const emit = useEmitEventBusEvent(ActionsUpdatedTopic)
    return useCallback(
      async ({ topicId, data }, { apolloClient }) => {
        await apolloClient.mutate({
          mutation: CreateActionOnTopicDocument,
          variables: {
            topicId,
            data: {
              ...data,
              parentMeetingId: data.parentMeetingId ?? meeting?.id,
            },
          },
          update: (cache, { data }) => {
            const action = data?.createActionOnTopic
            if (action?.__typename === 'Action') {
              capture('Action Created', {
                parent: 'Topic',
                parentMeetingId: meeting?.id,
              })
              markActionAsCreatedHere(cache, action)
              emit({ type: 'upserted', action })
            }
          },
        })
      },
      [meeting?.id, emit],
    )
  },
}

import { EditableRef, Suspender, useMergeRefs } from '@mm/company-ui'
import { HTMLContent } from '@tiptap/core'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocalStorage } from 'react-use'
import { AddCommentBoxDocument, CommentViewerDataFragment } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCapabilities, useRegisterCapability } from '../../capabilities'
import { useFeatureFlags } from '../../featureFlags'
import {
  FOCUS_COMMENT_INPUT,
  FOCUS_AND_QUOTE_COMMENT,
  FOCUS_AND_QUOTE_COMMENT_AS_DECISION,
  MARK_OR_UNMARK_COMMENT_AS_DECISION,
} from '../capabilities'
import { CommentComposer } from './CommentComposer'

export type AddCommentBoxSubmitData = {
  htmlBody: string
  author?: string
}

export type AddCommentBoxProps = {
  id: string
  placeholder?: string
  onSubmit?: (data: AddCommentBoxSubmitData) => Promise<CommentViewerDataFragment | void>
  registerDecisionCapability?: boolean
  showCancel?: boolean
  onBlur?: () => void
  onCancel?: () => void
}

const LOCAL_STORAGE_KEY = 'DraftComments'

export const AddCommentBox = React.forwardRef<EditableRef, AddCommentBoxProps>(function AddCommentBox(
  { id, placeholder, onSubmit, registerDecisionCapability = false, showCancel, onBlur, onCancel },
  ref,
) {
  const { data, loading, error } = useQuery(AddCommentBoxDocument)
  const { isRegistered, execute } = useCapabilities()
  const { highlightToMakeDecision, decisionBoxInDrawerView } = useFeatureFlags()
  const [shouldRetroactivelyMarkCommentAsDecision, setShouldRetroactivelyMarkCommentAsDecision] = React.useState(false)
  const [draftComments, setDraftComments] = useLocalStorage<Record<string, string>>(LOCAL_STORAGE_KEY, {})
  const initialHtmlBody = draftComments?.[id] || ''

  const handleChange = useMemo(
    () =>
      _.debounce((draftCommentHtml: string) => {
        setDraftComments((draftComments) => ({
          ...draftComments,
          [id]: draftCommentHtml,
        }))
      }, 1000),
    [setDraftComments, id],
  )

  useEffect(() => handleChange.flush, [handleChange])

  const handleSubmit = async (data: AddCommentBoxSubmitData) => {
    if (onSubmit) {
      const comment = await onSubmit(data)
      setDraftComments((draftComments) => _.omit(draftComments, id))

      if (shouldRetroactivelyMarkCommentAsDecision && comment) {
        if (isRegistered(MARK_OR_UNMARK_COMMENT_AS_DECISION) && comment.canMarkOrUnmarkDecision) {
          execute(MARK_OR_UNMARK_COMMENT_AS_DECISION, comment.id)
        }
        setShouldRetroactivelyMarkCommentAsDecision(false)
      }
    }
  }

  const editableRef = useRef<EditableRef>(null)
  const forwardRef = useMergeRefs([ref, editableRef])

  useRegisterCapability(
    FOCUS_COMMENT_INPUT,
    useCallback(() => {
      const editor = editableRef.current
      editor?.commands.focus('end')
    }, []),
  )

  useRegisterCapability(
    FOCUS_AND_QUOTE_COMMENT,
    useCallback((selectedHtml: HTMLContent) => {
      const editor = editableRef.current
      editor?.commands.setContent(`<blockquote>${selectedHtml}</blockquote><p></p>`)
      editor?.commands.focus('end')
    }, []),
  )

  useRegisterCapability(
    FOCUS_AND_QUOTE_COMMENT_AS_DECISION,
    useCallback((selectedHtml: HTMLContent) => {
      setShouldRetroactivelyMarkCommentAsDecision(true)
      editableRef.current?.chain().focus('end').createParagraphNear().insertContent(selectedHtml).run()
    }, []),
    !registerDecisionCapability || !highlightToMakeDecision || decisionBoxInDrawerView,
  )

  const me = data?.me
  if (me == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('Unauthenticated')
  }

  return (
    <CommentComposer
      ref={forwardRef}
      user={me}
      placeholder={placeholder || 'Add a comment'}
      initialHtmlBody={initialHtmlBody !== '<p></p>' ? initialHtmlBody : ''}
      submitLabel={shouldRetroactivelyMarkCommentAsDecision ? 'Make Decision' : 'Add'}
      collapsible
      onSubmit={handleSubmit}
      onChange={handleChange}
      showCancel={showCancel}
      onCancel={onCancel}
      onBlur={onBlur}
    />
  )
})

import { Suspender } from '@mm/company-ui'
import { LinkIcon } from '@mm/company-ui-icons/src'
import React from 'react'
import { GoalParentMeetingDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { PanelMetadataItemChips } from '../../components'

export const GoalParentMeeting = ({ goalId }: { goalId: string }) => {
  const { data, loading, error } = useQuery(GoalParentMeetingDocument, {
    variables: { id: goalId },
  })
  const goal = data?.goal

  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Goal parent meeting not found: ' + goalId)
  }

  if (!goal.parentMeeting?.id) {
    return <></>
  }

  return (
    <PanelMetadataItemChips
      title="Meetings"
      icon={<LinkIcon />}
      chips={[
        {
          id: goal.parentMeeting.id,
          label: goal.parentMeeting.title,
          href: { pathname: '/meetings/[id]', query: { id: goal.parentMeeting.id } },
        },
      ]}
    />
  )
}

import { Suspender } from '@mm/company-ui'
import React from 'react'
import { FeedbackUserFeedDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'
import { EmptyFeedback } from './EmptyFeedback'
import { FeedbackFeed } from './FeedbackFeed'

export type FeedbackUserFeedProps = {
  userId: string
  type?: 'received' | 'given'
}

export function FeedbackUserFeed(props: FeedbackUserFeedProps) {
  const { activeCompanyId: companyId } = useActiveCompanyId()
  const {
    data: feedbackData,
    loading: feedbackLoading,
    error: feedbackError,
    refetch,
  } = useQuery(FeedbackUserFeedDocument, {
    variables: {
      filter: props.type === 'received' ? { companyId, toIds: [props.userId] } : { authorId: props.userId, companyId },
    },
  })

  if (feedbackData?.prototypeFeedbackFeed == null) {
    if (feedbackLoading) {
      return <Suspender />
    }
    throw feedbackError ?? new Error('Feed not found')
  }

  return (
    <div sx={{ marginY: 2 }}>
      <FeedbackFeed
        sources={[
          {
            data: feedbackData.prototypeFeedbackFeed,
            groupBy: 'month',
          },
        ]}
        renderEmptyGroup={() => <EmptyFeedback />}
        onForceRefetch={refetch}
      />
    </div>
  )
}

FeedbackUserFeed.defaultProps = {
  type: 'received',
}

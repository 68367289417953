import { UpdateTopicOwnerCommandDocument, GetTopicOwnerCommandDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditTopicOwnerCommandValue = {
  topicId: string
  ownerId: string
  firstName?: string | null
  lastName?: string | null
  email?: string | null
}

export const editTopicOwnerCommand: Command<EditTopicOwnerCommandValue> = {
  id: 'editTopicOwnerCommand',
  name: 'Edit Owner',
  canExecute: async ({ topicId, ownerId }, { apolloClient }) => {
    const query = await apolloClient.query({ query: GetTopicOwnerCommandDocument, variables: { id: topicId } })
    return query.data.topic?.owner?.id !== ownerId
  },
  execute: async ({ topicId, ownerId, firstName, lastName, email }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const query = await apolloClient.query({ query: GetTopicOwnerCommandDocument, variables: { id: topicId } })

    await apolloClient.mutate({
      mutation: UpdateTopicOwnerCommandDocument,
      variables: {
        id: topicId,
        data: {
          ownerId,
        },
      },
      optimisticResponse: {
        updateTopic: {
          __typename: 'Topic',
          id: topicId,
          owner: {
            id: ownerId,
            firstName,
            lastName,
            email: email || '',
          },
        },
      },
    })

    if (!query.data.topic?.owner) {
      return
    }

    return {
      args: {
        topicId,
        ownerId: query.data.topic.owner.id,
        firstName: query.data.topic.owner.firstName,
        lastName: query.data.topic.owner.lastName,
        email: query.data.topic.owner.email,
      },
      execute: async ({ topicId, ownerId, firstName, lastName, email }, { apolloClient }) => {
        await apolloClient.mutate({
          mutation: UpdateTopicOwnerCommandDocument,
          variables: {
            id: topicId,
            data: {
              ownerId,
            },
          },
          optimisticResponse: {
            updateTopic: {
              __typename: 'Topic',
              id: topicId,
              owner: {
                id: ownerId,
                firstName,
                lastName,
                email: email || '',
              },
            },
          },
        })
      },
    }
  },
}

import styled from '@emotion/styled'
import { Flex, FloatingTooltip, Pressable, Text } from '@mm/company-ui'
import React from 'react'
import { css } from 'theme-ui'
import { Emoji } from './Emoji'

export type EmojiReactionPillProps = {
  emojis: string[]
  count: number
  active: boolean
  tooltip?: React.ReactNode
  onClick?: () => void
}

export const EmojiReactionPill = ({ emojis, count, active, tooltip, onClick }: EmojiReactionPillProps) => {
  return (
    <FloatingTooltip placement="bottom" tooltip={tooltip}>
      {({ getReferenceProps }) => (
        <Pill active={active} {...getReferenceProps({ onClick })}>
          <Flex gap={0.5} align="center">
            {emojis.map((emoji) => (
              <Emoji key={emoji} shortcodes={emoji} />
            ))}
          </Flex>
          <Text variant="small" bold={active} color={active ? 'accent-text' : 'text'}>
            {count}
          </Text>
        </Pill>
      )}
    </FloatingTooltip>
  )
}

const Pill = styled(Pressable)<{ active: boolean }>(
  css({
    display: 'inline-flex',
    alignItems: 'center',
    gap: 0.5,
    borderRadius: '8px',
    border: '1px solid',
    paddingX: 0.5,
    height: 3,
  }),
  ({ active }) =>
    !active
      ? css({
          borderColor: 'transparent',
          bg: 'background-light-alpha',
          '&:hover': {
            bg: 'background',
            borderColor: 'border-strong',
          },
        })
      : css({
          borderColor: 'accent-border',
          bg: 'accent-background-light',
        }),
)

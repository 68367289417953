import React from 'react'
import { DecisionUserReviewsDocument, DecisionUserReviewsToggleReviewDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { UserReviews } from '../../components/UserReviews'
import { useActiveUser } from '../../users'

export type DecisionUserReviewsProps = {
  decisionId: string
  withButton?: boolean
  size?: 'small' | 'medium'
  markAsReviewedOnOpen?: boolean
}

export const DecisionUserReviews = ({ decisionId, ...props }: DecisionUserReviewsProps) => {
  const activeUser = useActiveUser()

  const { data } = useQuery(DecisionUserReviewsDocument, {
    variables: { decisionId },
  })
  const userReviews = data?.decision?.userReviews

  const [toggleUserReview] = useMutation(DecisionUserReviewsToggleReviewDocument, {
    optimisticResponse: {
      toggleDecisionUserReview: {
        __typename: 'Decision',
        id: decisionId,
        ...(userReviews?.some(({ user }) => user.id === activeUser?.id)
          ? {
              userReviews: userReviews.filter(({ user }) => user.id !== activeUser?.id),
            }
          : {
              userReviews: [
                ...(userReviews || []),
                {
                  __typename: 'UserReview',
                  user: {
                    __typename: 'User',
                    id: activeUser?.id || '',
                    firstName: activeUser?.firstName,
                    lastName: activeUser?.lastName,
                    email: activeUser?.email || '',
                  },
                  reviewedAt: Date.now(),
                },
              ],
            }),
      },
    },
  })

  return (
    <UserReviews
      {...props}
      key={decisionId}
      userReviews={userReviews}
      onToggle={() => {
        void toggleUserReview({ variables: { decisionId } })
      }}
    />
  )
}

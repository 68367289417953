import { Flex, Suspender } from '@mm/company-ui'
import React from 'react'
import { DecisionCommentsDocument, DecisionUserCommentsListDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { Comment } from '../../comments/components/Comment'
import { useActiveUser } from '../../users'

export type DecisionCommentsDecisionsProps = {
  decisionId: string
}

export const DecisionCommentsDecisions = ({ decisionId }: DecisionCommentsDecisionsProps) => {
  const user = useActiveUser()
  const { data, loading, error } = useQuery(DecisionCommentsDocument, {
    variables: {
      decisionId,
    },
  })

  const decision = data?.decision
  const decidedComments = decision?.decidedComments ?? []

  const {
    data: userCommentsData,
    loading: userCommentsLoading,
    error: userCommentsError,
  } = useQuery(DecisionUserCommentsListDocument, {
    fetchPolicy: 'no-cache',
    variables: {
      decisionId,
      first: 1,
      authorId: user?.id,
    },
  })

  if (decision == null || userCommentsData == null) {
    if (loading || userCommentsLoading) {
      return <Suspender />
    }
    throw error ?? userCommentsError ?? new Error('Decision not found')
  }
  return decidedComments.length != 0 ? (
    <Flex column sx={{ padding: 1, paddingBottom: 0 }} gap={1}>
      {decidedComments.map(({ comment }) => {
        return <Comment key={comment.id} id={comment.id} showDecisionMark />
      })}
    </Flex>
  ) : null
  // <div sx={{ textAlign: 'center' }}>
  //   <Text variant="small" color="text-light">
  //     No decision yet.
  //   </Text>
  // </div>
}

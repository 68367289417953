import { ActionStatus } from '@mm/common'
import { formatDateDisplay, formatUserDisplayName } from '@mm/company-ui/src'
import { DateTime } from 'luxon'
import { ActionRepeatType } from '../../../../../../libs/graphql-helpers/gen/graphql/graphql'
import {
  AddActionCommentBoxDocument,
  UpdateActionRepeatDocument,
  UseEditActionDocument,
} from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useMutation, useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { addRootCommentToCache } from '../../comments'
import { useFeatureFlags } from '../../featureFlags'
import { useActiveUser } from '../../users'
import { editActionDueAtCommand } from '../commands/editActionDueAtCommand'
import { editActionStatusCommand } from '../commands/editActionStatusCommand'
import { useDoneNotDone } from '../DoneNotDoneContext'

type EditActionArgs = {
  status?: ActionStatus
  dueAt?: number
  repeat?: ActionRepeatType
  forceNotDoneFlow?: boolean
}

export const useEditAction = (actionId: string) => {
  const { silentNotDoneFlowWhenReschedulingActions, preventOldGoalAndActionUpdates } = useFeatureFlags()
  const editActionStatus = useCommand(editActionStatusCommand)
  const editActionDueAt = useCommand(editActionDueAtCommand)
  const [editActionRepeat] = useMutation(UpdateActionRepeatDocument)
  const { show } = useDoneNotDone()
  const activeUser = useActiveUser()

  const { data } = useQuery(UseEditActionDocument, {
    fetchPolicy: 'cache-first',
    variables: {
      id: actionId,
    },
  })

  const [addComment] = useMutation(AddActionCommentBoxDocument, {
    update: (cache, { data }) => {
      const comment = data?.createCommentOnAction
      if (comment?.__typename === 'Comment') {
        addRootCommentToCache(cache, comment)
      }
    },
  })

  const createdAt = data?.action?.createdAt

  if (!createdAt) {
    return undefined
  }

  return async ({ status, dueAt, repeat, forceNotDoneFlow = false }: EditActionArgs) => {
    if (preventOldGoalAndActionUpdates) {
      if (status) {
        await editActionStatus.execute({ actionId, status })
      }
      if (dueAt) {
        await editActionDueAt.execute({ actionId, dueAt })
      }
      if (repeat) {
        await editActionRepeat({ variables: { id: actionId, data: { repeat } } })
      }
      return
    }

    if (!forceNotDoneFlow) {
      if (status === 'DONE' || status === 'ACTIVE') {
        await editActionStatus.execute({ actionId, status })
        return
      }

      if (dueAt && DateTime.fromMillis(createdAt).hasSame(DateTime.now(), 'day')) {
        await editActionDueAt.execute({ actionId, dueAt })
        return
      }

      if (repeat) {
        await editActionRepeat({ variables: { id: actionId, data: { repeat } } })
        return
      }

      if (dueAt && silentNotDoneFlowWhenReschedulingActions) {
        const { data } = await addComment({
          variables: {
            actionId,
            htmlBody: `<blockquote><p>Rescheduled${
              activeUser ? ` by ${formatUserDisplayName(activeUser)}` : ''
            } to ${formatDateDisplay(dueAt)}</p></blockquote>`,
          },
        })

        if (data?.createCommentOnAction.__typename !== 'Comment') {
          throw new Error('failed to create a comment')
        }
        await editActionDueAt.execute({ actionId, dueAt })

        return
      }
    }

    show({
      actionId,
      dueAt,
      variant: dueAt ? 'reschedule' : status === 'ON_HOLD' ? 'hold' : 'cancel',
      onSubmit: async ({ htmlBody, cancel, dueAt }) => {
        capture('Action Not Done Submitted', {})

        const { data } = await addComment({
          variables: {
            actionId,
            htmlBody: `<blockquote><p>Reason for ${
              cancel ? 'cancellation' : dueAt ? 'rescheduling' : 'on hold'
            }</p></blockquote>${htmlBody}`,
          },
        })

        if (data?.createCommentOnAction.__typename !== 'Comment') {
          throw new Error('failed to create a comment')
        }

        if (cancel) {
          await editActionStatus.execute({ actionId, status: 'CANCELLED' })
        } else if (dueAt) {
          await editActionDueAt.execute({ actionId, dueAt })
        } else {
          await editActionStatus.execute({ actionId, status: 'ON_HOLD' })
        }
      },
    })
  }
}

import { useFeatureFlags } from '@mm/company-ui'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

type StableDrawerContext = {
  registerNavigationProvider: (type: string, provider: DrawerNavigationProvider) => () => void
}
type UnstableDrawerContext = {
  navigationProviders: Record<string, DrawerNavigationProvider[]>
}

const stableContext = React.createContext<StableDrawerContext | null>(null)
const unstableContext = React.createContext<UnstableDrawerContext | null>(null)

export const ContextualDrawerProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [navigationProviders, setNavigationProviders] = useState<UnstableDrawerContext['navigationProviders']>({})

  const stableValue = useMemo<StableDrawerContext>(
    () => ({
      registerNavigationProvider: (type, provider) => {
        // cloning the function to make it unique within the list
        const clone: DrawerNavigationProvider = provider.bind(null)
        setNavigationProviders((current) => {
          const providers = current[type] ?? []
          return {
            ...current,
            [type]: providers.concat(clone),
          }
        })
        return () => {
          setNavigationProviders(({ [type]: providers = [], ...rest }) => ({
            ...rest,
            [type]: providers.filter((provider) => provider !== clone),
          }))
        }
      },
    }),
    [],
  )
  const unstableValue = useMemo(() => ({ navigationProviders }), [navigationProviders])

  return (
    <stableContext.Provider value={stableValue}>
      <unstableContext.Provider value={unstableValue}>{children}</unstableContext.Provider>
    </stableContext.Provider>
  )
}

export const ContextualDrawerNavigationWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const stableValue = useMemo(
    () => ({
      registerNavigationProvider: () => () => {
        // ignore registerNavigationProvider within drawers
      },
    }),
    [],
  )

  return <stableContext.Provider value={stableValue}>{children}</stableContext.Provider>
}

export const useGetDrawerNavigation = () => {
  const ctxValue = React.useContext(unstableContext)
  const navigationProviders = ctxValue?.navigationProviders
  return useCallback(
    (type: string, id: string, sectionId?: string) => {
      for (const provider of navigationProviders?.[type] ?? []) {
        const result = provider(id, sectionId)
        if (result != null) {
          return result
        }
      }
      return null
    },
    [navigationProviders],
  )
}

export const useRegisterDrawerNavigationProvider = (type: string, provider: DrawerNavigationProvider) => {
  const { contextualDrawerNavigation } = useFeatureFlags()
  const ctxValue = React.useContext(stableContext)
  const registerNavigationProvider = ctxValue?.registerNavigationProvider

  useEffect(() => {
    if (!contextualDrawerNavigation) return

    return registerNavigationProvider?.(type, provider)
  }, [registerNavigationProvider, type, provider, contextualDrawerNavigation])
}

type DrawerNavigationProvider = (
  id: string,
  sectionId?: string,
) => {
  itemIndex: number
  totalItemsCount: number
  hasMoreItems: boolean
  displayNext: (() => Promise<void> | void) | null | undefined
  displayPrevious: (() => Promise<void> | void) | null | undefined
} | null

import React from 'react'
import { ActionCreatedContextFragmentFragmentDoc } from '../../../gen/graphql/documents'
import { useFragment_experimental } from '../../apollo'
import { CreatedContext } from './CreatedContext'

export function ActionCreatedContext({ actionId }: { actionId: string }) {
  const { data } = useFragment_experimental({
    fragment: ActionCreatedContextFragmentFragmentDoc,
    fragmentName: 'ActionCreatedContextFragment',
    from: {
      __typename: 'Action',
      id: actionId,
    },
  })

  if (!data) {
    // eslint-disable-next-line no-console
    console.log('Fragment returned no data, make sure a parent of ActionCreatedContext is querying the fragment')
    return null
  }

  return (
    <CreatedContext
      createdAt={data.createdAt}
      parent={data.followUpTo ?? data.parent ?? undefined}
      parentMeeting={data.parentMeeting ?? undefined}
    />
  )
}

import { convertHtmlToTextTitle, sanitizeTitleHtmlContent } from '@mm/tiptap-extension'
import { GetDecisionTitleDocument, UpdateDecisionTitleDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditDecisionTitleCommandValue = {
  title: string
  decisionId: string
}

export const editDecisionTitleCommand: Command<EditDecisionTitleCommandValue> = {
  id: 'editDecisionTitleCommand',
  name: 'Edit Issue Title',
  canExecute: () => true,
  useExecute: () => {
    return async ({ title, decisionId }, { apolloClient }) => {
      // Get current value from cache or refetch if needed
      const query = await apolloClient.query({ query: GetDecisionTitleDocument, variables: { id: decisionId } })

      await apolloClient.mutate({
        mutation: UpdateDecisionTitleDocument,
        variables: {
          id: decisionId,
          data: {
            title,
          },
        },
        optimisticResponse: {
          updateDecision: {
            __typename: 'Decision',
            id: decisionId,
            title: convertHtmlToTextTitle(title),
            titleHtml: sanitizeTitleHtmlContent(title),
          },
        },
      })

      if (!query.data.decision) {
        return
      }

      return {
        args: {
          title: query.data.decision.title,
          decisionId,
        },
        execute: async ({ title, decisionId }, { apolloClient }) => {
          await apolloClient.mutate({
            mutation: UpdateDecisionTitleDocument,
            variables: {
              id: decisionId,
              data: {
                title,
              },
            },
            optimisticResponse: {
              updateDecision: {
                __typename: 'Decision',
                id: decisionId,
                title: convertHtmlToTextTitle(title),
                titleHtml: sanitizeTitleHtmlContent(title),
              },
            },
          })
        },
      }
    }
  },
}

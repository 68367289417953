import { Card, Flex } from '@mm/company-ui'
import { CloseIcon } from '@mm/company-ui-icons'
import React, { ReactNode } from 'react'
import { useDismissContent } from '../hooks/useDismissContent'

export type IntroCardProps = {
  contentId?: string
  children?: ReactNode | undefined
  embedUrl?: string
}

export function IntroCard({ children, contentId, embedUrl }: IntroCardProps) {
  const [dismissed, dismiss] = useDismissContent(contentId)

  if (dismissed) {
    return <></>
  }

  return (
    <Card
      role="banner"
      variant="flat"
      sx={{ background: '#ECECF8', margin: 2, padding: 0, border: 0, borderRadius: 8, position: 'relative' }}
    >
      <Flex
        row
        gap={30}
        wrap="wrap"
        sx={{ width: '100%', paddingTop: 38, paddingLeft: 47, paddingRight: 38 }}
        justify="space-around"
        align="stretch"
      >
        <Flex column sx={{ flex: 1, paddingTop: 53, paddingBottom: 38, minWidth: 486 }}>
          {children}
        </Flex>
        {embedUrl && (
          <Flex
            sx={{
              borderRadius: '8px 8px 0 0',
              marginRight: 50,
              alignSelf: 'flex-end',
              width: 486,
              height: 304,
              overflow: 'hidden',
            }}
          >
            <iframe width="100%" height="100%" frameBorder="0" src={embedUrl} allowFullScreen></iframe>
          </Flex>
        )}
      </Flex>
      {contentId && (
        <div
          role="button"
          sx={{
            width: 30,
            height: 30,
            padding: '5px',
            cursor: 'pointer',
            position: 'absolute',
            right: 16,
            top: 16,
          }}
          onClick={dismiss}
        >
          <CloseIcon />
        </div>
      )}
    </Card>
  )
}

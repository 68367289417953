import { ConfirmPopover, FlyoutDivider, FlyoutItem, toast, useConfirmPopover } from '@mm/company-ui'
import { DeleteIcon, ExpandMaximizeIcon, LinkIcon, PencilIcon } from '@mm/company-ui-icons'
import Router from 'next/router'
import { route } from 'nextjs-routes'
import React from 'react'
import { UpdateMoreMenuDeleteDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { evictCache, isGraphQLError, useMutation } from '../../apollo'

export type UpdateMoreMenuProps = {
  updateId: string
  showEditOption?: boolean
  onEditClick?: () => void
  hide?: () => void
}

export const UpdateMoreMenu = ({ updateId, onEditClick, showEditOption }: UpdateMoreMenuProps) => {
  const { triggerConfirm, confirmControlProps } = useConfirmPopover()
  const [deleteUpdate] = useMutation(UpdateMoreMenuDeleteDocument, {
    update: (cache, { data }) => {
      if (data?.deleteUpdate.__typename === 'Success') {
        evictCache(cache, 'Update', updateId)
      }
    },
    variables: {
      updateId,
    },
  })

  const fullUrl = new URL(`/updates/${updateId}`, process.env.NEXT_PUBLIC_FRONTEND_URL).href

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(fullUrl)
    toast('Copied link to clipboard')
  }

  return (
    <div sx={{ width: '260px' }}>
      <FlyoutItem
        onClick={() => {
          void Router.push(route({ pathname: '/updates/[id]', query: { id: updateId } }))
          capture('More Menu Selected', {
            parent: 'Update',
            menuItem: 'View Full Screen',
          })
        }}
        startIcon={<ExpandMaximizeIcon />}
      >
        View Full Screen
      </FlyoutItem>
      {showEditOption && (
        <FlyoutItem onClick={onEditClick} startIcon={<PencilIcon />}>
          Edit Update
        </FlyoutItem>
      )}
      <FlyoutItem onClick={handleCopyLink} startIcon={<LinkIcon />}>
        Copy Link
      </FlyoutItem>

      <FlyoutDivider />
      <FlyoutItem
        onClick={async () => {
          if (await triggerConfirm()) {
            const { data } = await deleteUpdate()

            if (data?.deleteUpdate && isGraphQLError(data?.deleteUpdate)) {
              alert(`Delete goal failed: ${data?.deleteUpdate.message ?? 'unknown error'}`)
            } else if (data?.deleteUpdate.__typename !== 'Success') {
              alert('Delete goal failed: unknown error')
            }
          }
        }}
        startIcon={<DeleteIcon />}
      >
        Delete
      </FlyoutItem>

      <ConfirmPopover
        {...confirmControlProps}
        zIndex={10000}
        title="Permanently delete this Update?"
        description="This cannot be reversed. This will not delete any underlying Goals or Actions."
        confirmLabel="Delete permanently"
      />
    </div>
  )
}

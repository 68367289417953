import { Button, Flex, HotkeyTooltip, Text } from '@mm/company-ui'
import { ChevronIcon } from '@mm/company-ui-icons'
import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { capture } from '../../analytics'
import { useContextualDrawers } from '../hooks/useContextualDrawers'
import { useGetDrawerNavigation } from './ContextualDrawerProvider'

type ContextualDrawerNavigationProps = {
  className?: string
}

export const ContextualDrawerNavigation = ({ className }: ContextualDrawerNavigationProps) => {
  const { active } = useContextualDrawers()
  const getDrawerNavigationProvider = useGetDrawerNavigation()
  const data = active && getDrawerNavigationProvider(active.type, active.id, active.sectionId)
  const [loading, setLoading] = useState<'next' | 'previous'>()

  useHotkeys(
    'K',
    () => {
      if (data?.displayPrevious) {
        void data.displayPrevious()
        capture('Shortcut Navigation', { direction: 'Previous' })
      }
    },
    [data],
  )

  useHotkeys(
    'J',
    () => {
      if (data?.displayNext) {
        void data.displayNext()
        capture('Shortcut Navigation', { direction: 'Next' })
      }
    },
    [data],
  )

  if (active == null || data == null) {
    return null
  }

  return (
    <Flex row gap={1} shrink={false} align="center" className={className}>
      <HotkeyTooltip shortcutKey="K" description="Go to previous">
        <Button
          size="small"
          textHidden
          showTooltip={false}
          startIcon={<ChevronIcon sx={{ transform: 'scaleY(-1)' }} />}
          onClick={async () => {
            try {
              setLoading('previous')
              await data.displayPrevious?.()
              capture('Drawer Navigation', { key: 'previous button' })
            } finally {
              setLoading(undefined)
            }
          }}
          loading={loading === 'previous'}
          disabled={loading === 'next' || data.displayPrevious == null}
        >
          Go to previous
        </Button>
      </HotkeyTooltip>
      <HotkeyTooltip shortcutKey="J" description="Go to Next">
        <Button
          size="small"
          textHidden
          showTooltip={false}
          startIcon={<ChevronIcon />}
          onClick={async () => {
            try {
              setLoading('next')
              await data.displayNext?.()
              capture('Drawer Navigation', { key: 'next button' })
            } finally {
              setLoading(undefined)
            }
          }}
          loading={loading === 'next'}
          disabled={loading === 'previous' || data.displayNext == null}
        >
          Go to next
        </Button>
      </HotkeyTooltip>

      <Text variant="small" color="text-light">
        {data.itemIndex + 1} / {data.totalItemsCount}
        {data.hasMoreItems && '+'}
      </Text>
    </Flex>
  )
}

import { Suspender, TagSelect, Tag } from '@mm/company-ui'
import { ArrowRightIcon, CheckIcon, CloseIcon, ExclamationCircleIcon, FlagIcon } from '@mm/company-ui-icons'
import React from 'react'
import { GoalStatusPickerDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { editGoalStatusCommand } from '../commands/editGoalStatusCommand'

export type GoalStatusPickerProps = {
  goalId: string
  cached?: boolean
  skipNotDoneFlow?: boolean
}

export const GoalStatusPicker = ({ goalId, cached, skipNotDoneFlow }: GoalStatusPickerProps) => {
  const { data, loading, error } = useQuery(GoalStatusPickerDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      goalId,
    },
  })

  const editGoalStatus = useCommand(editGoalStatusCommand)

  const goal = data?.goal

  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('goal not found')
  }

  return (
    <TagSelect
      value={goal.status}
      onChange={(status) => {
        void editGoalStatus.execute({
          goalId: goalId,
          status,
          skipNotDoneFlow,
        })
        capture('More Menu Selected', {
          parent: 'Goal',
          menuItem: 'Status',
          status: status,
        })
      }}
    >
      <Tag value="ON_TRACK" variant="success" data-testid="TagSelect__selection">
        <ArrowRightIcon />
        On Track
      </Tag>
      <Tag value="AT_RISK" variant="alert" data-testid="TagSelect__selection">
        <FlagIcon />
        At risk
      </Tag>
      <Tag value="BEHIND" variant="warning" data-testid="TagSelect__selection">
        <ExclamationCircleIcon />
        Off Track
      </Tag>
      <Tag value="DONE" variant="accent" data-testid="TagSelect__selection">
        <CheckIcon />
        Completed
      </Tag>
      <Tag value="CANCELLED" variant="default" data-testid="TagSelect__selection">
        <CloseIcon />
        Cancelled
      </Tag>
    </TagSelect>
  )
}

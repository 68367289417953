import { setup, isSupported } from '@loomhq/record-sdk'
import { Button, ButtonGroup } from '@mm/company-ui'
import React, { useEffect, useRef } from 'react'

export type RecordLoomButton = {
  onInserted: (shareUrl: string) => void
  onStart?: () => void
  onComplete?: () => void
  children?: string
  loading?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export function RecordLoomButton({
  onInserted,
  onStart,
  onComplete,
  children = 'Record Loom',
  loading,
  onClick,
}: RecordLoomButton) {
  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    async function setupLoom() {
      const { supported, error } = await isSupported()

      if (!supported) {
        // eslint-disable-next-line no-console
        console.warn(`Error setting up Loom: ${error}`)
        return
      }

      if (!ref.current) {
        return
      }

      const { configureButton } = await setup({
        publicAppId: process.env.NEXT_PUBLIC_LOOM_APP_ID,
      })

      const sdkButton = configureButton({ element: ref.current })

      sdkButton.on('insert-click', (video: { sharedUrl: string }) => {
        onInserted(video.sharedUrl)
      })
      sdkButton.on('recording-start', () => {
        onStart?.()
      })
      sdkButton.on('recording-complete', () => {
        onComplete?.()
      })
      sdkButton.on('cancel', () => {
        onComplete?.()
      })
    }

    void setupLoom()
  }, [onInserted, onStart, onComplete])

  return (
    <ButtonGroup size="small" gap={1}>
      <Button ref={ref} loading={loading} onClick={onClick}>
        {children}
      </Button>

      <Button
        onClick={() => {
          const href = window.prompt('URL')
          if (href != null) {
            onInserted(href)
          }
        }}
      >
        Insert Loom
      </Button>
    </ButtonGroup>
  )
}

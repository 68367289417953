import React from 'react'
import { useFeatureFlags } from '../../helpers'
import { useOnScreen } from '../../hooks'
import { ButtonGroupProps } from '../Button/ButtonGroup'
import { Flex } from '../Flex'
import { Text } from '../Text'

export type DrawerLayoutProps = {
  title: string
  navigation?: React.ReactNode
  renderTitleButtonGroup: (props: ButtonGroupProps) => React.ReactNode
  renderTitleSection: (props: { ref: React.Ref<HTMLDivElement> }) => React.ReactNode
  children?: React.ReactNode
}

export function DrawerLayout({
  children,
  title,
  navigation,
  renderTitleButtonGroup,
  renderTitleSection,
}: DrawerLayoutProps) {
  const { nonBlockingDrawer } = useFeatureFlags()
  const [ref, onScreen] = useOnScreen<HTMLDivElement>(true)

  return (
    <Flex column sx={{ backgroundColor: nonBlockingDrawer ? 'white' : 'background-light', height: '100%' }}>
      <Flex
        justify="space-between"
        align="center"
        gap={4}
        sx={{
          height: 5,
          backgroundColor: nonBlockingDrawer ? 'white' : 'background-light',
          borderBottom: '1px solid',
          borderBottomColor: 'border',
          paddingX: 2,
        }}
      >
        <div>{navigation}</div>
        <Text
          variant="small"
          bold
          overflow="ellipsis"
          sx={{ opacity: onScreen ? 0 : 1, transition: '200ms ease-in-out' }}
        >
          {title}
        </Text>
        {renderTitleButtonGroup({ size: 'small', textHidden: true, variant: 'muted' })}
      </Flex>

      <Flex column gap={2} sx={{ flex: 1, overflowY: 'auto', paddingX: 2, paddingY: 1 }}>
        {renderTitleSection({ ref })}
        {children}
      </Flex>
    </Flex>
  )
}

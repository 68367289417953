import { Avatar, Editable, Flex, Text, formatDateDisplay, formatUserDisplayName, Tag, Tooltip } from '@mm/company-ui'
import { LockedIcon, UnlockedIcon } from '@mm/company-ui-icons/src'
import _ from 'lodash'
import React from 'react'
import { UpdateItemActionUpdateDataFragment } from '../../../gen/graphql/documents'
import { ActionLinkWithMenu } from '../../accountability/components/ActionLinkWithMenu'
import { useContextualDrawers } from '../../navigation'

export const stripHtml = (html: string): string => {
  return html.replace(/<[^>]*>?/gm, '')
}

export type ActionViewActionUpdateProps = {
  actionUpdate: UpdateItemActionUpdateDataFragment
  hasParent?: boolean
}

export function NextActionRow({
  action,
  index,
}: {
  action: UpdateItemActionUpdateDataFragment['action']
  index: number
}) {
  return (
    <ActionLinkWithMenu type="action" actionId={action.id} key={action.id}>
      <Flex
        row
        gap={1}
        sx={{
          padding: 1,
          ...(index !== 0
            ? {
                borderTop: '1px solid',
                borderTopColor: 'border',
              }
            : {}),
        }}
      >
        {action.status === 'DONE' ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#6B69C9" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7986 5.4993C12.0293 5.75606 12.0081 6.15123 11.7514 6.38192L7.11369 10.5486C6.98828 10.6613 6.82266 10.7185 6.65443 10.7073C6.4862 10.6961 6.32964 10.6174 6.22029 10.489L4.1913 8.10809C3.96742 7.84537 3.9989 7.4509 4.26162 7.22701C4.52435 7.00313 4.91882 7.03461 5.14271 7.29733L6.75555 9.18996L10.916 5.45208C11.1727 5.22139 11.5679 5.24253 11.7986 5.4993Z"
              fill="white"
            />
          </svg>
        ) : action.status === 'CANCELLED' ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#94979E" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.24468 10.7559C5.0006 10.5118 5.0006 10.1161 5.24468 9.87202L9.87185 5.24485C10.1159 5.00077 10.5117 5.00077 10.7557 5.24485C10.9998 5.48893 10.9998 5.88466 10.7557 6.12873L6.12856 10.7559C5.88448 11 5.48875 11 5.24468 10.7559Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.24468 5.24458C5.0006 5.48866 5.0006 5.88439 5.24468 6.12847L9.87185 10.7556C10.1159 10.9997 10.5117 10.9997 10.7557 10.7556C10.9998 10.5116 10.9998 10.1158 10.7557 9.87176L6.12856 5.24458C5.88448 5.0005 5.48875 5.0005 5.24468 5.24458Z"
              fill="white"
            />
          </svg>
        ) : action.status === 'ACTIVE' ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="0.65625"
              y="0.65625"
              width="14.6875"
              height="14.6875"
              rx="7.34375"
              stroke="#AFB1B6"
              strokeWidth="1.3125"
            />
          </svg>
        ) : (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="0.65625"
              y="0.65625"
              width="14.6875"
              height="14.6875"
              rx="7.34375"
              stroke="#AFB1B6"
              strokeWidth="1.3125"
            />
          </svg>
        )}

        <div sx={{ flex: 1, minWidth: 0 }}>
          <Text
            bold
            sx={{
              lineHeight: '1.5em',
              maxHeight: '3em',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {action.title}
          </Text>
        </div>
        {action.dueAt ? (
          <Text color="text-light" variant="small" sx={{ marginTop: 0.25 }}>
            By {formatDateDisplay(action.dueAt)}
          </Text>
        ) : null}
        <Avatar size="small" name={formatUserDisplayName(action.assignee)} />
      </Flex>
    </ActionLinkWithMenu>
  )
}

export function ActionViewActionUpdate({ actionUpdate, hasParent = true }: ActionViewActionUpdateProps) {
  const { showDrawer } = useContextualDrawers()

  const parentTypeLabel =
    actionUpdate.action.parent?.__typename === 'PrototypeFeedback'
      ? 'Feedback'
      : `${actionUpdate.action.parent?.__typename}`
  const parentTitleLabel = actionUpdate.action.parent?.title
    ? `${parentTypeLabel}: ${actionUpdate.action.parent.title}`
    : ''

  return (
    <Flex row sx={{ padding: 1.5 }} gap={2}>
      <div sx={{ marginTop: 0.25 }}>
        {actionUpdate.status === 'DONE' ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#6B69C9" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7986 5.4993C12.0293 5.75606 12.0081 6.15123 11.7514 6.38192L7.11369 10.5486C6.98828 10.6613 6.82266 10.7185 6.65443 10.7073C6.4862 10.6961 6.32964 10.6174 6.22029 10.489L4.1913 8.10809C3.96742 7.84537 3.9989 7.4509 4.26162 7.22701C4.52435 7.00313 4.91882 7.03461 5.14271 7.29733L6.75555 9.18996L10.916 5.45208C11.1727 5.22139 11.5679 5.24253 11.7986 5.4993Z"
              fill="white"
            />
          </svg>
        ) : actionUpdate.status === 'NOT_DONE' ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#DC2222" />
            <path
              d="M8.984 4.77022C9.01508 4.26672 8.61512 3.84131 8.11066 3.84131H7.88869C7.38423 3.84131 6.98427 4.26672 7.01535 4.77022L7.26244 8.77318C7.28649 9.16271 7.60941 9.46631 7.99967 9.46631C8.38994 9.46631 8.71286 9.16271 8.7369 8.77318L8.984 4.77022Z"
              fill="white"
            />
            <path
              d="M8 12.333C8.51777 12.333 8.9375 11.9133 8.9375 11.3955C8.9375 10.8777 8.51777 10.458 8 10.458C7.48223 10.458 7.0625 10.8777 7.0625 11.3955C7.0625 11.9133 7.48223 12.333 8 12.333Z"
              fill="white"
            />
          </svg>
        ) : actionUpdate.status === 'CANCELLED' ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#94979E" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.24468 10.7559C5.0006 10.5118 5.0006 10.1161 5.24468 9.87202L9.87185 5.24485C10.1159 5.00077 10.5117 5.00077 10.7557 5.24485C10.9998 5.48893 10.9998 5.88466 10.7557 6.12873L6.12856 10.7559C5.88448 11 5.48875 11 5.24468 10.7559Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.24468 5.24458C5.0006 5.48866 5.0006 5.88439 5.24468 6.12847L9.87185 10.7556C10.1159 10.9997 10.5117 10.9997 10.7557 10.7556C10.9998 10.5116 10.9998 10.1158 10.7557 9.87176L6.12856 5.24458C5.88448 5.0005 5.48875 5.0005 5.24468 5.24458Z"
              fill="white"
            />
          </svg>
        ) : actionUpdate.status === 'ACTIVE' ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="0.65625"
              y="0.65625"
              width="14.6875"
              height="14.6875"
              rx="7.34375"
              stroke="#AFB1B6"
              strokeWidth="1.3125"
            />
          </svg>
        ) : null}
      </div>

      <Flex column align="flex-start" gap={0.5} sx={{ flex: 1, minWidth: 0 }}>
        <Text
          bold
          sx={{
            lineHeight: '1.5em',
            maxHeight: '3em',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
          onClick={(event) => {
            showDrawer('action', actionUpdate.action.id, event)
          }}
        >
          {actionUpdate.action.title}
        </Text>
        {hasParent && parentTitleLabel ? (
          <Tag
            sx={{
              display: 'block',
              maxWidth: '75%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              '&:enabled': {
                cursor: 'initial',
              },
            }}
          >
            {parentTitleLabel}
          </Tag>
        ) : null}

        {actionUpdate.status === 'DONE' ? (
          <>
            {actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml) ? (
              <Editable mode="compact" initialValue={actionUpdate.reasonHtml} editable={false} />
            ) : null}

            {actionUpdate.nextActions?.length ? (
              <Flex column sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium', width: '100%' }}>
                {actionUpdate.nextActions.map(
                  (nextAction, index) =>
                    nextAction && <NextActionRow key={nextAction.id} action={nextAction} index={index} />,
                )}
              </Flex>
            ) : null}
          </>
        ) : null}

        {actionUpdate.status === 'NOT_DONE' ? (
          <>
            {actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml) ? (
              <Editable mode="compact" initialValue={actionUpdate.reasonHtml} editable={false} />
            ) : null}

            {actionUpdate.repeat != null ? <Text>Still want to do? {actionUpdate.repeat ? 'Yes' : 'No'}</Text> : null}

            {actionUpdate.nextHtml && stripHtml(actionUpdate.nextHtml) ? (
              <>
                <Text bold>Ideas for next actions</Text>
                <Editable mode="compact" initialValue={actionUpdate.nextHtml} editable={false} />
              </>
            ) : null}

            {actionUpdate.nextActions?.length ? (
              <Flex column sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium', width: '100%' }}>
                {actionUpdate.nextActions.map(
                  (nextAction, index) =>
                    nextAction && <NextActionRow key={nextAction.id} action={nextAction} index={index} />,
                )}
              </Flex>
            ) : null}
          </>
        ) : null}
      </Flex>

      <Flex gap={2} sx={{ alignSelf: 'flex-start' }} align="center">
        {actionUpdate.action.privacy === 'PRIVATE' ? (
          <Tooltip description="This action is private">
            <LockedIcon width={1.5} height={1.5} color="text-light" />
          </Tooltip>
        ) : (
          <Tooltip description="This action is public">
            <UnlockedIcon width={1.5} height={1.5} color="text-light" />
          </Tooltip>
        )}
        {actionUpdate.action.dueAt ? (
          <Text color="text-light" variant="small">
            Due {formatDateDisplay(actionUpdate.action.dueAt)}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  )
}

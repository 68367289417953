import { yDocStateToHtml } from '@mm/tiptap-extension/src'
import { toByteArray } from 'base64-js'
import { useCallback, useState } from 'react'
import { UseDynamicModulesOnActionFragmentDoc, UseDynamicModulesOnTopicFragmentDoc } from '../../gen/graphql/documents'
import { useFragment_experimental } from '../apollo'
import { useTotalActions } from './useTotalActions'
import { useTotalComments } from './useTotalComments'

export type DynamicModulesProps = {
  module: 'Action' | 'Topic'
  id: string
}

export function useDynamicModules({ id, module }: DynamicModulesProps) {
  const [showActions, setShowActions] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const [showDescription, setShowDescription] = useState(false)

  const { fragment, fragmentName } = (
    {
      Action: { fragment: UseDynamicModulesOnActionFragmentDoc, fragmentName: 'UseDynamicModulesOnAction' },
      Topic: { fragment: UseDynamicModulesOnTopicFragmentDoc, fragmentName: 'UseDynamicModulesOnTopic' },
    } as const
  )[module]
  const { data } = useFragment_experimental({
    fragment,
    fragmentName,
    from: { __typename: module, id },
  })

  const { hasComments } = useTotalComments({ __typename: module, id })
  const { hasActions } = useTotalActions({ __typename: module, id })

  if (!data) {
    // eslint-disable-next-line no-console
    console.warn(`Fragment used in DynamicModules returned no data, make sure a parent is querying for it`)
  }

  const hasDescription = yDocStateToHtml(toByteArray(data?.description?.byteContent || '')) !== '<p></p>'

  return {
    hasActions: showActions || hasActions,
    hasComments: showComments || hasComments,
    hasDescription: showDescription || hasDescription,
    handleShowActions: useCallback(() => {
      setShowActions(true)
    }, []),
    handleShowComments: useCallback(() => {
      setShowComments(true)
    }, []),
    handleShowDescription: useCallback(() => {
      setShowDescription(true)
    }, []),
  }
}

import { CloseSmallIcon, LinkIcon } from '@mm/company-ui-icons'
import { Flex, Tag } from '@mm/company-ui/src'
import { truncate } from 'lodash'
import React, { useCallback } from 'react'
import {
  ActionMetadataLinkTagsFragmentFragmentDoc,
  ActionPrivacyTagDocument,
  RemoveActionContextDocument,
} from '../../../gen/graphql/documents'
import { useFragment_experimental, useMutation, useQuery } from '../../apollo'
import { TagLink } from '../../components'
import { useFeatureFlags } from '../../featureFlags'

type ActionMetadataContextTagsProps = {
  actionId: string
  className?: string
  ['data-targetid']?: string
  cached?: boolean
}

export const ActionMetadataContextTags = ({ actionId, cached }: ActionMetadataContextTagsProps) => {
  const { prototypeFeedback } = useFeatureFlags()

  const { data } = useFragment_experimental({
    fragment: ActionMetadataLinkTagsFragmentFragmentDoc,
    fragmentName: 'ActionMetadataLinkTagsFragment',
    from: {
      __typename: 'Action',
      id: actionId,
    },
  })

  const { data: actionPrivacyData } = useQuery(ActionPrivacyTagDocument, {
    variables: { actionId: actionId },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const isActionPrivate = actionPrivacyData?.action?.isPrivate

  const [removeActionContext] = useMutation(RemoveActionContextDocument)

  const handleRemove = useCallback(
    async (id: string, typename?: string) => {
      await removeActionContext({
        variables: {
          id: actionId,
          contextRef: id,
        },
        optimisticResponse: {
          removeActionContext: {
            id: actionId,
            __typename: 'Action',
            isPrivate: isActionPrivate,
            ...(data && {
              ...data,
              ...(typename === 'Decision' && {
                decisions: {
                  edges: data?.decisions?.edges.filter(({ node }) => node.id !== id) || [],
                },
              }),

              ...(typename === 'Goal' && {
                goals: {
                  edges: data?.goals?.edges.filter(({ node }) => node.id !== id) || [],
                },
              }),

              ...(typename === 'Meeting' && {
                meetings: {
                  edges: data?.meetings?.edges.filter(({ node }) => node.id !== id) || [],
                },
              }),

              ...(typename === 'Topic' && {
                topics: {
                  edges: data?.topics?.edges.filter(({ node }) => node.id !== id) || [],
                },
              }),
            }),
          },
        },
      })
    },
    [actionId, data, isActionPrivate, removeActionContext],
  )

  if (!data) {
    // eslint-disable-next-line no-console
    console.log('Fragment returned no data, make sure a parent of ActionMetadataContextTag is querying the fragment')
    return null
  }

  return (
    <>
      {data.meetings?.edges.map(({ node }) => (
        <RemovableTag key={node.id} id={node.id} type={node.__typename} onRemove={handleRemove}>
          <TagLink
            sx={{ background: 'transparent' }}
            key={node.id}
            href={{ pathname: '/meetings/[id]', query: { id: node.id } }}
          >
            <LinkIcon />
            Meeting: {truncate(node.title, { length: 60 })}
          </TagLink>
        </RemovableTag>
      ))}

      {data.topics?.edges.map(({ node }) => (
        <RemovableTag key={node.id} id={node.id} type={node.__typename} onRemove={handleRemove}>
          <TagLink
            sx={{ background: 'transparent' }}
            key={node.id}
            href={{ pathname: '/topics/[id]', query: { id: node.id } }}
          >
            <LinkIcon />
            Topic: {truncate(node.title, { length: 60 })}
          </TagLink>
        </RemovableTag>
      ))}

      {data.goals?.edges.map(({ node }) => (
        <RemovableTag key={node.id} id={node.id} type={node.__typename} onRemove={handleRemove}>
          <TagLink
            sx={{ background: 'transparent' }}
            key={node.id}
            href={{ pathname: '/goals/[id]', query: { id: node.id } }}
          >
            <LinkIcon />
            Goal: {truncate(node.title, { length: 60 })}
          </TagLink>
        </RemovableTag>
      ))}

      {data.decisions?.edges.map(({ node }) => (
        <RemovableTag key={node.id} id={node.id} type={node.__typename} onRemove={handleRemove}>
          <TagLink
            sx={{ background: 'transparent' }}
            key={node.id}
            href={{ pathname: '/decisions/[id]', query: { id: node.id } }}
          >
            <LinkIcon />
            Decision: {truncate(node.title, { length: 60 })}
          </TagLink>
        </RemovableTag>
      ))}

      {prototypeFeedback &&
        data.prototypeFeedback?.edges.map(({ node }) => (
          <TagLink key={node.id} href={{ pathname: '/feedback/[id]', query: { id: node.id } }}>
            <LinkIcon />
            Feedback: {truncate(node.title, { length: 60 })}
          </TagLink>
        ))}

      {data.followUpTo ? (
        <TagLink href={{ pathname: '/actions/[id]', query: { id: data.followUpTo.id } }}>
          <LinkIcon />
          Follow-up Action: {truncate(data.followUpTo.title, { length: 60 })}
        </TagLink>
      ) : null}
    </>
  )
}

export const RemovableTag = ({
  id,
  type,
  children,
  onRemove,
}: {
  id: string
  type?: string
  children: React.ReactNode
  onRemove?: (id: string, type?: string) => void
}) => {
  return (
    <Flex
      row
      sx={{
        alignItems: 'center',
        backgroundColor: 'background-light-alpha',
        color: 'text-light',
        height: 3,
        paddingX: 1,
        borderRadius: 'full',
      }}
    >
      {children}
      <Tag
        sx={{ px: 0, height: 2, width: 2 }}
        onClick={() => {
          onRemove?.(id, type)
        }}
        variant="flat"
      >
        <CloseSmallIcon />
      </Tag>
    </Flex>
  )
}

import { Editor, getHTMLFromFragment } from '@tiptap/core'

export function getSelectionHtml(editor: Editor): string {
  const { doc, selection } = editor.state
  const range = doc.cut(selection.from, selection.to)
  return getHTMLFromFragment(range.content, editor.schema)
}

export function getSelectionText(editor: Editor, blockSeperator: string | null = ' '): string {
  const { doc, selection } = editor.state
  return doc.textBetween(selection.from, selection.to, blockSeperator)
}

import extension, { CompanyOSKitOptions } from '@mm/tiptap-extension'
import { Content, Extensions } from '@tiptap/core'
import { Editor, EditorOptions, HTMLContent, JSONContent } from '@tiptap/react'
import React, { useImperativeHandle } from 'react'
import { Bindings } from '../utils'
import { EditableContent, EditableContentProps } from './Editable.styles'
import { withPlugins } from './EditablePluginProvider'
import { ImageUploadExtension } from './extensions'
import { FloatingSelectionToolbar } from './FloatingSelectionToolbar'
import { defaultItems, SelectionToolbar, ToolbarItem } from './SelectionToolbar'
import { useTipTapEditor } from './useTipTapEditor'

type EditableOwnProps = {
  placeholder?: string
  initialValue?: Content
  autofocus?: boolean
  editable?: boolean

  hotkeys?: Bindings
  collaboration?: Extensions
  mentions?: CompanyOSKitOptions['mentions']
  selectionToolbar?: ToolbarItem[][] | React.ReactElement | false

  onChange?: (html: HTMLContent) => void
  onChangeRaw?: EditorOptions['onUpdate']
  onChangeJson?: (json: JSONContent) => void

  onBlur?: () => void
  onFocus?: () => void
  onUpload?: (file: File) => Promise<string>
}

export type EditableProps = Omit<React.ComponentPropsWithoutRef<'div'>, keyof EditableOwnProps> &
  EditableContentProps &
  EditableOwnProps

export type EditableRef = Editor

export const Editable = withPlugins(
  React.forwardRef<EditableRef, EditableProps>(function Editable(
    {
      placeholder,
      initialValue,
      autofocus = false,
      editable = true,
      hotkeys,
      collaboration = [],
      mentions = {},
      selectionToolbar = defaultItems,
      onChange,
      onChangeRaw,
      onChangeJson,
      onBlur,
      onFocus,
      role = 'textbox',
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      onUpload,
      ...rest
    },
    ref,
  ): React.ReactElement {
    const editor = useTipTapEditor({
      content: initialValue,
      editable,
      hotkeys,
      onUpdate: onChangeRaw,
      onChange,
      onChangeJson,
      onBlur,
      onFocus,
      autofocus,
      extensions: [
        extension.configure({
          history: collaboration.length === 0,
          mentions,
        }),
        ImageUploadExtension.configure({
          upload: onUpload,
        }),
        ...collaboration,
      ],
      role,
      ariaLabel,
      ariaLabelledBy,
      placeholder,
    })

    useImperativeHandle<Editor | null, Editor | null>(ref, () => editor, [editor])
    return (
      <>
        {editor && selectionToolbar !== false && (
          <FloatingSelectionToolbar editor={editor}>
            {React.isValidElement<any>(selectionToolbar) ? (
              selectionToolbar
            ) : (
              <SelectionToolbar editor={editor} items={selectionToolbar} />
            )}
          </FloatingSelectionToolbar>
        )}
        <EditableContent editor={editor} placeholder={placeholder} {...rest} />
      </>
    )
  }),
)

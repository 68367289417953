import { CalendarIcon, StopwatchTimerIcon } from '@mm/company-ui-icons/src'
import {
  Flex,
  PanelMetadataContainer,
  PanelMetadataItem,
  Suspender,
  Text,
  useFeatureFlags,
  formatDateDisplay,
  Tag,
} from '@mm/company-ui/src'
import React from 'react'
import { ActionMetadataDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { ActionMetadataFeedback } from '../../prototypeFeedback/components/ActionMetadataFeedback'
import { ActionDatepicker } from './ActionDatepicker'
import { ActionMetadataAssignee } from './ActionMetadataAssignee'
import { ActionMetadataDecisions } from './ActionMetadataDecisions'
import { ActionMetadataFollowUpTo } from './ActionMetadataFollowUpTo'
import { ActionMetadataGoals } from './ActionMetadataGoals'
import { ActionMetadataMeetings } from './ActionMetadataMeetings'
import { ActionMetadataTopics } from './ActionMetadataTopics'
import { ActionMetadataWaitingFor } from './ActionMetadataWaitingFor'
import { ActionStatusTagSelect } from './ActionStatusTagSelect'
import { AddActionContextButton } from './AddActionContextButton'

export type ActionConsolidatedMetadataProps = {
  actionId: string
  cached?: boolean
}

export const ActionConsolidatedMetadata = ({ actionId, cached }: ActionConsolidatedMetadataProps) => {
  const { prototypeFeedback, updateActionContext } = useFeatureFlags()
  const { data, loading, error } = useQuery(ActionMetadataDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id: actionId,
    },
  })

  const action = data?.action

  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Action not found')
  }

  return (
    <PanelMetadataContainer sx={{ gap: 2.5 }}>
      <PanelMetadataItem title="Status" sx={{ alignItems: 'center', '> div': { width: 112 } }}>
        <ActionStatusTagSelect actionId={actionId} />
      </PanelMetadataItem>
      <PanelMetadataItem title="Due date" sx={{ '> div': { width: 112 } }}>
        <ActionDatepicker cached id={actionId} prefix={<CalendarIcon />} inputWidth={150} />
      </PanelMetadataItem>

      {action.status === 'ACTIVE' && action.dueAt && (
        <PanelMetadataItem title="Remaining" sx={{ '> div': { width: 112 }, alignItems: 'center' }}>
          <Tag variant="flat">
            <StopwatchTimerIcon />
            <Text>{formatDateDisplay(action.dueAt, 'RELATIVE')}</Text>
          </Tag>
        </PanelMetadataItem>
      )}

      {action.status === 'DONE' && action.dueAt && action.statusUpdatedAt && (
        <PanelMetadataItem title="Completed" sx={{ '> div': { width: 112 }, alignItems: 'center' }}>
          <Tag variant="flat">
            <StopwatchTimerIcon />
            <Text>
              {formatDateDisplay(new Date().getTime() + (action.statusUpdatedAt - action.createdAt), 'RELATIVE')}
            </Text>
          </Tag>
        </PanelMetadataItem>
      )}

      <PanelMetadataItem title="Assignee" sx={{ '> div': { width: 112 } }}>
        <ActionMetadataAssignee cached actionId={actionId} />
      </PanelMetadataItem>
      <PanelMetadataItem title="Waiting" sx={{ '> div': { width: 112 } }}>
        <ActionMetadataWaitingFor cached actionId={actionId} />
      </PanelMetadataItem>

      {action.followUpTo?.id && (
        <PanelMetadataItem title="Follow-Up To" sx={{ '> div': { width: 112 }, alignItems: 'center' }}>
          <ActionMetadataFollowUpTo cached actionId={actionId} isLink />
        </PanelMetadataItem>
      )}

      <PanelMetadataItem title="Related" sx={{ '> div:first-child': { width: 112 } }}>
        <Flex column gap={1.5} sx={{ paddingTop: 0.75 }}>
          <ActionMetadataTopics actionId={actionId} isLink />
          <ActionMetadataMeetings actionId={actionId} isLink />
          <ActionMetadataGoals actionId={actionId} isLink />
          <ActionMetadataDecisions actionId={actionId} isLink />
          {prototypeFeedback && <ActionMetadataFeedback actionId={actionId} isLink />}

          {updateActionContext && <AddActionContextButton actionId={actionId} />}
        </Flex>
      </PanelMetadataItem>
    </PanelMetadataContainer>
  )
}

import { Avatar, Flex, formatUserDisplayName, Suspender, Text, Button, ButtonGroup } from '@mm/company-ui'
import { CommentChatIcon } from '@mm/company-ui-icons'
import React from 'react'
import { DecisionRequestForCommentDocument, AddDecisionCommentBoxDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useQuery, useMutation } from '../../apollo'
import { addRootCommentToCache } from '../utils/addCommentToCache'

export type DecisionRequestForCommentProps = {
  id: string
  variant?: 'muted' | 'accent'
  className?: string
}

export const DecisionRequestForComment = ({ id, variant = 'muted', className }: DecisionRequestForCommentProps) => {
  const [addComment] = useMutation(AddDecisionCommentBoxDocument, {
    onCompleted: () => {
      capture('Comment Created', {
        parent: 'Decision',
      })
    },
    update: (cache, { data }) => {
      const comment = data?.createCommentOnDecision
      if (comment?.__typename === 'Comment') {
        addRootCommentToCache(cache, comment)
      }
    },
  })

  const { data, loading, error } = useQuery(DecisionRequestForCommentDocument, {
    variables: {
      id,
    },
  })

  const decision = data?.decision

  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('Decision not found')
  }

  return (
    <Flex
      sx={{
        bg: variant === 'muted' ? 'background-light' : 'accent-background-light',
        color: variant === 'muted' ? 'text' : 'accent-text',
        borderRadius: '8px',
      }}
      className={className}
    >
      <Flex sx={{ padding: 1 }} grow justify="space-between">
        <Flex row align="center" gap={1.5}>
          <Avatar name={formatUserDisplayName(decision.creator)} />
          <Flex row gap={2} align="center">
            <Text bold>{formatUserDisplayName(decision.creator, 'FIRST_NAME')} requested your comment</Text>
          </Flex>
        </Flex>
        <ButtonGroup variant="muted" size="small" gap={1.5}>
          <Button
            size="small"
            loading={loading}
            onClick={() => {
              void addComment({
                variables: {
                  decisionId: id,
                  htmlBody: '<p>Nothing to add.</p>',
                },
              })
            }}
          >
            Nothing to add
          </Button>
          <Button
            startIcon={<CommentChatIcon />}
            variant={variant === 'muted' ? 'default' : 'accent'}
            onClick={() => {
              //TODO figure out how to do this with refs
              const elem = document.querySelector('[aria-placeholder="Add a comment"]') as
                | HTMLElement
                | null
                | undefined
              elem?.focus()
            }}
          >
            Comment
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  )
}

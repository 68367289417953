import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from 'prosemirror-state'

// https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521
export const ImageUploadExtension = Extension.create<{ upload: (image: File) => Promise<string> }>({
  name: 'image-upload',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('ImageUploadExtension'),
        props: {
          handlePaste: (view, event) => {
            if (!this.options.upload) {
              return // do nothing if upload function is not provided
            }
            const items = Array.from(event.clipboardData?.items || [])
            const { schema } = view.state
            const imageNode = schema.nodes['image']
            if (!imageNode) {
              throw Error('Image node is not defined in schema')
            }

            items.forEach((item) => {
              const image = item.getAsFile()

              if (item.type.indexOf('image') === 0) {
                event.preventDefault()

                if (this.options.upload && image) {
                  void this.options.upload(image).then((src: string) => {
                    const node = imageNode.create({
                      src: src,
                    })
                    const transaction = view.state.tr.replaceSelectionWith(node)
                    view.dispatch(transaction)
                  })
                }
              } else {
                const reader = new FileReader()
                reader.onload = (readerEvent) => {
                  const node = imageNode.create({
                    src: readerEvent.target?.result,
                  })
                  const transaction = view.state.tr.replaceSelectionWith(node)
                  view.dispatch(transaction)
                }
                if (!image) return
                reader.readAsDataURL(image)
              }
            })

            return false
          },
          handleDOMEvents: {
            drop: (view, event) => {
              if (!this.options.upload) {
                return // do nothing if upload function is not provided
              }
              const hasFiles = event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length

              if (!hasFiles) {
                return false
              }

              const images = Array.from(event.dataTransfer?.files ?? []).filter((file) => /image/i.test(file.type))

              if (images.length === 0) {
                return false
              }

              event.preventDefault()

              const { schema } = view.state
              const imageNode = schema.nodes['image']
              if (!imageNode) {
                throw Error('Image node is not defined in schema')
              }
              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              })
              if (!coordinates) return false

              images.forEach(async (image) => {
                const reader = new FileReader()

                if (this.options.upload) {
                  const node = imageNode.create({
                    src: await this.options.upload(image),
                  })
                  const transaction = view.state.tr.insert(coordinates.pos, node)
                  view.dispatch(transaction)
                } else {
                  reader.onload = (readerEvent) => {
                    const node = imageNode.create({
                      src: readerEvent.target?.result,
                    })
                    const transaction = view.state.tr.insert(coordinates.pos, node)
                    view.dispatch(transaction)
                  }
                  reader.readAsDataURL(image)
                }
              })

              return true
            },
          },
        },
      }),
    ]
  },
})

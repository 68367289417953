import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const AddCircleIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="add-circle" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.90001 10C1.90001 5.52649 5.5265 1.9 10 1.9C14.4735 1.9 18.1 5.52649 18.1 10C18.1 14.4735 14.4735 18.1 10 18.1C5.5265 18.1 1.90001 14.4735 1.90001 10ZM10 0.0999985C4.53239 0.0999985 0.100006 4.53238 0.100006 10C0.100006 15.4676 4.53239 19.9 10 19.9C15.4676 19.9 19.9 15.4676 19.9 10C19.9 4.53238 15.4676 0.0999985 10 0.0999985ZM10 5.00909C10.4971 5.00909 10.9 5.41203 10.9 5.90909V9.1H14.091C14.5881 9.1 14.991 9.50294 14.991 10C14.991 10.4971 14.5881 10.9 14.091 10.9H10.9V14.0909C10.9 14.588 10.4971 14.9909 10 14.9909C9.50295 14.9909 9.10001 14.588 9.10001 14.0909V10.9H5.90919C5.41213 10.9 5.00919 10.4971 5.00919 10C5.00919 9.50294 5.41213 9.1 5.90919 9.1H9.10001V5.90909C9.10001 5.41203 9.50295 5.00909 10 5.00909Z"
    />
  </IconBase>
)

import React from 'react'
import { GoalCommentsListDocument } from '../../../gen/graphql/documents'
import { CommentsList, CommentsListProps } from '../../comments'

export type GoalCommentsListProps = Omit<CommentsListProps<unknown, unknown>, 'query' | 'variables' | 'extractPage'> & {
  goalId: string
}

export const GoalCommentsList = ({ goalId, ...rest }: GoalCommentsListProps) => (
  <CommentsList
    query={GoalCommentsListDocument}
    variables={{ goalId }}
    extractPage={(data) => data.goal?.comments}
    {...rest}
  />
)

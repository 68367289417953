import React from 'react'
import { AddActionCommentBoxDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useMutation } from '../../apollo'
import { addRootCommentToCache } from '../utils/addCommentToCache'
import { AddCommentBox } from './AddCommentBox'

export type AddActionCommentBoxProps = {
  actionId: string
}

export const AddActionCommentBox = ({ actionId }: AddActionCommentBoxProps) => {
  const [addComment] = useMutation(AddActionCommentBoxDocument, {
    onCompleted: () => {
      capture('Comment Created', {
        parent: 'Action',
      })
    },
    update: (cache, { data }) => {
      const comment = data?.createCommentOnAction
      if (comment?.__typename === 'Comment') {
        addRootCommentToCache(cache, comment)
      }
    },
  })

  return (
    <AddCommentBox
      id={`action:${actionId}`}
      onSubmit={async ({ htmlBody, author }) => {
        const { data } = await addComment({
          variables: {
            actionId,
            htmlBody,
            author,
          },
        })
        if (data?.createCommentOnAction.__typename !== 'Comment') {
          throw new Error('failed to create a comment')
        }
      }}
    />
  )
}

import _ from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import { useActiveCompanyId } from '../companies'

type SelectOption = {
  label: string
  value: string
}

type DisplayConfig = {
  grouping: readonly string[]
  sortingFields: readonly SelectOption[]
  sortingOrder: readonly SelectOption[]
  fields: readonly string[]
}

type DisplayState<T extends DisplayConfig> = {
  grouping: T['grouping'][number] | null
  sortingField: T['sortingFields'][number] | null
  sortingOrder: T['sortingOrder'][number] | null
  fields: Record<T['fields'][number], boolean> | null
}

type DisplayContextType<T extends DisplayConfig> = {
  config: T
  state: DisplayState<T>
  setState: React.Dispatch<React.SetStateAction<DisplayState<T>>>
}

const DisplayContext = createContext<DisplayContextType<any> | undefined>(undefined)

interface DisplayProviderProps<T extends DisplayConfig> {
  pageKey: string
  children: React.ReactNode
  config: T
  initialState?: Partial<DisplayState<T>>
}

type LocalStorageType<T extends DisplayConfig> = Record<string, Record<string, DisplayState<T>>>

export const DisplaySettingsProvider: React.FC<DisplayProviderProps<DisplayConfig>> = ({
  children,
  pageKey,
  config,
  initialState,
}) => {
  const [storedDisplaySettings, setStoredDisplaySettings] = useLocalStorage<LocalStorageType<typeof config>>(
    'displaySettings',
    {},
  )

  const { activeCompanyId } = useActiveCompanyId()
  // TODO - This is a temp solution to add newly released options to state overriding localStorage state
  // will be replaced with _.mergeWith and server state in followup beta release
  const [state, setState] = useState<DisplayState<typeof config>>(() => {
    const workspaceStoredDisplaySettings = storedDisplaySettings?.[activeCompanyId]
    const fields = {
      ...initialState?.fields,
      ...workspaceStoredDisplaySettings?.[pageKey]?.fields,
    }
    const updatedState = {
      ...workspaceStoredDisplaySettings?.[pageKey],
      sortingField: workspaceStoredDisplaySettings?.[pageKey]?.sortingField || initialState?.sortingField || null,
      sortingOrder: workspaceStoredDisplaySettings?.[pageKey]?.sortingOrder || initialState?.sortingOrder || null,
      fields: Object.keys(fields).length ? fields : null,
    }

    return {
      grouping: null,

      ...initialState,
      ...updatedState,
    }
  })

  useEffect(() => {
    setStoredDisplaySettings((prevDisplaySettings) => ({
      ...prevDisplaySettings,
      [activeCompanyId]: {
        ...prevDisplaySettings?.[activeCompanyId],
        [pageKey]: state,
      },
    }))
  }, [activeCompanyId, pageKey, setStoredDisplaySettings, state])

  return <DisplayContext.Provider value={{ config, state, setState }}>{children}</DisplayContext.Provider>
}

export function useDisplaySettings<T extends DisplayConfig>(): DisplayContextType<T> {
  const context = useContext(DisplayContext)

  if (!context) {
    throw new Error('useDisplay must be used within a DisplayProvider')
  }

  return context
}

export function useSortOrderApiField() {
  const { state } = useDisplaySettings()
  const { sortingOrder, sortingField } = state
  if (!sortingOrder || !sortingField) return null
  const sortOrderApiField = `${_.snakeCase(sortingField?.value).toUpperCase()}_${sortingOrder?.value.toUpperCase()}`
  return sortOrderApiField
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const UserProfileIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="user-profile" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0778 13.1389C10.9582 14.0286 9.54114 14.56 8 14.56C6.45886 14.56 5.04184 14.0286 3.92222 13.1389C3.94572 12.816 4.15429 12.5332 4.46044 12.4171L6.36215 11.696C6.9559 11.4709 7.34857 10.9021 7.34857 10.2671C7.34857 9.77232 7.10907 9.30819 6.70584 9.02152L6.58862 8.93818C6.205 8.66545 5.97714 8.22389 5.97714 7.75321V6.50835C5.97714 6.14596 6.005 5.91601 6.08973 5.74434C6.1934 5.5343 6.36607 5.28391 6.64867 5.08584C6.92429 4.89265 7.34539 4.72 8 4.72C8.65461 4.72 9.07571 4.89265 9.35133 5.08584C9.63393 5.28391 9.8066 5.5343 9.91027 5.74434C9.995 5.91601 10.0229 6.14596 10.0229 6.50835V7.75321C10.0229 8.22389 9.795 8.66545 9.41138 8.93818L9.29416 9.02152C8.89093 9.30819 8.65143 9.77232 8.65143 10.2671C8.65143 10.9021 9.0441 11.4709 9.63785 11.696L11.5396 12.4171C11.8457 12.5332 12.0543 12.816 12.0778 13.1389ZM13.1878 12.0156C12.9299 11.5922 12.535 11.2545 12.0501 11.0706L10.1484 10.3496C10.1141 10.3366 10.0914 10.3037 10.0914 10.2671C10.0914 10.2385 10.1053 10.2117 10.1285 10.1952L10.2458 10.1118C11.0093 9.56897 11.4629 8.69007 11.4629 7.75321V6.50835C11.4629 6.12769 11.4457 5.60165 11.2015 5.107C11.0201 4.73947 10.7062 4.27702 10.1778 3.90665C9.64247 3.5314 8.92815 3.28 8 3.28C7.07185 3.28 6.35753 3.5314 5.82216 3.90665C5.29376 4.27702 4.97986 4.73947 4.79845 5.107C4.55431 5.60165 4.53714 6.12769 4.53714 6.50835V7.75321C4.53714 8.69007 4.99068 9.56897 5.75424 10.1118L5.87146 10.1952C5.89474 10.2117 5.90857 10.2385 5.90857 10.2671C5.90857 10.3037 5.8859 10.3366 5.85162 10.3496L3.9499 11.0706C3.46503 11.2545 3.07015 11.5922 2.81224 12.0156C1.95203 10.9059 1.44 9.51274 1.44 8C1.44 4.37701 4.37701 1.44 8 1.44C11.623 1.44 14.56 4.37701 14.56 8C14.56 9.51274 14.048 10.9059 13.1878 12.0156ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
    />
  </IconBase>
)

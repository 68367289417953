import { Card, Editable, Flex, Text, formatDateDisplay } from '@mm/company-ui'
import React from 'react'
import { GoalUpdateItemDataFragment } from '../../../gen/graphql/documents'
import { GoalLinkWithMenu } from './GoalLinkWithMenu'
import { GoalRowStatusIndicator } from './GoalRowStatusIndicator'
import { Item, Metadata, NextActionRow, stripHtml } from './UpdateListItemActionUpdate'

export type GoalUpdateListItemProps = {
  goalUpdate: GoalUpdateItemDataFragment
  updateCreatedAt: number
  type?: 'a-goal' | 'goal'
}

export function GoalUpdateListItem({ goalUpdate, updateCreatedAt, type }: GoalUpdateListItemProps) {
  return (
    <Card sx={{ padding: 0, borderRadius: 'medium' }} variant="flat">
      <GoalLinkWithMenu goalId={goalUpdate.goal.id} type={type}>
        <div>
          <Item first>
            <Flex column gap={0.5}>
              <Flex row gap={2}>
                <Text
                  bold
                  sx={{
                    flex: 1,
                    fontSize: '16px',
                  }}
                >
                  {goalUpdate.goal.title}
                </Text>

                <Text color="text-light" variant="small" sx={{ flexShrink: 0, marginTop: 0.5 }}>
                  By {formatDateDisplay(goalUpdate.goal.dueAt)}
                </Text>
              </Flex>

              <GoalRowStatusIndicator
                width={120}
                status={goalUpdate.status}
                startDate={goalUpdate.goal.createdAt}
                endDate={goalUpdate.goal.dueAt}
                progressDate={updateCreatedAt}
              />
            </Flex>
          </Item>
        </div>
      </GoalLinkWithMenu>

      <Item>
        <Metadata title="How is it progressing?">
          <Editable mode="compact" initialValue={goalUpdate.reasonHtml} editable={false} />
        </Metadata>
      </Item>

      {goalUpdate.nextHtml && stripHtml(goalUpdate.nextHtml) ? (
        <Item>
          <Metadata title="How to fix not good, or advance?">
            <Editable mode="compact" initialValue={goalUpdate.nextHtml} editable={false} />
          </Metadata>
        </Item>
      ) : null}

      {goalUpdate.nextActions?.length ? (
        <Item>
          <Metadata title="Next actions" titleOffset="2">
            <Flex column sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium' }}>
              {goalUpdate.nextActions.map(
                (nextAction, index) =>
                  nextAction && <NextActionRow key={nextAction.id} action={nextAction} index={index} />,
              )}
            </Flex>
          </Metadata>
        </Item>
      ) : null}
    </Card>
  )
}

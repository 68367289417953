import { ContextMenu, PopupWrapper } from '@mm/company-ui'
import React, { useState } from 'react'
import { useContextualDrawers } from '../../navigation'
import { TopicMoreMenu } from '../../topics'

export type TopicLinkWithMenuProps = {
  topicId: string
  hasHover?: boolean
  children: React.ReactNode
}

export function TopicLinkWithMenu({ topicId, hasHover = true, children }: TopicLinkWithMenuProps) {
  const [menuVisible, setmenuVisible] = useState(false)
  const { showDrawer } = useContextualDrawers()

  return (
    <ContextMenu
      render={() => (
        <PopupWrapper sx={{ px: 0, py: 1 }}>
          <TopicMoreMenu id={topicId} />
        </PopupWrapper>
      )}
      onOpenChange={setmenuVisible}
    >
      {({ getReferenceProps }) => (
        <div
          {...getReferenceProps({
            onClick: (event) => {
              if (
                event.target instanceof HTMLButtonElement &&
                (event.target.dataset['testid'] === 'TagSelect__target' ||
                  event.target.dataset['testid'] === 'TagSelect__selection')
              ) {
                // Dont Open Drawer if clicking Status Picker
                return
              }
              showDrawer('topic', topicId, event)
            },
          })}
          sx={{
            transition: '200ms background-color',
            cursor: 'pointer',
            borderRadius: 'medium',

            '&:hover': hasHover
              ? {
                  backgroundColor: 'background-light',
                }
              : {},

            ...(menuVisible
              ? {
                  backgroundColor: 'background-light',
                }
              : {}),
          }}
        >
          {children}
        </div>
      )}
    </ContextMenu>
  )
}

import React from 'react'
import { MeetingPrepCommentsDocument } from '../../../gen/graphql/documents'
import { CommentsList, CommentsListProps } from '../../comments'
import { MeetingPrepCommentsComposer } from './MeetingPrepCommentsComposer'

export type MeetingPrepCommentsProps = Omit<
  CommentsListProps<unknown, unknown>,
  'query' | 'variables' | 'extractPage'
> & {
  meetingPrepId: string
}

export const MeetingPrepComments = ({ meetingPrepId }: MeetingPrepCommentsProps) => {
  return (
    <CommentsList
      query={MeetingPrepCommentsDocument}
      variables={{ meetingPrepId }}
      extractPage={(data) => data.meetingPrep?.comments}
      order="newest"
      composer={<MeetingPrepCommentsComposer meetingPrepId={meetingPrepId} />}
      fixedComposer={false}
      fixedAndScroll={false}
    />
  )
}

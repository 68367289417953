import { EditorOptions, HTMLContent, JSONContent, useEditor } from '@tiptap/react'
import { Node } from 'prosemirror-model'
import { useCallback, useEffect, useRef } from 'react'
import { useLatest } from 'react-use'
import { useLatestCallback } from '../../../hooks'
import { Bindings, useTiptapHotkeys } from '../utils'

export type UseTipTapEditorOptions = Partial<Omit<EditorOptions, 'editorProps'>> & {
  hotkeys?: Bindings
  onChange?: (html: HTMLContent) => void
  onChangeJson?: (json: JSONContent) => void

  placeholder?: string
  role?: string
  ariaLabel?: string
  ariaLabelledBy?: string
}

export const useTipTapEditor = ({
  editable = true,
  hotkeys,
  onChange,
  placeholder,
  role = 'textbox',
  ariaLabel,
  ariaLabelledBy,
  onChangeJson,
  onUpdate,
  onFocus,
  onBlur,
  ...rest
}: UseTipTapEditorOptions) => {
  // using useLatest here intentionally so that editor.getHTML() is invoked only if the callback is passed
  const onChangeRef = useLatest(onChange)
  const onUpdateRef = useLatest(onUpdate)
  const onChangeJsonRef = useLatest(onChangeJson)

  const handleUpdate = useCallback<EditorOptions['onUpdate']>(
    (props): void => {
      // ignore changes not related to the content
      if (!props.transaction.docChanged) return

      onUpdateRef.current?.(props)
      onChangeRef.current?.(props.editor.getHTML())
      onChangeJsonRef.current?.(props.editor.getJSON())
    },
    [onUpdateRef, onChangeRef, onChangeJsonRef],
  )

  const handleBlur = useLatestCallback(onBlur)
  const handleFocus = useLatestCallback(onFocus)
  const cachedEmptyTopNode = useRef<Node | null>(null)

  const editor = useEditor({
    ...rest,
    onUpdate: handleUpdate,
    onFocus: handleFocus,
    onBlur: handleBlur,
    editable,

    editorProps: {
      // Pasting from GDocs sometimes preserves nbsp in clipboard.
      transformPastedText(text) {
        return text.replace(/&nbsp;/g, ' ')
      },
      transformPastedHTML(html) {
        return html.replace(/&nbsp;/g, ' ')
      },
      attributes: (state) => {
        const emptyTopNode = (cachedEmptyTopNode.current ??= state.doc.type.createAndFill())
        const diff = emptyTopNode?.content.findDiffStart(state.doc.content)
        return {
          class: diff == null ? 'empty' : '',
        }
      },
    },
  })
  useTiptapHotkeys(editor, hotkeys)
  useEffect(() => {
    if (editor != null && editor.isEditable !== editable) {
      editor.setEditable(editable)
    }
  }, [editor, editable])

  useEffect(() => {
    const dom = editor?.view.dom
    if (dom) {
      Object.entries({
        role,
        'aria-placeholder': placeholder,
        'aria-label': ariaLabel ?? (ariaLabelledBy == null ? placeholder : undefined),
        'aria-labelledby': ariaLabelledBy,
      }).forEach(([key, value]) => {
        if (value == null) {
          dom.removeAttribute(key)
        } else {
          dom.setAttribute(key, value)
        }
      })
    }
  }, [editor?.view.dom, role, ariaLabel, ariaLabelledBy, placeholder])

  return editor
}

import { useCallback } from 'react'
import {
  CommentEmojiReactionsDataFragmentDoc,
  UseRemoveCommentEmojiReactionDocument,
} from '../../../gen/graphql/documents'
import { useApolloClient, useMutation } from '../../apollo'
import { useActiveUserRequired } from '../../users'

export const useRemoveCommentEmojiReaction = (commentId: string) => {
  const client = useApolloClient()
  const [removeEmojiReaction] = useMutation(UseRemoveCommentEmojiReactionDocument)
  const activeUser = useActiveUserRequired()
  const activeUserId = activeUser.id

  return useCallback(
    (emoji: string) => {
      const data = client.readFragment(
        {
          id: client.cache.identify({ __typename: 'Comment', id: commentId }),
          fragment: CommentEmojiReactionsDataFragmentDoc,
        },
        true,
      )

      return removeEmojiReaction({
        variables: {
          id: commentId,
          emoji,
        },
        optimisticResponse: data
          ? {
              removeCommentEmojiReaction: {
                ...data,
                emojiReactions: data.emojiReactions?.filter((reaction) => {
                  const emojiId = emoji.match(/^(:[^:]+:)/)?.[1]
                  return reaction.user.id !== activeUserId || emojiId == null || !reaction.emoji.startsWith(emojiId)
                }),
              },
            }
          : undefined,
      })
    },
    [client, commentId, removeEmojiReaction, activeUserId],
  )
}

import React, { useState } from 'react'
import { CommentViewer } from './CommentViewer'
import { EditCommentBox } from './EditCommentBox'

export type CommentProps = {
  id: string
  cached?: boolean
  showDecisionMark?: boolean
}

export const Comment = ({ id, cached, showDecisionMark }: CommentProps) => {
  const [editing, setEditing] = useState(false)
  return editing ? (
    <EditCommentBox
      id={id}
      cached={cached}
      onCancel={() => {
        setEditing(false)
      }}
      onSaved={() => {
        setEditing(false)
      }}
    />
  ) : (
    <CommentViewer
      id={id}
      cached={cached}
      showDecisionMark={showDecisionMark}
      onEdit={() => {
        setEditing(true)
      }}
    />
  )
}

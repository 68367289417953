import React from 'react'
import { PrototypeFeedbackEmojiReactionsDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { EmojiReactions } from '../../components/emoji/EmojiReactions'
import { useAddPrototypeFeedbackEmojiReaction } from '../hooks/useAddPrototypeFeedbackEmojiReaction'
import { useRemovePrototypeFeedbackEmojiReaction } from '../hooks/useRemovePrototypeFeedbackEmojiReaction'

export type FeedbackReactionsProps = {
  id: string
  cached?: boolean
  hasAddButton?: boolean
}

export const FeedbackReactions = ({ id, cached, hasAddButton }: FeedbackReactionsProps) => {
  const { data } = useQuery(PrototypeFeedbackEmojiReactionsDocument, {
    variables: { id },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const addReaction = useAddPrototypeFeedbackEmojiReaction(id)
  const removeReaction = useRemovePrototypeFeedbackEmojiReaction(id)

  const emojiReactions = data?.prototypeFeedback?.emojiReactions ?? []

  return (
    <EmojiReactions
      reactions={emojiReactions}
      hasAddButton={hasAddButton}
      onAdd={addReaction}
      onRemove={removeReaction}
    />
  )
}

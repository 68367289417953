import { useCallback } from 'react'
import { CreateActionInput, CreateActionOnGoalDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { Command } from '../../commands'
import { useEmitEventBusEvent } from '../../eventbus'
import { useMeetingContext } from '../../meetings'
import { ActionsUpdatedTopic } from '../subscriptions/GlobalActionsSubscription'
import { markActionAsCreatedHere } from './markAsCreatedHere'

type CreateActionOnGoalCommandValue = {
  goalId: string
  data: CreateActionInput
}

export const createActionOnGoalCommand: Command<CreateActionOnGoalCommandValue> = {
  id: 'createActionOnGoal',
  name: 'Create Action on Goal',
  canExecute: () => true,
  useExecute: () => {
    const meeting = useMeetingContext()
    const emit = useEmitEventBusEvent(ActionsUpdatedTopic)
    return useCallback(
      async ({ goalId, data }, { apolloClient }) => {
        await apolloClient.mutate({
          mutation: CreateActionOnGoalDocument,
          variables: {
            goalId,
            data: {
              ...data,
              parentMeetingId: data.parentMeetingId ?? meeting?.id,
            },
          },
          update: (cache, { data }) => {
            const action = data?.createActionOnGoal
            if (action?.__typename === 'Action') {
              capture('Action Created', {
                parent: 'Goal',
                parentMeetingId: meeting?.id,
              })
              markActionAsCreatedHere(cache, action)
              emit({ type: 'upserted', action })
            }
          },
        })
      },
      [meeting?.id, emit],
    )
  },
}

import { BaseLink, Flex, Suspender } from '@mm/company-ui'
import React from 'react'
import { GoalMetadataPanelDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'
import { GoalTitle } from './GoalTitle'

export type GoalTitleSectionProps = {
  goalId: string
}

export const GoalTitleSection = React.forwardRef<HTMLDivElement, GoalTitleSectionProps>(({ goalId }, ref) => {
  const { data, loading, error } = useQuery(GoalMetadataPanelDocument, {
    variables: {
      id: goalId,
    },
  })

  const goal = data?.goal
  const { commentsLabel } = useTotalComments(goal)
  const { actionsLabel } = useTotalActions(goal)

  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Topic not found')
  }

  return (
    <Flex column ref={ref} sx={{ padding: 1 }}>
      <GoalTitle id={goalId} cached />

      <Flex gap={2}>
        <BaseLink href="#comments" sx={{ fontSize: 0, color: 'text-light' }}>
          {commentsLabel}
        </BaseLink>
        <BaseLink href="#actions" sx={{ fontSize: 0, color: 'text-light' }}>
          {actionsLabel}
        </BaseLink>
      </Flex>
    </Flex>
  )
})

GoalTitleSection.displayName = 'GoalTitleSection'

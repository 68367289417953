import { BaseLink, Flex, Suspender, Text } from '@mm/company-ui'
import React from 'react'
import { SinglePrototoypeFeedbackDocument } from '../../../gen/graphql/documents'
import { CreatedContext } from '../../actions/components/CreatedContext'
import { useQuery } from '../../apollo'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'

export type FeedbackTitleSectionProps = {
  prototypeFeedbackId: string
}

export const FeedbackTitleSection = React.forwardRef<HTMLDivElement, FeedbackTitleSectionProps>(
  ({ prototypeFeedbackId }, ref) => {
    const { data, loading, error } = useQuery(SinglePrototoypeFeedbackDocument, {
      variables: {
        id: prototypeFeedbackId,
      },
    })

    const prototypeFeedback = data?.prototypeFeedback

    const { commentsLabel } = useTotalComments(prototypeFeedback)
    const { actionsLabel } = useTotalActions(prototypeFeedback)

    if (prototypeFeedback == null) {
      if (loading) {
        return <Suspender />
      }
      throw error ?? new Error('Feedback not found')
    }

    return (
      <Flex column ref={ref} sx={{ padding: 1 }}>
        <CreatedContext
          createdAt={prototypeFeedback.createdAt}
          parent={prototypeFeedback.parent?.__typename === 'Meeting' ? prototypeFeedback.parent : undefined}
        />

        {/* Margin and Padding mimmics the inputs used in other titles */}
        <Text variant="h4" sx={{ paddingY: 1, marginBottom: '2px' }}>
          Feedback to{' '}
          {prototypeFeedback.to
            ? prototypeFeedback.to.__typename === 'Meeting'
              ? prototypeFeedback.to.title
              : prototypeFeedback.to.firstName
            : ''}
        </Text>

        <Flex gap={2}>
          <BaseLink href="#comments" sx={{ fontSize: 0, color: 'text-light' }}>
            {commentsLabel}
          </BaseLink>
          <BaseLink href="#actions" sx={{ fontSize: 0, color: 'text-light' }}>
            {actionsLabel}
          </BaseLink>
        </Flex>
      </Flex>
    )
  },
)

FeedbackTitleSection.displayName = 'FeedbackTitleSection'

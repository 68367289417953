import { yDocFragment } from '@mm/common'
import { getSchema } from '@tiptap/core'
import { generateHTML, generateJSON } from '@tiptap/html'
import { prosemirrorJSONToYDoc, yDocToProsemirrorJSON } from 'y-prosemirror'
import * as Y from 'yjs'
import extension from './extension'

const EMPTY_JSON_CONTENT = generateJSON('', [extension])
const EMPTY_HTML_CONTENT = generateHTML(EMPTY_JSON_CONTENT, [extension])

export const htmlToYDocState = (html?: string | null) => {
  if (!html) {
    return Uint8Array.from([])
  } else {
    const jsonContent = generateJSON(html, [extension])
    const yDoc = prosemirrorJSONToYDoc(getSchema([extension]), jsonContent, yDocFragment)
    const content = Y.encodeStateAsUpdate(yDoc)
    return Uint8Array.from(content)
  }
}

export const yDocStateToProsemirrorJSON = (yDocState: Uint8Array) => {
  if (yDocState.byteLength === 0) {
    return EMPTY_JSON_CONTENT
  }
  const yDoc = new Y.Doc()
  Y.applyUpdate(yDoc, yDocState)
  return yDocToProsemirrorJSON(yDoc, yDocFragment)
}

export const yDocStateToHtml = (yDocState: Uint8Array) => {
  if (yDocState.byteLength === 0) {
    return EMPTY_HTML_CONTENT
  }
  return generateHTML(yDocStateToProsemirrorJSON(yDocState), [extension])
}

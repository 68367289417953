import { ConfirmPopover, FlyoutItem, useConfirmPopover, useFeatureFlags } from '@mm/company-ui'
import { DeleteIcon, EditIcon, LoadingIcon } from '@mm/company-ui-icons'
import React from 'react'
import { useCommand } from '../../commands'
import { useContextualDrawers } from '../../navigation'
import { deleteFeedbackCommand } from '../commands/deleteFeedbackCommand'

export type FeedbackMoreMenuProps = {
  id: string
  onSelect?: () => void
}

const DeleteMenuItem = ({ id, onSelect }: FeedbackMoreMenuProps) => {
  const { confirmBeforeDelete } = useFeatureFlags()
  const { triggerConfirm, confirmControlProps } = useConfirmPopover()
  const deleteFeedback = useCommand(deleteFeedbackCommand)
  return (
    <>
      <FlyoutItem
        onClick={async () => {
          if (!confirmBeforeDelete || (await triggerConfirm())) {
            await deleteFeedback.execute({ prototypeFeedbackId: id, onFeedbackDeleted: onSelect })
          }
        }}
        startIcon={deleteFeedback.loading ? <LoadingIcon /> : <DeleteIcon />}
      >
        Delete
      </FlyoutItem>
      <ConfirmPopover
        {...confirmControlProps}
        zIndex={10000}
        title="Permanently delete the Feedback?"
        description="This action cannot be reversed. Actions created for this Feedback will not be deleted and will still show in your Actions tracker."
        confirmLabel="Delete permanently"
      />
    </>
  )
}

const EditMenuItem = ({ id, onSelect }: FeedbackMoreMenuProps) => {
  const { showDrawer } = useContextualDrawers()
  return (
    <FlyoutItem
      onClick={(e) => {
        showDrawer('feedback', id, e)
        onSelect?.()
      }}
      startIcon={<EditIcon />}
    >
      Edit
    </FlyoutItem>
  )
}

export const SingleFeedbackMoreMenu = (props: FeedbackMoreMenuProps) => {
  return <DeleteMenuItem {...props} />
}

export const FeedbackFeedItemMoreMenu = (props: FeedbackMoreMenuProps) => {
  return (
    <>
      <EditMenuItem {...props} />
      <DeleteMenuItem {...props} />
    </>
  )
}

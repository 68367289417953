import { Button, ButtonGroup } from '@mm/company-ui'
import React, { useCallback } from 'react'
import { GoalStatus } from '../../../gen/graphql/documents'

export type GoalStatusRadioProps = {
  status?: GoalStatus | null
  onChange: (status: GoalStatus) => void
  className?: string
}

export const GoalStatusRadio = ({ status, onChange, className }: GoalStatusRadioProps) => {
  const handleChange = useCallback(
    (newStatus: GoalStatus) => {
      if (newStatus !== status) {
        onChange?.(newStatus)
      }
    },
    [status, onChange],
  )

  return (
    <ButtonGroup gap={1} size="small" className={className}>
      <Button
        variant={status === 'ON_TRACK' ? 'success' : 'default'}
        onClick={() => {
          handleChange('ON_TRACK')
        }}
      >
        On Track
      </Button>
      <Button
        variant={status === 'AT_RISK' ? 'alert' : 'default'}
        onClick={() => {
          handleChange('AT_RISK')
        }}
      >
        At risk
      </Button>
      <Button
        variant={status === 'BEHIND' ? 'warning' : 'default'}
        onClick={() => {
          handleChange('BEHIND')
        }}
      >
        Off Track
      </Button>
    </ButtonGroup>
  )
}

import { CalendarRescheduleIcon, StopwatchTimerIcon } from '@mm/company-ui-icons/src'
import { Datepicker, RecurringDatepicker, Tooltip, useFeatureFlags } from '@mm/company-ui/src'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { ThemeUICSSObject } from 'theme-ui'
import { ActionDatepickerDocument, ActionRepeatType } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useEditAction } from '../../DoneNotDone'

export type ActionDatepickerProps = {
  id: string
  showFooter?: boolean
  disabled?: boolean
  showIcon?: boolean
  redIfPastDue?: boolean
  textAlign?: 'left' | 'right'
  variant?: 'default' | 'muted' | 'underline' | 'button' | 'tag'
  showRescheduledPrefix?: boolean
  prefix?: React.ReactElement
  cached?: boolean
  inputWidth?: number
  inputStyle?: ThemeUICSSObject
}

// TODO: ActionDatepicker.stories.tsx -- Show states for past due, rescheduled, etc...

export const ActionDatepicker = ({
  id,
  textAlign,
  redIfPastDue,
  showRescheduledPrefix,
  cached,
  prefix: prefixProp,
  inputWidth = 100,
  inputStyle,
  ...props
}: ActionDatepickerProps) => {
  const { data } = useQuery(ActionDatepickerDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: { id },
  })
  const editAction = useEditAction(id)
  const { recurringAction } = useFeatureFlags()
  const handleChange = useCallback(
    async (date: Date | null) => {
      if (date && editAction) {
        await editAction({ dueAt: DateTime.fromJSDate(date).toMillis() })
      }
    },
    [editAction],
  )
  const action = data?.action

  const handleRepeatChange = useCallback(
    async ({ repeat }: { repeat?: ActionRepeatType }) => {
      if (editAction) {
        await editAction({ repeat: repeat == null ? 'NONE' : repeat })
      }
    },
    [editAction],
  )

  if (!action) {
    return null
  }

  const isPastDue =
    !!action.dueAt &&
    DateTime.fromMillis(action.dueAt).startOf('day') <= DateTime.now().minus({ days: 1 }).startOf('day')

  const showPastDue = isPastDue && redIfPastDue && action.status === 'ACTIVE'
  const showRescheduled = showRescheduledPrefix && action.dateRescheduled
  const prefix = showRescheduled ? (
    <>
      <Tooltip description="Rescheduled" position="top">
        <CalendarRescheduleIcon />
      </Tooltip>
      {prefixProp}
    </>
  ) : showPastDue ? (
    <>
      <Tooltip description="Past Due" position="top">
        <StopwatchTimerIcon />
      </Tooltip>
      {prefixProp}
    </>
  ) : (
    prefixProp || ''
  )
  return recurringAction ? (
    <RecurringDatepicker
      {...props}
      repeatOptions={actionRepeatOptions}
      onRepeatChange={handleRepeatChange}
      repeat={action.repeat}
      value={action.dueAt}
      inputContainerStyle={{ color: showPastDue || showRescheduled ? 'system-text-warning' : '', width: inputWidth }}
      inputStyle={{ textAlign, color: showPastDue || showRescheduled ? 'system-text-warning' : '', ...inputStyle }}
      prefix={prefix}
      onChange={handleChange}
    />
  ) : (
    <Datepicker
      {...props}
      value={action.dueAt}
      inputContainerStyle={{ color: showPastDue || showRescheduled ? 'system-text-warning' : '', width: inputWidth }}
      inputStyle={{ textAlign, color: showPastDue || showRescheduled ? 'system-text-warning' : '', ...inputStyle }}
      prefix={prefix}
      onChange={handleChange}
    />
  )
}
export const actionRepeatOptions = [
  {
    label: 'Daily',
    value: 'DAILY' as const,
  },
  {
    label: 'Weekly',
    value: 'WEEKLY' as const,
  },
  {
    label: 'Monthly',
    value: 'MONTHLY' as const,
  },
  {
    label: 'Yearly',
    value: 'YEARLY' as const,
  },
]

import { useCallback } from 'react'
import {
  DuplicateAndAssignActionDocument,
  DuplicateAndAssignActionInput,
  GetActionDocument,
} from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { InternalRefetchQueriesInclude } from '../../apollo'
import { Command } from '../../commands'
import { useEmitEventBusEvent } from '../../eventbus'
import { ActionsUpdatedTopic } from '../subscriptions/GlobalActionsSubscription'
import { markActionAsCreatedHere } from './markAsCreatedHere'

type DuplicateAndAssignActionCommandValue = {
  actionId: string
  data: DuplicateAndAssignActionInput
  refetchQueries?: InternalRefetchQueriesInclude
}

export const duplicateAndAssignActionCommand: Command<DuplicateAndAssignActionCommandValue> = {
  id: 'duplicateAndAssignAction',
  name: 'Duplicate & Assign Action',
  canExecute: async ({ actionId }, { apolloClient }) => {
    const currentAction = await apolloClient.query({ query: GetActionDocument, variables: { id: actionId } })
    return !!currentAction.data.action
  },
  useExecute: () => {
    const emit = useEmitEventBusEvent(ActionsUpdatedTopic)
    return useCallback(
      async ({ actionId, data, refetchQueries = [] }, { apolloClient }) => {
        await apolloClient.mutate({
          mutation: DuplicateAndAssignActionDocument,
          variables: {
            id: actionId,
            data,
          },
          refetchQueries,
          update: (cache, { data }) => {
            if (data?.duplicateAndAssignAction.__typename !== 'ActionList') {
              return
            }

            for (const action of data.duplicateAndAssignAction.actions) {
              capture('Action Created', {
                duplicate: true,
              })
              markActionAsCreatedHere(cache, action)
              emit({ type: 'upserted', action })
            }
          },
        })
      },
      [emit],
    )
  },
}

import { EditableProps, useBeforeUnload } from '@mm/company-ui'
import { fromByteArray, toByteArray } from 'base64-js'
import _ from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import * as Y from 'yjs'
import { YjsProviderDocument, YjsProviderUpdateDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'

export function useYjsProvider(yDoc: Y.Doc, documentId?: string): EditableProps {
  const { data } = useQuery(YjsProviderDocument, {
    skip: !documentId,
    variables: {
      id: documentId ?? '',
    },
    pollInterval: 5000,
  })

  const [updateDocument, { loading }] = useMutation(YjsProviderUpdateDocument)

  const updateHandler = useMemo(() => {
    if (documentId == null) {
      return null
    } else {
      const throttled = _.throttle(
        (): void => {
          obj.pending = false
          // here we take snapshot of the whole document instead of applying granular updates
          // since we store the document snapshot in the firestore anyway, and doing it other way would force us
          // to handle situations where only part of the updates are applied in order to guarantee consistency between clients
          // WARN: this is probably a performance bottleneck for large documents
          const snapshot = Y.encodeStateAsUpdate(yDoc)
          updateDocument({
            variables: {
              id: documentId,
              patch: fromByteArray(snapshot),
            },
          }).catch((err) => {
            // eslint-disable-next-line no-console
            console.error('Failed to store yjs update', err)
          })
        },
        1000,
        { leading: false, trailing: true },
      )
      const obj = {
        invoke: () => {
          obj.pending = true
          throttled()
        },
        pending: false,
        flush: throttled.flush,
        cancel: throttled.cancel,
      }
      return obj
    }
  }, [documentId, yDoc, updateDocument])

  useEffect(() => {
    if (updateHandler) {
      yDoc.on('update', updateHandler.invoke)
      return () => {
        yDoc.off('update', updateHandler.invoke)
      }
    } else {
      return undefined
    }
  }, [yDoc, updateHandler])

  useEffect(() => () => updateHandler?.flush(), [updateHandler])

  useBeforeUnload(() => loading || updateHandler?.pending === true)

  const byteContent = data?.document?.byteContent

  useEffect(() => {
    if (byteContent) {
      Y.applyUpdate(yDoc, toByteArray(byteContent))
    }
  }, [yDoc, byteContent])

  return {
    onBlur: useCallback(() => {
      updateHandler?.flush()
    }, [updateHandler]),
  }
}

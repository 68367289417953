import { useLocalStorage } from 'react-use'

const STORAGE_KEY = 'dismissed-content'

export const useDismissContent = (contentId = ''): [boolean, () => void] => {
  const [dismissed, setDismissed] = useLocalStorage<{ [x: string]: boolean }>(STORAGE_KEY, {})

  const dismiss = () => {
    if (dismissed && contentId != '') {
      dismissed[contentId] = true
      setDismissed(dismissed)
    }
  }

  const value = dismissed?.[contentId] == true

  return [value, dismiss]
}

import { Flex, Text, Tooltip } from '@mm/company-ui'
import { CloseIcon } from '@mm/company-ui-icons'
import Link from 'next/link'
import { Route } from 'nextjs-routes'
import React from 'react'

export type PanelMetadataLinkChipsProps = {
  icon?: React.ReactElement
  chips: Array<{ id: string; label: string; href: Route; type: string }>
  hasNextPage?: boolean
  onLoadMore?: () => void
  onRemove?: (id: string) => void
}

export const PanelMetadataLinkChips = ({
  icon,
  chips,
  hasNextPage,
  onLoadMore,
  onRemove,
}: PanelMetadataLinkChipsProps) => {
  return (
    <Flex column gap={1.5}>
      {chips.map(({ id, label, href, type }) => (
        <Flex
          row
          gap={1}
          key={id}
          align="center"
          justify="center"
          sx={{
            width: 'fit-content',
            px: 1,
            '&:hover': {
              '& > div > div': {
                visibility: 'visible',
              },
            },
          }}
        >
          <Tooltip description={`${type}: ${label}`} delay={200} position="bottom">
            <Flex align="center" gap={1}>
              {icon}
              <Link key={id} href={href} passHref={true}>
                <Text
                  as="a"
                  underline
                  color="text"
                  overflow="ellipsis"
                  sx={{ fontSize: 1, cursor: 'pointer', maxWidth: 300 }}
                >
                  {label}
                </Text>
              </Link>
            </Flex>
          </Tooltip>
          {onRemove && (
            <Tooltip description="Remove" position="bottom" delay={200}>
              <Flex
                title="Remove"
                sx={{ visibility: 'hidden', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation()
                  onRemove?.(id)
                }}
              >
                <CloseIcon width={1.5} height={1.5} color="text-light" />
              </Flex>
            </Tooltip>
          )}
        </Flex>
      ))}
      {hasNextPage ? (
        <Text underline color="text" sx={{ cursor: 'pointer', paddingLeft: 4 }} onClick={onLoadMore}>
          +Show more
        </Text>
      ) : null}
    </Flex>
  )
}

import { PanelMetadataContainer, PanelMetadataItem, Suspender } from '@mm/company-ui'
import { CircleCheckmarkIcon, EyeViewOpen, LinkIcon, UserProfileIcon } from '@mm/company-ui-icons'
import React from 'react'
import { TopicMetadataDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { PanelMetadataItemChips } from '../../components'
import { UserSelectWithCompanyMembers, UserSelectWithMeetingParticipants } from '../../components/UserSelectWithQuery'
import { editTopicOwnerCommand } from '../commands/editTopicOwnerCommand'
import { TopicPrivacyTag } from './TopicPrivacyTag'
import { TopicStatusTagSelect } from './TopicStatusTagSelect'

export type TopicMetadataProps = {
  topicId: string
  meetingId?: string
}

export const TopicMetadata = ({ topicId, meetingId }: TopicMetadataProps) => {
  const { data, loading, error, fetchMore } = useQuery(TopicMetadataDocument, {
    variables: {
      id: topicId,
    },
  })

  const editTopicOwner = useCommand(editTopicOwnerCommand)

  const topic = data?.topic

  if (topic == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Topic not found')
  }

  const handleLoadMore = (endCursor: string) => () => {
    void fetchMore({
      variables: {
        meetingAfter: endCursor,
      },
    })
  }

  return (
    <PanelMetadataContainer>
      <PanelMetadataItem title="Status" icon={<CircleCheckmarkIcon />} sx={{ alignItems: 'center' }}>
        <TopicStatusTagSelect topicId={topicId} />
      </PanelMetadataItem>

      <PanelMetadataItem title="Presenter" icon={<UserProfileIcon />}>
        {meetingId ? (
          <UserSelectWithMeetingParticipants
            aria-label="Presenter" // TODO consider wrapping with a label element to reduce duplication
            meetingId={meetingId}
            value={topic?.owner?.id}
            onAdd={(ownerId: string) => {
              void editTopicOwner.execute({ topicId: topic.id, ownerId })
            }}
            withName
          />
        ) : (
          <UserSelectWithCompanyMembers
            aria-label="Presenter" // TODO consider wrapping with a label element to reduce duplication
            value={topic?.owner?.id}
            onAdd={(ownerId: string) => {
              void editTopicOwner.execute({ topicId: topic.id, ownerId })
            }}
            withName
          />
        )}
      </PanelMetadataItem>

      <PanelMetadataItem title="Privacy" sx={{ alignItems: 'center' }} icon={<EyeViewOpen />}>
        <TopicPrivacyTag topicId={topicId} />
      </PanelMetadataItem>

      {topic.meetings?.edges && topic.meetings?.edges.length !== 0 && (
        <PanelMetadataItemChips
          title="Meetings"
          icon={<LinkIcon />}
          chips={topic.meetings.edges.map(({ node }) => ({
            id: node.id,
            label: node.title,
            href: { pathname: '/meetings/[id]', query: { id: node.id } },
          }))}
          hasNextPage={topic.meetings.pageInfo.hasNextPage}
          onLoadMore={handleLoadMore(topic.meetings.pageInfo.endCursor)}
        />
      )}
    </PanelMetadataContainer>
  )
}

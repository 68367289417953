import { formatUserDisplayName, Suspender } from '@mm/company-ui'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import {
  DecisionParticipantsAddDocument,
  DecisionParticipantsCellDocument,
  DecisionParticipantsRemoveDocument,
} from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { useActiveUser } from '../../users'
import { DecisionWarningRemoval } from './DecisionWarningRemoval'

export type DecisionObserversCellProps = {
  decisionId: string
  cached?: boolean
}

export const DecisionObserversCell = ({ cached, decisionId }: DecisionObserversCellProps) => {
  const router = useRouter()
  const [confirmUserId, setConfirmUserId] = useState('')
  const activeUser = useActiveUser()
  const [addDecisionParticipant] = useMutation(DecisionParticipantsAddDocument)
  const [removeDecisionParticipant, { loading: removeParticipantLoading }] = useMutation(
    DecisionParticipantsRemoveDocument,
  )
  const { data, loading, error } = useQuery(DecisionParticipantsCellDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id: decisionId,
    },
  })
  const decision = data?.decision
  const activeUserId = activeUser?.id

  if (decision == null || activeUserId == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('decision or company users not found, id: ' + decisionId)
  }
  const decisionObservers = decision.observers?.edges.map((edge) => edge.node)
  const decisionContributorIds = decision.contributors?.edges.map((edge) => edge.node.id)
  const decisionMakerId = decision.decisionMaker?.id
  const selectedObservers = decisionObservers?.map((observer) => {
    return {
      label: formatUserDisplayName(observer),
      value: observer.id,
    }
  })

  const handleAdd = (userId: string) => {
    void addDecisionParticipant({
      variables: {
        id: decisionId,
        userId,
        role: 'OBSERVER',
      },
    })
  }

  const handleRemove = (userId: string) => {
    if (
      userId === activeUserId &&
      !decisionContributorIds.includes(userId) &&
      decisionMakerId !== userId &&
      decision.isPrivate
    ) {
      setConfirmUserId(userId)
      return
    }

    setConfirmUserId('')
    void removeDecisionParticipant({
      variables: {
        id: decisionId,
        userId,
      },
    })
  }

  return (
    <>
      <UserSelectWithCompanyMembers
        aria-label="Observers"
        multiple
        value={selectedObservers?.map((option) => option.value)}
        onAdd={handleAdd}
        onRemove={handleRemove}
      />

      {confirmUserId && (
        <DecisionWarningRemoval
          loading={removeParticipantLoading}
          onCancel={() => {
            setConfirmUserId('')
          }}
          onConfirmation={() => {
            void removeDecisionParticipant({
              variables: {
                id: decisionId,
                userId: confirmUserId,
              },
              onCompleted: async ({ removeDecisionParticipant }) => {
                if (removeDecisionParticipant.__typename === 'Decision') {
                  void router.push('/decisions')
                  setConfirmUserId('')
                }
              },
            })
          }}
        />
      )}
    </>
  )
}

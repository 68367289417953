import { LinkIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import React from 'react'
import { ActionMetadataLinkTagsFragmentFragmentDoc } from '../../../gen/graphql/documents'
import { useFragment_experimental } from '../../apollo'
import { TagLink } from '../../components'
import { useFeatureFlags } from '../../featureFlags'

type ActionMetadataLinkTagsProps = {
  actionId: string
  className?: string
  ['data-targetid']?: string
  baseUrl?: '/' | '/accountability/'
}

export const ActionMetadataLinkTags = ({ actionId, baseUrl = '/' }: ActionMetadataLinkTagsProps) => {
  const { prototypeFeedback } = useFeatureFlags()

  const { data } = useFragment_experimental({
    fragment: ActionMetadataLinkTagsFragmentFragmentDoc,
    fragmentName: 'ActionMetadataLinkTagsFragment',
    from: {
      __typename: 'Action',
      id: actionId,
    },
  })

  if (!data) {
    // eslint-disable-next-line no-console
    console.log('Fragment returned no data, make sure a parent of ActionMetadataLinkTags is querying the fragment')
    return null
  }

  return (
    <>
      {data.parentMeeting ? (
        <TagLink href={{ pathname: '/meetings/[id]', query: { id: data.parentMeeting.id } }}>
          <LinkIcon />
          Meeting: {_.truncate(data.parentMeeting.title, { length: 60 })}
        </TagLink>
      ) : (
        data.meetings?.edges.map(({ node }) => (
          <TagLink key={node.id} href={{ pathname: '/meetings/[id]', query: { id: node.id } }}>
            <LinkIcon />
            Meeting: {_.truncate(node.title, { length: 60 })}
          </TagLink>
        ))
      )}

      {data.topics?.edges.map(({ node }) => (
        <TagLink key={node.id} href={{ pathname: '/topics/[id]', query: { id: node.id } }}>
          <LinkIcon />
          Topic: {_.truncate(node.title, { length: 60 })}
        </TagLink>
      ))}

      {data.goals?.edges.map(({ node }) => (
        <TagLink key={node.id} href={{ pathname: `${baseUrl}goals/[id]`, query: { id: node.id } }}>
          <LinkIcon />
          Goal: {_.truncate(node.title, { length: 60 })}
        </TagLink>
      ))}

      {data.decisions?.edges.map(({ node }) => (
        <TagLink key={node.id} href={{ pathname: '/decisions/[id]', query: { id: node.id } }}>
          <LinkIcon />
          Decision: {_.truncate(node.title, { length: 60 })}
        </TagLink>
      ))}

      {prototypeFeedback &&
        data.prototypeFeedback?.edges.map(({ node }) => (
          <TagLink key={node.id} href={{ pathname: '/feedback/[id]', query: { id: node.id } }}>
            <LinkIcon />
            Feedback: {_.truncate(node.title, { length: 60 })}
          </TagLink>
        ))}

      {data.followUpTo ? (
        <TagLink href={{ pathname: `${baseUrl}actions/[id]`, query: { id: data.followUpTo.id } }}>
          <LinkIcon />
          Follow-up Action: {_.truncate(data.followUpTo.title, { length: 60 })}
        </TagLink>
      ) : null}
    </>
  )
}

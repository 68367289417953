import { useTheme } from '@emotion/react'
import { useDebouncedValue, Input } from '@mm/company-ui'
import { LoadingIcon, SearchIcon } from '@mm/company-ui-icons/src'
import { ListKeyboardDelegate, useSelectableCollection } from '@react-aria/selection'
import isHotkey from 'is-hotkey'
import React, { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import { AriaGridListItemOptions, useGridList, useGridListItem } from 'react-aria'
import { Item, ListState, useListState } from 'react-stately'
import { SearchDocument, SearchResultRowFragment, SearchableEntity } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'
import { NoResultsRow, SearchResultRow } from './SearchResultRows'

type ListItemProps = {
  item: AriaGridListItemOptions['node']
  state: ListState<SearchResultRowFragment>
  isFocused: boolean
  onClick?: MouseEventHandler
}
const AriaGridListItem = ({ item, state, isFocused, onClick }: ListItemProps) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const { rowProps, gridCellProps } = useGridListItem({ node: item }, state, ref)
  return (
    <>
      <div
        {...rowProps}
        onClick={onClick}
        ref={ref}
        sx={{
          cursor: 'pointer',
          backgroundColor: isFocused ? 'background-light' : undefined,
          borderBottomColor: 'border',
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          ':hover': {
            backgroundColor: 'background-light',
          },
        }}
      >
        <div {...gridCellProps}>{item.rendered}</div>
      </div>
    </>
  )
}

type SearchPanelProps = {
  handleRowClick?: (item: SearchResultRowFragment, event?: React.MouseEvent) => void
  searchIn?: SearchableEntity[]
  renderRow?: (item: SearchResultRowFragment) => React.ReactNode
  className?: string
}
export const SearchPanel = ({ handleRowClick, renderRow, searchIn, className }: SearchPanelProps) => {
  const theme = useTheme()
  const { activeCompanyId } = useActiveCompanyId()
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 500)
  const isSearchTermEmpty = debouncedSearchQuery.trim() === ''
  const { data: searchResults, loading } = useQuery(SearchDocument, {
    variables: {
      query: debouncedSearchQuery,
      companyId: activeCompanyId,
      ...(searchIn && { searchIn }),
    },
    skip: isSearchTermEmpty,
  })

  const state = useListState({
    items: searchResults?.search?.edges || [],
    children: (item: SearchResultRowFragment) => (
      <Item key={item.node.id} textValue={item.node.title}>
        {renderRow ? renderRow(item) : <SearchResultRow row={item} />}
      </Item>
    ),
  })
  const searchResultListRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { gridProps } = useGridList(
    {
      'aria-label': 'Search Results',
      isVirtualized: false,
    },
    state,
    searchResultListRef,
  )
  const delegate = useMemo(
    () => new ListKeyboardDelegate(state.collection, state.disabledKeys, searchResultListRef),
    [state.collection, state.disabledKeys, searchResultListRef],
  )

  // TODO T-2757 extract common react-aria usage to utils/hooks
  // Collection to handle keyboard navigation
  const { collectionProps } = useSelectableCollection({
    selectionManager: state.selectionManager,
    keyboardDelegate: delegate,
    disallowTypeAhead: true,
    disallowEmptySelection: true,
    shouldFocusWrap: true,
    ref: inputRef,
    scrollRef: searchResultListRef,
  })
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (isHotkey('Enter', event)) {
      event.preventDefault()

      const item = state.collection.getItem(state.selectionManager.focusedKey)
      if (!item) return
      handleRowClick?.(item.value)
      return
    }
    if (isHotkey('Escape', event)) {
      event.preventDefault()
      return
    }

    collectionProps.onKeyDown?.(event)
  }

  useEffect(() => {
    const focusedKey = state.selectionManager.focusedKey
    const firstKey = state.collection.getFirstKey()

    if ((!state.collection.getItem(focusedKey) || focusedKey !== firstKey) && firstKey) {
      state.selectionManager.setFocusedKey(firstKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults])

  return (
    <>
      <Input
        ref={inputRef}
        sx={{
          width: '100%',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: 'white',
          borderBottom: `1px solid ${theme.colors['border']}`,
          paddingLeft: 1,
          paddingRight: 1.5,
          '&:focus-within': {
            boxShadow: `none`,
          },
        }}
        onKeyDown={onKeyDown}
        value={searchQuery}
        autoFocus={true}
        placeholder="Search anything..."
        prefix={loading ? <LoadingIcon /> : <SearchIcon />}
        isClearable={!isSearchTermEmpty}
        onChange={(value) => {
          setSearchQuery(value)
        }}
      />
      <div {...gridProps} ref={searchResultListRef} sx={{ maxHeight: '65vh', overflowY: 'auto' }} className={className}>
        {state.collection.size > 0 &&
          [...state.collection].map((item) => (
            <AriaGridListItem
              key={item.key}
              isFocused={item.key === state.selectionManager.focusedKey}
              item={item}
              state={state}
              onClick={(event) => {
                handleRowClick?.(item.value, event)
              }}
            />
          ))}

        {!state.collection.size && !loading && !isSearchTermEmpty && <NoResultsRow />}
      </div>
    </>
  )
}

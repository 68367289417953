import { Flex, Text, Select, Button, Popover, PopupWrapper } from '@mm/company-ui'
import { ConfigureIcon } from '@mm/company-ui-icons/src'
import React from 'react'
import { useDisplaySettings } from './DisplayMenuContext'

export const DisplayMenu = ({ className }: { className?: string }) => {
  const { config, state, setState } = useDisplaySettings()
  const selectStyleOverrides = { backgroundColor: 'background' }
  return (
    <Popover
      placement="bottom-start"
      render={() => {
        return (
          <PopupWrapper sx={{ width: 300, backgroundColor: 'background-light' }}>
            <Flex column gap={2} sx={{ padding: 2 }}>
              {state?.grouping?.length ? (
                <>
                  <Flex align="center" justify="space-between">
                    <Text> Grouping :</Text>{' '}
                    <Select
                      sx={{ minWidth: 150 }}
                      controlStyleOverrides={selectStyleOverrides}
                      variant="condensed"
                      options={config.grouping.map((grouping) => ({
                        label: grouping,
                        value: grouping,
                      }))}
                      onChange={(e) => {
                        if (!e?.value) return
                        setState((prev) => ({ ...prev, grouping: e?.value }))
                      }}
                      value={{
                        label: state.grouping,
                        value: state.grouping,
                      }}
                    ></Select>
                  </Flex>
                </>
              ) : null}
              {state.sortingField ? (
                <>
                  <Flex justify="space-between">
                    <Text> Sort :</Text>{' '}
                    <Flex column gap={1} sx={{ minWidth: 150 }}>
                      <Select
                        variant="condensed"
                        controlStyleOverrides={selectStyleOverrides}
                        options={config.sortingFields?.map((sorting) => {
                          return {
                            label: sorting.label,
                            value: sorting.value,
                          }
                        })}
                        onChange={(e) => {
                          if (!e?.value) return
                          setState((prev) => ({ ...prev, sortingField: { label: e.label, value: e.value } }))
                        }}
                        value={{
                          label: state.sortingField?.label,
                          value: state.sortingField?.value,
                        }}
                      ></Select>
                      <Select
                        variant="condensed"
                        controlStyleOverrides={selectStyleOverrides}
                        options={config.sortingOrder.map((sorting) => {
                          return {
                            label: sorting.label,
                            value: sorting.value,
                          }
                        })}
                        onChange={(e) => {
                          if (!e?.value) return
                          setState((prev) => ({ ...prev, sortingOrder: { label: e.label, value: e.value } }))
                        }}
                        value={{
                          label: state.sortingOrder?.label || 'Ascending',
                          value: state.sortingOrder?.value || 'asc',
                        }}
                      ></Select>
                    </Flex>
                  </Flex>
                </>
              ) : null}
              {state.fields ? (
                <>
                  <Text variant="body" sx={{ py: 1 }}>
                    Display Properties
                  </Text>
                  <Flex gap={1} wrap>
                    {config.fields.map((fieldName, i) => (
                      <Button
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            fields: {
                              ...prev.fields,
                              [fieldName]: !prev.fields?.[fieldName],
                            },
                          }))
                        }}
                        sx={{
                          border: '1px solid',
                          borderColor: 'border',
                          borderRadius: 'default',
                          backgroundColor: state.fields?.[fieldName] ? 'background' : undefined,
                          fontWeight: 'body',
                        }}
                        size="small"
                        variant={state.fields?.[fieldName] ? 'default' : 'muted'}
                        key={i}
                      >
                        {fieldName}
                      </Button>
                    ))}
                  </Flex>
                </>
              ) : null}
            </Flex>
          </PopupWrapper>
        )
      }}
    >
      {({ getReferenceProps }) => (
        <Button size="small" {...getReferenceProps()} startIcon={<ConfigureIcon />} className={className}>
          Display
        </Button>
      )}
    </Popover>
  )
}

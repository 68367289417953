import { Avatar, formatUserDisplayName, Tag } from '@mm/company-ui'
import React from 'react'
import { ActionAssigneeTagSelectFragmentFragmentDoc } from '../../../gen/graphql/documents'
import { useFragment_experimental } from '../../apollo'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { editActionAssigneeCommand } from '../commands/editActionAssigneeComand'

export type ActionAssigneeTagSelectProps = {
  actionId: string
  label?: string
  withName?: boolean
}

export const ActionAssigneeTagSelect = ({
  actionId,
  label = 'Assignee',
  withName = false,
}: ActionAssigneeTagSelectProps) => {
  const editActionAssignee = useCommand(editActionAssigneeCommand)

  const { data } = useFragment_experimental({
    fragment: ActionAssigneeTagSelectFragmentFragmentDoc,
    fragmentName: 'ActionAssigneeTagSelectFragment',
    from: {
      __typename: 'Action',
      id: actionId,
    },
  })

  if (!data) {
    // eslint-disable-next-line no-console
    console.log(`Fragment ActionAssigneeTagSelect returned no data, make sure a parent is querying for it`)
    return null
  }

  const handlePickerSelectUser = (value: string) => {
    void editActionAssignee.execute({ actionId, assigneeId: value })
  }

  return (
    <UserSelectWithCompanyMembers aria-label="Assignee" value={data.assignee?.id} onAdd={handlePickerSelectUser}>
      {({ value, multiple: _, withName: __, ...restProps }) => {
        const [user] = value

        if (user) {
          return (
            <Tag {...restProps}>
              {label}
              <Avatar name={formatUserDisplayName(user.node)} />
              {withName && formatUserDisplayName(user.node)}
            </Tag>
          )
        }
        return null
      }}
    </UserSelectWithCompanyMembers>
  )
}

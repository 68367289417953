import { allDecisionStatuses } from '@mm/common'
import {
  Button,
  ButtonGroup,
  DRAWER_SECTION_INNER_PADDING,
  DrawerLayout,
  DrawerSection,
  Flex,
  Flyout,
  HotkeyTooltip,
  Suspender,
  SuspenseLoader,
  Text,
  useFeatureFlags,
} from '@mm/company-ui'
import {
  ActionsIcon,
  CommentChatIcon,
  DecisionIcon,
  EllipsisIcon,
  FilePageTemplateIcon,
  InfoIcon,
} from '@mm/company-ui-icons'
import { useRouter } from 'next/router'
import React from 'react'
import { SingleDecisionDocument } from '../../../gen/graphql/documents'
import { DecisionActions } from '../../actions'
import { useQuery } from '../../apollo'
import { QueuedCapabilitiesInterceptor, RegisterCapability } from '../../capabilities'
import { AddDecisionCommentBox } from '../../comments'
import { FOCUS_AND_QUOTE_COMMENT_AS_DECISION } from '../../comments/capabilities'
import { MarkOrUnmarkDecisionCapability } from '../../comments/components/DecisionCommentsList'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { DrawerView } from '../../components/DrawerView'
import { VerticalDivider } from '../../components/VerticalDivider'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'
import { useMeetingContext } from '../../meetings'
import { ContextualDrawerNavigation, useContextualDrawers } from '../../navigation'
import { ADD_TOPIC } from '../../topics/capabilities'
import { useActiveUserRequired } from '../../users'
import { CREATE_ISSUE } from '../capabilities'
import { DecidedCommentProvider } from '../DecidedCommentContext'
import { DecisionComments } from './DecisionComments'
import { DecisionCommentsDecisions } from './DecisionCommentsDecisions'
import { DecisionDescription } from './DecisionDescription'
import { DecisionDraft } from './DecisionDraft'
import { DecisionMetadata } from './DecisionMetadata'
import { DecisionMetadataSummary } from './DecisionMetadataSummary'
import { DecisionMoreMenu } from './DecisionMoreMenu'
import { DecisionPrivacyTag } from './DecisionPrivacyTag'
import { DecisionTitle } from './DecisionTitle'
import { DecisionTitleSection } from './DecisionTitleSection'
import { DecisionUserReviews } from './DecisionUserReviews'

export type SingleDecisionPageProps = {
  decisionId: string
}

export const SingleDecisionPage = ({ decisionId }: SingleDecisionPageProps) => {
  const router = useRouter()
  const user = useActiveUserRequired()
  const [isDecisionDraftVisible, setIsDecisionDraftVisible] = React.useState(false)

  const { active, hideDrawer } = useContextualDrawers()
  const {
    reorderAndHighlightDecisionComments,
    metadataSummary,
    commentsActionsInDrawersCollapsedWhenEmpty,
    quickDecisions,
    decisionBoxInDrawerView,
    contextualDrawerNavigation,
    reconfigureDrawerViews,
    userReviews,
    flipComments,
  } = useFeatureFlags()
  const meeting = useMeetingContext()

  const { data, loading, error } = useQuery(SingleDecisionDocument, {
    variables: {
      id: decisionId,
    },
  })
  const decision = data?.decision

  const { commentsLabel, hasComments } = useTotalComments(decision)
  const { actionsLabel, hasActions } = useTotalActions(decision)

  const decidedComments = decision?.decidedComments ?? []

  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Decision not found')
  }

  if (reconfigureDrawerViews) {
    return (
      <DecidedCommentProvider value={decidedComments}>
        <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meeting?.id} />
        <RegisterCapability capability={CREATE_ISSUE} handler={() => Promise.resolve()} skip={!meeting?.id} />
        <MarkOrUnmarkDecisionCapability
          decisionId={decisionId}
          user={{
            id: user.id,
            firstName: user.firstName || '',
          }}
        />
        <DrawerView
          resourceId={decisionId}
          resourceType="decisions"
          renderMoreMenu={() => (
            <DecisionMoreMenu
              id={decisionId}
              meetingId={meeting?.id}
              onDecisionDeleted={() => {
                if (!meeting) {
                  void router.push('/decisions')
                  return
                }
                if (active) hideDrawer()
              }}
            />
          )}
          renderExtraMenuItems={
            <>
              {userReviews && (
                <HotkeyTooltip shortcutKey="R" description="Mark as reviewed">
                  <DecisionUserReviews decisionId={decisionId} withButton markAsReviewedOnOpen />
                </HotkeyTooltip>
              )}
              <VerticalDivider />
              <DecisionPrivacyTag decisionId={decisionId} sx={{ background: 'none' }} />
            </>
          }
          renderTitle={<DecisionTitle id={decisionId} sx={{ fontSize: 2 }} />}
          renderMetadata={<DecisionMetadata decisionId={decisionId} />}
          renderDescription={
            <>
              <DecisionDescription decisionId={decisionId} fillHeight={false} padding={1} />

              {decisionBoxInDrawerView && (
                <Flex
                  column
                  gap={2}
                  data-testid="add-decision-box"
                  sx={{ padding: 1, paddingTop: 3, paddingBottom: 0 }}
                >
                  <Flex row align="center" gap={2}>
                    <DecisionIcon />
                    <Text variant="h5">Decision</Text>
                  </Flex>
                  {decidedComments.length > 0 && (
                    <SuspenseLoader>
                      <DecisionCommentsDecisions decisionId={decisionId} />
                    </SuspenseLoader>
                  )}
                  <QueuedCapabilitiesInterceptor
                    capability={FOCUS_AND_QUOTE_COMMENT_AS_DECISION}
                    onQueued={() => {
                      setIsDecisionDraftVisible(true)
                    }}
                  >
                    {(!(decidedComments.length > 0) || isDecisionDraftVisible) && (
                      <SuspenseLoader>
                        <div
                          sx={{
                            border: '1px solid',
                            borderColor: 'border',
                            borderRadius: 'medium',
                          }}
                        >
                          <DecisionDraft
                            decisionId={decisionId}
                            onDecisionCommentAdded={() => {
                              setIsDecisionDraftVisible(false)
                            }}
                          />
                        </div>
                      </SuspenseLoader>
                    )}
                  </QueuedCapabilitiesInterceptor>
                </Flex>
              )}
            </>
          }
          renderActions={<DecisionActions id={decisionId} renderEmptyPlaceholder={() => <></>} />}
          renderComments={
            <DecisionComments
              decisionId={decisionId}
              fixedComposer={false}
              order={flipComments ? 'oldest' : 'newest'}
              fixedAndScroll={false}
            />
          }
          renderCommentComposer={flipComments ? <AddDecisionCommentBox decisionId={decisionId} /> : null}
        />
      </DecidedCommentProvider>
    )
  }

  return (
    <DrawerLayout
      title={data?.decision?.title ?? ''}
      navigation={contextualDrawerNavigation && <ContextualDrawerNavigation />}
      renderTitleButtonGroup={(props) => (
        <ButtonGroup {...props}>
          <CloseDrawerButton resourceType="decisions" />

          <ExpandCollapseToggle resourceType="decisions" id={decisionId} />

          <Flyout
            position="bottom-start"
            content={
              <DecisionMoreMenu
                id={decisionId}
                onDecisionDeleted={() => {
                  void router.push('/decisions')
                }}
              />
            }
          >
            <Button variant="muted" startIcon={<EllipsisIcon />} textHidden size="small">
              Open menu
            </Button>
          </Flyout>
        </ButtonGroup>
      )}
      renderTitleSection={(props) => <DecisionTitleSection decisionId={decisionId} {...props} />}
    >
      <DecidedCommentProvider value={decidedComments}>
        <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meeting?.id} />
        <RegisterCapability capability={CREATE_ISSUE} handler={() => Promise.resolve()} skip={!meeting?.id} />
        <MarkOrUnmarkDecisionCapability
          decisionId={decisionId}
          user={{
            id: user.id,
            firstName: user.firstName || '',
          }}
        />
        <DrawerSection
          title="About"
          icon={<InfoIcon />}
          isClosedOnRender={metadataSummary}
          right={metadataSummary ? <DecisionMetadataSummary decisionId={decisionId} /> : null}
          dangerouslyCustomizeChildrenPadding={{
            paddingY: DRAWER_SECTION_INNER_PADDING - 1, // To Account for inconsistent Y padding of Metadata
          }}
        >
          <DecisionMetadata decisionId={decisionId} />
        </DrawerSection>

        <DrawerSection
          title="Issue"
          icon={<FilePageTemplateIcon />}
          isClosedOnRender={allDecisionStatuses.indexOf(decision.status) >= allDecisionStatuses.indexOf('IMPLEMENTING')}
          dangerouslyCustomizeChildrenPadding={{
            padding: 0, // Since content is a textarea, padding should be handled by that
          }}
        >
          {reorderAndHighlightDecisionComments ? <DecisionCommentsDecisions decisionId={decisionId} /> : null}

          <DecisionDescription decisionId={decisionId} fillHeight={false} padding={DRAWER_SECTION_INNER_PADDING} />
        </DrawerSection>

        {quickDecisions &&
          allDecisionStatuses.indexOf(decision.status) < allDecisionStatuses.indexOf('IMPLEMENTING') && (
            <DrawerSection
              title="Decision"
              icon={<DecisionIcon />}
              isClosedOnRender={true}
              dangerouslyCustomizeChildrenPadding={{
                padding: 0,
              }}
            >
              <DecisionDraft decisionId={decisionId} />
            </DrawerSection>
          )}

        {reorderAndHighlightDecisionComments ? (
          <>
            <DrawerSection
              id="actions"
              title="Actions"
              icon={<ActionsIcon />}
              isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasActions}
              right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{actionsLabel}</Text> : null}
              dangerouslyCustomizeChildrenPadding={{
                padding: 0, // Since action table has borders that are full-width, it handles its own padding
              }}
            >
              <DecisionActions
                id={decisionId}
                renderEmptyPlaceholder={() => (
                  <div sx={{ textAlign: 'center' }}>
                    <Text variant="small" color="text-light">
                      No actions yet.
                    </Text>
                  </div>
                )}
              />
            </DrawerSection>

            <DrawerSection
              id="comments"
              title="Comments"
              icon={<CommentChatIcon />}
              isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasComments}
              right={
                commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{commentsLabel}</Text> : null
              }
              dangerouslyCustomizeChildrenPadding={{
                padding: DRAWER_SECTION_INNER_PADDING - 1, // Since comments have extra padding for hover effects, offset here
              }}
            >
              <DecisionComments decisionId={decisionId} fixedComposer={false} order="newest" fixedAndScroll={false} />
            </DrawerSection>
          </>
        ) : (
          <>
            <DrawerSection
              id="comments"
              title="Comments"
              icon={<CommentChatIcon />}
              isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasComments}
              right={
                commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{commentsLabel}</Text> : null
              }
              dangerouslyCustomizeChildrenPadding={{
                padding: DRAWER_SECTION_INNER_PADDING - 1, // Since comments have extra padding for hover effects, offset here
              }}
            >
              <DecisionComments decisionId={decisionId} fixedComposer={false} order="newest" fixedAndScroll={false} />
            </DrawerSection>

            <DrawerSection
              id="actions"
              title="Actions"
              icon={<ActionsIcon />}
              isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasActions}
              right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{actionsLabel}</Text> : null}
              dangerouslyCustomizeChildrenPadding={{
                padding: 0, // Since action table has borders that are full-width, it handles its own padding
              }}
            >
              <DecisionActions id={decisionId} />
            </DrawerSection>
          </>
        )}
      </DecidedCommentProvider>
    </DrawerLayout>
  )
}

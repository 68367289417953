import { Suspender, Tag, Tooltip } from '@mm/company-ui'
import { LockedIcon, UnlockedIcon } from '@mm/company-ui-icons'
import React from 'react'
import { TopicPrivacyTagDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'

export type TopicPrivacyTagProps = {
  topicId: string
  className?: string
  cached?: boolean
  showPrivacyLabel?: boolean
}

export const TopicPrivacyTag = ({ topicId, className, cached, showPrivacyLabel = true }: TopicPrivacyTagProps) => {
  const { data, loading, error } = useQuery(TopicPrivacyTagDocument, {
    variables: { topicId },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const topic = data?.topic
  if (topic == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('topic not found: ' + topicId)
  }

  return (
    <Tooltip
      position="bottom-start"
      description={
        topic.isPrivate
          ? 'This topic is private because it’s part of private meeting. If you want to change it, update the privacy setting of the meeting doc.'
          : 'This topic is public because it’s part of at least one public meeting doc. If you want to change it, update the privacy setting of the meeting doc.'
      }
      sx={{ maxWidth: '300px', fontSize: 1, lineHeight: 1.5 }}
    >
      <Tag className={className}>
        {topic.isPrivate ? <LockedIcon /> : <UnlockedIcon />}
        {showPrivacyLabel && (topic.isPrivate ? 'Private' : 'Public')}
      </Tag>
    </Tooltip>
  )
}

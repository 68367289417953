import React from 'react'
import { ActionMetadataWaitingForDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { editActionWaitingForCommand } from '../commands/editActionWaitingForCommand'

export type ActionMetadataWaitingForProps = {
  actionId: string
  cached?: boolean
}

export const ActionMetadataWaitingFor = ({ actionId, cached }: ActionMetadataWaitingForProps) => {
  const { data } = useQuery(ActionMetadataWaitingForDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id: actionId,
    },
  })
  const editActionWaitingFor = useCommand(editActionWaitingForCommand)

  const handlePickerSelectUser = (value: string) => {
    void editActionWaitingFor.execute({ actionId, waitingForId: value })
  }

  return (
    <UserSelectWithCompanyMembers
      aria-label="Waiting For"
      value={data?.action?.waitingFor.id}
      onAdd={handlePickerSelectUser}
      withName={true}
    />
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ChevronRightIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="chevron-right" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.91058 15.5892C6.58514 15.2638 6.58514 14.7362 6.91058 14.4107L11.3213 9.99998L6.91058 5.58923C6.58514 5.2638 6.58514 4.73616 6.91058 4.41072C7.23602 4.08529 7.76366 4.08529 8.08909 4.41072L13.0891 9.41072C13.4145 9.73616 13.4145 10.2638 13.0891 10.5892L8.08909 15.5892C7.76366 15.9147 7.23602 15.9147 6.91058 15.5892Z"
    />
  </IconBase>
)

import { Suspender, Tag, TagSelect } from '@mm/company-ui'
import { CheckIcon } from '@mm/company-ui-icons/src'
import React, { useCallback } from 'react'
import { GetTopicStatusCommandDocument, TopicStatus } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { editTopicStatusCommand } from '../commands/editTopicStatusCommand'

export type TopicStatusTagSelectProps = {
  topicId: string
}

export const topicStatusActiveTag = (
  <Tag value="UNDISCUSSED" variant="default">
    Active
  </Tag>
)

export const topicStatusResolvedTag = (
  <Tag value="DISCUSSED" variant="success">
    <CheckIcon />
    Resolved
  </Tag>
)

export const TopicStatusTagSelect = ({ topicId }: TopicStatusTagSelectProps) => {
  const { data, error, loading } = useQuery(GetTopicStatusCommandDocument, {
    variables: {
      id: topicId,
    },
  })

  const editTopicStatus = useCommand(editTopicStatusCommand)

  const handleChange = useCallback(
    (status: TopicStatus) => {
      return editTopicStatus.execute({ topicId, status })
    },
    [editTopicStatus, topicId],
  )

  if (!data?.topic) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Topic not found')
  }

  return (
    <TagSelect value={data.topic.status} onChange={handleChange}>
      {topicStatusActiveTag}
      {topicStatusResolvedTag}
    </TagSelect>
  )
}

import Router, { useRouter } from 'next/router'
import { route, Route } from 'nextjs-routes'
import React, { useCallback, useMemo } from 'react'

export const DRAWER_QUERY_KEY = 'd'

// TODO[T-3059] replace with satisfies
const defineDrawers = <T extends Record<string, (id: string) => Route | null>>(drawers: T) => drawers

const DRAWERS = defineDrawers({
  action: (id) => ({ pathname: '/actions/[id]', query: { id } }),
  topic: (id) => ({ pathname: '/topics/[id]', query: { id } }),
  profile: (id) => ({ pathname: '/profile/[id]', query: { id } }),
  goal: (id) => ({ pathname: '/goals/[id]', query: { id } }),
  goodThing: (id) => ({ pathname: '/goodThings/[id]', query: { id } }),
  decision: (id) => ({ pathname: '/decisions/[id]', query: { id } }),
  archive: () => null,
  feedback: (id) => ({ pathname: '/feedback/[id]', query: { id } }),
  prep: (id) => ({ pathname: '/meeting-prep/[id]', query: { id } }),
  update: (id) => ({ pathname: '/updates/[id]', query: { id } }),

  // Accountability Prototype
  'a-action': (id) => ({ pathname: '/accountability/actions/[id]', query: { id } }),
  'a-goal': (id) => ({ pathname: '/accountability/goals/[id]', query: { id } }),
  'a-profile': (id) => ({ pathname: '/accountability/team/[id]', query: { id } }),
})

export type DrawerType = keyof typeof DRAWERS

export const useContextualDrawers = () => {
  const router = useRouter()

  const [activeKeyValue] = [router.query[DRAWER_QUERY_KEY]].flat()
  const [activeKey, activeValue] = activeKeyValue ? DrawerValue.decode(activeKeyValue) : []
  //TODO temp solution to support generic topic navigation, @santhosh-ps to remove once we remove issues sections
  const activeSectionId = router.query['sectionId'] as string | undefined

  const active = useMemo(
    () =>
      activeKey && activeValue && activeKey in DRAWERS
        ? {
            type: activeKey as DrawerType, // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
            id: activeValue,
            sectionId: activeSectionId,
          }
        : null,
    [activeKey, activeValue, activeSectionId],
  )

  return {
    active,
    showDrawer: useCallback(
      (key: DrawerType, id: string, event?: React.MouseEvent, sectionId?: string) => {
        const url = DRAWERS[key]?.(id)
        // TODO: Migrate table to use anchor to support this natively
        if (event?.metaKey && url) {
          window.open(route(url), '_blank')
          return
        }

        const query = {
          ...router.query,
          [DRAWER_QUERY_KEY]: DrawerValue.encode(key, id),
          ...(sectionId ? { sectionId } : { sectionId: undefined }),
        }
        query.sectionId === undefined && delete query.sectionId
        void Router.push({ pathname: router.pathname, query }, undefined, { scroll: false, shallow: true })
      },
      [router],
    ),
    hideDrawer: useCallback(() => {
      const { [DRAWER_QUERY_KEY]: _, sectionId: __, ...query } = router.query

      void Router.push({ pathname: router.pathname, query }, undefined, { scroll: false, shallow: true })
    }, [router]),
  }
}

export class DrawerValue {
  static delimiter = '-'
  static encode(key: string, value: string) {
    return key + this.delimiter + value
  }
  static decode(string: string) {
    // only split on the last occurrence of the delimiter
    const lastIndex = string.lastIndexOf(this.delimiter)
    const key = string.substring(0, lastIndex)
    const value = string.substring(lastIndex + 1)
    return [key, value]
  }
}

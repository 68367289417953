import { Extension, Extensions, NodeViewRenderer } from '@tiptap/core'
import { Image as ImageNode } from '@tiptap/extension-image'
import { Underline } from '@tiptap/extension-underline'
import { StarterKit } from '@tiptap/starter-kit'
import { BulletListExtension } from './extensions/BulletListExtension'
import { CollaborationPrivateAttrs } from './extensions/CollaborationPrivateAttrs'
import { CustomParagraph } from './extensions/CustomParagraph'
import { LinkExtension } from './extensions/LinkExtension'
import { LoomExtension } from './extensions/LoomExtension'
import { MentionsExtension, MentionsExtensionOptions } from './extensions/MentionsExtension'
import { OrderedListExtension } from './extensions/OrderedListExtension'
import { TabExtension } from './extensions/TabExtension'

export type CompanyOSKitOptions = {
  history: boolean
  mentions: Partial<MentionsExtensionOptions> & {
    nodeView?: NodeViewRenderer
  }
}

export default Extension.create<CompanyOSKitOptions>({
  name: 'mochary-method',
  addOptions() {
    return {
      history: true,
      placeholder: '',
      mentions: { readonly: true }, // TODO remove readonly=true once mentions feature flag is released
    }
  },
  addExtensions() {
    const extensions: Extensions = [
      TabExtension,
      StarterKit.configure({
        paragraph: false,
        history: this.options.history ? {} : false,
        heading: {
          levels: [1, 2],
        },
        bulletList: false,
        orderedList: false,
      }),
      CustomParagraph,
      Underline,
      LinkExtension.configure({
        validate: (href: string) => /^https?:\/\//.test(href),
      }),
      BulletListExtension,
      OrderedListExtension,
      LoomExtension,
      CollaborationPrivateAttrs,
      ImageNode,
    ]
    {
      const { nodeView, ...options } = this.options.mentions
      extensions.push(
        MentionsExtension.extend({
          ...(nodeView && {
            addNodeView() {
              return nodeView
            },
          }),
        }).configure(options),
      )
    }
    return extensions
  },
})

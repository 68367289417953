import React from 'react'
import { TopicCommentsListDocument } from '../../../gen/graphql/documents'
import { CommentsList, CommentsListProps } from './CommentsList'

export type TopicCommentsListProps = Omit<
  CommentsListProps<unknown, unknown>,
  'query' | 'variables' | 'extractPage'
> & {
  topicId: string
}

export const TopicCommentsList = ({ topicId, ...rest }: TopicCommentsListProps) => (
  <CommentsList
    query={TopicCommentsListDocument}
    variables={{ topicId }}
    extractPage={(data) => data.topic?.comments}
    {...rest}
  />
)

import { Tooltip, Tag, Suspender, Flyout, useFeatureFlags } from '@mm/company-ui'
import { LockedIcon, UnlockedIcon } from '@mm/company-ui-icons'
import React from 'react'
import { GoalPrivacyTagDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { GoalPrivacyMenu } from './GoalPrivacyMenu'

export type GoalPrivacyTagProps = {
  goalId: string
  className?: string
  cached?: boolean
  showPrivacyLabel?: boolean
}

export const GoalPrivacyTag = ({ goalId, className, cached, showPrivacyLabel = true }: GoalPrivacyTagProps) => {
  const { data, loading, error } = useQuery(GoalPrivacyTagDocument, {
    variables: { goalId },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const goal = data?.goal
  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('goal not found: ' + goalId)
  }
  const isParentMeetingPublic = goal.parentMeeting?.privacyLevel === 'PUBLIC'
  return (
    <GoalPrivacyTagUi
      className={className}
      isPrivate={goal.isPrivate || false}
      isParentMeetingPublic={isParentMeetingPublic}
      hasLabel={showPrivacyLabel}
      content={<GoalPrivacyMenu goalId={goal.id} />}
    />
  )
}

export const GoalPrivacyTagUi = ({
  isPrivate,
  isParentMeetingPublic,
  hasLabel = true,
  content,
  className,
}: {
  isPrivate: boolean
  isParentMeetingPublic: boolean
  hasLabel?: boolean
  content: React.ReactNode
  className?: string
}) => {
  const { publicGoalsOnly } = useFeatureFlags()

  const getGoalVisibilityMessage = () => {
    if (isPrivate && publicGoalsOnly) {
      return 'Privacy cannot be changed'
    } else if (publicGoalsOnly) {
      return 'Goals are public by default (Visible to everyone at the company) and cannot be changed'
    } else if (isParentMeetingPublic) {
      return "This goal is public because it's part of a public Meeting. To change its visibility, update the privacy setting of the Meeting"
    } else if (isPrivate) {
      return 'Private - Visible to the assignee of this goal and participants of any meeting this goal is linked to'
    } else {
      return 'Public - Visible to everyone at the company'
    }
  }
  return (
    <Flyout
      disabled={publicGoalsOnly ? true : isParentMeetingPublic}
      backdrop={false}
      position="right-start"
      trigger="click"
      sx={{ maxWidth: '300px', fontSize: 1, lineHeight: 1.5, display: 'flex', alignItems: 'center' }}
      content={content}
    >
      <Tooltip
        position="bottom-start"
        description={getGoalVisibilityMessage()}
        sx={{ maxWidth: '300px', fontSize: 1, lineHeight: 1.5 }}
      >
        <Tag type="button" name="privacyLevel" className={className}>
          {isPrivate ? <LockedIcon /> : <UnlockedIcon />}
          {hasLabel && (isPrivate ? 'Private' : 'Public')}
        </Tag>
      </Tooltip>
    </Flyout>
  )
}

import React from 'react'
import { Flex } from '../Flex'
import { TabBar } from '../TabBar'

export type PanelHeaderProps = {
  children?: React.ReactNode
  className?: string
  offset?: number
  height?: number
  border?: boolean
}

export const PanelHeader = ({ children, className, offset = 4, height = 48, border = true }: PanelHeaderProps) => {
  return (
    <Flex
      row
      justify="space-between"
      align="center"
      className={className}
      sx={{
        minHeight: height,
        borderBottom: border ? '1px solid' : '',
        borderBottomColor: border ? 'border' : '',
        paddingY: 1.5,
        paddingX: offset,
        [`& > .${TabBar.name}`]: {
          height,
          marginY: -1.5,
        },
      }}
    >
      {children}
    </Flex>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CheckSquareFilledIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="check-square-filled" viewBox="0 0 20 20" {...props}>
    <rect width={20} height={20} rx={4} />
    <path
      d="M6 10.1429L8.43478 13L14 8"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </IconBase>
)

import styled from '@emotion/styled'
import {
  Flex,
  Text,
  Avatar,
  formatUserDisplayName,
  Facepile,
  formatDateDisplay,
  StatusCheckbox,
  StatusCheckboxValue,
  UserDisplay,
} from '@mm/company-ui'
import { FilePageTemplateIcon, GoalsIcon, DecisionIcon } from '@mm/company-ui-icons'
import { uniqBy } from 'lodash'
import React from 'react'
import { css } from 'theme-ui'
import {
  ActionSearchNodeFragment,
  DecisionSearchNodeFragment,
  GoalSearchNodeFragment,
  MeetingSearchNodeFragment,
  SearchResultRowFragment,
  TopicSearchNodeFragment,
} from '../../../gen/graphql/documents'
import { getCurrentStatus } from '../../actions/helpers'
import { DecisionStatusPicker } from '../../decisions/components/DecisionStatusPicker'
import { GoalStatusPicker } from '../../goals/components/GoalStatusPicker'

type SearchRowProps = {
  titleHighlightSubRow: React.ReactNode
}

export type MeetingRowProps = MeetingSearchNodeFragment & SearchRowProps
export type GoalRowProps = GoalSearchNodeFragment & SearchRowProps
export type DecisionRowProps = DecisionSearchNodeFragment & SearchRowProps
export type ActionRowProps = ActionSearchNodeFragment & SearchRowProps
export type TopicRowProps = TopicSearchNodeFragment & SearchRowProps

interface TitleHighlightProps {
  title: string
  highlights: SearchResultRowFragment['highlights']
}

export const SubText = ({ content }: { content: string }) => {
  return (
    <Text color="text-light" variant="small">
      {content}
    </Text>
  )
}

export const TitleHighlightSubRow = ({ title, highlights }: TitleHighlightProps) => {
  let display: React.ReactNode = title
  const titleHighlight = highlights?.find(({ path }) => path === 'title')
  if (titleHighlight != null) {
    const { spans } = titleHighlight
    display = spans.map(({ isHit, text }, idx) => (
      <Text sx={{ backgroundColor: isHit ? 'system-background-alert-light' : undefined }} key={idx}>
        {text}
      </Text>
    ))
  }

  return <div>{display}</div>
}

export const SearchResultRowWrapper = styled(Flex)(
  css({
    width: '100%',
    paddingX: 2,
    paddingY: 1,
    gap: 2,
  }),
)

type FacePileAvatarProps = {
  userEdges: {
    node: UserDisplay
  }[]
}
const SearchResultAvatar = ({ user }: { user: FacePileAvatarProps['userEdges'][number]['node'] }) => {
  return <Avatar size="small" key={user.id} name={formatUserDisplayName(user)} />
}
const FacePileAvatars = ({ userEdges }: FacePileAvatarProps) => {
  const avatars = userEdges.map(({ node: user }) => <SearchResultAvatar key={user.id} user={user} />)
  return (
    <Facepile data-testid="MeetingParticipants-facepile" maxLength={4}>
      {avatars}
    </Facepile>
  )
}

export const MeetingRow = ({ participants, titleHighlightSubRow }: MeetingRowProps) => {
  return (
    <SearchResultRowWrapper sx={{ width: '100%', alignItems: 'center' }}>
      <FilePageTemplateIcon color="text-light" />
      <Flex justify="space-between" align="center" sx={{ width: '100%' }}>
        {titleHighlightSubRow}
        {!!participants?.edges.length && <FacePileAvatars userEdges={participants.edges} />}
      </Flex>
    </SearchResultRowWrapper>
  )
}

export const GoalRow = ({ id, assignee, titleHighlightSubRow, createdAt, dueAt }: GoalRowProps) => {
  return (
    <SearchResultRowWrapper>
      <GoalsIcon color="text-light" />
      <Flex justify="space-between" column gap={1}>
        {titleHighlightSubRow}
        <Flex gap={2} align="center">
          <div>
            <GoalStatusPicker goalId={id}></GoalStatusPicker>
          </div>
          {assignee && <SearchResultAvatar key={assignee.id} user={assignee} />}
          <SubText content={` Due on ${formatDateDisplay(dueAt)}. Created on ${formatDateDisplay(createdAt)}.`} />
        </Flex>
      </Flex>
    </SearchResultRowWrapper>
  )
}

export const DecisionRow = ({ id, titleHighlightSubRow, participatingIn, parent, createdAt }: DecisionRowProps) => {
  const inMeetingName = parent?.__typename === 'Meeting' && parent.title ? `in ${parent?.title}` : ``
  const subText = `Created ${inMeetingName} on ${formatDateDisplay(createdAt)}`

  return (
    <SearchResultRowWrapper>
      <DecisionIcon color="text-light" />
      <Flex justify="space-between" column gap={1}>
        {titleHighlightSubRow}
        <Flex gap={2} align="center">
          <DecisionStatusPicker decisionId={id}></DecisionStatusPicker>
          {!!participatingIn?.edges.length && (
            <FacePileAvatars userEdges={uniqBy(participatingIn?.edges, 'node.email')} />
          )}

          <SubText content={subText} />
        </Flex>
      </Flex>
    </SearchResultRowWrapper>
  )
}

export const ReadOnlyStatusCheckbox = ({ type, value }: { type: 'action' | 'topic'; value: StatusCheckboxValue }) => {
  return (
    <StatusCheckbox
      disableHover
      options={['active']}
      sx={{ alignItems: 'flex-start', paddingTop: 0.25 }}
      type={type}
      withDropdown={false}
      value={value}
      shape={type == 'action' ? 'circle' : 'square'}
    />
  )
}

const getMeetingSubText = (parent: ActionSearchNodeFragment['parent']): string =>
  (parent?.__typename === 'Topic' || parent?.__typename === 'Decision' || parent?.__typename === 'PrototypeFeedback') &&
  parent.parent?.__typename === 'Meeting'
    ? `from ${parent.parent.title}`
    : ''
export const ActionRow = ({
  actionAssignee,
  parent,
  titleHighlightSubRow,
  createdAt,
  actionStatus,
}: ActionRowProps) => {
  const label = parent?.__typename === 'PrototypeFeedback' ? 'Feedback: ' : `${parent?.__typename}: `
  const inTopicName = parent?.title ? `in ${label}${parent.title}` : ``
  const fromMeetingName = getMeetingSubText(parent)
  const subText = `Created ${inTopicName} ${fromMeetingName} on ${formatDateDisplay(createdAt)}`
  return (
    <SearchResultRowWrapper>
      <ReadOnlyStatusCheckbox type="action" value={getCurrentStatus({ status: actionStatus })} />
      <Flex justify="space-between" column gap={1}>
        {titleHighlightSubRow}
        <Flex gap={2} align="center">
          {actionAssignee && <SearchResultAvatar key={actionAssignee.id} user={actionAssignee} />}
          <SubText content={subText} />
        </Flex>
      </Flex>
    </SearchResultRowWrapper>
  )
}

export const TopicRow = ({ owner, parent, topicStatus, titleHighlightSubRow, createdAt }: TopicRowProps) => {
  const inMeetingName = parent?.__typename === 'Meeting' && parent.title ? `in ${parent?.title}` : ``
  const subText = `Created ${inMeetingName} on ${formatDateDisplay(createdAt)}`
  return (
    <SearchResultRowWrapper>
      <ReadOnlyStatusCheckbox type="topic" value={topicStatus == 'DISCUSSED' ? 'resolved' : 'active'} />
      <Flex justify="space-between" column gap={1}>
        {titleHighlightSubRow}
        <Flex gap={2} align="center">
          {owner && <SearchResultAvatar user={owner} />}
          <SubText content={subText} />
        </Flex>
      </Flex>
    </SearchResultRowWrapper>
  )
}

export const NoResultsRow = () => {
  return (
    <SearchResultRowWrapper sx={{ paddingY: 2 }}>
      <Text color="text-light">No results found. ¯\_(ツ)_/¯ </Text>
    </SearchResultRowWrapper>
  )
}

export type SearchResultRowProps = {
  row: SearchResultRowFragment
}

export const SearchResultRow = ({ row }: SearchResultRowProps) => {
  const { node, highlights } = row
  const titleHighlightSubRow = <TitleHighlightSubRow title={node.title} highlights={highlights} />

  switch (node.__typename) {
    case 'Meeting': {
      return <MeetingRow {...node} titleHighlightSubRow={titleHighlightSubRow} />
    }
    case 'Goal': {
      return <GoalRow {...node} titleHighlightSubRow={titleHighlightSubRow} />
    }
    case 'Decision': {
      return <DecisionRow {...node} titleHighlightSubRow={titleHighlightSubRow} />
    }
    case 'Action': {
      return <ActionRow {...node} titleHighlightSubRow={titleHighlightSubRow} />
    }
    case 'Topic': {
      return <TopicRow {...node} titleHighlightSubRow={titleHighlightSubRow} />
    }
    default:
      return titleHighlightSubRow
  }
}

import { GetDecisionDueDocument, UpdateDecisionCommentsDueAtDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditDecisionCommentsDueAtCommandValue = {
  id: string
  timestamp: Date
}

export const editDecisionCommentsDueAtCommand: Command<EditDecisionCommentsDueAtCommandValue> = {
  id: 'editDecisionCommentsDueAtCommand',
  name: 'Edit Decision Comments Due At',
  canExecute: async ({ id, timestamp }, { apolloClient }) => {
    const currentDecision = await apolloClient.query({ query: GetDecisionDueDocument, variables: { id: id } })

    return currentDecision.data.decision?.commentsDueAt !== timestamp.getTime()
  },
  execute: async ({ timestamp, id }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const currentDecision = await apolloClient.query({ query: GetDecisionDueDocument, variables: { id: id } })

    await apolloClient.mutate({
      mutation: UpdateDecisionCommentsDueAtDocument,
      variables: {
        id: id,
        data: {
          commentsDueAt: timestamp.getTime(),
        },
      },
      optimisticResponse: {
        updateDecision: {
          __typename: 'Decision',
          id: id,
          commentsDueAt: timestamp.getTime(),
        },
      },
    })

    if (currentDecision.data.decision?.commentsDueAt) {
      return {
        args: {
          id,
          timestamp: new Date(currentDecision.data.decision.commentsDueAt),
        },
        execute: async ({ timestamp, id }, { apolloClient }) => {
          await apolloClient.mutate({
            mutation: UpdateDecisionCommentsDueAtDocument,
            variables: {
              id: id,
              data: {
                commentsDueAt: timestamp.getTime(),
              },
            },
            optimisticResponse: {
              updateDecision: {
                __typename: 'Decision',
                id: id,
                commentsDueAt: timestamp.getTime(),
              },
            },
          })
        },
      }
    }

    return
  },
}

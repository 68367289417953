import { GenericTopic } from '@mm/graphql-helpers/gen/graphql/graphql'
import { AddGenericTopicToCacheFragmentDoc } from '../../../gen/graphql/documents'
import { ApolloCache } from '../../apollo'

export type GenericTopicFragment = {
  id: string
  __typename: NonNullable<GenericTopic['__typename']>
}

export function addGenericTopicToCache(cache: ApolloCache<unknown>, topic: GenericTopicFragment, sectionId: string) {
  const id = cache.identify({ __typename: 'MeetingTopicSection', id: sectionId })

  const fragment = cache.readFragment({
    fragment: AddGenericTopicToCacheFragmentDoc,
    id,
    fragmentName: 'addGenericTopicToCache',
  })
  if (fragment != null && fragment.genericTopics.every(({ id }) => id !== topic.id)) {
    cache.writeFragment({
      fragment: AddGenericTopicToCacheFragmentDoc,
      id,
      data: {
        genericTopics: [...fragment.genericTopics, topic],
      },
    })
    return
  }
}

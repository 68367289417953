import { Avatar, Card, Editable, Flex, Text, formatDateDisplay, formatUserDisplayName } from '@mm/company-ui'
import { LinkIcon, TemplatesMultiplePagesDocumentIcon } from '@mm/company-ui-icons/src'
import _ from 'lodash'
import React from 'react'
import { UpdateItemActionUpdateDataFragment } from '../../../gen/graphql/documents'
import { ActionLinkWithMenu } from './ActionLinkWithMenu'

export const stripHtml = (html: string): string => {
  return html.replace(/<[^>]*>?/gm, '')
}

export type UpdateListItemActionUpdateProps = {
  actionUpdate: UpdateItemActionUpdateDataFragment
}

export function Item({ children, first = false }: { children: React.ReactNode; first?: boolean }) {
  return (
    <div
      sx={{
        paddingY: 2,
        paddingX: 4,

        ...(!first
          ? {
              borderTop: '1px solid',
              borderTopColor: 'border',
            }
          : {}),
      }}
    >
      {children}
    </div>
  )
}

export function Metadata({
  children,
  title,
  titleOffset,
}: {
  children: React.ReactNode
  title: string
  titleOffset?: string
}) {
  return (
    <Flex row gap={2}>
      <div sx={{ width: 150, flexShrink: 0 }}>
        <Text color="text-light" sx={{ display: 'block', marginTop: titleOffset }}>
          {title}
        </Text>
      </div>
      <div sx={{ flex: 1, minWidth: 0 }}>{children}</div>
    </Flex>
  )
}

export function NextActionRow({
  action,
  index,
}: {
  action: UpdateItemActionUpdateDataFragment['action']
  index: number
}) {
  return (
    <ActionLinkWithMenu actionId={action.id} key={action.id}>
      <Flex
        row
        gap={2}
        sx={{
          padding: 16,
          ...(index !== 0
            ? {
                borderTop: '1px solid',
                borderTopColor: 'border',
              }
            : {}),
        }}
      >
        <TemplatesMultiplePagesDocumentIcon sx={{ marginTop: 0.5, marginRight: -1 }} />
        <div sx={{ flex: 1, minWidth: 0 }}>
          <Text
            bold
            sx={{
              lineHeight: '1.5em',
              maxHeight: '3em',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {action.title}
          </Text>
        </div>
        {action.dueAt ? (
          <Text color="text-light" variant="small" sx={{ marginTop: 0.25 }}>
            By {formatDateDisplay(action.dueAt)}
          </Text>
        ) : null}
        <Avatar size="small" name={formatUserDisplayName(action.assignee)} />
      </Flex>
    </ActionLinkWithMenu>
  )
}

export function UpdateListItemActionUpdate({ actionUpdate }: UpdateListItemActionUpdateProps) {
  const parentTypeLabel =
    actionUpdate.action.parent?.__typename === 'PrototypeFeedback'
      ? 'Feedback'
      : `${actionUpdate.action.parent?.__typename}`
  const parentTitleLabel = actionUpdate.action.parent?.title
    ? `${parentTypeLabel}: ${actionUpdate.action.parent.title}`
    : ''

  return (
    <Card sx={{ padding: 0, borderRadius: 'medium' }} variant="flat">
      <ActionLinkWithMenu actionId={actionUpdate.action.id}>
        <div>
          <Item first>
            <Flex column gap={0.5}>
              <Flex row gap={1} justify="space-between">
                {actionUpdate.status === 'DONE' ? (
                  <Text bold variant="small" uppercase color="system-text-success">
                    Done
                  </Text>
                ) : actionUpdate.status === 'NOT_DONE' ? (
                  <Text bold variant="small" uppercase color="system-text-warning">
                    Not Done
                  </Text>
                ) : (
                  <Text bold variant="small" uppercase color="text-light">
                    {_.startCase(_.toLower(actionUpdate.status))}
                  </Text>
                )}

                {actionUpdate.action.dueAt ? (
                  <Text color="text-light" variant="small">
                    By {formatDateDisplay(actionUpdate.action.dueAt)}
                  </Text>
                ) : null}
              </Flex>
              <Text
                bold
                sx={{
                  fontSize: '16px',
                  lineHeight: '1.5em',
                  maxHeight: '3em',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {actionUpdate.action.title}
              </Text>
              {parentTitleLabel ? (
                <Flex row gap={0.5} sx={{ flexShrink: 0 }}>
                  <LinkIcon width={1.5} height={1.5} color="text-light" sx={{ marginTop: 0.5 }} />

                  <Text color="text-light" overflow="ellipsis">
                    {parentTitleLabel}
                  </Text>
                </Flex>
              ) : null}
            </Flex>
          </Item>
        </div>
      </ActionLinkWithMenu>

      {actionUpdate.status === 'DONE' ? (
        <>
          {actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml) ? (
            <Item>
              <Metadata title="Key learnings">
                <Editable mode="compact" initialValue={actionUpdate.reasonHtml} editable={false} />
              </Metadata>
            </Item>
          ) : null}

          {actionUpdate.nextActions?.length ? (
            <Item>
              <Metadata title="Next actions" titleOffset="2">
                <Flex column sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium' }}>
                  {actionUpdate.nextActions.map(
                    (nextAction, index) =>
                      nextAction && <NextActionRow key={nextAction.id} action={nextAction} index={index} />,
                  )}
                </Flex>
              </Metadata>
            </Item>
          ) : null}
        </>
      ) : null}

      {actionUpdate.status === 'NOT_DONE' ? (
        <>
          {actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml) ? (
            <Item>
              <Metadata title="What blocked you?">
                <Editable mode="compact" initialValue={actionUpdate.reasonHtml} editable={false} />
              </Metadata>
            </Item>
          ) : null}

          {actionUpdate.repeat != null ? (
            <Item>
              <Metadata title="Still want to do?">{actionUpdate.repeat ? 'Yes' : 'No'}</Metadata>
            </Item>
          ) : null}

          {actionUpdate.nextHtml && stripHtml(actionUpdate.nextHtml) ? (
            <Item>
              <Metadata title="How to avoid getting blocked again?">
                <Editable mode="compact" initialValue={actionUpdate.nextHtml} editable={false} />
              </Metadata>
            </Item>
          ) : null}

          {actionUpdate.nextActions?.length ? (
            <Item>
              <Metadata title="Next actions" titleOffset="2">
                <Flex column sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium' }}>
                  {actionUpdate.nextActions.map(
                    (nextAction, index) =>
                      nextAction && <NextActionRow key={nextAction.id} action={nextAction} index={index} />,
                  )}
                </Flex>
              </Metadata>
            </Item>
          ) : null}
        </>
      ) : null}
    </Card>
  )
}

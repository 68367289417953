import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Avatar,
  Button,
  Datepicker,
  Editable,
  EditableRef,
  Flex,
  formatDateDisplay,
  formatUserDisplayName,
  Modal,
  MODAL_SECTION_PADDING,
  ModalSection,
  MotionFlex,
  RadioButton,
  RadioGroup,
  Suspender,
  Text,
  useFeatureFlags,
} from '@mm/company-ui'
import { useAnimation } from 'framer-motion'
import React, { useRef, useState } from 'react'
import { css } from 'theme-ui'
import { NotDoneModalDocument } from '../../../gen/graphql/documents'
import { FollowUpActions } from '../../actions/components/FollowUpActions'
import { useQuery } from '../../apollo'
import { isOnlyWhiteSpaces } from '../../comments/components/CommentComposer'

const Underline = styled.div<{ content: string }>(({ content }) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 0.5,
    paddingTop: '2px',

    '&::after': {
      content: `"${content}"`,
      display: 'block',
      height: '2px',
      backgroundColor: 'accent-border',
      overflow: 'hidden',
      transition: 'opacity 100ms linear',
    },

    '&:hover::after': {
      opacity: 0,
    },

    input: {
      padding: 0.5,
      textAlign: 'center',
    },
  }),
)

export type DoneNotDoneSubmitData = {
  htmlBody: string
  cancel: boolean
  dueAt?: number
}

export type DoneNotDoneModalProps = {
  actionId: string
  variant: 'hold' | 'cancel' | 'reschedule' | 'not-done' | 'done'
  dueAt?: number
  onRequestClose?: () => void
  onSubmit?: (data: DoneNotDoneSubmitData) => Promise<void>
  onCancel?: () => void
}
const variantLabels: { [key in DoneNotDoneModalProps['variant']]: string } = {
  hold: 'Set as blocked',
  cancel: 'Cancel',
  reschedule: 'Reschedule',
  'not-done': 'Reschedule',
  done: 'Done',
}

export const DoneNotDoneModal = ({
  actionId,
  dueAt,
  variant,
  onRequestClose,
  onSubmit,
  onCancel,
}: DoneNotDoneModalProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [onlyWhiteSpaces, setOnlyWhiteSpaces] = useState(true)
  const [keepAction, setKeepAction] = useState<'yes' | 'no'>(variant === 'cancel' ? 'no' : 'yes')
  const [dueAtValue, setDueAt] = useState(dueAt)
  const [isAddActionOpen, setIsAddActionOpen] = useState<true | undefined>(true)
  const [followUpDirty, setFollowUpDirty] = useState(false)
  const [notDoneReasonDirty, setNotDoneReasonDirty] = useState(false)
  const { preventNotDoneSubmitWhenFollowUpDirty, tweakWordingInCancelActionNotDoneFlow } = useFeatureFlags()
  const animateShake = useAnimation()
  const theme = useTheme()
  const { data, loading, error } = useQuery(NotDoneModalDocument, {
    variables: {
      id: actionId,
    },
  })
  const editableRef = useRef<EditableRef>(null)

  const me = data?.me
  const action = data?.action
  const waitingForIsMe = action?.waitingFor?.id === me?.id
  const isCancel = variant === 'cancel' || keepAction === 'no'
  const isNotDoneReasonValid = variant !== 'done' && onlyWhiteSpaces

  if (loading && (me == null || action == null)) {
    return <Suspender />
  }

  if (error) {
    throw error
  }

  if (!me) {
    throw new Error('Unauthenticated')
  }

  if (!action) {
    throw new Error('Action not found')
  }

  const handleSubmit = async () => {
    if (isNotDoneReasonValid) {
      setNotDoneReasonDirty(true)
      return
    }

    if (preventNotDoneSubmitWhenFollowUpDirty && followUpDirty) {
      void animateShake.start('shake')
      return
    }

    try {
      setSubmitting(true)
      editableRef.current?.commands.blur()
      await onSubmit?.({
        htmlBody: onlyWhiteSpaces ? '' : editableRef.current?.getHTML() ?? '',
        cancel: isCancel,
        dueAt: dueAtValue,
      })
      onRequestClose?.()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('failed to submit a comment', e)
      setSubmitting(false)
    }
  }

  const confirmLabel = keepAction === 'no' ? 'Cancel' : variantLabels[variant]

  return (
    <Modal
      isOpen
      title={variant === 'done' ? 'Action done' : 'Action not done'}
      onRequestClose={() => {
        onCancel?.()
        onRequestClose?.()
      }}
      sx={{ overflow: 'inherit' }}
    >
      {/** Scroll container when it becomes too large (when adding blocked actions). Ensure that submit button is always visible. */}
      <div sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
        <ModalSection>
          <Text>
            {action.waitingFor && !waitingForIsMe ? (
              <>
                You made an agreement with <Text bold>{formatUserDisplayName(action.waitingFor)}</Text> to{' '}
              </>
            ) : (
              'You made an agreement to '
            )}
            <Text bold>{action.title}</Text>
          </Text>
        </ModalSection>

        <ModalSection column gap={1.5}>
          <Text bold>
            {variant === 'done'
              ? 'Any key learnings?'
              : variant === 'cancel' && tweakWordingInCancelActionNotDoneFlow
              ? 'What’s the reason for canceling?'
              : 'What prevented you from getting this done?'}
          </Text>
          <Flex>
            {data.me && <Avatar name={formatUserDisplayName(data.me)} />}
            <Editable
              ref={editableRef}
              mode="compact"
              placeholder="Add a comment..."
              hidePlaceholderWhenFocused
              sx={{
                minHeight: 7,
                px: 1.5,
                py: 0.5,
                maxHeight: '70vh',
                overflowY: 'auto',
                flexGrow: 1,
                boxShadow: notDoneReasonDirty ? `inset 0 -2px 0 ${theme.colors['system-border-warning']}` : undefined,
              }}
              onChangeRaw={({ editor }) => {
                setNotDoneReasonDirty(false)
                setOnlyWhiteSpaces(isOnlyWhiteSpaces(editor.getText()))
              }}
              editable={!submitting}
            />
          </Flex>
        </ModalSection>

        {variant !== 'done' && (
          <ModalSection>
            <RadioGroup
              label={
                <Text bold as="div">
                  Do you still want to do this action?
                </Text>
              }
              value={keepAction}
              onChange={setKeepAction}
            >
              <RadioButton value="yes" align="center">
                {variant === 'hold' ? (
                  'Yes, set as blocked'
                ) : (
                  <Flex align="center">
                    Yes, reschedule it to{' '}
                    <Underline content={dueAtValue ? formatDateDisplay(dueAtValue) : ''}>
                      <Datepicker
                        inputContainerStyle={{
                          width: 'auto',
                        }}
                        value={dueAtValue}
                        onChange={(date) => {
                          if (date) {
                            setDueAt(date.getTime())
                          }
                        }}
                      />
                    </Underline>
                  </Flex>
                )}
              </RadioButton>
              <RadioButton value="no">
                <Text>No, cancel it</Text>
              </RadioButton>
            </RadioGroup>
          </ModalSection>
        )}

        {variant !== 'cancel' && (
          <ModalSection column sx={{ px: 0 }}>
            <div sx={{ px: MODAL_SECTION_PADDING }}>
              <Text bold>
                {variant === 'done' ? 'Next Actions?' : 'What are you going to do to not get blocked this way again?'}
              </Text>
            </div>
            <MotionFlex
              animate={animateShake}
              variants={{ shake: { translateX: [-2, 0, 2, -4, 0, -4] } }}
              transition={{ duration: 0.4 }}
            >
              <FollowUpActions
                actionId={actionId}
                addRowForcedOpen={isAddActionOpen}
                nonInteractive
                onChangeDirty={(isDirty) => {
                  if (isDirty) setIsAddActionOpen(undefined)
                  setFollowUpDirty(isDirty)
                }}
              />
            </MotionFlex>
          </ModalSection>
        )}
      </div>
      <ModalSection justify="flex-end" sx={{ paddingY: 1 }}>
        <Button
          data-testid="DoneNotDone-submit-btn"
          size="small"
          variant="accent"
          onClick={handleSubmit}
          loading={submitting}
        >
          {action?.waitingFor && !waitingForIsMe && variant !== 'done'
            ? `${confirmLabel} & Notify ${action.waitingFor.firstName}`
            : variant === 'cancel' && tweakWordingInCancelActionNotDoneFlow
            ? 'Save'
            : confirmLabel}
        </Button>
      </ModalSection>
    </Modal>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ArrowBackRewindResetIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="arrow-back-rewind-reset" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.90732 1.52967C2.83167 0.982596 2.32685 0.60043 1.77977 0.676083C1.23269 0.751735 0.850521 1.25656 0.926174 1.80364L1.59027 6.60598C1.59525 6.64751 1.60283 6.68863 1.61292 6.72908C1.67751 6.98812 1.84488 7.21986 2.09302 7.35918C2.18549 7.41131 2.28662 7.44898 2.39299 7.46959C2.50415 7.49121 2.61662 7.49333 2.72584 7.47751L7.71305 6.82549C8.26067 6.7539 8.64657 6.25192 8.57498 5.7043C8.50338 5.15667 8.0014 4.77078 7.45378 4.84237L4.69915 5.20251C6.01763 3.83682 7.90357 2.9935 10 2.9935C14.0497 2.9935 17.3334 6.24123 17.3334 10.1646C17.3334 10.7169 17.7811 11.1646 18.3334 11.1646C18.8856 11.1646 19.3334 10.7169 19.3334 10.1646C19.3334 5.09978 15.117 0.9935 10 0.9935C7.34464 0.9935 4.92394 2.07166 3.22775 3.84685L2.90732 1.52967ZM17.8601 12.9454C18.3551 13.1903 18.5578 13.7902 18.3129 14.2851C17.9113 15.0966 17.39 15.8348 16.7733 16.4809C16.3919 16.8804 15.7589 16.8951 15.3594 16.5138C14.96 16.1324 14.9452 15.4994 15.3266 15.1C15.8065 14.5972 16.2102 14.0248 16.5203 13.3981C16.7653 12.9031 17.3651 12.7004 17.8601 12.9454ZM13.2122 18.784C13.7324 18.5982 14.0034 18.026 13.8176 17.5059C13.6318 16.9858 13.0596 16.7147 12.5395 16.9005C11.8047 17.163 11.0128 17.314 10.1848 17.3336C9.63266 17.3466 9.19563 17.8048 9.20866 18.3569C9.22169 18.909 9.67984 19.3461 10.232 19.333C11.274 19.3084 12.2771 19.118 13.2122 18.784ZM3.34819 15.1689C3.73884 14.7785 4.37201 14.7787 4.7624 15.1694C5.33396 15.7413 6.00284 16.2216 6.74074 16.583C7.23673 16.8259 7.44188 17.4249 7.19896 17.9209C6.95603 18.4169 6.35702 18.6221 5.86103 18.3791C4.92393 17.9202 4.07448 17.3104 3.34771 16.5831C2.95732 16.1925 2.95753 15.5593 3.34819 15.1689ZM2.66663 10.1646C2.66663 9.61233 2.21891 9.16461 1.66663 9.16461C1.11434 9.16461 0.666626 9.61233 0.666626 10.1646C0.666626 11.1224 0.818568 12.0478 1.09902 12.9178C1.26846 13.4435 1.83194 13.7323 2.35759 13.5628C2.88324 13.3934 3.17201 12.8299 3.00257 12.3043C2.78468 11.6283 2.66663 10.9097 2.66663 10.1646Z"
    />
  </IconBase>
)

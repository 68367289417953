import { GoalStatus, openGoalStatuses } from '@mm/common/src'
import _ from 'lodash'
import { useMemo } from 'react'
import { SetGoalPriorityDocument, GlobalGoalsSubscriptionDataFragment } from '../../../gen/graphql/documents'
import { useMutation } from '../../apollo'
import { useEmitEventBusEvent } from '../../eventbus'
import { GoalsUpdatedTopic } from '../subscriptions/GlobalGoalsSubscription'

export type MoveTopicFn = (data: {
  goalId: string
  destinationPosition: number
  surroundingGoals?: Array<{ __typename: 'Goal'; priority?: number | null; id: string; status: GoalStatus }>
}) => void

export const useSetGoalPriority = (): MoveTopicFn => {
  const [setGoalPriority] = useMutation(SetGoalPriorityDocument)
  const emit = useEmitEventBusEvent(GoalsUpdatedTopic)

  return useMemo(() => {
    return async ({ goalId, destinationPosition, surroundingGoals }) => {
      const goal = surroundingGoals?.find((goal) => goal.id === goalId)
      const otherGoalsWithPriority = surroundingGoals?.filter(
        (g) => g.priority != null && openGoalStatuses.includes(g.status) && g.id != goalId,
      )

      const newGoalPriorities = _.sortBy(otherGoalsWithPriority, ['priority'])

      if (goal) {
        newGoalPriorities.splice(destinationPosition, 0, goal)
      }

      const optimisticGoals = newGoalPriorities.map((goal, newPriority) => {
        if (goal.priority !== newPriority) {
          return {
            ...goal,
            priority: newPriority,
          }
        }

        return goal
      })

      await setGoalPriority({
        variables: {
          goalId,
          priority: destinationPosition,
        },
        optimisticResponse: {
          setGoalPriority: {
            goals: optimisticGoals,
          },
        },
        update: (_cache, { data }) => {
          data?.setGoalPriority?.goals.map((goal) => {
            goal && emit({ type: 'upserted', goal: goal as GlobalGoalsSubscriptionDataFragment })
          })
        },
      })
    }
  }, [setGoalPriority, emit])
}

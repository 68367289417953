import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ItalicIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="italic" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 1.09982H12.0186C12.0056 1.09954 11.9925 1.09954 11.9796 1.09982H10C9.50295 1.09982 9.10001 1.50277 9.10001 1.99982C9.10001 2.49688 9.50295 2.89982 10 2.89982H10.8471L7.29703 17.0999H6.00001C5.50295 17.0999 5.10001 17.5028 5.10001 17.9999C5.10001 18.4969 5.50295 18.8999 6.00001 18.8999H7.9818C7.99424 18.9001 8.00665 18.9001 8.01902 18.8999H10C10.4971 18.8999 10.9 18.4969 10.9 17.9999C10.9 17.5028 10.4971 17.0999 10 17.0999H9.15243L12.7025 2.89982H14C14.4971 2.89982 14.9 2.49688 14.9 1.99982C14.9 1.50277 14.4971 1.09982 14 1.09982Z"
    />
  </IconBase>
)

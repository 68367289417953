import {
  UpdateActionAssigneeDocument,
  GetActionAssigneeDocument,
  GetUserDocument,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'

export type EditActionAssigneeCommandValue = {
  assigneeId: string
  actionId: string
}

export const editActionAssigneeCommand: Command<EditActionAssigneeCommandValue> = {
  id: 'editActionAssigneeCommand',
  name: 'Edit Assignee',
  canExecute: async ({ assigneeId, actionId }, { apolloClient }) => {
    const currentAction = await apolloClient.query({ query: GetActionAssigneeDocument, variables: { id: actionId } })
    return currentAction.data.action?.assignee.id !== assigneeId
  },
  execute: async ({ assigneeId, actionId }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const currentAction = await apolloClient.query({ query: GetActionAssigneeDocument, variables: { id: actionId } })
    const nextAssignee = await apolloClient.query({ query: GetUserDocument, variables: { id: assigneeId } })

    await apolloClient.mutate({
      mutation: UpdateActionAssigneeDocument,
      variables: {
        id: actionId,
        data: {
          assignee: assigneeId,
        },
      },
      ...(nextAssignee.data.user && {
        optimisticResponse: {
          updateAction: {
            __typename: 'Action',
            id: actionId,
            assignee: nextAssignee.data.user,
          },
        },
      }),
    })

    if (currentAction.data.action?.assignee.id) {
      return {
        args: {
          assigneeId: currentAction.data.action?.assignee.id,
          actionId,
        },
        execute: async ({ assigneeId, actionId }, { apolloClient }) => {
          const nextAssignee = await apolloClient.query({ query: GetUserDocument, variables: { id: assigneeId } })

          await apolloClient.mutate({
            mutation: UpdateActionAssigneeDocument,
            variables: {
              id: actionId,
              data: {
                assignee: assigneeId,
              },
            },
            ...(nextAssignee.data.user && {
              optimisticResponse: {
                updateAction: {
                  __typename: 'Action',
                  id: actionId,
                  assignee: nextAssignee.data.user,
                },
              },
            }),
          })

          return
        },
      }
    }

    return
  },
}

import { Flex, Text, formatDateDisplay } from '@mm/company-ui'
import React from 'react'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'

export type GoodThingRowContext = {
  goodThingId: string
  createdAt: number
}

export function GoodThingRowContext({ goodThingId, createdAt }: GoodThingRowContext) {
  const { commentsLabel } = useTotalComments({ __typename: 'GoodThing', id: goodThingId })
  const { actionsLabel } = useTotalActions({ __typename: 'GoodThing', id: goodThingId })

  return (
    <Flex row gap={2}>
      <Text variant="small" color="text-light">
        {formatDateDisplay(createdAt, 'RELATIVE')}
      </Text>
      {actionsLabel && (
        <Text variant="small" color="text-light">
          {actionsLabel}
        </Text>
      )}
      <Text variant="small" color="text-light">
        {commentsLabel}
      </Text>
    </Flex>
  )
}

import { Suspender } from '@mm/company-ui'
import { LinkIcon } from '@mm/company-ui-icons'
import React from 'react'
import { ActionMetadataFollowUpToDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { PanelMetadataItemChips } from '../../components'
import { PanelMetadataLinkChips } from '../../components/PanelMetadataLinkChips'
import { ReadOnlyStatusCheckbox } from '../../search/components/SearchResultRows'

export type ActionMetadataFollowUpToProps = {
  cached?: boolean
  actionId: string
  isLink?: boolean
}

export const ActionMetadataFollowUpTo = ({ cached, actionId, isLink }: ActionMetadataFollowUpToProps) => {
  const { data, loading, error } = useQuery(ActionMetadataFollowUpToDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      actionId,
    },
  })

  const action = data?.action

  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Action not found')
  }

  const { followUpTo } = action

  if (followUpTo == null) {
    return null
  }

  if (isLink) {
    return (
      <PanelMetadataLinkChips
        icon={<ReadOnlyStatusCheckbox type="action" value="active" />}
        chips={[
          {
            id: followUpTo.id,
            label: followUpTo.title,
            href: { pathname: '/actions/[id]', query: { id: followUpTo.id } },
            type: 'Action',
          },
        ]}
      />
    )
  }

  return (
    <PanelMetadataItemChips
      title="Follow-Up To"
      icon={<LinkIcon />}
      chips={[
        {
          id: followUpTo.id,
          label: followUpTo.title,
          href: { pathname: '/actions/[id]', query: { id: followUpTo.id } },
        },
      ]}
    />
  )
}

import { useTheme } from '@emotion/react'
import { Placement } from '@floating-ui/react'
import { CalendarIcon, UndoIcon } from '@mm/company-ui-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { DayPicker, Matcher } from 'react-day-picker'
import dayPickerStyles from 'react-day-picker/dist/style.module.css'
import { ThemeUICSSObject } from 'theme-ui'
import { formatDateDisplay } from '../../helpers/formatDateDisplay'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { Popover } from '../FloatingUI'
import { FlyoutDivider } from '../Flyout'
import { Input } from '../Input'
import { PopupWrapper } from '../Popup'
import { Tag } from '../Tag'
import { Text } from '../Text'
import { Tooltip } from '../Tooltip'

export type DatepickerProps = {
  onChange?: (val: Date | null) => void
  variant?: 'default' | 'muted' | 'underline' | 'button' | 'tag'
  value?: Date | number | string | null | undefined
  placeholderValue?: Date | number | string | null | undefined
  showFooter?: boolean
  disabled?: boolean
  inputContainerStyle?: ThemeUICSSObject
  inputStyle?: ThemeUICSSObject
  prefix?: React.ReactElement | string | null
  buttonLabel?: string
  suggestedDates?: Array<{
    date: Date
    label: string
    description: string
  }>
  disabledDays?: Matcher
  placement?: Placement
  renderExtraFooter?: () => JSX.Element
  showTooltip?: boolean
  showQuickSelectButtons?: boolean
}

export const Datepicker = ({
  onChange,
  renderExtraFooter,
  value,
  placeholderValue,
  disabled = false,
  showFooter = true,
  variant = 'muted',
  inputStyle,
  inputContainerStyle,
  prefix,
  buttonLabel = 'Change date',
  suggestedDates = [],
  disabledDays,
  placement = 'bottom-start',
  showTooltip = true,
  showQuickSelectButtons = true,
}: DatepickerProps) => {
  const theme = useTheme()
  const [today] = useState(() => new Date())
  const selectedDay = useMemo(() => (value ? new Date(value) : undefined), [value])

  useEffect(() => {
    if (!selectedDay) return
    setMonth(selectedDay)
  }, [selectedDay])

  const placeholderDay = useMemo(
    () => (placeholderValue ? new Date(placeholderValue) : today),
    [placeholderValue, today],
  )
  const [month, setMonth] = useState(selectedDay ?? today)
  return (
    <Popover
      // Put above the QuickAddAction modal since its still using tippy
      zIndex={10000}
      placement={placement}
      render={() => (
        <PopupWrapper>
          <div>
            {suggestedDates.length > 0 && (
              <Flex
                column
                gap={1}
                sx={{
                  paddingX: 2,
                  paddingTop: 0,
                  paddingBottom: 1,
                  marginBottom: 1,
                  borderBottom: '1px solid',
                  borderBottomColor: 'border',
                }}
              >
                <Text variant="small" color="text-light">
                  Suggested dates
                </Text>
                <Flex row gap={1}>
                  {suggestedDates.map(({ date, label, description }) => (
                    <Tooltip key={date.getTime()} description={description} position="bottom">
                      <Tag
                        onClick={() => {
                          setMonth(date)
                          onChange?.(date)
                        }}
                      >
                        {label}
                      </Tag>
                    </Tooltip>
                  ))}
                </Flex>
              </Flex>
            )}
            <DayPicker
              showOutsideDays
              mode="single"
              month={month}
              onMonthChange={setMonth}
              selected={selectedDay}
              onDayClick={(date: Date) => {
                onChange?.(date)
              }}
              classNames={dayPickerStyles}
              sx={{
                '--rdp-cell-size': '32px',
                [`.${dayPickerStyles.day}`]: {
                  border: 'none',
                  color: 'text',
                },
                [`.${dayPickerStyles.day_today}`]: {
                  color: 'text',
                  fontWeight: 'body',
                  bg: 'accent-background-light',
                },
                [`.${dayPickerStyles.day_selected}`]: {
                  color: 'text-inverted',
                  fontWeight: 'bold',
                  bg: 'accent-background',
                },
              }}
              styles={{
                root: { margin: theme.space[1], marginTop: 0, marginBottom: 0, height: '246px' }, // Fixed height to account for different height months when the popup is up so that it does not jump around
                nav_button_next: { border: 'none', width: 20, height: 20 },
                nav_button_previous: { border: 'none', width: 20, height: 20 },
                caption_label: { fontSize: theme.fontSizes[1] },
                head_cell: {
                  textTransform: 'initial',
                  fontWeight: 400,
                  fontSize: theme.fontSizes[0],
                  color: theme.colors['text-light'],
                },
              }}
              disabled={disabled ? [today, { dayOfWeek: [0, 1, 2, 3, 4, 5, 6] }] : disabledDays ? disabledDays : []}
            />
            {!disabled && showFooter ? (
              <div>
                <FlyoutDivider sx={{ marginBottom: 1 }} />
                <Flex justify="flex-start" sx={{ paddingX: 1 }} alignContent="center">
                  {renderExtraFooter ? renderExtraFooter() : null}
                  {showQuickSelectButtons && (
                    <Button
                      variant="muted"
                      size="small"
                      startIcon={<UndoIcon />}
                      sx={{ marginLeft: 'auto' }}
                      onClick={() => {
                        setMonth(today)
                        onChange?.(today)
                      }}
                    >
                      Today
                    </Button>
                  )}
                </Flex>
              </div>
            ) : null}
          </div>
        </PopupWrapper>
      )}
    >
      {({ getReferenceProps }) =>
        variant === 'tag' ? (
          <Tag disabled={disabled} {...getReferenceProps()} sx={inputStyle}>
            {prefix}
            {selectedDay ? formatDateDisplay(selectedDay) : ''}
          </Tag>
        ) : variant === 'button' ? (
          <Button
            startIcon={<CalendarIcon />}
            variant="muted"
            size="small"
            disabled={disabled}
            textHidden
            showTooltip={showTooltip}
            {...getReferenceProps()}
          >
            {buttonLabel}
          </Button>
        ) : (
          <div {...getReferenceProps()} sx={{ maxWidth: '150px' }}>
            <Input
              sx={{
                width: '100%',
                ...inputContainerStyle,
                input: inputStyle,
              }}
              variant={variant}
              disabled={disabled}
              value={selectedDay ? formatDateDisplay(selectedDay) : ''}
              prefix={prefix}
              placeholder={formatDateDisplay(placeholderDay)}
              onKeyDown={(e) => {
                if (e.key == 'Escape' || e.key == 'Esc') {
                  e.currentTarget.blur()
                }
              }}
            />
          </div>
        )
      }
    </Popover>
  )
}

import { Suspender, useFeatureFlags } from '@mm/company-ui'
import React from 'react'
import {
  DecidedComment,
  DecisionCommentsDocument,
  DecisionUserCommentsListDocument,
} from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { AddDecisionCommentBox, DecisionCommentsList } from '../../comments'
import { useActiveUser } from '../../users'
import { DecidedCommentProvider } from '../DecidedCommentContext'
import { UserMadeCommentContextProvider } from '../UserMadeCommentContext'

export type DecisionCommentsProps = {
  decisionId: string
  fixedComposer?: boolean
  order?: 'newest' | 'oldest'
  fixedAndScroll?: boolean
}

export const DecisionComments = ({
  decisionId,
  fixedComposer = true,
  order = 'oldest',
  fixedAndScroll = true,
}: DecisionCommentsProps) => {
  const { flipComments } = useFeatureFlags()
  const user = useActiveUser()
  const { data, loading, error } = useQuery(DecisionCommentsDocument, {
    variables: {
      decisionId,
    },
  })

  const decision = data?.decision
  const decidedComments = decision?.decidedComments ?? []

  const {
    data: userCommentsData,
    loading: userCommentsLoading,
    error: userCommentsError,
  } = useQuery(DecisionUserCommentsListDocument, {
    fetchPolicy: 'no-cache',
    variables: {
      decisionId,
      first: 1,
      authorId: user?.id,
    },
  })

  if (decision == null || userCommentsData == null || user == null) {
    if (loading || userCommentsLoading) {
      return <Suspender />
    }
    throw error ?? userCommentsError ?? new Error('Decision not found')
  }

  const decisionContributors = decision?.contributors?.edges?.map((edge) => edge.node.id)
  const userHasMadeComments = Boolean(userCommentsData?.decision?.comments?.edges?.length || 0)
  const showRequestForComments =
    !userHasMadeComments &&
    decisionContributors.includes(user?.id || '') &&
    decision.creator.id !== user?.id &&
    decision.decisionMaker.id !== user?.id

  return (
    <DecidedCommentProvider value={decidedComments as DecidedComment[]}>
      <UserMadeCommentContextProvider
        value={{
          showRequestForComments,
          decisionId,
        }}
      >
        <DecisionCommentsList
          user={{ id: user.id, firstName: user.firstName ?? '' }}
          decisionId={decisionId}
          order={order}
          composer={!flipComments ? <AddDecisionCommentBox decisionId={decisionId} /> : null}
          fixedAndScroll={fixedAndScroll}
          fixedComposer={fixedComposer}
        />
      </UserMadeCommentContextProvider>
    </DecidedCommentProvider>
  )
}

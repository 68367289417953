import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ArchiveBoxIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="archive-box" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.240005 1.6C0.240005 1.20236 0.56236 0.880005 0.960005 0.880005H15.04C15.4376 0.880005 15.76 1.20236 15.76 1.6V4.8C15.76 5.19765 15.4376 5.52 15.04 5.52H0.960005C0.56236 5.52 0.240005 5.19765 0.240005 4.8V1.6ZM1.68001 2.32V4.08H14.32V2.32H1.68001ZM2.24 6.63999C2.63765 6.63999 2.96 6.96234 2.96 7.35999V13.68H13.04V7.35999C13.04 6.96234 13.3623 6.63999 13.76 6.63999C14.1576 6.63999 14.48 6.96234 14.48 7.35999V14.4C14.48 14.7976 14.1576 15.12 13.76 15.12H2.24C1.84236 15.12 1.52 14.7976 1.52 14.4V7.35999C1.52 6.96234 1.84236 6.63999 2.24 6.63999ZM6.07999 6.63999C5.68235 6.63999 5.35999 6.96234 5.35999 7.35999V9.91999C5.35999 10.3176 5.68235 10.64 6.07999 10.64H9.91999C10.3176 10.64 10.64 10.3176 10.64 9.91999V7.35999C10.64 6.96234 10.3176 6.63999 9.91999 6.63999H6.07999ZM6.79999 9.19999V8.07999H9.19999V9.19999H6.79999Z"
    />
  </IconBase>
)

import React from 'react'
import { Flex } from '../Flex'

export type PanelMetadataContainerProps = {
  children: React.ReactNode
  className?: string
}

export const PanelMetadataContainer = ({ children, className }: PanelMetadataContainerProps) => {
  return (
    <Flex grow column className={className}>
      {children}
    </Flex>
  )
}

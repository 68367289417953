import { PanelMetadataContainer, PanelMetadataItem, useFeatureFlags } from '@mm/company-ui'
import { CalendarIcon, CircleCheckmarkIcon, UserProfileIcon } from '@mm/company-ui-icons'
import React from 'react'
import { GoalAssigneePicker } from './GoalAssigneePicker'
import { GoalCreatedDatePicker } from './GoalCreatedDatePicker'
import { GoalDueDatePicker } from './GoalDueDatePicker'
import { GoalParentMeeting } from './GoalParentMeeting'
import { GoalPriorityTagSelect } from './GoalPriorityTagSelect'
import { GoalStatusPicker } from './GoalStatusPicker'

export type GoalMetadataProps = {
  goalId: string
}

export const GoalMetadata = ({ goalId }: GoalMetadataProps) => {
  const { prioritizeGoals } = useFeatureFlags()
  return (
    <PanelMetadataContainer>
      <PanelMetadataItem title="Status" sx={{ alignItems: 'center' }} icon={<CircleCheckmarkIcon />}>
        <GoalStatusPicker cached goalId={goalId} />
      </PanelMetadataItem>

      <PanelMetadataItem title="Due date" icon={<CalendarIcon />}>
        <GoalDueDatePicker goalId={goalId} cached />
      </PanelMetadataItem>

      <PanelMetadataItem title="Assignee" icon={<UserProfileIcon />}>
        <GoalAssigneePicker goalId={goalId} cached />
      </PanelMetadataItem>

      <GoalParentMeeting goalId={goalId} />

      {/* <PanelMetadataItem title="Privacy" sx={{ alignItems: 'center' }} icon={<EyeViewOpen />}>
        <GoalPrivacyTag goalId={goalId} />
      </PanelMetadataItem> */}

      <PanelMetadataItem title="Created" icon={<CalendarIcon />}>
        <GoalCreatedDatePicker goalId={goalId} />
      </PanelMetadataItem>

      {prioritizeGoals ? (
        <PanelMetadataItem title="Priority" sx={{ alignItems: 'center' }} icon={<CalendarIcon />}>
          <GoalPriorityTagSelect goalId={goalId} />
        </PanelMetadataItem>
      ) : null}
    </PanelMetadataContainer>
  )
}

import { useCallback } from 'react'
import {
  UpdateWithEmojiReactionsFragmentDoc,
  UseRemoveUpdateEmojiReactionDocument,
} from '../../../gen/graphql/documents'
import { useApolloClient, useMutation } from '../../apollo'
import { useActiveUser } from '../../users'

export const useRemoveUpdateEmojiReaction = (updateId: string) => {
  const client = useApolloClient()
  const [removeEmojiReaction] = useMutation(UseRemoveUpdateEmojiReactionDocument)
  const activeUser = useActiveUser()

  const activeUserId = activeUser?.id

  return useCallback(
    (emoji: string) => {
      const data = client.readFragment(
        {
          id: client.cache.identify({ __typename: 'Update', id: updateId }),
          fragment: UpdateWithEmojiReactionsFragmentDoc,
          fragmentName: 'updateWithEmojiReactions',
        },
        true,
      )

      return removeEmojiReaction({
        variables: {
          id: updateId,
          emoji,
        },
        optimisticResponse:
          data && activeUserId
            ? {
                removeUpdateEmojiReaction: {
                  ...data,
                  emojiReactions: data.emojiReactions?.filter((reaction) => {
                    const emojiId = emoji.match(/^(:[^:]+:)/)?.[1]
                    return reaction.user.id !== activeUserId || emojiId == null || !reaction.emoji.startsWith(emojiId)
                  }),
                },
              }
            : undefined,
      })
    },
    [client, updateId, removeEmojiReaction, activeUserId],
  )
}

import { FlyoutDivider, FlyoutItem, Text, useFeatureFlags } from '@mm/company-ui'
import { CheckIcon, HistoryIcon } from '@mm/company-ui-icons'
import { useRouter } from 'next/router'
import React from 'react'
import { NavSidebarUserMenuDocument } from '../../../gen/graphql/documents'
import { capture, groupCompany } from '../../analytics'
import { useQuery } from '../../apollo'
import { useLogout } from '../../auth'
import { useActiveCompanyId } from '../../companies'

export const NavSidebarUserMenu = ({ hasRedirectOnChange = true }) => {
  const router = useRouter()
  const { settings } = useFeatureFlags()
  const { activeCompanyId, setActiveCompanyId } = useActiveCompanyId()
  const { data, fetchMore } = useQuery(NavSidebarUserMenuDocument)
  const logout = useLogout()
  const companies = data?.me?.companies?.edges
  const pageInfo = data?.me?.companies?.pageInfo

  const handleLogOut = () => {
    logout()
  }

  return (
    <>
      <div sx={{ maxHeight: '200px', overflowY: 'auto' }}>
        <Text variant="small" uppercase color="text-disabled-medium" sx={{ marginLeft: 2 }}>
          Workspaces
        </Text>
        {companies?.map(({ node }) => (
          <FlyoutItem
            key={node.id}
            onClick={async () => {
              capture('Company Selected', { name: node.name, id: node.id })
              groupCompany(node.id, { name: node.name })
              if (hasRedirectOnChange) {
                await router.push('/')
              }
              setActiveCompanyId(node.id)
            }}
            endIcon={activeCompanyId === node.id ? <CheckIcon /> : null}
            sx={{ fontWeight: activeCompanyId === node.id ? 'bold' : 'inherit' }}
          >
            {node.name}
          </FlyoutItem>
        ))}

        {pageInfo?.hasNextPage && (
          <FlyoutItem
            startIcon={<HistoryIcon />}
            onClick={() => {
              void fetchMore({
                variables: {
                  after: pageInfo?.endCursor,
                },
              })
            }}
          >
            Show more
          </FlyoutItem>
        )}
      </div>
      <FlyoutDivider />
      {settings && (
        <FlyoutItem onClick={() => router.push({ pathname: '/settings/[section]', query: { section: 'workspace' } })}>
          Settings
        </FlyoutItem>
      )}
      <FlyoutItem onClick={handleLogOut}>Log Out</FlyoutItem>
    </>
  )
}

import { Extension } from '@tiptap/core'
import { Bold } from '@tiptap/extension-bold'
import { Code } from '@tiptap/extension-code'
import { Document } from '@tiptap/extension-document'
import { History } from '@tiptap/extension-history'
import { Italic } from '@tiptap/extension-italic'
import { Strike } from '@tiptap/extension-strike'
import { Text } from '@tiptap/extension-text'
import { Underline } from '@tiptap/extension-underline'
import { LinkExtension } from './extensions/LinkExtension'

const SingleLineDocument = Document.extend({
  content: 'text*',
})

export const SingleLineExtension = Extension.create({
  name: 'mochary-method',
  addExtensions() {
    return [
      SingleLineDocument,
      Bold,
      Code,
      History,
      Italic,
      Strike,
      Text,
      Underline,
      LinkExtension.configure({
        validate: (href: string) => /^https?:\/\//.test(href),
      }),
    ]
  },
})

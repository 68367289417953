import { Flex, Suspender, ToggleButton, useFeatureFlags } from '@mm/company-ui'
import React from 'react'
import { ProfileBetaStatusDocument, ProfileBetaStatusUpdateUserDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { useActiveUser } from './ActiveUserProvider'

export type ProfileBetaStatusProps = {
  id: string
}

export const ProfileBetaStatus = ({ id }: ProfileBetaStatusProps) => {
  const { admin } = useFeatureFlags()
  const activeUser = useActiveUser()
  const { data, loading } = useQuery(ProfileBetaStatusDocument, {
    variables: {
      id,
    },
  })

  const [update] = useMutation(ProfileBetaStatusUpdateUserDocument)

  if (activeUser?.role !== 'ADMIN' && !admin) return null

  if (data?.user == null) {
    if (loading) {
      return <Suspender />
    }
    return null
  }

  return (
    <Flex direction="column" gap={1} sx={{ flex: 1, minWith: '250px' }}>
      <ToggleButton
        isSelected={Boolean(data.user.accessToBeta)}
        onChange={async (value: boolean) => {
          void update({
            variables: {
              userId: id,
              accessToBeta: value,
            },
            optimisticResponse: {
              updateUser: {
                __typename: 'User',
                id,
                accessToBeta: value,
              },
            },
          })
        }}
      >
        Beta Status
      </ToggleButton>
    </Flex>
  )
}

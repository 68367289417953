import { Suspender, Tag, TagSelect } from '@mm/company-ui'
import React, { useCallback } from 'react'
import { GetGoalPriorityDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useSetGoalPriority } from '../hooks/useSetGoalPriority'

export type GoalPriorityTagSelectProps = {
  goalId: string
}

const priorityToString = (priority: number) => String(priority + 1)
const stringToPriority = (string: string) => parseInt(string) - 1

export const GoalPriorityTagSelect = ({ goalId }: GoalPriorityTagSelectProps) => {
  const { data, loading } = useQuery(GetGoalPriorityDocument, {
    variables: { goalId },
  })
  const setGoalPriorityFn = useSetGoalPriority()

  const goal = data?.goal

  const handleChange = useCallback(
    (priorityString: string | undefined) => {
      if (priorityString && goal) {
        const priority = stringToPriority(priorityString)

        setGoalPriorityFn({
          goalId,
          destinationPosition: priority,
          surroundingGoals: [
            {
              ...goal,
              priority,
            },
          ],
        })
      }
    },
    [setGoalPriorityFn, goalId, goal],
  )

  if (data?.goal == null) {
    if (loading) {
      return <Suspender />
    }
    return null
  }

  return (
    <TagSelect
      renderEmpty={(props) => (
        <Tag type="button" variant="flat" sx={{ fontWeight: 500, fontSize: 1 }} {...props}>
          None
        </Tag>
      )}
      value={data.goal.priority != undefined ? priorityToString(data.goal.priority) : ''}
      onChange={handleChange}
    >
      <Tag value="1" variant="flat" sx={{ fontWeight: 500, fontSize: 1 }}>
        #1
      </Tag>
      <Tag value="2" variant="flat" sx={{ fontWeight: 500, fontSize: 1 }}>
        #2
      </Tag>
      <Tag value="3" variant="flat" sx={{ fontWeight: 500, fontSize: 1 }}>
        #3
      </Tag>
      <Tag value="4" variant="flat" sx={{ fontWeight: 500, fontSize: 1 }}>
        #4
      </Tag>
      <Tag value="5" variant="flat" sx={{ fontWeight: 500, fontSize: 1 }}>
        #5
      </Tag>
      <Tag value="6" variant="flat" sx={{ fontWeight: 500, fontSize: 1 }}>
        #6
      </Tag>
    </TagSelect>
  )
}

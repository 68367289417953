import { ConfirmPopover, Flyout, FlyoutItem, Suspender, useConfirmPopover, useFeatureFlags } from '@mm/company-ui'
import {
  ChevronIcon,
  DeleteIcon,
  EyeViewOpen,
  LoadingIcon,
  ExpandMaximizeIcon,
  ArchiveBoxIcon,
} from '@mm/company-ui-icons'
import Router from 'next/router'
import { route } from 'nextjs-routes'
import React from 'react'
import {
  DecisionPrivacyStatus,
  DecisionMoreMenuDocument,
  DecisionMoreMenuDeleteDocument,
} from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { evictCache, isGraphQLError, useMutation, useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { archiveDecisionCommand } from '../commands/archiveDecisionCommand'
import { detachDecisionFromMeetingCommand } from '../commands/detachDecisionFromMeetingCommand'
import { DecisionPrivacyMenu } from './DecisionPrivacyMenu'

export type DecisionMoreMenuProps = {
  id: string
  onDecisionDeleted?: () => void
  meetingId?: string
  cached?: boolean
}

export const DecisionMoreMenu = ({ id, meetingId, onDecisionDeleted, cached }: DecisionMoreMenuProps) => {
  const { data, loading, error } = useQuery(DecisionMoreMenuDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })
  const { confirmBeforeDelete, archiveTopicManually } = useFeatureFlags()
  const detachDecisionFromMeeting = useCommand(detachDecisionFromMeetingCommand)
  const { execute: archiveDecision, loading: archiveDecisionLoading } = useCommand(archiveDecisionCommand)
  const { triggerConfirm, confirmControlProps } = useConfirmPopover()
  const [deleteDecision, { loading: deleting }] = useMutation(DecisionMoreMenuDeleteDocument, {
    update: (cache, { data }) => {
      if (data?.deleteDecision.__typename === 'Success') {
        evictCache(cache, 'Decision', id)
        onDecisionDeleted?.()
      }
    },
    variables: {
      id,
    },
    optimisticResponse: {
      deleteDecision: {
        __typename: 'Success',
      },
    },
    onCompleted: ({ deleteDecision }) => {
      if (isGraphQLError(deleteDecision)) {
        alert(`Delete issue failed: ${deleteDecision.message ?? 'unknown error'}`)
      } else if (deleteDecision.__typename !== 'Success') {
        alert('Delete issue failed: unknown error')
      }
    },
  })

  const decision = data?.decision
  const decisionMeetings = decision?.meetings?.edges.map((edge) => edge.node) || []

  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('decision not found, id: ' + id)
  }

  return (
    <div sx={{ width: '260px' }}>
      <FlyoutItem
        onClick={() => {
          void Router.push(route({ pathname: '/decisions/[id]', query: { id } }))
          capture('More Menu Selected', {
            parent: 'Decision',
            menuItem: 'View Full Screen',
          })
        }}
        startIcon={<ExpandMaximizeIcon />}
      >
        View Full Screen
      </FlyoutItem>
      {decision && (
        <Flyout
          backdrop={false}
          position="right-start"
          trigger="mouseenter"
          content={<DecisionPrivacyMenu cached decisionId={id} />}
          sx={{ width: 'auto' }}
        >
          <FlyoutItem
            startIcon={<EyeViewOpen />}
            hint={decision.privacy && privacyLevelCaptionMap[decision.privacy]}
            endIcon={<ChevronIcon sx={{ transform: 'rotate(-90deg)' }} />}
          >
            Privacy
          </FlyoutItem>
        </Flyout>
      )}
      {archiveTopicManually && (
        <FlyoutItem
          onClick={async () => {
            if (!meetingId) return
            await archiveDecision({
              decisionId: id,
              meetingId,
            })
          }}
          startIcon={archiveDecisionLoading ? <LoadingIcon /> : <ArchiveBoxIcon />}
        >
          Archive
        </FlyoutItem>
      )}

      <FlyoutItem
        disabled={deleting || detachDecisionFromMeeting?.loading}
        onClick={async () => {
          if (decisionMeetings.length > 1 && meetingId) {
            void detachDecisionFromMeeting.execute({
              decisionId: id,
              meetingId: meetingId,
              onDecisionDetach: onDecisionDeleted,
            })
          } else {
            if (decisionMeetings.length > 1 || confirmBeforeDelete) {
              if (!(await triggerConfirm())) return
            }
            await deleteDecision()
          }
        }}
        startIcon={deleting || detachDecisionFromMeeting?.loading ? <LoadingIcon /> : <DeleteIcon />}
      >
        {decisionMeetings.length > 1 ? 'Remove' : 'Delete'}
      </FlyoutItem>
      <ConfirmPopover
        {...confirmControlProps}
        zIndex={10000}
        title="Permanently delete the Issue?"
        description="This action cannot be reversed. Actions created for this Issue will not be deleted and will still show in your Actions tracker."
        confirmLabel="Delete permanently"
      />
    </div>
  )
}

const privacyLevelCaptionMap: Record<DecisionPrivacyStatus, string> = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
}

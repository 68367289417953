import React from 'react'
import { AddGoalCommentBoxDocument } from '../../../gen/graphql/documents'
import { useMutation } from '../../apollo'
import { AddCommentBox, addRootCommentToCache } from '../../comments'

export type AddGoalCommentBoxProps = {
  goalId: string
}

export const AddGoalCommentBox = ({ goalId }: AddGoalCommentBoxProps) => {
  const [addComment] = useMutation(AddGoalCommentBoxDocument, {
    update: (cache, { data }) => {
      const comment = data?.createCommentOnGoal
      if (comment?.__typename === 'Comment') {
        addRootCommentToCache(cache, comment)
      }
    },
  })

  return (
    <AddCommentBox
      id={`goal:${goalId}`}
      onSubmit={async ({ htmlBody, author }) => {
        const { data } = await addComment({
          variables: {
            goalId,
            htmlBody,
            author,
          },
        })
        if (data?.createCommentOnGoal.__typename !== 'Comment') {
          throw new Error('failed to create a comment')
        }
      }}
    />
  )
}

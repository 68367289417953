import { Drawer } from '@mm/company-ui'
import React from 'react'
import { ActionView, GoalView, UserView } from '../../accountability'
import { SingleAction } from '../../actions'
import { CapabilitiesProvider } from '../../capabilities'
import { SingleDecision } from '../../decisions'
import { SingleGoal } from '../../goals'
import { SingleGoodThing } from '../../goodThing'
import { MeetingPrepView } from '../../meetingPrep'
import { MeetingTopicsArchive } from '../../meetings'
import { SinglePrototypeFeedback } from '../../prototypeFeedback'
import { SingleTopic } from '../../topics'
import { UpdateView } from '../../updates'
import { SingleProfile } from '../../users'
import { DrawerType, useContextualDrawers } from '../hooks/useContextualDrawers'

const drawerRenderMap: Record<DrawerType, (id: string) => React.ReactNode> = {
  action: (id) => <SingleAction id={id} />,
  topic: (id) => <SingleTopic id={id} />,
  profile: (id) => <SingleProfile id={id} />,
  goal: (id) => <SingleGoal id={id} />,
  goodThing: (id) => <SingleGoodThing goodThingId={id} />,
  decision: (id) => <SingleDecision id={id} />,
  archive: (id) => <MeetingTopicsArchive meetingId={id} />,
  feedback: (id) => <SinglePrototypeFeedback id={id} />,
  prep: (id) => (
    <div sx={{ overflowY: 'auto', height: '100%' }}>
      <MeetingPrepView meetingPrepId={id} inDrawer />
    </div>
  ),
  update: (id) => (
    <div sx={{ overflowY: 'auto', height: '100%' }}>
      <UpdateView updateId={id} inDrawer />
    </div>
  ),
  'a-action': (id) => (
    <div sx={{ overflowY: 'auto', height: '100%' }}>
      <ActionView actionId={id} inDrawer />
    </div>
  ),
  'a-goal': (id) => (
    <div sx={{ overflowY: 'auto', height: '100%' }}>
      <GoalView goalId={id} inDrawer />
    </div>
  ),
  'a-profile': (id) => (
    <div sx={{ overflowY: 'auto', height: '100%' }}>
      <UserView userId={id} inDrawer />
    </div>
  ),
}

export const ContextualDrawer = () => {
  const { active, hideDrawer } = useContextualDrawers()

  return (
    <Drawer open={active != null} onHide={hideDrawer} variant="flat">
      <CapabilitiesProvider isolated>{active && drawerRenderMap[active.type](active.id)}</CapabilitiesProvider>
    </Drawer>
  )
}

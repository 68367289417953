import React from 'react'
import { UpdateEmojiReactionsDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { EmojiReactions } from '../../components/emoji/EmojiReactions'
import { useAddUpdateEmojiReaction } from '../hooks/useAddUpdateEmojiReaction'
import { useRemoveUpdateEmojiReaction } from '../hooks/useRemoveUpdateEmojiReaction'

export type UpdateEmojiReactionsProps = {
  id: string
  cached?: boolean
  hasAddButton?: boolean
}

export const UpdateEmojiReactions = ({ id, cached, hasAddButton }: UpdateEmojiReactionsProps) => {
  const { data } = useQuery(UpdateEmojiReactionsDocument, {
    variables: { id },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const addReaction = useAddUpdateEmojiReaction(id)
  const removeReaction = useRemoveUpdateEmojiReaction(id)

  const emojiReactions = data?.update?.emojiReactions || []

  return (
    <EmojiReactions
      testid="UpdateEmojiReactions"
      reactions={emojiReactions}
      hasAddButton={hasAddButton}
      onAdd={addReaction}
      onRemove={removeReaction}
    />
  )
}

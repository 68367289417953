import { Suspender } from '@mm/company-ui'
import React, { useMemo } from 'react'
import { ActionsDashboardDataSource } from '../../types'
import { BaseActionsDashboard, defaultRenderEmptyPlaceholder } from './BaseActionsDashboard'
import { useFetchActionsDashboardData } from './DataSource'
import { ActionsDashboardData, ActionsDashboardProps } from './types'

export type ActionsDashboardFlatProps = React.ComponentPropsWithoutRef<typeof ActionsDashboardFlat>

export const ActionsDashboardFlat = ({
  dataSource,
  renderEmptyPlaceholder = defaultRenderEmptyPlaceholder,
  newActionDefaults,
  showDoneActions = true,
  showAddRow,
  ...rest
}: ActionsDashboardProps<{
  dataSource: ActionsDashboardDataSource
  renderEmptyPlaceholder?: () => React.ReactElement
  showDoneActions?: boolean
  showAddRow?: boolean
}>) => {
  const {
    data: actions,
    hasMore,
    fetchMore,
    loading,
  } = useFetchActionsDashboardData(dataSource, {
    orderBy: 'CREATED_AT_DESC',
    filters: {
      status: showDoneActions ? undefined : 'ACTIVE',
    },
  })

  const data = useMemo<ActionsDashboardData>(() => {
    return [
      {
        id: 'actions',
        title: 'Actions',
        hideTitle: true,
        rows: actions ?? [],
        hasAddMore: true,
        renderEmptyPlaceholder,
        hasMore,
        fetchMore,
        loadingMore: loading,
        newActionDefaults,
      },
    ]
  }, [actions, renderEmptyPlaceholder, hasMore, fetchMore, loading, newActionDefaults])

  if (actions == null) {
    if (loading) {
      return <Suspender />
    }
    return <>Access Denied</>
  }

  return <BaseActionsDashboard {...rest} data={data} newActionDefaults={newActionDefaults} showAddRow={showAddRow} />
}

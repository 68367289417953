import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Button,
  Editable,
  EditableFormController,
  Flex,
  isPropValid,
  MotionFlex,
  Select,
  TextareaAutosize,
  usePopoverInterceptor,
} from '@mm/company-ui'
import { useAnimation } from 'framer-motion'
import isHotkey from 'is-hotkey'
import _ from 'lodash'
import React, { useEffect, useImperativeHandle, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { css } from 'theme-ui'
import { QuickCreateTopicDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { UserSelectWithMeetingParticipants } from '../../components/UserSelectWithQuery'
import { useActiveUser } from '../../users'
import { createTopicCommand } from '../commands/createTopicCommand'

export type QuickCreateTopicData = {
  title: string
  description: string
  section: string
  owner: string
}

export type QuickCreateTopicProps = {
  meetingId: string
  defaults?: Partial<QuickCreateTopicData>
  autofocus?: boolean
  onRequestClose?: () => void
  onComplete?: (data: QuickCreateTopicData) => Promise<void> | void
}

export type QuickCreateTopicRef = {
  focusTitle: () => void
  setTitle: (title: string) => void
}

export const QuickCreateTopic = React.forwardRef<QuickCreateTopicRef, QuickCreateTopicProps>(
  ({ meetingId, defaults, autofocus = true, onRequestClose, onComplete }, forwardRef) => {
    const activeUser = useActiveUser()
    const containerRef = useRef<HTMLFormElement>(null)
    const animateShake = useAnimation()
    const { registerInterceptor } = usePopoverInterceptor()
    const theme = useTheme()
    const createTopic = useCommand(createTopicCommand)

    const { data } = useQuery(QuickCreateTopicDocument, {
      variables: {
        id: meetingId,
      },
    })

    const {
      register,
      handleSubmit,
      // destructuring here is important to prevent bugs due to the formState rules
      // see https://react-hook-form.com/api/useform/formstate#rules
      formState: { isDirty, isSubmitting, errors },
      control,
      reset,
      setValue,
      setFocus,
    } = useForm<QuickCreateTopicData>({
      mode: 'onChange',

      defaultValues: {
        title: defaults?.title,
        description: defaults?.description ?? '<p></p>',
        owner: defaults?.owner || activeUser?.id,
        section: defaults?.section,
      },
    })

    useEffect(
      () =>
        registerInterceptor(
          () =>
            isDirty &&
            !window.confirm('You are in the middle of creating a topic. Are you sure you want to navigate away?'),
        ),
      [registerInterceptor, isDirty],
    )

    useImperativeHandle(
      forwardRef,
      () => ({
        setTitle: (title) => {
          setValue('title', title, { shouldValidate: true })
        },
        focusTitle: () => {
          setFocus('title')
        },
      }),
      [setFocus, setValue],
    )

    const submit = handleSubmit(
      async (values) => {
        if (isSubmitting) {
          return
        }

        await createTopic.execute({
          data: {
            title: values.title,
            description: values.description,
            owner: values.owner,
            meetingTopicSectionId: values.section,
          },
        })

        await onComplete?.(values)
        reset({
          title: '',
          description: '<p></p>',
          owner: '',
          section: '',
        })
      },
      () => {
        void animateShake.start('shake')
      },
    )

    const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (isHotkey('esc', e)) {
        if (document.activeElement !== e.currentTarget) {
          e.stopPropagation()
          e.currentTarget.focus()
        }

        onRequestClose?.()
      }
    }

    return (
      <MotionFlex
        animate={animateShake}
        variants={{ shake: { translateX: [-2, 0, 2, -4, 0, -4] } }}
        transition={{ duration: 0.4 }}
      >
        <form
          ref={containerRef}
          tabIndex={0}
          data-testid="QuickCreateTopic"
          onSubmit={submit}
          onKeyDown={handleKeyDown}
          sx={{
            borderRadius: 'medium',
            width: '100%',

            '&:focus, &.focus': {
              outlineStyle: 'solid',
              outlineWidth: '4px',
              outlineColor: 'accent-border-light',
            },
          }}
        >
          <Container column>
            <Flex row gap={1.5} sx={{ px: 2, pt: 1 }}>
              <TitleInput
                {...register('title', { required: true })}
                titleValidation={Boolean(errors.title)}
                autoFocus={autofocus}
                placeholder="Title..."
                disabled={isSubmitting}
                onKeyDown={(e) => {
                  if (isHotkey('shift+enter', e)) {
                    setFocus('description')
                    e.preventDefault()
                  } else if (isHotkey('Meta+enter', e)) {
                    void submit()
                  } else if (isHotkey('enter', e)) {
                    e.preventDefault()
                  }
                }}
              />
              <Flex row>
                <Controller
                  name="owner"
                  control={control}
                  render={({ field }) => {
                    return (
                      <UserSelectWithMeetingParticipants
                        meetingId={meetingId}
                        value={field.value ?? null}
                        onChange={([value]: string[]) => {
                          field.onChange(value)
                          return
                        }}
                      />
                    )
                  }}
                />
              </Flex>
            </Flex>

            <Flex column gap={1} sx={{ px: 2, pb: 1 }}>
              <EditableFormController {...register('description')}>
                {(props) => (
                  <Editable
                    {...props}
                    mode="compact"
                    editable={!isSubmitting}
                    placeholder="Please write a description and provide a link to a Loom describing the topic."
                    hotkeys={{
                      'Meta-Enter': () => {
                        void submit()
                        return true
                      },
                    }}
                    sx={{
                      flexGrow: 1,
                      minHeight: 4,
                      overflow: 'auto',
                      maxHeight: `${6 * 32 + 8}px`,

                      ...(isSubmitting && {
                        color: 'text-light',
                      }),
                    }}
                  />
                )}
              </EditableFormController>

              <Controller
                name="section"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  const hasError = Boolean(errors.section)
                  const sections = _(data?.meeting?.sections)
                    .filter(({ __typename }) => __typename === 'MeetingTopicSection')
                    .map(({ title, id }) => ({
                      label: title || '',
                      value: id || '',
                    }))
                    .value()

                  return (
                    <div sx={{ boxShadow: hasError && `0 2px 0 ${theme.colors['system-border-warning']}` }}>
                      <Select
                        placeholder="Meeting Section"
                        variant="condensed"
                        value={_.find(sections, ({ value }) => value === field.value)}
                        options={sections}
                        onChange={(option) => {
                          field.onChange(option?.value)
                          return
                        }}
                      />
                    </div>
                  )
                }}
              />
            </Flex>

            <Flex
              row
              sx={{
                padding: 1,
                paddingLeft: 2,
                borderTop: '1px solid',
                borderTopColor: 'border',
              }}
              gap={1}
            >
              <div sx={{ flex: 1 }} />
              <Button
                variant="default"
                size="small"
                onClick={() => {
                  reset()

                  onRequestClose?.()
                }}
              >
                Cancel
              </Button>

              <Button variant="accent" size="small" loading={isSubmitting} type="submit">
                Create Topic
              </Button>
            </Flex>
          </Container>
        </form>
      </MotionFlex>
    )
  },
)

QuickCreateTopic.displayName = 'QuickCreateTopic'

const Container = styled(Flex)(() =>
  css({
    borderRadius: 'medium',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'border',
    bg: 'background',
    transition: 'box-shadow 200ms ease-in-out',
    boxShadow: 'default',
    minWidth: 400,
  }),
)

const TitleInput = styled(TextareaAutosize, { shouldForwardProp: isPropValid })<{ titleValidation?: boolean }>(
  () =>
    css({
      flexGrow: 1,
      minWidth: 0,
      px: 0,
      py: '5.5px',
      background: 'transparent',
      border: 'none',
      outline: 'none',
      color: 'text',
      fontWeight: 'bold',
      fontFamily: 'body',
      lineHeight: 'body',
      resize: 'none',
      overflow: 'hidden',

      '::placeholder': {
        color: 'text-light',
      },
      '&:disabled': {
        color: 'text-light',
      },
    }),
  ({ titleValidation = false, theme }) =>
    titleValidation &&
    css({
      boxShadow: `inset 0 -2px 0 ${theme.colors['system-border-warning']}`,
    }),
)

import { useFeatureFlags } from '@mm/company-ui/src'
import React from 'react'
import { FeedbackCommentsDocument } from '../../../gen/graphql/documents'
import { CommentsList, CommentsListProps } from '../../comments'
import { AddFeedbackCommentBox } from './AddFeedbackCommentBox'

export type FeedbackCommentsProps = Omit<CommentsListProps<unknown, unknown>, 'query' | 'variables' | 'extractPage'> & {
  prototypeFeedbackId: string
}

export const FeedbackComments = ({ prototypeFeedbackId }: FeedbackCommentsProps) => {
  const { flipComments } = useFeatureFlags()

  return (
    <CommentsList
      query={FeedbackCommentsDocument}
      variables={{ prototypeFeedbackId }}
      extractPage={(data) => data.prototypeFeedback?.comments}
      order={flipComments ? 'oldest' : 'newest'}
      composer={!flipComments ? <AddFeedbackCommentBox prototypeFeedbackId={prototypeFeedbackId} /> : null}
      fixedComposer={false}
      fixedAndScroll={false}
    />
  )
}

import { ConfirmPopover, FlyoutDivider, FlyoutItem, useConfirmPopover, useFeatureFlags } from '@mm/company-ui'
import { CheckIcon, CloseIcon, DeleteIcon, ExpandMaximizeIcon } from '@mm/company-ui-icons'
import Router from 'next/router'
import { route } from 'nextjs-routes'
import React from 'react'
import { GoalMoreMenuDeleteDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { evictCache, isGraphQLError, useMutation } from '../../apollo'
import { useCommand } from '../../commands'
import { editGoalStatusCommand } from '../commands/editGoalStatusCommand'

export type GoalMoreMenuProps = {
  id: string
  onGoalDeleted?: () => void
}

export const GoalMoreMenu = ({ id, onGoalDeleted }: GoalMoreMenuProps) => {
  const { confirmBeforeDelete } = useFeatureFlags()
  const { triggerConfirm, confirmControlProps } = useConfirmPopover()
  const [deleteGoal] = useMutation(GoalMoreMenuDeleteDocument, {
    update: (cache, { data }) => {
      if (data?.deleteGoal.__typename === 'Success') {
        evictCache(cache, 'Goal', id)
        onGoalDeleted?.()
      }
    },
    variables: {
      id,
    },
  })

  const editGoalStatus = useCommand(editGoalStatusCommand)

  return (
    <div sx={{ width: '260px' }}>
      <FlyoutItem
        onClick={() => {
          void Router.push(route({ pathname: '/goals/[id]', query: { id } }))
          capture('More Menu Selected', {
            parent: 'Goal',
            menuItem: 'View Full Screen',
          })
        }}
        startIcon={<ExpandMaximizeIcon />}
      >
        View Full Screen
      </FlyoutItem>
      <FlyoutItem
        onClick={() => {
          void editGoalStatus.execute({
            goalId: id,
            status: 'DONE',
            skipNotDoneFlow: true,
          })
        }}
        startIcon={<CheckIcon />}
      >
        Complete
      </FlyoutItem>
      <FlyoutItem
        onClick={() => {
          void editGoalStatus.execute({
            goalId: id,
            status: 'CANCELLED',
            skipNotDoneFlow: true,
          })
        }}
        startIcon={<CloseIcon />}
      >
        Cancel
      </FlyoutItem>
      <FlyoutDivider />
      <FlyoutItem
        onClick={async () => {
          if (!confirmBeforeDelete || (await triggerConfirm())) {
            const { data } = await deleteGoal()

            if (data?.deleteGoal && isGraphQLError(data?.deleteGoal)) {
              alert(`Delete goal failed: ${data?.deleteGoal.message ?? 'unknown error'}`)
            } else if (data?.deleteGoal.__typename !== 'Success') {
              alert('Delete goal failed: unknown error')
            }
          }
        }}
        startIcon={<DeleteIcon />}
      >
        Delete
      </FlyoutItem>

      <ConfirmPopover
        {...confirmControlProps}
        zIndex={10000}
        title="Permanently delete the Goal?"
        description="This action cannot be reversed. Actions created for this Goal will not be deleted and will still show in your Actions tracker."
        confirmLabel="Delete permanently"
      />
    </div>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const TemplatesMultiplePagesDocumentIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="templates-multiple-pages-document" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.43339 0C4.02832 0 3.69994 0.328376 3.69994 0.733447C3.69994 1.13852 4.02832 1.46689 4.43339 1.46689H14.0331L14.0331 13.2666C14.0331 13.6716 14.3615 14 14.7665 14C15.1716 14 15.5 13.6716 15.5 13.2666L15.5 0.733447C15.5 0.328376 15.1716 0 14.7666 0H4.43339ZM0.733447 2.99999C0.328376 2.99999 0 3.32837 0 3.73344V15.2666C0 15.6716 0.328376 16 0.733447 16H11.2334C11.6385 16 11.9669 15.6716 11.9669 15.2666V3.73344C11.9669 3.32837 11.6385 2.99999 11.2334 2.99999H0.733447ZM1.46689 14.5331V4.46689H10.5V14.5331H1.46689ZM3.19995 7.46668C3.19995 7.06161 3.52833 6.73323 3.9334 6.73323H7.73333C8.1384 6.73323 8.46677 7.06161 8.46677 7.46668C8.46677 7.87175 8.1384 8.20013 7.73333 8.20013H3.9334C3.52833 8.20013 3.19995 7.87175 3.19995 7.46668ZM3.9334 9.9332C3.52833 9.9332 3.19995 10.2616 3.19995 10.6666C3.19995 11.0717 3.52833 11.4001 3.9334 11.4001H6.13335C6.53842 11.4001 6.8668 11.0717 6.8668 10.6666C6.8668 10.2616 6.53842 9.9332 6.13335 9.9332H3.9334Z"
      />
    </IconBase>
  )
}

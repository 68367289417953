import { AddWaitingForRowData, Suspender, useFeatureFlags } from '@mm/company-ui'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { GoalDescriptionDocument } from '../../../gen/graphql/documents'
import { ADD_ACTION } from '../../actions/capabilities'
import { createActionOnGoalCommand } from '../../actions/commands/createActionOnGoal'
import { useQuery } from '../../apollo'
import { CapabilitiesProvider, RegisterCapability } from '../../capabilities'
import { useCommand } from '../../commands'
import { DocumentEditor } from '../../editor'

export type GoalDescriptionProps = {
  goalId: string
  fillHeight?: boolean
  padding?: number
}

export const GoalDescription = ({ goalId, fillHeight = true, padding = 3 }: GoalDescriptionProps) => {
  const { descriptionActions } = useFeatureFlags()
  const addAction = useCommand(createActionOnGoalCommand)
  const { data, loading, error } = useQuery(GoalDescriptionDocument, {
    variables: {
      goalId,
    },
  })

  const handleOnAddAction = useCallback(
    async (data: AddWaitingForRowData) => {
      void addAction.execute({
        goalId,
        data: {
          title: data.title,
          assignee: data.assignee,
          dateRescheduled: false,
          description: data.description,
          dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
          waitingFor: data.waitingFor,
          repeat: data.repeat,
        },
      })
    },
    [goalId, addAction],
  )

  const goal = data?.goal
  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('Goal not found')
  }

  return (
    <CapabilitiesProvider>
      <RegisterCapability capability={ADD_ACTION} handler={handleOnAddAction} skip={!descriptionActions} />

      <DocumentEditor
        key={goal.description.id}
        documentId={goal.description.id}
        placeholder="Enter goal description..."
        mode="compact"
        sx={{
          '&': {
            display: 'flex',
            flexDirection: 'column',

            ...(fillHeight && {
              height: '100%',
            }),
          },
          '.ProseMirror': {
            margin: 0,
            padding,
            minHeight: 'auto',

            ...(fillHeight && {
              padding: 5,
              overflowY: 'scroll',
              height: '100%',
              minHeight: '100%',
            }),
          },
        }}
      />
    </CapabilitiesProvider>
  )
}

import { Suspender } from '@mm/company-ui'
import React, { useCallback } from 'react'
import {
  CreateDecisionMutationInput,
  CreateDecisionOnMeetingDocument,
  CreateMeetingDecisionModalDocument,
} from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { isGraphQLError, useMutation, useQuery } from '../../apollo'
import { CreateDecisionModal, DecisionsUpdatedTopic } from '../../decisions'
import { useEmitEventBusEvent } from '../../eventbus'
import { addGenericTopicToCache } from '../utils/addGenericTopicToCache'

interface CreateMeetingDecisionModalProps {
  meetingId: string
  fromTopicId?: string
  topicSectionId?: string
  title?: string
  defaultIssueText?: string
  defaultLoomLink?: string
  onRequestClose?: () => void
  onComplete?: (id: string) => void
}

export const CreateMeetingDecisionModal = ({
  meetingId,
  fromTopicId,
  topicSectionId,
  title,
  defaultIssueText,
  defaultLoomLink,
  onRequestClose,
  onComplete,
}: CreateMeetingDecisionModalProps) => {
  const { data, loading, error } = useQuery(CreateMeetingDecisionModalDocument, {
    variables: {
      meetingId,
    },
  })
  const emit = useEmitEventBusEvent(DecisionsUpdatedTopic)
  const [createDecision] = useMutation(CreateDecisionOnMeetingDocument, {
    onCompleted: ({ createDecisionOnMeeting }) => {
      if (createDecisionOnMeeting.__typename === 'Decision') {
        capture('Decision Created', {
          parent: 'Meeting',
        })
        onRequestClose?.()
        onComplete?.(createDecisionOnMeeting.id)
      } else if (isGraphQLError(createDecisionOnMeeting)) {
        alert(`Issue creation failed: ${createDecisionOnMeeting.message ?? 'unknown error'}`)
      } else {
        alert('Issue creation failed: unknown error')
      }
    },
    update: (cache, { data }) => {
      if (data?.createDecisionOnMeeting?.__typename === 'Decision') {
        if (topicSectionId && !fromTopicId) addGenericTopicToCache(cache, data.createDecisionOnMeeting, topicSectionId)

        // Todo use event bus
        emit({ type: 'upserted', decision: data.createDecisionOnMeeting })
      }
    },
  })

  const handleCreateDecision = useCallback(
    async (data: CreateDecisionMutationInput) => {
      if (fromTopicId) {
        data.fromTopicId = fromTopicId
      }
      if (topicSectionId) {
        data.topicSectionId = topicSectionId
      }
      await createDecision({
        variables: {
          meetingId,
          data,
        },
      })
    },
    [fromTopicId, topicSectionId, createDecision, meetingId],
  )

  const meeting = data?.meeting

  const meetingMembers = meeting?.participants?.edges?.map((edge) => edge.node.id) || []

  if (meeting == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Meeting not found')
  }
  return (
    <CreateDecisionModal
      meetingMembers={meetingMembers}
      meetingPrivacy={meeting?.privacyLevel || 'PRIVATE'}
      title={title}
      defaultIssueText={defaultIssueText}
      defaultLoomLink={defaultLoomLink}
      onSubmit={handleCreateDecision}
      onRequestClose={onRequestClose}
    />
  )
}

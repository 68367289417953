import React from 'react'
import { MeetingPrepCommentsComposerDocument } from '../../../gen/graphql/documents'
import { useMutation } from '../../apollo'
import { AddCommentBox, addRootCommentToCache } from '../../comments'

export type MeetingPrepCommentsComposerProps = {
  meetingPrepId: string
  onBlur?: () => void
}

export const MeetingPrepCommentsComposer = ({ meetingPrepId, onBlur }: MeetingPrepCommentsComposerProps) => {
  const [addComment] = useMutation(MeetingPrepCommentsComposerDocument, {
    update: (cache, { data }) => {
      const comment = data?.createCommentOnMeetingPrep
      if (comment?.__typename === 'Comment') {
        addRootCommentToCache(cache, comment)
      }
    },
  })

  return (
    <AddCommentBox
      id={`meetingPrep:${meetingPrepId}`}
      onBlur={onBlur}
      onSubmit={async ({ htmlBody, author }) => {
        const { data } = await addComment({
          variables: {
            meetingPrepId,
            htmlBody,
            author,
          },
        })
        if (data?.createCommentOnMeetingPrep.__typename !== 'Comment') {
          throw new Error('failed to create a comment')
        }
      }}
    />
  )
}

import styled from '@emotion/styled'
import classNames from 'classnames'
import React from 'react'
import { css } from 'theme-ui'
import { Flex } from '../Flex'
import { Pressable } from '../Pressable'
import { Text } from '../Text'

export type TabBarProps<K extends React.Key> = {
  tabs: Array<{
    key: K
    label: string
    badge?: string
    icon?: React.ReactElement
  }>
  activeTabKey: K
  onTabChange?: (key: K) => void

  underline?: boolean
  className?: string
}

export const TabBar = <K extends React.Key>({
  tabs,
  activeTabKey,
  onTabChange,
  underline,
  className,
}: TabBarProps<K>) => {
  return (
    <Flex
      row
      gap={1.5}
      sx={{
        ml: -0.5, // to compensate for leftmost padding
        boxShadow: underline && 'inset 0 -1px rgba(0, 0, 0, 0.1)',
      }}
      className={classNames(TabBar.name, className)}
    >
      {tabs.map(({ label, key, badge, icon }) => {
        const active = key === activeTabKey
        return (
          <TabButton
            role="tab"
            aria-selected={active}
            key={key}
            active={active}
            onClick={() => {
              onTabChange?.(key)
            }}
          >
            {icon}
            <Text bold>{label}</Text>
            {badge ? (
              <Text
                variant="small"
                bold
                sx={{
                  gap: 0.5,
                  backgroundColor: 'background-light',
                  padding: '4px 8px',
                  borderRadius: 'large',
                }}
              >
                {badge}
              </Text>
            ) : null}
          </TabButton>
        )
      })}
    </Flex>
  )
}

const TabButton = styled(Pressable)<{ active: boolean }>(({ active, theme }) =>
  css({
    height: 5,
    px: '2px',
    display: 'flex',
    alignItems: 'center',
    gap: 1,

    '&:hover': {
      color: 'text',
      boxShadow: active
        ? `inset 0 -2px ${theme.colors['accent-background']}`
        : `inset 0 -2px ${theme.colors['border-medium']}`,
    },

    // '&:focus': {
    //   outlineStyle: 'solid',
    //   outlineWidth: '4px',
    //   outlineColor: 'accent-border-light',
    // },

    color: active ? 'text' : 'text-light',
    boxShadow: active && `inset 0 -2px ${theme.colors['accent-background']}`,
  }),
)

import { Flex, Text } from '@mm/company-ui'
import React from 'react'

export const EmptyFeedback = () => {
  return (
    <Flex direction="column" sx={{ color: 'text-light', textAlign: 'center' }}>
      {/* <svg
        sx={{ margin: '0 auto' }}
        width="226"
        height="191"
        viewBox="0 0 226 191"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M178.445 40.8633C172.12 40.8633 166.975 35.7223 166.975 29.4027C166.975 22.656 171.693 17.9421 178.445 17.9421C184.769 17.9421 189.914 23.0832 189.914 29.4027C189.914 36.0463 185.093 40.8633 178.445 40.8633ZM178.445 19.0911C172.371 19.0911 168.125 23.3336 168.125 29.4027C168.125 35.0888 172.754 39.7143 178.445 39.7143C184.43 39.7143 188.764 35.3834 188.764 29.4027C188.764 23.7166 184.135 19.0911 178.445 19.0911Z"
          fill="#C0C0C0"
        />
        <path
          d="M170.277 105.458C170.115 105.458 169.953 105.384 169.85 105.266C167.992 103.175 166.96 100.464 166.96 97.6654C166.96 90.9186 171.678 86.2048 178.43 86.2048C184.754 86.2048 189.9 91.3458 189.9 97.6654C189.9 98.9617 189.723 100.214 189.354 101.392C189.31 101.554 189.177 101.687 189.015 101.761C183.192 104.088 176.882 105.325 170.277 105.458C170.277 105.458 170.292 105.458 170.277 105.458ZM178.445 87.3538C172.371 87.3538 168.125 91.5963 168.125 97.6654C168.125 100.096 168.98 102.453 170.543 104.309C176.808 104.162 182.794 102.983 188.352 100.788C188.632 99.8013 188.764 98.7554 188.764 97.6654C188.764 91.9793 184.135 87.3538 178.445 87.3538Z"
          fill="#C0C0C0"
        />
        <path
          d="M181.791 78.9131C175.54 78.9131 169.894 75.4661 167.049 69.8978C166.901 69.6179 167.019 69.2644 167.299 69.1171C167.579 68.9698 167.933 69.0876 168.081 69.3675C170.734 74.538 175.983 77.7494 181.791 77.7494C190.71 77.7494 197.182 71.2825 197.182 62.3704C197.182 53.8854 190.283 46.9914 181.791 46.9914C172.725 46.9914 166.4 53.3109 166.4 62.3704C166.4 62.6944 166.149 62.9449 165.825 62.9449C165.501 62.9449 165.25 62.6944 165.25 62.3704C165.25 52.6333 172.046 45.8424 181.791 45.8424C190.917 45.8424 198.332 53.252 198.332 62.3704C198.332 71.9601 191.374 78.9131 181.791 78.9131Z"
          fill="#C0C0C0"
        />
        <path
          d="M183.369 23.4367C181.334 26.3976 179.034 29.4617 177.899 32.9087C176.676 31.4503 175.526 29.933 174.302 28.4747C173.816 27.9149 173.005 28.7251 173.491 29.2849C174.936 30.9937 176.263 32.8056 177.737 34.4996C178.017 34.8237 178.592 34.6469 178.695 34.2492C179.639 30.4634 182.204 27.1637 184.371 24.0113C184.784 23.4073 183.781 22.8328 183.369 23.4367Z"
          fill="#C0C0C0"
        />
        <path
          d="M181.231 95.0581C179.875 97.0762 178.666 99.1974 177.133 101.098C176.219 100.317 175.54 99.3153 174.641 98.5051C174.096 98.0042 173.285 98.8144 173.83 99.3153C174.921 100.302 175.732 101.54 176.926 102.409C177.118 102.541 177.472 102.497 177.619 102.32C179.373 100.243 180.715 97.8864 182.219 95.6326C182.632 95.0139 181.644 94.4394 181.231 95.0581Z"
          fill="#C0C0C0"
        />
        <path
          d="M180.597 51.2191V66.0089C180.597 66.7454 181.747 66.7454 181.747 66.0089V51.2191C181.747 50.4826 180.597 50.4826 180.597 51.2191Z"
          fill="#C0C0C0"
        />
        <path
          d="M181.6 71.1205C181.717 70.5755 181.821 70.0157 181.894 69.4707C181.998 68.7489 180.877 68.4985 180.774 69.235C180.7 69.7948 180.597 70.3398 180.479 70.8848C180.317 71.6066 181.437 71.8571 181.6 71.1205Z"
          fill="#C0C0C0"
        />
        <path
          d="M155.373 88.9594H132.581C132.256 88.9594 132.006 88.709 132.006 88.385C132.006 88.0609 132.256 87.8104 132.581 87.8104H155.373C155.697 87.8104 155.948 88.0609 155.948 88.385C155.948 88.709 155.697 88.9594 155.373 88.9594Z"
          fill="#C0C0C0"
        />
        <path
          d="M155.373 30.5961H140.026C139.701 30.5961 139.451 30.3456 139.451 30.0216C139.451 29.6975 139.701 29.447 140.026 29.447H155.373C155.697 29.447 155.948 29.6975 155.948 30.0216C155.948 30.3309 155.697 30.5961 155.373 30.5961Z"
          fill="#C0C0C0"
        />
        <path
          d="M157.422 62.7682H120.743C120.728 62.7682 120.713 62.7682 120.698 62.7682C120.787 63.1512 120.861 63.5342 120.92 63.9172H157.437C157.761 63.9172 158.012 63.6668 158.012 63.3427C157.997 63.0333 157.731 62.7682 157.422 62.7682Z"
          fill="#C0C0C0"
        />
        <path
          d="M169.216 112.116C162.375 112.116 155.712 110.908 149.402 108.507C144.891 106.828 140.571 104.515 136.561 101.657C121.878 91.1397 113.106 74.0961 113.106 56.0655C113.106 23.0537 136.178 0 169.216 0C200.146 0 225.311 25.1455 225.311 56.0655C225.311 88.547 201.708 112.116 169.216 112.116ZM169.216 1.149C136.856 1.149 114.256 23.7314 114.256 56.0655C114.256 73.7278 122.851 90.4178 137.239 100.729C141.161 103.528 145.392 105.782 149.815 107.447C155.992 109.789 162.523 110.967 169.216 110.967C201.06 110.967 224.161 87.8841 224.161 56.0655C224.161 25.779 199.512 1.149 169.216 1.149Z"
          fill="#C0C0C0"
        />
        <path
          d="M169.216 105.473C141.942 105.473 119.755 83.3028 119.755 56.0655C119.755 46.5199 122.069 37.4605 126.448 29.8594C127.568 27.9002 128.851 25.9852 130.281 24.2027L131.976 22.1846C131.976 22.1846 132.05 22.0962 132.065 22.0962C141.131 11.9909 153.987 6.65832 169.216 6.65832C196.475 6.65832 218.662 28.8282 218.662 56.0803C218.662 77.2632 207.59 94.3362 189.044 101.761C183.206 104.103 176.897 105.34 170.307 105.473C169.938 105.473 169.57 105.473 169.216 105.473ZM169.216 7.7926C154.311 7.7926 141.765 12.9926 132.92 22.8475L131.18 24.9098C129.794 26.6481 128.541 28.5041 127.45 30.4192C123.175 37.8582 120.905 46.7262 120.905 56.0655C120.905 82.6694 142.576 104.324 169.216 104.324C169.57 104.324 169.923 104.324 170.263 104.309C176.72 104.191 182.897 102.968 188.602 100.685C206.691 93.4376 217.497 76.7623 217.497 56.0655C217.497 29.4469 195.841 7.7926 169.216 7.7926Z"
          fill="#C0C0C0"
        />
        <path
          d="M99.484 191C99.3808 191 99.2776 190.971 99.1891 190.912L90.3142 185.476C90.0488 185.314 89.9603 184.975 90.1078 184.71L99.0417 168.624C99.1891 168.344 99.5429 168.241 99.823 168.403C100.103 168.55 100.206 168.904 100.044 169.184L91.3756 184.784L99.2776 189.63L110.099 171.835C110.261 171.57 110.615 171.482 110.895 171.644C111.16 171.806 111.248 172.159 111.086 172.439L99.9705 190.735C99.8968 190.867 99.7641 190.956 99.6166 191C99.5872 191 99.5282 191 99.484 191Z"
          fill="#C0C0C0"
        />
        <path
          d="M121.332 155.057C121.229 155.057 121.126 155.027 121.037 154.968C120.772 154.806 120.684 154.453 120.846 154.173L149.122 107.668C149.284 107.402 149.638 107.314 149.918 107.476C150.183 107.638 150.272 107.992 150.11 108.272L121.834 154.777C121.716 154.954 121.524 155.057 121.332 155.057Z"
          fill="#C0C0C0"
        />
        <path
          d="M111.234 148.251C111.145 148.251 111.042 148.222 110.954 148.178C110.673 148.03 110.57 147.677 110.732 147.397L136.399 100.906C136.547 100.626 136.9 100.523 137.18 100.685C137.461 100.833 137.564 101.186 137.402 101.466L111.735 147.957C111.632 148.148 111.425 148.251 111.234 148.251Z"
          fill="#C0C0C0"
        />
        <path
          d="M121.332 155.057C121.229 155.057 121.126 155.027 121.037 154.968C120.772 154.806 120.684 154.453 120.846 154.173L141.854 119.6C138.05 117.655 134.232 115.77 130.487 113.987L113.032 145.585C112.885 145.865 112.531 145.968 112.251 145.806C111.971 145.658 111.868 145.305 112.03 145.025L129.75 112.956C129.898 112.691 130.222 112.588 130.502 112.72C134.601 114.65 138.787 116.712 142.945 118.863C143.092 118.937 143.195 119.069 143.24 119.217C143.284 119.364 143.254 119.541 143.181 119.673L121.834 154.777C121.716 154.954 121.524 155.057 121.332 155.057Z"
          fill="#C0C0C0"
        />
        <path
          d="M102.197 186.551C102.093 186.551 102.005 186.522 101.917 186.478L92.7172 181.175C92.4518 181.013 92.3487 180.674 92.4961 180.394L99.0565 168.624C99.2039 168.344 99.5577 168.241 99.8378 168.403C100.118 168.55 100.221 168.904 100.059 169.184L93.7787 180.468L101.99 185.196L110.113 171.835C110.275 171.57 110.629 171.482 110.909 171.644C111.175 171.806 111.263 172.159 111.101 172.439L102.683 186.286C102.58 186.448 102.388 186.551 102.197 186.551Z"
          fill="#C0C0C0"
        />
        <path
          d="M156.08 59.8367C155.933 59.8367 155.785 59.7778 155.668 59.6747L126.536 30.5371C126.36 30.3603 126.315 30.0657 126.448 29.8447C127.568 27.8855 128.851 25.9705 130.281 24.1881L131.976 22.17C132.08 22.0521 132.271 21.9343 132.433 21.9195C132.596 21.9195 132.787 21.9343 132.905 22.0521L163.127 52.2503C163.23 52.3535 163.289 52.5008 163.289 52.6628C163.289 52.8101 163.23 52.9574 163.127 53.0753L156.508 59.6747C156.375 59.7778 156.228 59.8367 156.08 59.8367ZM127.657 30.0362L156.08 58.4373L161.889 52.6481L132.522 23.319L131.18 24.9246C129.883 26.5303 128.704 28.2538 127.657 30.0362Z"
          fill="#C0C0C0"
        />
        <path d="M165.825 62.3851L162.361 53.1636L156.596 58.9234L165.825 62.3851Z" fill="#C0C0C0" />
        <path
          d="M165.825 62.9596C165.751 62.9596 165.692 62.9449 165.619 62.9302L156.39 59.4684C156.213 59.3948 156.066 59.2475 156.036 59.056C156.007 58.8645 156.051 58.673 156.198 58.5257L161.963 52.7659C162.095 52.6333 162.302 52.5744 162.493 52.6038C162.685 52.648 162.847 52.7806 162.906 52.9574L166.371 62.1789C166.444 62.3851 166.4 62.6208 166.238 62.7829C166.12 62.9007 165.973 62.9596 165.825 62.9596ZM157.628 58.7024L164.837 61.3982L162.139 54.1948L157.628 58.7024Z"
          fill="#C0C0C0"
        />
        <path
          d="M110.172 172.704C107.209 172.704 103.14 171.511 97.6707 167.814C91.6705 163.763 89.6213 163.895 80.997 164.426C75.8518 164.75 68.8049 165.177 57.468 164.985C20.4497 164.322 1.88892 154.143 0.753747 133.859C-0.543589 110.584 17.9877 98.5787 32.052 91.5668C45.9394 84.6433 70.6183 84.1425 70.8541 84.1425C71.1932 84.1425 71.4291 84.3929 71.4438 84.7023C71.4438 85.0263 71.1932 85.2768 70.8836 85.2915C70.633 85.2915 46.2195 85.7923 32.568 92.598C18.7985 99.4626 0.650548 111.203 1.90366 133.8C2.9946 153.363 21.1868 163.188 57.4827 163.836C68.7902 164.043 75.8076 163.601 80.9232 163.277C89.4296 162.746 91.9948 162.584 98.3193 166.856C108.492 173.721 113.283 171.555 115.229 169.655C115.45 169.434 115.819 169.434 116.04 169.67C116.261 169.891 116.261 170.259 116.025 170.48C114.949 171.541 113.091 172.704 110.172 172.704Z"
          fill="#C0C0C0"
        />
        <path
          d="M113.681 142.27C113.578 142.27 113.475 142.241 113.371 142.182C109.583 139.796 91.9212 139.398 74.8495 139.03C61.4043 138.735 47.5169 138.426 39.7182 137.144C32.9072 136.024 29.2363 132.71 29.8997 128.261C30.6516 123.179 37.4036 117.817 44.6569 118.789C44.9517 118.834 45.1581 119.069 45.1581 119.364C45.1581 119.673 45.0402 126.891 38.8484 132.224C38.6125 132.43 38.2439 132.401 38.0375 132.165C37.8311 131.929 37.8606 131.561 38.0965 131.355C42.9763 127.142 43.8313 121.574 43.9787 119.865C37.5363 119.305 31.713 123.975 31.0496 128.423C30.4894 132.209 33.718 134.979 39.9246 135.995C47.6349 137.262 61.4928 137.571 74.8937 137.866C92.909 138.264 109.937 138.632 114.005 141.195C114.271 141.372 114.359 141.725 114.182 141.99C114.05 142.182 113.873 142.27 113.681 142.27Z"
          fill="#C0C0C0"
        />
        <path
          d="M31.3297 125.742C31.0938 125.742 30.8874 125.595 30.799 125.374C28.5139 119.423 17.2654 111.409 6.98987 112.941C6.68028 112.986 6.38543 112.765 6.34121 112.455C6.29698 112.146 6.51811 111.851 6.82771 111.807C17.7961 110.172 29.4132 118.524 31.8752 124.962C31.9931 125.256 31.8457 125.595 31.5508 125.698C31.4624 125.728 31.3886 125.742 31.3297 125.742Z"
          fill="#C0C0C0"
        />
        <path
          d="M121.45 153.407C121.067 154.276 120.507 154.35 120.286 154.364C117.647 154.659 112.31 149.194 111.042 146.1C110.924 145.806 110.585 145.673 110.29 145.791C109.995 145.909 109.863 146.248 109.981 146.542C111.249 149.636 116.6 155.528 120.094 155.528C120.197 155.528 120.315 155.528 120.418 155.513C121.318 155.41 122.468 154.836 122.851 152.685C122.379 152.891 121.907 153.127 121.45 153.407Z"
          fill="#C0C0C0"
        />
        <path
          d="M119.696 160.713C117.44 160.713 114.669 159.977 111.425 157.929C111.16 157.767 111.072 157.399 111.249 157.134C111.411 156.869 111.765 156.78 112.045 156.957C120.964 162.555 124.885 157.605 125.313 157.016C126.507 155.351 125.962 151.801 124.237 149.989C124.016 149.754 124.03 149.4 124.251 149.179C124.487 148.958 124.841 148.973 125.062 149.194C127.185 151.433 127.76 155.558 126.242 157.679C125.195 159.137 122.998 160.713 119.696 160.713Z"
          fill="#C0C0C0"
        />
        <path
          d="M118.015 165.722C115.539 165.722 112.354 164.352 108.123 162.363C107.828 162.231 107.71 161.892 107.843 161.597C107.976 161.317 108.315 161.185 108.61 161.317C114.418 164.042 118.104 165.56 120.359 163.822C122.556 162.113 122.305 160.33 122.291 160.257C122.246 159.947 122.453 159.653 122.762 159.594C123.072 159.535 123.367 159.756 123.426 160.065C123.441 160.168 123.824 162.584 121.067 164.735C120.183 165.412 119.18 165.722 118.015 165.722Z"
          fill="#C0C0C0"
        />
        <path
          d="M114.919 170.288C112.443 170.288 109.258 168.918 105.027 166.93C104.732 166.797 104.614 166.458 104.747 166.164C104.88 165.884 105.219 165.751 105.514 165.884C111.322 168.609 115.008 170.126 117.264 168.388C119.46 166.679 119.21 164.897 119.195 164.823C119.151 164.514 119.357 164.219 119.667 164.16C119.976 164.101 120.271 164.308 120.33 164.632C120.345 164.735 120.728 167.151 117.971 169.301C117.087 169.994 116.084 170.288 114.919 170.288Z"
          fill="#C0C0C0"
        />
        <path
          d="M37.9784 136.776C37.9637 136.776 37.9342 136.776 37.9195 136.776C37.6099 136.746 37.374 136.452 37.4035 136.142L39.2316 119.585C39.261 119.276 39.5411 119.04 39.8655 119.069C40.1751 119.099 40.4109 119.393 40.3815 119.703L38.5534 136.26C38.5092 136.555 38.2733 136.776 37.9784 136.776Z"
          fill="#C0C0C0"
        />
        <path d="M44.9369 119.246L39.7918 119.629L39.0251 131.09L43.0351 126.243L44.9369 119.246Z" fill="#C0C0C0" />
        <path
          d="M85.9799 100.022C85.1838 100.022 84.2992 99.9487 83.341 99.7867C77.5472 98.8144 71.8124 93.6881 69.8369 89.4751C68.8934 87.4717 68.7902 85.7187 69.542 84.4224C69.7042 84.1425 70.058 84.0542 70.3234 84.2162C70.6035 84.3782 70.6919 84.7318 70.5298 84.9969C69.8369 86.2048 70.3381 87.8252 70.8836 88.9742C72.7411 92.9221 78.1074 97.7243 83.5474 98.6377C86.5253 99.1385 88.5745 98.8292 89.636 97.7096C90.5648 96.7374 90.8006 95.0875 90.3436 92.8043C90.2847 92.4949 90.4763 92.1856 90.7859 92.1266C91.0955 92.0677 91.4051 92.2592 91.464 92.5686C92.0095 95.2496 91.6704 97.253 90.4616 98.5051C89.4886 99.5215 87.9848 100.022 85.9799 100.022Z"
          fill="#C0C0C0"
        />
        <path
          d="M110.526 141.166C110.202 141.166 109.951 140.915 109.951 140.591C109.951 109.288 105.351 96.9584 92.3043 90.2264C92.0242 90.0791 91.9063 89.7403 92.0537 89.4456C92.2011 89.1658 92.5402 89.0479 92.8351 89.1952C106.354 96.1629 111.101 108.743 111.101 140.576C111.101 140.915 110.836 141.166 110.526 141.166Z"
          fill="#C0C0C0"
        />
        <path
          d="M101.754 140.031C101.74 140.031 101.725 140.031 101.71 140.031C101.4 140.002 101.15 139.737 101.179 139.413L102.403 122.634C102.432 122.31 102.713 122.074 103.022 122.104C103.332 122.133 103.582 122.399 103.553 122.723L102.329 139.501C102.3 139.796 102.049 140.031 101.754 140.031Z"
          fill="#C0C0C0"
        />
        <path
          d="M105.351 135.421C104.364 135.421 103.258 135.332 101.99 135.082C101.681 135.023 101.474 134.713 101.533 134.404C101.592 134.095 101.902 133.889 102.211 133.947C107.519 134.964 110.069 133.181 110.099 133.167C110.349 132.975 110.718 133.034 110.895 133.299C111.086 133.55 111.027 133.918 110.777 134.095C110.688 134.154 108.934 135.421 105.351 135.421Z"
          fill="#C0C0C0"
        />
        <path
          d="M102.374 61.0004C102.138 61.0004 101.917 60.8531 101.828 60.6174C101.725 60.3228 101.887 59.984 102.182 59.8808C103.317 59.4831 104.216 58.7908 104.467 58.1279C104.585 57.8185 104.555 57.5239 104.393 57.2146C103.951 56.4044 101.872 56.5959 100.944 56.8168C100.634 56.8905 100.324 56.699 100.251 56.3896C100.177 56.0803 100.369 55.7709 100.678 55.6973C101.061 55.6089 104.423 54.8724 105.41 56.6548C105.735 57.2588 105.794 57.9069 105.558 58.5256C105.175 59.5273 104.069 60.4406 102.58 60.9562C102.506 61.0004 102.433 61.0004 102.374 61.0004Z"
          fill="#C0C0C0"
        />
        <path
          d="M93.0563 52.3829L93.4396 53.4582C93.9261 54.8576 96.108 54.0916 95.6067 52.6922L95.2234 51.6168C94.7222 50.2174 92.555 50.9834 93.0563 52.3829Z"
          fill="#C0C0C0"
        />
        <path
          d="M92.8646 45.2825C92.2897 45.695 91.7884 46.2106 91.184 46.5641C90.5501 46.9471 91.0218 47.993 91.6557 47.61C92.3486 47.1976 92.9236 46.6378 93.587 46.1664C94.2062 45.7392 93.4691 44.8553 92.8646 45.2825Z"
          fill="#C0C0C0"
        />
        <path
          d="M90.4321 85.2915C90.1372 85.2915 89.8866 85.0705 89.8571 84.7759C89.8276 84.4666 90.0488 84.1719 90.3584 84.1425C90.4468 84.1277 99.1744 83.1113 102.727 78.972C105.617 75.5986 105.381 70.9731 101.828 60.6173C101.725 60.3227 101.887 59.9839 102.182 59.8808C102.491 59.7777 102.816 59.9397 102.919 60.2343C106.148 69.6179 107.209 75.4955 103.612 79.7085C99.764 84.2014 90.8744 85.2326 90.4911 85.2767C90.4763 85.2915 90.4468 85.2915 90.4321 85.2915Z"
          fill="#C0C0C0"
        />
        <path
          d="M93.6018 84.054L91.4936 89.858C88.9874 88.93 87.7195 86.1753 87.292 83.7005C89.3707 84.0246 91.4936 84.1424 93.6018 84.054Z"
          fill="#C0C0C0"
        />
        <path
          d="M90.4321 85.2915C85.7588 85.2915 81.6899 75.2304 78.417 67.1579C77.1492 64.0202 75.7192 60.4848 75.0705 59.9692C71.9303 60.912 69.8369 62.2083 69.0851 63.6814C68.7165 64.4032 68.6723 65.1545 68.9376 65.9205C69.0998 66.3919 69.5421 67.2463 70.633 67.7324C71.8419 68.2774 73.4488 68.1743 75.3064 67.4378C75.4538 67.3788 75.616 67.3788 75.7486 67.4525C75.8961 67.5114 75.9993 67.6293 76.0582 67.7766C77.5914 71.8276 77.7684 74.9358 76.589 76.7624C75.9403 77.7788 74.8641 78.368 73.5815 78.4564C69.4536 78.7069 67.3307 76.1142 63.306 65.8321L60.2396 57.1409C57.9987 50.7772 58.8538 41.9534 75.6897 36.0169C86.378 32.2458 93.3954 34.6469 96.565 43.1466C97.3169 45.1648 97.995 46.9472 98.6142 48.6265C99.6019 51.2486 100.487 53.5761 101.386 56.095C101.489 56.3896 101.342 56.7285 101.032 56.8316C100.737 56.9347 100.398 56.7874 100.295 56.478C99.3956 53.9738 98.5257 51.6463 97.538 49.039C96.9041 47.3744 96.2259 45.592 95.4741 43.5591C92.5403 35.6486 86.1863 33.5421 76.0582 37.107C67.257 40.2152 57.5564 46.137 61.301 56.7726L64.3675 65.4491C68.4364 75.8343 70.2202 77.5284 73.5078 77.3369C74.1565 77.2927 75.041 77.057 75.6307 76.1732C76.5447 74.759 76.3973 72.1369 75.1884 68.7488C72.7854 69.5737 71.149 69.2497 70.176 68.8077C69.085 68.3069 68.2595 67.423 67.8762 66.333C67.6108 65.5964 67.4339 64.4474 68.0826 63.1953C68.9966 61.3981 71.3112 59.9398 74.9378 58.8644C76.1909 58.6877 77.0902 60.7647 79.5227 66.7602C82.3238 73.6984 86.5696 84.172 90.4764 84.1867C90.8007 84.1867 91.0513 84.4371 91.0513 84.7612C91.0071 85.0264 90.7417 85.2915 90.4321 85.2915Z"
          fill="#C0C0C0"
        />
        <path
          d="M89.6803 96.87C89.6213 96.87 89.5623 96.8553 89.4886 96.8406C89.1938 96.7374 89.0316 96.4134 89.1348 96.1187L93.0563 84.5403C93.1595 84.2457 93.4838 84.0837 93.7787 84.1868C94.0735 84.2899 94.2357 84.614 94.1325 84.9086L90.211 96.487C90.1373 96.708 89.9161 96.87 89.6803 96.87Z"
          fill="#C0C0C0"
        />
        <path
          d="M85.8915 65.6406C83.4 65.6406 81.9847 60.6616 80.1861 54.3568C79.5669 52.1766 78.9183 49.9376 78.2254 47.89C77.7978 46.6526 77.8273 45.5625 78.3138 44.6345C79.3458 42.6458 82.1911 41.8356 85.1838 40.9812C88.0733 40.1563 91.0661 39.3019 91.8179 37.4016C91.9359 37.107 92.2749 36.9597 92.5698 37.0775C92.8646 37.1954 93.0121 37.5342 92.8941 37.8288C91.9359 40.2447 88.663 41.1874 85.5081 42.086C82.8692 42.8373 80.1566 43.618 79.3458 45.1648C79.0067 45.8129 79.0067 46.5789 79.3311 47.5217C80.0387 49.5987 80.6874 51.8526 81.3065 54.0475C82.7071 58.9528 84.2845 64.4916 85.9062 64.4916H85.9209C86.8055 64.4622 88.6778 63.5783 90.6533 62.6208C94.678 60.6911 99.6904 58.2752 102.668 59.9251C102.948 60.0724 103.052 60.4259 102.889 60.7058C102.742 60.9857 102.388 61.0888 102.108 60.9268C99.6461 59.5568 94.7369 61.9284 91.1545 63.652C88.8842 64.742 87.1003 65.6112 85.9652 65.6406C85.9209 65.6406 85.9062 65.6406 85.8915 65.6406Z"
          fill="#C0C0C0"
        />
        <path
          d="M70.0286 88.3555C69.9843 88.3555 69.9254 88.3555 69.8811 88.3407C69.5715 88.2523 69.3946 87.943 69.4831 87.6336L72.181 77.7345C72.2694 77.4252 72.579 77.2484 72.8886 77.3368C73.1982 77.4252 73.3751 77.7345 73.2866 78.0439L70.5888 87.943C70.5151 88.1787 70.2792 88.3555 70.0286 88.3555Z"
          fill="#C0C0C0"
        />
        <path
          d="M98.7321 65.2281C98.3488 64.9924 97.9507 64.7862 97.5379 64.5947C96.8745 64.2853 96.4765 65.3607 97.1546 65.67C97.4789 65.8173 97.7885 65.9941 98.0834 66.1709C98.7026 66.5539 99.366 65.6258 98.7321 65.2281Z"
          fill="#C0C0C0"
        />
      </svg> */}
      <Text>No feedback, yet</Text>
    </Flex>
  )
}

import { actionStatusMap } from '@mm/common'
import { Action } from '../../gen/graphql/documents'

export const getCurrentStatus = (action: Pick<Action, 'status'>) => {
  if (action.status === actionStatusMap.ACTIVE) {
    return 'active'
  }

  if (action.status === actionStatusMap.DONE) {
    return 'resolved'
  }

  if (action.status === actionStatusMap.ON_HOLD) {
    return 'on-hold'
  }

  if (action.status === actionStatusMap.CANCELLED) {
    return 'canceled'
  }

  return 'active'
}

export const statusCheckboxToActionStatus = (val: string) => {
  if (val === 'active') {
    return actionStatusMap.ACTIVE
  }

  if (val === 'resolved') {
    return actionStatusMap.DONE
  }

  if (val === 'on-hold') {
    return actionStatusMap.ON_HOLD
  }

  if (val === 'canceled') {
    return actionStatusMap.CANCELLED
  }

  return actionStatusMap.ACTIVE
}

import { Suspender, Tag, TagSelect } from '@mm/company-ui'
import {
  CheckIcon,
  CommentChatIcon,
  DecisionIcon,
  DeleteIcon,
  LightbulbIdeaFeedbackIcon,
  StopwatchTimerIcon,
} from '@mm/company-ui-icons'
import React from 'react'
import { DecisionStatusPickerDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { editDecisionStatusCommand } from '../commands/editDecisionStatus'

export type DecisionStatusPickerProps = {
  decisionId: string
  cached?: boolean
  disabled?: boolean
}

export const DecisionStatusPicker = ({ decisionId, disabled = false, cached }: DecisionStatusPickerProps) => {
  const { data, loading, error } = useQuery(DecisionStatusPickerDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      decisionId,
    },
  })

  const editDecisionStatus = useCommand(editDecisionStatusCommand)

  const decision = data?.decision

  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('decision not found')
  }

  return (
    <TagSelect
      value={decision.status}
      onChange={(status) => {
        void editDecisionStatus.execute({
          id: decisionId,
          status,
        })
        capture('More Menu Selected', {
          parent: 'Decision',
          menuItem: 'Status',
          status: status,
        })
      }}
    >
      <Tag disabled={disabled} value="WAITING_FOR_COMMENTS" variant="warning">
        <LightbulbIdeaFeedbackIcon />
        Proposal
      </Tag>
      <Tag disabled={disabled} value="WAITING_FOR_DECISION" variant="pastel-orange">
        <CommentChatIcon />
        Comments in
      </Tag>
      <Tag disabled={disabled} value="IMPLEMENTING" variant="alert">
        <DecisionIcon />
        Decided
      </Tag>
      <Tag disabled={disabled} value="RESOLVED" variant="success">
        <CheckIcon />
        Resolved
      </Tag>
      <Tag disabled={disabled} value="ON_HOLD" variant="default">
        <StopwatchTimerIcon />
        On hold
      </Tag>
      <Tag disabled={disabled} value="CANCELLED" variant="default">
        <DeleteIcon />
        Cancelled
      </Tag>
    </TagSelect>
  )
}

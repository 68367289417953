import { InputRule } from '@tiptap/core'
import { BulletList } from '@tiptap/extension-bullet-list'
import { canJoin, findWrapping } from 'prosemirror-transform'

export const inputRegex = /^\s*([-+*])\s$/

export const BulletListExtension = BulletList.extend({
  addInputRules() {
    return [
      new InputRule({
        find: inputRegex,
        handler: ({ state, range }) => {
          const tr = state.tr

          const doWrap = (dryRun?: boolean) => {
            const $start = tr.doc.resolve(range.from)
            const blockRange = $start.blockRange()
            const wrapping = blockRange && findWrapping(blockRange, this.type)
            if (blockRange && wrapping) {
              if (!dryRun) tr.wrap(blockRange, wrapping)
              return true
            } else {
              return false
            }
          }

          if (!doWrap(true)) {
            return
          }

          tr.delete(range.from, range.to)
          doWrap()

          const before = tr.doc.resolve(range.from - 1).nodeBefore
          if (before && before.type === this.type && canJoin(tr.doc, range.from - 1)) {
            tr.join(range.from - 1)
          }
        },
      }),
    ]
  },
})

import { Flex, formatDateDisplay, Suspender, Tag, UserSelectTarget } from '@mm/company-ui'
import React from 'react'
import { ActionMetadataSummaryDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { ActionPrivacyTag } from './ActionPrivacyTag'
import { ActionStatusTagSelect } from './ActionStatusTagSelect'

export type ActionMetadataSummaryProps = {
  actionId: string
  cached?: boolean
}

export const ActionMetadataSummary = ({ actionId, cached }: ActionMetadataSummaryProps) => {
  const { data, loading, error } = useQuery(ActionMetadataSummaryDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id: actionId,
    },
  })

  const action = data?.action

  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Action not found')
  }

  return (
    <Flex row gap={2} align="center">
      <ActionStatusTagSelect actionId={actionId} />

      {action.dueAt ? (
        <Tag>
          Due
          {formatDateDisplay(action.dueAt)}
        </Tag>
      ) : null}

      {action.assignee ? (
        <Tag>
          Assignee
          <UserSelectTarget value={{ node: action.assignee }} disabled />
        </Tag>
      ) : null}

      {action.waitingFor ? (
        <Tag>
          Waiting For
          <UserSelectTarget value={{ node: action.waitingFor }} disabled />
        </Tag>
      ) : null}

      <ActionPrivacyTag actionId={actionId} />
    </Flex>
  )
}

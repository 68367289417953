import { Extension } from '@tiptap/core'

export const TabExtension = Extension.create({
  name: 'tab-behavior',
  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        return editor.commands.insertContent('\t')
      },
    }
  },
})

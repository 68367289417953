import { NotFoundBanner, Suspender } from '@mm/company-ui'
import React from 'react'
import { SinglePrototypeFeedbackDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useSyncActiveCompanyWithResource } from '../../companies'
import { SinglePrototypeFeedbackPage } from './SinglePrototypeFeedbackPage'

export type SinglePrototypeFeedbackProps = {
  id: string
}

export const SinglePrototypeFeedback = ({ id }: SinglePrototypeFeedbackProps) => {
  const { data, loading } = useQuery(SinglePrototypeFeedbackDocument, {
    variables: {
      id,
    },
  })

  // useSubscription(PrototypeFeedbackUpdatedSubscription, {
  //   variables: {
  //     id,
  //   },
  // })

  useSyncActiveCompanyWithResource(data?.prototypeFeedback?.company?.id)

  if (data?.prototypeFeedback == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return <SinglePrototypeFeedbackPage prototypeFeedbackId={id} />
}

import { isGraphQLError } from '@mm/graphql-helpers/src'
import { DeleteTopicCommandDocument, GetTopicCommandDocument } from '../../../gen/graphql/documents'
import { evictCache } from '../../apollo'
import { Command } from '../../commands'

export type DeleteTopicCommandValue = {
  topicId: string
  onTopicDeleted?: () => void
}

export const deleteTopicCommand: Command<DeleteTopicCommandValue> = {
  id: 'deleteTopicCommand',
  name: 'Delete Topic',
  canExecute: async ({ topicId }, { apolloClient }) => {
    const query = await apolloClient.query({ query: GetTopicCommandDocument, variables: { id: topicId } })
    return !!query.data.topic
  },
  execute: async ({ topicId, onTopicDeleted }, { apolloClient }) => {
    const { data } = await apolloClient.mutate({
      mutation: DeleteTopicCommandDocument,
      variables: {
        id: topicId,
      },
      update: (cache, { data }) => {
        if (data?.deleteTopic.__typename === 'Success') {
          evictCache(cache, 'Topic', topicId)
          onTopicDeleted?.()
        }
      },
      optimisticResponse: {
        deleteTopic: {
          __typename: 'Success',
        },
      },
    })
    if (data?.deleteTopic && isGraphQLError(data?.deleteTopic)) {
      alert(`Delete topic failed: ${data?.deleteTopic.message ?? 'unknown error'}`)
    } else if (data?.deleteTopic.__typename !== 'Success') {
      alert('Delete topic failed: unknown error')
    }
  },
}

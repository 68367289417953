import { Suspender, useFeatureFlags } from '@mm/company-ui'
import { FilePageTemplateIcon, LinkIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { ActionMetadataMeetingsDocument, RemoveActionMeetingContextDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { PanelMetadataItemChips } from '../../components'
import { PanelMetadataLinkChips } from '../../components/PanelMetadataLinkChips'

export const ActionMetadataMeetings = ({ actionId, isLink }: { actionId: string; isLink?: boolean }) => {
  const { updateActionContext } = useFeatureFlags()
  const { data, loading, error, fetchMore } = useQuery(ActionMetadataMeetingsDocument, {
    variables: {
      id: actionId,
    },
  })

  const parentMeeting = data?.action?.parentMeeting
  const meetings = data?.action?.meetings
  const [removeActionContext] = useMutation(RemoveActionMeetingContextDocument, {
    optimisticResponse: ({ id, contextRef }) => {
      return {
        removeActionContext: {
          __typename: 'Action' as const,
          id,
          parentMeeting: parentMeeting,
          meetings: {
            __typename: 'MeetingsConnection' as const,
            edges: meetings?.edges.filter(({ node }) => node.id !== contextRef) || [],
            pageInfo: {
              hasNextPage: meetings?.pageInfo.hasNextPage ?? false,
              endCursor: meetings?.pageInfo.endCursor ?? '',
              __typename: meetings?.pageInfo.__typename,
            },
          },
        },
      }
    },
  })

  const handleRemove = useCallback(
    async (id: string) => {
      await removeActionContext({
        variables: {
          id: actionId,
          contextRef: id,
        },
      })
    },
    [actionId, removeActionContext],
  )

  if (error) {
    throw error
  }

  if (loading) {
    return <Suspender />
  }
  if (isLink) {
    return meetings?.edges.length ? (
      <PanelMetadataLinkChips
        icon={<FilePageTemplateIcon color="text-light" />}
        chips={meetings.edges.map(({ node }) => ({
          id: node.id,
          label: node.title,
          href: { pathname: '/meetings/[id]', query: { id: node.id } },
          type: 'Meeting',
        }))}
        hasNextPage={meetings.pageInfo.hasNextPage}
        onLoadMore={() => fetchMore({ variables: { after: meetings.pageInfo.endCursor } })}
        onRemove={handleRemove}
      />
    ) : null
  }

  return parentMeeting && !updateActionContext ? (
    <PanelMetadataItemChips
      title="Meeting"
      icon={<LinkIcon />}
      chips={[
        {
          id: parentMeeting.id,
          label: parentMeeting.title,
          href: { pathname: '/meetings/[id]', query: { id: parentMeeting.id } },
        },
      ]}
    />
  ) : meetings?.edges.length ? (
    <PanelMetadataItemChips
      title="Meetings"
      icon={<LinkIcon />}
      chips={meetings.edges.map(({ node }) => ({
        id: node.id,
        label: node.title,
        href: { pathname: '/meetings/[id]', query: { id: node.id } },
        __typename: node.__typename,
      }))}
      hasNextPage={meetings.pageInfo.hasNextPage}
      onLoadMore={() => fetchMore({ variables: { after: meetings.pageInfo.endCursor } })}
      isRemovable={updateActionContext}
      onRemove={handleRemove}
    />
  ) : null
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ModeratorPresenterIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="moderator-presenter" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5297 1.05667C14.2847 0.757628 13.8436 0.713835 13.5446 0.958854C13.2456 1.20387 13.2018 1.64492 13.4468 1.94396C14.1373 2.78675 14.6 4.05218 14.6 5.50002C14.6 6.94792 14.1373 8.21393 13.4468 9.05667C13.2018 9.35571 13.2456 9.79675 13.5446 10.0418C13.8436 10.2868 14.2847 10.243 14.5297 9.94396C15.4497 8.82114 16 7.23065 16 5.50002C16 3.76932 15.4497 2.17944 14.5297 1.05667ZM3.4 5.9998C3.4 5.00569 4.20589 4.1998 5.2 4.1998C6.19411 4.1998 7 5.00569 7 5.9998C7 6.99392 6.19411 7.7998 5.2 7.7998C4.20589 7.7998 3.4 6.99392 3.4 5.9998ZM5.2 2.7998C3.43269 2.7998 2 4.23249 2 5.9998C2 7.76712 3.43269 9.1998 5.2 9.1998C6.96731 9.1998 8.4 7.76712 8.4 5.9998C8.4 4.23249 6.96731 2.7998 5.2 2.7998ZM1.4 15.4998C1.4 14.2177 1.79438 13.4414 2.37967 12.9652C2.99181 12.4672 3.93104 12.1998 5.2 12.1998C6.43497 12.1998 7.37805 12.4743 8.00083 12.981C8.59851 13.4672 9 14.248 9 15.4998C9 15.8864 9.3134 16.1998 9.7 16.1998C10.0866 16.1998 10.4 15.8864 10.4 15.4998C10.4 13.9201 9.87494 12.7009 8.88434 11.895C7.91885 11.1095 6.61193 10.7998 5.2 10.7998C3.77004 10.7998 2.45927 11.0956 1.49614 11.8792C0.506162 12.6846 0 13.9083 0 15.4998C0 15.8864 0.313401 16.1998 0.7 16.1998C1.0866 16.1998 1.4 15.8864 1.4 15.4998ZM10.7996 3.1786C11.1078 2.94522 11.5468 3.00588 11.7802 3.31409C12.231 3.90935 12.4938 4.67606 12.4938 5.49999C12.4938 6.32445 12.2301 7.09085 11.7799 7.68618C11.5468 7.99455 11.1078 8.05551 10.7994 7.82234C10.491 7.58918 10.4301 7.15017 10.6632 6.8418C10.9249 6.4958 11.0938 6.02753 11.0938 5.49999C11.0938 4.97193 10.9251 4.50397 10.6641 4.15923C10.4307 3.85102 10.4914 3.41198 10.7996 3.1786Z"
    />
  </IconBase>
)

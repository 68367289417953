import { AddWaitingForRowData } from '@mm/company-ui/src'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { ActionStatus, CreateActionInput, GoalActionsDocument } from '../../../gen/graphql/documents'
import { CapabilitiesProvider, RegisterCapability } from '../../capabilities'
import { useCommand } from '../../commands'
import { ADD_ACTION } from '../capabilities'
import { createActionOnGoalCommand } from '../commands/createActionOnGoal'
import { ActionsDashboardFlat, createDataSource } from './ActionsDashboard'
import { ActionSectionWithFilterAndDisplay } from './ActionsDashboard/ActionSectionWithFilterAndDisplay'

export type GoalActionsProps = {
  goalId: string
  status?: ActionStatus
  renderEmptyPlaceholder?: () => React.ReactElement
  skipRegisteringCapabilities?: boolean
  addRowForcedOpen?: boolean
  isFiltered?: boolean
}

export const GoalActions = ({ goalId, status, addRowForcedOpen, isFiltered, ...rest }: GoalActionsProps) => {
  const createActionOnGoal = useCommand(createActionOnGoalCommand)
  const dataSource = useGoalActionsDataSource({ goalId, status })

  const handleCreateAction = useCallback(
    (actionPayload: CreateActionInput) =>
      createActionOnGoal.execute({
        goalId,
        data: actionPayload,
      }),
    [createActionOnGoal, goalId],
  )

  const handleOnAdd = useCallback(
    async (data: AddWaitingForRowData) => {
      const actionPayload = {
        title: data.title,
        dateRescheduled: false,
        description: data.description,
        dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
        assignee: data.assignee,
        waitingFor: data.waitingFor,
        repeat: data.repeat,
      }

      void createActionOnGoal.execute({
        goalId,
        data: actionPayload,
      })
    },
    [createActionOnGoal, goalId],
  )

  return (
    <CapabilitiesProvider>
      <RegisterCapability capability={ADD_ACTION} handler={handleOnAdd} />

      {isFiltered ? (
        <ActionSectionWithFilterAndDisplay
          skipRegisteringCapabilities
          pageKey="goalActions"
          dataSource={dataSource}
          onCreateAction={handleCreateAction}
          {...rest}
        />
      ) : (
        <ActionsDashboardFlat
          skipRegisteringCapabilities
          dataSource={dataSource}
          onCreateAction={handleCreateAction}
          addRowForcedOpen={addRowForcedOpen || undefined}
          {...rest}
        />
      )}
    </CapabilitiesProvider>
  )
}

export type Options = {
  goalId: string
  status?: ActionStatus[] | ActionStatus
  assignee?: string
  createdAfter?: Date
}
export const useGoalActionsDataSource = ({ goalId, status, assignee, createdAfter }: Options) =>
  useMemo(
    () =>
      createDataSource({
        query: GoalActionsDocument,
        extractActions: (data) => data?.goal?.actions,
        matchAction: (action) => action.parent?.__typename === 'Goal' && action.parent.id === goalId,
        variables: {
          goalId,
          status,
          assignee,
          createdAfter: createdAfter?.getTime(),
        },
      }),
    [goalId, status, assignee, createdAfter],
  )

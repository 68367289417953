import React, { createContext, useContext } from 'react'
import { DecidedCommentsDataFragmentFragment } from '../../gen/graphql/documents'

type ContextValue = DecidedCommentsDataFragmentFragment['decidedComments'] | null | undefined

const DecidedCommentContext = createContext<ContextValue>(undefined)

type DecidedCommentProviderProps = {
  children?: React.ReactNode
  value?: ContextValue
}

export const DecidedCommentProvider = ({ children, value }: DecidedCommentProviderProps) => {
  return <DecidedCommentContext.Provider value={value}>{children}</DecidedCommentContext.Provider>
}

export const useGetDecidedCommentIds = () => {
  return [useContext(DecidedCommentContext)?.map((decidedComment) => decidedComment.comment.id)]
}

export const useGetDecidedComments = () => {
  return [useContext(DecidedCommentContext)]
}

import { GoalStatus, openGoalStatuses } from '@mm/common'
import { Flex, Text } from '@mm/company-ui'
import React from 'react'
import { UpdateGoalStatus } from '../../../gen/graphql/documents'

const goalStatusToReadableLabel: Record<GoalStatus, string> = {
  AT_RISK: 'At risk',
  BEHIND: 'Off Track',
  CANCELLED: 'Cancelled',
  DONE: 'Completed',
  ON_TRACK: 'On Track',
}

const goalStatusToColor: Record<GoalStatus, { color: string; background: string }> = {
  AT_RISK: {
    color: 'system-text-alert-light',
    background: 'system-background-alert-light',
  },
  BEHIND: {
    color: 'system-text-warning',
    background: 'system-background-warning-light',
  },
  CANCELLED: {
    color: 'text-light',
    background: 'background-light-alpha',
  },
  DONE: {
    color: 'text-light',
    background: 'background-light-alpha',
  },
  ON_TRACK: {
    color: 'system-text-success',
    background: 'system-background-success-light',
  },
}

export type GoalRowStatusIndicatorProps = {
  status: GoalStatus | UpdateGoalStatus
  width?: number
  startDate: number
  endDate: number
  progressDate: number
  hasIndicator?: boolean
}

export function GoalRowStatusIndicator({
  status,
  width = 300,
  startDate,
  endDate,
  progressDate,
  hasIndicator = true,
}: GoalRowStatusIndicatorProps) {
  return (
    <Flex row align="center" gap={2}>
      {openGoalStatuses.includes(status) && hasIndicator ? (
        <div
          sx={{
            width,
            height: 1,
            padding: 0.25,
            backgroundColor: goalStatusToColor[status].background,
            borderRadius: '4px',
          }}
        >
          <div
            sx={{
              width: `${Math.min(
                Math.round((Math.abs(progressDate - startDate) / Math.abs(endDate - startDate)) * 100),
                100,
              )}%`,
              height: 0.5,
              backgroundColor: goalStatusToColor[status].color,
              borderRadius: '4px',
            }}
          />
        </div>
      ) : null}

      <Text bold uppercase color={goalStatusToColor[status].color} sx={{ fontSize: 10 }}>
        {goalStatusToReadableLabel[status]}
      </Text>
    </Flex>
  )
}

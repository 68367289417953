import {
  AddWaitingForRowData,
  Button,
  defaultItems,
  Editable,
  EditableFormController,
  Flex,
  Modal,
  MODAL_SECTION_PADDING,
  ModalSection,
  Suspender,
  Text,
  useBeforeUnload,
  useFeatureFlags,
} from '@mm/company-ui'
import { LoadingIcon } from '@mm/company-ui-icons'
import { useId } from '@react-aria/utils'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  GoalNotDoneModalAddCommentDocument,
  GoalNotDoneModalDocument,
  GoalStatus,
} from '../../../gen/graphql/documents'
import { GoalActions } from '../../actions'
import { ADD_ACTION } from '../../actions/capabilities'
import { createActionOnGoalCommand } from '../../actions/commands/createActionOnGoal'
import { capture } from '../../analytics'
import { useMutation, useQuery } from '../../apollo'
import { CapabilitiesProvider, RegisterCapability } from '../../capabilities'
import { useCommand } from '../../commands'
import { addCommentToCache } from '../../comments'
import { GoalDecisions } from '../../decisions'
import { useComplexToolbar } from '../../editor/hooks/useComplexToolbar'
import { editGoalStatusCommand } from '../commands/editGoalStatusCommand'
import { GoalStatusRadio } from './GoalStatusRadio'

type GoalNotDoneDescriptionProps = {
  isSubmitting: boolean
  updateTextId: string
  props: any
}

const GoalNotDoneDescription = ({ isSubmitting, updateTextId, props }: GoalNotDoneDescriptionProps) => {
  const selectionToolbar = useComplexToolbar(defaultItems)

  return (
    <Editable
      {...props}
      data-testid="goal-not-done-description"
      hidePlaceholderWhenFocused
      sx={{
        minHeight: 10,
        maxHeight: '70vh',
        overflowY: 'auto',
      }}
      editable={!isSubmitting}
      selectionToolbar={selectionToolbar}
      aria-labelledby={updateTextId}
    />
  )
}

export type GoalNotDoneModalProps = {
  goalId: string
  onRequestClose?: () => void
  initialStatus?: GoalStatus
}

const initialUpdateHtml = `
<p><b>Good:</b></p>
<ul>
  <li></li>
</ul>
<p><b>Not Good:</b></p>
<ul>
  <li></li>
</ul>
`

const goalStatusToReadableLabel: Record<GoalStatus, string> = {
  AT_RISK: 'At risk',
  BEHIND: 'Off Track',
  CANCELLED: 'Cancelled',
  DONE: 'Completed',
  ON_TRACK: 'On Track',
}

export const GoalNotDoneModal = ({ goalId, onRequestClose, initialStatus }: GoalNotDoneModalProps) => {
  const { descriptionActions } = useFeatureFlags()
  const addAction = useCommand(createActionOnGoalCommand)
  const { data, loading, error } = useQuery(GoalNotDoneModalDocument, {
    variables: {
      id: goalId,
    },
  })
  const goal = data?.goal

  const handleOnAddAction = useCallback(
    async (data: AddWaitingForRowData) => {
      void addAction.execute({
        goalId,
        data: {
          title: data.title,
          assignee: data.assignee,
          dateRescheduled: false,
          description: data.description,
          dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
          waitingFor: data.waitingFor,
          repeat: data.repeat,
        },
      })
    },
    [goalId, addAction],
  )

  const [addComment] = useMutation(GoalNotDoneModalAddCommentDocument)
  const editGoalStatus = useCommand(editGoalStatusCommand)

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      status: initialStatus ?? null,
      description: initialUpdateHtml,
    },
  })

  const isOffTrack = form.watch('status') === 'BEHIND'
  const { isSubmitting, isValid, isDirty } = form.formState

  const updateTextId = useId()

  useBeforeUnload(isDirty)

  const handleRequestClose = () => {
    if (isDirty) {
      const accept = window.confirm(
        'You are in the middle of creating a goal update. Are you sure you want to navigate away?',
      )

      // If the user doesn't want to navigate away, we don't want to close the modal
      if (!accept) {
        return
      }
    }

    onRequestClose?.()
    return
  }

  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Goal not found')
  }

  const handleSubmit = form.handleSubmit(async ({ status, description }) => {
    if (status == null) {
      throw new Error('status is required')
    }

    capture('Goal Not Done Submitted', {})

    const { data } = await addComment({
      variables: {
        goalId,
        htmlBody: `<p><strong>Status:</strong> ${goalStatusToReadableLabel[status]}</p>${description}`,
      },
      update: (cache, { data }) => {
        if (data?.createCommentOnGoal.__typename === 'Comment') {
          addCommentToCache(cache, data.createCommentOnGoal)
        }
      },
    })

    if (data?.createCommentOnGoal.__typename !== 'Comment') {
      throw new Error('failed to create a comment')
    }

    await editGoalStatus.execute({
      goalId,
      status,
      skipNotDoneFlow: true,
    })

    onRequestClose?.()
  })

  return (
    <Modal isOpen title={goal.title} onRequestClose={handleRequestClose}>
      <FormProvider {...form}>
        {/** Scroll container when it becomes too large (when adding blocked actions). Ensure that submit button is always visible. */}
        <div sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
          <CapabilitiesProvider>
            <RegisterCapability capability={ADD_ACTION} handler={handleOnAddAction} skip={!descriptionActions} />
            <ModalSection column gap={1}>
              <Text bold>What is the status of this goal?</Text>
              <Controller
                control={form.control}
                name="status"
                rules={{ required: true }}
                render={({ field }) => <GoalStatusRadio status={field.value} onChange={field.onChange} />}
              />
            </ModalSection>
            <ModalSection column gap={1}>
              <Text bold id={updateTextId}>
                Provide an update on the status of your goal:
              </Text>
              <EditableFormController {...form.register('description')}>
                {(field) => (
                  <GoalNotDoneDescription props={field} isSubmitting={isSubmitting} updateTextId={updateTextId} />
                )}
              </EditableFormController>
            </ModalSection>
            <ModalSection column sx={{ px: 0 }}>
              <div sx={{ px: MODAL_SECTION_PADDING }}>
                <Text bold>What next actions will help you complete your goal?</Text>
              </div>
              <React.Suspense
                fallback={
                  <Flex justify="center" sx={{ py: 1 }}>
                    <LoadingIcon />
                  </Flex>
                }
              >
                <GoalActions
                  goalId={goalId}
                  status="ACTIVE"
                  addRowForcedOpen
                  renderEmptyPlaceholder={() => (
                    <div sx={{ px: 4 }}>
                      <Text variant="small" color="text-light">
                        No open actions
                      </Text>
                    </div>
                  )}
                  skipRegisteringCapabilities
                />
              </React.Suspense>
            </ModalSection>
            {isOffTrack && (
              <ModalSection column sx={{ px: 0 }}>
                <div sx={{ px: MODAL_SECTION_PADDING }}>
                  <Text bold>Since this goal is off track, please create an IPS explaining why:</Text>
                </div>
                <React.Suspense
                  fallback={
                    <Flex justify="center" sx={{ py: 1 }}>
                      <LoadingIcon />
                    </Flex>
                  }
                >
                  <GoalDecisions
                    goalId={goalId}
                    variant="open"
                    renderEmptyPlaceholder={() => (
                      <div sx={{ px: 4 }}>
                        <Text variant="small" color="text-light">
                          No open issues
                        </Text>
                      </div>
                    )}
                  />
                </React.Suspense>
              </ModalSection>
            )}
          </CapabilitiesProvider>
        </div>
        <ModalSection justify="flex-end" sx={{ paddingY: 1 }}>
          <Button type="submit" variant="accent" loading={isSubmitting} disabled={!isValid} onClick={handleSubmit}>
            Update Goal
          </Button>
        </ModalSection>
      </FormProvider>
    </Modal>
  )
}

import { Suspender, Tag, TagSelect } from '@mm/company-ui'
import { CircleCheckmarkIcon, CloseCircleIcon, ExclamationCircleIcon, RadioCircleEmptyIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { ActionStatus, ActionStatusCheckboxDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useQuery } from '../../apollo'
import { useEditAction } from '../../DoneNotDone'

export type ActionStatusTagSelectProps = {
  actionId: string
  className?: string
}

export const ActionStatusTagSelect = ({ actionId }: ActionStatusTagSelectProps) => {
  const editAction = useEditAction(actionId)
  const { data, loading } = useQuery(ActionStatusCheckboxDocument, {
    variables: { id: actionId },
  })

  const handleChange = useCallback(
    (status: ActionStatus) => {
      void editAction?.({ status })
      capture('More Menu Selected', {
        menuItem: 'Status',
        status: status,
        parent: 'Action',
      })
    },
    [editAction],
  )

  if (data?.action == null) {
    if (loading) {
      return <Suspender />
    }
    return null
  }

  return (
    <TagSelect value={data.action.status} onChange={handleChange}>
      <Tag value="ACTIVE" variant="default">
        <RadioCircleEmptyIcon />
        To do
      </Tag>
      <Tag value="DONE" variant="success">
        <CircleCheckmarkIcon />
        Done
      </Tag>
      <Tag value="ON_HOLD" variant="alert">
        <ExclamationCircleIcon />
        Blocked
      </Tag>
      <Tag value="CANCELLED" variant="default">
        <CloseCircleIcon />
        Canceled
      </Tag>
    </TagSelect>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const EditIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="edit" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 0.766666C13.572 0.766666 13.8009 0.861488 13.9697 1.03027L17.3031 4.3636C17.6545 4.71508 17.6545 5.28492 17.3031 5.6364L14.8071 8.13237L14.8031 8.1364L14.7991 8.1404L7.30305 15.6364C7.17741 15.762 7.01739 15.8477 6.84316 15.8825L2.67649 16.7159C2.38142 16.7749 2.07638 16.6825 1.86359 16.4697C1.65081 16.2569 1.55845 15.9519 1.61747 15.6568L2.4508 11.4902C2.48565 11.3159 2.57129 11.1559 2.69693 11.0303L12.6969 1.03027C12.8657 0.861488 13.0946 0.766666 13.3333 0.766666ZM15.3939 5L14.1667 6.2272L12.1061 4.16665L13.3333 2.93946L15.3939 5ZM4.16241 12.1104L10.8333 5.43945L12.8939 7.49999L6.22295 14.1709L3.64727 14.6861L4.16241 12.1104ZM1.66666 18.2667C1.16961 18.2667 0.766663 18.6696 0.766663 19.1667C0.766663 19.6637 1.16961 20.0667 1.66666 20.0667H18.3333C18.8304 20.0667 19.2333 19.6637 19.2333 19.1667C19.2333 18.6696 18.8304 18.2667 18.3333 18.2667H1.66666Z"
    />
  </IconBase>
)

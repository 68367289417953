import { Suspender, Flyout, Tag, Tooltip } from '@mm/company-ui'
import { LockedIcon, UnlockedIcon } from '@mm/company-ui-icons'
import React from 'react'
import { DecisionPrivacyTagDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { DecisionPrivacyMenu } from './DecisionPrivacyMenu'

export type DecisionPrivacyTagProps = {
  decisionId: string
  className?: string
  disabled?: boolean
  showPrivacyLabel?: boolean
}

export const DecisionPrivacyTag = ({
  decisionId,
  className,
  disabled,
  showPrivacyLabel = true,
}: DecisionPrivacyTagProps) => {
  const { data, loading, error } = useQuery(DecisionPrivacyTagDocument, {
    variables: { id: decisionId },
    fetchPolicy: 'cache-only',
  })

  const decision = data?.decision

  const isParentMeetingPublic = decision?.parentMeeting?.privacyLevel === 'PUBLIC'
  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('decision not found: ' + decisionId)
  }

  return (
    <Flyout
      disabled={isParentMeetingPublic || disabled}
      backdrop={false}
      position="right-start"
      trigger="click"
      sx={{ maxWidth: '300px', fontSize: 1, lineHeight: 1.5 }}
      content={<DecisionPrivacyMenu decisionId={decisionId} />}
    >
      <Tooltip
        position="bottom-start"
        description={
          isParentMeetingPublic
            ? "This issue is public because it's part of a public Meeting. To change its visibility, update the privacy setting of the Meeting"
            : decision.isPrivate
            ? 'Private - Visible to participants of this issue and participants of any meeting this issue is linked to'
            : 'Public - Visible to everyone at the company'
        }
        sx={{ maxWidth: '300px', fontSize: 1, lineHeight: 1.5 }}
      >
        <Tag className={className}>
          {decision.isPrivate ? <LockedIcon /> : <UnlockedIcon />}
          {showPrivacyLabel && (decision.isPrivate ? 'Private' : 'Public ')}
        </Tag>
      </Tooltip>
    </Flyout>
  )
}

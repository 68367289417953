import { useCallback, useEffect, useRef } from 'react'
import { useDebouncedExecutor } from './useDebouncedExecutor'

export type UseDebouncedCallbackControls = {
  flush: () => void
  cancel: () => void
}

export type UseDebouncedCallbackOptions = {
  timeout: number
  onUnmount?: 'cancel' | 'flush'
}

export type UseDebouncedCallbackResult<F extends (...args: any[]) => any> = [F, UseDebouncedCallbackControls]

export const useDebouncedCallback = <A extends any[]>(
  callback: ((...args: A) => void) | undefined | null,
  { timeout, onUnmount = 'cancel' }: UseDebouncedCallbackOptions,
): UseDebouncedCallbackResult<(...args: A) => void> => {
  const callbackRef = useRef(callback)
  const [execute, controls] = useDebouncedExecutor({ timeout, onUnmount })

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  return [
    useCallback(
      (...args) => {
        execute(() => callbackRef.current?.(...args))
      },
      [execute],
    ),
    controls,
  ]
}

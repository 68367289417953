import styled from '@emotion/styled'
import { parseEmoji, utils } from '@mm/common'
import { Flex, Popover, PopupWrapper, Pressable, Text } from '@mm/company-ui'
import { AddEmojiReactionIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import React, { useState } from 'react'
import { css } from 'theme-ui'
import { useActiveUser } from '../../users'
import { Emoji } from './Emoji'
import { EmojiPicker } from './EmojiPicker'
import { EmojiReactionPill } from './EmojiReactionPill'

type Reaction = {
  emoji: string
  user: {
    id: string
    firstName?: string | null
  }
}

export type EmojiReactionsProps = {
  reactions?: Array<Reaction> | null
  onAdd: (emoji: string) => void
  onRemove: (emoji: string) => void
  testid?: string
  hasAddButton?: boolean
}

export const EmojiReactions = ({
  reactions,
  onAdd,
  onRemove,
  testid = 'EmojiReactions',
  hasAddButton = true,
}: EmojiReactionsProps) => {
  const activeUser = useActiveUser()
  const [pickingEmoji, setPickingEmoji] = useState(false)

  const emojis = _(reactions)
    .groupBy(({ emoji }) => parseEmoji(emoji)?.id)
    .mapValues((reactions) => ({
      emojis: _.uniq(reactions.map(({ emoji }) => emoji)),
      active: reactions.some(({ user }) => user.id === activeUser?.id),
      count: reactions.length,
      names: _(reactions)
        .map(({ user }) => user)
        .uniqBy('id')
        .map('firstName')
        .filter(utils.isNonNil)
        .value(),
    }))
    .entries()
    .value()

  return (
    <Flex gap={0.5} data-testid={testid} wrap>
      {emojis.map(([emojiId, { emojis, count, active, names }]) => (
        <EmojiReactionPill
          key={emojiId}
          emojis={emojis}
          count={count}
          active={active}
          tooltip={
            utils.isNonEmptyArray(names) && (
              <PopupWrapper>
                <Flex column align="center" gap={1}>
                  <div sx={{ fontSize: '32px', lineHeight: 1, padding: 0.5 }}>
                    <Emoji shortcodes={`:${emojiId}:`} />
                  </div>
                  <div sx={{ width: '150px', textAlign: 'center' }}>
                    <Text>
                      {names.length === 1 ? names[0] : utils.init(names).join(', ') + ', and ' + utils.last(names)}
                    </Text>
                    <Text> </Text>
                    <Text color="text-light">reacted with :{emojiId}:</Text>
                  </div>
                </Flex>
              </PopupWrapper>
            )
          }
          onClick={() => {
            const emoji = `:${emojiId}:`
            if (active) {
              onRemove(emoji)
            } else {
              onAdd(emoji)
            }
          }}
        />
      ))}
      {hasAddButton ? (
        <Popover
          placement="bottom-start"
          render={({ hide }) => (
            <EmojiPicker
              onSelect={(emoji) => {
                onAdd(emoji)
                hide()
              }}
            />
          )}
          onOpenChange={setPickingEmoji}
        >
          {({ getReferenceProps }) => (
            <AddEmojiButton aria-label="Add Emoji Reaction" active={pickingEmoji} {...getReferenceProps()}>
              <AddEmojiReactionIcon />
            </AddEmojiButton>
          )}
        </Popover>
      ) : null}
    </Flex>
  )
}

const AddEmojiButton = styled(Pressable)<{ active: boolean }>(({ theme, active }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '8px',
    height: 3,
    px: 0.5,
    bg: 'background-light-alpha',
    color: active ? 'text' : 'text-light',
    transition: theme.transition.default,
    transitionProperty: 'color',
    fontSize: '20px',
    '&:hover': {
      color: 'text',
    },
  }),
)

import { Flex, Text } from '@mm/company-ui'
import React from 'react'

export type EmptyPlaceholderProps = {
  children: React.ReactNode
}

export const EmptyPlaceholder = ({ children }: EmptyPlaceholderProps) => {
  return (
    <Flex column align="center" sx={{ color: 'text-light' }}>
      <svg sx={{ py: 2 }} width="136" height="150" viewBox="0 0 136 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M94.9112 94.1182C94.8241 94.1182 94.7478 94.0964 94.6825 94.0529L72.2469 79.6985C72.1271 79.6223 72.0509 79.4807 72.0509 79.3391C72.0509 79.1866 72.1271 79.0559 72.2469 78.9688L89.7162 67.5332C89.8468 67.4461 90.0211 67.4352 90.1518 67.5114L97.5032 71.3777C97.6666 71.4649 97.7537 71.6282 97.732 71.8134L95.3468 93.7479C95.3359 93.9004 95.2379 94.0311 95.1072 94.0964C95.0419 94.1073 94.9765 94.1182 94.9112 94.1182ZM73.2816 79.3173L94.5627 92.942L96.8498 71.9985L89.9775 68.3936L73.2816 79.3173Z"
          fill="#AFB1B6"
        />
        <path
          d="M85.0222 91.2317C84.0529 91.2317 83.1163 91.0139 82.2777 90.5673C80.1213 89.4238 78.8361 86.9406 78.651 83.5753C78.6401 83.3357 78.8252 83.1288 79.0648 83.1179C79.3044 83.0961 79.5114 83.2921 79.5223 83.5317C79.6856 86.5812 80.8183 88.8139 82.6915 89.805C84.3579 90.698 86.5252 90.5238 88.5074 89.3475C88.7143 89.2277 88.9757 89.2931 89.1064 89.5C89.2262 89.7069 89.1608 89.9683 88.9539 90.099C87.6687 90.8505 86.3074 91.2317 85.0222 91.2317Z"
          fill="#AFB1B6"
        />
        <path
          d="M97.3073 72.1838C97.2528 72.1838 97.2093 72.1729 97.1548 72.162C96.9261 72.0858 96.8172 71.8353 96.8934 71.6066L99.1479 65.366L94.3014 62.9591L90.2935 68.1541C90.1519 68.3393 89.8796 68.3828 89.6836 68.2303C89.4984 68.0888 89.4549 67.8056 89.6073 67.6204L93.8331 62.1423C93.9637 61.9789 94.1816 61.9244 94.3667 62.0224L99.8776 64.767C100.074 64.865 100.172 65.0937 100.095 65.3007L97.7103 71.9115C97.6558 72.0749 97.4816 72.1838 97.3073 72.1838Z"
          fill="#AFB1B6"
        />
        <path
          d="M118.327 143.781C118.251 132.542 117.663 73.0005 114.439 65.3223C112.598 60.9442 109.658 59.6046 107.512 59.256C103.929 58.6679 99.8993 60.356 96.9805 63.6669C96.8172 63.8521 96.839 64.1243 97.0241 64.2768C97.2093 64.4402 97.4815 64.4184 97.634 64.2332C100.313 61.1946 104.136 59.5719 107.371 60.1055C110.137 60.563 112.304 62.4798 113.644 65.6491C116.759 73.0659 117.369 132.662 117.456 143.738C117.75 143.76 118.033 143.77 118.327 143.781Z"
          fill="#AFB1B6"
        />
        <path
          d="M127.236 148.072C124.35 146.624 121.474 145.175 118.686 143.76C118.371 143.596 118.055 143.433 117.728 143.27C117.412 143.444 117.096 143.607 116.791 143.781C112.99 145.829 109.375 147.768 106.129 149.488H108.002C111.019 147.887 114.308 146.123 117.739 144.272C120.821 145.84 124.012 147.463 127.225 149.064V148.072H127.236Z"
          fill="#AFB1B6"
        />
        <path
          d="M127.28 150L5.92103 149.259C5.69232 149.259 5.49628 149.074 5.48539 148.846L0.704224 34.2936C0.704224 34.1956 0.726008 34.0976 0.791354 34.0105L18.2606 10.6601C18.3368 10.5512 18.4675 10.4858 18.5982 10.4858L124.143 9.20068C124.383 9.20068 124.568 9.38583 124.579 9.62543L127.705 149.553C127.705 149.673 127.661 149.782 127.585 149.869C127.509 149.956 127.4 150 127.28 150ZM6.33489 148.399L126.833 149.129L123.729 10.072L18.8269 11.3462L1.57551 34.4134L6.33489 148.399Z"
          fill="#AFB1B6"
        />
        <path
          d="M25.7532 35.7969C25.7423 35.7969 25.7423 35.7969 25.7314 35.7969L1.11769 34.588C0.878085 34.5771 0.692935 34.3701 0.703826 34.1305C0.714717 33.8909 0.910757 33.6949 1.16125 33.7167L25.1651 34.9038L18.1839 11.0524C18.1186 10.8237 18.2493 10.5841 18.478 10.5188C18.7067 10.4534 18.9463 10.5841 19.0117 10.8128L26.1671 35.2523C26.2106 35.383 26.178 35.5355 26.0908 35.6444C26.0146 35.7315 25.8948 35.7969 25.7532 35.7969Z"
          fill="#AFB1B6"
        />
        <path d="M25.7533 35.3612L1.60784 45.3592L1.13953 34.1523L25.7533 35.3612Z" fill="#AFB1B6" />
        <path
          d="M1.60779 45.7944C1.52066 45.7944 1.44442 45.7726 1.37908 45.729C1.25927 45.6528 1.18303 45.5221 1.18303 45.3805L0.714722 34.1736C0.714722 34.0538 0.758287 33.934 0.845415 33.8469C0.932544 33.7598 1.05235 33.7053 1.17215 33.7162L25.7859 34.9251C25.9928 34.936 26.1562 35.0885 26.1889 35.2845C26.2215 35.4806 26.1126 35.6875 25.9275 35.7637L1.78204 45.7617C1.7167 45.7835 1.66224 45.7944 1.60779 45.7944ZM1.5969 34.6093L2.02165 44.7162L23.8146 35.6984L1.5969 34.6093Z"
          fill="#AFB1B6"
        />
        <path
          d="M22.4859 23.4463C22.2463 23.4463 22.0503 23.2502 22.0503 23.0106C22.0503 22.771 22.2463 22.575 22.4859 22.575L124.437 22.1611C124.676 22.1611 124.873 22.3572 124.873 22.5968C124.873 22.8364 124.676 23.0324 124.437 23.0324L22.4859 23.4463C22.4968 23.4463 22.4859 23.4463 22.4859 23.4463Z"
          fill="#AFB1B6"
        />
        <path
          d="M24.2394 30.7104C23.9997 30.7104 23.8037 30.5144 23.8037 30.2748C23.8037 30.0352 23.9997 29.8392 24.2394 29.8392L124.611 29.4253C124.851 29.4253 125.047 29.6213 125.047 29.8609C125.047 30.1005 124.851 30.2966 124.611 30.2966L24.2394 30.7104Z"
          fill="#AFB1B6"
        />
        <path
          d="M79.0866 72.1836H68.4351C68.2064 72.1836 68.0104 71.9984 67.9995 71.7697L66.3441 40.9154C66.3332 40.7956 66.3767 40.6758 66.4639 40.5887C66.551 40.5016 66.6599 40.458 66.7797 40.458H91.6004C91.84 40.458 92.036 40.654 92.036 40.8936V58.2757C92.036 58.5153 91.84 58.7114 91.6004 58.7114C91.3608 58.7114 91.1647 58.5153 91.1647 58.2757V41.3293H67.2371L68.849 71.3232H79.0866C79.3262 71.3232 79.5222 71.5192 79.5222 71.7588C79.5222 71.9984 79.3262 72.1836 79.0866 72.1836Z"
          fill="#AFB1B6"
        />
        <path
          d="M129.959 48.0817C129.338 48.0817 128.684 48.0382 128.042 47.9728C117.499 46.9491 117.129 46.3283 114.711 26.071L114.221 21.9542C112.718 9.53837 111.967 3.29781 114.352 1.01069C115.343 0.0522817 116.868 -0.209102 119.122 0.161193C127.116 1.47901 127.116 1.47901 130.362 30.0135C130.383 30.2531 130.22 30.4709 129.98 30.4927C129.73 30.5254 129.523 30.3511 129.501 30.1115C126.332 2.2196 126.332 2.2196 118.98 1.01069C117.009 0.683962 115.724 0.880002 114.951 1.63148C112.871 3.62454 113.655 10.0938 115.081 21.8452L115.582 25.962C117.978 46.1105 118.175 46.1322 128.064 47.0907C130.873 47.3629 132.344 47.1124 133.215 46.2194C135.295 44.0738 134.457 37.4412 132.594 22.9235C131.973 18.0443 131.189 11.9671 130.383 4.87701C130.351 4.63741 130.525 4.41959 130.765 4.3978C131.004 4.36513 131.222 4.53939 131.244 4.77899C132.061 11.8582 132.834 17.9354 133.455 22.8145C135.404 38.062 136.21 44.3788 133.836 46.8184C132.888 47.8095 131.483 48.0817 129.959 48.0817Z"
          fill="#AFB1B6"
        />
        <path
          d="M115.212 97.1239L109.788 95.5447C109.669 95.512 109.538 95.5229 109.44 95.5883C109.331 95.6536 109.266 95.7625 109.244 95.8823L101.304 142.42H65.2441C64.1877 143.04 63.1203 143.618 61.9877 144.064V149.161C61.9877 149.401 62.1837 149.597 62.4233 149.597H104.779C104.985 149.597 105.16 149.455 105.203 149.248L115.528 97.614C115.561 97.3962 115.43 97.1784 115.212 97.1239Z"
          fill="#AFB1B6"
        />
        <path
          d="M72.9768 144.075C72.7372 144.075 72.5411 143.879 72.5411 143.639V140.797L69.4481 141.135V143.639C69.4481 143.879 69.252 144.075 69.0124 144.075C68.7728 144.075 68.5768 143.879 68.5768 143.639V140.742C68.5768 140.525 68.7401 140.339 68.9689 140.307L72.9223 139.871C73.0421 139.86 73.1728 139.893 73.2599 139.98C73.3471 140.067 73.4015 140.176 73.4015 140.307V143.639C73.4124 143.879 73.2273 144.075 72.9768 144.075Z"
          fill="#AFB1B6"
        />
        <path
          d="M92.646 143.716C92.4063 143.716 92.2103 143.52 92.2103 143.28V140.797L89.1173 140.775V143.28C89.1173 143.52 88.9212 143.716 88.6816 143.716C88.442 143.716 88.246 143.52 88.246 143.28V140.383C88.246 140.165 88.4093 139.98 88.6381 139.947L92.5915 139.871C92.7113 139.86 92.842 139.893 92.9291 139.98C93.0162 140.067 93.0707 140.176 93.0707 140.307V143.269C93.0816 143.52 92.8856 143.716 92.646 143.716Z"
          fill="#AFB1B6"
        />
        <path
          d="M84.8916 144.075C84.652 144.075 84.456 143.879 84.456 143.64V140.764L77.7036 141.145V142.844C77.7036 143.084 77.5075 143.28 77.2679 143.28C77.0283 143.28 76.8323 143.084 76.8323 142.844V140.732C76.8323 140.503 77.0174 140.307 77.2461 140.296L84.859 139.86C84.9788 139.86 85.0986 139.893 85.1857 139.98C85.2728 140.067 85.3273 140.176 85.3273 140.296V143.629C85.3273 143.879 85.1312 144.075 84.8916 144.075Z"
          fill="#AFB1B6"
        />
        <path
          d="M37.9948 74.7753C38.278 75.3634 38.8117 76.104 39.5631 75.9298C40.1077 75.7991 40.3582 76.6268 39.8136 76.7575C38.6592 77.0298 37.7335 76.2238 37.2542 75.2328C37.0146 74.7427 37.7552 74.2743 37.9948 74.7753Z"
          fill="#AFB1B6"
        />
        <path
          d="M44.7365 73.7515C44.4969 73.7515 44.3009 73.5554 44.3009 73.3158C44.3009 73.0762 44.4969 72.8802 44.7365 72.8802C45.6405 72.8802 46.4573 72.6188 46.8058 72.205C46.9692 72.0089 47.0236 71.7911 46.9801 71.5406C46.8711 70.8545 45.3355 70.4733 44.6167 70.3971C44.3771 70.3753 44.2028 70.1574 44.2355 69.9178C44.2573 69.6782 44.4751 69.504 44.7147 69.5367C45.0088 69.5693 47.59 69.8852 47.8404 71.4099C47.9276 71.9218 47.7969 72.3901 47.4701 72.7822C46.9474 73.3812 45.9236 73.7515 44.7365 73.7515Z"
          fill="#AFB1B6"
        />
        <path
          d="M40.1187 65.235V66.1063C40.1187 67.2281 41.8612 67.2281 41.8612 66.1063V65.235C41.8612 64.1241 40.1187 64.1241 40.1187 65.235Z"
          fill="#AFB1B6"
        />
        <path
          d="M41.7741 60.1375C41.2622 60.2791 40.7721 60.5187 40.2494 60.6276C39.7048 60.7365 39.7811 61.6078 40.3256 61.488C40.9246 61.3682 41.4801 61.1177 42.0682 60.9435C42.6127 60.8019 42.3187 59.9851 41.7741 60.1375Z"
          fill="#AFB1B6"
        />
        <path
          d="M31.9723 92.8654C31.9178 92.8654 31.8634 92.8545 31.8089 92.8327C28.9119 91.6238 29.6634 87.6922 29.6961 87.5288C29.7178 87.409 29.7832 87.311 29.8812 87.2456C29.9792 87.1803 30.099 87.1585 30.2188 87.1912C30.2842 87.2021 36.7643 88.6724 40.3257 86.614C43.2227 84.9367 44.2247 81.5823 44.3009 73.316C44.3009 73.0764 44.497 72.8804 44.7366 72.8804C44.9762 72.8804 45.1722 73.0764 45.1722 73.316C45.1069 80.809 44.3881 85.2744 40.7614 87.3655C37.4396 89.2823 32.103 88.4546 30.4911 88.1387C30.404 89.0862 30.3931 91.308 32.1465 92.0377C32.3644 92.1248 32.4733 92.3862 32.3753 92.604C32.3099 92.7674 32.1465 92.8654 31.9723 92.8654Z"
          fill="#AFB1B6"
        />
        <path
          d="M34.8693 88.139L31.9832 92.4301C30.1317 91.635 30.3386 89.5004 30.1317 87.6162C31.6782 87.9429 33.2792 88.1281 34.8693 88.139Z"
          fill="#AFB1B6"
        />
        <path
          d="M44.6711 70.3862C44.4315 70.3862 44.2355 70.1901 44.2355 69.9505C44.2246 67.9466 44.1919 66.0733 44.1375 63.9714C44.1048 62.6209 44.0721 61.1724 44.0503 59.5387C43.9523 53.1675 39.9553 50.0744 31.8524 50.0744C22.5405 50.0744 18.3911 53.4179 18.3911 60.9328V67.8812C18.3911 68.1208 18.195 68.3169 17.9554 68.3169C17.7158 68.3169 17.5198 68.1208 17.5198 67.8812V60.9328C17.5198 52.9279 22.0722 49.2031 31.8524 49.2031C40.4127 49.2031 44.8018 52.6774 44.9216 59.5278C44.9434 61.1615 44.9761 62.61 45.0087 63.9605C45.0523 66.0733 45.0959 67.9466 45.1068 69.9614C45.1068 70.1901 44.9107 70.3862 44.6711 70.3862Z"
          fill="#AFB1B6"
        />
        <path
          d="M18.9356 67.9466L17.9663 67.8704C17.9663 67.8704 17.9663 67.8704 17.9663 60.9219C17.9663 53.9735 21.4405 49.6279 31.8633 49.6279C37.0692 49.6279 40.2058 50.9022 42.0573 52.7645C38.1256 58.8853 32.3316 63.4051 25.7425 66.3239C23.3029 65.9645 20.6237 66.2694 18.9356 67.9466Z"
          fill="#AFB1B6"
        />
        <path
          d="M25.4484 72.5315C24.893 71.8018 24.1197 71.4969 23.2375 71.3226C22.2682 71.1266 21.6692 71.1593 20.7108 70.7672C18.4999 69.8741 18.5326 67.391 20.4821 66.291C20.6672 66.3018 20.8524 66.2256 20.9395 66.0514C21.1247 65.9207 21.1138 65.692 20.9831 65.5177C21.0811 62.7078 20.7435 59.9633 19.9702 57.2623C20.4712 57.3821 20.6781 56.5871 20.1663 56.4237C17.5306 55.5633 14.5029 54.8554 11.8564 56.097C9.88512 57.0227 8.54553 58.8088 8.48018 60.9652C4.30892 60.5514 1.89111 64.8207 2.78417 68.6652C3.86238 73.3266 8.76335 77.2256 13 78.9572C16.7791 80.5038 23.2484 80.7651 25.5791 76.5721C26.2543 75.3414 26.3306 73.686 25.4484 72.5315Z"
          fill="#AFB1B6"
        />
        <path
          d="M48.6573 53.1782C47.3504 49.6277 41.7088 50.096 38.9098 51.0109C38.3762 51.1851 38.6049 52.0238 39.1385 51.8495C39.4108 51.7624 39.694 51.6752 39.9989 51.599C40.0316 51.7297 40.1187 51.8604 40.2712 51.9148C43.5385 53.0475 43.9741 56.7505 44.2029 59.7346C44.2246 60.0396 44.4642 60.192 44.7474 60.1485C47.6226 59.7346 49.6593 55.8683 48.6573 53.1782Z"
          fill="#AFB1B6"
        />
        <path
          d="M3.97126 102.656C3.89502 102.656 3.81878 102.635 3.74255 102.591C3.53562 102.46 3.47027 102.199 3.60097 101.992C11.4534 89.2168 18.685 82.704 19.5672 81.9307L23.0741 72.3684C21.2771 72.3139 20.2425 71.6931 19.687 71.1595C19.0336 70.5278 18.6742 69.7001 18.6742 68.8179C18.6742 68.2298 18.8375 67.3694 19.6108 66.6288C20.7217 65.5724 22.7256 65.115 25.5791 65.2565C25.8187 65.2674 26.0038 65.4744 25.9929 65.714C25.982 65.9536 25.7533 66.1387 25.5355 66.1278C22.9652 65.9862 21.1246 66.3892 20.2098 67.2605C19.7633 67.6852 19.5454 68.208 19.5454 68.8179C19.5454 69.1882 19.6435 69.9179 20.2969 70.5387C21.0157 71.2357 22.192 71.5625 23.6949 71.508C23.8365 71.4971 23.9781 71.5733 24.0652 71.6823C24.1523 71.8021 24.1741 71.9545 24.1197 72.0852L20.3514 82.3446C20.3296 82.4208 20.286 82.4753 20.2207 82.5297C20.1444 82.5951 12.6078 89.0208 4.34155 102.46C4.26532 102.591 4.12373 102.656 3.97126 102.656Z"
          fill="#AFB1B6"
        />
        <path
          d="M30.7633 94.7277C30.7415 94.7277 30.7198 94.7277 30.698 94.7277C23.4337 93.6495 19.6763 82.7912 19.5238 82.3337C19.4475 82.105 19.5673 81.8545 19.7961 81.7783C20.0248 81.7021 20.2644 81.8219 20.3515 82.0506C20.3842 82.1595 23.9891 92.5822 30.5564 93.8129L34.3791 87.899C34.5098 87.703 34.7821 87.6377 34.9781 87.7684C35.1742 87.8991 35.2395 88.1713 35.1088 88.3674L31.1227 94.5208C31.0465 94.6624 30.9158 94.7277 30.7633 94.7277Z"
          fill="#AFB1B6"
        />
        <path
          d="M96.1639 132.248C95.5758 132.248 94.8461 131.91 94.1382 131.278C91.1431 128.621 89.6511 127.652 86.8847 126.552C86.6669 126.464 86.558 126.214 86.6451 125.985C86.7323 125.757 86.6016 125.539 86.8303 125.626C89.7055 126.769 91.6332 127.891 94.7263 130.625C95.5104 131.311 96.1203 131.442 96.3382 131.354C96.3708 131.344 96.4253 131.322 96.4471 131.169C96.5451 130.287 94.9114 127.434 88.061 122.925C78.2373 116.466 67.1393 122.348 58.2195 127.074C52.2295 130.255 47.0562 132.988 43.4077 131.551C35.3266 128.349 35.0761 120.485 34.8038 112.154C34.5969 105.771 34.3791 99.1715 30.4583 94.5864C30.3058 94.4012 30.3276 94.1289 30.5019 93.9765C30.687 93.824 30.9593 93.8458 31.1117 94.02C35.2286 98.8448 35.4464 105.597 35.6642 112.121C35.9256 120.159 36.1761 127.75 43.7236 130.734C46.9909 132.03 52.0116 129.372 57.8057 126.301C66.9324 121.465 78.2808 115.454 88.5293 122.195C95.2382 126.617 97.4599 129.775 97.3075 131.256C97.2639 131.681 97.0243 132.008 96.654 132.16C96.4906 132.226 96.3273 132.248 96.1639 132.248Z"
          fill="#AFB1B6"
        />
        <path
          d="M42.6671 149.532C36.6008 149.532 31.4276 147.931 27.2236 144.74C25.7098 143.585 24.3266 142.235 23.0959 140.678C17.9445 138.782 14.7098 135.896 13.4791 132.106C11.399 125.691 15.7989 119.07 15.9841 118.787C16.0821 118.634 16.2781 118.558 16.4524 118.612C16.6266 118.656 16.7573 118.819 16.7682 119.004C17.5851 130.571 21.3751 139.24 27.7355 144.054C33.5949 148.486 41.4255 149.739 51.0096 147.757C59.069 146.09 65.1571 142.082 70.0472 138.859C73.4452 136.615 76.3749 134.687 79.0105 134.219C81.2105 133.827 83.5738 134.426 84.6411 135.624C86.3293 137.53 87.4401 138.663 88.6817 138.478C89.0302 138.423 89.1065 137.977 89.1283 137.791C89.2589 136.234 87.4946 133.261 82.06 131.42C81.8313 131.344 81.7115 131.093 81.7877 130.865C81.8639 130.636 82.1144 130.516 82.3431 130.592C88.7144 132.738 90.1302 136.288 89.9995 137.857C89.9342 138.663 89.4768 139.229 88.8233 139.327C87.0154 139.61 85.6213 138.02 83.9986 136.19C83.1491 135.232 81.0253 134.731 79.1739 135.058C76.7125 135.493 73.8481 137.378 70.5373 139.567C65.5818 142.834 59.4284 146.885 51.2057 148.584C48.178 149.227 45.3354 149.532 42.6671 149.532ZM16.0167 120.453C14.8732 122.598 12.8366 127.358 14.296 131.845C15.3415 135.047 17.9662 137.574 22.1048 139.349C18.8157 134.568 16.7356 128.175 16.0167 120.453Z"
          fill="#AFB1B6"
        />
        <path
          d="M90.5769 139.512C89.9017 139.512 89.1829 139.12 89.0086 139.022C88.8017 138.902 88.7364 138.63 88.8562 138.423C88.976 138.216 89.2482 138.15 89.4552 138.27C89.8581 138.51 90.5552 138.749 90.8165 138.597C90.9363 138.521 90.9908 138.259 90.9581 137.9L90.9363 137.66C90.7403 135.395 90.3373 130.668 83.3126 129.209C83.073 129.154 82.9314 128.926 82.975 128.697C83.0186 128.457 83.2473 128.316 83.4869 128.359C91.1433 129.949 91.6007 135.308 91.7967 137.584L91.8185 137.813C91.8839 138.553 91.6987 139.054 91.274 139.327C91.067 139.457 90.8274 139.512 90.5769 139.512Z"
          fill="#AFB1B6"
        />
        <path
          d="M92.058 139.054C91.5134 139.054 91.1322 138.662 91.0778 138.608C90.9144 138.434 90.9253 138.161 91.0996 137.998C91.2738 137.835 91.5461 137.846 91.7095 138.02C91.7639 138.074 91.9491 138.216 92.1015 138.183C92.3194 138.14 92.559 137.791 92.7441 137.247C93.3213 135.624 95.0748 130.679 85.4036 125.876C85.1857 125.767 85.0986 125.506 85.2075 125.299C85.3164 125.081 85.5778 124.994 85.7848 125.103C96.1421 130.244 94.2035 135.733 93.561 137.541C93.2451 138.423 92.8095 138.924 92.2758 139.033C92.2105 139.044 92.1342 139.054 92.058 139.054Z"
          fill="#AFB1B6"
        />
        <path
          d="M19.0989 135.243C19.0553 135.243 19.0009 135.232 18.9573 135.221C18.7286 135.145 18.6088 134.894 18.685 134.665C21.9959 125.005 30.0553 121.836 36.579 122.979C36.8186 123.023 36.9711 123.241 36.9275 123.48C36.8839 123.72 36.6552 123.872 36.4265 123.829C30.2731 122.751 22.6494 125.768 19.5127 134.949C19.4474 135.123 19.284 135.243 19.0989 135.243Z"
          fill="#AFB1B6"
        />
        <path
          d="M16.3436 119.037C8.2189 131.071 19.0337 140.361 22.7149 139.74C18.7941 135.504 16.5288 125.114 16.3436 119.037Z"
          fill="#AFB1B6"
        />
        <path
          d="M96.9699 127.717C96.8719 127.717 96.7738 127.684 96.6976 127.619C96.5125 127.466 96.4798 127.194 96.6322 127.009C96.6431 126.998 97.9936 125.125 94.759 120.866C91.5026 116.575 79.6313 109.779 63.7413 116.129C61.28 117.109 59.0473 118.067 57.076 118.917C46.2177 123.589 41.2513 125.724 35.1959 115.955C35.0652 115.748 35.1306 115.486 35.3375 115.356C35.5445 115.225 35.8058 115.29 35.9365 115.497C41.6108 124.635 46.0325 122.729 56.7384 118.122C58.7097 117.272 60.9532 116.314 63.4255 115.323C79.7729 108.788 92.0689 115.878 95.456 120.333C99.1481 125.19 97.3837 127.456 97.3075 127.554C97.2204 127.662 97.0897 127.717 96.9699 127.717Z"
          fill="#AFB1B6"
        />
        <path
          d="M96.4582 129.536C96.4037 129.536 96.3493 129.525 96.2948 129.503C96.077 129.416 95.9681 129.166 96.0552 128.937C96.7849 127.129 93.7681 123.47 89.7384 121.248C89.5315 121.128 89.4553 120.867 89.5642 120.66C89.684 120.453 89.9454 120.377 90.1523 120.486C94.1929 122.707 97.8522 126.77 96.8503 129.253C96.7958 129.427 96.6325 129.536 96.4582 129.536Z"
          fill="#AFB1B6"
        />
        <path
          d="M96.97 124.254C96.7958 124.254 96.6433 124.156 96.5671 123.992C96.5562 123.96 95.3473 121.193 90.9473 119.451C90.7295 119.364 90.6206 119.113 90.7077 118.884C90.7948 118.667 91.0453 118.558 91.274 118.645C96.0334 120.54 97.3294 123.535 97.373 123.655C97.4601 123.873 97.3621 124.134 97.1443 124.221C97.0789 124.243 97.0245 124.254 96.97 124.254Z"
          fill="#AFB1B6"
        />
      </svg>

      <div sx={{ textAlign: 'center', width: '225px' }}>
        <Text>{children}</Text>
      </div>
    </Flex>
  )
}

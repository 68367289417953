import { Button, Editable, EditableRef, Flex, NotFoundBanner, Suspender } from '@mm/company-ui'
import React, { useCallback, useRef, useState } from 'react'
import { UpdateUserBioDocument, UserBioDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'

export type UserBioProps = {
  userId: string
}

export const UserBio = ({ userId }: UserBioProps) => {
  const editableRef = useRef<EditableRef>(null)
  const [submitting, setSubmitting] = useState(false)
  const [hasText, setHasText] = useState(false)

  const { data, loading } = useQuery(UserBioDocument, {
    variables: {
      userId,
    },
  })

  const [updateBio] = useMutation(UpdateUserBioDocument)

  const handleSubmit = useCallback(() => {
    void (async () => {
      try {
        setSubmitting(true)
        editableRef.current?.commands.blur()
        await updateBio({
          variables: {
            userId,
            bio: editableRef.current?.getHTML() ?? '',
          },
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('failed to create a comment', e)
      } finally {
        setHasText(false)
        setSubmitting(false)
      }
    })()
  }, [userId, updateBio])

  const user = data?.user
  if (user == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return (
    <Flex column>
      <div sx={{ margin: 2, padding: 2, borderRadius: 'default', border: '1px solid', borderColor: 'border' }}>
        <Editable
          ref={editableRef}
          placeholder="User bio..."
          initialValue={user.bioHtml}
          mode="compact"
          onChangeRaw={({ editor }) => {
            setHasText(!editor.isEmpty)
          }}
          editable={!submitting}
        />
      </div>

      <Flex justify="flex-end" sx={{ marginX: 2, marginBottom: 2 }}>
        <Button variant="accent" disabled={!hasText} loading={submitting} onClick={handleSubmit}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}

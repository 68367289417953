import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const AddEmojiReactionIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="add-emoji-reaction" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3687 1.24998C16.3687 0.752919 15.9658 0.349976 15.4687 0.349976C14.9717 0.349976 14.5687 0.752919 14.5687 1.24998V3.63123H12.1875C11.6904 3.63123 11.2875 4.03417 11.2875 4.53123C11.2875 5.02828 11.6904 5.43123 12.1875 5.43123H14.5687V7.81248C14.5687 8.30953 14.9717 8.71248 15.4687 8.71248C15.9658 8.71248 16.3687 8.30953 16.3687 7.81248V5.43123H18.75C19.247 5.43123 19.65 5.02828 19.65 4.53123C19.65 4.03417 19.247 3.63123 18.75 3.63123H16.3687V1.24998ZM2.14998 11.0937C2.14998 7.36235 5.17485 4.33748 8.90623 4.33748C9.40328 4.33748 9.80622 3.93453 9.80622 3.43748C9.80622 2.94042 9.40328 2.53748 8.90623 2.53748C4.18074 2.53748 0.349976 6.36824 0.349976 11.0937C0.349976 15.8192 4.18074 19.65 8.90623 19.65C13.3212 19.65 16.9542 16.3068 17.4136 12.0133C17.4664 11.5191 17.1086 11.0756 16.6144 11.0227C16.1202 10.9698 15.6767 11.3276 15.6238 11.8219C15.2612 15.2104 12.3914 17.85 8.90623 17.85C5.17485 17.85 2.14998 14.8251 2.14998 11.0937ZM8.32297 9.28123C8.32297 9.97158 7.76333 10.5312 7.07297 10.5312C6.38262 10.5312 5.82297 9.97158 5.82297 9.28123C5.82297 8.59087 6.38262 8.03123 7.07297 8.03123C7.76333 8.03123 8.32297 8.59087 8.32297 9.28123ZM11.198 10.5312C11.8883 10.5312 12.448 9.97158 12.448 9.28123C12.448 8.59087 11.8883 8.03123 11.198 8.03123C10.5076 8.03123 9.94797 8.59087 9.94797 9.28123C9.94797 9.97158 10.5076 10.5312 11.198 10.5312ZM6.6151 12.4677C6.35908 12.0417 5.80615 11.9038 5.3801 12.1599C4.95405 12.4159 4.81622 12.9688 5.07224 13.3949C5.46983 14.0565 6.03187 14.6039 6.7037 14.984C7.37552 15.3641 8.13428 15.5639 8.90617 15.5639C9.67806 15.5639 10.4368 15.3641 11.1087 14.984C11.7805 14.6039 12.3425 14.0565 12.7401 13.3949C12.9961 12.9688 12.8583 12.4159 12.4322 12.1599C12.0062 11.9038 11.4533 12.0417 11.1972 12.4677C10.9597 12.8631 10.6238 13.1902 10.2223 13.4174C9.82085 13.6445 9.36744 13.7639 8.90617 13.7639C8.44491 13.7639 7.99149 13.6445 7.59002 13.4174C7.18855 13.1902 6.85269 12.8631 6.6151 12.4677Z"
    />
  </IconBase>
)

import { formatListCountDisplay, useFeatureFlags } from '@mm/company-ui'
import { CommentsForTotalsFragmentFragmentDoc } from '../../gen/graphql/documents'
import { useFragment_experimental } from '../apollo'

export type UseCommentsForTotalsParent = {
  __typename: string
  id: string
}

export type UseCommentsForTotalsResult = {
  hasComments: boolean
  commentsLabel?: string
  latestCommentDate?: number
}

export const useTotalComments = (parent: UseCommentsForTotalsParent | null | undefined): UseCommentsForTotalsResult => {
  const { tableRowDetails } = useFeatureFlags()
  const { data } = useFragment_experimental({
    fragment: CommentsForTotalsFragmentFragmentDoc,
    fragmentName: 'CommentsForTotalsFragment',
    from: parent ?? {},
  })

  if (!data) {
    return { hasComments: false }
  }

  const commentsLabel = formatListCountDisplay(data.comments, {
    label: 'comments',
    singular: 'comment',
    empty: tableRowDetails ? '' : '0 comments',
  })
  const latestCommentDate = data.comments?.edges[0]?.node?.createdAt

  return {
    commentsLabel,
    hasComments: data.comments != null && data.comments.edges.length > 0,
    latestCommentDate: latestCommentDate,
  }
}

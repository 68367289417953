import { Suspender, Flex, Tag, formatDateDisplay, UserSelectTarget } from '@mm/company-ui'
import React from 'react'
import { DecisionMetadataSummaryDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { DecisionStatusPicker } from './DecisionStatusPicker'

export type DecisionMetadataSummaryProps = {
  decisionId: string
}

export const DecisionMetadataSummary = ({ decisionId }: DecisionMetadataSummaryProps) => {
  const { data, loading, error } = useQuery(DecisionMetadataSummaryDocument, {
    variables: {
      id: decisionId,
    },
  })

  const decision = data?.decision

  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Decision not found')
  }

  return (
    <Flex row gap={2} align="center">
      <DecisionStatusPicker decisionId={decisionId} />

      <Tag>
        Creator
        <UserSelectTarget value={{ node: decision.creator }} disabled />
      </Tag>

      <Tag>
        Decision Maker
        <UserSelectTarget value={{ node: decision.decisionMaker }} disabled />
      </Tag>

      <Tag>
        Decision Due
        {formatDateDisplay(decision.dueAt)}
      </Tag>
    </Flex>
  )
}

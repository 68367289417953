import { useCallback } from 'react'
import {
  PrototypeFeedbackEmojiReactionsDataFragmentDoc,
  UseRemovePrototypeFeedbackEmojiReactionDocument,
} from '../../../gen/graphql/documents'
import { useApolloClient, useMutation } from '../../apollo'
import { useActiveUser } from '../../users'

export const useRemovePrototypeFeedbackEmojiReaction = (prototypeFeedbackId: string) => {
  const client = useApolloClient()
  const [removeEmojiReaction] = useMutation(UseRemovePrototypeFeedbackEmojiReactionDocument)
  const activeUser = useActiveUser()

  const activeUserId = activeUser?.id

  return useCallback(
    (emoji: string) => {
      const data = client.readFragment(
        {
          id: client.cache.identify({ __typename: 'PrototypeFeedback', id: prototypeFeedbackId }),
          fragment: PrototypeFeedbackEmojiReactionsDataFragmentDoc,
        },
        true,
      )

      return removeEmojiReaction({
        variables: {
          id: prototypeFeedbackId,
          emoji,
        },
        optimisticResponse:
          data && activeUserId
            ? {
                removePrototypeFeedbackEmojiReaction: {
                  ...data,
                  emojiReactions: data.emojiReactions?.filter((reaction) => {
                    const emojiId = emoji.match(/^(:[^:]+:)/)?.[1]
                    return reaction.user.id !== activeUserId || emojiId == null || !reaction.emoji.startsWith(emojiId)
                  }),
                },
              }
            : undefined,
      })
    },
    [client, prototypeFeedbackId, removeEmojiReaction, activeUserId],
  )
}

import { ConfirmPopover, FlyoutItem, useConfirmPopover } from '@mm/company-ui'
import { DeleteIcon, LoadingIcon } from '@mm/company-ui-icons'
import React from 'react'
import { GoodThingMoreMenuDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { deleteGoodThingCommand } from '../commands/deleteGoodThingCommand'

export type GoodThingMoreMenuProps = {
  id: string
  onGoodThingDeleted?: () => void
  cached?: boolean
}

export const GoodThingMoreMenu = ({ id, cached, onGoodThingDeleted }: GoodThingMoreMenuProps) => {
  const { data, loading, error } = useQuery(GoodThingMoreMenuDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })

  const deleteGoodThing = useCommand(deleteGoodThingCommand)
  const { triggerConfirm, confirmControlProps } = useConfirmPopover()

  const goodThing = data?.goodThing
  if (goodThing == null) {
    if (loading) {
      return <LoadingIcon />
    }
    throw error ?? new Error('Good Thing not found')
  }

  return (
    <>
      <FlyoutItem
        onClick={async () => {
          if (!(await triggerConfirm())) return

          void deleteGoodThing.execute({ goodThingId: id, onGoodThingDeleted })
        }}
        startIcon={deleteGoodThing?.loading ? <LoadingIcon /> : <DeleteIcon />}
      >
        Delete
      </FlyoutItem>
      <ConfirmPopover
        {...confirmControlProps}
        zIndex={10000}
        title="Permanently delete the Good Thing?"
        description="This action cannot be reversed."
        confirmLabel="Delete permanently"
      />
    </>
  )
}

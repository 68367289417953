import { Flex, NotFoundBanner, Suspender, Text, formatDateDisplay, useFeatureFlags } from '@mm/company-ui'
import { LinkIcon } from '@mm/company-ui-icons/src'
import Link from 'next/link'
import React from 'react'
import { AccountabilityActionViewDocument } from '../../../gen/graphql/documents'
import { ActionAssigneeTagSelect } from '../../../modules/actions/components/ActionAssigneeTagSelect'
import { ActionDatepicker } from '../../../modules/actions/components/ActionDatepicker'
import { ActionDescription } from '../../../modules/actions/components/ActionDescription'
import { ActionPrivacyTag } from '../../../modules/actions/components/ActionPrivacyTag'
import { ActionTitle } from '../../../modules/actions/components/ActionTitle'
import { useQuery } from '../../../modules/apollo'
import { ActionComments } from '../../actions/components/ActionComments'
import { ActionConsolidatedMetadata } from '../../actions/components/ActionConsolidatedMetadata'
import { CloseDrawerButton } from '../../components'
import { ContextualDrawerNavigation } from '../../navigation'
import { ActionStatusSelect } from './ActionStatusSelect'
import { ActionUpdateListItem } from './ActionUpdateListItem'

export type ActionViewProps = {
  actionId: string
  inDrawer?: boolean
}

export function ActionView({ actionId, inDrawer }: ActionViewProps) {
  const { data, loading } = useQuery(AccountabilityActionViewDocument, {
    variables: {
      actionId: actionId,
    },
  })
  const { contextualDrawerNavigation, actionConsolidatedMetadata } = useFeatureFlags()
  const action = data?.action
  const actionUpdates = data?.action?.actionUpdates

  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  const parentTypeLabel =
    action.parent?.__typename === 'PrototypeFeedback' ? 'Feedback' : `${action.parent?.__typename}`
  const parentTitleLabel = action.parent?.title ? `${parentTypeLabel}: ${action.parent.title}` : ''
  const parentHref =
    action.parent?.__typename === 'Decision'
      ? {
          pathname: '/decision/[id]',
          query: { id: action.parent.id },
        }
      : action.parent?.__typename === 'Goal'
      ? {
          pathname: '/accountability/goals/[id]',
          query: { id: action.parent.id },
        }
      : action.parent?.__typename === 'Meeting'
      ? {
          pathname: '/meetings/[id]',
          query: { id: action.parent.id },
        }
      : action.parent?.__typename === 'Topic'
      ? {
          pathname: '/topics/[id]',
          query: { id: action.parent.id },
        }
      : action.parent?.__typename === 'PrototypeFeedback'
      ? {
          pathname: '/feedback/[id]',
          query: { id: action.parent.id },
        }
      : undefined

  return (
    <>
      {inDrawer ? (
        <Flex row sx={{ height: 7, gap: 1, paddingX: 2.5 }} align="center">
          <CloseDrawerButton resourceType="accountability/actions" />
          {contextualDrawerNavigation && <ContextualDrawerNavigation />}
        </Flex>
      ) : (
        <Flex row sx={{ height: 7, gap: 1, paddingX: 2.5 }} align="center">
          <Link href="/accountability/actions">
            <Text
              color="text-light"
              variant="small"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Actions
            </Text>
          </Link>
          <Text color="text-light">›</Text>
          <Text color="text-light" variant="small" sx={{ maxWidth: 150 }} overflow="ellipsis">
            {action.title}
          </Text>
        </Flex>
      )}

      <div sx={{ width: '100%', maxWidth: '800px', paddingX: 2, marginX: 'auto' }}>
        <Flex column sx={{ paddingTop: 1, paddingBottom: 3, borderBottom: '1px solid', borderBottomColor: 'border' }}>
          <ActionTitle id={actionId} cached />

          {parentTitleLabel ? (
            parentHref ? (
              <Link href={parentHref} target="_blank">
                <Flex row gap={0.5} sx={{ marginTop: 0.25, flexShrink: 0 }}>
                  <LinkIcon width={1.5} height={1.5} color="text-light" sx={{ marginTop: 0.5 }} />
                  <Text color="text-light" overflow="ellipsis" sx={{ maxWidth: 400, flexShrink: 0 }}>
                    {parentTitleLabel}
                  </Text>
                </Flex>
              </Link>
            ) : (
              <Flex row gap={0.5} sx={{ marginTop: 0.25, flexShrink: 0 }}>
                <LinkIcon width={1.5} height={1.5} color="text-light" sx={{ marginTop: 0.5 }} />
                <Text color="text-light" overflow="ellipsis" sx={{ maxWidth: 400, flexShrink: 0 }}>
                  {parentTitleLabel}
                </Text>
              </Flex>
            )
          ) : null}

          <div sx={{ marginTop: 2 }}>
            <ActionDescription actionId={actionId} fillHeight={false} padding={0} />
          </div>
        </Flex>

        <Flex
          column
          gap={1}
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
            borderBottom: '1px solid',
            borderBottomColor: 'border',
          }}
        >
          {actionConsolidatedMetadata ? (
            <ActionConsolidatedMetadata actionId={actionId} />
          ) : (
            <div sx={{ display: 'grid', gridTemplateColumns: '1fr 85%', gridRowGap: 2 }}>
              <Text color="text-light">Status</Text>
              <div>
                <ActionStatusSelect actionId={actionId} />
              </div>

              <Text color="text-light">Due date</Text>
              <div>
                <ActionDatepicker id={actionId} showRescheduledPrefix redIfPastDue variant="tag" />
              </div>

              <Text color="text-light">Assignee</Text>
              <div>
                <ActionAssigneeTagSelect actionId={actionId} label="" withName />
              </div>

              <Text color="text-light">Privacy</Text>
              <div>
                <ActionPrivacyTag actionId={actionId} />
              </div>
            </div>
          )}
        </Flex>

        <Flex
          column
          gap={1}
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
            borderBottom: '1px solid',
            borderBottomColor: 'border',
          }}
        >
          <Text bold>Updates</Text>
          <Flex column gap={2}>
            {actionUpdates?.map((update) => {
              return update ? (
                <Flex key={update.id} column gap={1}>
                  <Text uppercase bold variant="small" color="text-light">
                    {formatDateDisplay(update.createdAt, 'SHORT_MONTH_WITH_DATE')}
                  </Text>
                  <ActionUpdateListItem actionUpdate={update} />
                </Flex>
              ) : null
            })}
          </Flex>
        </Flex>

        <Flex
          column
          gap={1}
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          <Text bold>Comments</Text>
          <ActionComments actionId={actionId} fixedComposer={false} order="newest" fixedAndScroll={false} />
        </Flex>
      </div>
    </>
  )
}

import React from 'react'
import { AddDecisionCommentBoxDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useMutation } from '../../apollo'
import { addRootCommentToCache } from '../utils/addCommentToCache'
import { AddCommentBox } from './AddCommentBox'

export type AddDecisionCommentBoxProps = {
  decisionId: string
}

export const AddDecisionCommentBox = ({ decisionId }: AddDecisionCommentBoxProps) => {
  const [addComment] = useMutation(AddDecisionCommentBoxDocument, {
    onCompleted: (data) => {
      if (data?.createCommentOnDecision?.__typename !== 'Comment') {
        alert(`failed to create a comment: ${data.createCommentOnDecision.message ?? 'unknown error'}`)
      }
      capture('Comment Created', {
        parent: 'Decision',
      })
    },
    update: (cache, { data }) => {
      const comment = data?.createCommentOnDecision
      if (comment?.__typename === 'Comment') {
        addRootCommentToCache(cache, comment)
      }
    },
  })

  return (
    <AddCommentBox
      id={`decision:${decisionId}`}
      registerDecisionCapability
      onSubmit={async ({ htmlBody, author }) => {
        const { data } = await addComment({
          variables: {
            decisionId,
            htmlBody,
            author,
          },
        })
        if (data?.createCommentOnDecision.__typename !== 'Comment') {
          throw new Error('failed to create a comment')
        }

        return data.createCommentOnDecision
      }}
    />
  )
}

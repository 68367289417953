import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const DecisionIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="decision" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.74408 8.92294C7.41864 8.5975 7.41864 8.06987 7.74408 7.74444L14.4108 1.07766C14.7363 0.752229 15.2639 0.752229 15.5893 1.07766L18.0895 3.57785C18.415 3.90328 18.415 4.43092 18.0895 4.75635L15.3455 7.50034L19.756 11.9107C20.0814 12.2362 20.0814 12.7638 19.756 13.0893C19.4305 13.4147 18.9029 13.4147 18.5774 13.0893L14.167 8.67885L11.4228 11.4231C11.0973 11.7486 10.5697 11.7486 10.2443 11.4231L7.74408 8.92294ZM16.3218 4.1671L13.5983 6.89054C13.5939 6.8947 13.5895 6.89893 13.5851 6.90321C13.5825 6.9057 13.58 6.90821 13.5774 6.91074C13.5706 6.91762 13.5638 6.92459 13.5572 6.93165L10.8335 9.65537L9.51183 8.33369L15.0001 2.84541L16.3218 4.1671ZM3.61937 6.60742C3.29393 6.28198 2.76629 6.28198 2.44086 6.60742C2.11542 6.93286 2.11542 7.46049 2.44086 7.78593L4.20835 9.55343C4.53379 9.87887 5.06143 9.87887 5.38687 9.55343C5.7123 9.22799 5.7123 8.70036 5.38687 8.37492L3.61937 6.60742ZM0.833333 11.6667C0.373096 11.6667 0 12.0398 0 12.5C0 12.9602 0.373096 13.3333 0.833333 13.3333H3.33333C3.79357 13.3333 4.16667 12.9602 4.16667 12.5C4.16667 12.0398 3.79357 11.6667 3.33333 11.6667H0.833333ZM2.5 15C2.03976 15 1.66667 15.3731 1.66667 15.8333V18.3333H0.833333C0.373096 18.3333 0 18.7064 0 19.1667C0 19.6269 0.373096 20 0.833333 20H2.4991H2.5H2.5009H12.4991H12.5H12.5009H14.1667C14.6269 20 15 19.6269 15 19.1667C15 18.7064 14.6269 18.3333 14.1667 18.3333H13.3333V15.8333C13.3333 15.3731 12.9602 15 12.5 15H2.5ZM11.6667 18.3333V16.6667H3.33333V18.3333H11.6667Z"
      />
    </IconBase>
  )
}

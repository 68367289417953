import { Suspender } from '@mm/company-ui'
import React, { useCallback } from 'react'
import { DecisionDatepickerDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { editDecisionDueAtCommand } from '../commands/editDecisionDueAt'
import { DecisionDatepicker, DecisionDatepickerProps } from './DecisionDatepicker'

export type DecisionDueAtDatePickerProps = Omit<
  DecisionDatepickerProps,
  'value' | 'onChange' | 'decisionStatus' | 'dateRescheduled'
> & {
  id: string
  cached?: boolean
}

export const DecisionDueAtDatePicker = ({ id, cached, ...rest }: DecisionDueAtDatePickerProps) => {
  const { data, loading, error } = useQuery(DecisionDatepickerDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: { id },
  })
  const editDecisionDue = useCommand(editDecisionDueAtCommand)

  const handleChange = useCallback(
    async (date: Date | null) => {
      if (date) {
        void editDecisionDue.execute({
          id,
          timestamp: date,
        })
      }
    },
    [editDecisionDue, id],
  )

  const decision = data?.decision

  if (decision == null || !decision?.dueAt) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Decision not found')
  }

  return (
    <DecisionDatepicker
      value={new Date(decision.dueAt)}
      onChange={handleChange}
      decisionStatus={decision.status}
      dateRescheduled={decision.dateRescheduled}
      {...rest}
    />
  )
}

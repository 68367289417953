import { NotFoundBanner, Suspender, useFeatureFlags } from '@mm/company-ui'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { SingleActionDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useSyncActiveCompanyWithResource } from '../../companies'
import { useEditAction } from '../../DoneNotDone'
import { ActionView } from './ActionView'
import { SingleActionPage } from './SingleActionPage'

export type SingleActionProps = {
  id: string
}

export const SingleAction = ({ id }: SingleActionProps) => {
  const { newDrawerViews } = useFeatureFlags()
  const editAction = useEditAction(id)
  useHotkeys('cmd+.', () => {
    void editAction?.({ status: 'DONE' })
  })

  const { data, loading } = useQuery(SingleActionDocument, {
    variables: {
      id,
    },
  })

  useSyncActiveCompanyWithResource(data?.action?.company?.id)

  if (data?.action == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return newDrawerViews ? <ActionView actionId={id} /> : <SingleActionPage actionId={id} />
}

import { EditableFormController, SingleLineEditable } from '@mm/company-ui'
import React, { useMemo } from 'react'
import { Control } from 'react-hook-form'
import { CreateDecisionData } from '../types'

export type CreateDecisionTitleProps = {
  control: Control<CreateDecisionData>
  formError: {
    type: string
    message: string
  }
}
export const CreateDecisionTitle = ({ control, formError }: CreateDecisionTitleProps) => {
  const hasError = useMemo(() => {
    return formError.type === 'title'
  }, [formError])

  return (
    <EditableFormController {...control.register('title')}>
      {(field) => (
        <SingleLineEditable
          {...field}
          invalid={hasError}
          variant="underline"
          placeholder={hasError ? formError.message : 'Give it a title...'}
          sx={{
            fontSize: '20px',
            fontWeight: 'body',
          }}
        />
      )}
    </EditableFormController>
  )
}

import {
  Flex,
  PanelMetadataContainer,
  PanelMetadataItem,
  Suspender,
  UserSelectTarget,
  useFeatureFlags,
} from '@mm/company-ui'
import { CalendarIcon, CircleCheckmarkIcon, LinkIcon, TeamIcon, UserProfileIcon } from '@mm/company-ui-icons'
import React from 'react'
import { DecisionMetadataDocument, UpdateDecisionCreatorDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { PanelMetadataItemChips } from '../../components'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { DecisionCommentsDueAtDatePicker } from './DecisionCommentsDueAtDatePicker'
import { DecisionContributorsCell } from './DecisionContributorsCell'
import { DecisionDueAtDatePicker } from './DecisionDueAtDatePicker'
import { DecisionMakerCell } from './DecisionMakerCell'
import { DecisionObserversCell } from './DecisionObserversCell'
import { DecisionStatusPicker } from './DecisionStatusPicker'

export type DecisionMetadataProps = {
  decisionId: string
}

export const DecisionMetadata = ({ decisionId }: DecisionMetadataProps) => {
  const { data, loading, error, fetchMore } = useQuery(DecisionMetadataDocument, {
    variables: {
      id: decisionId,
    },
  })
  const { canCreateOnBehalfOfOtherUsers } = useFeatureFlags()
  const [updateCreator] = useMutation(UpdateDecisionCreatorDocument)
  const decision = data?.decision

  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Decision not found')
  }
  const handleLoadMore = (endCursor: string) => () => {
    void fetchMore({
      variables: {
        meetingAfter: endCursor,
      },
    })
  }

  return (
    <PanelMetadataContainer>
      <Flex row gap={2}>
        <PanelMetadataContainer>
          <PanelMetadataItem title="Status" sx={{ alignItems: 'center' }} icon={<CircleCheckmarkIcon />}>
            <DecisionStatusPicker decisionId={decisionId} />
          </PanelMetadataItem>

          <PanelMetadataItem title="Decision maker" column={false} icon={<UserProfileIcon />}>
            <DecisionMakerCell decisionId={decisionId} withName />
          </PanelMetadataItem>
          <PanelMetadataItem title="Commenters" column={false} icon={<TeamIcon />}>
            <DecisionContributorsCell decisionId={decisionId} />
          </PanelMetadataItem>

          {decision.meetings?.edges && decision.meetings?.edges.length !== 0 && (
            <PanelMetadataItemChips
              title="Meetings"
              icon={<LinkIcon />}
              chips={decision.meetings.edges.map(({ node }) => ({
                id: node.id,
                label: node.title,
                href: { pathname: '/meetings/[id]', query: { id: node.id } },
              }))}
              hasNextPage={decision.meetings.pageInfo.hasNextPage}
              onLoadMore={handleLoadMore(decision.meetings.pageInfo.endCursor)}
            />
          )}
          {decision.parent?.__typename === 'Goal' && (
            <PanelMetadataItemChips
              title="Goals"
              icon={<LinkIcon />}
              chips={[
                {
                  id: decision.parent.id,
                  label: decision.parent.title,
                  href: { pathname: '/goals/[id]', query: { id: decision.parent.id } },
                },
              ]}
            />
          )}
        </PanelMetadataContainer>

        <PanelMetadataContainer>
          <PanelMetadataItem title="Creator" column={false} icon={<UserProfileIcon />}>
            {canCreateOnBehalfOfOtherUsers ? (
              <UserSelectWithCompanyMembers
                value={decision.creator.id}
                onAdd={(creatorId: string) => {
                  void updateCreator({ variables: { id: decisionId, creatorId } })
                }}
                withName
              />
            ) : (
              <UserSelectTarget withName value={{ node: decision.creator }} />
            )}
          </PanelMetadataItem>
          <PanelMetadataItem title="Observers" column={false} icon={<TeamIcon />}>
            <DecisionObserversCell decisionId={decisionId} />
          </PanelMetadataItem>
          <PanelMetadataItem title="Comments due" column={false} icon={<CalendarIcon />}>
            <DecisionCommentsDueAtDatePicker
              id={decisionId}
              disabledDays={{ after: new Date(decision.dueAt) }}
              showIcon={false}
              textAlign="left"
              redIfPastDue
              showRescheduledPrefix
              showFooter={false}
            />
          </PanelMetadataItem>
          <PanelMetadataItem title="Decision due" column={false} icon={<CalendarIcon />}>
            <DecisionDueAtDatePicker
              id={decisionId}
              disabledDays={{ before: new Date(decision.commentsDueAt) }}
              showIcon={false}
              textAlign="left"
              redIfPastDue
              showRescheduledPrefix
              showFooter={false}
            />
          </PanelMetadataItem>
        </PanelMetadataContainer>
      </Flex>
    </PanelMetadataContainer>
  )
}

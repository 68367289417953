import { Flex, Text, formatDateDisplay, Tooltip, Tag } from '@mm/company-ui'
import { LockedIcon, UnlockedIcon } from '@mm/company-ui-icons'
import React from 'react'
import { UserGoalsDashboardRowDataFragment } from '../../../gen/graphql/documents'
import { GoalLinkWithMenu } from './GoalLinkWithMenu'

export type GoalUpdateRowProps = {
  goal: UserGoalsDashboardRowDataFragment
}

export function GoalUpdateRow({ goal }: GoalUpdateRowProps) {
  return (
    <GoalLinkWithMenu goalId={goal.id} hasHover={false}>
      <Flex row gap={2} sx={{ padding: 1 }}>
        {goal.priority != undefined ? (
          <Tag variant="flat" data-clickable="true" sx={{ fontWeight: 500, fontSize: 1 }}>
            #{goal.priority + 1}
          </Tag>
        ) : null}

        <Text
          bold
          sx={{
            flex: 1,
            fontSize: '16px',
          }}
        >
          {goal.title}
        </Text>

        <Flex gap={2} sx={{ alignSelf: 'flex-start' }} align="center">
          {goal.privacy === 'PRIVATE' ? (
            <Tooltip description="This goal is private">
              <LockedIcon width={1.5} height={1.5} color="text-light" />
            </Tooltip>
          ) : (
            <Tooltip description="This goal is public">
              <UnlockedIcon width={1.5} height={1.5} color="text-light" />
            </Tooltip>
          )}
          <Text color="text-light" variant="small" sx={{ flexShrink: 0 }}>
            By {formatDateDisplay(goal.dueAt)}
          </Text>
        </Flex>
      </Flex>
    </GoalLinkWithMenu>
  )
}

export const ActionListDisplayOptions = {
  grouping: ['Weekly', 'Assignee', 'Status', 'None'] as const,
  sortingFields: [
    { label: 'Created', value: 'createdAt' as const },
    { label: 'Due', value: 'dueAt' as const },
    { label: 'Updated', value: 'updatedAt' as const },
    // { label: 'completed', value: 'dateCompleted' as const }, //TODO not showing in UI yet
  ],
  sortingOrder: [
    { label: 'Ascending', value: 'asc' as const },
    { label: 'Descending', value: 'desc' as const },
  ],
  fields: ['Assigned To', 'Due Date', 'Created', 'Updated', 'Context', 'Privacy'] as const,
}

export const InitialActionState = {
  grouping: 'Weekly',
  sortingField: { label: 'Updated', value: 'updatedAt' },
  sortingOrder: { label: 'Descending', value: 'desc' },
  fields: { 'Assigned To': true, 'Due Date': true, Context: true, Updated: true, Parent: true, Created: true },
}

export const TeamListDisplayOptions = {
  fields: ['Update', 'Goals', 'Actions', 'Feedback', 'Role'] as const,
  grouping: [],
  sortingFields: [],
  sortingOrder: [],
}

export const InitialTeamState = {
  fields: { Update: true, Goals: true, Actions: true, Feedback: true, Role: true },
}

const updateFieldsState = { Goals: true, Actions: true, Comments: true, Created: true, Assignee: true, Privacy: true }
export const UpdatesListDisplayOptions = {
  grouping: ['Assignee', 'Weekly', 'None'] as const,
  sortingFields: [{ label: 'Created', value: 'createdAt' as const }],
  sortingOrder: [{ label: 'Descending', value: 'desc' as const }],
  fields: Object.keys(updateFieldsState),
}

export const InitialUpdatesListState = {
  grouping: 'Weekly',
  sortingField: { label: 'Created', value: 'createdAt' },
  sortingOrder: { label: 'Descending', value: 'desc' },
  fields: updateFieldsState,
}

export const ActionSectionDisplayOptions = {
  grouping: ['Weekly', 'Status', 'None'] as const,
  sortingFields: [
    { label: 'Created', value: 'createdAt' as const },
    { label: 'Due', value: 'dueAt' as const },
  ],
  sortingOrder: [
    { label: 'Ascending', value: 'asc' as const },
    { label: 'Descending', value: 'desc' as const },
  ],
  fields: [],
}

export const InitialActionSectionState = {
  grouping: 'None',
  sortingField: { label: 'Due', value: 'dueAt' },
  sortingOrder: { label: 'Ascending', value: 'asc' },
}

import {
  BaseLink,
  Button,
  ButtonGroup,
  DRAWER_SECTION_INNER_PADDING,
  DrawerLayout,
  DrawerSection,
  Flex,
  Flyout,
  Suspender,
  Text,
} from '@mm/company-ui'
import { ActionsIcon, CommentChatIcon, EllipsisIcon, FilePageTemplateIcon, InfoIcon } from '@mm/company-ui-icons'
import Router from 'next/router'
import { route } from 'nextjs-routes'
import React from 'react'
import { SingleGoodThingDocument } from '../../../gen/graphql/documents'
import { CreatedContext } from '../../actions/components/CreatedContext'
import { useQuery } from '../../apollo'
import { RegisterCapability } from '../../capabilities'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { DrawerView } from '../../components/DrawerView'
import { CREATE_ISSUE } from '../../decisions/capabilities'
import { DocumentEditor } from '../../editor'
import { useFeatureFlags } from '../../featureFlags'
import { useTotalComments } from '../../hooks/useTotalComments'
import { useMeetingContext } from '../../meetings'
import { ContextualDrawerNavigation } from '../../navigation'
import { ADD_TOPIC } from '../../topics/capabilities'
import { useActiveUser } from '../../users'
import { GoodThingActions } from './GoodThingActions'
import { GoodThingComments, AddGoodThingCommentBox } from './GoodThingComments'
import { GoodThingEmojiReactions } from './GoodThingEmojiReactions'
import { GoodThingMetadata } from './GoodThingMetadata'
import { GoodThingMetadataSummary } from './GoodThingMetadataSummary'
import { GoodThingMoreMenu } from './GoodThingMoreMenu'

export type SingleGoodThingPageProps = {
  goodThingId: string
  cached?: boolean
}

export const SingleGoodThingPage = ({ goodThingId, cached }: SingleGoodThingPageProps) => {
  const {
    metadataSummary,
    commentsActionsInDrawersCollapsedWhenEmpty,
    goodThingActions,
    reconfigureDrawerViews,
    flipComments,
  } = useFeatureFlags()

  const { data, loading, error } = useQuery(SingleGoodThingDocument, {
    variables: {
      goodThingId,
    },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const activeUser = useActiveUser()
  const meeting = useMeetingContext()

  const goodThing = data?.goodThing

  const { commentsLabel, hasComments } = useTotalComments(goodThing)

  if (goodThing == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Good Thing not found')
  }

  const meetingId = goodThing.parent?.id
  const goodThingEditable = goodThing.user.id == activeUser?.id

  if (reconfigureDrawerViews) {
    return (
      <DrawerView
        resourceId={goodThingId}
        resourceType="goodThings"
        renderMoreMenu={() => (
          <GoodThingMoreMenu
            id={goodThingId}
            onGoodThingDeleted={() => {
              if (!meetingId) {
                void Router.push(`/meetings`)
                return
              }
              void Router.push(`/meetings/${meetingId}`)
            }}
            cached
          />
        )}
        renderTitle={
          <Text variant="h4" sx={{ paddingY: 1, marginBottom: '2px' }}>
            {`Good things from ${goodThing.user.firstName}`}
          </Text>
        }
        renderMetadata={<GoodThingMetadata goodThingId={goodThingId} cached />}
        renderDescription={
          <Flex column gap={1} sx={{ padding: 1 }}>
            <DocumentEditor
              key={goodThing.description.id}
              documentId={goodThing.description.id}
              mode="compact"
              editable={goodThingEditable}
              sx={{
                '&': {
                  display: 'flex',
                  flexDirection: 'column',
                },
                '.ProseMirror': {
                  margin: 0,
                  padding: 0,
                  minHeight: 'auto',
                },
              }}
            />
            <GoodThingEmojiReactions id={goodThingId} cached />
          </Flex>
        }
        renderActions={<GoodThingActions id={goodThingId} />}
        renderComments={<GoodThingComments goodThingId={goodThingId} />}
        renderCommentComposer={flipComments ? <AddGoodThingCommentBox goodThingId={goodThingId} /> : null}
      />
    )
  }

  return (
    <DrawerLayout
      title="Good Things"
      navigation={<ContextualDrawerNavigation />}
      renderTitleButtonGroup={(props) => (
        <ButtonGroup {...props}>
          <CloseDrawerButton resourceType="goodThings" />

          <ExpandCollapseToggle resourceType="goodThings" id={goodThingId} />
          <Flyout
            position="bottom-start"
            content={
              <GoodThingMoreMenu
                id={goodThingId}
                onGoodThingDeleted={() => {
                  if (!meetingId) {
                    void Router.push(route({ pathname: '/meetings' }))
                    return
                  }
                  void Router.push(route({ pathname: '/meetings/[id]', query: { id: meetingId } }))
                }}
                cached
              />
            }
          >
            <Button variant="muted" startIcon={<EllipsisIcon />} textHidden size="small">
              Open menu
            </Button>
          </Flyout>
        </ButtonGroup>
      )}
      renderTitleSection={() => (
        <Flex column sx={{ padding: 1 }}>
          <CreatedContext createdAt={goodThing.createdAt} parent={goodThing.parent ?? undefined} />
          <Text variant="h4" sx={{ paddingY: 1, marginBottom: '2px' }}>
            {`Good Things from ${goodThing.user.firstName}`}
          </Text>

          <Flex gap={2}>
            <BaseLink href="#comments" sx={{ fontSize: 0, color: 'text-light' }}>
              {commentsLabel}
            </BaseLink>
          </Flex>
        </Flex>
      )}
    >
      <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meeting?.id} />
      <RegisterCapability capability={CREATE_ISSUE} handler={() => Promise.resolve()} skip={!meeting?.id} />

      <DrawerSection
        title="About"
        icon={<InfoIcon />}
        isClosedOnRender={metadataSummary}
        right={metadataSummary ? <GoodThingMetadataSummary goodThingId={goodThingId} cached /> : null}
        dangerouslyCustomizeChildrenPadding={{
          paddingY: DRAWER_SECTION_INNER_PADDING - 1,
        }}
      >
        <GoodThingMetadata goodThingId={goodThingId} cached />
      </DrawerSection>

      <DrawerSection
        title="Description"
        icon={<FilePageTemplateIcon />}
        dangerouslyCustomizeChildrenPadding={{
          padding: 0,
        }}
      >
        <Flex column gap={2} sx={{ padding: 3 }}>
          <DocumentEditor
            documentId={goodThing.description.id}
            mode="compact"
            editable={goodThingEditable}
            sx={{
              '&': {
                display: 'flex',
                flexDirection: 'column',
              },
              '.ProseMirror': {
                margin: 0,
                padding: 0,
                minHeight: 'auto',
              },
            }}
          />
          <GoodThingEmojiReactions id={goodThingId} cached />
        </Flex>
      </DrawerSection>

      {goodThingActions ? (
        <DrawerSection
          id="actions"
          title="Actions"
          icon={<ActionsIcon />}
          dangerouslyCustomizeChildrenPadding={{
            padding: 0, // Since action table has borders that are full-width, it handles its own padding
          }}
        >
          <GoodThingActions id={goodThingId} />
        </DrawerSection>
      ) : null}

      <DrawerSection
        id="comments"
        title="Comments"
        icon={<CommentChatIcon />}
        isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasComments}
        right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{commentsLabel}</Text> : null}
        dangerouslyCustomizeChildrenPadding={{
          padding: DRAWER_SECTION_INNER_PADDING - 1,
        }}
      >
        <GoodThingComments goodThingId={goodThingId} />
      </DrawerSection>
    </DrawerLayout>
  )
}

import { Button, ButtonGroup, DRAWER_SECTION_INNER_PADDING, DrawerSection, Flex, Suspender } from '@mm/company-ui'
import { ActionsIcon, CommentChatIcon, FilePageTemplateIcon, PlusIcon } from '@mm/company-ui-icons'
import React, { useCallback, useState } from 'react'
import { ActionInlineViewDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { QueuedCapabilitiesInterceptor, useCapabilities } from '../../capabilities'
import { FOCUS_AND_QUOTE_COMMENT, FOCUS_COMMENT_INPUT } from '../../comments/capabilities'
import { useDynamicModules } from '../../hooks/useDynamicModules'
import { ActionComments } from './ActionComments'
import { ActionDescription } from './ActionDescription'
import { FollowUpActions } from './FollowUpActions'

export type ActionInlineViewProps = {
  actionId: string
}

export const ActionInlineView = ({ actionId }: ActionInlineViewProps) => {
  const [hasFollowUpActionsToShow, setHasFollowUpActionsToShow] = useState(false)

  const { data, loading, error } = useQuery(ActionInlineViewDocument, {
    variables: {
      actionId,
    },
  })

  const { execute } = useCapabilities()

  const { hasComments, hasDescription, handleShowComments, handleShowDescription } = useDynamicModules({
    id: actionId,
    module: 'Action',
  })

  const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(hasComments)

  const handleQueued = useCallback(() => {
    handleShowComments()
    setIsCommentDrawerOpen(true)
  }, [handleShowComments])

  if (data?.action == null) {
    if (loading) {
      return <Suspender />
    }

    throw error || new Error('Action not found')
  }

  return (
    <div sx={{ backgroundColor: 'background-light', height: '100%', paddingBottom: 2 }}>
      <Flex column gap={2} sx={{ paddingX: 2 }}>
        <ButtonGroup sx={{ marginBottom: hasDescription || hasComments ? 2 : 0 }}>
          {!hasDescription && (
            <Button startIcon={<PlusIcon />} size="small" onClick={handleShowDescription}>
              Add Description
            </Button>
          )}
          {!isCommentDrawerOpen && !hasComments && (
            <Button
              startIcon={<PlusIcon />}
              size="small"
              onClick={() => {
                execute(FOCUS_COMMENT_INPUT)
              }}
            >
              Add Comment
            </Button>
          )}
        </ButtonGroup>
      </Flex>

      <Flex column gap={1} sx={{ paddingX: 2 }}>
        {hasDescription && (
          <DrawerSection
            title="Description"
            icon={<FilePageTemplateIcon />}
            isClosedOnRender
            dangerouslyCustomizeChildrenPadding={{
              padding: 0, // Since content is a textarea, padding should be handled by that
            }}
          >
            <ActionDescription actionId={actionId} fillHeight={false} padding={DRAWER_SECTION_INNER_PADDING} />
          </DrawerSection>
        )}

        {
          <DrawerSection
            id="followUpActions"
            title="Follow-Up Actions"
            icon={<ActionsIcon />}
            isClosedOnRender
            dangerouslyCustomizeChildrenPadding={{
              padding: 0, // Since action table has borders that are full-width, it handles its own padding
            }}
            sx={{
              display: hasFollowUpActionsToShow ? undefined : 'none',
            }}
          >
            <FollowUpActions
              actionId={actionId}
              noAddNewAction
              onHasActionsToShowChange={setHasFollowUpActionsToShow}
            />
          </DrawerSection>
        }
        <QueuedCapabilitiesInterceptor capability={FOCUS_AND_QUOTE_COMMENT} onQueued={handleQueued}>
          <QueuedCapabilitiesInterceptor capability={FOCUS_COMMENT_INPUT} onQueued={handleQueued}>
            {(hasComments || isCommentDrawerOpen) && (
              <DrawerSection
                isOpen={isCommentDrawerOpen}
                setIsOpen={setIsCommentDrawerOpen}
                id="comments"
                title="Comments"
                icon={<CommentChatIcon />}
                dangerouslyCustomizeChildrenPadding={{
                  padding: DRAWER_SECTION_INNER_PADDING - 1, // Since comments have extra padding for hover effects, offset here
                }}
              >
                <ActionComments actionId={actionId} fixedComposer={false} order="newest" fixedAndScroll={false} />
              </DrawerSection>
            )}
          </QueuedCapabilitiesInterceptor>
        </QueuedCapabilitiesInterceptor>
      </Flex>
    </div>
  )
}

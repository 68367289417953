import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ClockIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="clock" viewBox="0 0 20 20" {...props}>
    <path
      d="M10 4V10H14.5M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      fill="none"
      strokeWidth="1.5"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </IconBase>
)

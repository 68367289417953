import { StatusCheckbox, Suspender } from '@mm/company-ui/src'
import React, { useCallback } from 'react'
import { FeedbackStatusCheckboxDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { editFeedbackStatusCommand } from '../commands/editFeedbackStatusCommand'

export const FeedbackStatusCheckbox = ({ id, cached }: { id: string; cached?: boolean }) => {
  const { data, loading, error } = useQuery(FeedbackStatusCheckboxDocument, {
    variables: {
      id,
    },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })
  const feedbackStatus = ['active', 'resolved'] as const

  const editFeedbackStatus = useCommand(editFeedbackStatusCommand)
  const handleChange = useCallback(
    (checkboxStatus: (typeof feedbackStatus)[number]) => {
      const status = checkboxStatus == 'resolved' ? 'DISCUSSED' : 'UNDISCUSSED'
      return editFeedbackStatus.execute({ feedbackId: id, status })
    },
    [editFeedbackStatus, id],
  )

  if (!data?.prototypeFeedback) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Topic not found')
  }

  return (
    <StatusCheckbox
      type="feedback"
      options={feedbackStatus}
      value={data.prototypeFeedback.status == 'DISCUSSED' ? 'resolved' : 'active'}
      shape="square"
      withDropdown={false}
      onChange={handleChange}
    />
  )
}

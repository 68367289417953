import { privacyMap } from '@mm/common'
import {
  AddWaitingForRowData,
  AddWaitingForRowProps,
  AnimateOpen,
  Button,
  Card,
  FastTable,
  Flex,
  Popover,
  Tag,
  useFeatureFlags,
} from '@mm/company-ui'
import { ChevronIcon, CircleCheckmarkIcon, ExpandMaximizeIcon, HistoryIcon, LoadingIcon } from '@mm/company-ui-icons'
import { AnimatePresence } from 'framer-motion'
import { DateTime } from 'luxon'
import React, { Suspense, useCallback, useMemo } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { ActionsDashboardRowDataFragment, CreateActionInput } from '../../../../gen/graphql/documents'
import {
  CapabilitiesProvider,
  QueuedCapabilitiesInterceptor,
  RegisterCapability,
  useRegisterCapability,
} from '../../../capabilities'
import { FOCUS_AND_QUOTE_COMMENT } from '../../../comments/capabilities'
import { CREATE_ISSUE } from '../../../decisions/capabilities'
import { useMeetingContext } from '../../../meetings'
import { useContextualDrawers, useRegisterDrawerNavigationProvider } from '../../../navigation'
import { ADD_TOPIC } from '../../../topics/capabilities'
import { ADD_ACTION } from '../../capabilities'
import { ActionRowsGroup } from '../../types'
import { ActionAssigneeCellContent } from '../ActionAssigneeCell'
import { ActionComments } from '../ActionComments'
import { ActionDatepicker } from '../ActionDatepicker'
import { ActionDescription } from '../ActionDescription'
import { ActionDoneNotDoneButtons } from '../ActionDoneNotDoneButtons'
import { ActionInlineView } from '../ActionInlineView'
import { ActionMoreMenu } from '../ActionMoreMenu'
import { ActionPrivacyTag } from '../ActionPrivacyTag'
import { ActionRowContext } from '../ActionRowContext'
import { ActionStatusCheckbox } from '../ActionStatusCheckbox'
import { ActionTableAddRow } from '../ActionTableAddRow'
import { ActionTableGroupHeaderMenu } from '../ActionTableGroupHeaderMenu'
import { ActionTitle } from '../ActionTitle'
import { ActionWaitingForCellContent } from '../ActionWaitingForCell'
import { CreatedContext } from '../CreatedContext'
import { EmptyActions } from '../EmptyActions'
import { QuickAddAction } from '../QuickAddAction'
import { ActionRowAnimateInDecorator } from './ActionRowAnimateInDecorator'

const getRowId = (row: ActionsDashboardRowDataFragment) => row.id

export type BaseActionsDashboardProps = Omit<
  FastTable.TableProps<ActionsDashboardRowDataFragment, ActionRowsGroup>,
  'columns' | 'getRowId'
> & {
  columns?: FastTable.TableColumn<ActionsDashboardRowDataFragment>[]
  condensed?: boolean
  addRowForcedOpen?: boolean
  noHeaders?: boolean
  skipRegisteringCapabilities?: boolean
  onCreateAction?: (data: CreateActionInput) => Promise<void>
  newActionDefaults?: Pick<AddWaitingForRowProps, 'defaultAssignee' | 'defaultWaitingFor' | 'defaultDueAt'>
  addRowStyles?: ThemeUIStyleObject
  addRowBottom?: boolean
  showAddRow?: boolean
}

export const defaultRenderEmptyPlaceholder = () => <EmptyActions width={0} height={0} />

export function BaseActionsDashboard({
  data,
  columns: columnsOverride,
  condensed,
  addRowForcedOpen,
  noHeaders,
  skipRegisteringCapabilities,
  decorateRow: decorateRowOverride,
  onCreateAction,
  newActionDefaults,
  addRowStyles,
  addRowBottom,
  showAddRow = true,
  ...rest
}: BaseActionsDashboardProps): React.ReactElement {
  const {
    copyPlainTextActions,
    actionsDashboardContext,
    actionsDashboardWithDoneNotDone,
    dangerouslyAllowForClickingOnContextInActionTable,
    addActionInTableHeader,
    toggleOpenActionsInline,
    actionsEditableInlineWithPersistentExpand,
    actionsHideWaitingForColumn,
    hideTableHeaders,
    moveActionNotDoneToDrawer,
    hideActionStatusToggle,
    rowMoreMenu,
    tableRowDetails,
    showPrivacyInTable,
  } = useFeatureFlags()
  const { showDrawer } = useContextualDrawers()
  const meeting = useMeetingContext()

  const [inlineActionOpen, setInlineActionOpen] = React.useState<Record<string, boolean>>({})

  useRegisterDrawerNavigationProvider(
    'action',
    useCallback(
      (id) => {
        const findGroup = (groups: ActionRowsGroup[]): ActionRowsGroup | null => {
          for (const group of groups) {
            if (group.rows?.some((row) => row.id === id)) return group
            const subGroup = findGroup(group.groups ?? [])
            if (subGroup != null) return subGroup
          }
          return null
        }
        const group = findGroup(data)
        const itemIndex = group?.rows?.findIndex((action) => action.id === id)
        if (group?.rows == null || itemIndex == null) return null

        const prevItem = group.rows[itemIndex - 1]
        const nextItem = group.rows[itemIndex + 1]

        const hasMoreItems = !!group.hasMore
        const fetchMore = group.fetchMore

        return {
          itemIndex,
          totalItemsCount: group.rows.length,
          hasMoreItems,
          displayPrevious:
            prevItem &&
            (() => {
              showDrawer('action', prevItem.id)
            }),
          displayNext:
            nextItem != null
              ? () => {
                  showDrawer('action', nextItem.id)
                }
              : !hasMoreItems || fetchMore == null
              ? null
              : async () => {
                  const data = await fetchMore()
                  const nextItemId = data?.edges[0]?.node.id
                  if (nextItemId != null) {
                    showDrawer('action', nextItemId)
                  }
                },
        }
      },
      [data, showDrawer],
    ),
  )

  const defaultDecorateRow = useCallback(
    (children: React.ReactElement, row: ActionsDashboardRowDataFragment) => {
      const isInlineOpen = inlineActionOpen[row.id] === true

      if (actionsEditableInlineWithPersistentExpand) {
        return (
          <CapabilitiesProvider>
            <QueuedCapabilitiesInterceptor
              capability={FOCUS_AND_QUOTE_COMMENT}
              onQueued={() => {
                setInlineActionOpen({ ...inlineActionOpen, [row.id]: true })
              }}
            >
              <div sx={{ backgroundColor: isInlineOpen ? 'background-light' : undefined }}>
                {children}
                <AnimatePresence initial={false}>
                  {isInlineOpen && (
                    <AnimateOpen>
                      <RegisterCapability
                        capability={ADD_TOPIC}
                        handler={() => Promise.resolve()}
                        skip={!meeting?.id}
                      />
                      <RegisterCapability
                        capability={CREATE_ISSUE}
                        handler={() => Promise.resolve()}
                        skip={!meeting?.id}
                      />
                      <div sx={{ borderBottom: '1px solid', borderBottomColor: 'border', pl: '100px' }}>
                        <Suspense
                          fallback={
                            <Flex align="center" justify="center" sx={{ height: '100%', minHeight: 60 }}>
                              <LoadingIcon width={20} height={20} color="text-disabled" />
                            </Flex>
                          }
                        >
                          <ActionInlineView actionId={row.id} />
                        </Suspense>
                      </div>
                    </AnimateOpen>
                  )}
                </AnimatePresence>
              </div>
            </QueuedCapabilitiesInterceptor>
          </CapabilitiesProvider>
        )
      }

      if (toggleOpenActionsInline) {
        return (
          <>
            {children}
            <AnimatePresence initial={false}>
              {isInlineOpen && (
                <AnimateOpen>
                  <CapabilitiesProvider>
                    <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meeting?.id} />
                    <RegisterCapability
                      capability={CREATE_ISSUE}
                      handler={() => Promise.resolve()}
                      skip={!meeting?.id}
                    />
                    <div sx={{ padding: 1, paddingTop: 0, borderBottom: '1px solid', borderBottomColor: 'border' }}>
                      <Suspense
                        fallback={
                          <Card variant="flat" sx={{ padding: 0 }}>
                            <Flex align="center" justify="center" sx={{ height: '100%', minHeight: 60 }}>
                              <LoadingIcon width={20} height={20} color="text-disabled" />
                            </Flex>
                          </Card>
                        }
                      >
                        <Card variant="flat" sx={{ padding: 0 }}>
                          <div sx={{ paddingX: 2, paddingBottom: 1 }}>
                            <Flex gap={1}>
                              <ActionTitle cached id={row.id} sx={{ flex: 1, fontSize: 1 }} />
                            </Flex>
                            <CreatedContext
                              createdAt={row.createdAt}
                              parent={row.followUpTo ?? row.parent ?? undefined}
                              parentMeeting={row.parentMeeting}
                              overflow="default"
                            />
                          </div>
                          <div
                            sx={{
                              borderTop: '1px solid',
                              borderTopColor: 'border',
                            }}
                          >
                            <ActionDescription actionId={row.id} fillHeight={false} padding={1} />
                          </div>
                          <div
                            sx={{
                              borderTop: '1px solid',
                              borderTopColor: 'border',
                              padding: 1,
                            }}
                          >
                            <ActionComments
                              actionId={row.id}
                              fixedComposer={false}
                              order="newest"
                              fixedAndScroll={false}
                            />
                          </div>
                        </Card>
                      </Suspense>
                    </div>
                  </CapabilitiesProvider>
                </AnimateOpen>
              )}
            </AnimatePresence>
          </>
        )
      }

      return children
    },
    [inlineActionOpen, toggleOpenActionsInline, meeting?.id, actionsEditableInlineWithPersistentExpand],
  )

  const decorateRow = useCallback(
    (children: React.ReactElement, row: ActionsDashboardRowDataFragment) => {
      children = (
        <ActionRowAnimateInDecorator noBorders={!actionsDashboardContext} id={row.id}>
          {children}
        </ActionRowAnimateInDecorator>
      )
      return (decorateRowOverride ?? defaultDecorateRow)(children, row)
    },
    [defaultDecorateRow, decorateRowOverride, actionsDashboardContext],
  )

  const [displayFullContext, setDisplayFullContext] = React.useState<Record<string, boolean>>({})

  const columns = useMemo<FastTable.TableColumn<ActionsDashboardRowDataFragment>[]>(() => {
    const calculatedColumns = columnsOverride ?? [
      {
        header: addActionInTableHeader ? (
          <Popover
            placement="bottom-start"
            key="action"
            render={({ hide }) => <QuickAddAction {...newActionDefaults} onDone={hide} />}
          >
            {({ getReferenceProps }) => (
              <Button startIcon={<CircleCheckmarkIcon />} size="small" {...getReferenceProps()} sx={{ marginLeft: -1 }}>
                Add Action
              </Button>
            )}
          </Popover>
        ) : (
          ''
        ),
        renderCell: ({ id }) =>
          actionsDashboardContext && !tableRowDetails ? (
            <Flex column sx={{ paddingY: 1.5, height: '100%' }} justify="center">
              <ActionStatusCheckbox cached id={id} />
            </Flex>
          ) : (
            <ActionStatusCheckbox cached id={id} />
          ),
        width: hideActionStatusToggle ? 28 : 44,
        disableResizing: true,
      },
      {
        header: '',
        renderCell: (row) => {
          const { id, titleHtml, createdAt, parent, followUpTo, dueAt, status, repeat } = row
          const isPastDue = !!dueAt && DateTime.fromMillis(dueAt).startOf('day') <= DateTime.now().startOf('day')
          const shouldRenderPastDue = isPastDue && status === 'ACTIVE' && actionsDashboardWithDoneNotDone
          const inlineOpen = inlineActionOpen[row.id] === true

          if (tableRowDetails) {
            return (
              <Flex column gap={0.5} data-clickable="true" sx={{ paddingY: 1.5, width: '100%' }}>
                <FastTable.RichTextCell value={titleHtml} sx={{ fontSize: tableRowDetails ? 1 : undefined }} />
                {tableRowDetails ? (
                  <ActionRowContext actionId={id} dueAt={dueAt ?? undefined} repeat={repeat ?? undefined} />
                ) : null}
              </Flex>
            )
          }

          if (actionsEditableInlineWithPersistentExpand) {
            return (
              <Flex row gap={1} sx={{ width: '100%' }}>
                <Flex column shrink sx={{ paddingBottom: 1, width: '100%' }}>
                  <ActionTitle
                    cached
                    id={row.id}
                    sx={{
                      flex: 1,
                      fontSize: 1,
                      fontWeight: 'body',
                      fontFamily: 'body',
                      '.ProseMirror': { paddingBottom: '2px', height: 30, overflow: 'hidden' },
                      '.ProseMirror-focused': { height: 'auto', overflow: 'initial' },
                      ...(inlineOpen
                        ? { '.ProseMirror': { paddingBottom: '2px', height: 'auto', overflow: 'initial' } }
                        : {}),
                    }}
                  />
                  <CreatedContext
                    highlightLinks
                    overflow={inlineOpen ? 'default' : 'clip'}
                    createdAt={createdAt}
                    parent={followUpTo ?? parent ?? undefined}
                    parentMeeting={row.parentMeeting}
                  />
                </Flex>

                <Flex row gap={1} sx={{ paddingTop: 2, flexShrink: 0 }}>
                  <Button
                    size="small"
                    textHidden
                    startIcon={<ChevronIcon sx={{ transform: `rotate(${!inlineOpen ? 0 : -180}deg)` }} />}
                    onClick={() => {
                      setInlineActionOpen({ ...inlineActionOpen, [row.id]: !inlineActionOpen[row.id] })
                    }}
                    sx={{ paddingX: 0.5, height: 24 }}
                  >
                    Expand Action Inline
                  </Button>

                  {shouldRenderPastDue && !moveActionNotDoneToDrawer ? (
                    <div sx={{ flexShrink: 0 }}>
                      <ActionDoneNotDoneButtons {...row} />
                    </div>
                  ) : null}
                </Flex>
              </Flex>
            )
          }

          if (toggleOpenActionsInline) {
            return (
              <>
                <Flex
                  row
                  align="center"
                  gap={1}
                  sx={{
                    width: '100%',
                    '&:hover .open-drawer-button': {
                      opacity: 1,
                    },
                  }}
                >
                  <Flex
                    column
                    shrink
                    sx={{ paddingY: 1, width: '100%' }}
                    onClick={() => {
                      setInlineActionOpen({ ...inlineActionOpen, [row.id]: !inlineActionOpen[row.id] })
                    }}
                  >
                    <FastTable.RichTextCell value={titleHtml} clickable={false} />
                    <CreatedContext
                      withoutLink
                      createdAt={createdAt}
                      parent={followUpTo ?? parent ?? undefined}
                      parentMeeting={row.parentMeeting}
                    />
                  </Flex>

                  <div data-clickable="true" className="open-drawer-button" sx={{ opacity: 0 }}>
                    <Button size="small" textHidden startIcon={<ExpandMaximizeIcon />}>
                      Open Action Drawer
                    </Button>
                  </div>

                  {shouldRenderPastDue && !moveActionNotDoneToDrawer ? (
                    <div sx={{ flexShrink: 0 }}>
                      <ActionDoneNotDoneButtons {...row} />
                    </div>
                  ) : null}
                </Flex>
              </>
            )
          }

          const titleNode = <FastTable.RichTextCell value={titleHtml} />

          return actionsDashboardContext ? (
            <Flex row align="center" gap={1} sx={{ width: '100%' }}>
              {dangerouslyAllowForClickingOnContextInActionTable ? (
                <Flex column shrink sx={{ paddingY: 1, width: '100%' }}>
                  {titleNode}
                  <CreatedContext
                    withoutLink
                    onClick={() => {
                      setDisplayFullContext({ ...displayFullContext, [row.id]: !displayFullContext[row.id] })
                    }}
                    createdAt={createdAt}
                    parent={followUpTo ?? parent ?? undefined}
                    parentMeeting={row.parentMeeting}
                    overflow={displayFullContext[row.id] === true ? 'default' : 'clip'}
                  />
                </Flex>
              ) : (
                <Flex column shrink sx={{ paddingY: 1, width: '100%' }} data-clickable="true">
                  {titleNode}
                  <CreatedContext
                    withoutLink
                    createdAt={createdAt}
                    parent={followUpTo ?? parent ?? undefined}
                    parentMeeting={row.parentMeeting}
                  />
                </Flex>
              )}

              {shouldRenderPastDue && !moveActionNotDoneToDrawer ? (
                <div sx={{ flexShrink: 0 }}>
                  <ActionDoneNotDoneButtons {...row} />
                </div>
              ) : null}
            </Flex>
          ) : (
            titleNode
          )
        },
        width: 100,
      },
      {
        header: 'Due',
        renderCell: ({ id, status }) => {
          if (tableRowDetails) {
            if (status === 'DONE') {
              return (
                <Flex data-clickable="true" justify="flex-end" sx={{ width: '100%' }}>
                  <Tag value="DONE" variant="success">
                    Done
                  </Tag>
                </Flex>
              )
            } else if (status === 'ON_HOLD') {
              return (
                <Flex data-clickable="true" justify="flex-end" sx={{ width: '100%' }}>
                  <Tag value="ON_HOLD" variant="alert">
                    Blocked
                  </Tag>
                </Flex>
              )
            } else if (status === 'CANCELLED') {
              return (
                <Flex data-clickable="true" justify="flex-end" sx={{ width: '100%' }}>
                  <Tag value="CANCELLED" variant="default">
                    Canceled
                  </Tag>
                </Flex>
              )
            }

            return
          }

          return actionsDashboardContext && !tableRowDetails ? (
            <Flex column sx={{ paddingY: 1, height: '100%' }}>
              <ActionDatepicker
                id={id}
                showIcon={false}
                textAlign="left"
                redIfPastDue={true}
                showRescheduledPrefix={true}
                cached
              />
            </Flex>
          ) : (
            <ActionDatepicker
              id={id}
              showIcon={false}
              textAlign="left"
              redIfPastDue={true}
              showRescheduledPrefix={true}
              cached
            />
          )
        },
        width: 100,
        disableResizing: true,
      },

      ...(showPrivacyInTable
        ? [
            {
              header: 'Access',
              renderCell: ({ id }: ActionsDashboardRowDataFragment) => (
                <ActionPrivacyTag
                  actionId={id}
                  sx={{ background: 'none', px: 0 }}
                  showPrivacyLabel={false}
                  cached
                  disabled
                />
              ),
              width: 32,
              disableResizing: true,
            },
          ]
        : []),
      {
        header: 'DRI',
        renderCell: ({ id, assignee }) =>
          actionsDashboardContext && !tableRowDetails ? (
            <Flex column sx={{ paddingY: 1, height: '100%' }}>
              <ActionAssigneeCellContent assigneeId={assignee.id} actionId={id} avatarOnly />
            </Flex>
          ) : (
            <ActionAssigneeCellContent
              assigneeId={assignee.id}
              actionId={id}
              avatarOnly
              size={tableRowDetails ? 'large' : undefined}
            />
          ),
        width: tableRowDetails ? 36 : 40,
        disableResizing: true,
      },
      {
        header: 'Waiting',
        renderCell: ({ id, waitingFor }) =>
          waitingFor ? (
            actionsDashboardContext && !tableRowDetails ? (
              <Flex column sx={{ paddingY: 1, height: '100%' }}>
                <ActionWaitingForCellContent waitingForId={waitingFor?.id} actionId={id} avatarOnly />
              </Flex>
            ) : (
              <ActionWaitingForCellContent waitingForId={waitingFor?.id} actionId={id} avatarOnly />
            )
          ) : null,
        width: 40,
        disableResizing: true,
      },
    ]

    return calculatedColumns.filter((column) => {
      if (actionsHideWaitingForColumn && column.header === 'Waiting') {
        return false
      }

      return true
    })
  }, [
    actionsDashboardContext,
    actionsDashboardWithDoneNotDone,
    columnsOverride,
    dangerouslyAllowForClickingOnContextInActionTable,
    displayFullContext,
    addActionInTableHeader,
    newActionDefaults,
    inlineActionOpen,
    setInlineActionOpen,
    toggleOpenActionsInline,
    actionsEditableInlineWithPersistentExpand,
    actionsHideWaitingForColumn,
    moveActionNotDoneToDrawer,
    hideActionStatusToggle,
    tableRowDetails,
    showPrivacyInTable,
  ])

  const handleOnAdd = useCallback(
    async (data: AddWaitingForRowData) => {
      const actionPayload = {
        title: data.title,
        privacy: privacyMap.PRIVATE,
        dateRescheduled: false,
        description: data.description,
        dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
        assignee: data.assignee,
        waitingFor: data.waitingFor,
        repeat: data.repeat,
      }

      await onCreateAction?.(actionPayload)
    },
    [onCreateAction],
  )

  useRegisterCapability(ADD_ACTION, handleOnAdd, skipRegisteringCapabilities)

  const handleOnRowClick = useCallback(
    (row: ActionsDashboardRowDataFragment, event: React.MouseEvent<HTMLDivElement>) => {
      showDrawer('action', row.id, event)
    },
    [showDrawer],
  )

  const renderRowGroupExtraHeader = useCallback(
    (group: ActionRowsGroup) =>
      group.hasAddMore ? (
        <ActionTableAddRow
          sx={{ ...addRowStyles, border: !group.rows?.length ? 'none' : undefined }}
          {...group.newActionDefaults}
          onAdd={handleOnAdd}
          condensed={condensed}
          addRowForcedOpen={addRowForcedOpen}
        />
      ) : null,
    [handleOnAdd, condensed, addRowForcedOpen, addRowStyles],
  )

  const renderRowMenu = useCallback(({ id }: ActionsDashboardRowDataFragment) => <ActionMoreMenu id={id} />, [])

  const renderRowGroupMenu = useCallback(
    (rowGroup: ActionRowsGroup) => <ActionTableGroupHeaderMenu rowGroup={rowGroup} />,
    [],
  )

  const renderRowGroupFooter = useCallback((group: ActionRowsGroup) => {
    if (group.hasMore) {
      return (
        <div sx={{ px: 4 }}>
          <Button
            variant="muted"
            size="small"
            loading={group.loadingMore}
            startIcon={<HistoryIcon />}
            onClick={group.fetchMore}
          >
            Show more
          </Button>
        </div>
      )
    }

    return null
  }, [])

  const renderRowGroupEmptyPlaceholder = useCallback((group: ActionRowsGroup) => group.renderEmptyPlaceholder?.(), [])

  return (
    <FastTable.Table
      columns={columns}
      data={data}
      noBorders={!actionsDashboardContext}
      stickyHeader
      getRowId={getRowId}
      decorateRow={decorateRow}
      onRowClick={handleOnRowClick}
      noHeaders={noHeaders == null ? hideTableHeaders : noHeaders}
      renderRowGroupExtraHeader={
        showAddRow ? (addActionInTableHeader || addRowBottom ? undefined : renderRowGroupExtraHeader) : undefined
      }
      renderRowGroupFooter={showAddRow ? (addRowBottom ? renderRowGroupExtraHeader : renderRowGroupFooter) : undefined}
      renderRowGroupMenu={copyPlainTextActions ? renderRowGroupMenu : undefined}
      renderRowMenu={renderRowMenu}
      noDragHandle={!rowMoreMenu}
      renderRowGroupEmptyPlaceholder={renderRowGroupEmptyPlaceholder}
      {...rest}
    />
  )
}

import { Suspender } from '@mm/company-ui'
import React from 'react'
import { DecisionTitleDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { ControlledHtmlTitleInput } from '../../components/ControlledHtmlTitleInput'
import { editDecisionTitleCommand } from '../commands/editDecisionTitle'

export type DecisionTitleProps = {
  id: string
  cached?: boolean
  className?: string
}

export const DecisionTitle = ({ id, cached, className }: DecisionTitleProps) => {
  const editDecisionTitle = useCommand(editDecisionTitleCommand)
  const { data, loading, error } = useQuery(DecisionTitleDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })

  const decision = data?.decision
  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Decision not found')
  }

  const handleChange = (title: string) => {
    void editDecisionTitle.execute({
      decisionId: id,
      title,
    })
  }

  return (
    <ControlledHtmlTitleInput
      value={decision.titleHtml}
      placeholder="Type issue title here..."
      onDebouncedChange={handleChange}
      className={className}
    />
  )
}

export type DisplayListCountSchemes = 'LABEL_WITH_OVERFLOW' | 'LABEL' | 'NUMBER_WITH_OVERFLOW' | 'NUMBER'

export type ListCountCollection = {
  edges: Array<{ node: unknown }>
  pageInfo: {
    hasNextPage: boolean
  }
}

export const formatListCountDisplay = (
  connection: ListCountCollection | null | undefined,
  strings?: {
    label?: string
    singular?: string
    empty?: string
  },
  scheme: DisplayListCountSchemes = 'LABEL_WITH_OVERFLOW',
): string => {
  const count = connection?.edges.length
  const hasMore = connection?.pageInfo.hasNextPage

  if (count === undefined) {
    return ''
  }

  switch (scheme) {
    case 'LABEL_WITH_OVERFLOW': {
      return count > 0
        ? `${count}${hasMore ? '+' : ''} ${count === 1 ? strings?.singular || 'item' : strings?.label || 'items'}`
        : strings?.empty !== undefined
        ? strings?.empty
        : 'No items'
    }
    case 'LABEL': {
      return count > 0
        ? `${count === 1 ? strings?.singular || 'item' : strings?.label || 'items'}`
        : strings?.empty !== undefined
        ? strings?.empty
        : 'No items'
    }
    case 'NUMBER_WITH_OVERFLOW': {
      return count > 0 ? `${count}${hasMore ? '+' : ''}` : strings?.empty || '0'
    }
    case 'NUMBER': {
      return count > 0 ? `${count}` : strings?.empty !== undefined ? strings?.empty : '0'
    }
  }
}

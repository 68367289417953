import { useId } from '@react-aria/utils'
import React from 'react'
import { Flex } from '../Flex'
import { Text } from '../Text'

export type PanelMetadataItemProps = {
  icon?: React.ReactElement
  title: string
  column?: boolean
  className?: string
  children?: React.ReactNode | ((props: { labelId: string }) => React.ReactNode)
}

const MIN_HEIGHT = 32

export const PanelMetadataItem = ({ children, icon, title, className }: PanelMetadataItemProps) => {
  const labelId = useId()
  return (
    <Flex row gap={0.5} sx={{ minHeight: MIN_HEIGHT }} role="row" className={className}>
      <Flex align="center" gap={1} sx={{ height: MIN_HEIGHT, width: 170, flexShrink: 0 }}>
        {icon && React.cloneElement(icon, { color: 'text-light' })}
        <Text color="text-light" id={labelId}>
          {title}
        </Text>
      </Flex>

      {children}
    </Flex>
  )
}

import { useFeatureFlags } from '@mm/company-ui/src'
import React from 'react'
import { ActionCommentsList, AddActionCommentBox } from '../../comments'

export type ActionCommentsProps = {
  actionId: string
  fixedComposer?: boolean
  order?: 'newest' | 'oldest'
  fixedAndScroll?: boolean
}

export const ActionComments = ({
  actionId,
  order = 'oldest',
  fixedAndScroll = true,
  fixedComposer = true,
}: ActionCommentsProps) => {
  const { flipComments } = useFeatureFlags()

  return (
    <ActionCommentsList
      actionId={actionId}
      order={order}
      fixedAndScroll={fixedAndScroll}
      fixedComposer={fixedComposer}
      composer={!flipComments ? <AddActionCommentBox actionId={actionId} /> : null}
    />
  )
}

import { openActionStatuses } from '@mm/common'
import { ActionsDashboardRowDataFragment, MeetingUpdateRequestBannerDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'
import { useActiveUser } from '../../users'

export const useMeetingPastDueActionsForUser = (meetingId: string): Array<ActionsDashboardRowDataFragment> => {
  const { activeCompanyId } = useActiveCompanyId()

  const activeUser = useActiveUser()

  const filterToUserId = activeUser?.id

  const query = useQuery(MeetingUpdateRequestBannerDocument, {
    variables: {
      meetingId,
      status: openActionStatuses,
      orderBy: 'DUE_AT_ASC',
      companyId: activeCompanyId,
      assignee: filterToUserId,
    },
  })

  if (query.data?.meeting?.actions?.edges?.length) {
    return query.data.meeting.actions.edges
      .filter(
        ({ node }) =>
          node.dueAt &&
          new Date(node.dueAt) < new Date() &&
          node.status === 'ACTIVE' &&
          node.assignee.id === filterToUserId,
      )
      .map(({ node }) => node)
  }

  return []
}

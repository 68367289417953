import { AddWaitingForRowData, Suspender, useFeatureFlags } from '@mm/company-ui'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { ActionDescriptionDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { CapabilitiesProvider, RegisterCapability } from '../../capabilities'
import { useCommand } from '../../commands'
import { useActiveCompanyId } from '../../companies'
import { DocumentEditor } from '../../editor'
import { ADD_ACTION } from '../capabilities'
import { createActionCommand } from '../commands/createAction'

export type ActionDescriptionProps = {
  actionId: string
  fillHeight?: boolean
  padding?: number
}

export const ActionDescription = ({ actionId, fillHeight = true, padding = 3 }: ActionDescriptionProps) => {
  const { activeCompanyId } = useActiveCompanyId()
  const { descriptionActions } = useFeatureFlags()
  const addAction = useCommand(createActionCommand)
  const { data, loading, error } = useQuery(ActionDescriptionDocument, {
    variables: {
      id: actionId,
    },
  })

  const handleOnAddAction = useCallback(
    async (data: AddWaitingForRowData) => {
      void addAction.execute({
        companyId: activeCompanyId,
        data: {
          title: data.title,
          assignee: data.assignee,
          dateRescheduled: false,
          description: data.description,
          dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
          waitingFor: data.waitingFor,
          repeat: data.repeat,
        },
      })
    },
    [activeCompanyId, addAction],
  )

  const action = data?.action
  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Action not found')
  }

  return (
    <CapabilitiesProvider>
      <RegisterCapability capability={ADD_ACTION} handler={handleOnAddAction} skip={!descriptionActions} />

      <DocumentEditor
        key={action.description.id}
        documentId={action.description.id}
        placeholder="Enter description here..."
        mode="compact"
        sx={{
          '&': {
            display: 'flex',
            flexDirection: 'column',

            ...(fillHeight && {
              height: '100%',
            }),
          },
          '.ProseMirror': {
            margin: 0,
            padding,
            minHeight: 'auto',

            ...(fillHeight && {
              padding: 5,
              overflowY: 'scroll',
              height: '100%',
              minHeight: '100%',
            }),
          },
        }}
      />
    </CapabilitiesProvider>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CommentChatIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="comment-chat" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.300293 9.2C0.300293 4.54199 4.80116 1.10001 10.0003 1.10001C15.1994 1.10001 19.7003 4.54199 19.7003 9.2C19.7003 11.0958 18.9006 12.7947 17.6627 14.1557L18.0944 17.8968C18.1308 18.2127 17.9979 18.5243 17.7447 18.7167C17.4915 18.909 17.1557 18.9535 16.8612 18.8337L12.4249 17.0291C11.6226 17.2335 10.828 17.3 10.0003 17.3C4.80116 17.3 0.300293 13.858 0.300293 9.2ZM10.0003 2.90001C5.43943 2.90001 2.10029 5.85802 2.10029 9.2C2.10029 12.542 5.43943 15.5 10.0003 15.5C10.8247 15.5 11.5315 15.425 12.2217 15.218C12.4183 15.159 12.6292 15.169 12.8194 15.2463L16.1321 16.5939L15.8262 13.9432C15.7947 13.6702 15.8896 13.3979 16.0839 13.2036C17.2333 12.0542 17.9003 10.6722 17.9003 9.2C17.9003 5.85802 14.5612 2.90001 10.0003 2.90001ZM6.1001 7.00001C6.1001 6.50295 6.50304 6.10001 7.0001 6.10001H13.0001C13.4972 6.10001 13.9001 6.50295 13.9001 7.00001C13.9001 7.49706 13.4972 7.90001 13.0001 7.90001H7.0001C6.50304 7.90001 6.1001 7.49706 6.1001 7.00001ZM7.0001 10.1C6.50304 10.1 6.1001 10.5029 6.1001 11C6.1001 11.4971 6.50304 11.9 7.0001 11.9H11.0001C11.4972 11.9 11.9001 11.4971 11.9001 11C11.9001 10.5029 11.4972 10.1 11.0001 10.1H7.0001Z"
    />
  </IconBase>
)

import { Suspender, useFeatureFlags } from '@mm/company-ui'
import { GoalsIcon, LinkIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { ActionMetadataGoalsDocument, RemoveActionGoalContextDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { PanelMetadataItemChips } from '../../components'
import { PanelMetadataLinkChips } from '../../components/PanelMetadataLinkChips'

export const ActionMetadataGoals = ({ actionId, isLink }: { actionId: string; isLink?: boolean }) => {
  const { data, loading, error, fetchMore } = useQuery(ActionMetadataGoalsDocument, {
    variables: {
      id: actionId,
    },
  })
  const { updateActionContext } = useFeatureFlags()
  const goals = data?.action?.goals
  const [removeActionContext] = useMutation(RemoveActionGoalContextDocument)
  const handleRemove = useCallback(
    async (id: string) => {
      await removeActionContext({
        variables: {
          id: actionId,
          contextRef: id,
        },
        optimisticResponse: {
          removeActionContext: {
            id: actionId,
            __typename: 'Action',
            goals: {
              __typename: 'GoalsConnection',
              edges: goals?.edges.filter(({ node }) => node.id !== id) || [],
              pageInfo: {
                hasNextPage: goals?.pageInfo.hasNextPage ?? false,
                endCursor: goals?.pageInfo.endCursor ?? '',
                __typename: goals?.pageInfo.__typename,
              },
            },
          },
        },
      })
    },
    [actionId, goals, removeActionContext],
  )

  if (error) {
    throw error
  }

  if (loading) {
    return <Suspender />
  }

  if (isLink) {
    return goals?.edges.length ? (
      <PanelMetadataLinkChips
        icon={<GoalsIcon color="text-light" />}
        chips={goals.edges.map(({ node }) => ({
          id: node.id,
          label: node.title,
          href: { pathname: '/goals/[id]', query: { id: node.id } },
          type: 'Goal',
        }))}
        hasNextPage={goals.pageInfo.hasNextPage}
        onLoadMore={() => fetchMore({ variables: { after: goals.pageInfo.endCursor } })}
        onRemove={handleRemove}
      />
    ) : null
  }

  return goals?.edges.length ? (
    <PanelMetadataItemChips
      title="Goals"
      icon={<LinkIcon />}
      chips={goals.edges.map(({ node }) => ({
        id: node.id,
        label: node.title,
        href: { pathname: '/goals/[id]', query: { id: node.id } },
        __typename: node.__typename,
      }))}
      hasNextPage={goals.pageInfo.hasNextPage}
      onLoadMore={() => fetchMore({ variables: { after: goals.pageInfo.endCursor } })}
      isRemovable={updateActionContext}
      onRemove={handleRemove}
    />
  ) : null
}

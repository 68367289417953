import { ArrowBackRewindResetIcon } from '@mm/company-ui-icons'
import type { ActionRepeatType, TopicRepeatType } from '@mm/graphql-helpers/gen/graphql/graphql'
import React from 'react'
import { DisplayDateSchemes, formatDateDisplay } from '../../helpers/formatDateDisplay'
import { Select } from '../Select'
import { Tooltip } from '../Tooltip'
import { Datepicker, DatepickerProps } from './Datepicker'

type RecurringDatepickerProps<T extends ActionRepeatType | TopicRepeatType> = {
  repeat?: T | null
  onRepeatChange?: (value: { repeat?: T; repeatEndDate?: Date | null; repeatNTimes?: number }) => void
  repeatOptions?: { label: string; value: T }[]
  isClearable?: boolean
} & DatepickerProps

export const RecurringDatepicker = <T extends ActionRepeatType | TopicRepeatType>({
  repeat,
  onRepeatChange,
  repeatOptions,
  prefix,
  isClearable = true,
  ...props
}: RecurringDatepickerProps<T>) => {
  return (
    <Datepicker
      {...props}
      renderExtraFooter={() => (
        <div>
          <Select
            menuPlacement="auto"
            variant="muted"
            controlStyleOverrides={{ height: 28 }}
            isClearable={isClearable}
            placeholder="Repeat"
            defaultValue={repeatOptions?.find((opt) => opt.value === repeat)}
            onChange={(val) => {
              onRepeatChange?.({
                repeat: val?.value,
              })
            }}
            data-testid="action-repeat"
            options={repeatOptions}
          />
        </div>
      )}
      prefix={<RecurringDatePrefixIcon repeat={repeat} prefix={prefix} date={props.value || ''} />}
    />
  )
}

type RecurringDatePrefixProps<T extends ActionRepeatType | TopicRepeatType> = {
  repeat?: T | null
  prefix?: React.ReactElement | string | null
  date?: Date | string | number
  icon?: React.ReactElement
}

export const RecurringDatePrefixIcon = <T extends ActionRepeatType | TopicRepeatType>({
  repeat,
  prefix,
  date,
  icon,
}: RecurringDatePrefixProps<T>) => {
  const recurringDateTooltipOption: Record<ActionRepeatType | TopicRepeatType, DisplayDateSchemes | undefined> = {
    WEEKLY: 'WEEKDAY_ONLY',
    MONTHLY: 'DAY_ONLY',
    YEARLY: 'DAY_WITH_MONTH',
    BIWEEKLY: 'WEEKDAY_ONLY',
    QUARTERLY: undefined,
    DAILY: undefined,
    NONE: undefined,
  }

  const recurNextOn =
    date && typeof date != 'string' && repeat ? formatDateDisplay(date, recurringDateTooltipOption[repeat]) : undefined

  return (
    <>
      {repeat != null && repeat != 'NONE' && (
        <Tooltip
          referenceWrapperProps={{ sx: { display: 'flex', gap: 0.25 } }}
          description={`Repeats ${repeat.toLowerCase()}  ${recurNextOn ? `on ${recurNextOn}` : ''}`}
          position="bottom"
        >
          {icon ? icon : <ArrowBackRewindResetIcon />}
        </Tooltip>
      )}
      {prefix}
    </>
  )
}

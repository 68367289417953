import {
  EditGoalAssigneeCommandDocument,
  EditGoalAssigneeCommandGetGoalDocument,
  EditGoalAssigneeCommandGetUserDocument,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditGoalAssigneeCommandValue = {
  assigneeId: string
  goalId: string
}

export const editGoalAssigneeCommand: Command<EditGoalAssigneeCommandValue> = {
  id: 'editGoalAssigneeCommand',
  name: 'Edit Assignee',
  canExecute: async ({ assigneeId, goalId }, { apolloClient }) => {
    const { data } = await apolloClient.query({
      query: EditGoalAssigneeCommandGetGoalDocument,
      variables: {
        goalId,
      },
    })
    return data.goal?.assignee?.id !== assigneeId
  },
  execute: async ({ assigneeId, goalId }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const currentGoal = await apolloClient.query({
      query: EditGoalAssigneeCommandGetGoalDocument,
      variables: { goalId },
    })
    const currentAssigneeId = currentGoal.data?.goal?.assignee?.id

    const nextAssignee = await apolloClient.query({
      fetchPolicy: 'cache-only',
      query: EditGoalAssigneeCommandGetUserDocument,
      variables: { userId: assigneeId },
    })

    await apolloClient.mutate({
      mutation: EditGoalAssigneeCommandDocument,
      variables: {
        goalId,
        assigneeId,
      },
      ...(nextAssignee.data.user && {
        optimisticResponse: {
          updateGoal: {
            __typename: 'Goal',
            id: goalId,
            assignee: nextAssignee.data.user,
          },
        },
      }),
    })

    if (currentAssigneeId) {
      return {
        args: {
          goalId,
          assigneeId: currentAssigneeId,
        },
        execute: async ({ assigneeId, goalId }, { apolloClient }) => {
          const nextAssignee = await apolloClient.query({
            fetchPolicy: 'cache-only',
            query: EditGoalAssigneeCommandGetUserDocument,
            variables: { userId: assigneeId },
          })

          await apolloClient.mutate({
            mutation: EditGoalAssigneeCommandDocument,
            variables: {
              goalId,
              assigneeId,
            },
            ...(nextAssignee.data.user && {
              optimisticResponse: {
                updateGoal: {
                  __typename: 'Goal',
                  id: goalId,
                  assignee: nextAssignee.data.user,
                },
              },
            }),
          })
        },
      }
    }

    return
  },
}

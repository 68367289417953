import { Flex, Suspender, Tag, UserSelectTarget } from '@mm/company-ui'
import React from 'react'
import { TopicMetadataSummaryDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { TopicPrivacyTag } from './TopicPrivacyTag'
import { topicStatusActiveTag, topicStatusResolvedTag } from './TopicStatusTagSelect'

export type TopicMetadataSummaryProps = {
  topicId: string
  meetingId?: string
}

export const TopicMetadataSummary = ({ topicId }: TopicMetadataSummaryProps) => {
  const { data, loading, error } = useQuery(TopicMetadataSummaryDocument, {
    variables: {
      id: topicId,
    },
  })
  const topic = data?.topic

  if (topic == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Topic not found')
  }

  return (
    <Flex row gap={2} align="center">
      {topic.status === 'UNDISCUSSED' ? topicStatusActiveTag : topicStatusResolvedTag}

      {topic.owner ? (
        <Tag>
          Presenter
          <UserSelectTarget value={{ node: topic.owner }} disabled />
        </Tag>
      ) : null}

      <TopicPrivacyTag topicId={topicId} />
    </Flex>
  )
}

import { EditableRef } from '@mm/company-ui'
import React from 'react'
import { AddCommentReplyBoxDocument, AddCommentReplyBoxSubmitDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useMutation, useQuery } from '../../apollo'
import { addCommentReplyToCache } from '../utils/addCommentToCache'
import { AddCommentBox } from './AddCommentBox'

export type AddCommentReplyBoxProps = {
  replyTo: string
  cached?: boolean
  onCancel?: () => void
}

export const AddCommentReplyBox = React.forwardRef<EditableRef, AddCommentReplyBoxProps>(function AddCommentReplyBox(
  { replyTo, cached, onCancel },
  ref,
) {
  const { data } = useQuery(AddCommentReplyBoxDocument, {
    fetchPolicy: cached ? 'cache-only' : 'cache-and-network',
    variables: {
      replyTo,
    },
  })
  const [addComment] = useMutation(AddCommentReplyBoxSubmitDocument, {
    onCompleted: () => {
      capture('Comment Created', {
        parent: 'Comment',
        isReply: true,
      })
    },
    update: (cache, { data }) => {
      const comment = data?.createCommentReply
      if (comment?.__typename === 'Comment') {
        addCommentReplyToCache(cache, comment)
      }
    },
  })

  return (
    <AddCommentBox
      ref={ref}
      id={`replyTo:${replyTo}`}
      placeholder={
        data?.comment?.author ? `Reply to ${data.comment.author.firstName}'s comment...` : 'Reply to a comment...'
      }
      onSubmit={async ({ htmlBody, author }) => {
        const { data } = await addComment({
          variables: {
            replyTo,
            htmlBody,
            author,
          },
        })
        if (data?.createCommentReply.__typename !== 'Comment') {
          throw new Error('failed to create a comment')
        }
      }}
      showCancel
      onCancel={onCancel}
    />
  )
})

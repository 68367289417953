import { ConfirmPopover, FlyoutItem, useConfirmPopover, useFeatureFlags, useModal } from '@mm/company-ui'
import { ArchiveBoxIcon, DecisionIcon, DeleteIcon, ExpandMaximizeIcon, LoadingIcon } from '@mm/company-ui-icons'
import Router from 'next/router'
import { route } from 'nextjs-routes'
import React from 'react'
import { TopicHtmlContentDocument, TopicMoreMenuDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useLazyQuery, useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { CreateMeetingDecisionModal } from '../../meetings'
import { useContextualDrawers } from '../../navigation'
import { archiveTopicCommand } from '../commands/archiveTopicCommand'
import { deleteTopicCommand } from '../commands/deleteTopicCommand'
import { detachTopicFromMeetingCommand } from '../commands/detachTopicFromMeetingCommand'

export type TopicMoreMenuProps = {
  id: string
  meetingId?: string
  topicSectionId?: string
  onTopicDeleted?: () => void

  cached?: boolean
}

export const TopicMoreMenu = ({ id, meetingId, topicSectionId, cached, onTopicDeleted }: TopicMoreMenuProps) => {
  const { data, loading, error } = useQuery(TopicMoreMenuDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })
  const [fetchTopicContent, { loading: topicHtmlLoading }] = useLazyQuery(TopicHtmlContentDocument)

  const { confirmBeforeDelete, convertTopicToIssue, archiveTopicManually } = useFeatureFlags()
  const deleteTopic = useCommand(deleteTopicCommand)
  const detachTopicFromMeeting = useCommand(detachTopicFromMeetingCommand)
  const { execute: archiveTopic, loading: archiveTopicLoading } = useCommand(archiveTopicCommand)

  const { triggerConfirm, confirmControlProps } = useConfirmPopover()
  const { showModal } = useModal()
  const { showDrawer } = useContextualDrawers()

  const topic = data?.topic
  if (topic == null) {
    if (loading) {
      return <LoadingIcon />
    }
    throw error ?? new Error('Topic not found')
  }
  const topicMeetings = data?.topic?.meetings?.edges.map((edge) => edge.node) || []

  //To handle fullscreen view of topic(outside meeting context)
  const fallbackMeetingId = topicMeetings.length === 1 ? topicMeetings[0]?.id : undefined
  const issueMeetingId = meetingId || fallbackMeetingId

  return (
    <>
      <FlyoutItem
        onClick={() => {
          void Router.push(route({ pathname: '/topics/[id]', query: { id } }))
          capture('More Menu Selected', {
            parent: 'Topic',
            menuItem: 'View Full Screen',
          })
        }}
        startIcon={<ExpandMaximizeIcon />}
      >
        View Full Screen
      </FlyoutItem>

      {/* TODO Support convert to issue in fullscreen view for multi-meeting topic if required - OS-734 */}
      {convertTopicToIssue && issueMeetingId && (
        <FlyoutItem
          onClick={async () => {
            const { data } = await fetchTopicContent({ variables: { id } })
            showModal((props) => {
              return (
                <CreateMeetingDecisionModal
                  {...props}
                  meetingId={issueMeetingId}
                  fromTopicId={topic.id}
                  topicSectionId={topicSectionId}
                  title={data?.topic?.titleHtml}
                  defaultIssueText={data?.topic?.description.htmlContent}
                  defaultLoomLink={data?.topic?.loomRecordingLink ?? undefined}
                  onComplete={(id) => {
                    showDrawer('decision', id, undefined, topicSectionId)
                  }}
                />
              )
            })
          }}
          startIcon={topicHtmlLoading ? <LoadingIcon /> : <DecisionIcon />}
        >
          Convert to Issue
        </FlyoutItem>
      )}
      {archiveTopicManually && (
        <FlyoutItem
          onClick={async () => {
            if (!meetingId) return
            await archiveTopic({
              topicId: id,
              meetingId,
            })
          }}
          startIcon={archiveTopicLoading ? <LoadingIcon /> : <ArchiveBoxIcon />}
        >
          Archive
        </FlyoutItem>
      )}

      <FlyoutItem
        onClick={async () => {
          if (topicMeetings.length > 1 && meetingId) {
            void detachTopicFromMeeting.execute({ topicId: id, meetingId: meetingId, onTopicDetach: onTopicDeleted })
          } else {
            if (topicMeetings.length > 1 || confirmBeforeDelete) {
              if (!(await triggerConfirm())) return
            }
            void deleteTopic.execute({ topicId: id, onTopicDeleted })
          }
        }}
        startIcon={deleteTopic?.loading || detachTopicFromMeeting?.loading ? <LoadingIcon /> : <DeleteIcon />}
      >
        {topicMeetings.length > 1 || detachTopicFromMeeting.loading ? 'Remove' : 'Delete'}
      </FlyoutItem>
      <ConfirmPopover
        {...confirmControlProps}
        zIndex={10000}
        title="Permanently delete the Topic?"
        description="This action cannot be reversed. Deleting the Topic would remove it from all meetings."
        confirmLabel="Delete permanently"
      />
    </>
  )
}

import { Flex, NotFoundBanner, Suspender, Text, formatDateDisplay } from '@mm/company-ui'
import Link from 'next/link'
import React from 'react'
import { GoalViewDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../../modules/apollo'
import { CloseDrawerButton } from '../../components'
import { GoalAssigneePicker } from '../../goals/components/GoalAssigneePicker'
import { GoalComments } from '../../goals/components/GoalComments'
import { GoalDescription } from '../../goals/components/GoalDescription'
import { GoalDueDatePicker } from '../../goals/components/GoalDueDatePicker'
import { GoalPrivacyTag } from '../../goals/components/GoalPrivacyTag'
import { GoalStatusPicker } from '../../goals/components/GoalStatusPicker'
import { GoalTitle } from '../../goals/components/GoalTitle'
import { ActionRow } from './ActionRow'
import { GoalUpdateListItem } from './GoalUpdateListItem'

export type GoalViewProps = {
  goalId: string
  inDrawer?: boolean
}

export function GoalView({ goalId, inDrawer }: GoalViewProps) {
  const { data, loading } = useQuery(GoalViewDocument, {
    variables: {
      id: goalId,
    },
  })

  const goal = data?.goal
  const goalActions = data?.goal?.actions?.edges.map(({ node }) => node)
  const goalUpdates = data?.goal?.updates

  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return (
    <>
      {inDrawer ? (
        <Flex row sx={{ height: 7, gap: 1, paddingX: 2.5 }} align="center">
          <CloseDrawerButton resourceType="accountability/goals" />
        </Flex>
      ) : (
        <Flex row sx={{ height: 7, gap: 1, paddingX: 2.5 }} align="center">
          <Link href="/accountability/goals">
            <Text
              color="text-light"
              variant="small"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Goals
            </Text>
          </Link>
          <Text color="text-light">›</Text>
          <Text color="text-light" variant="small" sx={{ maxWidth: 150 }} overflow="ellipsis">
            {goal.title}
          </Text>
        </Flex>
      )}

      <div sx={{ width: '100%', maxWidth: '800px', paddingX: 2, marginX: 'auto' }}>
        <Flex
          column
          gap={1}
          sx={{ paddingTop: 1, paddingBottom: 3, borderBottom: '1px solid', borderBottomColor: 'border' }}
        >
          <GoalTitle id={goalId} cached />

          <GoalDescription goalId={goalId} fillHeight={false} padding={0} />
        </Flex>

        <Flex
          column
          gap={1}
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
            borderBottom: '1px solid',
            borderBottomColor: 'border',
          }}
        >
          <div sx={{ display: 'grid', gridTemplateColumns: '1fr 85%', gridRowGap: 2 }}>
            <Text color="text-light">Status</Text>
            <div>
              <GoalStatusPicker goalId={goalId} cached skipNotDoneFlow />
            </div>

            <Text color="text-light">Due date</Text>
            <div sx={{ marginY: '-4px' }}>
              <GoalDueDatePicker goalId={goalId} cached />
            </div>

            <Text color="text-light">Assignee</Text>
            <div sx={{ marginY: '-4px' }}>
              <GoalAssigneePicker goalId={goalId} cached />
            </div>

            <Text color="text-light">Privacy</Text>
            <div>
              <GoalPrivacyTag goalId={goalId} />
            </div>
          </div>
        </Flex>

        <Flex
          column
          gap={1}
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
            borderBottom: '1px solid',
            borderBottomColor: 'border',
          }}
        >
          <Text bold>Actions</Text>
          <div>
            {goalActions?.map((action) => (
              <ActionRow key={action.id} action={action} />
            ))}
          </div>
        </Flex>

        <Flex
          column
          gap={1}
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
            borderBottom: '1px solid',
            borderBottomColor: 'border',
          }}
        >
          <Text bold>Updates</Text>
          <Flex column gap={2}>
            {goalUpdates?.map((update) => {
              return update ? (
                <Flex column key={update.id} gap={1}>
                  <Text uppercase bold variant="small" color="text-light">
                    {formatDateDisplay(update.createdAt, 'SHORT_MONTH_WITH_DATE')}
                  </Text>

                  <GoalUpdateListItem goalUpdate={update} updateCreatedAt={update.createdAt} />
                </Flex>
              ) : null
            })}
          </Flex>
        </Flex>

        <Flex
          column
          gap={1}
          sx={{
            paddingTop: 3,
            paddingBottom: 3,
          }}
        >
          <Text bold>Comments</Text>
          <GoalComments goalId={goalId} order="newest" fixedAndScroll={false} />
        </Flex>
      </div>
    </>
  )
}

import { useTheme } from '@emotion/react'
import {
  AnimateOpen,
  Button,
  Flex,
  Popover,
  PopoverRenderProps,
  PopupWrapper,
  SuspenseLoader,
  Text,
} from '@mm/company-ui'
import {
  ActionsIcon,
  CarretDownIcon,
  CommentChatIcon,
  EllipsisIcon,
  LoadingIcon,
  ModeratorPresenterIcon,
} from '@mm/company-ui-icons'
import { AnimatePresence } from 'framer-motion'
import React, { Suspense } from 'react'
import { CapabilitiesProvider } from '../capabilities'
import { useFeatureFlags } from '../featureFlags'
import { ContextualDrawerNavigation } from '../navigation'
import { CloseDrawerButton, CloseDrawerButtonProps } from './CloseDrawerButton'
import { FullscreenDrawerButton } from './FullscreenDrawerButton'

type DrawerViewProps = {
  noScroll?: boolean
  resourceId: string
  actionTitle?: string
  resourceType: CloseDrawerButtonProps['resourceType']
  renderMoreMenu?: (props: PopoverRenderProps) => React.ReactElement

  renderExtraMenuItems?: React.ReactElement
  renderBanner?: React.ReactElement | null
  renderTitle: React.ReactElement
  renderMetadata: React.ReactElement
  renderDescription: React.ReactElement
  renderActions?: boolean | React.ReactElement
  renderActionsWithFilter?: React.ReactElement
  renderUpdates?: boolean | React.ReactElement
  renderComments?: React.ReactElement
  renderCommentComposer?: React.ReactElement | null
}

// const resourceTypeToString: Record<DrawerViewProps['resourceType'], string> = {
//   topics: 'Topic',
//   actions: 'Action',
//   profile: 'User',
//   goals: 'Goal',
//   goodThings: 'Good Thing',
//   decisions: 'Issue',
//   updates: 'Update',
//   'prototype/feedback': 'Feedback',
//   'accountability/actions': 'Action',
//   'accountability/goals': 'Goal',
//   'accountability/team': 'User',
//   'accountability/updates': 'Update',
// }

export function DrawerView({
  noScroll,
  resourceId,
  actionTitle,
  resourceType,
  renderMoreMenu,
  renderExtraMenuItems,
  renderBanner,
  renderTitle,
  renderMetadata,
  renderDescription,
  renderActions,
  renderActionsWithFilter,
  renderUpdates,
  renderComments,
  renderCommentComposer,
}: DrawerViewProps) {
  const {
    nonBlockingDrawer,
    contextualDrawerNavigation,
    drawerViewActionsCollapsible,
    meetingPrep,
    intercomIntegration,
  } = useFeatureFlags()
  const theme = useTheme()

  const [isOpen, setIsOpen] = React.useState(true)

  return (
    <Flex
      column
      sx={{ backgroundColor: nonBlockingDrawer ? 'white' : 'background-light', height: '100%', width: '100%' }}
    >
      <Flex row gap={2} justify="space-between" sx={{ paddingX: 3, paddingY: 2 }}>
        <Flex row gap={2}>
          <Flex row gap={1}>
            <CloseDrawerButton resourceType={resourceType} />
            <FullscreenDrawerButton resourceType={resourceType} id={resourceId} />
          </Flex>

          {contextualDrawerNavigation && <ContextualDrawerNavigation />}
        </Flex>

        <Flex row gap={1}>
          {renderExtraMenuItems}
          {renderMoreMenu ? (
            <Popover
              placement="bottom-start"
              render={(popoverProps) => <PopupWrapper sx={{ padding: 0 }}>{renderMoreMenu(popoverProps)}</PopupWrapper>}
            >
              {({ getReferenceProps }) => (
                <Button variant="muted" startIcon={<EllipsisIcon />} textHidden size="small" {...getReferenceProps()}>
                  Open menu
                </Button>
              )}
            </Popover>
          ) : null}
        </Flex>
      </Flex>

      <div sx={{ flex: 1, overflowY: noScroll ? undefined : 'auto' }}>
        <Suspense
          fallback={
            <Flex align="center" justify="center" sx={{ height: '100%', minHeight: 60 }}>
              <LoadingIcon width={20} height={20} color="text-disabled" />
            </Flex>
          }
        >
          <div sx={{ paddingX: 3, paddingBottom: 3 }}>
            {renderBanner ? <div sx={{ marginBottom: 2 }}>{renderBanner}</div> : null}
            <Flex column>
              {/* <Text variant="small" color="text-light" uppercase>
                {resourceTypeToString[resourceType]}
              </Text> */}
              {renderTitle}
              <div sx={{ height: 1 }} />
              {renderMetadata}
            </Flex>

            <Flex column>
              <div sx={{ marginX: -1, marginTop: 2 }}>{renderDescription}</div>

              {renderActions || renderActionsWithFilter ? (
                <div>
                  <Flex
                    row
                    align="center"
                    gap={1}
                    sx={{ paddingY: 2, paddingTop: 4, cursor: drawerViewActionsCollapsible ? 'pointer' : undefined }}
                    onClick={
                      drawerViewActionsCollapsible
                        ? () => {
                            setIsOpen((isOpen) => !isOpen)
                          }
                        : undefined
                    }
                  >
                    <ActionsIcon />
                    <Text variant="h5">{actionTitle || 'Actions'}</Text>
                    {drawerViewActionsCollapsible && (
                      <Flex sx={{ marginRight: 'auto' }} align="center">
                        <CarretDownIcon
                          sx={{
                            transition: theme.transition.default,
                            transform: `rotate(${isOpen ? 0 : -180}deg)`,
                          }}
                        />
                      </Flex>
                    )}
                  </Flex>

                  <AnimatePresence initial={false}>
                    <AnimateOpen>
                      <SuspenseLoader>
                        <div sx={{ display: !isOpen ? 'none' : undefined }}>
                          {renderActionsWithFilter}
                          {renderActions ? (
                            <div
                              sx={{
                                border: '1px solid',
                                borderColor: 'border',
                                borderRadius: 'medium',
                              }}
                            >
                              {renderActions}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </SuspenseLoader>
                    </AnimateOpen>
                  </AnimatePresence>
                </div>
              ) : null}

              {meetingPrep && renderUpdates ? (
                <div>
                  <Flex row align="center" gap={1} sx={{ paddingY: 2, paddingTop: 4 }}>
                    <ModeratorPresenterIcon />
                    <Text variant="h5">Updates</Text>
                  </Flex>
                  <div>{renderUpdates}</div>
                </div>
              ) : null}

              {renderComments ? (
                <div>
                  <Flex row align="center" gap={1} sx={{ paddingY: 2, paddingTop: 4 }}>
                    <CommentChatIcon />
                    <Text variant="h5">Comments</Text>
                  </Flex>
                  <div sx={{ marginX: -1 }}>{renderComments}</div>
                </div>
              ) : null}
            </Flex>
          </div>
        </Suspense>
      </div>

      {renderCommentComposer ? (
        <div
          sx={{
            paddingX: 2,
            paddingY: 1,
            paddingRight: intercomIntegration ? 10 : 1,
            borderTop: '1px solid',
            borderTopColor: 'border',
          }}
        >
          <CapabilitiesProvider>{renderCommentComposer}</CapabilitiesProvider>
        </div>
      ) : null}
    </Flex>
  )
}

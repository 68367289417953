import { privacyMap } from '@mm/common'
import { AddWaitingForRowData } from '@mm/company-ui'
import { produce } from 'immer'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { forwardRef, useCallback, useImperativeHandle } from 'react'
import {
  ActionsDashboardRowDataFragment,
  CreateActionInput,
  FollowUpActionsCreateDocument,
  FollowUpActionsDocument,
} from '../../../gen/graphql/documents'
import { ActionsUpdatedTopic, markActionAsCreatedHere } from '../../actions'
import { ActionTableAddRow } from '../../actions/components/ActionTableAddRow'
import { capture } from '../../analytics'
import { useMutation, useQuery } from '../../apollo'
import { useEmitEventBusEvent, useOnEventBusEvent } from '../../eventbus'
import { useMeetingContext } from '../../meetings'
import { ActionRow } from './ActionRow'

export type NextActionsListRef = { addAction: (data: AddWaitingForRowData) => Promise<void> }

export type NextActionsListProps = {
  actionId: string
  onCreate?: (action: ActionsDashboardRowDataFragment) => void
}

export const NextActionsList = forwardRef<unknown, NextActionsListProps>(({ actionId, onCreate }, ref) => {
  const meeting = useMeetingContext()
  // const [isAddActionOpen, setIsAddActionOpen] = useState<boolean | undefined>(true)

  const { data, updateQuery } = useQuery(FollowUpActionsDocument, {
    variables: {
      actionId,
    },
  })
  const emit = useEmitEventBusEvent(ActionsUpdatedTopic)
  const [createFollowUpAction] = useMutation(FollowUpActionsCreateDocument, {
    update: (cache, { data }) => {
      const action = data?.createFollowUpAction
      if (action?.__typename === 'Action') {
        capture('Action Created', {
          parent: 'Action',
          parentMeetingId: meeting?.id,
        })
        markActionAsCreatedHere(cache, action)
        emit({ type: 'upserted', action })
        onCreate?.(action)
      }
    },
  })

  useOnEventBusEvent(ActionsUpdatedTopic, (event) => {
    if (event.type !== 'upserted') return
    const { action } = event

    updateQuery((data, { variables: { actionId: followUpToId } = {} }) =>
      produce(data, (draft) => {
        const followUpActions = draft.action?.followUpActions
        if (followUpActions == null) return

        _.remove(followUpActions.edges, ({ node }) => node.id === action.id)

        if (action.followUpTo?.id === followUpToId) {
          const targetIdx = followUpActions.edges.findIndex(({ node }) => node.createdAt > action.createdAt)

          if (targetIdx === -1) {
            followUpActions.edges.push({ node: action })
          } else {
            followUpActions.edges.splice(targetIdx, 0, { node: action })
          }
        }
      }),
    )
  })

  useImperativeHandle<unknown, NextActionsListRef>(ref, () => ({
    async addAction(data) {
      await handleOnAdd(data)
    },
  }))

  const handleOnAdd = useCallback(
    async (data: AddWaitingForRowData) => {
      const actionPayload: CreateActionInput = {
        title: data.title,
        privacy: privacyMap.PUBLIC,
        dateRescheduled: false,
        description: data.description,
        dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
        assignee: data.assignee,
        waitingFor: data.waitingFor,
        parentMeetingId: meeting?.id,
      }

      await createFollowUpAction({
        variables: {
          actionId,
          data: actionPayload,
        },
      })
    },
    [actionId, createFollowUpAction, meeting],
  )

  const action = data?.action

  const actions = action?.followUpActions?.edges.map(({ node }) => node) ?? []

  return (
    <>
      <ActionTableAddRow
        onAdd={handleOnAdd}
        // addRowForcedOpen={isAddActionOpen}
        defaultAssignee={action?.assignee.id}
        defaultWaitingFor={action?.waitingFor.id}
        // onChangeDirty={(isDirty) => {
        //   if (isDirty) setIsAddActionOpen(undefined)
        // }}
        // onCancel={(src) => {
        //   if (src === 'button' || src === 'esc-key') {
        //     setIsAddActionOpen(undefined)
        //   }
        // }}
        sx={{ px: 0, minHeight: 'auto' }}
        containerStyles={{
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
        }}
      />

      {actions.length ? (
        <div sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium', marginTop: 1 }}>
          {actions.map((action, i) => (
            <ActionRow
              key={action.id}
              action={action}
              withStatus={false}
              hasBottomBorder={i !== actions.length - 1}
              hasContext={false}
            />
          ))}
        </div>
      ) : null}
    </>
  )
})

NextActionsList.displayName = 'NextActionsList'

import React, { useCallback, useMemo, useState } from 'react'

export type UseOnScreenResult<E> = [ref: React.Ref<E>, onScreen: boolean]

export function useOnScreen<E extends HTMLElement>(
  initialExpectedOnScreenState = false,
  rootMargin = '0px',
): UseOnScreenResult<E> {
  const [onScreen, setOnScreen] = useState(initialExpectedOnScreenState)
  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          if (entry) {
            setOnScreen(entry.isIntersecting)
          }
        },
        {
          rootMargin,
        },
      ),
    [rootMargin],
  )

  return [
    useCallback(
      (el: HTMLElement | null) => {
        observer.disconnect()
        if (el != null && el instanceof HTMLElement) {
          observer.observe(el)
        }
      },
      [observer],
    ),
    onScreen,
  ]
}

import { produce } from 'immer'
import { useCallback } from 'react'
import {
  PrototypeFeedbackEmojiReactionsDataFragmentDoc,
  UseAddPrototypeFeedbackEmojiReactionDocument,
} from '../../../gen/graphql/documents'
import { useApolloClient, useMutation } from '../../apollo'
import { useActiveUser } from '../../users'

export const useAddPrototypeFeedbackEmojiReaction = (prototypeFeedbackId: string) => {
  const client = useApolloClient()
  const [addEmojiReaction] = useMutation(UseAddPrototypeFeedbackEmojiReactionDocument)
  const activeUser = useActiveUser()

  return useCallback(
    (emoji: string) => {
      const data = client.readFragment(
        {
          id: client.cache.identify({ __typename: 'ProrotypeFeedback', id: prototypeFeedbackId }),
          fragment: PrototypeFeedbackEmojiReactionsDataFragmentDoc,
        },
        true,
      )

      return addEmojiReaction({
        variables: {
          id: prototypeFeedbackId,
          emoji,
        },
        optimisticResponse:
          data && activeUser
            ? {
                addPrototypeFeedbackEmojiReaction: produce(data, (draft) => {
                  if (
                    draft.emojiReactions?.every(
                      (reaction) => reaction.user.id !== activeUser.id || reaction.emoji !== emoji,
                    )
                  ) {
                    draft.emojiReactions.push({
                      user: activeUser,
                      emoji,
                    })
                  }
                }),
              }
            : undefined,
      })
    },
    [client, prototypeFeedbackId, addEmojiReaction, activeUser],
  )
}

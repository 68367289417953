import { formatUserDisplayName, UserSelectTarget } from '@mm/company-ui'
import { FilePageTemplateIcon } from '@mm/company-ui-icons'
import React from 'react'

export type FeedbackToChipProps =
  | {
      __typename?: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email: string
      className?: string
      isText?: boolean
    }
  | {
      __typename?: 'Meeting'
      id: string
      title: string
      className?: string
      isText?: boolean
    }

export function FeedbackToChip({ className, isText, ...props }: FeedbackToChipProps) {
  if (isText) {
    return (
      <>
        {props.__typename === 'User'
          ? formatUserDisplayName(props)
          : props.__typename === 'Meeting'
          ? props.title.length > 29
            ? `${props.title.substring(0, 30)}...`
            : props.title
          : ''}
      </>
    )
  }
  return props.__typename === 'User' ? (
    <UserSelectTarget withName value={{ node: props }} />
  ) : props.__typename === 'Meeting' ? (
    <UserSelectTarget
      withName
      value={{
        node: {
          id: props.id,
          firstName: props.title.length > 29 ? `${props.title.substring(0, 30)}...` : props.title,
          lastName: '',
          email: '',
        },
      }}
      icon={<FilePageTemplateIcon height={12} width={12} />}
      className={className}
    />
  ) : null
}

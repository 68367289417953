import { Suspender } from '@mm/company-ui'
import { convertHtmlToTextTitle, sanitizeTitleHtmlContent } from '@mm/tiptap-extension'
import React from 'react'
import { ActionTitleDocument, ActionTitleUpdateDocument } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useMutation, useQuery } from '../../apollo'
import { ControlledHtmlTitleInput } from '../../components/ControlledHtmlTitleInput'

export type ActionTitleProps = {
  id: string
  cached?: boolean
  className?: string
}

export const ActionTitle = ({ id, cached, className }: ActionTitleProps) => {
  const { data, loading, error } = useQuery(ActionTitleDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })

  const [updateTitle] = useMutation(ActionTitleUpdateDocument, {
    onCompleted: ({ updateAction }) => {
      if (updateAction.__typename == 'Action') {
        capture('Action Updated', {
          field: 'title',
        })
      }
    },
  })

  const action = data?.action
  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Action not found')
  }

  const handleChange = (title: string) => {
    void updateTitle({
      variables: {
        id,
        title,
      },
      optimisticResponse: {
        updateAction: {
          __typename: 'Action',
          id,
          title: convertHtmlToTextTitle(title),
          titleHtml: sanitizeTitleHtmlContent(title),
        },
      },
    })
  }

  return (
    <ControlledHtmlTitleInput
      sx={{ width: '100%', fontSize: 2 }}
      value={action.titleHtml}
      placeholder="Type action title here..."
      onDebouncedChange={handleChange}
      className={className}
    />
  )
}

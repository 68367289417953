import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const TeamIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="team" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.18 1.1699C11.8152 0.796379 12.5371 0.599079 13.2728 0.599121C14.0086 0.599163 14.7304 0.796545 15.3656 1.17014C16.0006 1.54365 16.5258 2.07963 16.8901 2.72258C17.2543 3.36537 17.4454 4.09324 17.4453 4.8332V6.49987L17.4453 6.5018C17.4437 7.23942 17.2515 7.96453 16.8868 8.60435C16.6114 9.08741 16.2448 9.50945 15.8089 9.84674V10.0593L18.8244 11.2904C19.145 11.421 19.4176 11.6454 19.6081 11.9317C19.7987 12.218 19.8996 12.5541 19.8998 12.8968V17.4999C19.8998 17.9969 19.4969 18.3999 18.9998 18.3999C18.5027 18.3999 18.0998 17.9969 18.0998 17.4999V12.9388L15.0844 11.7077L15.0834 11.7073C14.7636 11.5769 14.492 11.3533 14.3016 11.0678C14.1111 10.7822 14.0098 10.4468 14.009 10.1047L14.009 10.1024V9.3707C14.009 9.05088 14.1787 8.75509 14.4548 8.5937C14.8133 8.38417 15.1133 8.08098 15.323 7.71299C15.5327 7.34519 15.6442 6.92644 15.6453 6.49895V4.8332C15.6454 4.40286 15.5342 3.98079 15.3241 3.60996C15.114 3.23924 14.813 2.93343 14.453 2.72164C14.093 2.50994 13.686 2.39914 13.2727 2.39912C12.8594 2.3991 12.4524 2.50984 12.0924 2.72151C11.664 2.97347 11.1124 2.83038 10.8604 2.40191C10.6085 1.97344 10.7516 1.42185 11.18 1.1699ZM15.7628 10.0404L15.7638 10.0408L15.7628 10.0404Z"
    />
    <path d="M6.87288 1.4332C7.98494 1.4332 9.04804 1.8833 9.82925 2.67898C10.6099 3.4741 11.0456 4.54906 11.0456 5.66654C11.0456 6.23413 11.3376 6.88593 11.7168 7.46531C11.8971 7.7408 12.0787 7.97207 12.2149 8.13391C12.5404 8.52068 12.8549 8.93387 12.5571 9.45805C12.4647 9.62065 12.2078 9.9941 11.6709 10.3764C11.2788 10.6557 10.7486 10.9335 10.0397 11.15L12.4247 12.1237C12.7454 12.2543 13.0179 12.4787 13.2085 12.7651C13.399 13.0514 13.4999 13.3875 13.5001 13.7301L13.5001 17.4999C13.5001 17.9969 13.0972 18.3999 12.6001 18.3999C12.1031 18.3999 11.7001 17.9969 11.7001 17.4999V13.7722L8.68369 12.5406C8.36385 12.4102 8.09226 12.1866 7.90185 11.9011C7.71211 11.6166 7.60973 11.2776 7.60924 10.9357V10.5599C7.60924 10.1097 7.94182 9.7288 8.38786 9.66809C9.53356 9.51215 10.2003 9.19689 10.5654 8.9526C10.4531 8.80496 10.3321 8.63646 10.2108 8.4511C9.7718 7.78048 9.2456 6.76561 9.2456 5.66654C9.2456 5.0159 8.99168 4.39517 8.54484 3.94004C8.09854 3.48548 7.49678 3.2332 6.87288 3.2332C6.20446 3.2332 5.57556 3.45651 5.10081 3.94004C4.65397 4.39517 4.40005 5.0159 4.40005 5.66654C4.40005 6.76561 3.87385 7.78048 3.4349 8.4511C3.31357 8.63646 3.19259 8.80496 3.08026 8.9526C3.44538 9.19689 4.11209 9.51215 5.25779 9.66809C5.70383 9.7288 6.03641 10.1097 6.03641 10.5599V10.9357C6.03592 11.2776 5.93354 11.6166 5.7438 11.9011C5.55339 12.1866 5.2818 12.4102 4.96196 12.5406L1.94551 13.7722V17.4999C1.94551 17.9969 1.54256 18.3999 1.04551 18.3999C0.548451 18.3999 0.145508 17.9969 0.145508 17.4999V13.7301C0.145721 13.3875 0.246644 13.0514 0.437164 12.7651C0.627734 12.4787 0.900291 12.2543 1.22093 12.1237L3.60591 11.15C2.89701 10.9335 2.36688 10.6557 1.9747 10.3764C1.66746 10.1576 1.45231 9.94311 1.30635 9.7673C0.936771 9.32213 0.843662 8.7827 1.27046 8.31696C1.38225 8.19497 1.65887 7.87776 1.92884 7.46531C2.30807 6.88593 2.60005 6.23413 2.60005 5.66654C2.60005 4.54906 3.03574 3.4741 3.8164 2.67898C4.62767 1.85268 5.72375 1.4332 6.87288 1.4332Z" />
  </IconBase>
)

import styled from '@emotion/styled'
import type { ActionRepeatType, MeetingPrivacyLevel } from '@mm/graphql-helpers/gen/graphql/graphql'
import * as chrono from 'chrono-node'
import { useAnimation } from 'framer-motion'
import isHotkey from 'is-hotkey'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ThemeUIStyleObject, css } from 'theme-ui'
import { useDebouncedCallback, useOutsideClick } from '../../hooks'
import { Button } from '../Button'
import { Datepicker, DatepickerProps } from '../Datepicker'
import { RecurringDatepicker } from '../Datepicker/RecurringDatepicker'
import { Editable, EditableFormController, SingleLineEditable } from '../Editable'
import { Flex, MotionFlex } from '../Flex'
import { getFloatingUiRoot, usePopoverInterceptor } from '../FloatingUI'
import { Text } from '../Text'
import { Tooltip } from '../Tooltip'

export type AddWaitingForRowData = {
  title: string
  description: string
  privacy?: MeetingPrivacyLevel

  assignee?: string | null
  waitingFor?: string | null
  dueAt?: Date | null
  repeat?: ActionRepeatType
  repeatEndDate?: Date | null
  repeatNTimes?: number
}

export type AddWaitingForRowProps = {
  titlePlaceholder?: string
  defaultTitle?: string

  descriptionPlaceholder?: string
  defaultDescription?: string
  maxDescriptionLines?: number

  hasDueAt?: boolean
  hasPrivacy?: boolean
  defaultPrivacy?: MeetingPrivacyLevel
  defaultDueAt?: Date | null
  dueAtSuggestedDates?: DatepickerProps['suggestedDates']

  hasAssignee?: boolean
  defaultAssignee?: string | null

  hasWaitingFor?: boolean
  defaultWaitingFor?: string | null

  renderUserPicker: (props: {
    'aria-label': string
    value: string | null
    onChange: (value: string[]) => void
  }) => React.ReactElement

  renderPrivacy?: (props: {
    value: MeetingPrivacyLevel
    onChange: (value: MeetingPrivacyLevel) => void
  }) => React.ReactElement

  renderDatePicker?: (props: { value: Date | null; onChange: (value: Date | null) => void }) => React.ReactElement

  useRecurringDatepicker?: boolean
  condensed?: boolean
  forcedOpen?: boolean
  autofocus?: boolean
  refocusTitleOnSubmit?: boolean

  hasCancel?: boolean
  onCancel?: (source: string) => void
  onChangeDirty?: (isDirty: boolean) => void
  onChangeTitleAndDescription?: (title: string, description: string) => void

  hasClear?: boolean

  extraControls?: React.ReactNode

  onAdd?: (data: AddWaitingForRowData) => Promise<void | boolean> | void | boolean
  onComplete?: () => void

  className?: string

  containerStyles?: ThemeUIStyleObject
  renderBottomContent?: React.ReactNode
  repeatOptions?: { label: string; value: ActionRepeatType }[]

  isDisabled?: boolean
  disabledTooltip?: string

  useDueDateFromTitle?: boolean
}

interface RenderUserPickerParams {
  selectedGroupHeading?: string
  noSelectedText?: string
  userPickerType: 'assignee' | 'waitingFor'
}

const collapsedHeight = 50

export type AddWaitingForRowRef = {
  focusTitle: () => void
  setTitle: (title: string) => void
}

export const AddWaitingForRow = React.forwardRef<AddWaitingForRowRef, AddWaitingForRowProps>(
  (
    {
      titlePlaceholder = 'Add item...',
      defaultTitle,
      defaultPrivacy = 'PRIVATE',
      descriptionPlaceholder = 'Description...',
      defaultDescription,
      maxDescriptionLines,
      hasPrivacy,
      renderPrivacy,
      hasDueAt,
      renderDatePicker,
      useRecurringDatepicker,
      defaultDueAt = new Date(),
      dueAtSuggestedDates,
      hasAssignee,
      defaultAssignee,
      hasWaitingFor,
      defaultWaitingFor,
      renderUserPicker: renderOutsideUserPicker,
      condensed = false,
      forcedOpen = false,
      autofocus = false,
      refocusTitleOnSubmit = true,
      hasCancel,
      onCancel,
      hasClear,
      extraControls,
      onAdd,
      onComplete,
      onChangeDirty,
      onChangeTitleAndDescription,
      className,
      containerStyles,
      renderBottomContent,
      repeatOptions,
      isDisabled,
      disabledTooltip,
      useDueDateFromTitle,
    },
    forwardRef,
  ) => {
    const [isOpenAnimated, setIsOpenAnimated] = useState(false)
    const [focused, setFocused] = useState(false)
    const containerRef = useRef<HTMLFormElement>(null)
    const animateShake = useAnimation()
    const { registerInterceptor } = usePopoverInterceptor()
    const skipInterceptorRef = useRef(false)

    const {
      register,
      handleSubmit,
      // destructuring here is important to prevent bugs due to the formState rules
      // see https://react-hook-form.com/api/useform/formstate#rules
      formState: { isDirty, isSubmitting },
      control,
      reset,
      setValue,
      setFocus,
      watch,
    } = useForm<AddWaitingForRowData>({
      mode: 'onChange',

      defaultValues: {
        title: defaultTitle,
        description: defaultDescription ?? '<p></p>',
        privacy: defaultPrivacy,

        assignee: defaultAssignee,
        waitingFor: defaultWaitingFor,
        dueAt: null,
      },
    })

    useEffect(() => {
      onChangeDirty?.(isDirty)
    }, [onChangeDirty, isDirty])

    const watchTitle = watch('title')
    const watchDescription = watch('description')

    useEffect(() => {
      if (!isDirty) return
      onChangeTitleAndDescription?.(watchTitle, watchDescription)
    }, [onChangeTitleAndDescription, watchTitle, watchDescription, isDirty])

    useEffect(() => {
      skipInterceptorRef.current = false
      return registerInterceptor(
        () =>
          isDirty &&
          !skipInterceptorRef.current &&
          !window.confirm('You are in the middle of creating an action. Are you sure you want to navigate away?'),
      )
    }, [isDirty, registerInterceptor])

    useImperativeHandle(
      forwardRef,
      () => ({
        setTitle: (title) => {
          setValue('title', title, { shouldValidate: true })
        },
        focusTitle: () => {
          setFocus('title')
        },
      }),
      [setFocus, setValue],
    )

    const submit = handleSubmit(
      async (values) => {
        if (isSubmitting) {
          return
        }

        skipInterceptorRef.current = true

        const result = await onAdd?.({
          ...values,
          dueAt: values.dueAt ?? defaultDueAt,
        })

        if (result !== false) {
          reset({
            title: '',
            description: '<p></p>',
            dueAt: values.dueAt,
            assignee: values.assignee,
            privacy: values.privacy,
            waitingFor: values.waitingFor,
          })
          if (refocusTitleOnSubmit) {
            setFocus('title')
          }
          onComplete?.()
        }
      },
      () => {
        void animateShake.start('shake')
      },
    )

    const renderUserPicker = ({ userPickerType = 'assignee' }: RenderUserPickerParams) => {
      return (
        <Controller
          name={userPickerType}
          control={control}
          rules={{ required: userPickerType === 'waitingFor' }}
          render={({ field }) => {
            return renderOutsideUserPicker({
              'aria-label': userPickerType === 'waitingFor' ? 'Waiting For' : 'Assignee',
              value: field.value ?? null,
              onChange: ([value]) => {
                field.onChange(value)
                return
              },
            })
          }}
        />
      )
    }

    const isOpen = isDirty || !!watch('title') || focused || forcedOpen

    useEffect(() => {
      // We are setting animated open here. The <Container /> below handles setting it to false.
      if (isOpen) {
        setIsOpenAnimated(true)
      }
    }, [isOpen])

    const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (isHotkey('esc', e)) {
        if (document.activeElement !== e.currentTarget) {
          e.stopPropagation()
          e.currentTarget.focus()
        }

        onCancel?.('esc-key')
      }
    }

    useOutsideClick(
      containerRef,
      (event) => {
        let target = event.target as HTMLElement | null
        while (target != null) {
          // Skip closing if click is inside loom record SDK.
          // Cant put as parent to ignore since they create 3 elements with this id...
          if (target.id === 'loom-sdk-record-overlay-shadow-root-id') {
            return
          }

          target = target.parentElement
        }

        if (!isDirty) {
          onCancel?.('outside-click')
        }
      },
      // Escape hatch to make sure we don't close the form when clicking on the user picker
      [getFloatingUiRoot()],
    )

    const titleProps = register('title', { required: true })
    const [onEditChange] = useDebouncedCallback(
      (e: { target: { value: string } }) => {
        const actionTitle = e.target.value || ''
        repeatOptions?.forEach(({ label, value }) => {
          if (actionTitle.toLowerCase().includes(label.toLowerCase())) {
            setValue('repeat', value)
          }
        })
        const dueDate = chrono.parseDate(e.target.value)
        if (dueDate) setValue('dueAt', dueDate)
      },
      {
        timeout: 500,
        onUnmount: 'flush',
      },
    )
    const titleInput = (
      <EditableFormController
        {...titleProps}
        onChange={(e) => {
          if (useDueDateFromTitle) {
            onEditChange(e)
          }
          return titleProps.onChange(e)
        }}
      >
        {(props) => (
          <HtmlTitleInput
            {...props}
            autofocus={autofocus}
            variant="unstyled"
            placeholder={titlePlaceholder}
            readonly={isSubmitting}
            hotkeys={{
              'Shift-Enter': () => {
                setFocus('description')
                return true
              },
              'Meta-Enter': () => {
                void submit()
                return true
              },
            }}
          />
        )}
      </EditableFormController>
    )

    const description = (
      <EditableFormController {...register('description')}>
        {(props) => (
          <Editable
            {...props}
            mode="compact"
            editable={!isSubmitting}
            placeholder={descriptionPlaceholder}
            hotkeys={{
              'Meta-Enter': () => {
                void submit()
                return true
              },
            }}
            sx={{
              flexGrow: 1,
              minHeight: 4,
              display: !isOpen && !isOpenAnimated && 'none',
              maxHeight: maxDescriptionLines !== undefined ? `${maxDescriptionLines * 32 + 8}px` : 'initial',
              overflow: 'auto',

              ...(isSubmitting && {
                color: 'text-light',
              }),
            }}
          />
        )}
      </EditableFormController>
    )

    const controls = [
      hasPrivacy && renderPrivacy && (
        <Controller
          name="privacy"
          key="privacy"
          control={control}
          defaultValue={defaultPrivacy}
          render={({ field }) => renderPrivacy?.({ value: field.value || defaultPrivacy, onChange: field.onChange })}
        />
      ),
      hasDueAt && (
        <Controller
          key="dueAt"
          name="dueAt"
          control={control}
          render={({ field }) => {
            const { ref: _, ...restField } = field
            const repeat = watch('repeat')
            if (renderDatePicker)
              return renderDatePicker({ value: field.value || defaultDueAt, onChange: field.onChange })
            return (
              <FixedLabel label="Due date" condensed={condensed}>
                {useRecurringDatepicker ? (
                  <RecurringDatepicker
                    onChange={field.onChange}
                    repeat={repeat}
                    onRepeatChange={({ repeat, repeatEndDate, repeatNTimes }) => {
                      setValue('repeat', repeat)
                      setValue('repeatEndDate', repeatEndDate)
                      setValue('repeatNTimes', repeatNTimes)
                    }}
                    value={field.value}
                    inputContainerStyle={condensed ? { width: 'auto' } : { width: '100px', marginRight: 1.5 }}
                    inputStyle={{ textAlign: 'left' }}
                    buttonLabel="Due Date"
                    placeholderValue={defaultDueAt}
                    variant="muted"
                    // Dont cover the Add Action button when the datepicker is open.
                    placement="bottom-end"
                    repeatOptions={repeatOptions}
                  />
                ) : (
                  <Datepicker
                    {...restField}
                    onChange={(date) => {
                      field.onChange(date)
                    }}
                    inputContainerStyle={condensed ? { width: 'auto' } : { width: '100px', marginRight: 1.5 }}
                    inputStyle={{ textAlign: 'left' }}
                    suggestedDates={dueAtSuggestedDates}
                    buttonLabel="Due Date"
                    value={field.value}
                    placeholderValue={defaultDueAt}
                    variant="muted"
                    // Dont cover the Add Action button when the datepicker is open.
                    placement="bottom-end"
                  />
                )}
              </FixedLabel>
            )
          }}
        />
      ),
      hasAssignee && (
        <FixedLabel key="assignee" label="Assignee" condensed={condensed}>
          {renderUserPicker({
            userPickerType: 'assignee',
          })}
        </FixedLabel>
      ),
      hasWaitingFor && (
        <FixedLabel key="waiting-for" label="Waiting" condensed={condensed}>
          {renderUserPicker({
            selectedGroupHeading: 'Waiting For',
            noSelectedText: 'No Waiting For.',
            userPickerType: 'waitingFor',
          })}
        </FixedLabel>
      ),
    ].filter(Boolean)

    const buttons = (
      <Flex row align="center" gap={1.5} alignSelf="flex-end">
        {hasCancel && (
          <Button
            variant="default"
            size="small"
            onClick={() => {
              reset()
              if (refocusTitleOnSubmit) {
                setFocus('title')
              }
              onCancel?.('button')
            }}
          >
            Cancel
          </Button>
        )}

        {isDisabled && disabledTooltip ? (
          <Tooltip description={disabledTooltip} position="top">
            <Button variant="accent" size="small" loading={isSubmitting} type="submit" disabled={isDisabled}>
              Add
            </Button>
          </Tooltip>
        ) : (
          <Button variant="accent" size="small" loading={isSubmitting} type="submit" disabled={isDisabled}>
            Add
          </Button>
        )}
      </Flex>
    )

    const hasFooter = condensed || hasClear || React.Children.count(extraControls) > 0

    return (
      <MotionFlex
        animate={animateShake}
        variants={{ shake: { translateX: [-2, 0, 2, -4, 0, -4] } }}
        transition={{ duration: 0.4 }}
        className={className}
      >
        <form
          ref={containerRef}
          tabIndex={0}
          data-testid="AddRow"
          aria-expanded={isOpen}
          onSubmit={submit}
          onFocus={() => {
            setFocused(true)
          }}
          onBlur={() => {
            setFocused(false)
          }}
          onKeyDown={handleKeyDown}
          sx={{
            borderRadius: 'medium',
            width: '100%',

            '&:focus, &.focus': {
              outlineStyle: 'solid',
              outlineWidth: '4px',
              outlineColor: 'accent-border-light',
            },
          }}
        >
          <Container
            column
            active={isOpen}
            initial={forcedOpen ? false : { height: collapsedHeight }}
            animate={{ height: isOpen ? 'auto' : collapsedHeight }}
            transition={{ type: 'tween' }}
            onAnimationComplete={() => {
              if (!isOpen) {
                setIsOpenAnimated(false)
              }
            }}
            sx={containerStyles}
          >
            <Flex row gap={1.5} sx={{ pl: 2, pr: 2, py: 1 }}>
              {titleInput}
              {!condensed && <Flex row>{controls}</Flex>}
            </Flex>

            <Flex row gap={1.5} sx={{ px: 2, pt: 0.5, pb: 2 }}>
              {description}
              {!hasFooter && buttons}
            </Flex>

            {renderBottomContent}

            {condensed && controls.length > 0 && (
              <Flex row gap={1} sx={{ px: 1, py: 1, borderTop: '1px solid', borderTopColor: 'border' }}>
                {controls}
              </Flex>
            )}

            {hasFooter && (
              <Flex
                row
                justify="space-between"
                sx={{
                  display: !isOpen && !isOpenAnimated && 'none',
                  padding: 1.5,
                  paddingLeft: 2,
                  borderTop: '1px solid',
                  borderTopColor: 'border',
                }}
              >
                <Flex row gap={1.5}>
                  {hasClear && (
                    <Button
                      variant="muted"
                      size="small"
                      onClick={() => {
                        reset()
                        setFocus('title')
                      }}
                    >
                      Clear
                    </Button>
                  )}
                  {extraControls}
                </Flex>
                {buttons}
              </Flex>
            )}
          </Container>
        </form>
      </MotionFlex>
    )
  },
)

AddWaitingForRow.displayName = 'AddWaitingForRow'

const Container = styled(MotionFlex)<{ active: boolean }>(({ active }) =>
  css({
    borderRadius: 'medium',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'border',
    bg: 'background',
    overflow: 'hidden',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0)',
    transition: 'box-shadow 200ms ease-in-out',

    ':hover, :focus': {
      boxShadow: 'default',
    },

    ...(active && {
      boxShadow: 'default',
    }),
  }),
)

const HtmlTitleInput = styled(SingleLineEditable)(
  css({
    flexGrow: 1,
    fontWeight: 'bold',
    strong: {
      fontWeight: 'extrabold',
    },
    '> .ProseMirror': {
      px: 0,
      py: '5.5px',
    },
  }),
  ({ readonly }) =>
    readonly &&
    css({
      color: 'text-light',
    }),
)

type FixedLabelProps = {
  label: string
  condensed: boolean
  children: React.ReactNode
}

const FixedLabel = ({ label, condensed, children }: FixedLabelProps) => {
  return condensed ? (
    <Flex as="label" column sx={{ width: 10 }}>
      <Text color="text-light" variant="small" sx={{ my: 0.5, mx: 1 }}>
        {label}
      </Text>
      {children}
    </Flex>
  ) : (
    <>{children}</>
  )
}

import { privacyMap } from '@mm/common'
import { AddWaitingForRowData, Button, FastTable, Flex, Text, useFeatureFlags } from '@mm/company-ui'
import { HistoryIcon, LoadingIcon } from '@mm/company-ui-icons'
import { produce } from 'immer'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  ActionsDashboardRowDataFragment,
  CreateActionInput,
  FollowUpActionsCreateDocument,
  FollowUpActionsDocument,
} from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { useMutation, useQuery } from '../../apollo'
import { useEmitEventBusEvent, useOnEventBusEvent } from '../../eventbus'
import { useMeetingContext } from '../../meetings'
import { useContextualDrawers } from '../../navigation'
import { markActionAsCreatedHere } from '../commands/markAsCreatedHere'
import { ActionsUpdatedTopic } from '../subscriptions/GlobalActionsSubscription'
import { ActionAssigneeCellContent } from './ActionAssigneeCell'
import { ActionDatepicker } from './ActionDatepicker'
import { ActionMoreMenu } from './ActionMoreMenu'
import { ActionRowAnimateInDecorator } from './ActionsDashboard'
import { ActionStatusCheckbox } from './ActionStatusCheckbox'
import { ActionTableAddRow } from './ActionTableAddRow'
import { ActionTableGroupHeaderMenu } from './ActionTableGroupHeaderMenu'
import { ActionWaitingForCellContent } from './ActionWaitingForCell'

export type FollowUpActionsProps = {
  actionId: string
  noAddNewAction?: boolean
  nonInteractive?: boolean
  addRowForcedOpen?: boolean

  onHasActionsToShowChange?: (hasActionsToShow: boolean) => void
  onChangeDirty?: (isDirty: boolean) => void
  onCreate?: (action: ActionsDashboardRowDataFragment) => void
}

export const FollowUpActions = ({
  actionId,
  noAddNewAction,
  nonInteractive,
  addRowForcedOpen,
  onHasActionsToShowChange,
  onChangeDirty,
  onCreate,
}: FollowUpActionsProps) => {
  const { copyPlainTextActions, hideTableHeaders } = useFeatureFlags()
  const { showDrawer } = useContextualDrawers()
  const meeting = useMeetingContext()

  const { data, loading, fetchMore, updateQuery } = useQuery(FollowUpActionsDocument, {
    variables: {
      actionId,
    },
  })
  const emit = useEmitEventBusEvent(ActionsUpdatedTopic)
  const [createFollowUpAction] = useMutation(FollowUpActionsCreateDocument, {
    update: (cache, { data }) => {
      const action = data?.createFollowUpAction
      if (action?.__typename === 'Action') {
        capture('Action Created', {
          parent: 'Action',
          parentMeetingId: meeting?.id,
        })
        markActionAsCreatedHere(cache, action)
        emit({ type: 'upserted', action })
        onCreate?.(action)
      }
    },
  })

  useOnEventBusEvent(ActionsUpdatedTopic, (event) => {
    if (event.type !== 'upserted') return
    const { action } = event

    updateQuery((data, { variables: { actionId: followUpToId } = {} }) =>
      produce(data, (draft) => {
        const followUpActions = draft.action?.followUpActions
        if (followUpActions == null) return

        _.remove(followUpActions.edges, ({ node }) => node.id === action.id)

        if (action.followUpTo?.id === followUpToId) {
          const targetIdx = followUpActions.edges.findIndex(({ node }) => node.createdAt > action.createdAt)

          if (targetIdx === -1) {
            followUpActions.edges.push({ node: action })
          } else {
            followUpActions.edges.splice(targetIdx, 0, { node: action })
          }
        }
      }),
    )
  })

  const action = data?.action

  const hasActionsToShow = action?.followUpActions != null && action.followUpActions.edges.length > 0
  useEffect(() => {
    onHasActionsToShowChange?.(hasActionsToShow)
  }, [onHasActionsToShowChange, hasActionsToShow])

  const tableData = useMemo<FastTable.TableRowGroup<ActionsDashboardRowDataFragment>[]>(() => {
    return [
      {
        id: 'actions',
        title: '',
        hideTitle: true,
        rows: action?.followUpActions?.edges.map(({ node }) => node) ?? [],
      },
    ]
  }, [action])

  const getRowId = useCallback((row: ActionsDashboardRowDataFragment) => row.id, [])

  const columns = useMemo<FastTable.TableColumn<ActionsDashboardRowDataFragment>[]>(
    () => [
      {
        header: 'Status',
        renderCell: ({ id }) => <ActionStatusCheckbox cached id={id} />,
        width: 64,
        disableResizing: true,
      },
      {
        header: '',
        renderCell: ({ title }) => <FastTable.TextCell>{title}</FastTable.TextCell>,
        width: 150, // minWidth as well
      },
      {
        header: 'Due',
        renderCell: ({ id }) => (
          <ActionDatepicker
            id={id}
            showIcon={false}
            textAlign="left"
            redIfPastDue={true}
            showRescheduledPrefix={true}
            cached
          />
        ),
        width: 116,
        disableResizing: true,
      },
      {
        header: 'Assignee',
        renderCell: ({ id, assignee }) => (
          <ActionAssigneeCellContent assigneeId={assignee.id} actionId={id} avatarOnly />
        ),
        width: 80,
        disableResizing: true,
      },
      {
        header: 'Waiting',
        renderCell: ({ id, waitingFor }) =>
          waitingFor ? (
            <ActionWaitingForCellContent waitingForId={waitingFor?.id} actionId={id} avatarOnly />
          ) : undefined,
        width: 80,
        disableResizing: true,
      },
    ],
    [],
  )

  const handleOnRowClick = useCallback(
    (row: ActionsDashboardRowDataFragment, event: React.MouseEvent<HTMLDivElement>) => {
      showDrawer('action', row.id, event)
    },
    [showDrawer],
  )

  const handleOnAdd = useCallback(
    async (data: AddWaitingForRowData) => {
      const actionPayload: CreateActionInput = {
        title: data.title,
        privacy: privacyMap.PUBLIC,
        dateRescheduled: false,
        description: data.description,
        dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
        assignee: data.assignee,
        waitingFor: data.waitingFor,
        parentMeetingId: meeting?.id,
      }

      await createFollowUpAction({
        variables: {
          actionId,
          data: actionPayload,
        },
      })
    },
    [actionId, createFollowUpAction, meeting],
  )

  const renderRowGroupExtraHeader = useCallback(() => {
    if (action == null) return null
    return (
      <ActionTableAddRow
        onAdd={handleOnAdd}
        addRowForcedOpen={addRowForcedOpen}
        defaultAssignee={action.assignee.id}
        defaultWaitingFor={action.waitingFor.id}
        onChangeDirty={onChangeDirty}
      />
    )
  }, [action, addRowForcedOpen, handleOnAdd, onChangeDirty])

  const renderRowMenu = useCallback(({ id }: ActionsDashboardRowDataFragment) => <ActionMoreMenu id={id} />, [])

  const renderRowGroupMenu = useCallback(
    (rowGroup: FastTable.TableRowGroup<ActionsDashboardRowDataFragment>) => (
      <ActionTableGroupHeaderMenu rowGroup={rowGroup} />
    ),
    [],
  )

  const renderRowGroupEmptyPlaceholder = useCallback(
    () =>
      loading ? (
        <Flex column align="center">
          <LoadingIcon />
        </Flex>
      ) : (
        <Text sx={{ ml: 4 }} color="text-disabled" variant="small">
          No follow-up actions
        </Text>
      ),
    [loading],
  )

  const decorateRow = useCallback(
    (children: React.ReactElement, row: ActionsDashboardRowDataFragment) => (
      <ActionRowAnimateInDecorator noBorders id={row.id}>
        {children}
      </ActionRowAnimateInDecorator>
    ),
    [],
  )

  const pageInfo = action?.followUpActions?.pageInfo

  return (
    <>
      <FastTable.Table
        tableStyles={{ width: '100%' }}
        columns={columns}
        data={tableData}
        noBorders
        stickyHeader
        noHeaders={hideTableHeaders}
        getRowId={getRowId}
        onRowClick={!nonInteractive ? handleOnRowClick : undefined}
        renderRowGroupExtraHeader={!noAddNewAction ? renderRowGroupExtraHeader : undefined}
        renderRowGroupMenu={copyPlainTextActions ? renderRowGroupMenu : undefined}
        renderRowMenu={renderRowMenu}
        renderRowGroupEmptyPlaceholder={renderRowGroupEmptyPlaceholder}
        decorateRow={decorateRow}
      />
      {pageInfo?.hasNextPage ? (
        <div sx={{ px: 4, marginTop: -2.5 }}>
          <Button
            variant="muted"
            size="small"
            loading={loading}
            startIcon={<HistoryIcon />}
            onClick={() => {
              void fetchMore({
                variables: {
                  after: pageInfo.endCursor,
                },
              })
            }}
          >
            Show 10 more
          </Button>
        </div>
      ) : null}
    </>
  )
}

import { useTheme } from '@emotion/react'
import { CarretDownIcon, CloseCircleIcon } from '@mm/company-ui-icons'
import React from 'react'
import ReactSelect, {
  ClearIndicatorProps,
  components,
  ControlProps,
  DropdownIndicatorProps,
  Props as ReactSelectProps,
  StylesConfig,
} from 'react-select'
import { ThemeUIStyleObject } from 'theme-ui'

const {
  DropdownIndicator: BaseDropdownIndicator,
  ClearIndicator: BaseClearIndicator,
  ValueContainer: BaseValueContainer,
} = components

// Inherits all props from https://react-select.com/props

export type SelectProps<T = unknown, IsMulti extends boolean = false> = ReactSelectProps<Option<T>, IsMulti> & {
  variant?: 'default' | 'muted' | 'heading' | 'condensed'
  isValid?: boolean
  prefix?: React.ReactElement | string
  controlStyleOverrides?: Omit<ThemeUIStyleObject, 'AccentColor'>
}

const DropdownIndicator = <Option, IsMulti extends boolean>(props: DropdownIndicatorProps<Option, IsMulti>) => {
  return (
    <BaseDropdownIndicator {...props}>
      <CarretDownIcon width={2.5} height={2.5} display="block" color="text-light" />
    </BaseDropdownIndicator>
  )
}

const ClearIndicator = <Option, IsMulti extends boolean>(props: ClearIndicatorProps<Option, IsMulti>) => {
  return (
    <BaseClearIndicator {...props}>
      <CloseCircleIcon width={2.5} height={2.5} display="block" color="text-light" />
    </BaseClearIndicator>
  )
}

type Option<T> = {
  label: string
  value: T
}

export const Select = <T, IsMulti extends boolean = false>({
  variant = 'default',
  prefix,
  isValid = true,
  controlStyleOverrides,
  ...props
}: SelectProps<T, IsMulti>) => {
  const theme = useTheme()

  const variantStyles = {
    default: {
      height: theme.sizes[7],
      padding: theme.space[2],
      control: (state: ControlProps<Option<T>, IsMulti>) => {
        const outlineColor = isValid ? theme.colors['border-medium'] : theme.colors['system-background-warning']

        return {
          backgroundColor: state.isFocused ? theme.colors['background'] : theme.colors['background-light'],
          boxShadow: `inset 0 0 0 ${isValid && !state.isFocused ? '0' : '1px'} ${outlineColor}, ${
            state.isFocused ? theme.shadows.default : theme.shadows.defaultTransparent
          }`,

          '&:hover, .hover &': {
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${
              state.isFocused ? theme.shadows.default : theme.shadows.defaultTransparent
            }`,
          },

          '.focus &': {
            backgroundColor: theme.colors['background'],
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.default}`,
          },
          ...controlStyleOverrides,
        }
      },
    },
    condensed: {
      height: theme.sizes[4],
      padding: theme.space[1],
      control: (state: ControlProps<Option<T>, IsMulti>) => {
        const outlineColor = isValid ? theme.colors['border-medium'] : theme.colors['system-background-warning']

        return {
          backgroundColor: state.isFocused ? theme.colors['background'] : theme.colors['background-light'],
          boxShadow: `inset 0 0 0 ${isValid && !state.isFocused ? '0' : '1px'} ${outlineColor}, ${
            state.isFocused ? theme.shadows.default : theme.shadows.defaultTransparent
          }`,

          '&:hover, .hover &': {
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${
              state.isFocused ? theme.shadows.default : theme.shadows.defaultTransparent
            }`,
          },

          '.focus &': {
            backgroundColor: theme.colors['background'],
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.default}`,
          },
          ...controlStyleOverrides,
        }
      },
    },
    muted: {
      height: theme.sizes[5],
      padding: theme.space[1],
      control: (state: ControlProps<Option<T>, IsMulti>) => {
        const outlineColor = isValid ? theme.colors['border-medium'] : theme.colors['system-background-warning']
        const boxShadow = `inset 0 0 0 ${!isValid || state.isFocused ? '1px' : '0'} ${outlineColor}, ${
          state.isFocused ? theme.shadows.default : theme.shadows.defaultTransparent
        }`
        return {
          backgroundColor: state.isFocused ? theme.colors['background'] : 'transparent',
          boxShadow,

          '&:hover, .hover &': {
            backgroundColor: state.isFocused ? theme.colors['background'] : theme.colors['background-light'],
          },

          '.focus &': {
            backgroundColor: theme.colors['background'],
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.default}`,
          },
          ...controlStyleOverrides,
        }
      },
    },
    heading: {
      height: '46px',
      padding: theme.space[1],
      control: (state: ControlProps<Option<T>, IsMulti>) => {
        const outlineColor = theme.colors['border-medium']
        const boxShadow = `inset 0 0 0 ${state.isFocused ? '1px' : '0'} ${outlineColor}, ${
          state.isFocused ? theme.shadows.default : theme.shadows.defaultTransparent
        }`
        return {
          backgroundColor: state.isFocused ? theme.colors['background'] : 'transparent',
          boxShadow,

          '&:hover, .hover &': {
            backgroundColor: state.isFocused ? theme.colors['background'] : theme.colors['background-light'],
          },

          '.focus &': {
            backgroundColor: theme.colors['background'],
            boxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.default}`,
          },
          ...controlStyleOverrides,
        }
      },
    },
  }[variant]

  const customStyles: StylesConfig<Option<T>, IsMulti> = {
    container: (provided) => ({
      ...provided,
      fontFamily: theme.fonts.body,
    }),
    control: (_, state) => ({
      display: 'flex',
      height: variantStyles.height,
      borderRadius: theme.radii.default,
      transition: 'all 100ms linear',
      ...variantStyles.control(state),
    }),
    valueContainer: () => ({
      padding: variantStyles.padding,
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      fontSize: variant === 'heading' ? '20px' : '', // TODO: Waiting on deisgn to advise on non-scale size
    }),
    placeholder: () => ({
      color: theme.colors['background-strong-alpha'],
    }),
    dropdownIndicator: () => ({
      padding: variantStyles.padding,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: `${theme.shadows.default}, inset 0 0 0 1px ${theme.colors['background-light']}`,
      borderRadius: theme.radii.default,
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: theme.space[1],
      paddingBottom: theme.space[1],
    }),
  }

  // TODO: Properly type react-select component with option type

  return (
    <ReactSelect<Option<T>, IsMulti>
      {...props}
      components={{
        DropdownIndicator,
        ClearIndicator,
        ValueContainer: ({ children, ...props }) => (
          <BaseValueContainer {...props}>
            {prefix ? <span sx={{ marginRight: 1, color: 'text-light' }}>{prefix}</span> : null}
            {children}
          </BaseValueContainer>
        ),
      }}
      styles={customStyles}
    />
  )
}

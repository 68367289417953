import { Flex, ModalLabel, Datepicker, DatepickerProps } from '@mm/company-ui'
import { DateTime } from 'luxon'
import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { CreateDecisionData } from '../types'

export type CreateDecisionDateProps = {
  label: string
  name: 'dueAt' | 'commentsDueAt'
  control: Control<CreateDecisionData>
  disabledDays?: DatepickerProps['disabledDays']
}
export const CreateDecisionDate = ({ label, name, control, disabledDays }: CreateDecisionDateProps) => {
  return (
    <Flex>
      <ModalLabel label={label} />

      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { ref: _, ...restField } = field
          return (
            <Datepicker
              {...restField}
              inputContainerStyle={{
                width: 'auto',
              }}
              disabledDays={disabledDays}
              value={field.value}
              onChange={(date) => {
                if (date) {
                  field.onChange(DateTime.fromJSDate(date).toMillis())
                }
              }}
            />
          )
        }}
      />
    </Flex>
  )
}

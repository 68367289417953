import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const DoubleArrowRight = (props: OptionalIconBaseProps) => (
  <IconBase name="double-error-right" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7891 7.49087C14.0703 7.77205 14.0703 8.22793 13.7891 8.50911L9.12245 13.1758C8.84127 13.457 8.38539 13.457 8.10422 13.1758C7.82304 12.8946 7.82304 12.4387 8.10422 12.1575L12.2618 7.99999L8.10422 3.84244C7.82304 3.56126 7.82304 3.10538 8.10422 2.82421C8.38539 2.54303 8.84127 2.54303 9.12245 2.82421L13.7891 7.49087ZM3.12245 2.82421L7.78912 7.49087C8.07029 7.77205 8.07029 8.22793 7.78912 8.50911L3.12245 13.1758C2.84127 13.457 2.38539 13.457 2.10422 13.1758C1.82304 12.8946 1.82304 12.4387 2.10422 12.1575L6.26177 7.99999L2.10422 3.84244C1.82304 3.56126 1.82304 3.10538 2.10422 2.82421C2.38539 2.54303 2.84127 2.54303 3.12245 2.82421Z"
    />
  </IconBase>
)

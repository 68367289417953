import { DeleteActionDocument, GetActionDocument } from '../../../gen/graphql/documents'
import { evictCache, isGraphQLError } from '../../apollo'
import { Command } from '../../commands'

type DeleteActionCommandValue = {
  actionId: string
  onActionDeleted?: () => void
}

export const deleteActionCommand: Command<DeleteActionCommandValue> = {
  id: 'deleteAction',
  name: 'Delete Action',
  canExecute: async ({ actionId }, { apolloClient }) => {
    const currentAction = await apolloClient.query({ query: GetActionDocument, variables: { id: actionId } })
    return !!currentAction.data.action
  },
  execute: async ({ actionId, onActionDeleted }, { apolloClient }) => {
    const { data } = await apolloClient.mutate({
      mutation: DeleteActionDocument,
      variables: {
        id: actionId,
      },
      update: (cache, { data }) => {
        if (data?.deleteAction.__typename === 'Success') {
          evictCache(cache, 'Action', actionId)
          onActionDeleted?.()
        }
      },
      optimisticResponse: {
        deleteAction: {
          __typename: 'Success',
        },
      },
    })
    if (data?.deleteAction && isGraphQLError(data?.deleteAction)) {
      alert(`Delete action failed: ${data?.deleteAction.message ?? 'unknown error'}`)
    } else if (data?.deleteAction.__typename !== 'Success') {
      alert('Delete action failed: unknown error')
    }
  },
}

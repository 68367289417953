import { toast } from '@mm/company-ui'
import {
  GetTopicStatusCommandDocument,
  TopicStatus,
  UpdateTopicStatusCommandDocument,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditTopicStatusCommandValue = {
  status: TopicStatus
  topicId: string
}

export const editTopicStatusCommand: Command<EditTopicStatusCommandValue> = {
  id: 'editTopicStatusCommand',
  name: 'Edit Status',
  canExecute: async ({ status, topicId }, { apolloClient }) => {
    const query = await apolloClient.query({ query: GetTopicStatusCommandDocument, variables: { id: topicId } })
    return query.data.topic?.status !== status
  },
  execute: async ({ status, topicId }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const query = await apolloClient.query({ query: GetTopicStatusCommandDocument, variables: { id: topicId } })

    const { data } = await apolloClient.mutate({
      mutation: UpdateTopicStatusCommandDocument,
      variables: {
        id: topicId,
        data: {
          status,
        },
      },
      optimisticResponse: {
        updateTopic: {
          __typename: 'Topic',
          id: topicId,
          status,
          statusUpdatedAt: Date.now(),
          isArchived: false,
          recurringDate: query?.data?.topic?.recurringDate,
          repeat: query?.data?.topic?.repeat,
        },
      },
    })

    if (!query.data.topic?.status) {
      return
    }

    if (data?.updateTopic.__typename === 'Topic' && !data.updateTopic.isArchived && query.data.topic.isArchived) {
      toast('Topic restored', { type: 'success' })
    }

    return {
      args: {
        status: query.data.topic.status,
        topicId,
      },
      execute: async ({ status, topicId }, { apolloClient }) => {
        await apolloClient.mutate({
          mutation: UpdateTopicStatusCommandDocument,
          variables: {
            id: topicId,
            data: {
              status,
            },
          },
          optimisticResponse: {
            updateTopic: {
              __typename: 'Topic',
              id: topicId,
              status,
              statusUpdatedAt: Date.now(),
              isArchived: false,
              recurringDate: query?.data?.topic?.recurringDate,
              repeat: query?.data?.topic?.repeat,
            },
          },
        })
      },
    }
  },
}

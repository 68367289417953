import { generateText } from '@tiptap/core'
import { generateHTML, generateJSON } from '@tiptap/html'
import { SingleLineExtension } from './SingleLineExtension'

const extensions = [SingleLineExtension]

export const sanitizeTitleHtmlContent = (titleHtml: string) => {
  const contentJSON = generateJSON(titleHtml, extensions)
  return generateHTML(contentJSON, extensions)
}

export const convertHtmlToTextTitle = (titleHtml: string) => {
  const contentJSON = generateJSON(titleHtml, extensions)
  return generateText(contentJSON, extensions)
}

export const convertTextToHtmlTitle = (titleText: string) => {
  return generateHTML(
    {
      type: 'doc',
      content: [
        {
          type: 'text',
          text: titleText,
        },
      ],
    },
    extensions,
  )
}

import {
  AddWaitingForRow,
  AddWaitingForRowProps,
  AddWaitingForRowRef,
  Button,
  ButtonGroup,
  Flex,
  Toggler,
  useFeatureFlags,
} from '@mm/company-ui'
import { AddCircleIcon } from '@mm/company-ui-icons'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useRef } from 'react'
import { UserSelectWithCompanyMembers, UserSelectWithMeetingParticipants } from '../../components/UserSelectWithQuery'
import { useMeetingContext } from '../../meetings'
import { useActiveUserRequired } from '../../users'
import { actionRepeatOptions } from './ActionDatepicker'

export type ActionTableAddRowProps = Partial<AddWaitingForRowProps> & {
  addRowForcedOpen?: boolean
  className?: string
}

export const ActionTableAddRow = ({ addRowForcedOpen, className, onCancel, ...props }: ActionTableAddRowProps) => {
  const { showMeetingParticipantsFirst, actionWaitingFor, recurringAction, predictDueDateFromActionTitle } =
    useFeatureFlags()
  const titleRef = useRef<AddWaitingForRowRef>(null)
  const meeting = useMeetingContext()
  const me = useActiveUserRequired()

  return (
    <Flex align="center" sx={{ px: 5, minHeight: 4 }} className={className}>
      <Toggler
        sx={{ flexGrow: 1 }}
        hideControlsWhenOpen={true}
        isSelected={addRowForcedOpen}
        controls={({ setSelected }) => (
          <ButtonGroup gap={2} size="small" variant="muted" sx={{ marginLeft: -1 }}>
            {/** Negative margin aligns the text of the first button with the rest of the content on the page */}

            <Button
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setSelected(true)
              }}
            >
              Add action
            </Button>
          </ButtonGroup>
        )}
      >
        {({ setSelected }) => (
          <AddWaitingForRow
            useDueDateFromTitle={predictDueDateFromActionTitle}
            repeatOptions={actionRepeatOptions}
            hasDueAt
            useRecurringDatepicker={recurringAction}
            titlePlaceholder="Add action..."
            defaultDueAt={DateTime.now().plus({ week: 1 }).toJSDate()}
            autofocus={!addRowForcedOpen}
            hasAssignee
            defaultAssignee={me.id}
            hasWaitingFor={actionWaitingFor}
            defaultWaitingFor={me.id}
            renderUserPicker={(props) => {
              if (meeting && showMeetingParticipantsFirst) {
                return <UserSelectWithMeetingParticipants meetingId={meeting.id} {...props} />
              }

              return <UserSelectWithCompanyMembers {...props} />
            }}
            ref={titleRef}
            forcedOpen={true}
            hasCancel
            refocusTitleOnSubmit={false}
            onComplete={() => {
              setSelected(false)
            }}
            onCancel={(src) => {
              setSelected(false)
              onCancel?.(src)
            }}
            {..._.omitBy(props, _.isUndefined)}
          />
        )}
      </Toggler>
    </Flex>
  )
}

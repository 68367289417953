import type { VariablesOf } from '@graphql-typed-document-node/core'
import { closedDecisionStatuses, onHoldDecisionStatuses, openDecisionStatuses } from '@mm/common'
import {
  Button,
  FastTable,
  Flex,
  Suspender,
  Text,
  useDistinct,
  useFeatureFlags,
  useModal,
  UserSelectTarget,
} from '@mm/company-ui'
import { AddCircleIcon, HistoryIcon } from '@mm/company-ui-icons'
import { produce } from 'immer'
import invariant from 'invariant'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { ThemeUICSSObject } from 'theme-ui'
import {
  DecisionsDashboardDataFragment,
  DecisionsDashboardRowDataFragment,
  DecisionsDashboardSectionDataFragment,
  DecisionStatus,
  MeetingGenericTopicsDocument,
} from '../../../gen/graphql/documents'
import { TypedDocumentNode, useQuery } from '../../apollo'
import { useOnEventBusEvent } from '../../eventbus'
import { useMeetingContext } from '../../meetings'
import { getGenericTopicDrawerNav } from '../../meetings/utils/genericDrawerNavigation'
import { useContextualDrawers, useRegisterDrawerNavigationProvider } from '../../navigation'
import { useActiveUser } from '../../users'
import { DecisionsUpdatedTopic } from '../subscriptions/GlobalDecisionsSubscription'
import { DecisionContributorsCell } from './DecisionContributorsCell'
import { DecisionDueAtDatePicker } from './DecisionDueAtDatePicker'
import { DecisionMakerCell } from './DecisionMakerCell'
import { DecisionMoreMenu } from './DecisionMoreMenu'
import { DecisionPrivacyTag } from './DecisionPrivacyTag'
import { DecisionRowContext } from './DecisionRowContext'
import { DecisionStatusPicker } from './DecisionStatusPicker'
import { EmptyDecisions } from './EmptyDecisions'

const Title: FastTable.TableColumn<DecisionsDashboardRowDataFragment> = {
  header: 'Title',
  renderCell: ({ titleHtml }) => <FastTable.RichTextCell value={titleHtml} />,
}

const Status: FastTable.TableColumn<DecisionsDashboardRowDataFragment> = {
  header: 'Status',
  renderCell: ({ id }) => <DecisionStatusPicker decisionId={id} cached />,
  width: 130,
  disableResizing: true,
}

const DueAt: FastTable.TableColumn<DecisionsDashboardRowDataFragment> = {
  header: 'Decision Due',
  renderCell: ({ id, commentsDueAt }) => (
    <DecisionDueAtDatePicker
      id={id}
      disabledDays={{ before: new Date(commentsDueAt) }}
      showIcon
      textAlign="left"
      redIfPastDue
      showRescheduledPrefix
      showFooter={false}
      cached
    />
  ),
  width: 120,
  disableResizing: true,
}

const DecisionMaker: FastTable.TableColumn<DecisionsDashboardRowDataFragment> = {
  header: 'Decision Maker',
  renderCell: ({ id }) => <DecisionMakerCell withName={false} decisionId={id} />,
  width: 100,
  disableResizing: true,
}

const Creator: FastTable.TableColumn<DecisionsDashboardRowDataFragment> = {
  header: 'Creator',
  renderCell: ({ creator }) => <UserSelectTarget value={{ node: creator }} disabled />,
  width: 60,
  disableResizing: true,
}

const Contributors: FastTable.TableColumn<DecisionsDashboardRowDataFragment> = {
  header: 'Contributors',
  renderCell: ({ id }) => <DecisionContributorsCell decisionId={id} cached />,
  width: 150,
  disableResizing: true,
}

const Privacy: FastTable.TableColumn<DecisionsDashboardRowDataFragment> = {
  header: 'Access',
  renderCell: ({ id }) => <DecisionPrivacyTag decisionId={id} />,
  width: 100,
  disableResizing: true,
}

export const DecisionDashboardColumns = {
  Title,
  Status,
  DueAt,
  Privacy,
  DecisionMaker,
  Creator,
  Contributors,
}

const defaultColumns: FastTable.TableColumn<DecisionsDashboardRowDataFragment>[] = [
  Title,
  Status,
  Creator,
  Privacy,
  DecisionMaker,
  Contributors,
  DueAt,
]

const getRowId = (row: DecisionsDashboardRowDataFragment) => row.id

type DecisionsDashboardData<K extends string> = Partial<Record<K, DecisionsDashboardDataFragment | null>>

export type DecisionsDashboardProps<K extends string, V extends { companyId: string }> = {
  query: TypedDocumentNode<
    DecisionsDashboardData<K>,
    V & {
      status?: DecisionStatus | DecisionStatus[] | null
      after?: string | null
      participatingIn?: boolean | null
      createdAfter?: number | null
      creator?: string | null
    }
  >
  queryField: K
  variables: V
  matchDecision: (decision: DecisionsDashboardRowDataFragment) => boolean
  onCreateDecision?: () => void
  variant?: 'open' | 'all' | 'user-recent-and-waiting'
  renderEmptyPlaceholder?: () => React.ReactNode
  createdAfter?: Date
  creator?: string
  overrideColumns?: Array<FastTable.TableColumn<DecisionsDashboardRowDataFragment>>
  renderNewDecisionModal?: (props: { onRequestClose?: () => void }) => React.ReactElement
  tableStyles?: React.CSSProperties
  addRowStyles?: React.CSSProperties
  noBorders?: boolean
  addRowBottom?: boolean
  rowStyles?: ThemeUICSSObject
  showPrivacyWithLabel?: boolean
}

type DecisionRowsGroup = FastTable.TableRowGroup<DecisionsDashboardRowDataFragment> & {
  renderEmptyPlaceholder?: () => React.ReactNode
  loadingMore?: boolean
  hasAddMore?: boolean
} & (
    | { hasMore?: false }
    | {
        hasMore: true
        fetchMore: () => void
      }
  )

export function DecisionsDashboard<K extends string, V extends { companyId: string }>({
  query,
  variables,
  queryField,
  matchDecision,
  variant = 'all',
  createdAfter,
  creator,
  overrideColumns: columns = defaultColumns,
  renderEmptyPlaceholder = () => <EmptyDecisions width={0} height={0} />,
  renderNewDecisionModal,
  tableStyles,
  addRowStyles,
  noBorders = true,
  addRowBottom,
  rowStyles,
  showPrivacyWithLabel = true,
}: DecisionsDashboardProps<K, V>): React.ReactElement {
  const { addDecisionInTableHeader, hideTableHeaders, rowMoreMenu, tableRowDetails, showPrivacyInTable } =
    useFeatureFlags()
  const { showModal } = useModal()
  const { showDrawer } = useContextualDrawers()
  const activeUser = useActiveUser()
  const meetingContext = useMeetingContext()

  const transformedColumns = useMemo(() => {
    if (tableRowDetails) {
      return columns
        .filter(
          (column) =>
            column === DecisionDashboardColumns.Title ||
            column === DecisionDashboardColumns.Status ||
            (showPrivacyInTable && column === DecisionDashboardColumns.Privacy) ||
            column === DecisionDashboardColumns.DecisionMaker,
        )
        .map((column) => {
          if (column === DecisionDashboardColumns.Title) {
            return {
              ...column,
              renderCell: ({ titleHtml, id, dueAt, createdAt, updatedAt }: DecisionsDashboardRowDataFragment) => (
                <Flex column gap={0.5} data-clickable="true" sx={{ paddingY: 0.5, width: '100%' }}>
                  <FastTable.RichTextCell
                    value={titleHtml}
                    sx={{ fontSize: tableRowDetails ? 1 : undefined, width: '100%' }}
                  />
                  {tableRowDetails ? (
                    <DecisionRowContext
                      decisionId={id}
                      dueAt={dueAt ?? undefined}
                      createdAt={createdAt}
                      updatedAt={updatedAt}
                    />
                  ) : null}
                </Flex>
              ),
            }
          } else if (column === DecisionDashboardColumns.Status) {
            return {
              ...column,
              renderCell: ({ id }: DecisionsDashboardRowDataFragment) => (
                <Flex justify="flex-end" sx={{ width: '100%' }}>
                  <DecisionStatusPicker decisionId={id} cached />
                </Flex>
              ),
            }
          } else if (column === DecisionDashboardColumns.Privacy) {
            return {
              ...column,
              renderCell: ({ id }: DecisionsDashboardRowDataFragment) => (
                <DecisionPrivacyTag
                  decisionId={id}
                  sx={{ background: 'none' }}
                  showPrivacyLabel={showPrivacyWithLabel}
                  disabled
                />
              ),
              width: showPrivacyWithLabel ? 90 : 36,
            }
          } else if (column === DecisionDashboardColumns.DecisionMaker) {
            return {
              ...column,
              renderCell: ({ id }: DecisionsDashboardRowDataFragment) => (
                <DecisionMakerCell withName={false} decisionId={id} size="large" />
              ),
              width: 36,
            }
          }
          return column
        })
    }

    if (!addDecisionInTableHeader) {
      return columns
    }

    return columns.map((column) => {
      if (column === DecisionDashboardColumns.Title && renderNewDecisionModal) {
        return {
          ...column,
          header: (
            <Button
              size="small"
              variant="muted"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                showModal(renderNewDecisionModal)
              }}
            >
              New issue
            </Button>
          ),
        }
      }
      return column
    })
  }, [
    tableRowDetails,
    addDecisionInTableHeader,
    columns,
    showPrivacyInTable,
    showPrivacyWithLabel,
    renderNewDecisionModal,
    showModal,
  ])

  const emptyDecisionGroupPlaceholder = useCallback(
    () => (
      <div sx={{ pl: 4, py: 1 }}>
        <Text variant="small" color="text-light">
          No issues with this status
        </Text>
      </div>
    ),
    [],
  )

  const { data: meetingData } = useQuery(MeetingGenericTopicsDocument, {
    variables: {
      id: meetingContext?.id || '',
    },
    skip: !meetingContext?.id,
  })

  const queryResult = useQuery(query, {
    variables: {
      status: openDecisionStatuses,
      ...variables,
    },
  })

  const doneQueryResult = useQuery(query, {
    variables: {
      status: closedDecisionStatuses,
      ...variables,
      skip: variant === 'open',
    },
  })
  const onHoldQueryResult = useQuery(query, {
    variables: {
      status: onHoldDecisionStatuses,
      ...variables,
      skip: variant === 'open',
    },
  })

  const distinctResults = useDistinct(
    [queryResult, doneQueryResult, onHoldQueryResult].map(({ data, fetchMore }) => ({ data, fetchMore })),
  )
  const meeting = meetingData?.meeting

  useRegisterDrawerNavigationProvider(
    'decision',
    useCallback(
      (id, sectionId) => {
        if (sectionId) {
          if (meeting == null) return null
          return getGenericTopicDrawerNav({
            sectionId,
            topicId: id,
            sections: meeting.sections,
            displayNext: (type, id) => {
              showDrawer(type, id, undefined, sectionId)
            },
            displayPrevious: (type, id) => {
              showDrawer(type, id, undefined, sectionId)
            },
          })
        }

        const result = distinctResults.find(({ data }) =>
          data?.[queryField]?.decisions?.edges.some(({ node }) => node.id === id),
        )
        const decisions = result?.data?.[queryField]?.decisions
        if (result == null || decisions == null) return null

        const itemIndex = decisions.edges.findIndex(({ node }) => node.id === id)
        const previousItem = decisions.edges[itemIndex - 1]
        const nextItem = decisions.edges[itemIndex + 1]
        const hasMoreItems = decisions.pageInfo.hasNextPage

        return {
          itemIndex,
          totalItemsCount: decisions.edges.length,
          hasMoreItems,
          displayPrevious:
            previousItem &&
            (() => {
              showDrawer('decision', previousItem.node.id)
            }),
          displayNext:
            nextItem != null
              ? () => {
                  showDrawer('decision', nextItem.node.id)
                }
              : !hasMoreItems
              ? null
              : async () => {
                  const { data } = await result.fetchMore({ variables: { after: decisions.pageInfo.endCursor } })
                  const nextItem = data?.[queryField]?.decisions?.edges[0]
                  if (nextItem != null) {
                    showDrawer('decision', nextItem.node.id)
                  }
                },
        }
      },
      [distinctResults, meeting, queryField, showDrawer],
    ),
  )

  const isStillLoading = queryResult.loading || doneQueryResult.loading

  useOnEventBusEvent(DecisionsUpdatedTopic, (event) => {
    if (event.type === 'upserted') {
      const { decision } = event

      const handleUpdate = (data: DecisionsDashboardData<K>, opts: { variables?: VariablesOf<typeof query> }) =>
        produce(data, (draft: DecisionsDashboardData<K>) => {
          const decisions = draft[queryField]?.decisions
          if (decisions == null) return

          const { status, companyId, participatingIn, creator, createdAfter } = opts.variables ?? {}

          const matchesStatus = status == null || [status].flat().includes(decision.status)
          const matchesCompany = companyId == null || decision.company.id === companyId
          const matchesParticipation =
            participatingIn == null ||
            participatingIn === decision.participatingIn.edges.some(({ node }) => node.id === activeUser?.id)
          const matchesCreator = creator == null || decision.creator.id === creator
          const matchesCreatedAfter = createdAfter == null || decision.createdAt > createdAfter

          if (
            matchDecision(decision) &&
            matchesStatus &&
            matchesCompany &&
            matchesParticipation &&
            matchesCreator &&
            matchesCreatedAfter
          ) {
            if (decisions.edges.every(({ node }) => node.id !== decision.id)) {
              decisions.edges.unshift({
                node: decision,
              })
            }
          } else {
            _.remove(decisions.edges, ({ node }) => node.id === decision.id)
          }
        })

      queryResult.updateQuery(handleUpdate)
      onHoldQueryResult.updateQuery(handleUpdate)
      doneQueryResult.updateQuery(handleUpdate)
    }
  })

  const tableData = useMemo<DecisionRowsGroup[]>(() => {
    const decisions = queryResult.data?.[queryField]?.decisions
    const onHoldDecisions = onHoldQueryResult.data?.[queryField]?.decisions
    const doneDecisions = doneQueryResult.data?.[queryField]?.decisions

    const unwrapEdge = ({ node }: DecisionsDashboardSectionDataFragment['edges'][number]) => node

    switch (variant) {
      case 'open': {
        return [
          {
            id: 'open-decisions',
            title: 'Open',
            hideTitle: true,
            rows: decisions?.edges.map(unwrapEdge) ?? [],
            pageInfo: decisions?.pageInfo,
            hasAddMore: true,
            renderEmptyPlaceholder,
            hasMore: decisions?.pageInfo.hasNextPage,
            fetchMore: () => {
              void queryResult.fetchMore({
                variables: {
                  after: decisions?.pageInfo.endCursor,
                },
              })
            },
          },
        ]
      }
      case 'user-recent-and-waiting': {
        if (!createdAfter || !creator) {
          invariant(!createdAfter || !creator, 'createdAfter and creator must be set to use user-recent-and-waiting')
          return []
        }

        const filterForUserRecent = (edge: DecisionsDashboardSectionDataFragment['edges'][number]) => {
          return edge.node.creator.id === creator && edge.node.createdAt > createdAfter.getTime()
        }

        const filtededUserRecent = decisions?.edges.filter(filterForUserRecent).map(unwrapEdge) ?? []
        const filteredWaiting =
          decisions?.edges
            .filter((edge) => !filterForUserRecent(edge) && edge.node.status !== 'IMPLEMENTING')
            .map(unwrapEdge) ?? []
        const rows: Array<DecisionRowsGroup> = [
          {
            id: 'open-decisions',
            title: 'Open',
            hideTitle: true,
            rows: filtededUserRecent,
            hasAddMore: true,
            renderEmptyPlaceholder,
          },
        ]

        if (filteredWaiting.length) {
          rows.push({
            id: 'waiting',
            title: 'Decisons waiting on you',
            rows: filteredWaiting,
          })
        }

        return rows
      }
      case 'all': {
        return [
          {
            id: 'open-decisions',
            title: 'Open',
            rows: decisions?.edges.map(unwrapEdge) ?? [],
            pageInfo: decisions?.pageInfo,
            renderEmptyPlaceholder,
            hasMore: decisions?.pageInfo.hasNextPage,
            hasAddMore: true,
            fetchMore: () => {
              void queryResult.fetchMore({
                variables: {
                  after: decisions?.pageInfo.endCursor,
                },
              })
            },
          },
          {
            id: 'on-hold-decisions',
            title: 'On Hold',
            renderEmptyPlaceholder: emptyDecisionGroupPlaceholder,
            rows: onHoldDecisions?.edges.map(unwrapEdge) ?? [],
            hasMore: onHoldDecisions?.pageInfo.hasNextPage,
            fetchMore: () => {
              void onHoldQueryResult.fetchMore({
                variables: {
                  after: onHoldDecisions?.pageInfo.endCursor,
                },
              })
            },
          },
          {
            id: 'closed-decisions',
            title: 'Closed',
            renderEmptyPlaceholder: emptyDecisionGroupPlaceholder,
            rows: doneDecisions?.edges.map(unwrapEdge) ?? [],
            hasMore: doneDecisions?.pageInfo.hasNextPage,
            fetchMore: () => {
              void doneQueryResult.fetchMore({
                variables: {
                  after: doneDecisions?.pageInfo.endCursor,
                },
              })
            },
          },
        ]
      }
    }
  }, [
    queryResult,
    queryField,
    onHoldQueryResult,
    doneQueryResult,
    variant,
    renderEmptyPlaceholder,
    createdAfter,
    creator,
    emptyDecisionGroupPlaceholder,
  ])

  const handleOnRowClick = useCallback(
    (row: DecisionsDashboardRowDataFragment, event: React.MouseEvent<HTMLDivElement>) => {
      showDrawer('decision', row.id, event)
    },
    [showDrawer],
  )

  const renderRowMenu = useCallback(({ id }: DecisionsDashboardRowDataFragment) => {
    return <DecisionMoreMenu cached id={id} />
  }, [])

  const renderRowGroupFooter = useCallback((group: DecisionRowsGroup) => {
    if (group.hasMore) {
      return (
        <div sx={{ px: 4 }}>
          <Button
            variant="muted"
            size="small"
            loading={group.loadingMore}
            startIcon={<HistoryIcon />}
            onClick={group.fetchMore}
          >
            Show 10 more
          </Button>
        </div>
      )
    }

    return null
  }, [])

  const renderRowGroupExtraHeader = useCallback(
    (group: DecisionRowsGroup) => {
      return group.hasAddMore && renderNewDecisionModal ? (
        <Flex
          align="center"
          sx={{ paddingX: 3, height: 4, ...addRowStyles, border: !group.rows?.length ? 'none' : undefined }}
        >
          <Button
            size="small"
            variant="muted"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              showModal(renderNewDecisionModal)
            }}
          >
            New issue
          </Button>
        </Flex>
      ) : null
    },
    [showModal, renderNewDecisionModal, addRowStyles],
  )

  const renderRowGroupEmptyPlaceholder = useCallback((group: DecisionRowsGroup) => group.renderEmptyPlaceholder?.(), [])

  if (queryResult.data == null && isStillLoading) {
    return <Suspender />
  }

  if (queryResult.error) {
    throw queryResult.error ?? new Error('Decisions not found')
  }

  return (
    <FastTable.Table
      tableStyles={tableStyles}
      rowStyles={rowStyles}
      columns={transformedColumns}
      data={tableData}
      draggable={false}
      noBorders={noBorders}
      stickyHeader
      noHeaders={hideTableHeaders}
      getRowId={getRowId}
      onRowClick={handleOnRowClick}
      renderRowGroupFooter={addRowBottom ? renderRowGroupExtraHeader : renderRowGroupFooter}
      renderRowGroupExtraHeader={
        (addDecisionInTableHeader && !hideTableHeaders) || addRowBottom ? undefined : renderRowGroupExtraHeader
      }
      renderRowMenu={renderRowMenu}
      noDragHandle={!rowMoreMenu}
      renderRowGroupEmptyPlaceholder={renderRowGroupEmptyPlaceholder}
    />
  )
}

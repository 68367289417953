import { HTMLContent } from '@tiptap/core'
import { defineCapability } from '../capabilities/Capability'

export const FOCUS_AND_QUOTE_COMMENT = defineCapability<[selectedHtml: HTMLContent]>('focusAndQuoteComment')
export const FOCUS_COMMENT_INPUT = defineCapability('focusCommentInput')
export const FOCUS_AND_QUOTE_COMMENT_AS_DECISION = defineCapability<[selectedHtml: HTMLContent]>(
  'focusAndQuoteCommentAsDecision',
)
export const REPLY_TO_COMMENT = defineCapability('replyToComment')
export const MARK_OR_UNMARK_COMMENT_AS_DECISION = defineCapability('markOrUnmarkCommentAsDecision')

import { utils } from '@mm/common/src'
import { Text, Flex, Button } from '@mm/company-ui'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import {
  Controller,
  FieldArrayWithId,
  FieldError,
  FieldErrorsImpl,
  FormProvider,
  Merge,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import {
  UpdateActionStatus,
  UpdateItemGoalUpdateDataFragment,
  UpdateItemActionUpdateDataFragment,
  UpdateUpdateDocument,
} from '../../../gen/graphql/documents'
import { useMutation } from '../../apollo'
import { CapabilitiesProvider } from '../../capabilities'
import { BaseActionStatusSelect } from './ActionStatusSelect'
import { ActionUpdateRow } from './ActionUpdateRow'
import { GoalUpdateRow } from './GoalUpdateRow'
import { UpdateActionForm } from './UpdateActionForm'
import { UpdateGoalForm } from './UpdateGoalForm'

type EditUpdateFormProps = {
  userId: string
  updateId: string
  initialValues: {
    goals: Array<UpdateItemGoalUpdateDataFragment>
    actions: Array<UpdateItemActionUpdateDataFragment>
  }
  onSubmitSuccess?: () => void
}

export type EditUpdateFormRef = { submit: () => Promise<void>; clear: () => void; navigateBack: () => void }

export type EditUpdateData = {
  goals: Array<UpdateItemGoalUpdateDataFragment>
  actions: Array<UpdateItemActionUpdateDataFragment>
}

const findDifferences = <T extends Record<string, unknown>>(original: T, changed?: T) => {
  return _.pickBy<T>(changed, (value, key) => !_.isEqual(value, original?.[key]))
}

const findChangedItems = <T extends Record<string, unknown>>(
  accessor: (item: T) => string,
  original: Array<T>,
  changed: Array<T>,
  extras: (item: T) => Partial<T>,
): Array<Partial<T>> => {
  return _.differenceWith(original, changed, (a, b) => {
    const differences = findDifferences<T>(a, b)
    return _.isEmpty(differences)
  }).map((item) => {
    return {
      ...findDifferences<T>(
        item,
        _.find(changed, (match) => accessor(match) === accessor(item)),
      ),
      ...extras(item),
    }
  })
}

export const EditUpdateForm = ({ initialValues, updateId, onSubmitSuccess }: EditUpdateFormProps) => {
  const [loading, setLoading] = useState(false)
  const [updateUpdate] = useMutation(UpdateUpdateDocument)

  const formMethods = useForm<EditUpdateData>({
    mode: 'onChange',
    defaultValues: initialValues,
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = formMethods

  const handleClear = () => {
    reset()
  }

  const submitForm = handleSubmit(async (values: EditUpdateData) => {
    const updatedActions = findChangedItems(
      (item) => item.action.id,
      initialValues.actions,
      values.actions,
      (item) => ({ action: item.action }),
    )
    const updatedGaols = findChangedItems(
      (item) => item.goal.id,
      initialValues.goals,
      values.goals,
      (item) => ({ goal: item.goal }),
    )

    try {
      const update = await updateUpdate({
        variables: {
          updateId,
          data: {
            actions: updatedActions.map((action) => ({
              ...action,
              action: String(action.action?.id),
              nextActions: action.nextActions?.map((action) => action?.id).filter(utils.isNonNil),
            })),
            goals: updatedGaols.map((goal) => ({
              ...goal,
              goal: String(goal.goal?.id),
              nextActions: goal.nextActions?.map((action) => action?.id).filter(utils.isNonNil),
            })),
          },
        },
      })

      if (update.data?.updateUpdate?.__typename === 'Update') {
        handleClear()
        onSubmitSuccess?.()
        return
      }

      if (update.errors) {
        alert('The server had a problem updating your update. Please refresh the page and try again.')
        console.error(update) // eslint-disable-line no-console
      }
    } catch (error) {
      alert('There was a problem updating your update. Please refresh the page and try again.')
      console.error(error) // eslint-disable-line no-console
    }
  })

  const { fields: actionFields } = useFieldArray({
    control,
    name: 'actions',
  })

  const { fields: goalFields } = useFieldArray({
    control,
    name: 'goals',
  })

  return (
    <FormProvider {...formMethods}>
      <div sx={{ paddingBottom: 4 }}>
        <Text
          as="h4"
          variant="h3"
          bold
          sx={{
            marginTop: 3,
            marginLeft: 1,
          }}
        >
          Actions
        </Text>

        <Flex column gap={4} sx={{ marginTop: 3 }}>
          {actionFields.map((field, index) => {
            return (
              <div key={field.action.id}>
                <ActionUpdateFormItem
                  field={field}
                  fieldIdentifier={`actions.${index}`}
                  error={errors.actions?.[index]}
                />
              </div>
            )
          })}
        </Flex>

        <Text
          as="h4"
          variant="h3"
          bold
          sx={{
            marginTop: 3,
            marginBottom: 1,
            marginLeft: 1,
          }}
        >
          Goals
        </Text>

        <Flex column gap={4} sx={{ marginTop: 3 }}>
          {goalFields.map((field, index) => (
            <GoalUpdateFormItem
              key={field.goal.id}
              field={field}
              fieldIdentifier={`goals.${index}`}
              error={errors.goals?.[index]}
            />
          ))}
        </Flex>

        <Button
          loading={loading}
          onClick={async () => {
            setLoading(true)
            await submitForm()
            setLoading(false)
          }}
          variant="accent"
          sx={{ width: 320, marginTop: 4 }}
        >
          Save
        </Button>
      </div>
    </FormProvider>
  )
}

type ActionUpdateFormItemProps = {
  field: FieldArrayWithId<EditUpdateData, 'actions', 'id'>
  fieldIdentifier: `actions.${number}`
  error?: Merge<FieldError, FieldErrorsImpl<EditUpdateData['actions'][number]>>
}

function ActionUpdateFormItem({ field, fieldIdentifier, error }: ActionUpdateFormItemProps) {
  const formMethods = useFormContext<EditUpdateData>()
  const { control, getValues: _, watch } = formMethods
  const watchStatus = watch(`${fieldIdentifier}.status`)

  const isPastDue =
    field.action.status === 'ACTIVE' &&
    !!field.action.dueAt &&
    DateTime.fromMillis(field.action.dueAt).startOf('day') <= DateTime.now().startOf('day')

  return (
    <Flex column>
      <Flex row gap={2} align="flex-start">
        <div sx={{ flex: 1, minWidth: 0 }}>
          <ActionUpdateRow action={field.action} />
        </div>

        <Controller
          name={`${fieldIdentifier}.status`}
          rules={{ required: isPastDue }}
          control={control}
          render={({ field: { onChange, value } }) => {
            const handleChange = (value: UpdateActionStatus) => {
              onChange(value)
            }

            return !isPastDue ? (
              <div sx={{ marginTop: 1, marginRight: 1 }}>
                <Flex row gap={1}>
                  <BaseActionStatusSelect
                    value={value || watchStatus || 'ACTIVE'}
                    onChange={(value) => {
                      handleChange(value as UpdateActionStatus)
                    }}
                  />
                </Flex>
              </div>
            ) : (
              <></>
            )
          }}
        />
      </Flex>

      {isPastDue ? (
        <Flex row sx={{ paddingY: 1, marginLeft: 1 }}>
          <div
            sx={{
              width: '4px',
              borderRadius: 'medium',
              backgroundColor: 'border',
              marginRight: 1.5,
            }}
          />
          <div sx={{ flex: 1, minWidth: 0 }}>
            <CapabilitiesProvider>
              <UpdateActionForm field={field} fieldIdentifier={fieldIdentifier} error={error} />
            </CapabilitiesProvider>
          </div>
        </Flex>
      ) : null}
    </Flex>
  )
}

type GoalUpdateFormItemProps = {
  field: FieldArrayWithId<EditUpdateData, 'goals', 'id'>
  fieldIdentifier: `goals.${number}`
  error?: Merge<FieldError, FieldErrorsImpl<EditUpdateData['goals'][number]>>
}

function GoalUpdateFormItem({ field, fieldIdentifier, error }: GoalUpdateFormItemProps) {
  const { control: _, getValues: __, setValue: ___ } = useFormContext<EditUpdateData>()

  return (
    <Flex column>
      <Flex column align="flex-start">
        <div sx={{ width: '100%' }}>
          <GoalUpdateRow goal={field.goal} />
        </div>
      </Flex>

      <Flex row sx={{ paddingY: 1, marginLeft: 1 }}>
        <div
          sx={{
            width: '4px',
            borderRadius: 'medium',
            backgroundColor: 'border',
            marginRight: 1.5,
          }}
        />
        <div sx={{ flex: 1, minWidth: 0 }}>
          <CapabilitiesProvider>
            <UpdateGoalForm field={field} fieldIdentifier={fieldIdentifier} error={error} />
          </CapabilitiesProvider>
        </div>
      </Flex>
    </Flex>
  )
}

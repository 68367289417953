import { Flex, Text } from '@mm/company-ui'
import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { CreateDecisionData } from '../types'
import { CreateDecisionPrivacy } from './CreateDecisionPrivacy'

export type CreateDecisionModalHeaderProps = {
  control: Control<CreateDecisionData>
  isParentMeetingPublic?: boolean
}
export const CreateDecisionModalHeader = ({ control, isParentMeetingPublic }: CreateDecisionModalHeaderProps) => {
  return (
    <Flex gap={1} justify="space-between" align="center" sx={{ paddingX: 2, paddingY: 1 }}>
      <Text sx={{ color: 'text-light', fontWeight: 'bold' }}>New Issue</Text>
      <Controller
        name="privacy"
        control={control}
        render={({ field }) => {
          const { ref: _, ...restField } = field
          return (
            <CreateDecisionPrivacy
              {...restField}
              privacyLevel={field.value}
              isParentMeetingPublic={isParentMeetingPublic}
              setPrivacyLevel={field.onChange}
            />
          )
        }}
      />
    </Flex>
  )
}

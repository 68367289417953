import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CalendarRescheduleIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="calendar-reschedule" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.01511 0.0999756C7.51217 0.0999756 7.91511 0.502919 7.91511 0.999976V2.59998H12.7818V0.999976C12.7818 0.502919 13.1847 0.0999756 13.6818 0.0999756C14.1788 0.0999756 14.5818 0.502919 14.5818 0.999976V2.59998H16.8818C18.3729 2.59998 19.5818 3.80881 19.5818 5.29998V7.33811V9.1C19.5818 9.59706 19.1788 10 18.6818 10C18.1847 10 17.7818 9.59706 17.7818 9.1V8.23811H2.8V16.7C2.8 17.197 3.20294 17.6 3.7 17.6H9.29089C9.78794 17.6 10.1909 18.0029 10.1909 18.5C10.1909 18.997 9.78794 19.4 9.29089 19.4H3.7C2.20883 19.4 1 18.1911 1 16.7V5.29998C1 3.80881 2.20883 2.59998 3.7 2.59998H6.11511V0.999976C6.11511 0.502919 6.51806 0.0999756 7.01511 0.0999756ZM2.8 6.43811H17.7818V5.29998C17.7818 4.80292 17.3788 4.39998 16.8818 4.39998L13.6818 4.39998H7.01511L3.7 4.39998C3.20294 4.39998 2.8 4.80292 2.8 5.29998V6.43811ZM15.2794 10.3636C14.9279 10.0121 14.3581 10.0121 14.0066 10.3636C13.6551 10.7151 13.6551 11.2849 14.0066 11.6364L16.1839 13.8136C14.837 13.8128 13.7645 13.8132 12.5868 13.8137H12.5863H12.5857H12.585H12.5842C11.8055 13.8141 10.9806 13.8144 10 13.8144C9.50295 13.8144 9.10001 14.2173 9.10001 14.7144C9.10001 15.2115 9.50295 15.6144 10 15.6144H16.1846L14.0066 17.7924C13.6551 18.1439 13.6551 18.7137 14.0066 19.0652C14.3581 19.4167 14.9279 19.4167 15.2794 19.0652L18.9938 15.3508C19.3453 14.9993 19.3453 14.4295 18.9938 14.078L15.2794 10.3636Z"
    />
  </IconBase>
)

import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { EditorContent } from '@tiptap/react'
import { css } from 'theme-ui'
import { variant } from '../../../helpers'
import { EditorStyles } from './styles'

export type EditableContentProps = {
  mode?: 'full' | 'compact'
  hidePlaceholderWhenFocused?: boolean
}

// TODO: Hook up all styles to theme.
export const EditableContent = styled(EditorStyles.withComponent(EditorContent), {
  shouldForwardProp: (prop) => prop === 'editor' || (isPropValid(prop) && !['placeholder', 'mode'].includes(prop)),
})<EditableContentProps>`
  ${variant({
    prop: 'mode',
    variants: {
      compact: {},
      full: {
        '> .ProseMirror': {
          padding: '10px',

          borderRadius: '2px',
          borderColor: 'background-medium',
          borderWidth: '1px',
          borderStyle: 'solid',

          '&:focus': {
            borderColor: 'background-strong',
            borderWidth: '1px',
            borderStyle: 'solid',
          },
        },
      },
    },
  })}

  display: flex;
  flex-direction: column;

  > .ProseMirror {
    flex-grow: 1;
    outline: none;

    overflow-wrap: anywhere;
    // fallback to deprecated break-word in case overflow-wrap: anywhere is not supported (eg Safari)
    word-break: break-word;
  }

  .ProseMirror img {
    max-width: 100%;
  }

  .ProseMirror .ProseMirror-selectednode {
    outline: 2px solid ${({ theme }) => theme.colors['accent-border-light']};
    outline-offset: 4px;
    border-radius: 1px;
  }

  ${({ hidePlaceholderWhenFocused }) =>
    css({
      [`${hidePlaceholderWhenFocused ? '&:not(:focus-within)' : ''} > .ProseMirror.empty::before`]: {
        content: `attr(aria-placeholder)`,
        float: 'left',
        color: 'background-strong-alpha',
        pointerEvents: 'none',
        height: 0,
      },
    })}

  //p[data-placeholder].is-empty::after {
  //  content: attr(data-placeholder);
  //  color: #ced4da;
  //  pointer-events: none;
  //  display: block;
  //  margin-top: -24px;
  //}

  .collaboration-cursor__caret {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    word-break: normal;
    pointer-events: none;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    position: absolute;
    top: -1.4em;
    left: -1px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    user-select: none;
    color: #0d0d0d;
    padding: 0.1rem 0.3rem;
    border-radius: 3px 3px 3px 0;
    white-space: nowrap;
    z-index: 2;
  }
`

EditableContent.defaultProps = {
  mode: 'full',
  hidePlaceholderWhenFocused: false,
}

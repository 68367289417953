import { Flex, Text, formatDateDisplay } from '@mm/company-ui'
import React from 'react'
import { TopicRowDataFragment } from '../../../gen/graphql/documents'
import { TopicLinkWithMenu } from './TopicLinkWithMenu'

export type TopicRowProps = {
  topic: TopicRowDataFragment
  hasBorder?: boolean
}

export function TopicRow({ topic, hasBorder = true }: TopicRowProps) {
  return (
    <TopicLinkWithMenu topicId={topic.id}>
      <Flex
        row
        gap={1}
        grow
        sx={{
          minWidth: 0,
          paddingX: 1,
          paddingY: 1.5,
          ...(hasBorder
            ? {
                borderBottom: '1px solid',
                borderBottomColor: 'border',
              }
            : {}),
        }}
      >
        <Flex column sx={{ flex: 1, minWidth: 0 }}>
          <Flex row gap={1}>
            <Text
              bold
              sx={{
                flex: 1,
                lineHeight: '1.5em',
                maxHeight: '3em',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                marginTop: 0.25,
                marginLeft: 1,
              }}
            >
              {topic.title}
            </Text>
          </Flex>
        </Flex>
        <Flex row gap={2} sx={{ minWidth: 0, flexShrink: 0 }}>
          <Text color="text-light" sx={{ flexShrink: 0, marginTop: 0.25, textAlign: 'right' }}>
            Created {formatDateDisplay(topic.createdAt)}
          </Text>
        </Flex>
      </Flex>
    </TopicLinkWithMenu>
  )
}

import React from 'react'
import { GoodThingEmojiReactionsDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { EmojiReactions } from '../../components/emoji/EmojiReactions'
import { useAddGoodThingEmojiReaction } from '../hooks/useAddGoodThingEmojiReaction'
import { useRemoveGoodThingEmojiReaction } from '../hooks/useRemoveGoodThingEmojiReaction'

export type GoodThingEmojiReactionsProps = {
  id: string
  cached?: boolean
  hasAddButton?: boolean
}

export const GoodThingEmojiReactions = ({ id, cached, hasAddButton }: GoodThingEmojiReactionsProps) => {
  const { data } = useQuery(GoodThingEmojiReactionsDocument, {
    variables: { id },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const addReaction = useAddGoodThingEmojiReaction(id)
  const removeReaction = useRemoveGoodThingEmojiReaction(id)

  const emojiReactions = data?.goodThing?.emojiReactions || []

  return (
    <EmojiReactions
      testid="GoodThingEmojiReactions"
      reactions={emojiReactions}
      hasAddButton={hasAddButton}
      onAdd={addReaction}
      onRemove={removeReaction}
    />
  )
}

import { oembed } from '@loomhq/loom-embed'
import { Button, Card, Editable, Flex, Text, formatDateDisplay, formatUserDisplayName, toast } from '@mm/company-ui'
import { ClipboardDocIcon, LinkIcon } from '@mm/company-ui-icons/src'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { UpdateItemDataFragment } from '../../../gen/graphql/documents'
import { CapabilitiesProvider } from '../../capabilities'
import { UpdateComments } from './UpdateComments'

const stripHtml = (html: string): string => {
  return html.replace(/<[^>]*>?/gm, '')
}

export type UpdateListTextItemProps = {
  update: UpdateItemDataFragment
}

function selectElementContents(element: HTMLDivElement) {
  const range = document.createRange()
  range.selectNodeContents(element)
  const select = window.getSelection()
  if (select) {
    select.removeAllRanges()
    select.addRange(range)
  }
}

export function UpdateListTextItem({ update }: UpdateListTextItemProps) {
  const handleCopyPlainText = () => {
    const element = document.getElementById(`update-plain-text-${update.id}`) as HTMLDivElement

    const clone = element.cloneNode(true) as HTMLDivElement
    document.body.appendChild(clone)
    selectElementContents(clone)
    document.execCommand('copy')
    document.body.removeChild(clone)

    toast('Copied text to clipboard')
  }

  const fullUrl = new URL(`/updates/${update.id}`, process.env.NEXT_PUBLIC_FRONTEND_URL).href

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(fullUrl)
    toast('Copied link to clipboard')
  }

  return (
    <div>
      <Flex row align="center" gap={1} justify="space-between" sx={{ marginBottom: 1 }}>
        <Text sx={{ margin: 0 }}>
          Update for {formatUserDisplayName(update.author)} on{' '}
          {formatDateDisplay(update.reportAt, 'SHORT_MONTH_WITH_DATE')}
        </Text>

        <Flex row gap={1}>
          <Button
            variant="muted"
            size="small"
            onClick={handleCopyPlainText}
            startIcon={<ClipboardDocIcon />}
            textHidden
          >
            Copy plain-text update
          </Button>

          <Button
            endIcon={<LinkIcon />}
            textHidden
            variant="muted"
            size="small"
            className="link"
            onClick={handleCopyLink}
          >
            Copy Link
          </Button>
        </Flex>
      </Flex>

      <div sx={{ height: 0, overflow: 'hidden' }}>
        <div id={`update-plain-text-${update.id}`}>
          <ul>
            <li>
              <a href={fullUrl}>{fullUrl}</a>
            </li>
            <li>
              Actions Review
              <ul>
                {update.actionUpdates?.map((actionUpdate) => (
                  <li key={actionUpdate.action.id}>
                    <span style={{ fontWeight: 'bold' }}>
                      {actionUpdate.action.title}
                      {actionUpdate.action.dueAt
                        ? ` by ${formatDateDisplay(actionUpdate.action.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                        : ''}
                    </span>

                    <ul>
                      <li>
                        <span style={{ fontWeight: 'bold' }}>
                          {actionUpdate.status === 'DONE'
                            ? 'DONE'
                            : actionUpdate.status === 'NOT_DONE'
                            ? 'NOT DONE'
                            : actionUpdate.status === 'CANCELLED'
                            ? 'CANCELED'
                            : actionUpdate.status}
                        </span>

                        {actionUpdate.status === 'DONE' &&
                        ((actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml)) ||
                          actionUpdate.nextActions?.length) ? (
                          <ul>
                            {actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml) ? (
                              <li>
                                Any key insights?
                                <ul>
                                  <li>
                                    <Editable mode="compact" initialValue={actionUpdate.reasonHtml} editable={false} />
                                  </li>
                                </ul>
                              </li>
                            ) : null}

                            {actionUpdate.nextActions?.length ? (
                              <li>
                                Next actions:
                                <ul>
                                  {actionUpdate.nextActions.map(
                                    (nextAction) =>
                                      nextAction && (
                                        <li>
                                          <span style={{ fontWeight: 'bold' }}>
                                            {nextAction.title}
                                            {nextAction.dueAt
                                              ? ` by ${formatDateDisplay(nextAction.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                                              : ''}
                                          </span>
                                        </li>
                                      ),
                                  )}
                                </ul>
                              </li>
                            ) : null}
                          </ul>
                        ) : null}

                        {actionUpdate.status === 'NOT_DONE' ? (
                          <ul>
                            {actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml) ? (
                              <li>
                                What blocked you?
                                <ul>
                                  <li>
                                    <Editable mode="compact" initialValue={actionUpdate.reasonHtml} editable={false} />
                                  </li>
                                </ul>
                              </li>
                            ) : null}

                            {actionUpdate.repeat ? (
                              <li>
                                Do you still want to do it?
                                <ul>
                                  <li>{actionUpdate.repeat ? 'Yes' : 'No'}</li>
                                </ul>
                              </li>
                            ) : null}

                            {actionUpdate.nextHtml && stripHtml(actionUpdate.nextHtml) ? (
                              <li>
                                How to avoid getting blocked again?
                                <ul>
                                  <li>
                                    <Editable mode="compact" initialValue={actionUpdate.nextHtml} editable={false} />
                                  </li>
                                </ul>
                              </li>
                            ) : null}

                            {actionUpdate.nextActions?.length ? (
                              <li>
                                Next actions:
                                <ul>
                                  {actionUpdate.nextActions.map(
                                    (nextAction) =>
                                      nextAction && (
                                        <li>
                                          <span style={{ fontWeight: 'bold' }}>
                                            {nextAction.title}
                                            {nextAction.dueAt
                                              ? ` by ${formatDateDisplay(nextAction.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                                              : ''}
                                          </span>
                                        </li>
                                      ),
                                  )}
                                </ul>
                              </li>
                            ) : null}
                          </ul>
                        ) : null}
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              Goals Review
              <ul>
                {update.goals?.map((goalUpdate) => (
                  <li key={goalUpdate.goal.id}>
                    <span>
                      {goalUpdate.goal.title}
                      {goalUpdate.goal.dueAt
                        ? ` by ${formatDateDisplay(goalUpdate.goal.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                        : ''}
                    </span>

                    <ul>
                      <li>
                        Status:{' '}
                        {goalUpdate.status === 'ON_TRACK' ? (
                          <span>🟢 On Track</span>
                        ) : goalUpdate.status === 'AT_RISK' ? (
                          <span>🟡 At risk</span>
                        ) : goalUpdate.status === 'BEHIND' ? (
                          <span>🔴 Off Track</span>
                        ) : (
                          <span>{_.startCase(_.toLower(goalUpdate.status))}</span>
                        )}
                      </li>

                      {goalUpdate.reasonHtml ? (
                        <Editable mode="compact" initialValue={goalUpdate.reasonHtml} sx={{}} editable={false} />
                      ) : null}

                      {goalUpdate.nextHtml && stripHtml(goalUpdate.nextHtml) ? (
                        <li>
                          How to fix not good, or advance?
                          <ul>
                            <li>
                              <Editable mode="compact" initialValue={goalUpdate.nextHtml} editable={false} />
                            </li>
                          </ul>
                        </li>
                      ) : null}

                      {goalUpdate.nextActions?.length ? (
                        <li>
                          Next actions:
                          <ul>
                            {goalUpdate.nextActions.map(
                              (nextAction) =>
                                nextAction && (
                                  <li>
                                    <span style={{ fontWeight: 'bold' }}>
                                      {nextAction.title}
                                      {nextAction.dueAt
                                        ? ` by ${formatDateDisplay(nextAction.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                                        : ''}
                                    </span>
                                  </li>
                                ),
                            )}
                          </ul>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <Card sx={{ paddingLeft: 0, borderRadius: 'medium' }} variant="flat">
        <ul>
          <li>
            Actions Review
            <ul>
              {update.actionUpdates?.map((actionUpdate) => (
                <li key={actionUpdate.action.id}>
                  <span style={{ fontWeight: 'bold' }}>
                    {actionUpdate.action.title}
                    {actionUpdate.action.dueAt
                      ? ` by ${formatDateDisplay(actionUpdate.action.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                      : ''}
                  </span>

                  <ul>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>
                        {actionUpdate.status === 'DONE'
                          ? 'DONE'
                          : actionUpdate.status === 'NOT_DONE'
                          ? 'NOT DONE'
                          : actionUpdate.status === 'CANCELLED'
                          ? 'CANCELED'
                          : actionUpdate.status}
                      </span>

                      {actionUpdate.status === 'DONE' &&
                      ((actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml)) ||
                        actionUpdate.nextActions?.length) ? (
                        <ul>
                          {actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml) ? (
                            <li>
                              Any key insights?
                              <ul>
                                <li>
                                  <Editable mode="compact" initialValue={actionUpdate.reasonHtml} editable={false} />
                                </li>
                              </ul>
                            </li>
                          ) : null}

                          {actionUpdate.nextActions?.length ? (
                            <li>
                              Next actions:
                              <ul>
                                {actionUpdate.nextActions.map(
                                  (nextAction) =>
                                    nextAction && (
                                      <li>
                                        <span style={{ fontWeight: 'bold' }}>
                                          {nextAction.title}
                                          {nextAction.dueAt
                                            ? ` by ${formatDateDisplay(nextAction.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                                            : ''}
                                        </span>
                                      </li>
                                    ),
                                )}
                              </ul>
                            </li>
                          ) : null}
                        </ul>
                      ) : null}

                      {actionUpdate.status === 'NOT_DONE' ? (
                        <ul>
                          {actionUpdate.reasonHtml && stripHtml(actionUpdate.reasonHtml) ? (
                            <li>
                              What blocked you?
                              <ul>
                                <li>
                                  <Editable mode="compact" initialValue={actionUpdate.reasonHtml} editable={false} />
                                </li>
                              </ul>
                            </li>
                          ) : null}

                          {actionUpdate.repeat ? (
                            <li>
                              Do you still want to do it?
                              <ul>
                                <li>{actionUpdate.repeat ? 'Yes' : 'No'}</li>
                              </ul>
                            </li>
                          ) : null}

                          {actionUpdate.nextHtml && stripHtml(actionUpdate.nextHtml) ? (
                            <li>
                              How to avoid getting blocked again?
                              <ul>
                                <li>
                                  <Editable mode="compact" initialValue={actionUpdate.nextHtml} editable={false} />
                                </li>
                              </ul>
                            </li>
                          ) : null}

                          {actionUpdate.nextActions?.length ? (
                            <li>
                              Next actions:
                              <ul>
                                {actionUpdate.nextActions.map(
                                  (nextAction) =>
                                    nextAction && (
                                      <li>
                                        <span style={{ fontWeight: 'bold' }}>
                                          {nextAction.title}
                                          {nextAction.dueAt
                                            ? ` by ${formatDateDisplay(nextAction.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                                            : ''}
                                        </span>
                                      </li>
                                    ),
                                )}
                              </ul>
                            </li>
                          ) : null}
                        </ul>
                      ) : null}
                    </li>
                  </ul>
                </li>
              ))}
            </ul>
          </li>

          <li>
            Goals Review
            <ul>
              {update.goals?.map((goalUpdate) => (
                <li key={goalUpdate.goal.id}>
                  <span>
                    {goalUpdate.goal.title}
                    {goalUpdate.goal.dueAt
                      ? ` by ${formatDateDisplay(goalUpdate.goal.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                      : ''}
                  </span>

                  <ul>
                    <li>
                      Status:{' '}
                      {goalUpdate.status === 'ON_TRACK' ? (
                        <span>🟢 On Track</span>
                      ) : goalUpdate.status === 'AT_RISK' ? (
                        <span>🟡 At risk</span>
                      ) : goalUpdate.status === 'BEHIND' ? (
                        <span>🔴 Off Track</span>
                      ) : (
                        <span>{_.startCase(_.toLower(goalUpdate.status))}</span>
                      )}
                    </li>

                    {goalUpdate.reasonHtml ? (
                      <Editable mode="compact" initialValue={goalUpdate.reasonHtml} sx={{}} editable={false} />
                    ) : null}

                    {goalUpdate.nextHtml && stripHtml(goalUpdate.nextHtml) ? (
                      <li>
                        How to fix not good, or advance?
                        <ul>
                          <li>
                            <Editable mode="compact" initialValue={goalUpdate.nextHtml} editable={false} />
                          </li>
                        </ul>
                      </li>
                    ) : null}

                    {goalUpdate.nextActions?.length ? (
                      <li>
                        Next actions:
                        <ul>
                          {goalUpdate.nextActions.map(
                            (nextAction) =>
                              nextAction && (
                                <li>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {nextAction.title}
                                    {nextAction.dueAt
                                      ? ` by ${formatDateDisplay(nextAction.dueAt, 'SHORT_MONTH_WITH_DATE')}`
                                      : ''}
                                  </span>
                                </li>
                              ),
                          )}
                        </ul>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        </ul>

        <Flex justify="center">{update.goalsLoomLink ? <LoomVideo url={update.goalsLoomLink} /> : null}</Flex>

        <div sx={{ padding: 1, paddingBottom: 0, borderTop: '1px solid', borderTopColor: 'border' }}>
          <CapabilitiesProvider>
            <UpdateComments updateId={update.id} />
          </CapabilitiesProvider>
        </div>
      </Card>
    </div>
  )
}

export const LoomVideo = ({ url }: { url: string }) => {
  const [isErrored, setIsErrored] = useState(false)
  const [video, setVideo] = useState('')

  useEffect(() => {
    async function main() {
      try {
        const embed = await oembed(url, { width: 500, height: 300 })
        setVideo(embed.html)
      } catch (e) {
        setIsErrored(true)
        console.error(e) //eslint-disable-line no-console
      }
    }

    void main()
  }, [url])

  return !isErrored ? <div dangerouslySetInnerHTML={{ __html: video }}></div> : <div>Error loading loom video</div>
}

import React, { useCallback } from 'react'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { editActionWaitingForCommand } from '../commands/editActionWaitingForCommand'

export type ActionWaitingForCellContentProps = {
  actionId: string
  waitingForId: string
  avatarOnly?: boolean
}

export const ActionWaitingForCellContent = ({
  waitingForId,
  actionId,
  avatarOnly,
}: ActionWaitingForCellContentProps) => {
  const editActionWaitingFor = useCommand(editActionWaitingForCommand)

  const handleSelectedUser = useCallback(
    (waitingForId: string) => {
      void editActionWaitingFor.execute({ actionId, waitingForId })
    },
    [actionId, editActionWaitingFor],
  )

  return (
    <UserSelectWithCompanyMembers
      aria-label="Waiting For"
      value={waitingForId}
      onAdd={handleSelectedUser}
      withName={!avatarOnly}
    />
  )
}

import { utils } from '@mm/common'
import {
  Button,
  Flex,
  FloatingTooltip,
  formatUserDisplayName,
  PopupWrapper,
  Text,
  useFeatureFlags,
} from '@mm/company-ui'
import { CheckIcon, EyeViewOpen } from '@mm/company-ui-icons/src'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useActiveUser } from '../users'

type UserReview = {
  user: {
    id: string
    firstName?: string | null
    lastName?: string | null
    email: string
  }
  reviewedAt: number
}

export type UserReviewsProps = {
  userReviews?: Array<UserReview> | null
  onToggle: () => void
  size?: 'small' | 'medium'
  withButton?: boolean
  markAsReviewedOnOpen?: boolean
}

export const UserReviews = ({
  userReviews,
  onToggle,
  withButton,
  size,
  markAsReviewedOnOpen = false,
}: UserReviewsProps) => {
  const activeUser = useActiveUser()
  const active = userReviews?.some(({ user }) => user.id === activeUser?.id) ?? null
  const [reviewAutomatically, setReviewAutomatically] = useState(markAsReviewedOnOpen && !active)
  const { markReviewedOnOpen } = useFeatureFlags()

  const count = userReviews?.length ?? 0
  const names =
    userReviews?.map(({ user }) => formatUserDisplayName(user, 'FIRST_NAME_WITH_FALLBACK')).filter(utils.isNonNil) ?? []

  useEffect(() => {
    if (active == null) return
    if (markReviewedOnOpen && !active && reviewAutomatically) {
      setReviewAutomatically(false)
      onToggle()
    }
  }, [active, markReviewedOnOpen, onToggle, reviewAutomatically])

  const debounceToggleUserReview = debounce(() => {
    onToggle()
  }, 500)

  useHotkeys(
    'R',
    () => {
      if (withButton) {
        debounceToggleUserReview()
      }
    },
    [],
  )

  return (
    <UserReviewReactionPill
      count={count}
      active={active ?? false}
      size={size}
      withButton={withButton}
      tooltip={
        utils.isNonEmptyArray(names) && (
          <PopupWrapper>
            <Text variant="small">
              {names.length === 1 ? names[0] : utils.init(names).join(', ') + ', and ' + utils.last(names)}
            </Text>
            <Text variant="small" color="text-light">
              {' '}
              reviewed
            </Text>
          </PopupWrapper>
        )
      }
      onClick={onToggle}
    />
  )
}

export type UserReviewReactionPillProps = {
  count: number
  active: boolean
  tooltip?: React.ReactNode
  onClick?: () => void
  size?: 'small' | 'medium'
  withButton?: boolean
}

export const UserReviewReactionPill = ({
  count,
  active,
  tooltip,
  size = 'medium',
  withButton = false,
  onClick,
}: UserReviewReactionPillProps) => {
  return (
    <Flex row gap={1} align="center">
      {withButton && !active ? (
        <Button size="small" startIcon={<CheckIcon />} onClick={onClick}>
          Mark reviewed
        </Button>
      ) : null}

      <FloatingTooltip placement="bottom" tooltip={tooltip}>
        {({ getReferenceProps }) => (
          <Flex>
            {withButton && active ? (
              <Button size="small" variant="accent-inverted" startIcon={<CheckIcon />} onClick={onClick}>
                Reviewed
              </Button>
            ) : null}
            <Button
              variant="muted"
              size="small"
              {...getReferenceProps({ onClick })}
              sx={{
                height: size === 'medium' ? undefined : '20px',
                color: active ? 'accent-text' : undefined,
                paddingX: 0.75,
                gap: 0.5,
              }}
              data-clickable="false" // Break row-clicking showing drawers on tables
              startIcon={<EyeViewOpen />}
            >
              {count}
            </Button>
          </Flex>
        )}
      </FloatingTooltip>
    </Flex>
  )
}

export const UserReviewBubble = ({ userReviews }: { userReviews: UserReview[] }) => {
  const activeUser = useActiveUser()
  const isReviewed = userReviews?.some(({ user }) => user.id === activeUser?.id) ?? false
  return isReviewed ? null : <PurpleBubble />
}

export const PurpleBubble = ({ className }: { className?: string }) => (
  <div
    className={className}
    title="Pending review"
    sx={{
      height: 1,
      width: 1,
      backgroundColor: 'accent-background',
      borderRadius: '50%',
      display: 'inline-block',
    }}
  ></div>
)

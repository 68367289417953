import { Extension } from '@tiptap/core'

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface MarkConfig<Options = any, Storage = any> {
    yPrivateAttrs?: Record<string, boolean>
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface NodeConfig<Options = any, Storage = any> {
    yPrivateAttrs?: Record<string, boolean>
  }
}

export const CollaborationPrivateAttrs = Extension.create({
  name: 'CollaborationPrivateAttrs',

  extendNodeSchema(extension) {
    return {
      yPrivateAttrs: extension.config.yPrivateAttrs ?? {},
    }
  },

  extendMarkSchema(extension) {
    return {
      yPrivateAttrs: extension.config.yPrivateAttrs ?? {},
    }
  },
})

import React, { useCallback, useMemo } from 'react'
import {
  CreateActionInput,
  CreateActionOnPrototypeFeedbackDocument,
  FeedbackActionsDocument,
  FeedbackAuthorDocument,
} from '../../../gen/graphql/documents'
import { ActionsDashboardFlat, ActionsUpdatedTopic, createDataSource, markActionAsCreatedHere } from '../../actions'
import { capture } from '../../analytics'
import { useMutation, useQuery } from '../../apollo'
import { useEmitEventBusEvent } from '../../eventbus'
import { useFeatureFlags } from '../../featureFlags'
import { useMeetingContext } from '../../meetings'

export type FeedbackActionsProps = {
  id: string
  noHeaders?: boolean
  skipRegisteringCapabilities?: boolean
}

export const FeedbackActions = ({
  id,
  noHeaders = false,
  skipRegisteringCapabilities = false,
}: FeedbackActionsProps) => {
  const { actionsOnFeedbackDefaultWaitingForIsAuthor } = useFeatureFlags()
  const emit = useEmitEventBusEvent(ActionsUpdatedTopic)
  const dataSource = useFeedbackActionsDataSource(id)

  const { data } = useQuery(FeedbackAuthorDocument, {
    variables: {
      id,
    },
  })

  const [addAction] = useMutation(CreateActionOnPrototypeFeedbackDocument, {
    update: (cache, { data }) => {
      const action = data?.createActionOnPrototypeFeedback
      if (action?.__typename === 'Action') {
        capture('Action Created', {
          parent: 'Feedback',
          parentMeetingId: meeting?.id,
        })
        markActionAsCreatedHere(cache, action)
        emit({ type: 'upserted', action })
      }
    },
  })

  const meeting = useMeetingContext()

  const handleAddAction = useCallback(
    async (actionPayload: CreateActionInput) => {
      await addAction({
        variables: {
          prototypeFeedbackId: id,
          data: {
            ...actionPayload,
            parentMeetingId: actionPayload.parentMeetingId ?? meeting?.id,
          },
        },
      })
    },
    [id, addAction, meeting?.id],
  )

  return (
    <ActionsDashboardFlat
      noHeaders={noHeaders}
      dataSource={dataSource}
      onCreateAction={handleAddAction}
      newActionDefaults={{
        defaultWaitingFor:
          data?.prototypeFeedback != null && actionsOnFeedbackDefaultWaitingForIsAuthor && data.prototypeFeedback.author
            ? data.prototypeFeedback.author.id
            : undefined,
      }}
      skipRegisteringCapabilities={skipRegisteringCapabilities}
    />
  )
}

const useFeedbackActionsDataSource = (feedbackId: string) =>
  useMemo(
    () =>
      createDataSource({
        query: FeedbackActionsDocument,
        extractActions: (data) => data?.prototypeFeedback?.actions,
        matchAction: (action) => action.parent?.__typename === 'PrototypeFeedback' && action.parent.id === feedbackId,
        variables: {
          id: feedbackId,
        },
      }),
    [feedbackId],
  )

import { convertHtmlToTextTitle, sanitizeTitleHtmlContent } from '@mm/tiptap-extension'
import { GetTopicTitleCommandDocument, UpdateTopicTitleCommandDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditTopicTitleCommandValue = {
  title: string
  topicId: string
}

export const editTopicTitleCommand: Command<EditTopicTitleCommandValue> = {
  id: 'editTopicTitleCommand',
  name: 'Edit Title',
  canExecute: async ({ title, topicId }, { apolloClient }) => {
    const query = await apolloClient.query({ query: GetTopicTitleCommandDocument, variables: { id: topicId } })
    return query.data.topic?.title !== title
  },
  useExecute: () => {
    return async ({ title, topicId }, { apolloClient }) => {
      // Get current value from cache or refetch if needed
      const query = await apolloClient.query({ query: GetTopicTitleCommandDocument, variables: { id: topicId } })

      await apolloClient.mutate({
        mutation: UpdateTopicTitleCommandDocument,
        variables: {
          id: topicId,
          data: {
            title,
          },
        },
        optimisticResponse: {
          updateTopic: {
            __typename: 'Topic',
            id: topicId,
            title: convertHtmlToTextTitle(title),
            titleHtml: sanitizeTitleHtmlContent(title),
          },
        },
      })

      if (!query.data.topic) {
        return
      }

      return {
        args: {
          title: query.data.topic.title,
          topicId,
        },
        execute: async ({ title, topicId }, { apolloClient }) => {
          await apolloClient.mutate({
            mutation: UpdateTopicTitleCommandDocument,
            variables: {
              id: topicId,
              data: {
                title,
              },
            },
            optimisticResponse: {
              updateTopic: {
                __typename: 'Topic',
                id: topicId,
                title: convertHtmlToTextTitle(title),
                titleHtml: sanitizeTitleHtmlContent(title),
              },
            },
          })
        },
      }
    }
  },
}

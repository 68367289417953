import { CloseIcon } from '@mm/company-ui-icons/src'
import { Flex, RecurringDatepicker, Tag, Tooltip } from '@mm/company-ui/src'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import {
  TopicDatePickerDocument,
  TopicRepeatType,
  UpdateTopicRecurringDataDocument,
} from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'

type TopicDatePickerProps = {
  topicId: string
  variant?: 'default' | 'muted' | 'underline' | 'button' | 'tag'
}

export const TopicDatePicker = ({ topicId, ...props }: TopicDatePickerProps) => {
  const { data } = useQuery(TopicDatePickerDocument, {
    variables: {
      id: topicId,
    },
  })

  const [tomorrow] = React.useState(() => DateTime.now().plus({ day: 1 }).toJSDate())

  const [updateTopic] = useMutation(UpdateTopicRecurringDataDocument)
  const handleChange = useCallback(
    async (date: Date | null) => {
      if (date) {
        await updateTopic({
          variables: {
            id: topicId,
            data: {
              recurringDate: DateTime.fromJSDate(date).toMillis(),
            },
          },
          optimisticResponse: {
            updateTopic: {
              id: topicId,
              __typename: 'Topic',
              recurringDate: DateTime.fromJSDate(date).toMillis(),
            },
          },
        })
      }
    },
    [topicId, updateTopic],
  )
  const handleRepeatChange = useCallback(
    async ({ repeat }: { repeat?: TopicRepeatType }) => {
      await updateTopic({
        variables: {
          id: topicId,
          data: {
            repeat: repeat == null ? 'NONE' : repeat,
          },
        },
        optimisticResponse: {
          updateTopic: {
            id: topicId,
            __typename: 'Topic',
            repeat: repeat,
          },
        },
      })
    },
    [topicId, updateTopic],
  )

  const handleRemove = useCallback(async () => {
    await updateTopic({
      variables: {
        id: topicId,
        data: {
          repeat: 'NONE',
        },
      },
      optimisticResponse: {
        updateTopic: {
          id: topicId,
          __typename: 'Topic',
          recurringDate: null,
          repeat: null,
        },
      },
    })
  }, [topicId, updateTopic])

  const topic = data?.topic
  if (!topic) {
    return null
  }

  return (
    <Tag>
      <RecurringDatepicker
        {...props}
        inputStyle={{ background: 'transparent' }}
        disabledDays={{ before: tomorrow }}
        prefix={topic.recurringDate && topic.repeat ? `${getTopicRecurringPrefix(topic.repeat)}: ` : 'Recurring: None'}
        onChange={handleChange}
        repeat={topic.repeat}
        value={topic.recurringDate}
        repeatOptions={topicRepeatOptions}
        onRepeatChange={handleRepeatChange}
        showQuickSelectButtons={false}
      />

      {topic.recurringDate && (
        <Tooltip description="Remove" position="bottom" delay={200}>
          <Flex
            sx={{ cursor: 'pointer' }}
            onClick={async (e) => {
              e.stopPropagation()
              await handleRemove()
            }}
          >
            <CloseIcon width={1.5} height={1.5} color="text-light" />
          </Flex>
        </Tooltip>
      )}
    </Tag>
  )
}

export function getTopicRecurringPrefix(repeat?: TopicRepeatType) {
  return topicRepeatOptions.find((opt) => opt.value === repeat)?.label
}

export const topicRepeatOptions: { label: string; value: TopicRepeatType }[] = [
  {
    label: 'Weekly',
    value: 'WEEKLY' as const,
  },
  {
    label: 'Bi-Weekly',
    value: 'BIWEEKLY' as const,
  },

  {
    label: 'Monthly',
    value: 'MONTHLY' as const,
  },
  {
    label: 'Quarterly',
    value: 'QUARTERLY' as const,
  },
]

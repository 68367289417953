import { Card, Flex, formatDateDisplay, Suspender, Text, useFeatureFlags } from '@mm/company-ui'
import { ArrowRightIcon, LoadingIcon } from '@mm/company-ui-icons/src'
import React, { Suspense } from 'react'
import { FeedbackFeedItemDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { CapabilitiesProvider, RegisterCapability } from '../../capabilities'
import { CREATE_ISSUE } from '../../decisions/capabilities'
import { useMeetingContext } from '../../meetings'
import { useContextualDrawers } from '../../navigation'
import { ADD_TOPIC } from '../../topics/capabilities'
import { FeedbackPrivacySelect } from './FeedbackPrivacySelect'
import { FeedbackToChip } from './FeedbackToChip'

function SmallTextLink({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}) {
  return (
    <Text
      variant="small"
      color="text-light"
      sx={{ cursor: 'pointer', ':hover': { color: 'text', textDecoration: 'underline' } }}
      onClick={onClick}
    >
      {children}
    </Text>
  )
}

function FeedbackItem({ id, cached }: { id: string; cached?: boolean }) {
  const { showDrawer } = useContextualDrawers()
  const { data, loading, error } = useQuery(FeedbackFeedItemDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })
  const { showPrivacyInTable } = useFeatureFlags()

  const handleShowDrawer = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    showDrawer('feedback', id, event)
  }

  const meeting = useMeetingContext()

  const prototypeFeedback = data?.prototypeFeedback

  if (prototypeFeedback == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('feedback not found ' + id)
  }
  const { createdAt, author, to } = prototypeFeedback

  return (
    <CapabilitiesProvider>
      <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meeting?.id} />
      <RegisterCapability capability={CREATE_ISSUE} handler={() => Promise.resolve()} skip={!meeting?.id} />

      <FeedbackCardWithSuspense sx={{ padding: 0, borderRadius: 'medium', border: 'none' }}>
        <Flex column>
          <Flex
            row
            justify="space-between"
            align="center"
            sx={{ padding: 1.5, cursor: 'pointer' }}
            onClick={handleShowDrawer}
          >
            <Flex row align="center">
              {author && <FeedbackToChip {...author} />}
              <div sx={{ marginLeft: -1, marginRight: 0.5 }}>
                <ArrowRightIcon color="text-disabled-medium" />
              </div>
              {to && <FeedbackToChip {...to} />}
            </Flex>

            <Flex
              align="center"
              gap={1}
              justify="flex-end"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <SmallTextLink>{formatDateDisplay(createdAt, 'RELATIVE')}</SmallTextLink>
              {showPrivacyInTable && (
                <FeedbackPrivacySelect feedbackId={id} sx={{ background: 'none', pr: 0 }} cached />
              )}
            </Flex>
          </Flex>
        </Flex>
      </FeedbackCardWithSuspense>
    </CapabilitiesProvider>
  )
}

export const FeedbackFeedItem = FeedbackItem

const FeedbackCardWithSuspense = ({ children, className }: { children?: React.ReactNode; className?: string }) => {
  return (
    <Card sx={{ width: '100%' }} className={className} role="article" variant="flat">
      <FeedbackSuspenseWrap>{children}</FeedbackSuspenseWrap>
    </Card>
  )
}

const FeedbackSuspenseWrap = ({ children }: { children?: React.ReactNode }) => (
  <Suspense
    fallback={
      <Flex align="center" justify="center" sx={{ height: '100%', minHeight: 6 }}>
        <LoadingIcon width={20} height={20} color="text-disabled" />
      </Flex>
    }
  >
    {children}
  </Suspense>
)

import styled from '@emotion/styled'
import { css } from 'theme-ui'

export const VerticalDivider = styled.div(
  css({
    display: 'inline-block',
    borderLeft: '2px solid',
    borderColor: 'border',
    height: 3,
    marginTop: 0.25,
  }),
)

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CheckCircleFilledIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="check-circle-filled" viewBox="0 0 20 20" {...props}>
    <circle cx={10} cy={10} r={10} />
    <path
      d="M6 10.1429L8.43478 13L14 8"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </IconBase>
)

import { openActionStatuses, utils } from '@mm/common'
import { AddWaitingForRowData } from '@mm/company-ui'
import { produce } from 'immer'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { forwardRef, useCallback, useImperativeHandle } from 'react'
import {
  ActionsDashboardRowDataFragment,
  CreateActionOnGoalDocument,
  GoalActionsDocument,
} from '../../../gen/graphql/documents'
import { ActionsUpdatedTopic, markActionAsCreatedHere } from '../../actions'
import { ActionTableAddRow } from '../../actions/components/ActionTableAddRow'
import { capture } from '../../analytics'
import { useMutation, useQuery } from '../../apollo'
import { useEmitEventBusEvent, useOnEventBusEvent } from '../../eventbus'
import { useMeetingContext } from '../../meetings'
import { ActionRow } from './ActionRow'

export type NextActionsTowardsGoalsListRef = { addAction: (data: AddWaitingForRowData) => Promise<void> }

export type NextActionsTowardsGoalsListProps = {
  goalId: string
  withAdd?: boolean
  onCreate?: (action: ActionsDashboardRowDataFragment) => void
  hideIds?: Array<string>
}

export const NextActionsTowardsGoalsList = forwardRef<unknown, NextActionsTowardsGoalsListProps>(
  ({ goalId, withAdd = true, hideIds = [], onCreate }, ref) => {
    const meeting = useMeetingContext()
    // const [isAddActionOpen, setIsAddActionOpen] = useState<boolean | undefined>(true)

    const { data, updateQuery } = useQuery(GoalActionsDocument, {
      variables: {
        goalId,
        status: openActionStatuses,
      },
    })
    const emit = useEmitEventBusEvent(ActionsUpdatedTopic)
    const [createFollowUpAction] = useMutation(CreateActionOnGoalDocument, {
      update: (cache, { data }) => {
        const action = data?.createActionOnGoal
        if (action?.__typename === 'Action') {
          capture('Action Created', {
            parent: 'Action',
            parentMeetingId: meeting?.id,
          })
          markActionAsCreatedHere(cache, action)
          emit({ type: 'upserted', action })
          onCreate?.(action)
        }
      },
    })

    useOnEventBusEvent(ActionsUpdatedTopic, (event) => {
      if (event.type !== 'upserted') return
      const { action } = event

      updateQuery((data, { variables: { goalId: followUpToId } = {} }) =>
        produce(data, (draft) => {
          const followUpActions = draft.goal?.actions
          if (followUpActions == null) return

          _.remove(followUpActions.edges, ({ node }) => node.id === action.id)

          if (action.parent?.id === followUpToId) {
            const targetIdx = followUpActions.edges.findIndex(({ node }) => node.createdAt > action.createdAt)

            if (targetIdx === -1) {
              followUpActions.edges.push({ node: action })
            } else {
              followUpActions.edges.splice(targetIdx, 0, { node: action })
            }
          }
        }),
      )
    })

    useImperativeHandle<unknown, NextActionsTowardsGoalsListRef>(ref, () => ({
      async addAction(data) {
        await handleOnAdd(data)
      },
    }))

    const handleOnAdd = useCallback(
      async (data: AddWaitingForRowData) => {
        const actionPayload = {
          title: data.title,
          dateRescheduled: false,
          description: data.description,
          dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
          assignee: data.assignee,
          waitingFor: data.waitingFor,
        }

        await createFollowUpAction({
          variables: {
            goalId,
            data: actionPayload,
          },
        })
      },
      [goalId, createFollowUpAction],
    )

    const goal = data?.goal
    const actions =
      goal?.actions?.edges
        .map(({ node }) => node)
        .filter((action) => {
          if (hideIds.includes(action.id)) return null
          return action
        })
        .filter(utils.isNonNil) ?? []

    return (
      <>
        {withAdd ? (
          <ActionTableAddRow
            onAdd={handleOnAdd}
            // addRowForcedOpen={isAddActionOpen}
            defaultAssignee={goal?.assignee?.id}
            // onChangeDirty={(isDirty) => {
            //   if (isDirty) setIsAddActionOpen(undefined)
            // }}
            // onCancel={(src) => {
            //   if (src === 'button' || src === 'esc-key') {
            //     setIsAddActionOpen(undefined)
            //   }
            // }}
            sx={{ px: 0, minHeight: 'auto' }}
          />
        ) : null}

        {actions.length ? (
          <div sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium', marginTop: 1 }}>
            {actions.map((action, i) => {
              return (
                <ActionRow
                  key={action.id}
                  action={action}
                  withStatus={false}
                  hasBottomBorder={i !== actions.length - 1}
                  hasContext={false}
                />
              )
            })}
          </div>
        ) : null}
      </>
    )
  },
)

NextActionsTowardsGoalsList.displayName = 'NextActionsTowardsGoalsList'

import React, { useCallback, useMemo } from 'react'
import { CreateActionInput, UserActionsDocument } from '../../../gen/graphql/documents'
import { useCommand } from '../../commands'
import { useActiveCompanyId } from '../../companies'
import { createActionCommand } from '../commands/createAction'
import { ActionOnboarding } from './ActionOnboarding'
import { ActionsDashboardNewOrder, createDataSource } from './ActionsDashboard'

export type UserActionsProps = {
  selectedUserId: string
  tab?: 'actions' | 'waiting-from-others'
  showOnboarding?: boolean
  showDoneActions?: boolean
  showAddRow?: boolean
  dashboardComponent?: React.ComponentType<any>
}

export const UserActions = ({
  selectedUserId,
  tab = 'actions',
  showOnboarding = true,
  showDoneActions,
  showAddRow,
  dashboardComponent: Dashboard = ActionsDashboardNewOrder,
}: UserActionsProps) => {
  const { activeCompanyId } = useActiveCompanyId()

  const createAction = useCommand(createActionCommand)
  const dataSource = useUserActionsDataSource(
    {
      actions: { userId: selectedUserId, companyId: activeCompanyId },
      'waiting-from-others': { userId: selectedUserId, waitingFor: selectedUserId, companyId: activeCompanyId },
    }[tab],
  )

  const handleCreateAction = useCallback(
    (actionPayload: CreateActionInput) =>
      createAction.execute({
        companyId: activeCompanyId,
        data: actionPayload,
      }),
    [createAction, activeCompanyId],
  )

  return (
    <>
      {showOnboarding ? <ActionOnboarding /> : null}
      <Dashboard
        dataSource={dataSource}
        onCreateAction={handleCreateAction}
        showDoneActions={showDoneActions}
        showAddRow={showAddRow}
        tableStyles={{ padding: 0 }}
      />
    </>
  )
}

type Options = {
  userId: string
  companyId: string
  waitingFor?: string
}
export const useUserActionsDataSource = ({ userId, waitingFor, companyId }: Options) =>
  useMemo(
    () =>
      createDataSource({
        query: UserActionsDocument,
        extractActions: (data) => data?.user?.actions,
        matchAction: (action) =>
          (waitingFor != null ? action.waitingFor.id === waitingFor : action.assignee.id === userId) &&
          action.company.id === companyId,
        variables: {
          userId,
          waitingFor,
          companyId,
        },
      }),
    [userId, waitingFor, companyId],
  )

import { AddWaitingForRow, toast, useFeatureFlags } from '@mm/company-ui'
import { DateTime } from 'luxon'
import React from 'react'
import { useCapabilities } from '../../capabilities'
import { UserSelectWithCompanyMembers, UserSelectWithMeetingParticipants } from '../../components/UserSelectWithQuery'
import { useMeetingContext } from '../../meetings'
import { useActiveUserRequired } from '../../users'
import { ADD_ACTION } from '../capabilities'
import { actionRepeatOptions } from './ActionDatepicker'

export type QuickAddActionProps = {
  defaultTitle?: string
  defaultDescription?: string
  defaultWaitingFor?: string | null
  onDone?: () => void
}

export const QuickAddAction = ({
  defaultTitle,
  defaultDescription,
  defaultWaitingFor,
  onDone,
}: QuickAddActionProps) => {
  const { execute } = useCapabilities()
  const meeting = useMeetingContext()
  const { recurringAction, predictDueDateFromActionTitle } = useFeatureFlags()
  const me = useActiveUserRequired()
  const defaultSelectedUser = me.id

  return (
    <AddWaitingForRow
      useDueDateFromTitle={predictDueDateFromActionTitle}
      repeatOptions={actionRepeatOptions}
      condensed
      hasDueAt
      useRecurringDatepicker={recurringAction}
      defaultDueAt={DateTime.now().plus({ week: 1 }).toJSDate()}
      autofocus
      refocusTitleOnSubmit={false}
      defaultTitle={defaultTitle}
      defaultDescription={defaultDescription}
      titlePlaceholder="Add action..."
      renderUserPicker={(props) =>
        meeting ? (
          <UserSelectWithMeetingParticipants meetingId={meeting.id} {...props} />
        ) : (
          <UserSelectWithCompanyMembers {...props} />
        )
      }
      hasAssignee
      defaultAssignee={defaultSelectedUser}
      hasWaitingFor
      defaultWaitingFor={defaultWaitingFor || defaultSelectedUser}
      sx={{ width: '500px' }}
      maxDescriptionLines={3}
      hasCancel
      onCancel={onDone}
      onAdd={async (data) => {
        try {
          await execute(ADD_ACTION, data)
          toast('Action Created')
        } catch (error) {
          console.error(error) // eslint-disable-line no-console
          toast('Failed to create action', { type: 'error' })
        }
      }}
      onComplete={onDone}
    />
  )
}

import { produce } from 'immer'
import { useCallback } from 'react'
import { GoodThingDataFragmentDoc, UseAddGoodThingEmojiReactionDocument } from '../../../gen/graphql/documents'
import { useApolloClient, useMutation } from '../../apollo'
import { useActiveUser } from '../../users'

export const useAddGoodThingEmojiReaction = (goodThingId: string) => {
  const client = useApolloClient()
  const [addEmojiReaction] = useMutation(UseAddGoodThingEmojiReactionDocument)
  const activeUser = useActiveUser()

  return useCallback(
    (emoji: string) => {
      const data = client.readFragment(
        {
          id: client.cache.identify({ __typename: 'GoodThing', id: goodThingId }),
          fragment: GoodThingDataFragmentDoc,
          fragmentName: 'goodThingData',
        },
        true,
      )

      return addEmojiReaction({
        variables: {
          id: goodThingId,
          emoji,
        },
        optimisticResponse:
          data && activeUser
            ? {
                addGoodThingEmojiReaction: produce(data, (draft) => {
                  if (
                    draft.emojiReactions?.every(
                      (reaction) => reaction.user.id !== activeUser.id || reaction.emoji !== emoji,
                    )
                  ) {
                    draft.emojiReactions.push({
                      user: activeUser,
                      emoji,
                    })
                  }
                }),
              }
            : undefined,
      })
    },
    [client, goodThingId, addEmojiReaction, activeUser],
  )
}

import { Editor } from '@tiptap/core'
import React from 'react'
import { Button } from '../../Button'
import { Select } from '../../Select'

export type Options = Array<{
  label: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}>

type ButtonToolbarItem = {
  type: 'button'
  label: string
  icon?: React.ReactElement
  onClick?: () => void
  textHidden?: boolean
  isActive?: boolean
  disabled?: boolean
  hidden?: boolean
}

type SelectToolbarItem = {
  type: 'select'
  label: string
  options: Array<{
    value: string
    label: string
    onSelect?: () => void
    disabled?: boolean
  }>
  disabled?: boolean
  hidden?: boolean
}

export type ToolbarAction = (editor: Editor) => SelectToolbarItem | ButtonToolbarItem

export type ToolbarActionItemProps = SelectToolbarItem | ButtonToolbarItem

export const ToolbarActionItem = (props: ToolbarActionItemProps) => {
  if (props.hidden) {
    return null
  }

  if (props.type === 'button') {
    return (
      <Button
        startIcon={props.icon}
        size="small"
        variant={props.isActive ? 'default' : 'muted'}
        textHidden={props.textHidden}
        disabled={props.disabled}
        onClick={(e) => {
          e.preventDefault()
          props.onClick?.()
        }}
      >
        {props.label}
      </Button>
    )
  }

  return (
    <Select
      sx={{ minWidth: '120px' }}
      variant="muted"
      isDisabled={props.disabled}
      value={{ value: '', label: props.label }}
      options={props.options}
      onChange={(change) => {
        const { value } = change as { value: string } // TODO: Properly type <Select /> to pass option type down to React Select
        const selected = props.options.find((option) => option.value === value)
        selected?.onSelect?.()
      }}
    />
  )
}

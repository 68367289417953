import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const DuplicateIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="duplicate" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1C4 0.447715 4.44772 0 5 0H18C18.5523 0 19 0.447715 19 1V17C19 17.5523 18.5523 18 18 18C17.4477 18 17 17.5523 17 17V2H5C4.44772 2 4 1.55228 4 1ZM1 5C1 4.44772 1.44772 4 2 4H14C14.5523 4 15 4.44772 15 5V19C15 19.5523 14.5523 20 14 20H2C1.44772 20 1 19.5523 1 19V5ZM3 6V18H13V6H3ZM7.99082 9C8.48788 9 8.89082 9.40294 8.89082 9.9V11.0909H10.0818C10.5789 11.0909 10.9818 11.4939 10.9818 11.9909C10.9818 12.488 10.5789 12.8909 10.0818 12.8909H8.89082V14.0818C8.89082 14.5789 8.48788 14.9818 7.99082 14.9818C7.49376 14.9818 7.09082 14.5789 7.09082 14.0818V12.8909H5.9C5.40294 12.8909 5 12.488 5 11.9909C5 11.4939 5.40294 11.0909 5.9 11.0909H7.09082V9.9C7.09082 9.40294 7.49376 9 7.99082 9Z"
    />
  </IconBase>
)

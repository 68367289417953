import { IconComponent } from '@mm/company-ui-icons'
import React from 'react'
import { FlyoutItem } from '../Flyout'
import {
  colorByStatus,
  iconByStatus,
  actionLabelByStatus,
  topicLabelByStatus,
  Shape,
  StatusCheckboxValue,
} from './helpers'

export type StatusMenuProps<T extends StatusCheckboxValue> = {
  type: 'action' | 'topic' | 'feedback'
  shape?: Shape
  options: readonly [T, ...T[]]
  onChange?: (state: T) => void
  renderMenuFooter?: () => React.ReactElement
}

// Icon shadow filter
const filter = 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))'

export const StatusMenu = <T extends StatusCheckboxValue>({
  type,
  shape = 'circle',
  options,
  onChange,
  renderMenuFooter,
}: StatusMenuProps<T>) => {
  return (
    <div data-testid="StatusCheckbox-dropdown-content">
      {options.map((option) => {
        const OptionIcon: IconComponent = iconByStatus[shape][option]
        const label = type === 'action' ? actionLabelByStatus[option] : topicLabelByStatus[option]
        const color = colorByStatus[option]

        return (
          <FlyoutItem
            key={option}
            startIcon={<OptionIcon color={color} sx={{ filter }} />}
            onClick={() => {
              onChange?.(option)
            }}
          >
            {label}
          </FlyoutItem>
        )
      })}
      {renderMenuFooter?.()}
    </div>
  )
}

import { ActionStatus, actionStatusMap } from '@mm/common/src'
import {
  CircleCheckmarkIcon,
  CloseCircleIcon,
  ExclamationCircleIcon,
  RadioCircleEmptyIcon,
} from '@mm/company-ui-icons/src'
import { Button, CheckboxButton, CheckboxGroup, Flex, Popover, PopupWrapper, Tag } from '@mm/company-ui/src'
import React from 'react'

export type StatusFilterProps = {
  value?: ActionStatus[]
  onChange?: (status: ActionStatus[]) => void
  className?: string
}

export function StatusFilter({ value, onChange, className }: StatusFilterProps) {
  return (
    <Popover
      render={() => (
        <PopupWrapper>
          <CheckboxGroup onChange={onChange} value={value?.map((status) => status)} aria-label="Status Filter">
            <Flex column gap={2} shrink={false}>
              <Flex row gap={1} align="center">
                <CheckboxButton value={actionStatusMap.ACTIVE} aria-label="Todo" />
                <Tag value="ACTIVE" variant="default">
                  <RadioCircleEmptyIcon />
                  To do
                </Tag>
              </Flex>
              <Flex row gap={1} align="center">
                <CheckboxButton value={actionStatusMap.DONE} aria-label="Done" />
                <Tag value="DONE" variant="success">
                  <CircleCheckmarkIcon />
                  Done
                </Tag>
              </Flex>
              <Flex row gap={1} align="center">
                <CheckboxButton value={actionStatusMap.ON_HOLD} aria-label="Blocked" />
                <Tag value="ON_HOLD" variant="alert">
                  <ExclamationCircleIcon />
                  Blocked
                </Tag>
              </Flex>
              <Flex row gap={1} align="center">
                <CheckboxButton value={actionStatusMap.CANCELLED} aria-label="Canceled" />
                <Tag value="CANCELLED" variant="default">
                  <CloseCircleIcon />
                  Canceled
                </Tag>
              </Flex>
            </Flex>
          </CheckboxGroup>
        </PopupWrapper>
      )}
    >
      {({ getReferenceProps }) => (
        <Button
          className={className}
          variant="muted"
          size="small"
          startIcon={value?.length ? <IconDisplay values={value} /> : undefined}
          {...getReferenceProps()}
        >
          {value?.length ? '' : 'Select'}
        </Button>
      )}
    </Popover>
  )
}

const IconDisplay: React.FC<{ values: ActionStatus[] }> = ({ values }) => {
  return (
    <Flex gap={0.25}>
      {values.includes(actionStatusMap.ACTIVE) && <RadioCircleEmptyIcon />}
      {values.includes(actionStatusMap.DONE) && <CircleCheckmarkIcon />}
      {values.includes(actionStatusMap.ON_HOLD) && <ExclamationCircleIcon />}
      {values.includes(actionStatusMap.CANCELLED) && <CloseCircleIcon />}
    </Flex>
  )
}

import React from 'react'
import { EmojiPicker } from '../../components/emoji/EmojiPicker'
import { useAddCommentEmojiReaction } from '../hooks/useAddCommentEmojiReaction'

export type CommentEmojiPickerProps = {
  commentId: string

  onEmojiSelected?: () => void
}

export const CommentEmojiPicker = ({ commentId, onEmojiSelected }: CommentEmojiPickerProps) => {
  const addCommentEmoji = useAddCommentEmojiReaction(commentId)

  return (
    <EmojiPicker
      onSelect={(emoji) => {
        void addCommentEmoji(emoji)
        onEmojiSelected?.()
      }}
    />
  )
}

import { Suspender, useFeatureFlags } from '@mm/company-ui'
import { LinkIcon, RequestCommentIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { ActionMetadataTopicsDocument, RemoveActionTopicContextDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { PanelMetadataItemChips } from '../../components'
import { PanelMetadataLinkChips } from '../../components/PanelMetadataLinkChips'

export const ActionMetadataTopics = ({ actionId, isLink }: { actionId: string; isLink?: boolean }) => {
  const { data, loading, error, fetchMore } = useQuery(ActionMetadataTopicsDocument, {
    variables: {
      id: actionId,
    },
  })

  const topics = data?.action?.topics
  const { updateActionContext } = useFeatureFlags()
  const [removeActionContext] = useMutation(RemoveActionTopicContextDocument)

  const handleRemove = useCallback(
    async (id: string) => {
      await removeActionContext({
        variables: {
          id: actionId,
          contextRef: id,
        },
        optimisticResponse: {
          removeActionContext: {
            id: actionId,
            __typename: 'Action' as const,
            topics: {
              __typename: 'TopicsConnection' as const,
              edges: topics?.edges.filter(({ node }) => node.id !== id) || [],
              pageInfo: {
                hasNextPage: topics?.pageInfo.hasNextPage ?? false,
                endCursor: topics?.pageInfo.endCursor ?? '',
                __typename: topics?.pageInfo.__typename,
              },
            },
          },
        },
      })
    },
    [actionId, topics, removeActionContext],
  )

  if (error) {
    throw error
  }

  if (loading) {
    return <Suspender />
  }

  if (isLink) {
    return topics?.edges.length ? (
      <PanelMetadataLinkChips
        icon={<RequestCommentIcon color="text-light" />}
        chips={topics.edges.map(({ node }) => ({
          id: node.id,
          label: node.title,
          href: { pathname: '/topics/[id]', query: { id: node.id } },
          type: 'Topic',
        }))}
        hasNextPage={topics.pageInfo.hasNextPage}
        onLoadMore={() => fetchMore({ variables: { after: topics.pageInfo.endCursor } })}
        onRemove={handleRemove}
      />
    ) : null
  }

  return topics?.edges.length ? (
    <PanelMetadataItemChips
      icon={<LinkIcon />}
      title="Topics"
      chips={topics.edges.map(({ node }) => ({
        id: node.id,
        label: node.title,
        href: { pathname: '/topics/[id]', query: { id: node.id } },
        __typename: node.__typename,
      }))}
      hasNextPage={topics.pageInfo.hasNextPage}
      onLoadMore={() => fetchMore({ variables: { after: topics.pageInfo.endCursor } })}
      isRemovable={updateActionContext}
      onRemove={handleRemove}
    />
  ) : null
}

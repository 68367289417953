import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const DeleteIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="delete" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.82715 1.00001C5.82715 0.50295 6.23009 0.100006 6.72715 0.100006H13.2726C13.7697 0.100006 14.1726 0.50295 14.1726 1.00001V3.37273H19.0001C19.4972 3.37273 19.9001 3.77568 19.9001 4.27273C19.9001 4.76979 19.4972 5.17273 19.0001 5.17273H1.0001C0.503041 5.17273 0.100098 4.76979 0.100098 4.27273C0.100098 3.77568 0.503041 3.37273 1.0001 3.37273H5.82715V1.00001ZM12.3726 1.90001V3.37273H7.62715V1.90001H12.3726ZM3.45469 6.64546C3.95174 6.64546 4.35469 7.04841 4.35469 7.54546V17.3636C4.35469 17.5589 4.43227 17.7462 4.57036 17.8843C4.70846 18.0224 4.89576 18.1 5.09105 18.1H14.9092C15.1045 18.1 15.2918 18.0224 15.4299 17.8843C15.568 17.7462 15.6456 17.5589 15.6456 17.3636V7.54546C15.6456 7.04841 16.0485 6.64546 16.5456 6.64546C17.0427 6.64546 17.4456 7.04841 17.4456 7.54546V17.3636C17.4456 18.0363 17.1784 18.6815 16.7027 19.1571C16.2271 19.6328 15.5819 19.9 14.9092 19.9H5.09105C4.41837 19.9 3.77323 19.6328 3.29757 19.1571C2.82191 18.6815 2.55469 18.0363 2.55469 17.3636V7.54546C2.55469 7.04841 2.95763 6.64546 3.45469 6.64546ZM10.0001 9.10001C10.4972 9.10001 10.9001 9.50295 10.9001 10V14.9091C10.9001 15.4062 10.4972 15.8091 10.0001 15.8091C9.50304 15.8091 9.1001 15.4062 9.1001 14.9091V10C9.1001 9.50295 9.50304 9.10001 10.0001 9.10001ZM7.62715 10C7.62715 9.50295 7.2242 9.10001 6.72715 9.10001C6.23009 9.10001 5.82715 9.50295 5.82715 10V14.9091C5.82715 15.4062 6.23009 15.8091 6.72715 15.8091C7.2242 15.8091 7.62715 15.4062 7.62715 14.9091V10ZM13.2726 9.10001C13.7696 9.10001 14.1726 9.50295 14.1726 10V14.9091C14.1726 15.4062 13.7696 15.8091 13.2726 15.8091C12.7755 15.8091 12.3726 15.4062 12.3726 14.9091V10C12.3726 9.50295 12.7755 9.10001 13.2726 9.10001Z"
    />
  </IconBase>
)

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const StopwatchTimerIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="stopwatch-timer" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49984 0C7.0396 0 6.6665 0.373096 6.6665 0.833333C6.6665 1.29357 7.0396 1.66667 7.49984 1.66667H12.4998C12.9601 1.66667 13.3332 1.29357 13.3332 0.833333C13.3332 0.373096 12.9601 0 12.4998 0H7.49984ZM9.99984 4.99999C6.31794 4.99999 3.33317 7.98476 3.33317 11.6667C3.33317 15.3486 6.31794 18.3333 9.99984 18.3333C13.6817 18.3333 16.6665 15.3486 16.6665 11.6667C16.6665 7.98476 13.6817 4.99999 9.99984 4.99999ZM1.6665 11.6667C1.6665 7.06429 5.39746 3.33333 9.99984 3.33333C14.6022 3.33333 18.3332 7.06429 18.3332 11.6667C18.3332 16.269 14.6022 20 9.99984 20C5.39746 20 1.6665 16.269 1.6665 11.6667ZM9.99984 7.5C10.4601 7.5 10.8332 7.8731 10.8332 8.33333V10.8333H13.3332C13.7934 10.8333 14.1665 11.2064 14.1665 11.6667C14.1665 12.1269 13.7934 12.5 13.3332 12.5H9.99984C9.5396 12.5 9.1665 12.1269 9.1665 11.6667V8.33333C9.1665 7.8731 9.5396 7.5 9.99984 7.5Z"
    />
  </IconBase>
)

import { Button } from '@mm/company-ui'
import { ContractMinimizeIcon, ExpandMaximizeIcon } from '@mm/company-ui-icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { Route } from 'nextjs-routes'
import React from 'react'
import { isExactRoute } from '../../utils'

export type ExpandCollapseToggleProps = {
  id: string
  resourceType: 'topics' | 'actions' | 'profile' | 'goals' | 'goodThings' | 'decisions' | 'feedback'
}

export const ExpandCollapseToggle = ({ resourceType, id }: ExpandCollapseToggleProps) => {
  const router = useRouter()
  const isFullScreen = isExactRoute(router, `/${resourceType}/[id]`)
  const collapseTo = router.query['collapseTo'] as unknown as Route

  const resourceUrl = (
    {
      topics: '/topics/[id]',
      actions: '/actions/[id]',
      profile: '/profile/[id]',
      goals: '/goals/[id]',
      goodThings: '/goodThings/[id]',
      decisions: '/decisions/[id]',
      feedback: '/feedback/[id]',
    } as const
  )[resourceType]

  if (!isFullScreen) {
    return (
      <Link href={{ pathname: resourceUrl, query: { id, ...(router.asPath ? { collapseTo: router.asPath } : {}) } }}>
        <Button
          data-testid="expand-full-screen"
          variant="muted"
          startIcon={<ExpandMaximizeIcon />}
          textHidden
          size="small"
        >
          Open in full screen
        </Button>
      </Link>
    )
  } else if (collapseTo && typeof collapseTo === 'string') {
    return (
      <Link href={collapseTo}>
        <Button
          data-testid="collapse-full-screen"
          variant="muted"
          startIcon={<ContractMinimizeIcon />}
          textHidden
          size="small"
        >
          Collapse
        </Button>
      </Link>
    )
  }

  return null
}

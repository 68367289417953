import { FlyoutDivider, FlyoutItem, StatusCheckbox, Suspender } from '@mm/company-ui'
import {
  CheckCircleFilledIcon,
  CheckCircleIcon,
  CloseIcon,
  CrossCircleFilledIcon,
  ExclamationCircleIcon,
} from '@mm/company-ui-icons'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { ActionStatus, ActionStatusCheckboxDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useEditAction } from '../../DoneNotDone'
import { useFeatureFlags } from '../../featureFlags'
import { getCurrentStatus, statusCheckboxToActionStatus } from '../helpers'

export type ActionStatusCheckboxProps = {
  id: string
  cached?: boolean
  withLabel?: boolean
  className?: string
  onStatusSubmitted?: (status: ActionStatus) => void
}

const actionStatuses = ['active', 'resolved', 'on-hold', 'canceled'] as const
const filter = 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))'

export const ActionStatusCheckbox = ({
  id,
  cached,
  withLabel,
  className,
  onStatusSubmitted,
}: ActionStatusCheckboxProps) => {
  const { notDoneRowInActionStatusMenu, reorderedActionStatusMenu, hideActionStatusToggle } = useFeatureFlags()
  const editAction = useEditAction(id)
  const { data, loading } = useQuery(ActionStatusCheckboxDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: { id },
  })

  const handleChange = useCallback(
    (checkboxStatus: (typeof actionStatuses)[number]) => {
      const status = statusCheckboxToActionStatus(checkboxStatus)
      void editAction?.({ status })
      onStatusSubmitted?.(status)
    },
    [editAction, onStatusSubmitted],
  )

  if (data?.action == null) {
    if (loading) {
      return <Suspender />
    }
    return null
  }

  return reorderedActionStatusMenu ? (
    <StatusCheckbox
      type="action"
      options={actionStatuses}
      value={getCurrentStatus(data.action)}
      withLabel={withLabel}
      shape="circle"
      withDropdown={!hideActionStatusToggle}
      onChange={handleChange}
      className={className}
      renderMenu={({ onChange }) => (
        <div data-testid="StatusCheckbox-dropdown-content">
          <FlyoutItem
            startIcon={<CheckCircleIcon color="background-strong" sx={{ filter }} />}
            onClick={() => {
              onChange('active')
            }}
          >
            To do
          </FlyoutItem>
          <FlyoutItem
            startIcon={<CheckCircleFilledIcon color="system-background-success" sx={{ filter }} />}
            onClick={() => {
              onChange('resolved')
            }}
          >
            Done
          </FlyoutItem>
          <FlyoutItem
            startIcon={<CloseIcon />}
            onClick={() => {
              // To trigger not-done flow, set due date 2 weeks in advance.
              void editAction?.({
                dueAt: DateTime.fromMillis(data?.action?.dueAt || new Date().getTime())
                  .plus({ days: 14 })
                  .toMillis(),
                forceNotDoneFlow: true,
              })
            }}
          >
            Not Done
          </FlyoutItem>
          <FlyoutDivider />
          <FlyoutItem
            startIcon={<ExclamationCircleIcon color="system-background-warning" sx={{ filter }} />}
            onClick={() => {
              onChange('on-hold')
            }}
          >
            Blocked
          </FlyoutItem>
          <FlyoutItem
            startIcon={<CrossCircleFilledIcon color="text-medium" sx={{ filter }} />}
            onClick={() => {
              onChange('canceled')
            }}
          >
            Canceled
          </FlyoutItem>
        </div>
      )}
    />
  ) : (
    <StatusCheckbox
      type="action"
      options={actionStatuses}
      value={getCurrentStatus(data.action)}
      withLabel={withLabel}
      shape="circle"
      onChange={handleChange}
      className={className}
      renderMenuFooter={
        notDoneRowInActionStatusMenu
          ? () => (
              <>
                <FlyoutDivider />
                <FlyoutItem
                  startIcon={<CloseIcon />}
                  onClick={() => {
                    // To trigger not-done flow, set due date 2 weeks in advance.
                    void editAction?.({
                      dueAt: DateTime.fromMillis(data?.action?.dueAt || new Date().getTime())
                        .plus({ days: 14 })
                        .toMillis(),
                      forceNotDoneFlow: true,
                    })
                  }}
                >
                  Not Done
                </FlyoutItem>
              </>
            )
          : undefined
      }
    />
  )
}

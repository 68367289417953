import { useTheme } from '@emotion/react'
import { AnimateOpen, Button, Flex, formatDateDisplay, SuspenseLoader, Text } from '@mm/company-ui'
import { ChevronIcon } from '@mm/company-ui-icons'
import { AnimatePresence } from 'framer-motion'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo, useState } from 'react'
import { FeedbackItemDataFragment } from '../../../gen/graphql/documents'
import { useOnEventBusEvent } from '../../eventbus'
import { useContextualDrawers, useRegisterDrawerNavigationProvider } from '../../navigation'
import { FeedbacksUpdatedTopic } from '../subscriptions/GlobalFeedbacksSubscription'
import { FeedbackToControlProps } from './AddFeedbackModal'
import { FeedbackFeedItem } from './FeedbackFeedItem'
import { FeedbackToChip } from './FeedbackToChip'

type GroupTitle = string | FeedbackToControlProps

type FeedProps = {
  sources: Array<{
    groups: Array<{ id: string; title: GroupTitle; items: FeedbackItemDataFragment[]; addAfterSection?: boolean }>
    pageInfo: unknown
  }>
  renderFeedItem: (item: FeedbackItemDataFragment) => React.ReactElement
  renderEmptySection?: () => React.ReactElement
  renderEmptyGroup?: () => React.ReactElement
  renderAddItem?: (title: GroupTitle) => React.ReactElement
}

type FeedItemProps = {
  item: { id: string; title: GroupTitle; items: FeedbackItemDataFragment[]; addAfterSection?: boolean }
  renderFeedItem: (item: FeedbackItemDataFragment) => React.ReactElement
  renderEmptyGroup?: () => React.ReactElement
  renderAddItem?: (title: GroupTitle) => React.ReactElement
}

function FeedItem(props: FeedItemProps) {
  const theme = useTheme()
  const [isOpen, setIsOpen] = React.useState(true)
  const { id, title, items, addAfterSection } = props.item

  const { showDrawer } = useContextualDrawers()
  useRegisterDrawerNavigationProvider(
    'feedback',
    useCallback(
      (id) => {
        const itemIndex = items.findIndex((item) => item.id === id)
        if (itemIndex === -1) return null

        const prevItem = items[itemIndex - 1]
        const nextItem = items[itemIndex + 1]

        return {
          itemIndex,
          hasMoreItems: false,
          totalItemsCount: items.length,
          displayNext:
            nextItem &&
            (() => {
              showDrawer('feedback', nextItem.id)
            }),
          displayPrevious:
            prevItem &&
            (() => {
              showDrawer('feedback', prevItem.id)
            }),
        }
      },
      [items, showDrawer],
    ),
  )

  if (!items.length) return null
  return (
    <Flex column key={id} gap={1} sx={{ marginBottom: 2, paddingLeft: 3 }}>
      <Flex
        row
        justify="space-between"
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <Flex row gap={1} align="center">
          <Text variant="small" uppercase color="text-light">
            {typeof title === 'string' ? title : <FeedbackToChip {...title} />}
          </Text>
          <Button
            size="small"
            variant="muted"
            textHidden
            startIcon={
              <ChevronIcon
                sx={{
                  transition: theme.transition.default,
                  transform: `rotate(${isOpen ? 0 : -180}deg)`,
                }}
              />
            }
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            Toggle Closed
          </Button>
        </Flex>
        {addAfterSection && props.renderAddItem?.(title)}
      </Flex>

      <AnimatePresence initial={false}>
        {isOpen && (
          <AnimateOpen>
            <SuspenseLoader>
              <Flex
                column
                gap={0}
                align="flex-start"
                sx={{
                  border: '1px solid',
                  borderColor: 'border',
                  borderRadius: 'medium',
                  '&>div+div': {
                    borderTop: '1px solid',
                    borderTopColor: 'border',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  },
                }}
              >
                {items.map((item) => props.renderFeedItem(item))}
              </Flex>
            </SuspenseLoader>
          </AnimateOpen>
        )}
      </AnimatePresence>
    </Flex>
  )
}

export function Feed({ sources, renderFeedItem, renderEmptySection, renderEmptyGroup, renderAddItem }: FeedProps) {
  if (!sources.length && renderEmptySection) return renderEmptySection?.()
  return (
    <Flex column sx={{ marginX: 'auto', paddingX: 2 }}>
      {sources.map(
        (source) =>
          source.groups.length
            ? source.groups.map((item) => (
                <FeedItem
                  key={item.id}
                  item={item}
                  renderFeedItem={renderFeedItem}
                  renderEmptyGroup={renderEmptyGroup}
                  renderAddItem={renderAddItem}
                />
              ))
            : renderEmptyGroup?.(),
        // TODO: Display pagination load more
      )}
    </Flex>
  )
}

export function FeedbackFeed({
  sources = [],
  renderEmptySection,
  renderEmptyGroup,
  onForceRefetch,
}: {
  sources: Array<{
    data: { edges: Array<{ node: FeedbackItemDataFragment }>; pageInfo: Record<string, unknown> }
    groupBy?: 'week' | 'to' | 'month'
    sections?: Array<FeedbackToControlProps>
    addAfterSection?: boolean
  }>
  renderEmptySection?: () => React.ReactElement
  renderEmptyGroup?: () => React.ReactElement
  onForceRefetch: () => void
}) {
  useOnEventBusEvent(FeedbacksUpdatedTopic, (event) => {
    if (event.type === 'upserted') {
      onForceRefetch()
    }
  })

  const [thisWeek] = useState(() => DateTime.now().startOf('week'))
  const [thisMonth] = useState(() => DateTime.now().startOf('month'))
  const sourcesData = useMemo(() => {
    const isEmptyData = sources.every((source) => !source.data.edges.length)
    if (isEmptyData) return []
    return sources.map((source) => {
      const groups =
        source.groupBy === 'week'
          ? _(source.data.edges)
              .groupBy(({ node: item }: { node: FeedbackItemDataFragment }) => {
                const week = DateTime.fromMillis(item.createdAt).startOf('week')
                return (week < thisWeek ? week : thisWeek).toISODate()
              })
              .map((items, weekISO) => {
                const week = DateTime.fromISO(weekISO)

                return {
                  id: weekISO,
                  title: week.hasSame(thisWeek, 'week') ? 'This week' : `Week of ${formatDateDisplay(week)}`,
                  items: items.map(({ node }) => node),
                  addAfterSection: source.addAfterSection,
                }
              })
              .value()
          : source.groupBy === 'month'
          ? _(source.data.edges)
              .groupBy(({ node: item }: { node: FeedbackItemDataFragment }) => {
                const month = DateTime.fromMillis(item.createdAt).startOf('month')
                return (month < thisMonth ? month : thisMonth).toISODate()
              })
              .map((items, weekISO) => {
                const month = DateTime.fromISO(weekISO)

                return {
                  id: weekISO,
                  title: month.hasSame(thisMonth, 'month')
                    ? 'This month'
                    : `Month of ${formatDateDisplay(month, 'SHORT_MONTH_NO_DATE')}`,
                  items: items.map(({ node }) => node),
                  addAfterSection: source.addAfterSection,
                }
              })
              .value()
          : source.groupBy === 'to'
          ? _(source.sections)
              .map((section) => {
                return {
                  id: section.id,
                  title: section,
                  items: source.data.edges.filter(({ node }) => node.to?.id === section.id).map(({ node }) => node),
                  addAfterSection: source.addAfterSection,
                }
              })
              .value()
          : []

      return {
        groups,
        pageInfo: source.data.pageInfo,
      }
    })
  }, [sources, thisWeek, thisMonth])

  return (
    <Feed
      sources={sourcesData}
      renderEmptySection={renderEmptySection}
      renderEmptyGroup={renderEmptyGroup}
      renderFeedItem={(item) => <FeedbackFeedItem cached={true} key={item.id} {...item} />}
    />
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const RequestCommentIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="close" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.59999 0.299999C3.24518 0.299999 0.299988 2.58911 0.299988 5.66667C0.299988 6.70709 0.647978 7.66984 1.23332 8.47982V11.7333C1.23332 11.9985 1.38313 12.2409 1.62029 12.3594C1.85745 12.478 2.14125 12.4524 2.35336 12.2933L4.45972 10.7133C5.12848 10.9198 5.84879 11.0333 6.59999 11.0333C9.9548 11.0333 12.9 8.74422 12.9 5.66667C12.9 2.58911 9.9548 0.299999 6.59999 0.299999ZM1.69999 5.66667C1.69999 3.59036 3.76866 1.7 6.59999 1.7C9.43131 1.7 11.5 3.59036 11.5 5.66667C11.5 7.74297 9.43131 9.63333 6.59999 9.63333C5.87644 9.63333 5.19343 9.5037 4.57805 9.27563C4.35494 9.19294 4.1051 9.22925 3.91475 9.37202L2.63332 10.3332V8.24453C2.63332 8.08606 2.57955 7.93228 2.4808 7.80834C1.97986 7.17958 1.69999 6.44469 1.69999 5.66667ZM15.7 8.93333C15.7 8.54673 15.3866 8.23333 15 8.23333C14.6134 8.23333 14.3 8.54673 14.3 8.93333C14.3 9.71221 14.0202 10.4462 13.5191 11.075C13.4204 11.199 13.3666 11.3527 13.3666 11.5112V13.6923L11.8555 12.6852C11.6762 12.5658 11.4519 12.5355 11.2474 12.6032C10.6757 12.7923 10.0536 12.9 9.39997 12.9C8.74025 12.9 8.11337 12.7941 7.54217 12.6037C7.17542 12.4814 6.77899 12.6796 6.65672 13.0464C6.53446 13.4131 6.73266 13.8095 7.09942 13.9318C7.81406 14.17 8.59049 14.3 9.39997 14.3C10.0831 14.3 10.7394 14.2048 11.3543 14.0336L13.6784 15.5825C13.8932 15.7257 14.1694 15.739 14.397 15.6171C14.6246 15.4953 14.7666 15.2581 14.7666 15V11.7465C15.3519 10.9367 15.7 9.97469 15.7 8.93333Z"
    />
    <defs>
      <clipPath id="clip0_4_300">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </IconBase>
)

const ACTIVE = 'ACTIVE'
const DONE = 'DONE'
const ON_HOLD = 'ON_HOLD'
const CANCELLED = 'CANCELLED'

export const actionStatusMap = {
  ACTIVE,
  DONE,
  ON_HOLD,
  CANCELLED,
} as const

export type ActionStatus = (typeof actionStatusMap)[keyof typeof actionStatusMap]

export const openActionStatuses: ActionStatus[] = [ACTIVE, ON_HOLD]
export const closedActionStatuses: ActionStatus[] = [DONE, CANCELLED]
export const allActionStatuses: ActionStatus[] = [ACTIVE, ON_HOLD, DONE, CANCELLED]

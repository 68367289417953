import {
  autoUpdate,
  flip,
  FloatingNode,
  hide as hideMiddleware,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { Editor, posToDOMRect } from '@tiptap/react'
import React, { useCallback, useEffect } from 'react'
import { FloatingPortal, usePopoverInterceptor } from '../../FloatingUI'
import { floatingTreeNode } from '../../FloatingUI/floatingTreeNode'
import { Wrapper } from '../../FloatingUI/styles'
import { useFloatingTreeNode } from '../../FloatingUI/useFloatingTreeNode'
import { useOpenState, UseOpenStateProps } from '../../FloatingUI/useOpenState'
import { useEditorEvent } from '../utils/useEditorEvent'

export type FloatingSelectionToolbarProps = UseOpenStateProps & {
  editor: Editor
  children?: React.ReactNode
}

export const FloatingSelectionToolbar = floatingTreeNode(
  ({ editor, children, ...props }: FloatingSelectionToolbarProps): React.ReactElement | null => {
    const [open, setOpen] = useOpenState(props)
    const { interceptors } = usePopoverInterceptor()

    const handleOpenStateChange = useCallback(
      (change: boolean) => {
        if (change === true) {
          setOpen(true)
        } else if (interceptors.every((cb) => !cb())) {
          setOpen(false)
        }
      },
      [setOpen, interceptors],
    )

    const nodeId = useFloatingNodeId()
    const { hide } = useFloatingTreeNode({
      onOpenChange: handleOpenStateChange,
    })

    const {
      x,
      y,
      strategy,
      context,
      update,
      refs: { setReference, setFloating, floating },
      middlewareData,
    } = useFloating({
      nodeId,
      open,
      onOpenChange: handleOpenStateChange,
      middleware: [offset(8), flip(), shift(), hideMiddleware()],
      placement: 'top',
      whileElementsMounted: autoUpdate,
    })

    const { getFloatingProps } = useInteractions([useRole(context, { role: 'dialog' }), useDismiss(context)])

    useEffect(() => {
      setReference({
        getBoundingClientRect() {
          return posToDOMRect(editor.view, editor.state.selection.from, editor.state.selection.to)
        },
        contextElement: editor.view.dom,
      })
    }, [setReference, editor])

    useEditorEvent(
      editor,
      'selectionUpdate',
      useCallback(
        ({ editor }) => {
          if (editor.state.selection.empty) {
            setOpen(false)
          } else {
            setOpen(true)
            update()
          }
        },
        [setOpen, update],
      ),
    )

    useEditorEvent(
      editor,
      'blur',
      useCallback(
        ({ event }) => {
          if (!floating.current?.contains(event.relatedTarget as Element)) {
            hide()
          }
        },
        [hide, floating],
      ),
    )

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          {open && (
            <Wrapper
              {...getFloatingProps({
                ref: setFloating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  display: middlewareData.hide?.referenceHidden ? 'none' : undefined,
                },
              })}
            >
              {children}
            </Wrapper>
          )}
        </FloatingPortal>
      </FloatingNode>
    )
  },
)

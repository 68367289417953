import { useFocusRing } from '@react-aria/focus'
import { useSwitch } from '@react-aria/switch'
import { useId, useObjectRef } from '@react-aria/utils'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { useToggleState } from '@react-stately/toggle'
import type { AriaLabelingProps } from '@react-types/shared'
import type { AriaSwitchProps } from '@react-types/switch'
import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { visualRefreshTheme } from '../../themes'
import { Flex } from '../Flex'

export type ToggleButtonProps = Omit<AriaSwitchProps, keyof AriaLabelingProps> & {
  disabled?: boolean
  className?: string
  labelBefore?: boolean
  sx?: ThemeUIStyleObject
}

export const ToggleButton = React.forwardRef<HTMLInputElement, ToggleButtonProps>(
  ({ labelBefore = false, disabled = false, className, children, ...rest }, ref) => {
    const state = useToggleState(rest)
    const objRef = useObjectRef(ref)
    const labelId = useId()
    const descriptionId = useId()
    const { inputProps } = useSwitch(
      {
        ...rest,
        children,
        'aria-labelledby': labelId,
        'aria-describedby': descriptionId,
      },
      state,
      objRef,
    )
    const { isFocusVisible, focusProps } = useFocusRing()
    const isDisabled = !!inputProps.disabled || disabled
    return (
      <Flex as="label" align="start" className={className}>
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            color: state.isSelected ? 'text-light' : 'text',
          }}
        >
          <VisuallyHidden>
            <input {...inputProps} {...focusProps} disabled={isDisabled} ref={objRef} />
          </VisuallyHidden>
          {labelBefore && children}
          <svg
            width={36}
            height={26}
            aria-hidden="true"
            style={{
              marginRight: labelBefore ? 0 : children ? visualRefreshTheme.sizes[1] : 0,
              marginLeft: labelBefore ? (children ? visualRefreshTheme.sizes[1] : 0) : 0,
            }}
          >
            <rect
              x={4}
              y={4}
              width={28}
              height={18}
              rx={9}
              fill={
                state.isSelected
                  ? visualRefreshTheme.colors['system-background-success']
                  : visualRefreshTheme.colors['text-disabled']
              }
            />
            <circle cx={state.isSelected ? 24 : 12} cy={13} r={5} fill="white" />
            {isFocusVisible && (
              <rect
                x={1}
                y={1}
                width={34}
                height={24}
                rx={11}
                fill="none"
                stroke={
                  state.isSelected
                    ? visualRefreshTheme.colors['system-background-success']
                    : visualRefreshTheme.colors['text-disabled']
                }
                strokeWidth={2}
              />
            )}
          </svg>
          {!labelBefore && children}
        </label>
      </Flex>
    )
  },
)

ToggleButton.displayName = 'ToggleButton'

import { GetUserDocument, GetDecisionMakerDocument, UpdateDecisionMakerDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

export type EditDecisionMakerCommandValue = {
  decisionMakerId: string
  decisionId: string
}

export const editDecisionMakerCommand: Command<EditDecisionMakerCommandValue> = {
  id: 'editDecisionMakerCommand',
  name: 'Edit Decision Maker',
  canExecute: async ({ decisionMakerId, decisionId }, { apolloClient }) => {
    const currentDecisionMaker = await apolloClient.query({
      query: GetDecisionMakerDocument,
      variables: { id: decisionId },
    })
    return currentDecisionMaker.data.decision?.decisionMaker.id !== decisionMakerId
  },
  execute: async ({ decisionMakerId, decisionId }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const currentDecisionMaker = await apolloClient.query({
      query: GetDecisionMakerDocument,
      variables: { id: decisionId },
    })
    const nextDecisionMaker = await apolloClient.query({ query: GetUserDocument, variables: { id: decisionMakerId } })

    await apolloClient.mutate({
      mutation: UpdateDecisionMakerDocument,
      variables: {
        id: decisionId,
        data: {
          decisionMaker: decisionMakerId,
        },
      },
      ...(nextDecisionMaker.data.user && {
        optimisticResponse: {
          updateDecision: {
            __typename: 'Decision',
            id: decisionId,
            decisionMaker: nextDecisionMaker.data.user,
          },
        },
      }),
    })

    if (currentDecisionMaker.data.decision?.decisionMaker.id) {
      return {
        args: {
          decisionMakerId: currentDecisionMaker.data.decision?.decisionMaker.id,
          decisionId,
        },
        execute: async ({ decisionMakerId, decisionId }, { apolloClient }) => {
          const nextDecisionMaker = await apolloClient.query({
            query: GetUserDocument,
            variables: { id: decisionMakerId },
          })

          await apolloClient.mutate({
            mutation: UpdateDecisionMakerDocument,
            variables: {
              id: decisionId,
              data: {
                decisionMaker: decisionMakerId,
              },
            },
            ...(nextDecisionMaker.data.user && {
              optimisticResponse: {
                updateDecision: {
                  __typename: 'Decision',
                  id: decisionId,
                  decisionMaker: nextDecisionMaker.data.user,
                },
              },
            }),
          })

          return
        },
      }
    }

    return
  },
}

import { NotFoundBanner, Suspender, useFeatureFlags } from '@mm/company-ui'
import React from 'react'
import { SingleTopicDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useSyncActiveCompanyWithResource } from '../../companies'
import { useMeetingContext } from '../../meetings'
import { SingleTopicPage } from './SingleTopicPage'
import { TopicView } from './TopicView'

export type SingleTopicProps = {
  id: string
  noScroll?: boolean
}

export const SingleTopic = ({ id, noScroll }: SingleTopicProps) => {
  const { newTopicDrawerView } = useFeatureFlags()
  const meeting = useMeetingContext()

  const { data, loading } = useQuery(SingleTopicDocument, {
    variables: {
      id,
    },
  })

  useSyncActiveCompanyWithResource(data?.topic?.company?.id)

  if (data?.topic == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return newTopicDrawerView ? (
    <TopicView topicId={id} meetingId={meeting?.id} noScroll={noScroll} />
  ) : (
    <SingleTopicPage topicId={id} meetingId={meeting?.id} />
  )
}

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Capabilities, CapabilitiesContext, useRegisterCapability } from './CapabilitiesContext'
import { Capability } from './Capability'

type QueuedCapabilitiesProviderProps<A extends any[]> = {
  capability: Capability<A>
  onQueued: () => void
  children: React.ReactNode
}
export const QueuedCapabilitiesInterceptor = <A extends any[]>({
  capability,
  onQueued,
  children,
}: QueuedCapabilitiesProviderProps<A>) => {
  const parent = useContext(CapabilitiesContext)

  if (parent == null) {
    throw new Error('Must be a descendant of CapabilitiesProvider')
  }

  const [pendingInvocation, setPendingInvocation] = useState<A>()
  const [impl, setImpl] = useState<((...args: A) => any) | undefined>()

  useRegisterCapability(
    capability,
    useCallback(
      (...args) => {
        if (impl) {
          impl(...args)
        } else {
          onQueued()
          setPendingInvocation(args)
        }
      },
      [impl, onQueued],
    ),
  )

  const registerParentCapability = parent?.registerCapability
  const registerCapability: Capabilities['registerCapability'] = useCallback(
    (capability2, cb) => {
      if (capability === capability2) {
        setImpl(() => cb)
        return () => {
          setImpl(undefined)
        }
      } else {
        return registerParentCapability(capability2, cb)
      }
    },
    [capability, registerParentCapability],
  )

  const ctxValue = useMemo<Capabilities>(() => ({ ...parent, registerCapability }), [parent, registerCapability])

  useEffect(() => {
    if (impl && pendingInvocation) {
      setPendingInvocation(undefined)
      impl(...pendingInvocation)
    }
  }, [impl, pendingInvocation])

  return <CapabilitiesContext.Provider value={ctxValue}>{children}</CapabilitiesContext.Provider>
}

import { Button, Flex, Input, useFeatureFlags } from '@mm/company-ui'
import { PencilIcon } from '@mm/company-ui-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { TopicLoomEditorUpdateUrlDocument } from '../../../gen/graphql/documents'
import { LoomVideo } from '../../accountability/components/UpdatesListTextItem'
import { useMutation } from '../../apollo'
import { RecordLoomButton } from '../../components/RecordLoomButton'

export type TopicLoomEditorProps = {
  topicId: string
  url?: string
}

export function TopicLoomEditor({ topicId, url }: TopicLoomEditorProps) {
  const [inputUrl, setInputUrl] = useState(url)
  const [isEditing, setIsEditing] = useState(false)
  const [updateLoomUrl] = useMutation(TopicLoomEditorUpdateUrlDocument)
  const { recordLoomOnUpdatesAndTopics } = useFeatureFlags()

  useEffect(() => {
    if (inputUrl !== url) {
      setInputUrl(url)
    }
  }, [url]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleInsertLoom = useCallback(
    async (url: string) => {
      await updateLoomUrl({
        variables: {
          id: topicId,
          loomRecordingLink: url,
        },
      })
      setIsEditing(false)
    },
    [topicId, updateLoomUrl],
  )

  return url && !isEditing ? (
    <div sx={{ position: 'relative' }}>
      <Flex justify="center">
        <LoomVideo url={url} />
      </Flex>
      <Button
        startIcon={<PencilIcon />}
        textHidden
        sx={{ position: 'absolute', top: 0, right: 0 }}
        onClick={() => {
          setIsEditing(true)
        }}
      >
        Edit Loom
      </Button>
    </div>
  ) : recordLoomOnUpdatesAndTopics ? (
    <Flex gap={1}>
      <RecordLoomButton onInserted={handleInsertLoom} />
    </Flex>
  ) : (
    <Flex align="center" gap={2}>
      <Input
        placeholder="Paste link to loom video here."
        sx={{
          flex: 1,
          backgroundColor: 'background',
          border: '1px solid',
          borderColor: 'border',
          borderRadius: 'medium',
        }}
        value={inputUrl}
        onChange={setInputUrl}
        isClearable={isEditing}
        onFocus={() => {
          if (!isEditing) {
            setIsEditing(true)
          }
        }}
      />
      {isEditing && (
        <Button
          onClick={async () => {
            await updateLoomUrl({
              variables: {
                id: topicId,
                loomRecordingLink: inputUrl,
              },
            })
            setIsEditing(false)
          }}
        >
          Save
        </Button>
      )}
    </Flex>
  )
}

const WAITING_FOR_COMMENTS = 'WAITING_FOR_COMMENTS'
const WAITING_FOR_DECISION = 'WAITING_FOR_DECISION'
const ON_HOLD = 'ON_HOLD'
const IMPLEMENTING = 'IMPLEMENTING'
const RESOLVED = 'RESOLVED'
const CANCELLED = 'CANCELLED'

export const decisionStatusMap = {
  WAITING_FOR_COMMENTS,
  WAITING_FOR_DECISION,
  ON_HOLD,
  CANCELLED,
  IMPLEMENTING,
  RESOLVED,
} as const

export type DecisionStatus = (typeof decisionStatusMap)[keyof typeof decisionStatusMap]

export const undecidedDecisionStatuses: DecisionStatus[] = [WAITING_FOR_COMMENTS, WAITING_FOR_DECISION, CANCELLED]
export const openDecisionStatuses: DecisionStatus[] = [WAITING_FOR_COMMENTS, WAITING_FOR_DECISION, IMPLEMENTING]
export const closedDecisionStatuses: DecisionStatus[] = [CANCELLED, RESOLVED]
export const onHoldDecisionStatuses: DecisionStatus[] = [ON_HOLD]
export const allDecisionStatuses: DecisionStatus[] = [
  WAITING_FOR_COMMENTS,
  WAITING_FOR_DECISION,
  IMPLEMENTING,
  RESOLVED,
  ON_HOLD,
  CANCELLED,
]

import { FlyoutItem, formatDateDisplay, formatUserDisplayName, toast } from '@mm/company-ui'
import { CopyDuplicateIcon, EyeViewClosedIcon } from '@mm/company-ui-icons'
import { yDocStateToHtml } from '@mm/tiptap-extension/src'
import { toByteArray } from 'base64-js'
import React from 'react'
import { ActionsDashboardRowDataFragment } from '../../../gen/graphql/documents'
import { useFeatureFlags } from '../../featureFlags'
import { ActiveUserData, useActiveUser } from '../../users'
import { ActionRowsGroup } from '../types'

export type ActionTableGroupHeaderMenuProps = {
  rowGroup: ActionRowsGroup & { isUser?: boolean }
}

export const extractFlatRows = (rowGroup: ActionRowsGroup): Array<ActionsDashboardRowDataFragment> => {
  if (rowGroup.groups) {
    return rowGroup.groups.flatMap(extractFlatRows)
  } else if (rowGroup.rows) {
    return rowGroup.rows
  }

  return []
}

export const formatActionText = (action: ActionsDashboardRowDataFragment, activeUser: ActiveUserData | null) => {
  let text = ''

  if (action.parent) {
    text += `[${action.parent.__typename}: ${'title' in action.parent ? action.parent.title : ''}] `
  }

  if (action.assignee.id !== activeUser?.id) {
    text += `${formatUserDisplayName(action.assignee, 'FIRST_NAME')}: `
  }

  text += action.titleHtml ?? action.title

  if (action.dueAt) {
    text += ` (due ${formatDateDisplay(action.dueAt)})`
  }

  return text
}

function selectElementContents(element: HTMLDivElement) {
  const range = document.createRange()
  range.selectNodeContents(element)
  const select = window.getSelection()
  if (select) {
    select.removeAllRanges()
    select.addRange(range)
  }
}

function copyElementContents(element: HTMLDivElement) {
  document.body.appendChild(element)

  selectElementContents(element)
  document.execCommand('copy')

  document.body.removeChild(element)
}

function createListElementWithActions(
  actions: Array<ActionsDashboardRowDataFragment>,
  cb: (action: ActionsDashboardRowDataFragment) => string,
) {
  const container = document.createElement('div')
  container.contentEditable = 'true'

  const list = document.createElement('ul')

  actions.forEach((action) => {
    const item = document.createElement('li')
    item.innerHTML = cb(action)

    const descriptionHtml = yDocStateToHtml(toByteArray(action.description.byteContent))

    if (descriptionHtml !== '<p></p>') {
      const actionUl = document.createElement('ul')
      const desc = document.createElement('li')
      desc.innerHTML = descriptionHtml
      actionUl.appendChild(desc)
      item.appendChild(actionUl)
    }

    list.appendChild(item)
  })

  container.append(list)

  return container
}

export function ActionTableGroupHeaderMenu(
  props: ActionTableGroupHeaderMenuProps & { onHideUser?: (userId: string) => void },
) {
  const me = useActiveUser()
  const { hideUsersFromCustomMeetingSections } = useFeatureFlags()

  const handleClick = () => {
    const actions = extractFlatRows(props.rowGroup)
    const container = createListElementWithActions(actions, (action) => formatActionText(action, me))

    copyElementContents(container)

    toast('Actions copied to clipboard.')
  }

  return (
    <>
      {hideUsersFromCustomMeetingSections && props.onHideUser && props.rowGroup.isUser ? (
        <FlyoutItem
          onClick={() => {
            props.onHideUser?.(props.rowGroup.id)
          }}
          startIcon={<EyeViewClosedIcon />}
        >
          Hide User
        </FlyoutItem>
      ) : null}

      <FlyoutItem onClick={handleClick} startIcon={<CopyDuplicateIcon />}>
        Copy plain-text actions
      </FlyoutItem>
    </>
  )
}

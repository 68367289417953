import { utils } from '@mm/common'
import { ChevronIcon, IconComponent } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { Flyout } from '../Flyout'
import { Pressable } from '../Pressable'
import {
  actionLabelByStatus,
  colorByStatus,
  filter,
  hoverColorByStatus,
  iconByStatus,
  Shape,
  StatusCheckboxValue,
  topicLabelByStatus,
} from './helpers'
import { StatusMenu } from './StatusMenu'

export type StatusCheckboxProps<T extends StatusCheckboxValue> = {
  type: 'action' | 'topic' | 'feedback'
  shape?: Shape
  withLabel?: boolean
  withDropdown?: boolean
  disableHover?: boolean

  options: readonly [T, ...T[]]
  value?: T
  onChange?: (state: T) => void

  className?: string
  renderMenu?: (props: { onChange: (change: T) => void }) => React.ReactElement
  renderMenuFooter?: () => React.ReactElement
}

export const StatusCheckbox = <T extends StatusCheckboxValue>({
  type,
  shape = 'circle',
  options,
  value = options[0],
  withLabel,
  withDropdown = true,
  disableHover = false,
  onChange,
  className,
  renderMenu,
  renderMenuFooter,
}: StatusCheckboxProps<T>) => {
  const [visible, setVisible] = React.useState(false)
  const Icon: IconComponent = iconByStatus[shape][value]
  const color = colorByStatus[value]
  const hoverColor = hoverColorByStatus[value]
  const label = type === 'action' ? actionLabelByStatus[value] : topicLabelByStatus[value]

  const handleIconClick = useCallback(() => {
    const newValue = value === 'active' ? 'resolved' : 'active'
    if (utils.isOneOf(options, newValue)) {
      onChange?.(newValue)
    }
  }, [onChange, value, options])

  const handleChange = useCallback(
    (state: T) => {
      setVisible(false)
      onChange?.(state)
    },
    [onChange],
  )

  return (
    <Flex row align="center" className={className}>
      <Pressable
        sx={{
          color,
          fontSize: '20px',
          ':hover': disableHover
            ? {}
            : {
                color: hoverColor,
              },
          transition: 'color 0.2s ease',
        }}
        onClick={handleIconClick}
        data-testid="StatusCheckbox-icon-button"
        aria-label={label}
      >
        <Icon display="block" sx={{ filter }} />
      </Pressable>

      {withDropdown && (
        <Flyout
          position="bottom-start"
          visible={visible}
          onClickOutside={() => {
            setVisible(false)
          }}
          content={
            renderMenu ? (
              renderMenu({ onChange: handleChange })
            ) : (
              <StatusMenu
                type={type}
                shape={shape}
                options={options}
                onChange={handleChange}
                renderMenuFooter={renderMenuFooter}
              />
            )
          }
        >
          <Button
            variant="muted"
            endIcon={<ChevronIcon />}
            onClick={() => {
              setVisible(true)
            }}
            sx={{
              borderRadius: '8px',
              pl: withLabel ? 1 : 0.25,
              pr: withLabel ? 0.5 : 0.25,
              py: withLabel ? 0.5 : 0.25,
              height: withLabel ? 4 : 3,
              gap: '2px',
            }}
            data-testid="StatusCheckbox-dropdown-button"
            textHidden={!withLabel}
          >
            {label}
          </Button>
        </Flyout>
      )}
    </Flex>
  )
}

import styled from '@emotion/styled'
import { Button, EditableRef, Flex, Suspender, useMergeRefs } from '@mm/company-ui'
import { HistoryIcon } from '@mm/company-ui-icons'
import { HTMLContent } from '@tiptap/core'
import _ from 'lodash'
import React, { useCallback, useRef, useState } from 'react'
import { css } from 'theme-ui'
import { CommentThreadDocument, CommentThreadMoreRepliesDocument } from '../../../gen/graphql/documents'
import { useLazyQuery, useQuery } from '../../apollo'
import { CapabilitiesProvider, RegisterCapability } from '../../capabilities'
import { FOCUS_AND_QUOTE_COMMENT, REPLY_TO_COMMENT } from '../capabilities'
import { AddCommentReplyBox } from './AddCommentReplyBox'
import { Comment } from './Comment'

export type CommentThreadProps = {
  id: string
  cached?: boolean
}

export const CommentThread = ({ id, cached }: CommentThreadProps) => {
  const { data, loading, error } = useQuery(CommentThreadDocument, {
    fetchPolicy: cached ? 'cache-only' : 'cache-and-network',
    variables: {
      commentId: id,
    },
  })
  const [fetchMore, { loading: fetchingMore }] = useLazyQuery(CommentThreadMoreRepliesDocument)

  const [forceExpanded, setForceExpanded] = useState(false)
  const replyRequested = useRef<HTMLContent | null>(null)
  const reply = useCallback((editor: EditableRef | null) => {
    const quote = replyRequested.current
    if (editor == null || quote == null) return
    if (quote) {
      editor.commands.setContent(`<blockquote>${quote}</blockquote><p></p>`)
    }
    editor.commands.focus('end')
    replyRequested.current = null
  }, [])
  const editableRef = useRef<EditableRef>(null)
  const forwardEditableRef = useMergeRefs([editableRef, reply])
  const handleReply = useCallback(
    (htmlContent: HTMLContent = '') => {
      setForceExpanded(true)
      replyRequested.current = htmlContent
      reply(editableRef.current)
    },
    [reply],
  )
  const handleCancelReply = useCallback(() => {
    editableRef.current?.commands.clearContent(true)
    setForceExpanded(false)
  }, [])

  const comment = data?.comment
  if (comment == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('comment not found')
  }

  const oldestReply = _.last(comment.oldestReplies?.edges)?.node
  const pageInfo = comment.replies?.pageInfo

  let replies = comment.replies?.edges ?? []
  const lastPaginatedReply = _.last(replies)?.node
  const overlapIdx =
    !lastPaginatedReply || !comment.oldestReplies
      ? -1
      : comment.oldestReplies.edges.findIndex(({ node }) => node.id === lastPaginatedReply.id)

  if (overlapIdx !== -1) {
    replies = replies.concat(comment.oldestReplies?.edges.slice(overlapIdx + 1) ?? [])
  }

  if (replies.length === 0 && !forceExpanded) {
    return (
      <CapabilitiesProvider>
        <RegisterCapability capability={REPLY_TO_COMMENT} handler={handleReply} />
        <RegisterCapability capability={FOCUS_AND_QUOTE_COMMENT} handler={handleReply} />
        <div data-testid="CommentThread">
          <Comment id={comment.id} cached />
        </div>
      </CapabilitiesProvider>
    )
  }

  // using paddings instead of  flexbox gaps, so that our "tree" is continuous
  return (
    <CapabilitiesProvider>
      <div data-testid="CommentThread">
        <div sx={{ position: 'relative' }}>
          <TreeRoot />
          <CapabilitiesProvider>
            <RegisterCapability capability={REPLY_TO_COMMENT} handler={handleReply} />
            <Comment id={comment.id} cached />
          </CapabilitiesProvider>
        </div>
        <Flex column reverse>
          <ReplyWrapper>
            <TreeBranch />
            <AddCommentReplyBox replyTo={comment.id} ref={forwardEditableRef} onCancel={handleCancelReply} cached />
          </ReplyWrapper>
          {replies.map(({ node }) => (
            <ReplyWrapper key={node.id}>
              <TreeBranch />
              <TreeTrunk />
              <Comment id={node.id} cached />
            </ReplyWrapper>
          ))}
          {pageInfo?.hasNextPage && overlapIdx === -1 && (
            <>
              <ReplyWrapper>
                <TreeBranch />
                <TreeTrunk />
                <Button
                  variant="muted"
                  size="small"
                  loading={fetchingMore}
                  startIcon={<HistoryIcon />}
                  sx={{ marginY: '6px' }}
                  onClick={() => {
                    void fetchMore({
                      variables: {
                        commentId: id,
                        after: pageInfo.endCursor,
                      },
                    })
                  }}
                >
                  View 15 previous comments
                </Button>
              </ReplyWrapper>
              {oldestReply && (
                <ReplyWrapper>
                  <TreeBranch />
                  <TreeTrunk />
                  <Comment id={oldestReply.id} cached />
                </ReplyWrapper>
              )}
            </>
          )}
        </Flex>
      </div>
    </CapabilitiesProvider>
  )
}

const ReplyWrapper = styled.div(
  css({
    position: 'relative',
    paddingLeft: '36px',
  }),
)

const BaseTree = styled.div(
  css({
    position: 'absolute',
    left: '20px',
    borderLeft: '1px solid',
    color: 'border',
  }),
)

const TreeRoot = styled(BaseTree)(
  css({
    top: '36px',
    bottom: 0,
  }),
)

const TreeTrunk = styled(BaseTree)(
  css({
    top: 0,
    bottom: 0,
  }),
)

const TreeBranch = styled(BaseTree)(
  css({
    top: 0,
    height: '20px',
    width: '16px',
    borderBottom: '1px solid',
    borderBottomLeftRadius: '12px',
  }),
)

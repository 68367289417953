import { ActionStatus, GetActionStatusDocument, UpdateActionStatusDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditActionStatusCommandValue = {
  status: ActionStatus
  actionId: string
}

export const editActionStatusCommand: Command<EditActionStatusCommandValue> = {
  id: 'editActionStatusCommand',
  name: 'Edit Status',
  canExecute: async ({ status, actionId }, { apolloClient }) => {
    const currentAction = await apolloClient.query({ query: GetActionStatusDocument, variables: { id: actionId } })
    return currentAction.data.action?.status !== status
  },
  execute: async ({ status, actionId }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const currentAction = await apolloClient.query({ query: GetActionStatusDocument, variables: { id: actionId } })
    const updatedAt = Date.now()
    await apolloClient.mutate({
      mutation: UpdateActionStatusDocument,
      variables: {
        id: actionId,
        data: {
          status,
        },
      },
      optimisticResponse: {
        updateAction: {
          __typename: 'Action',
          id: actionId,
          status,
          updatedAt: updatedAt,
          statusUpdatedAt: updatedAt,
        },
      },
    })

    if (currentAction.data.action?.status) {
      return {
        args: {
          status: currentAction.data.action.status,
          actionId,
        },
        execute: async ({ status, actionId }, { apolloClient }) => {
          const updatedAt = Date.now()
          await apolloClient.mutate({
            mutation: UpdateActionStatusDocument,
            variables: {
              id: actionId,
              data: {
                status,
              },
            },
            optimisticResponse: {
              updateAction: {
                __typename: 'Action',
                id: actionId,
                status,
                updatedAt: updatedAt,
                statusUpdatedAt: updatedAt,
              },
            },
          })

          return
        },
      }
    }

    return
  },
}

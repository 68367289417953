import { Modal, ModalSection, Flex, Text, Button } from '@mm/company-ui'
import React from 'react'

export type DecisionWarningRemovealProps = {
  onConfirmation: () => void
  onCancel?: () => void
  loading?: boolean
}

export const DecisionWarningRemoval = ({ onCancel, onConfirmation, loading }: DecisionWarningRemovealProps) => {
  return (
    <Modal title="Are you sure?" isOpen={true}>
      <ModalSection>
        <Text>Removing yourself from this field will revoke your access to this issue.</Text>
      </ModalSection>
      <ModalSection>
        <Flex row grow justify="flex-end" gap={2}>
          {onCancel && (
            <Button
              onClick={() => {
                onCancel()
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            variant="warning"
            loading={loading}
            disabled={loading}
            onClick={() => {
              onConfirmation()
            }}
          >
            Remove
          </Button>
        </Flex>
      </ModalSection>
    </Modal>
  )
}

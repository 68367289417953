import styled from '@emotion/styled'
import { css } from 'theme-ui'

export const InlineEditorStyles = css({
  fontFamily: 'body',
  fontWeight: 'body',
  lineHeight: 'body',
  fontSize: 1,
  color: 'text',

  code: {
    bg: 'background-light',
    borderRadius: '8px',
    padding: '4px',
  },

  'a[href]': {
    textDecoration: 'underline',
    color: 'accent-text',
    cursor: 'pointer',
  },

  strong: {
    fontWeight: 'bold',
  },
})

export const EditorStyles = styled.div`
  ${InlineEditorStyles}

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }

  .ProseMirror > :first-child {
    margin-top: 0;
  }

  .ProseMirror > :last-child {
    margin-bottom: 0;
  }

  ol,
  ul {
    margin: 0 0 0.15em 0;
    padding-left: 28px;
  }
  li {
    margin: 0 0 0.15em 0;
  }
  p {
    margin: 0 0 0.15em 0;
  }

  h1 {
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: 20px;
    line-height: 1.4;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 12px;
  }
  h2 {
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: 17px;
    line-height: 1.4;
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  ol,
  ol > li > ol > li > ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol {
    list-style-type: decimal;
  }
  ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol {
    list-style-type: lower-alpha;
  }
  ol > li > ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol,
  ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol {
    list-style-type: lower-roman;
  }

  ul,
  ul > li > ul > li > ul > li > ul,
  ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul,
  ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul {
    list-style-type: disc;
  }
  ul > li > ul,
  ul > li > ul > li > ul > li > ul > li > ul,
  ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul,
  ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul {
    list-style-type: circle;
  }
  ul > li > ul > li > ul,
  ul > li > ul > li > ul > li > ul > li > ul > li > ul,
  ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul,
  ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul {
    list-style-type: square;
  }

  blockquote {
    margin: 0 0 0.15em 0;
    padding-left: 24px;
    box-shadow: 2px 0 0 0 ${({ theme }) => theme.colors['border-strong']} inset;
  }

  pre {
    margin: 0 0 0.15em 0;
  }

  pre > code {
    background: ${({ theme }) => theme.colors['background-light']};
    border-radius: 4px;
    padding: 8px 16px;
    display: block;
  }

  p[data-placeholder].is-empty::after {
    content: attr(data-placeholder);
    color: ${({ theme }) => theme.colors['text-disabled-medium']};
    pointer-events: none;
    display: block;
    margin-top: -21px;
  }
`

import { Button, Flex, Text } from '@mm/company-ui'
import { CloseIcon, InfoIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { useLocalStorage } from 'react-use'

export const MeetingTopicsArchiveBanner = () => {
  const [hidden, setHidden] = useLocalStorage('meeting-topics-archive-banner-hidden', false)
  const hide = useCallback(() => {
    setHidden(true)
  }, [setHidden])

  if (hidden) return null

  return (
    <Flex gap={0.5} justify="space-between" sx={{ marginX: 2, bg: 'background-light', borderRadius: 'medium' }}>
      <Flex gap={1} sx={{ padding: 1 }}>
        <InfoIcon sx={{ marginTop: 0.25 }} />
        <Text>
          To unarchive a topic, change its status back to “Open” by unchecking the green status checkbox. The topic will
          appear back at the top of the section it was initially in.
        </Text>
      </Flex>
      <Button variant="muted" startIcon={<CloseIcon />} textHidden onClick={hide}>
        Hide
      </Button>
    </Flex>
  )
}

import { Suspender, Tag, TagSelect } from '@mm/company-ui'
import React, { useCallback } from 'react'
import { ActionStatus, ActionStatusCheckboxDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useEditAction } from '../../DoneNotDone'

export type BaseActionStatusSelectProps = {
  value: ActionStatus | 'NOT_DONE'
  onChange: (status: ActionStatus | 'NOT_DONE') => void
}

export const BaseActionStatusSelect = ({ value, onChange }: BaseActionStatusSelectProps) => {
  return (
    <TagSelect value={value} onChange={onChange}>
      <Tag
        value="ACTIVE"
        variant="default"
        sx={{ width: 10, fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
        data-testid="TagSelect__selection"
      >
        To do
      </Tag>
      <Tag
        value="DONE"
        variant="success"
        sx={{ width: 10, fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
        data-testid="TagSelect__selection"
      >
        Done
      </Tag>
      <Tag
        value="NOT_DONE"
        variant="warning"
        hidden
        sx={{ width: 10, fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
        data-testid="TagSelect__selection"
      >
        Not Done
      </Tag>
      <Tag
        value="ON_HOLD"
        variant="alert"
        hidden
        sx={{ width: 10, fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
        data-testid="TagSelect__selection"
      >
        Blocked
      </Tag>
      <Tag
        value="CANCELLED"
        variant="default"
        sx={{ width: 10, fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
        data-testid="TagSelect__selection"
      >
        Canceled
      </Tag>
    </TagSelect>
  )
}

export type ActionStatusSelectProps = {
  actionId: string
  className?: string
}

export const ActionStatusSelect = ({ actionId }: ActionStatusSelectProps) => {
  const editAction = useEditAction(actionId)
  const { data, loading } = useQuery(ActionStatusCheckboxDocument, {
    variables: { id: actionId },
  })

  const handleChange = useCallback(
    (status: ActionStatus | 'NOT_DONE') => {
      if (status === 'NOT_DONE') {
        return
      }
      void editAction?.({ status })
    },
    [editAction],
  )

  if (data?.action == null) {
    if (loading) {
      return <Suspender />
    }
    return null
  }

  return <BaseActionStatusSelect value={data.action.status} onChange={handleChange} />
}

import {
  Button,
  ButtonGroup,
  DRAWER_SECTION_INNER_PADDING,
  DrawerSection,
  Flex,
  Popover,
  PopupWrapper,
  Suspender,
  Text,
  useFeatureFlags,
} from '@mm/company-ui'
import { ActionsIcon, CommentChatIcon, EllipsisIcon, FilePageTemplateIcon, PlusIcon } from '@mm/company-ui-icons'
import React, { useCallback, useState } from 'react'
import { ActionViewDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { AddActionCommentBox } from '../../comments'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { DrawerView } from '../../components/DrawerView'
import { useDynamicModules } from '../../hooks/useDynamicModules'
import { Banner } from '../../meetings/components/MeetingUpdateRequestBanner'
import { ContextualDrawerNavigation, useContextualDrawers } from '../../navigation'
import { ActionCollapsibleMetadata } from './ActionCollapsibleMetadata'
import { ActionComments } from './ActionComments'
import { ActionConsolidatedMetadata } from './ActionConsolidatedMetadata'
import { ActionCreatedContext } from './ActionCreatedContext'
import { ActionDescription } from './ActionDescription'
import { ActionDoneNotDoneButtons } from './ActionDoneNotDoneButtons'
import { ActionMetadata } from './ActionMetadata'
import { ActionMoreMenu } from './ActionMoreMenu'
import { ActionPrivacyTag } from './ActionPrivacyTag'
import { ActionTitle } from './ActionTitle'
import { ActionUpdates } from './ActionUpdates'
import { FollowUpActions } from './FollowUpActions'

export type ActionViewProps = {
  actionId: string
}

export const ActionView = ({ actionId }: ActionViewProps) => {
  const {
    contextualDrawerNavigation,
    nonBlockingDrawer,
    reconfigureDrawerViews,
    moveActionNotDoneToDrawer,
    flipComments,
    preventOldGoalAndActionUpdates,
    actionConsolidatedMetadata,
  } = useFeatureFlags()
  const [hasFollowUpActionsToShow, setHasFollowUpActionsToShow] = useState(false)

  const { data, loading, error } = useQuery(ActionViewDocument, {
    variables: {
      actionId,
    },
  })
  const followUpActions = data?.action?.followUpActions?.edges

  const { active, hideDrawer } = useContextualDrawers()
  const onActionDeleted = useCallback(() => {
    if (active) hideDrawer()
  }, [active, hideDrawer])

  const { hasComments, hasDescription, handleShowComments, handleShowDescription } = useDynamicModules({
    id: actionId,
    module: 'Action',
  })

  const action = data?.action

  if (action == null) {
    if (loading) {
      return <Suspender />
    }

    throw error || new Error('Action not found')
  }

  if (reconfigureDrawerViews) {
    return (
      <DrawerView
        actionTitle="Follow-Up Actions"
        resourceId={actionId}
        resourceType="actions"
        renderMoreMenu={() => <ActionMoreMenu id={actionId} onActionDeleted={onActionDeleted} />}
        renderExtraMenuItems={
          <>
            <ActionPrivacyTag actionId={actionId} sx={{ background: 'none' }} />
          </>
        }
        renderBanner={
          moveActionNotDoneToDrawer && !preventOldGoalAndActionUpdates ? (
            <Banner
              label="Did you get it done?"
              action={
                <ActionDoneNotDoneButtons
                  id={action.id}
                  status={action.status}
                  dateRescheduled={action.dateRescheduled}
                  dateRescheduledAt={action.dateRescheduledAt}
                  statusUpdatedAt={action.statusUpdatedAt}
                />
              }
            />
          ) : null
        }
        renderTitle={<ActionTitle cached id={actionId} sx={{ fontSize: 2 }} />}
        renderMetadata={
          actionConsolidatedMetadata ? (
            <ActionConsolidatedMetadata actionId={actionId} />
          ) : (
            <ActionMetadata actionId={actionId} />
          )
        }
        renderDescription={<ActionDescription actionId={actionId} fillHeight={false} padding={1} />}
        renderActions={
          followUpActions &&
          followUpActions?.length > 0 && (
            <FollowUpActions
              actionId={actionId}
              noAddNewAction
              onHasActionsToShowChange={setHasFollowUpActionsToShow}
            />
          )
        }
        renderUpdates={<ActionUpdates actionId={actionId} />}
        renderComments={
          <ActionComments
            actionId={actionId}
            fixedComposer={false}
            order={flipComments ? 'oldest' : 'newest'}
            fixedAndScroll={false}
          />
        }
        renderCommentComposer={flipComments ? <AddActionCommentBox actionId={actionId} /> : null}
      />
    )
  }

  return (
    <div sx={{ backgroundColor: nonBlockingDrawer ? 'white' : 'background-light', height: '100%', overflowY: 'auto' }}>
      <Flex row gap={2} align="start" sx={{ padding: 1, paddingBottom: 0 }}>
        {contextualDrawerNavigation && <ContextualDrawerNavigation sx={{ padding: 0.5 }} />}
        <ActionTitle
          cached
          id={actionId}
          sx={{
            // Very brittle, should build a real component for it
            fontSize: 18,
            '&:hover': {
              boxShadow: 'none',
            },
            '&:focus-within': {
              boxShadow: 'none',
            },
            '&:hover > .ProseMirror': {
              backgroundColor: 'background-light',
            },
            '> .ProseMirror': { padding: 1, borderRadius: 'medium', transition: 'background-color 200ms' },
            '> .ProseMirror-focused': { backgroundColor: 'background-light' },
          }}
        />

        <ButtonGroup size="small" textHidden variant="muted" containerProps={{ sx: { padding: 0.5 } }}>
          <CloseDrawerButton resourceType="actions" />

          <ExpandCollapseToggle resourceType="actions" id={actionId} />

          <Popover
            placement="bottom-start"
            render={() => (
              <PopupWrapper sx={{ px: 0 }}>
                <ActionMoreMenu id={actionId} onActionDeleted={onActionDeleted} />
              </PopupWrapper>
            )}
          >
            {({ getReferenceProps }) => (
              <Button variant="muted" startIcon={<EllipsisIcon />} textHidden size="small" {...getReferenceProps()}>
                Open menu
              </Button>
            )}
          </Popover>
        </ButtonGroup>
      </Flex>

      <Flex column gap={2} sx={{ paddingX: 2 }}>
        <div>
          <Text variant="small" uppercase color="#ff7043" sx={{ fontWeight: 500 }}>
            Action
          </Text>{' '}
          <ActionCreatedContext actionId={actionId} />
        </div>

        <ActionCollapsibleMetadata actionId={actionId} />

        <ButtonGroup sx={{ marginBottom: 2 }}>
          {!hasDescription && (
            <Button startIcon={<PlusIcon />} size="small" onClick={handleShowDescription}>
              Add Description
            </Button>
          )}
          {!hasComments && (
            <Button startIcon={<PlusIcon />} size="small" onClick={handleShowComments}>
              Add Comment
            </Button>
          )}
        </ButtonGroup>
      </Flex>

      <Flex column gap={1} sx={{ paddingX: 2 }}>
        {hasDescription && (
          <DrawerSection
            title="Description"
            icon={<FilePageTemplateIcon />}
            dangerouslyCustomizeChildrenPadding={{
              padding: 0, // Since content is a textarea, padding should be handled by that
            }}
          >
            <ActionDescription actionId={actionId} fillHeight={false} padding={DRAWER_SECTION_INNER_PADDING} />
          </DrawerSection>
        )}

        <DrawerSection
          id="followUpActions"
          title="Follow-Up Actions"
          icon={<ActionsIcon />}
          dangerouslyCustomizeChildrenPadding={{
            padding: 0, // Since action table has borders that are full-width, it handles its own padding
          }}
          sx={{
            display: hasFollowUpActionsToShow ? undefined : 'none',
          }}
        >
          <FollowUpActions actionId={actionId} noAddNewAction onHasActionsToShowChange={setHasFollowUpActionsToShow} />
        </DrawerSection>

        {hasComments && (
          <DrawerSection
            id="comments"
            title="Comments"
            icon={<CommentChatIcon />}
            dangerouslyCustomizeChildrenPadding={{
              padding: DRAWER_SECTION_INNER_PADDING - 1, // Since comments have extra padding for hover effects, offset here
            }}
          >
            <ActionComments actionId={actionId} fixedComposer={false} order="newest" fixedAndScroll={false} />
          </DrawerSection>
        )}
      </Flex>
    </div>
  )
}

import { Button, Editable, FastTable, Flex, formatDateDisplay, useFeatureFlags } from '@mm/company-ui'
import { HistoryIcon } from '@mm/company-ui-icons'
import { yDocStateToHtml } from '@mm/tiptap-extension/src'
import { toByteArray } from 'base64-js'
import React, { useCallback, useMemo } from 'react'
import { GoodThingDataFragment, GoodThingsUserTableDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'
import { useOnEventBusEvent } from '../../eventbus'
import { useContextualDrawers, useRegisterDrawerNavigationProvider } from '../../navigation'
import { GoodThingsUpdatedTopic } from '../subscriptions/GlobalGoodThingsSubscription'
import { GoodThingMoreMenu } from './GoodThingMoreMenu'
import { GoodThingRowContext } from './GoodThingRowContext'

export type GoodThingsUserTableProps = {
  userId: string
}

type RowGroup = FastTable.TableRowGroup<GoodThingDataFragment> & {
  renderEmptyPlaceholder?: () => React.ReactNode
}

const getRowId = ({ id }: GoodThingDataFragment) => id

export function GoodThingsUserTable({ userId }: GoodThingsUserTableProps) {
  const { tableRowDetails } = useFeatureFlags()
  const { activeCompanyId } = useActiveCompanyId()
  const { data, loading, refetch, fetchMore } = useQuery(GoodThingsUserTableDocument, {
    variables: { userId, companyId: activeCompanyId },
  })

  useOnEventBusEvent(GoodThingsUpdatedTopic, (event) => {
    if (event.type === 'upserted') {
      void refetch()
    }
  })

  const goodThings = useMemo(() => data?.user?.goodThings?.edges.map((t) => t.node.id) || [], [data])

  const { showDrawer } = useContextualDrawers()
  useRegisterDrawerNavigationProvider(
    'goodThing',
    useCallback(
      (id) => {
        const itemIndex = goodThings.findIndex((goodThingId) => goodThingId === id)
        if (itemIndex === -1) return null

        const prevItemId = goodThings[itemIndex - 1]
        const nextItemId = goodThings[itemIndex + 1]

        return {
          itemIndex,
          totalItemsCount: goodThings.length,
          hasMoreItems: false,
          displayNext:
            nextItemId == null
              ? null
              : () => {
                  showDrawer('goodThing', nextItemId)
                },
          displayPrevious:
            prevItemId == null
              ? null
              : () => {
                  showDrawer('goodThing', prevItemId)
                },
        }
      },
      [goodThings, showDrawer],
    ),
  )

  const columns = useMemo<FastTable.TableColumn<GoodThingDataFragment>[]>(() => {
    const columns: Array<FastTable.TableColumn<GoodThingDataFragment>> = [
      {
        renderCell: ({ id, createdAt, description }) => {
          return (
            <Flex column gap={0.5} data-clickable="true" sx={{ width: '100%' }}>
              <Editable
                mode="compact"
                initialValue={yDocStateToHtml(toByteArray(description.byteContent))}
                editable={false}
              />
              {tableRowDetails ? <GoodThingRowContext goodThingId={id} createdAt={createdAt} /> : null}
            </Flex>
          )
        },
      },
    ]

    if (!tableRowDetails) {
      columns.push({
        header: 'Created At',
        renderCell: ({ createdAt }) => (
          <FastTable.TextCell textProps={{ variant: 'small', color: 'text-light' }}>
            {formatDateDisplay(createdAt, 'RELATIVE')}
          </FastTable.TextCell>
        ),
        width: 100,
        disableResizing: true,
      })
    }

    columns.push({
      header: 'Creator',
      renderCell: ({ user }) => <FastTable.UserCell user={user} size={tableRowDetails ? 'large' : undefined} />,
      width: 30,
      disableResizing: true,
    })

    return columns
  }, [tableRowDetails])

  const tableData = useMemo<RowGroup[]>(() => {
    return [
      {
        id: 'goodThings',
        title: 'Good Things',
        hideTitle: true,
        rows: data?.user?.goodThings?.edges.map((t) => t.node) || [],
        renderEmptyPlaceholder: () => <></>,
      },
    ]
  }, [data?.user?.goodThings])

  const handleRowClick = useCallback(
    ({ id }: GoodThingDataFragment, event: React.MouseEvent) => {
      showDrawer('goodThing', id, event)
    },
    [showDrawer],
  )

  const pageInfo = data?.user?.goodThings?.pageInfo
  return (
    <>
      <FastTable.Table
        tableStyles={{ padding: 0 }}
        rowStyles={{ paddingX: 2, paddingY: 1.5 }}
        data={tableData}
        columns={columns}
        getRowId={getRowId}
        noHeaders
        renderRowGroupFooter={() => (
          <div sx={{ borderTop: '1px solid', borderTopColor: 'border', paddingY: 1 }}>
            {pageInfo?.hasNextPage ? (
              <div sx={{ px: 2 }}>
                <Button
                  variant="muted"
                  size="small"
                  loading={loading}
                  startIcon={<HistoryIcon />}
                  onClick={() => {
                    void fetchMore({
                      variables: {
                        after: pageInfo.endCursor,
                      },
                    })
                  }}
                >
                  Show 10 more
                </Button>
              </div>
            ) : null}
          </div>
        )}
        renderRowMenu={({ id }) => <GoodThingMoreMenu id={id} />}
        renderRowGroupEmptyPlaceholder={({ renderEmptyPlaceholder }) => renderEmptyPlaceholder?.()}
        onRowClick={handleRowClick}
        noBorders={false}
      />
    </>
  )
}

import { AddWaitingForRowData, Editable, EditableFormController, Flex, Tag, Text, defaultItems } from '@mm/company-ui'
import React, { useCallback, useRef } from 'react'
import { Controller, FieldArrayWithId, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form'
import { CrudUpdateGoal } from '../../../gen/graphql/documents'
import { ADD_ACTION } from '../../actions/capabilities'
import { useRegisterCapability } from '../../capabilities'
import { useComplexToolbar } from '../../editor/hooks/useComplexToolbar'
import { CreateUpdateData } from './CreateUpdateForm'
import { EditUpdateData } from './EditUpdateForm'
import { GoalRowProps } from './GoalRow'
import { NextActionsTowardsGoalsList, NextActionsTowardsGoalsListRef } from './NextActionsTowardsGoalsList'

export type UpdateGoalData = CrudUpdateGoal & { goal: GoalRowProps['goal'] }

export type UpdateGoalFormProps = {
  field: FieldArrayWithId<CreateUpdateData | EditUpdateData, 'goals', 'id'>
  fieldIdentifier: `goals.${number}`
  error?: Merge<FieldError, FieldErrorsImpl<CreateUpdateData['goals'][number]>>
}

export function UpdateGoalForm({ field, fieldIdentifier, error }: UpdateGoalFormProps) {
  const {
    formState: { isSubmitting },
    control,
    watch,
    register,
    setValue,
    trigger,
  } = useFormContext<CreateUpdateData>()

  const nextActionsRef = useRef<NextActionsTowardsGoalsListRef>()

  const processedActionsToHide = watch('actions')
    .map((action) => {
      if (action.status) {
        return action.action.id
      }
      return
    })
    .filter(Boolean) as Array<string>

  const cb = useCallback(
    (data: AddWaitingForRowData) => {
      if (!nextActionsRef.current) {
        throw new Error('NextActionsListRef not available')
      }
      return nextActionsRef.current.addAction(data)
    },
    [nextActionsRef],
  )
  useRegisterCapability(ADD_ACTION, cb)
  const selectionToolbar = useComplexToolbar(defaultItems)

  const watchStatus = watch(`${fieldIdentifier}.status`)

  const handleClose = async () => {
    const pass = await trigger(fieldIdentifier)

    if (pass) {
      setValue(`${fieldIdentifier}.editing`, false)
    }
  }

  return (
    <>
      <Flex
        column
        sx={{
          border: '1px solid',
          borderColor: 'border',
          borderRadius: 'medium',
          backgroundColor: 'background',
        }}
      >
        <Flex
          column
          gap={1}
          sx={{
            border: '1px solid',
            borderColor: error?.status ? 'system-border-warning' : 'transparent',
            padding: 2,
          }}
        >
          <Text color="text-light" sx={{ flex: 1, display: 'block' }}>
            What is the status?
          </Text>
          <Controller
            name={`${fieldIdentifier}.status`}
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, ref } }) => (
              <div ref={ref} tabIndex={0}>
                <Flex gap={1}>
                  <Tag
                    sx={{ width: '88px', fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
                    variant={!watchStatus ? 'success' : watchStatus === 'ON_TRACK' ? 'success-inverted' : 'default'}
                    onClick={() => {
                      onChange(watchStatus === 'ON_TRACK' ? undefined : 'ON_TRACK')
                    }}
                  >
                    On Track
                  </Tag>
                  <Tag
                    sx={{ width: '88px', fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
                    variant={!watchStatus ? 'alert' : watchStatus === 'AT_RISK' ? 'alert-inverted' : 'default'}
                    onClick={() => {
                      onChange(watchStatus === 'AT_RISK' ? undefined : 'AT_RISK')
                    }}
                  >
                    At Risk
                  </Tag>
                  <Tag
                    sx={{ width: '88px', fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
                    variant={!watchStatus ? 'warning' : watchStatus === 'BEHIND' ? 'warning-inverted' : 'default'}
                    onClick={() => {
                      onChange(watchStatus === 'BEHIND' ? undefined : 'BEHIND')
                    }}
                  >
                    Off Track
                  </Tag>

                  <div sx={{ flex: 1 }} />

                  <Tag
                    sx={{ width: '88px', fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
                    variant={!watchStatus ? 'default' : watchStatus === 'DONE' ? 'accent' : 'default'}
                    onClick={() => {
                      onChange(watchStatus === 'DONE' ? undefined : 'DONE')
                    }}
                  >
                    Completed
                  </Tag>
                  <Tag
                    sx={{ width: '88px', fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
                    variant={!watchStatus ? 'default' : watchStatus === 'CANCELLED' ? 'accent' : 'default'}
                    onClick={() => {
                      onChange(watchStatus === 'CANCELLED' ? undefined : 'CANCELLED')
                    }}
                  >
                    Canceled
                  </Tag>
                </Flex>
              </div>
            )}
          />
        </Flex>

        {watchStatus && !['DONE', 'CANCELLED'].includes(watchStatus) ? (
          <>
            <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
              <Flex
                column
                sx={{
                  border: '1px solid',
                  borderColor: error?.reasonHtml ? 'system-border-warning' : 'transparent',
                  padding: 2,
                }}
              >
                <Text color="text-light" sx={{ display: 'block', marginBottom: 1 }}>
                  How is it progressing?
                </Text>
                <EditableFormController
                  {...register(`${fieldIdentifier}.reasonHtml`, {
                    required: true,
                    validate: (value) => {
                      return (
                        !!value &&
                        value !== '<p></p>' &&
                        value !==
                          '<p><strong>Good:</strong></p><ul><li><p></p></li></ul><p><strong>Not good:</strong></p><ul><li><p></p></li></ul>'
                      )
                    },
                  })}
                >
                  {(field) => (
                    <Editable
                      {...field}
                      selectionToolbar={selectionToolbar}
                      mode="compact"
                      initialValue="<p><strong>Good:</strong></p><ul><li><p></p></li></ul><p><strong>Not good:</strong></p><ul><li><p></p></li></ul>"
                      editable={!isSubmitting}
                      hotkeys={{
                        'Meta-Enter': () => {
                          void handleClose()
                          return true
                        },
                      }}
                      sx={{ '> .ProseMirror': { padding: 2, margin: -2 } }}
                    />
                  )}
                </EditableFormController>
              </Flex>
            </div>

            <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
              <Flex
                column
                sx={{
                  border: '1px solid',
                  borderColor: error?.nextHtml ? 'system-border-warning' : 'transparent',
                  padding: 2,
                }}
              >
                <Text color="text-light" sx={{ display: 'block', marginBottom: 1 }}>
                  What can you do to fix the <Text bold>not good</Text>, or continue to advance toward the goal?{' '}
                  <Text variant="small">(optional)</Text>
                </Text>
                <EditableFormController {...register(`${fieldIdentifier}.nextHtml`)}>
                  {(field) => (
                    <Editable
                      {...field}
                      selectionToolbar={selectionToolbar}
                      mode="compact"
                      hidePlaceholderWhenFocused
                      editable={!isSubmitting}
                      placeholder="Write ideas for next actions"
                      hotkeys={{
                        'Meta-Enter': () => {
                          void handleClose()
                          return true
                        },
                      }}
                      sx={{ '> .ProseMirror': { padding: 2, margin: -2 } }}
                    />
                  )}
                </EditableFormController>
              </Flex>
            </div>

            <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
              <Flex
                column
                sx={{
                  border: '1px solid',
                  borderColor: error?.nextActions ? 'system-border-warning' : 'transparent',
                  padding: 2,
                }}
              >
                <Text color="text-light" sx={{ display: 'block', marginBottom: 1 }}>
                  Next actions <Text variant="small">(optional)</Text>
                </Text>

                <Controller
                  name={`${fieldIdentifier}.nextActions`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <NextActionsTowardsGoalsList
                      ref={nextActionsRef}
                      goalId={field.goal.id}
                      hideIds={processedActionsToHide}
                      onCreate={(action) => {
                        onChange([...(value || []), action.id])
                      }}
                    />
                  )}
                />
              </Flex>
            </div>
          </>
        ) : null}

        {watchStatus && ['DONE', 'CANCELLED'].includes(watchStatus) ? (
          <>
            <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
              <Flex
                column
                sx={{
                  border: '1px solid',
                  borderColor: error?.reasonHtml ? 'system-border-warning' : 'transparent',
                  padding: 2,
                }}
              >
                <Text color="text-light" sx={{ display: 'block', marginBottom: 1 }}>
                  Any key learnings? <Text variant="small">(optional)</Text>
                </Text>
                <EditableFormController {...register(`${fieldIdentifier}.reasonHtml`, { required: false })}>
                  {(field) => (
                    <Editable
                      {...field}
                      selectionToolbar={selectionToolbar}
                      mode="compact"
                      editable={!isSubmitting}
                      placeholder="Share any insights or realizations"
                      hotkeys={{
                        'Meta-Enter': () => {
                          void handleClose()
                          return true
                        },
                      }}
                      sx={{ '> .ProseMirror': { padding: 2, margin: -2 } }}
                    />
                  )}
                </EditableFormController>
              </Flex>
            </div>
          </>
        ) : null}
      </Flex>

      {/* <ButtonGroup size="small" containerProps={{ sx: { paddingTop: 2, justifyContent: 'flex-end' } }}>
        <Button variant="accent" onClick={handleClose}>
          Complete
        </Button>
      </ButtonGroup> */}
    </>
  )
}

import { quickEmojiReactions } from '@mm/common'
import { Button, ButtonProps, Card, Flex, FlyoutItem, Popover, PopupWrapper, Suspender, Text } from '@mm/company-ui'
import {
  AddEmojiReactionIcon,
  CommentChatIcon,
  DecisionIcon,
  DeleteIcon,
  EditIcon,
  MoreHorizontalIcon,
} from '@mm/company-ui-icons'
import React from 'react'
import { CommentMenuDeleteCommentDocument, CommentMenuDocument } from '../../../gen/graphql/documents'
import { evictCache, useMutation, useQuery } from '../../apollo'
import { useCapabilities } from '../../capabilities'
import { Emoji } from '../../components/emoji/Emoji'
import { useGetDecidedCommentIds } from '../../decisions/DecidedCommentContext'
import { MARK_OR_UNMARK_COMMENT_AS_DECISION, REPLY_TO_COMMENT } from '../capabilities'
import { useAddCommentEmojiReaction } from '../hooks/useAddCommentEmojiReaction'
import { CommentEmojiPicker } from './CommentEmojiPicker'

export type CommentMenuProps = {
  id: string
  onEditComment?: () => void
  onDeletionMenuVisibilityChange?: (visible: boolean) => void
  onSubmenuVisibilityChange?: (visible: boolean) => void

  className?: string
  cached?: boolean
}

const VISIBLE_MENU_ITEM_COUNT = 6

export const CommentMenu = ({
  id,
  onEditComment,
  onDeletionMenuVisibilityChange,
  onSubmenuVisibilityChange,
  className,
  cached,
}: CommentMenuProps) => {
  const [decidedCommentIds] = useGetDecidedCommentIds()
  const { data, loading, error } = useQuery(CommentMenuDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: { id },
  })

  const [deleteComment, { loading: deleting }] = useMutation(CommentMenuDeleteCommentDocument, {
    variables: { id },
    update: (cache, { data }) => {
      if (data?.deleteComment.__typename === 'Success') {
        evictCache(cache, 'Comment', id)
      }
    },
    optimisticResponse: {
      deleteComment: {
        __typename: 'Success',
      },
    },
  })

  const { isRegistered, execute } = useCapabilities()
  const addEmojiReaction = useAddCommentEmojiReaction(id)

  const comment = data?.comment
  if (comment == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Comment not found')
  }

  const menuItems: React.ReactElement[] = []

  menuItems.push(
    ...quickEmojiReactions.map((emoji) => (
      <MenuItem
        key={emoji}
        icon={<Emoji shortcodes={emoji} />}
        onClick={() => {
          void addEmojiReaction(emoji)
        }}
      >
        {`Add ${emoji} reaction`}
      </MenuItem>
    )),
  )

  menuItems.push(
    <Popover
      key="emoji-reactions"
      placement="bottom-end"
      render={({ hide }) => <CommentEmojiPicker commentId={id} onEmojiSelected={hide} />}
      onOpenChange={onSubmenuVisibilityChange}
    >
      {({ getReferenceProps }) => (
        <MenuItem icon={<AddEmojiReactionIcon />} {...getReferenceProps()}>
          Add Emoji Reaction
        </MenuItem>
      )}
    </Popover>,
  )

  if (isRegistered(REPLY_TO_COMMENT)) {
    menuItems.push(
      <MenuItem
        key="reply"
        icon={<CommentChatIcon />}
        onClick={() => {
          execute(REPLY_TO_COMMENT)
        }}
      >
        Reply
      </MenuItem>,
    )
  }

  if (isRegistered(MARK_OR_UNMARK_COMMENT_AS_DECISION) && comment.canMarkOrUnmarkDecision) {
    menuItems.push(
      <MenuItem
        key="mark-decision"
        variant={decidedCommentIds?.includes(comment.id) ? 'default' : 'muted'}
        icon={<DecisionIcon />}
        onClick={() => {
          execute(MARK_OR_UNMARK_COMMENT_AS_DECISION, comment.id)
        }}
      >
        {decidedCommentIds?.includes(comment.id) ? 'Unmark decision' : 'Mark as decision'}
      </MenuItem>,
    )
  }

  if (comment.canEdit) {
    menuItems.push(
      <MenuItem key="edit" icon={<EditIcon />} onClick={onEditComment}>
        Edit
      </MenuItem>,
    )
  }

  if (comment.canDelete) {
    menuItems.push(
      <Popover
        key="delete"
        placement="top-end"
        onOpenChange={onDeletionMenuVisibilityChange}
        render={({ hide }) => (
          <PopupWrapper sx={{ px: 0 }} data-testid="DeleteCommentPopup">
            <Flex
              column
              gap={0.5}
              sx={{
                width: '300px',
                px: 2,
                pt: 1,
                pb: 2,
                borderBottom: '1px solid',
                borderBottomColor: 'border',
              }}
            >
              <Text bold>Delete this message?</Text>
              <Text variant="small" color="text-light">
                Careful, this action cannot be undone.
              </Text>
            </Flex>
            <Flex row justify="flex-end" gap={1.5} sx={{ px: 2, pt: 1.5, pb: 0.5 }}>
              <Button size="small" onClick={hide}>
                Cancel
              </Button>
              <Button
                size="small"
                variant="warning"
                onClick={() => {
                  void deleteComment()
                }}
                loading={deleting}
              >
                Delete
              </Button>
            </Flex>
          </PopupWrapper>
        )}
      >
        {({ getReferenceProps }) => (
          <MenuItem icon={<DeleteIcon />} {...getReferenceProps()}>
            Delete
          </MenuItem>
        )}
      </Popover>,
    )
  }

  if (menuItems.length === 0) {
    return null
  }

  return (
    <Card sx={{ padding: 0.5, display: 'inline-block' }} className={className} data-testid="CommentMenu">
      <Flex row>
        {menuItems.slice(0, VISIBLE_MENU_ITEM_COUNT)}
        {menuItems.length === VISIBLE_MENU_ITEM_COUNT + 1 && menuItems[VISIBLE_MENU_ITEM_COUNT]}
        {menuItems.length > VISIBLE_MENU_ITEM_COUNT + 1 && (
          <Popover
            placement="bottom-end"
            onOpenChange={onSubmenuVisibilityChange}
            render={() => (
              <PopupWrapper sx={{ px: 0 }}>
                <menuContext.Provider value={true}>{menuItems.slice(VISIBLE_MENU_ITEM_COUNT)}</menuContext.Provider>
              </PopupWrapper>
            )}
          >
            {({ getReferenceProps }) => (
              <Button
                variant="muted"
                size="small"
                startIcon={<MoreHorizontalIcon />}
                textHidden
                {...getReferenceProps()}
              >
                More
              </Button>
            )}
          </Popover>
        )}
      </Flex>
    </Card>
  )
}

export const menuContext = React.createContext(false)

type MenuItemOwnProps = {
  variant?: ButtonProps['variant']
  icon: React.ReactElement
  children: string
}

type MenuItemProps = React.ComponentPropsWithoutRef<'button'> & MenuItemOwnProps

const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  ({ variant = 'muted', icon, children, ...rest }, ref) => {
    const insidePopover = React.useContext(menuContext)
    return insidePopover ? (
      <FlyoutItem startIcon={icon} {...rest} ref={ref}>
        {children}
      </FlyoutItem>
    ) : (
      <Button variant={variant} size="small" startIcon={icon} textHidden {...rest} ref={ref}>
        {children}
      </Button>
    )
  },
)

MenuItem.displayName = 'MenuItem'

import styled from '@emotion/styled'
import { FlyoutDivider, RadioButton, RadioGroup, Suspender } from '@mm/company-ui'
import { MeetingPrivacyLevel } from '@mm/graphql-helpers/gen/graphql/graphql'
import React from 'react'
import { css } from 'theme-ui'
import { GoalPrivacyMenuDocument, GoalPrivacyMenuUpdatePrivacyDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'

export type GoalPrivacyMenuProps = {
  goalId: string

  cached?: boolean
}

export const GoalPrivacyMenu = ({ goalId, cached }: GoalPrivacyMenuProps) => {
  const { data, loading, error } = useQuery(GoalPrivacyMenuDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id: goalId,
    },
  })

  const [updatePrivacy] = useMutation(GoalPrivacyMenuUpdatePrivacyDocument, {
    optimisticResponse: ({ id, privacy }) =>
      ({
        updateGoal: {
          __typename: 'Goal',
          id,
          privacy,
        },
      } as const),
  })

  const goal = data?.goal

  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Goal not found, id: ' + goalId)
  }
  return (
    <GoalPrivacyMenuUi
      privacyLevel={goal.isPrivate ? 'PRIVATE' : 'PUBLIC'}
      companyName={goal.company.name}
      onChange={(privacy) => {
        void updatePrivacy({
          variables: {
            id: goalId,
            privacy,
          },
        })
      }}
    />
  )
}
type GoalPrivacyBareMenu = {
  privacyLevel: MeetingPrivacyLevel
  companyName: string
  onChange: (privacyLevel: MeetingPrivacyLevel) => void
}
export const GoalPrivacyMenuUi = ({ privacyLevel, companyName, onChange }: GoalPrivacyBareMenu) => {
  return (
    <RadioGroup
      shape="tick"
      value={privacyLevel}
      onChange={onChange}
      aria-label="Privacy level"
      sx={{ width: '350px' }}
    >
      <MenuItem value="PUBLIC" description={<>Visible to everyone at {companyName}</>}>
        Public
      </MenuItem>
      <FlyoutDivider sx={{ my: 0 }} />
      <MenuItem
        value="PRIVATE"
        description="Visible to assignee of this goal and participants of any meeting this goal is linked to"
      >
        Private
      </MenuItem>
    </RadioGroup>
  )
}
const MenuItem = styled(RadioButton)(
  css({
    padding: 2,
    ':hover': {
      bg: 'background-light',
    },
  }),
)

import { useRef, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLatestCallback<T extends ((...args: any[]) => any) | undefined>(
  callback: T,
): (...args: Parameters<NonNullable<T>>) => ReturnType<NonNullable<T>> | Extract<T, undefined> {
  const callbackRef = useRef(callback)
  callbackRef.current = callback
  return useState(
    () =>
      (...args: Parameters<NonNullable<T>>) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        callbackRef.current?.(...args),
  )[0]
}

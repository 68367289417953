import { AddWaitingForRowData, Button, Editable, EditableRef, Flex, Text, defaultItems } from '@mm/company-ui'
import { HTMLContent } from '@tiptap/core'
import { DateTime } from 'luxon'
import React, { useCallback, useRef, useState } from 'react'
import { ADD_ACTION } from '../../actions/capabilities'
import { createActionOnDecisionCommand } from '../../actions/commands/createActionOnDecision'
import { CapabilitiesProvider, RegisterCapability, useRegisterCapability } from '../../capabilities'
import { useCommand } from '../../commands'
import { FOCUS_AND_QUOTE_COMMENT_AS_DECISION } from '../../comments/capabilities'
import { Container } from '../../comments/components/CommentComposer'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { useComplexToolbar } from '../../editor/hooks/useComplexToolbar'
import { useFeatureFlags } from '../../featureFlags'
import { useActiveUser } from '../../users'
import { addDecisionCommentCommand } from '../commands/addDecisionCommentCommand'

const isOnlyWhiteSpaces = (text: string) => !/\S/.test(text)

export type DecisionDraftSubmitData = {
  htmlBody: string
  author: string
}

export type DecisionDraftProps = {
  decisionId: string
  onDecisionCommentAdded?: () => void
}

export const DecisionDraft = ({ decisionId, onDecisionCommentAdded }: DecisionDraftProps) => {
  const { canCreateOnBehalfOfOtherUsers, decisionBoxInDrawerView } = useFeatureFlags()
  const editableRef = useRef<EditableRef>(null)
  const activeUser = useActiveUser()
  const [author, setAuthor] = useState(activeUser?.id || '')
  const [submitting, setSubmitting] = useState(false)
  const [onlyWhiteSpaces, setOnlyWhiteSpaces] = useState(true)
  const [isFocused, setFocused] = useState<boolean>()
  const [hasText, setHasText] = useState(false)

  const addDecisionComment = useCommand(addDecisionCommentCommand)
  const addAction = useCommand(createActionOnDecisionCommand)

  const handleSubmit = useCallback(() => {
    if (onlyWhiteSpaces) {
      return
    }

    void (async () => {
      try {
        setSubmitting(true)
        editableRef.current?.commands.blur()

        await addDecisionComment.execute({
          decisionId,
          author,
          htmlBody: editableRef.current?.getHTML() ?? '',
          onDecisionCommentAdded: () => {
            editableRef.current?.commands.clearContent(true)
            onDecisionCommentAdded?.()
          },
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('failed to submit a decision', e)
      } finally {
        setSubmitting(false)
      }
    })()
  }, [addDecisionComment, onlyWhiteSpaces, author, decisionId, onDecisionCommentAdded])

  const handleOnAddAction = useCallback(
    async (data: AddWaitingForRowData) => {
      void addAction.execute({
        decisionId,
        data: {
          title: data.title,
          assignee: data.assignee,
          dateRescheduled: false,
          description: data.description,
          dueAt: data.dueAt ? DateTime.fromJSDate(data.dueAt).toMillis() : null,
          waitingFor: data.waitingFor,
          repeat: data.repeat,
        },
      })
    },
    [decisionId, addAction],
  )

  const items = useComplexToolbar(defaultItems, ['add-action'])

  useRegisterCapability(
    FOCUS_AND_QUOTE_COMMENT_AS_DECISION,
    useCallback((selectedHtml: HTMLContent) => {
      editableRef.current?.chain().focus('end').createParagraphNear().insertContent(selectedHtml).run()
    }, []),
    !decisionBoxInDrawerView,
  )

  const active = hasText || isFocused
  return (
    <>
      <CapabilitiesProvider>
        <RegisterCapability capability={ADD_ACTION} handler={handleOnAddAction} />

        <Container
          column
          grow
          active={active}
          onFocus={() => {
            setFocused(true)
          }}
          onBlur={() => {
            setFocused(false)
          }}
          initial={{
            height: 40,
          }}
          animate={{
            height: active ? 'auto' : 40,
          }}
          transition={{ type: 'tween' }}
          sx={{
            border: 'none',
            width: '100%',
          }}
        >
          <Editable
            ref={editableRef}
            placeholder="Enter decision here..."
            mode="compact"
            onChangeRaw={({ editor }) => {
              setHasText(!editor.isEmpty)
              setOnlyWhiteSpaces(isOnlyWhiteSpaces(editor.getText()))
            }}
            hotkeys={{
              'Meta-Enter': () => {
                handleSubmit()
                return true
              },
            }}
            sx={{
              display: 'flex',
              flexDirection: 'column',

              padding: 1,
              '.ProseMirror': {
                minHeight: 10,
                margin: 0,
                py: 0,
                px: 1,
                overflowY: 'auto',
                maxHeight: '70vh',
              },
            }}
            selectionToolbar={items}
          />
          <Flex
            row
            gap={1.5}
            sx={{
              borderTop: '1px solid',
              borderColor: 'border',
              p: 1,
            }}
          >
            <div sx={{ flex: 1 }}>
              {canCreateOnBehalfOfOtherUsers && (
                <Flex align="center" gap={1}>
                  <Text color="text-light" variant="small">
                    Author:
                  </Text>
                  <UserSelectWithCompanyMembers
                    withName
                    value={author}
                    onAdd={setAuthor}
                    targetProps={{ size: 'small' }}
                  />
                </Flex>
              )}
            </div>
            <Button
              variant="accent"
              size="small"
              onClick={handleSubmit}
              loading={submitting}
              disabled={onlyWhiteSpaces}
            >
              Add
            </Button>
          </Flex>
        </Container>
      </CapabilitiesProvider>
    </>
  )
}

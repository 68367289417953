import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CopyDuplicateIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="copy-duplicate" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.9 1.5C1.40294 1.5 1 1.90294 1 2.4V14.0667C1 14.5637 1.40294 14.9667 1.9 14.9667H13.5667C14.0637 14.9667 14.4667 14.5637 14.4667 14.0667V2.4C14.4667 1.90294 14.0637 1.5 13.5667 1.5H1.9ZM2.8 13.1667V3.3H12.6667V13.1667H2.8ZM16.7333 6.5C16.2363 6.5 15.8333 6.90294 15.8333 7.4C15.8333 7.89706 16.2363 8.3 16.7333 8.3H17.6667V18.1667H7.8V17.2333C7.8 16.7363 7.39706 16.3333 6.9 16.3333C6.40294 16.3333 6 16.7363 6 17.2333V19.0667C6 19.5637 6.40294 19.9667 6.9 19.9667H18.5667C19.0637 19.9667 19.4667 19.5637 19.4667 19.0667V7.4C19.4667 6.90294 19.0637 6.5 18.5667 6.5H16.7333Z"
    />
  </IconBase>
)

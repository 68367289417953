import { Avatar, Button, Flex, Flyout, Text, formatUserDisplayName } from '@mm/company-ui'
import { ChevronIcon } from '@mm/company-ui-icons'
import React from 'react'
import { NavSidebarUserDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyIdOrNull } from '../../companies'
import { useIsBeta } from '../hooks/useIsBeta'
import { NavSidebarUserMenu } from './NavSidebarUserMenu'

export const NavSidebarAccount = ({ hasBorder = true, hasRedirectOnChange = true }) => {
  const { activeCompanyId } = useActiveCompanyIdOrNull()
  const [isBeta] = useIsBeta()

  const { data } = useQuery(NavSidebarUserDocument, {
    variables: {
      companyId: activeCompanyId ?? '',
      includeCompany: !!activeCompanyId,
    },
  })

  if (data?.me == null) {
    return null
  }

  return (
    <Flyout content={<NavSidebarUserMenu hasRedirectOnChange={hasRedirectOnChange} />} sx={{ width: 'auto' }}>
      <Flex
        role="button"
        aria-label="Account Picker"
        row
        gap={1}
        align="center"
        sx={{
          paddingLeft: 1,
          borderRadius: 'medium',
          cursor: 'pointer',
          ...(hasBorder
            ? {
                border: '1px solid',
                borderColor: 'border',
              }
            : {}),

          '&:hover': {
            backgroundColor: 'background-light-alpha',
          },
        }}
      >
        <Flex shrink={false}>
          <Avatar size="small" name={formatUserDisplayName(data.me)} />
        </Flex>

        <Flex column grow sx={{ minWidth: 0 }}>
          <Text overflow="ellipsis">{data?.company?.name}</Text>
        </Flex>

        {isBeta && (
          <Text data-testid="NavSidebar-beta-text" color="accent-text" variant="small" uppercase bold>
            Beta
          </Text>
        )}

        <Button variant="muted" size="small" startIcon={<ChevronIcon />} textHidden>
          Open account menu
        </Button>
      </Flex>
    </Flyout>
  )
}

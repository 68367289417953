import { Suspender } from '@mm/company-ui'
import React from 'react'
import { TopicTitleDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { ControlledHtmlTitleInput } from '../../components/ControlledHtmlTitleInput'
import { editTopicTitleCommand } from '../commands/editTopicTitleCommand'

export type TopicTitleProps = {
  id: string
  cached?: boolean
  className?: string
}

export const TopicTitle = ({ id, cached, className }: TopicTitleProps) => {
  const editTopicTitle = useCommand(editTopicTitleCommand)
  const { data, loading, error } = useQuery(TopicTitleDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })

  const topic = data?.topic
  if (topic == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Topic not found')
  }

  const handleChange = (title: string) => {
    void editTopicTitle.execute({
      topicId: id,
      title,
    })
  }

  return (
    <ControlledHtmlTitleInput
      value={topic.titleHtml}
      placeholder="Type topic title here..."
      onDebouncedChange={handleChange}
      className={className}
    />
  )
}

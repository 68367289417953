import { GetDecisionDueDocument, UpdateDecisionDueAtDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditDecisionDueAtCommandValue = {
  id: string
  timestamp: Date
}

export const editDecisionDueAtCommand: Command<EditDecisionDueAtCommandValue> = {
  id: 'editDecisionDueAtCommand',
  name: 'Edit Decision Due At',
  canExecute: async ({ id, timestamp }, { apolloClient }) => {
    const currentDecision = await apolloClient.query({ query: GetDecisionDueDocument, variables: { id: id } })

    return currentDecision.data.decision?.dueAt !== timestamp.getTime()
  },
  execute: async ({ timestamp, id }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const currentDecision = await apolloClient.query({ query: GetDecisionDueDocument, variables: { id: id } })

    await apolloClient.mutate({
      mutation: UpdateDecisionDueAtDocument,
      variables: {
        id: id,
        data: {
          dueAt: timestamp.getTime(),
        },
      },
      optimisticResponse: {
        updateDecision: {
          __typename: 'Decision',
          id: id,
          dueAt: timestamp.getTime(),
        },
      },
    })

    if (currentDecision.data.decision?.dueAt) {
      return {
        args: {
          id,
          timestamp: new Date(currentDecision.data.decision.dueAt),
        },
        execute: async ({ timestamp, id }, { apolloClient }) => {
          await apolloClient.mutate({
            mutation: UpdateDecisionDueAtDocument,
            variables: {
              id: id,
              data: {
                dueAt: timestamp.getTime(),
              },
            },
            optimisticResponse: {
              updateDecision: {
                __typename: 'Decision',
                id: id,
                dueAt: timestamp.getTime(),
              },
            },
          })
        },
      }
    }

    return
  },
}

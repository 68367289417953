import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const UnlockedIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="unlocked" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.489 0.100195H10.4041C9.11693 0.0886316 7.87768 0.588096 6.95826 1.48913C6.03772 2.39125 5.51312 3.62206 5.49991 4.91087V7.99999H2.85705C2.38366 7.99999 1.99991 8.40977 1.99991 8.91525V19.0847C1.99991 19.5902 2.38366 20 2.85705 20H17.1428C17.6162 20 17.9999 19.5902 17.9999 19.0847V8.91525C17.9999 8.40977 17.6162 7.99999 17.1428 7.99999H7.29991V4.92501C7.30935 4.11503 7.63951 3.34176 8.21813 2.77471C8.79778 2.20665 9.57931 1.89212 10.3909 1.90019H10.4749C11.2849 1.90964 12.0582 2.2398 12.6252 2.81842C13.1933 3.39806 13.5078 4.17959 13.4998 4.99114C13.4948 5.48817 13.8937 5.89511 14.3908 5.90005C14.8878 5.905 15.2947 5.50608 15.2997 5.00905C15.3125 3.72023 14.8129 2.47908 13.9108 1.55854C13.0087 0.638007 11.7779 0.113408 10.489 0.100195ZM3.71419 18.1695V9.8305H16.2856V18.1695H3.71419Z"
      />
    </IconBase>
  )
}

import React from 'react'
import { TopicEmojiReactionsDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { EmojiReactions } from '../../components/emoji/EmojiReactions'
import { useAddTopicEmojiReaction } from '../hooks/useAddTopicEmojiReaction'
import { useRemoveTopicEmojiReaction } from '../hooks/useRemoveTopicEmojiReaction'

export type TopicEmojiReactionsProps = {
  id: string
  cached?: boolean
  hasAddButton?: boolean
}

export const TopicEmojiReactions = ({ id, cached, hasAddButton }: TopicEmojiReactionsProps) => {
  const { data } = useQuery(TopicEmojiReactionsDocument, {
    variables: { id },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const addReaction = useAddTopicEmojiReaction(id)
  const removeReaction = useRemoveTopicEmojiReaction(id)

  const emojiReactions = data?.topic?.emojiReactions || []

  return (
    <EmojiReactions
      testid="TopicEmojiReactions"
      reactions={emojiReactions}
      hasAddButton={hasAddButton}
      onAdd={addReaction}
      onRemove={removeReaction}
    />
  )
}

import { Button, useFeatureFlags } from '@mm/company-ui'
import { DoubleArrowRight } from '@mm/company-ui-icons'
import { useRouter } from 'next/router'
import React from 'react'
import { isExactRoute } from '../../utils'
import { useContextualDrawers } from '../navigation'

export type CloseDrawerButtonProps = {
  resourceType:
    | 'topics'
    | 'actions'
    | 'profile'
    | 'goals'
    | 'goodThings'
    | 'decisions'
    | 'updates'
    | 'feedback'
    | 'accountability/updates'
    | 'accountability/actions'
    | 'accountability/goals'
    | 'accountability/team'
}

export const CloseDrawerButton = ({ resourceType }: CloseDrawerButtonProps) => {
  const { closeDrawerButton } = useFeatureFlags()
  const router = useRouter()
  const { hideDrawer } = useContextualDrawers()
  const isFullScreen = isExactRoute(router, `/${resourceType}/[id]`)

  if (!isFullScreen && closeDrawerButton) {
    return (
      <Button variant="muted" textHidden size="small" startIcon={<DoubleArrowRight />} onClick={hideDrawer}>
        Close Drawer
      </Button>
    )
  }

  return null
}

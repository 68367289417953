import {
  Text,
  Flex,
  useFeatureFlags,
  PanelMetadataItem,
  Suspender,
  PanelMetadataContainer,
  formatDateDisplay,
  toast,
} from '@mm/company-ui'
import { CircleCheckmarkIcon, UserProfileIcon, ArrowRightIcon, ClockIcon } from '@mm/company-ui-icons'
import React from 'react'
import { EditPrototypeFeedbackDocument, SinglePrototoypeFeedbackDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { FeedbackStatusCheckbox } from './FeedbackStatusCheckbox'
import { FeedbackToChip } from './FeedbackToChip'

export type FeedbackMetadataProps = {
  prototypeFeedbackId: string
}

export const FeedbackMetadata = ({ prototypeFeedbackId }: FeedbackMetadataProps) => {
  const { data, loading, error } = useQuery(SinglePrototoypeFeedbackDocument, {
    variables: {
      id: prototypeFeedbackId,
    },
  })
  const { resolveFeedback } = useFeatureFlags()
  const [editFeedback] = useMutation(EditPrototypeFeedbackDocument)

  const prototypeFeedback = data?.prototypeFeedback

  const handleChange = async ({ fromId, toId }: { fromId?: string; toId?: string }) => {
    try {
      const { data } = await editFeedback({
        variables: {
          prototypeFeedbackId,
          data: {
            fromId,
            toId,
          },
        },
      })

      if (data?.updatePrototypeFeedback?.__typename === 'AccessDeniedError') {
        toast(`You are unable to edit feedback you do not have access to.`, { type: 'error' })
      }
    } catch (error) {
      toast(`You are unable to edit feedback you do not have access to.`, { type: 'error' })
    }
  }

  if (prototypeFeedback == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Feedback not found')
  }

  return (
    <PanelMetadataContainer>
      <PanelMetadataItem title="From" icon={<UserProfileIcon />}>
        <UserSelectWithCompanyMembers
          value={prototypeFeedback.author?.id}
          onAdd={(fromId: string) => {
            return handleChange({ fromId })
          }}
          withName
        />
      </PanelMetadataItem>

      <PanelMetadataItem title="To" icon={<ArrowRightIcon />}>
        {prototypeFeedback.to?.__typename === 'User' ? (
          <UserSelectWithCompanyMembers
            value={prototypeFeedback.to?.id}
            onAdd={(toId: string) => {
              return handleChange({ toId })
            }}
            withName
          />
        ) : prototypeFeedback.to ? (
          <FeedbackToChip {...prototypeFeedback.to} />
        ) : null}
      </PanelMetadataItem>

      <PanelMetadataItem title="Created" sx={{ alignItems: 'center' }} icon={<ClockIcon />}>
        <Text sx={{ paddingLeft: 1 }}>{formatDateDisplay(prototypeFeedback.createdAt)}</Text>
      </PanelMetadataItem>
      {prototypeFeedback.status && resolveFeedback && (
        <PanelMetadataItem title="Status" icon={<CircleCheckmarkIcon />} sx={{ alignItems: 'center' }}>
          <Flex gap={1} sx={{ paddingLeft: 1 }}>
            <FeedbackStatusCheckbox id={prototypeFeedbackId} cached /> Resolved
          </Flex>
        </PanelMetadataItem>
      )}
    </PanelMetadataContainer>
  )
}

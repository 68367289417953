import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const ConfigureIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="settings-control" viewBox="0 0 12 12" {...props}>
    <path
      d="M5.84091 2.02273C5.84091 1.31982 5.27109 0.75 4.56818 0.75C3.86527 0.75 3.29545 1.31982 3.29545 2.02273C3.29545 2.72564 3.86527 3.29545 4.56818 3.29545C5.27109 3.29545 5.84091 2.72564 5.84091 2.02273Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M10.4545 2.5C10.7181 2.5 10.9318 2.28632 10.9318 2.02273C10.9318 1.75914 10.7181 1.54545 10.4545 1.54545L6.95455 1.54545C6.69096 1.54545 6.47727 1.75914 6.47727 2.02273C6.47727 2.28632 6.69095 2.5 6.95455 2.5L10.4545 2.5Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M2.65909 2.02273C2.65909 2.28632 2.44541 2.5 2.18182 2.5H1.22727C0.963682 2.5 0.75 2.28632 0.75 2.02273C0.75 1.75914 0.963682 1.54545 1.22727 1.54545L2.18182 1.54545C2.44541 1.54545 2.65909 1.75914 2.65909 2.02273Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M2.18182 10.4545C2.44541 10.4545 2.65909 10.2409 2.65909 9.97727C2.65909 9.71368 2.44541 9.5 2.18182 9.5H1.22727C0.963682 9.5 0.75 9.71368 0.75 9.97727C0.75 10.2409 0.963682 10.4545 1.22727 10.4545H2.18182Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M10.4545 10.4545C10.7181 10.4545 10.9318 10.2409 10.9318 9.97727C10.9318 9.71368 10.7181 9.5 10.4545 9.5H6.95455C6.69096 9.5 6.47727 9.71368 6.47727 9.97727C6.47727 10.2409 6.69095 10.4545 6.95455 10.4545H10.4545Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M5.20455 6C5.20455 6.26359 4.99086 6.47727 4.72727 6.47727H1.22727C0.963682 6.47727 0.75 6.26359 0.75 6C0.75 5.73641 0.963682 5.52273 1.22727 5.52273H4.72727C4.99086 5.52273 5.20455 5.73641 5.20455 6Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M10.4545 6.47727C10.7181 6.47727 10.9318 6.26359 10.9318 6C10.9318 5.73641 10.7181 5.52273 10.4545 5.52273H9.5C9.23641 5.52273 9.02273 5.73641 9.02273 6C9.02273 6.26359 9.23641 6.47727 9.5 6.47727H10.4545Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M8.38636 6C8.38636 5.29709 7.81654 4.72727 7.11364 4.72727C6.41073 4.72727 5.84091 5.29709 5.84091 6C5.84091 6.70291 6.41073 7.27273 7.11364 7.27273C7.81654 7.27273 8.38636 6.70291 8.38636 6Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M5.84091 9.97727C5.84091 9.27436 5.27109 8.70455 4.56818 8.70455C3.86527 8.70455 3.29545 9.27436 3.29545 9.97727C3.29545 10.6802 3.86527 11.25 4.56818 11.25C5.27109 11.25 5.84091 10.6802 5.84091 9.97727Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </IconBase>
)

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const MoreHorizontalIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="more-horizontal" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33317 11.6663C4.25365 11.6663 4.99984 10.9201 4.99984 9.99967C4.99984 9.0792 4.25365 8.33301 3.33317 8.33301C2.4127 8.33301 1.6665 9.0792 1.6665 9.99967C1.6665 10.9201 2.4127 11.6663 3.33317 11.6663ZM10.0002 11.6663C10.9206 11.6663 11.6668 10.9201 11.6668 9.99967C11.6668 9.0792 10.9206 8.33301 10.0002 8.33301C9.07969 8.33301 8.3335 9.0792 8.3335 9.99967C8.3335 10.9201 9.07969 11.6663 10.0002 11.6663ZM18.3333 9.99967C18.3333 10.9201 17.5871 11.6663 16.6667 11.6663C15.7462 11.6663 15 10.9201 15 9.99967C15 9.0792 15.7462 8.33301 16.6667 8.33301C17.5871 8.33301 18.3333 9.0792 18.3333 9.99967Z"
    />
  </IconBase>
)

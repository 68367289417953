import {
  Button,
  ButtonGroup,
  DRAWER_SECTION_INNER_PADDING,
  DrawerLayout,
  DrawerSection,
  Popover,
  PopupWrapper,
  Suspender,
  Text,
} from '@mm/company-ui'
import { ActionsIcon, CommentChatIcon, EllipsisIcon, FilePageTemplateIcon, InfoIcon } from '@mm/company-ui-icons'
import React, { useState } from 'react'
import { SingleActionDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { RegisterCapability } from '../../capabilities'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { CREATE_ISSUE } from '../../decisions/capabilities'
import { useFeatureFlags } from '../../featureFlags'
import { useTotalComments } from '../../hooks/useTotalComments'
import { useMeetingContext } from '../../meetings'
import { ADD_TOPIC } from '../../topics/capabilities'
import { ActionComments } from './ActionComments'
import { ActionDescription } from './ActionDescription'
import { ActionMetadata } from './ActionMetadata'
import { ActionMetadataSummary } from './ActionMetadataSummary'
import { ActionMoreMenu } from './ActionMoreMenu'
import { ActionTitleSection } from './ActionTitleSection'
import { FollowUpActions } from './FollowUpActions'

export type SingleActionPageProps = {
  actionId: string
}

export const SingleActionPage = ({ actionId }: SingleActionPageProps) => {
  const { metadataSummary, commentsActionsInDrawersCollapsedWhenEmpty } = useFeatureFlags()

  const { data, loading, error } = useQuery(SingleActionDocument, {
    variables: {
      id: actionId,
    },
  })

  const meeting = useMeetingContext()

  const [hasFollowUpActionsToShow, setHasFollowUpActionsToShow] = useState(false)

  const action = data?.action
  const { commentsLabel, hasComments } = useTotalComments(action)

  if (action == null) {
    if (loading) {
      return <Suspender />
    }

    throw error || new Error('Action not found')
  }

  return (
    <DrawerLayout
      title={action.title}
      renderTitleButtonGroup={(props) => (
        <ButtonGroup {...props}>
          <CloseDrawerButton resourceType="actions" />

          <ExpandCollapseToggle resourceType="actions" id={actionId} />

          <Popover
            placement="bottom-start"
            render={() => (
              <PopupWrapper sx={{ px: 0 }}>
                <ActionMoreMenu id={actionId} />
              </PopupWrapper>
            )}
          >
            {({ getReferenceProps }) => (
              <Button variant="muted" startIcon={<EllipsisIcon />} textHidden size="small" {...getReferenceProps()}>
                Open menu
              </Button>
            )}
          </Popover>
        </ButtonGroup>
      )}
      renderTitleSection={(props) => <ActionTitleSection actionId={actionId} {...props} />}
    >
      <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meeting?.id} />
      <RegisterCapability capability={CREATE_ISSUE} handler={() => Promise.resolve()} skip={!meeting?.id} />

      <DrawerSection
        title="About"
        icon={<InfoIcon />}
        isClosedOnRender={metadataSummary}
        right={metadataSummary ? <ActionMetadataSummary actionId={actionId} /> : null}
        dangerouslyCustomizeChildrenPadding={{
          paddingY: DRAWER_SECTION_INNER_PADDING - 1, // To Account for inconsistent Y padding of Metadata
        }}
      >
        <ActionMetadata actionId={actionId} />
      </DrawerSection>

      <DrawerSection
        title="Description"
        icon={<FilePageTemplateIcon />}
        dangerouslyCustomizeChildrenPadding={{
          padding: 0, // Since content is a textarea, padding should be handled by that
        }}
      >
        <ActionDescription actionId={actionId} fillHeight={false} padding={DRAWER_SECTION_INNER_PADDING} />
      </DrawerSection>

      <DrawerSection
        id="followUpActions"
        title="Follow-Up Actions"
        icon={<ActionsIcon />}
        dangerouslyCustomizeChildrenPadding={{
          padding: 0, // Since action table has borders that are full-width, it handles its own padding
        }}
        sx={{ display: hasFollowUpActionsToShow ? undefined : 'none' }}
      >
        <FollowUpActions actionId={actionId} noAddNewAction onHasActionsToShowChange={setHasFollowUpActionsToShow} />
      </DrawerSection>

      <DrawerSection
        id="comments"
        title="Comments"
        icon={<CommentChatIcon />}
        isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasComments}
        right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{commentsLabel}</Text> : null}
        dangerouslyCustomizeChildrenPadding={{
          padding: DRAWER_SECTION_INNER_PADDING - 1, // Since comments have extra padding for hover effects, offset here
        }}
      >
        <ActionComments actionId={actionId} fixedComposer={false} order="newest" fixedAndScroll={false} />
      </DrawerSection>
    </DrawerLayout>
  )
}

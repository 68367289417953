import { isGraphQLError } from '@mm/graphql-helpers/src'
import { produce } from 'immer'
import { remove } from 'lodash'
import {
  ArchiveTopicCommandDocument,
  GenericTopicsDataFragmentDoc,
  TopicMeetingDataFragmentDoc,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'

export type ArchiveTopicCommandValue = {
  topicId: string
  meetingId: string
  onTopicArchive?: () => void
}

export const archiveTopicCommand: Command<ArchiveTopicCommandValue> = {
  id: 'archiveTopicCommand',
  name: 'Archive Topic',
  canExecute: () => true,
  execute: async ({ topicId, meetingId, onTopicArchive }, { apolloClient }) => {
    const meetingData = apolloClient.readFragment({
      fragment: TopicMeetingDataFragmentDoc,
      id: apolloClient.cache.identify({ __typename: 'Meeting', id: meetingId }),
      fragmentName: 'topicMeetingData',
    })

    const sectionId = meetingData?.sections.find(
      (section) =>
        section.__typename === 'MeetingTopicSection' && section.genericTopics.some((topic) => topic.id === topicId),
    )?.id

    const { data } = await apolloClient.mutate({
      mutation: ArchiveTopicCommandDocument,
      variables: {
        id: topicId,
      },
      update: (cache, { data }) => {
        if (data?.archiveTopic.__typename === 'Success') {
          cache.updateFragment(
            {
              id: cache.identify({ __typename: 'MeetingTopicSection', id: sectionId }),
              fragment: GenericTopicsDataFragmentDoc,
            },
            (meeting) =>
              produce(meeting, (meetingDraft) => {
                remove(meetingDraft?.genericTopics || [], (topic) => topic.id === topicId)
              }),
          )
        }
      },
      optimisticResponse: {
        archiveTopic: {
          __typename: 'Success',
        },
      },
    })
    if (data?.archiveTopic.__typename === 'Success') {
      onTopicArchive?.()
    } else if (data?.archiveTopic && isGraphQLError(data?.archiveTopic)) {
      alert(`Archive topic failed: ${data?.archiveTopic.message ?? 'unknown error'}`)
    } else {
      alert('Archive topic failed: unknown error')
    }
  },
}

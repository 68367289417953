import { useTheme } from '@emotion/react'
import { CarretDownIcon } from '@mm/company-ui-icons'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Flex } from '../Flex'
import { SuspenseLoader } from '../suspense'
import { Text } from '../Text'
import { AnimateOpen } from '../Toggler'

export type DrawerSectionProps = {
  id?: string
  title: string
  icon: React.ReactElement
  children?: React.ReactNode
  right?: React.ReactNode
  isClosedOnRender?: boolean
  dangerouslyCustomizeChildrenPadding?: {
    paddingX?: number
    paddingY?: number
    padding?: number
    paddingTop?: number
  }
  className?: string
  hasBorder?: boolean
  isOpen?: boolean
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export const DRAWER_SECTION_INNER_PADDING = 2

export function DrawerSection({
  id,
  children,
  icon,
  title,
  right,
  isClosedOnRender,
  dangerouslyCustomizeChildrenPadding,
  className,
  hasBorder = true,
  isOpen: parentIsOpen,
  setIsOpen: parentSetIsOpen,
}: DrawerSectionProps) {
  const [internalIsOpen, setInternalIsOpen] = React.useState(isClosedOnRender ? false : true)
  const isOpen = parentIsOpen ?? internalIsOpen
  const setIsOpen = parentSetIsOpen ?? setInternalIsOpen

  const theme = useTheme()

  return (
    <Flex
      as="section"
      column
      sx={{ backgroundColor: 'background', borderRadius: 'default' }}
      id={id}
      className={className}
    >
      <Flex
        row
        align="center"
        role="button"
        gap={2}
        sx={{
          color: 'text-light',
          cursor: 'pointer',
          height: 5,
          borderBottom: hasBorder && '1px solid',
          borderBottomColor: isOpen ? 'border' : 'transparent',
          transition: isOpen ? 'border-bottom-color 0ms' : 'border-bottom-color 200ms',
          paddingX: 2,
        }}
        onClick={() => {
          setIsOpen((isOpen) => !isOpen)
        }}
      >
        {icon}
        <Text as="h3" bold sx={{ color: isOpen ? 'text' : 'text-light' }}>
          {title}
        </Text>

        <Flex sx={{ marginRight: 'auto' }} align="center">
          <CarretDownIcon
            sx={{
              transition: theme.transition.default,
              transform: `rotate(${isOpen ? 0 : -180}deg)`,
            }}
          />
        </Flex>

        <AnimatePresence initial={false}>{!isOpen ? right : null}</AnimatePresence>
      </Flex>

      <AnimatePresence initial={false}>
        {isOpen && (
          <AnimateOpen>
            <SuspenseLoader>
              <div sx={{ padding: DRAWER_SECTION_INNER_PADDING, ...dangerouslyCustomizeChildrenPadding }}>
                {children}
              </div>
            </SuspenseLoader>
          </AnimateOpen>
        )}
      </AnimatePresence>
    </Flex>
  )
}

import { Suspender, Tag, Flex, UserSelectTarget } from '@mm/company-ui'
import { EyeViewOpen, LockedIcon } from '@mm/company-ui-icons'
import React from 'react'
import { GoodThingMetadataSummaryDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'

export type GoodThingMetadataSummaryProps = {
  goodThingId: string
  cached?: boolean
}

export const GoodThingMetadataSummary = ({ goodThingId, cached }: GoodThingMetadataSummaryProps) => {
  const { data, loading, error } = useQuery(GoodThingMetadataSummaryDocument, {
    variables: {
      goodThingId,
    },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const goodThing = data?.goodThing

  if (goodThing == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Good thing not found')
  }

  return (
    <Flex row gap={2} align="center">
      <Tag>
        Author
        <UserSelectTarget value={{ node: goodThing.user }} disabled />
      </Tag>

      {goodThing.parent?.privacyLevel == 'PRIVATE' ? (
        <Tag variant="default">
          <LockedIcon />
          Private
        </Tag>
      ) : null}
      {goodThing.parent?.privacyLevel == 'PUBLIC' ? (
        <Tag variant="default">
          <EyeViewOpen />
          Public
        </Tag>
      ) : null}
    </Flex>
  )
}

import { Datepicker, Suspender } from '@mm/company-ui'
import React from 'react'
import { GoalDueDatePickerDocument, GoalDueDatePickerUpdateDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { useSuggestedGoalDueDates } from '../hooks/useSuggestedGoalDueDates'

export type GoalDueDatePickerProps = {
  goalId: string
  cached?: boolean
}

export const GoalDueDatePicker = ({ goalId, cached }: GoalDueDatePickerProps) => {
  const { data, loading, error } = useQuery(GoalDueDatePickerDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      goalId,
    },
  })

  const [updateDueDate] = useMutation(GoalDueDatePickerUpdateDocument, {
    optimisticResponse: ({ goalId, dueAt }) => ({
      updateGoal: {
        __typename: 'Goal' as const,
        id: goalId,
        dueAt,
      },
    }),
  })

  const suggestedDates = useSuggestedGoalDueDates()

  const goal = data?.goal
  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Goal not found')
  }

  return (
    <Datepicker
      suggestedDates={suggestedDates}
      value={goal.dueAt}
      onChange={(dueAt) => {
        if (dueAt) {
          void updateDueDate({
            variables: {
              goalId,
              dueAt: dueAt.getTime(),
            },
          })
        }
      }}
    />
  )
}

import _ from 'lodash'
import { DateTime } from 'luxon'
import { useState } from 'react'

export const useSuggestedGoalDueDates = () => {
  const [suggestedDates] = useState(() => {
    const now = DateTime.now()
    return _.range(0, 4).map((quarter) => {
      const date = now.plus({ quarter }).endOf('quarter')
      return {
        date: date.toJSDate(),
        label: date.toFormat("yy'Q'q"),
        description: date.toFormat("'End of Q'q y - LLL d"),
      }
    })
  })
  return suggestedDates
}

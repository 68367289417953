import {
  Avatar,
  ButtonGroup,
  Flex,
  formatUserDisplayName,
  Heading,
  NotFoundBanner,
  Panel,
  PanelHeader,
  Suspender,
  TabBar,
  Text,
} from '@mm/company-ui'
import React, { useState } from 'react'
import { SingleProfileDocument, SingleProfileSubscriptionDocument } from '../../../gen/graphql/documents'
import { UserActions } from '../../actions'
import { useQuery, useSubscription } from '../../apollo'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { useFeatureFlags } from '../../featureFlags'
import { UserGoalsDashboard } from '../../goals'
import { ProfileBetaStatus } from './ProfileBetaStatus'
import { SimpleSingleUser } from './SimpleSingleUser'

export type SingleProfileProps = {
  id: string
}

type TabKey = 'actions' | 'waiting-from-others' | 'goals'

export const SingleProfile = ({ id }: SingleProfileProps) => {
  const { simpleUserProfile } = useFeatureFlags()
  return simpleUserProfile ? <SimpleSingleUser id={id} /> : <OldSingleProfile id={id} />
}

export const OldSingleProfile = ({ id }: SingleProfileProps) => {
  const { data, loading } = useQuery(SingleProfileDocument, {
    variables: {
      id,
    },
  })

  useSubscription(SingleProfileSubscriptionDocument, {
    variables: {
      id,
    },
  })

  const [activeTab, setActiveTab] = useState<TabKey>('actions')

  const user = data?.user
  if (user == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return (
    <Panel
      header={
        <PanelHeader height={68}>
          <Flex column grow>
            <Flex grow justify="space-between" gap={1.5} sx={{ paddingY: 1 }}>
              <Flex row gap={3}>
                <Avatar size="large" name={formatUserDisplayName(user)} />

                <Flex column gap={0.5}>
                  <Heading level={3}>{formatUserDisplayName(user)}</Heading>
                  <Text color="text-light">{user.email}</Text>
                  <ProfileBetaStatus id={id} />
                </Flex>
              </Flex>

              <ButtonGroup>
                <CloseDrawerButton resourceType="profile" />

                <ExpandCollapseToggle resourceType="profile" id={user.id} />
              </ButtonGroup>
            </Flex>
            <TabBar
              sx={{ marginBottom: -1.5 }}
              tabs={[
                {
                  label: 'Actions',
                  key: 'actions',
                },
                {
                  label: 'Waiting from others',
                  key: 'waiting-from-others',
                },
                {
                  label: 'Goals',
                  key: 'goals',
                },
              ]}
              activeTabKey={activeTab}
              onTabChange={(key: TabKey) => {
                setActiveTab(key)
              }}
            />
          </Flex>
        </PanelHeader>
      }
      sx={{ flex: 1 }}
    >
      {activeTab === 'actions' || activeTab === 'waiting-from-others' ? (
        <UserActions tab={activeTab} selectedUserId={user.id} />
      ) : null}
      {activeTab === 'goals' && <UserGoalsDashboard userId={user.id} />}
    </Panel>
  )
}

import { formatListCountDisplay, useFeatureFlags } from '@mm/company-ui'
import { produce } from 'immer'
import { ActionsForTotalsFragmentFragmentDoc } from '../../gen/graphql/documents'
import { ActionsUpdatedTopic } from '../actions'
import { useApolloClient, useFragment_experimental } from '../apollo'
import { useOnEventBusEvent } from '../eventbus'

export type UseActionsForTotalsParent = {
  __typename: string
  id: string
}

export type UseActionsForTotalsResult = {
  hasActions: boolean
  actionsLabel?: string
  latestActionDate?: number
}

export const useTotalActions = (parent?: UseActionsForTotalsParent | null): UseActionsForTotalsResult => {
  const { tableRowDetails } = useFeatureFlags()
  const client = useApolloClient()

  const fragmentId = client.cache.identify(parent ?? {})

  const { data } = useFragment_experimental({
    fragment: ActionsForTotalsFragmentFragmentDoc,
    fragmentName: 'ActionsForTotalsFragment',
    from: fragmentId ?? {},
  })

  useOnEventBusEvent(ActionsUpdatedTopic, (event) => {
    if (data == null || event.type !== 'upserted') return
    const { action } = event

    if (action.parent?.id === data.id) {
      client.cache.updateFragment(
        {
          fragment: ActionsForTotalsFragmentFragmentDoc,
          id: fragmentId,
        },
        (data) =>
          produce(data, (draft) => {
            if (draft?.actions?.edges?.every(({ node }) => node.id !== action.id)) {
              draft.actions.edges.unshift({ node: action })
            }
          }),
      )
    }
  })

  if (!data) {
    return { hasActions: false }
  }

  const actionsLabel = formatListCountDisplay(data.actions, {
    label: 'actions',
    singular: 'action',
    empty: tableRowDetails ? '' : '0 actions',
  })
  const latestActionDate = data.actions?.edges[0]?.node?.createdAt

  return { actionsLabel, hasActions: data.actions != null && data.actions.edges.length > 0, latestActionDate }
}

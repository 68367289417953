import { Suspender } from '@mm/company-ui'
import React from 'react'
import { GoalMetadataPanelDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useSyncActiveCompanyWithResource } from '../../companies'
import { SingleGoalPage } from './SingleGoalPage'

export type SingleGoalProps = {
  id: string
}

export const SingleGoal = ({ id }: SingleGoalProps) => {
  const { data, loading, error } = useQuery(GoalMetadataPanelDocument, {
    variables: {
      id,
    },
  })
  useSyncActiveCompanyWithResource(data?.goal?.company?.id)

  const goal = data?.goal
  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('goal not found')
  }

  return <SingleGoalPage goalId={id} />
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const CircleCheckmarkIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="circle-checkmark" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66667 10C1.66667 5.39763 5.39763 1.66667 10 1.66667C14.6024 1.66667 18.3333 5.39763 18.3333 10C18.3333 14.6024 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM15.5891 7.60189C15.9145 7.27646 15.9145 6.74882 15.5891 6.42338C15.2637 6.09795 14.736 6.09795 14.4106 6.42338L8.33317 12.5008L5.58909 9.75672C5.26366 9.43128 4.73602 9.43128 4.41058 9.75672C4.08514 10.0822 4.08514 10.6098 4.41058 10.9352L7.74392 14.2686C8.06935 14.594 8.59699 14.594 8.92243 14.2686L15.5891 7.60189Z"
    />
  </IconBase>
)

import React from 'react'
import { ActionMetadataAssigneeDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { editActionAssigneeCommand } from '../commands/editActionAssigneeComand'

export type ActionMetadataAssigneeProps = {
  actionId: string
  cached?: boolean
}

export const ActionMetadataAssignee = ({ actionId, cached }: ActionMetadataAssigneeProps) => {
  const { data } = useQuery(ActionMetadataAssigneeDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id: actionId,
    },
  })
  const editActionAssignee = useCommand(editActionAssigneeCommand)

  const handlePickerSelectUser = (value: string) => {
    void editActionAssignee.execute({ actionId, assigneeId: value })
  }

  return (
    <UserSelectWithCompanyMembers
      aria-label="Assignee"
      value={data?.action?.assignee.id}
      onAdd={handlePickerSelectUser}
      withName={true}
    />
  )
}

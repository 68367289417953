import { Paragraph } from '@tiptap/extension-paragraph'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

export const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      placeholder: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-placeholder'),
        renderHTML: (attributes) => {
          if (!attributes['placeholder']) {
            return {}
          }

          return {
            'data-placeholder': attributes['placeholder'] as string,
          }
        },
        keepOnSplit: false,
      },
    }
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: ({ doc }) => {
            const decorations: Decoration[] = []

            doc.descendants((node, pos) => {
              if (node.type.isBlock && node.childCount === 0) {
                decorations.push(
                  Decoration.node(pos, pos + node.nodeSize, {
                    class: 'is-empty',
                  }),
                )
              }
            })

            return DecorationSet.create(doc, decorations)
          },
        },
      }),
    ]
  },
})

const AT_RISK = 'AT_RISK'
const ON_TRACK = 'ON_TRACK'
const BEHIND = 'BEHIND'
const DONE = 'DONE'
const CANCELLED = 'CANCELLED'

export const goalStatusMap = {
  AT_RISK,
  ON_TRACK,
  BEHIND,
  DONE,
  CANCELLED,
} as const

export type GoalStatus = (typeof goalStatusMap)[keyof typeof goalStatusMap]

export const openGoalStatuses: GoalStatus[] = [AT_RISK, ON_TRACK, BEHIND]
export const closedGoalStatuses: GoalStatus[] = [CANCELLED, DONE]
export const allGoalStatuses: GoalStatus[] = [ON_TRACK, AT_RISK, BEHIND, DONE, CANCELLED]

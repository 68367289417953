import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const LockedIcon = (props: OptionalIconBaseProps) => {
  return (
    <IconBase name="locked" viewBox="0 0 20 20" {...props}>
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M10.089 0.100195H10.0041C8.71693 0.0886316 7.47768 0.588096 6.55826 1.48913C5.63773 2.39125 5.11313 3.62206 5.09991 4.91087V7.99999H2.85696C2.38357 7.99999 1.99982 8.40977 1.99982 8.91525V19.0847C1.99982 19.5902 2.38357 20 2.85696 20H17.1427C17.6161 20 17.9998 19.5902 17.9998 19.0847V8.91525C17.9998 8.40977 17.6161 7.99999 17.1427 7.99999L14.8997 8V5.00431C14.9113 3.71719 14.4118 2.47795 13.5108 1.55854C12.6087 0.638007 11.3779 0.113408 10.089 0.100195ZM13.0997 8V4.99114C13.1078 4.17959 12.7933 3.39806 12.2252 2.81842C11.6582 2.2398 10.8849 1.90964 10.0749 1.90019H9.99086C9.17931 1.89212 8.39778 2.20665 7.81814 2.77471C7.23952 3.34176 6.90936 4.11503 6.89991 4.92501V7.99999L13.0997 8ZM3.7141 18.1695V9.8305H16.2855V18.1695H3.7141Z"
      />
    </IconBase>
  )
}

import { PlusIcon, CloseSmallIcon } from '@mm/company-ui-icons/src'
import { Button, Flex, FlyoutItem, Popover, PopupWrapper, Text } from '@mm/company-ui/src'
import React, { useEffect, useState } from 'react'
import { FilterConfig, FilterValue, useDisplayFilter } from './DisplayFilterContext'

export enum FilterType {
  UserPicker,
  Meeting,
  Status,
  Date,
}

export type Filter = {
  label: string
  icon?: React.ReactElement
  filterType: FilterType
  fieldName: string
  unRemovable?: boolean
  inputRenderFn?: (value?: FilterValue, onChange?: (value: FilterValue) => void) => React.ReactElement
}

export type SelectedFilter = Filter & {
  value?: FilterValue
}

type DisplayFilterProps = {
  availableFilters: Filter[]
  onChange?: (filter: FilterConfig) => void
  initialFilters?: FilterConfig
  className?: string
}

// TODO refactor this component for better typing and readability
export const DisplayFilter = ({ availableFilters = [], onChange, initialFilters, className }: DisplayFilterProps) => {
  const { filter, setFilter } = useDisplayFilter()

  const accValue: SelectedFilter[] = []
  const defaultFilters: SelectedFilter[] = availableFilters.reduce((acc, availableFilter) => {
    const filterValue = initialFilters ? initialFilters[availableFilter.fieldName] : filter[availableFilter.fieldName]
    if (filterValue) {
      acc.push({ ...availableFilter, value: filterValue })
    }
    return acc
  }, accValue)

  useEffect(() => {
    if (initialFilters == null) return
    setSelectedFilters((selectedFilters) => {
      return selectedFilters.map((selectedFilter) => {
        const filterValue = initialFilters ? initialFilters[selectedFilter.fieldName] : ''
        if (filterValue && filterValue.length) {
          return { ...selectedFilter, value: filterValue }
        }
        return selectedFilter
      })
    })
  }, [initialFilters])

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>(defaultFilters)
  const handleFilterTypeSelection = (filter: Filter) => {
    setSelectedFilters((selectedFilters) => [...selectedFilters, { ...filter, value: undefined }])
  }

  // TODO move this outside the component?
  useEffect(() => {
    if (selectedFilters.length === 0) {
      setFilter({})
    }
    if (selectedFilters.length > 0 && selectedFilters[selectedFilters.length - 1]?.value !== undefined) {
      const initialValue: Record<string, string | string[]> = {}
      const filters: Record<string, FilterValue> = selectedFilters.reduce((acc, filter) => {
        if (!filter.value) return acc
        acc[filter.fieldName] = filter.value
        return acc
      }, initialValue)
      setFilter(filters)
      onChange?.(filters)
    }
  }, [onChange, selectedFilters, setFilter])

  return (
    <>
      <Flex align="center" justify="center" gap={1}>
        {selectedFilters?.map((filter, index) => {
          return (
            <Flex
              className={className}
              align="center"
              justify="center"
              sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium' }}
              key={index}
            >
              <Flex sx={{ py: 1, paddingLeft: 1 }} gap={0.5}>
                {filter.icon}
                {filter.label} :
              </Flex>
              {filter.inputRenderFn?.(filter.value, (value) => {
                setSelectedFilters((filters) => filters.map((f, i) => (i === index ? { ...f, value } : f)))
              })}

              {!filter?.unRemovable && (
                <Flex
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSelectedFilters((filters) => filters.filter((_, i) => i !== index))
                  }}
                >
                  <CloseSmallIcon />
                </Flex>
              )}
            </Flex>
          )
        })}
        {availableFilters.length === selectedFilters.length ? null : (
          <Popover
            placement="bottom-start"
            render={({ hide }) => {
              return (
                <PopupWrapper sx={{ width: 150, backgroundColor: 'background-light', borderRadius: 8 }}>
                  <Flex column gap={2}>
                    <Flex gap={0} column>
                      {availableFilters?.map((filter, i) =>
                        selectedFilters.some(({ fieldName }) => fieldName === filter.fieldName) ? null : (
                          <FlyoutItem
                            onClick={() => {
                              handleFilterTypeSelection(filter)
                              hide()
                            }}
                            key={i}
                            startIcon={filter.icon}
                            sx={{ minWidth: 100, backgroundColor: 'background-light', px: 1 }}
                          >
                            <Text variant="body"> {filter.label}</Text>
                          </FlyoutItem>
                        ),
                      )}
                    </Flex>
                  </Flex>
                </PopupWrapper>
              )
            }}
          >
            {({ getReferenceProps }) => (
              <Button {...getReferenceProps()} size="small" startIcon={<PlusIcon />} sx={{ borderRadius: 'medium' }}>
                Filter
              </Button>
            )}
          </Popover>
        )}
      </Flex>
    </>
  )
}

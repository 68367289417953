import { useCallback, useEffect, useMemo, useRef } from 'react'

export type UseDebouncedExecutorControls = {
  flush: () => void
  cancel: () => void
}

export type UseDebouncedExecutorOptions = {
  timeout: number
  onUnmount?: 'cancel' | 'flush'
}

export type UseDebouncedExecutorResult = [(callback: () => void) => void, UseDebouncedExecutorControls]

export const useDebouncedExecutor = ({
  timeout,
  onUnmount = 'cancel',
}: UseDebouncedExecutorOptions): UseDebouncedExecutorResult => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const latestInvocationRef = useRef<() => void>()
  const onUnmountRef = useRef(onUnmount)

  useEffect(() => {
    onUnmountRef.current = onUnmount
  }, [onUnmount])

  const commit = (flush: boolean) => {
    const latestTimeout = timeoutRef.current
    timeoutRef.current = undefined
    if (latestTimeout != null) {
      clearTimeout(latestTimeout)
    }

    const latestInvocation = latestInvocationRef.current
    latestInvocationRef.current = undefined
    if (flush) {
      latestInvocation?.()
    }
  }

  const controls = useMemo<UseDebouncedExecutorControls>(
    () => ({
      flush: () => {
        commit(true)
      },
      cancel: () => {
        commit(false)
      },
    }),
    [],
  )

  useEffect(
    () => () => {
      commit(onUnmountRef.current === 'flush')
    },
    [],
  )

  return [
    useCallback(
      (callback: () => void) => {
        commit(false)
        latestInvocationRef.current = callback
        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = undefined
          commit(true)
        }, timeout)
      },
      [timeout],
    ),
    controls,
  ]
}

import { EditPrototypeFeedbackScoreCommandDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditFeedbackScoreCommandValue = {
  prototypeFeedbackId: string
  score: number
}

export const editFeedbackScoreCommand: Command<EditFeedbackScoreCommandValue> = {
  id: 'editFeedbackScoreCommand',
  name: 'Edit Feedback Score',
  canExecute: () => true,
  execute: async ({ prototypeFeedbackId, score }, { apolloClient }) => {
    await apolloClient.mutate({
      mutation: EditPrototypeFeedbackScoreCommandDocument,
      variables: {
        prototypeFeedbackId,
        score,
      },
      optimisticResponse: {
        updatePrototypeFeedback: {
          __typename: 'PrototypeFeedback',
          id: prototypeFeedbackId,
          score,
        },
      },
    })

    return
  },
}

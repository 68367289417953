import { PlusIcon } from '@mm/company-ui-icons/src'
import {
  useConfirmPopover,
  PopupWrapper,
  Tag,
  ConfirmPopover,
  Popover,
  Button,
  useFeatureFlags,
} from '@mm/company-ui/src'
import { isGraphQLError } from '@mm/graphql-helpers/src'
import React, { useCallback } from 'react'
import {
  UpdateActionParentDocument,
  ActionContextDataFragmentDoc,
  SearchResultRowFragment,
} from '../../../gen/graphql/documents'
import { useMutation, useFragment_experimental } from '../../apollo'
import { ContextSearchPanel } from '../../search/components/ContextSearchPanel'

export const AddActionContextButton = ({
  actionId,
  isTagButton = false,
}: {
  actionId: string
  isTagButton?: boolean
}) => {
  const { triggerConfirm, confirmControlProps } = useConfirmPopover()
  const [updateActionParent] = useMutation(UpdateActionParentDocument)

  const { actionConsolidatedMetadata } = useFeatureFlags()

  const { data: actionData } = useFragment_experimental({
    fragment: ActionContextDataFragmentDoc,
    fragmentName: 'actionContextData',
    from: {
      __typename: 'Action',
      id: actionId,
    },
  })

  const isActionPrivate = actionData?.isPrivate

  const handleRowClick = useCallback(
    async ({ node: { id, __typename, title, isPrivate } }: SearchResultRowFragment) => {
      if (!__typename || __typename === 'Action') return

      const isExistingMeeting =
        __typename === 'Meeting' && actionData?.meetings?.edges.some(({ node }) => node.id === id)
      const isExistingGoal = __typename === 'Goal' && actionData?.goals?.edges.some(({ node }) => node.id === id)
      const isExistingDecision =
        __typename === 'Decision' && actionData?.decisions?.edges.some(({ node }) => node.id === id)
      const isExistingTopic = __typename === 'Topic' && actionData?.topics?.edges.some(({ node }) => node.id === id)

      if (isExistingMeeting || isExistingDecision || isExistingGoal || isExistingTopic) return

      if (!isPrivate && isActionPrivate) {
        if (!(await triggerConfirm())) return
      }
      const data = await updateActionParent({
        variables: {
          id: actionId,
          context: {
            ref: id,
            type: __typename,
          },
        },
        optimisticResponse: {
          addActionContext: {
            id: actionId,
            isPrivate: isActionPrivate,
            __typename: 'Action',

            ...(__typename === 'Decision' && {
              decisions: {
                edges: [
                  ...(actionData?.decisions?.edges || []),
                  {
                    node: {
                      id,
                      __typename,
                      title,
                    },
                  },
                ],
              },
            }),

            ...(__typename === 'Goal' && {
              goals: {
                edges: [
                  ...(actionData?.goals?.edges || []),
                  {
                    node: {
                      id,
                      __typename,
                      title,
                    },
                  },
                ],
              },
            }),

            ...(__typename === 'Meeting' && {
              meetings: {
                edges: [
                  ...(actionData?.meetings?.edges || []),
                  {
                    node: {
                      id,
                      __typename,
                      title,
                    },
                  },
                ],
              },
            }),

            ...(__typename === 'Topic' && {
              topics: {
                edges: [
                  ...(actionData?.topics?.edges || []),
                  {
                    node: {
                      id,
                      __typename,
                      title,
                    },
                  },
                ],
              },
            }),
          },
        },
      })

      if (data.data?.addActionContext.__typename && isGraphQLError(data.data.addActionContext.__typename)) {
        alert(`Context addition failed : ${data.data.addActionContext.__typename}`)
      } else if (data.data?.addActionContext.__typename !== 'Action') {
        alert(`Context added failed: unknown error`)
      }
    },
    [actionData, actionId, isActionPrivate, triggerConfirm, updateActionParent],
  )

  return (
    <>
      <Popover
        placement="bottom-start"
        render={({ hide }) => {
          return (
            <PopupWrapper
              sx={{ width: 500, maxHeight: 500, backgroundColor: 'background-light', borderRadius: 'medium' }}
            >
              <ContextSearchPanel
                handleRowClick={async (item) => {
                  hide()
                  await handleRowClick(item)
                }}
              />
            </PopupWrapper>
          )
        }}
      >
        {({ getReferenceProps }) =>
          isTagButton ? (
            <Tag sx={{ width: 'fit-content' }} {...getReferenceProps()}>
              <PlusIcon /> Add context
            </Tag>
          ) : (
            <Button
              size="small"
              {...getReferenceProps()}
              sx={{ width: 'fit-content', marginY: actionConsolidatedMetadata ? 1 : undefined }}
              startIcon={<PlusIcon width={1.5} height={1.5} />}
            >
              {actionConsolidatedMetadata ? 'Add related' : 'Add context'}
            </Button>
          )
        }
      </Popover>
      <ConfirmPopover
        {...confirmControlProps}
        zIndex={10000}
        title="You're adding this action to a public item"
        description="This will make the action public, Proceed?"
        confirmLabel="Yes"
        confirmVariant="accent"
      />
    </>
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const UnderlineIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="underline" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.55459 1.99961C2.55459 1.50255 2.95753 1.09961 3.45459 1.09961H4.90918H6.36368C6.86074 1.09961 7.26368 1.50255 7.26368 1.99961C7.26368 2.49667 6.86074 2.89961 6.36368 2.89961H5.80918V8.99962C5.80918 11.3142 7.68551 13.1905 10.0001 13.1905C12.3147 13.1905 14.191 11.3142 14.191 8.99962V2.89961H13.6362C13.1392 2.89961 12.7362 2.49667 12.7362 1.99961C12.7362 1.50255 13.1392 1.09961 13.6362 1.09961H15.091H16.5453C17.0424 1.09961 17.4453 1.50255 17.4453 1.99961C17.4453 2.49667 17.0424 2.89961 16.5453 2.89961H15.991V8.99962C15.991 12.3083 13.3088 14.9905 10.0001 14.9905C6.6914 14.9905 4.00918 12.3083 4.00918 8.99962V2.89961H3.45459C2.95753 2.89961 2.55459 2.49667 2.55459 1.99961ZM3.6001 17.0996C3.10304 17.0996 2.7001 17.5026 2.7001 17.9996C2.7001 18.4967 3.10304 18.8996 3.6001 18.8996H16.4001C16.8972 18.8996 17.3001 18.4967 17.3001 17.9996C17.3001 17.5026 16.8972 17.0996 16.4001 17.0996H3.6001Z"
    />
  </IconBase>
)

import { Button } from '@mm/company-ui'
import { LockedIcon, UnlockedIcon } from '@mm/company-ui-icons'
import React from 'react'

export type PrivacyChipProps = {
  privacyLevel: 'PRIVATE' | 'PUBLIC'
  disabled?: boolean
  noIcon?: boolean
  noLabel?: boolean
  fontType?: 'normal' | 'bold'
}

export const PrivacyChip = ({ privacyLevel, disabled, noIcon, noLabel, fontType = 'bold' }: PrivacyChipProps) => {
  const { label, icon } = {
    PRIVATE: {
      label: 'Private',
      icon: <LockedIcon />,
    },
    PUBLIC: {
      label: 'Public',
      icon: <UnlockedIcon />,
    },
  }[privacyLevel]

  return (
    <Button
      variant="muted"
      size="small"
      disabled={disabled}
      startIcon={noIcon ? undefined : icon}
      sx={
        fontType === 'normal'
          ? {
              fontWeight: 'normal',
              color: 'text',
            }
          : {}
      }
      textHidden={noLabel}
    >
      {label}
    </Button>
  )
}

import { StatusCheckbox, Suspender, Tooltip } from '@mm/company-ui'
import React, { useCallback } from 'react'
import { GetTopicStatusCommandDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { editTopicStatusCommand } from '../commands/editTopicStatusCommand'

export type TopicStatusCheckboxProps = {
  id: string
  withLabel?: boolean
  withDropdown?: boolean
  cached?: boolean
}

const topicStatuses = ['active', 'resolved'] as const

export const TopicStatusCheckbox = ({ id, withLabel, withDropdown, cached }: TopicStatusCheckboxProps) => {
  const { data, error, loading } = useQuery(GetTopicStatusCommandDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })

  const editTopicStatus = useCommand(editTopicStatusCommand)

  const handleChange = useCallback(
    (checkboxStatus: (typeof topicStatuses)[number]) => {
      const status = checkboxStatus == 'resolved' ? 'DISCUSSED' : 'UNDISCUSSED'

      return editTopicStatus.execute({ topicId: id, status })
    },
    [editTopicStatus, id],
  )

  if (!data?.topic) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Topic not found')
  }

  return (
    <Tooltip description="Mark discussed" position="bottom">
      <StatusCheckbox
        type="topic"
        options={topicStatuses}
        value={data.topic.status == 'DISCUSSED' ? 'resolved' : 'active'}
        withLabel={withLabel}
        withDropdown={withDropdown}
        shape="square"
        onChange={handleChange}
      />
    </Tooltip>
  )
}

import { isGraphQLError } from '@mm/graphql-helpers/src'
import { produce } from 'immer'
import { remove } from 'lodash'
import {
  DetachTopicFromMeetingCommandDocument,
  GenericTopicsDataFragmentDoc,
  GetTopicCommandDocument,
  TopicMeetingDataFragmentDoc,
  TopicMoreMenuDataFragmentDoc,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type DetachTopicFromMeetingCommandValue = {
  topicId: string
  meetingId: string
  onTopicDetach?: () => void
}

export const detachTopicFromMeetingCommand: Command<DetachTopicFromMeetingCommandValue> = {
  id: 'detachTopicFromMeetingCommand',
  name: 'Detach Topic From Meeting',
  canExecute: async ({ topicId }, { apolloClient }) => {
    const query = await apolloClient.query({ query: GetTopicCommandDocument, variables: { id: topicId } })
    return !!query.data.topic
  },
  execute: async ({ topicId, meetingId, onTopicDetach }, { apolloClient }) => {
    const meetingData = apolloClient.readFragment({
      fragment: TopicMeetingDataFragmentDoc,
      id: apolloClient.cache.identify({ __typename: 'Meeting', id: meetingId }),
      fragmentName: 'topicMeetingData',
    })

    const sectionId = meetingData?.sections.find(
      (section) =>
        section.__typename === 'MeetingTopicSection' && section.genericTopics.some((topic) => topic.id === topicId),
    )?.id

    const { data } = await apolloClient.mutate({
      mutation: DetachTopicFromMeetingCommandDocument,
      variables: {
        id: topicId,
        meetingId,
      },
      update: (cache, { data }) => {
        if (data?.detachTopicFromMeeting.__typename === 'Success') {
          cache.updateFragment(
            {
              id: cache.identify({ __typename: 'MeetingTopicSection', id: sectionId }),
              fragment: GenericTopicsDataFragmentDoc,
            },
            (meeting) =>
              produce(meeting, (meetingDraft) => {
                remove(meetingDraft?.genericTopics || [], (topic) => topic.id === topicId)
              }),
          ),
            cache.updateFragment(
              {
                id: cache.identify({ __typename: 'Topic', id: topicId }),
                fragment: TopicMoreMenuDataFragmentDoc,
              },
              (topic) =>
                produce(topic, (topicDraft) => {
                  remove(topicDraft?.meetings?.edges || [], (edge) => edge.node.id === meetingId)
                }),
            )
        }
      },
      optimisticResponse: {
        detachTopicFromMeeting: {
          __typename: 'Success',
        },
      },
    })
    if (data?.detachTopicFromMeeting.__typename === 'Success') {
      onTopicDetach?.()
    } else if (data?.detachTopicFromMeeting && isGraphQLError(data?.detachTopicFromMeeting)) {
      alert(`Delete topic failed: ${data?.detachTopicFromMeeting.message ?? 'unknown error'}`)
    } else {
      alert('Delete topic failed: unknown error')
    }
  },
}

import { TagSelect, Tag, Tooltip, Suspender } from '@mm/company-ui'
import { LockedIcon, UnlockedIcon } from '@mm/company-ui-icons'
import React from 'react'
import { FeedbackPrivacySelectDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { editFeedbackPrivacyCommand } from '../commands/editFeedbackPrivacyCommand'

export type FeedbackPrivacySelectProps = {
  feedbackId: string
  className?: string
  cached?: boolean
}

export const FeedbackPrivacySelect = ({ feedbackId, className, cached }: FeedbackPrivacySelectProps) => {
  const { data, loading, error } = useQuery(FeedbackPrivacySelectDocument, {
    variables: { id: feedbackId },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const feedback = data?.prototypeFeedback
  const editPrivacy = useCommand(editFeedbackPrivacyCommand)

  const isParentMeetingPublic = feedback?.parent?.__typename == 'Meeting' && feedback.parent.privacyLevel === 'PUBLIC'

  if (feedback == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('feedback not found: ' + feedbackId)
  }

  return (
    <Tooltip
      position="bottom-start"
      description={
        isParentMeetingPublic
          ? "This feedback is public because it's part of a public Meeting. To change its visibility, update the privacy setting of the Meeting"
          : feedback.isPrivate
          ? 'Private - Visible to participants of meeting this feedback is linked to'
          : 'Public - Visible to everyone at the company'
      }
      sx={{ maxWidth: '300px', fontSize: 1, lineHeight: 1.5 }}
    >
      <TagSelect
        value={isParentMeetingPublic ? 'PUBLIC' : feedback.isPrivate ? 'PRIVATE' : 'PUBLIC'}
        onChange={(privacy) => {
          void editPrivacy.execute({
            prototypeFeedbackId: feedbackId,
            privacy,
          })
        }}
      >
        <Tag disabled={isParentMeetingPublic} value="PRIVATE" variant="default" className={className}>
          <LockedIcon />
          Private
        </Tag>
        <Tag disabled={isParentMeetingPublic} value="PUBLIC" variant="default" className={className}>
          <UnlockedIcon />
          Public
        </Tag>
      </TagSelect>
    </Tooltip>
  )
}

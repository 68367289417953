import { Suspender, useFeatureFlags } from '@mm/company-ui'
import { DecisionIcon, LinkIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { ActionMetadataDecisionsDocument, RemoveActionDecisionContextDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { PanelMetadataItemChips } from '../../components'
import { PanelMetadataLinkChips } from '../../components/PanelMetadataLinkChips'

export const ActionMetadataDecisions = ({ actionId, isLink }: { actionId: string; isLink?: boolean }) => {
  const { data, loading, error, fetchMore } = useQuery(ActionMetadataDecisionsDocument, {
    variables: {
      id: actionId,
    },
  })
  const { updateActionContext } = useFeatureFlags()
  const [removeActionContext] = useMutation(RemoveActionDecisionContextDocument)
  const decisions = data?.action?.decisions
  const handleRemove = useCallback(
    async (id: string) => {
      await removeActionContext({
        variables: {
          id: actionId,
          contextRef: id,
        },
        optimisticResponse: {
          removeActionContext: {
            id: actionId,
            __typename: 'Action',
            decisions: {
              __typename: 'DecisionsConnection',
              edges: decisions?.edges.filter(({ node }) => node.id !== id) || [],
              pageInfo: {
                hasNextPage: decisions?.pageInfo.hasNextPage ?? false,
                endCursor: decisions?.pageInfo.endCursor ?? '',
                __typename: decisions?.pageInfo.__typename,
              },
            },
          },
        },
      })
    },
    [actionId, decisions, removeActionContext],
  )

  if (error) {
    throw error
  }

  if (loading) {
    return <Suspender />
  }

  if (isLink) {
    return decisions?.edges.length ? (
      <PanelMetadataLinkChips
        icon={<DecisionIcon color="text-light" />}
        chips={decisions.edges.map(({ node }) => ({
          id: node.id,
          label: node.title,
          href: { pathname: '/decisions/[id]', query: { id: node.id } },
          type: 'Decision',
        }))}
        hasNextPage={decisions.pageInfo.hasNextPage}
        onLoadMore={() => fetchMore({ variables: { after: decisions.pageInfo.endCursor } })}
        onRemove={handleRemove}
      />
    ) : null
  }

  return decisions?.edges.length ? (
    <PanelMetadataItemChips
      title="Issues"
      icon={<LinkIcon />}
      chips={decisions.edges.map(({ node }) => ({
        id: node.id,
        label: node.title,
        href: { pathname: '/decisions/[id]', query: { id: node.id } },
        __typename: node.__typename,
      }))}
      hasNextPage={decisions.pageInfo.hasNextPage}
      onLoadMore={() => fetchMore({ variables: { after: decisions.pageInfo.endCursor } })}
      isRemovable={updateActionContext}
      onRemove={handleRemove}
    />
  ) : null
}

import { Flex, formatDateDisplay, NotFoundBanner, Suspender, Text } from '@mm/company-ui'
import Link from 'next/link'
import React from 'react'
import { MeetingPrepViewDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../../modules/apollo'
import { ActionUpdateListItem } from '../../accountability/components/ActionUpdateListItem'
import { GoalUpdateListItem } from '../../accountability/components/GoalUpdateListItem'
import { CloseDrawerButton } from '../../components'
import { GoodThingRow } from './GoodThingRow'
import { MeetingPrepComments } from './MeetingPrepComments'
import { TopicRow } from './TopicRow'

export type MeetingPrepViewProps = {
  meetingPrepId: string
  inDrawer?: boolean
}

export function MeetingPrepView({ meetingPrepId, inDrawer }: MeetingPrepViewProps) {
  const { data, loading } = useQuery(MeetingPrepViewDocument, {
    variables: {
      meetingPrepId: meetingPrepId,
    },
  })

  const meetingPrep = data?.meetingPrep

  if (meetingPrep == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return (
    <>
      {inDrawer ? (
        <Flex row sx={{ height: 7, gap: 1, paddingX: 2.5 }} align="center">
          <CloseDrawerButton resourceType="accountability/updates" />
        </Flex>
      ) : (
        <Flex row sx={{ height: 7, gap: 1, paddingX: 2.5 }} align="center">
          <Link href="/updates">
            <Text
              color="text-light"
              variant="small"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Updates
            </Text>
          </Link>
          <Text color="text-light">›</Text>
          <Text color="text-light" variant="small" sx={{ maxWidth: 150 }} overflow="ellipsis">
            {meetingPrep.title}
          </Text>
        </Flex>
      )}

      <div sx={{ width: '100%', maxWidth: '800px', paddingX: 2, paddingBottom: 4, marginX: 'auto' }}>
        <Text as="h3" variant="h3">
          {meetingPrep.title}
        </Text>
        <Text variant="small" color="text-light">
          {formatDateDisplay(meetingPrep.createdAt, 'SHORT_MONTH_WITH_DATE')}
        </Text>

        <Text
          as="h4"
          variant="small"
          uppercase
          color="text-light"
          bold
          sx={{
            marginTop: 3,
            marginBottom: 1,
          }}
        >
          Actions Review
        </Text>
        <Flex column gap={2}>
          {meetingPrep.actionUpdates?.map((actionUpdate) => (
            <ActionUpdateListItem key={actionUpdate.action.id} actionUpdate={actionUpdate} type="action" />
          ))}
        </Flex>

        <Text
          as="h4"
          variant="small"
          uppercase
          color="text-light"
          bold
          sx={{
            marginTop: 6,
            marginBottom: 1,
          }}
        >
          Goals Review
        </Text>
        <Flex column gap={2}>
          {meetingPrep.goalUpdates?.map((goalUpdate) => (
            <GoalUpdateListItem
              key={goalUpdate.goal.id}
              goalUpdate={goalUpdate}
              updateCreatedAt={meetingPrep.createdAt}
              type="goal"
            />
          ))}
        </Flex>

        <Text
          as="h4"
          variant="small"
          uppercase
          color="text-light"
          bold
          sx={{
            marginTop: 6,
            marginBottom: 1,
          }}
        >
          Good Things
        </Text>

        <Flex column sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium' }}>
          {meetingPrep.goodThings?.map((goodThing, i, array) => (
            <GoodThingRow key={goodThing.id} goodThing={goodThing} hasBorder={i + 1 !== array.length} />
          ))}
        </Flex>

        <Text
          as="h4"
          variant="small"
          uppercase
          color="text-light"
          bold
          sx={{
            marginTop: 6,
            marginBottom: 1,
          }}
        >
          Topics
        </Text>

        <Flex column sx={{ border: '1px solid', borderColor: 'border', borderRadius: 'medium' }}>
          {meetingPrep.topics?.map((topic, i, array) => (
            <TopicRow key={topic.id} topic={topic} hasBorder={i + 1 !== array.length} />
          ))}
        </Flex>

        <Text
          as="h4"
          variant="small"
          uppercase
          color="text-light"
          bold
          sx={{
            marginTop: 6,
            marginBottom: 1,
          }}
        >
          Comments
        </Text>

        <MeetingPrepComments meetingPrepId={meetingPrepId} />
      </div>
    </>
  )
}

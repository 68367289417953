import { Flex, RecurringDatePrefixIcon, Text, formatDateDisplay, useFeatureFlags } from '@mm/company-ui'
import { ArrowBackRewindResetIcon } from '@mm/company-ui-icons/src'
import type { ActionRepeatType } from '@mm/graphql-helpers/gen/graphql/graphql'
import React from 'react'
import { useTotalComments } from '../../hooks/useTotalComments'

export type ActionRowContext = {
  actionId: string
  dueAt?: number
  repeat?: ActionRepeatType
}

export function ActionRowContext({ actionId, dueAt, repeat }: ActionRowContext) {
  const { commentsLabel } = useTotalComments({ __typename: 'Action', id: actionId })
  const { recurringAction } = useFeatureFlags()

  return (
    <Flex row gap={2}>
      {dueAt ? (
        <Flex gap={0.5} align="center">
          {repeat && recurringAction && (
            <RecurringDatePrefixIcon
              repeat={repeat}
              date={dueAt}
              icon={<ArrowBackRewindResetIcon color="text-light" width={1.5} height={1.5} />}
            />
          )}
          <Text variant="small" color="text-light">
            Due {formatDateDisplay(dueAt)}
          </Text>
        </Flex>
      ) : null}
      <Text variant="small" color="text-light">
        {commentsLabel}
      </Text>
    </Flex>
  )
}

import { oembed } from '@loomhq/loom-embed'
import { decisionIssueDefaultText } from '@mm/common'
import {
  Button,
  Editable,
  EditableFormController,
  Flex,
  Modal,
  MODAL_SECTION_PADDING,
  ModalSection,
  MotionFlex,
  Text,
  useBeforeUnload,
  Tooltip,
  ConfirmPopover,
  useConfirmPopover,
} from '@mm/company-ui'
import { useAnimation } from 'framer-motion'
import { DateTime } from 'luxon'
import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import {
  CreateDecisionMutationInput,
  DecisionParticipantRole,
  DecisionPrivacyStatus,
} from '../../../gen/graphql/documents'
import { RecordLoomButton } from '../../components/RecordLoomButton'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { useFeatureFlags } from '../../featureFlags'
import { useMeetingContext } from '../../meetings'
import { useActiveUserRequired } from '../../users'
import { CreateDecisionData } from '../types'
import { CreateDecisionDate } from './CreateDecisionDate'
import { CreateDecisionDecider } from './CreateDecisionDecider'
import { CreateDecisionModalHeader } from './CreateDecisionModalHeader'
import { CreateDecisionParticipants } from './CreateDecisionParticipants'
import { CreateDecisionTitle } from './CreateDecisionTitle'

export interface CreateDecisionModalProps {
  meetingMembers?: Array<string>
  meetingPrivacy?: DecisionPrivacyStatus
  defaultIssueText?: string
  defaultLoomLink?: string
  title?: string
  onRequestClose?: () => void
  onSubmit?: (data: CreateDecisionMutationInput) => Promise<void>
}

export const CreateDecisionModal = ({
  meetingMembers,
  meetingPrivacy,
  defaultIssueText,
  defaultLoomLink,
  title = '',
  onRequestClose,
  onSubmit,
}: CreateDecisionModalProps) => {
  const {
    recordLoomWhenCreatingDecision,
    canCreateOnBehalfOfOtherUsers,
    decisionParentMeeting,
    createIssueDefaultPrivate,
    ipsWarnIfNoLoom,
  } = useFeatureFlags()
  const animateShake = useAnimation()
  const [formError, setFormError] = useState({ type: '', message: '' })
  const activeUser = useActiveUserRequired()
  const meeting = useMeetingContext()
  const [author, setAuthor] = React.useState(activeUser?.id || '')
  const [isRecording, setIsRecording] = useState(false)

  const privacyLevel = createIssueDefaultPrivate ? 'PRIVATE' : 'PUBLIC'
  const {
    register,
    handleSubmit,
    // destructuring here is important to prevent bugs due to the formState rules
    // see https://react-hook-form.com/api/useform/formstate#rules
    formState: { isSubmitting, isDirty },
    control,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm<CreateDecisionData>({
    mode: 'onChange',
    defaultValues: {
      title,
      decisionMaker: null,
      privacy: meetingPrivacy ? meetingPrivacy : privacyLevel,
      dueAt: DateTime.local().plus({ days: 7 }).toMillis(),
      contributors: meetingMembers?.length ? meetingMembers : [activeUser.id],
      observers: [],
      commentsDueAt: DateTime.local().plus({ days: 6 }).toMillis(),
      description: defaultIssueText
        ? decisionIssueDefaultText.replace(
            '<p data-placeholder="State the problem"></p>',
            `<p data-placeholder="State the problem">${defaultIssueText.replace(/^<p>(.*)<\/p>$/s, '$1')}</p>`, // replace any wrapping <p> tags to avoid unnecessary whitespace
          )
        : decisionIssueDefaultText,
    },
  })

  const { triggerConfirm, confirmControlProps } = useConfirmPopover()

  //default values are cached at first render for useForm so we need to update privateLevel manually
  useEffect(() => {
    setValue('privacy', meetingPrivacy ? meetingPrivacy : privacyLevel)
  }, [meetingPrivacy, privacyLevel, setValue])

  const dueAtTime = watch('dueAt')
  const commentsDueAtTime = watch('commentsDueAt')

  useEffect(() => {
    if (DateTime.fromMillis(dueAtTime).startOf('day') < DateTime.fromMillis(commentsDueAtTime).startOf('day')) {
      setValue('commentsDueAt', DateTime.fromMillis(dueAtTime).minus({ days: 1 }).toMillis())
    }
  }, [dueAtTime, commentsDueAtTime, setValue])

  const submitForm = handleSubmit(async (values: CreateDecisionData) => {
    if (isSubmitting) {
      return
    }

    if (ipsWarnIfNoLoom && !watch('description').includes('loom.com') && !(await triggerConfirm())) {
      return
    }

    if (values.description === '<p></p>') {
      setFormError({ type: 'description', message: "Description can't be empty" })
      void animateShake.start('shake')
      return
    }

    if (!values.title) {
      setFormError({ type: 'title', message: "Title can't be empty" })
      void animateShake.start('shake')
      return
    }

    const allParticipantIds = [...values.contributors, ...values.observers, values.decisionMaker]

    if (!allParticipantIds.includes(activeUser.id)) {
      setFormError({ type: 'creator', message: 'Creator must be decision maker, contributor or observer' })
      void animateShake.start('shake')
      return
    }

    const decisionMaker = values.decisionMaker ?? null
    const contributorList = values.contributors.map((contributor) => ({
      id: contributor,
      role: 'CONTRIBUTOR' as DecisionParticipantRole,
    }))

    const observerList = values.observers.map((contributor) => ({
      id: contributor,
      role: 'OBSERVER' as DecisionParticipantRole,
    }))

    const decisionPayload = {
      decisionMaker,
      title: values.title,
      privacy: values.privacy,
      description: values.description,
      participants: [...contributorList, ...observerList],
      dueAt: values.dueAt ? values.dueAt : null,
      commentsDueAt: values.commentsDueAt ? values.commentsDueAt : null,
      author,
      parentMeetingId: decisionParentMeeting ? meeting?.id : null,
    }

    await onSubmit?.(decisionPayload)
  })

  useBeforeUnload(isDirty)

  const handleRequestClose = () => {
    if (isDirty) {
      const accept = window.confirm(
        'You are in the middle of creating an issue. Are you sure you want to navigate away?',
      )

      // If the user doesn't want to navigate away, we don't want to close the modal
      if (!accept) {
        return
      }
    }

    onRequestClose?.()
    return
  }

  const handleInsertLoom = useCallback(
    async (shareUrl: string) => {
      let description = getValues('description')

      const embed = await oembed(shareUrl, { width: 500, height: 300 })
      const htmlWithDataAttr = embed.html.replace('<iframe', '<iframe data-loom')

      // Remove helper text if its still there
      description = description.replace(
        '<p data-placeholder="Now that you have written the issue/proposed solution, please record a Loom video of yourself describing each of the steps above."></p>',
        '',
      )
      // Append loom preview to bottom
      description += htmlWithDataAttr + '<p></p>'

      setValue('description', description)
    },
    [getValues, setValue],
  )
  const onRecordLoomStart = useCallback(() => {
    setIsRecording(true)
  }, [])
  const onRecordLoomEnd = useCallback(() => {
    setIsRecording(false)
  }, [])

  // We only want this to run on mount
  useEffect(() => {
    if (defaultLoomLink) {
      void handleInsertLoom(defaultLoomLink)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        isOpen
        sx={{ padding: 0, overflow: 'inherit', maxWidth: '800px' }}
        title=""
        showCloseIcon={false}
        onRequestClose={handleRequestClose}
      >
        <MotionFlex
          animate={animateShake}
          variants={{ shake: { translateX: [-2, 0, 2, -4, 0, -4] } }}
          transition={{ duration: 0.4 }}
          sx={{ position: 'relative', zIndex: 1 }}
        >
          <form
            sx={{ width: '100%' }}
            onSubmit={(e) => {
              e.preventDefault()
              void submitForm()
            }}
          >
            <CreateDecisionModalHeader control={control} isParentMeetingPublic={meetingPrivacy == 'PUBLIC'} />
            <div sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
              <ModalSection column sx={{ paddingTop: MODAL_SECTION_PADDING - 1 }}>
                <CreateDecisionTitle control={control} formError={formError} />

                <Flex row>
                  <Flex column grow>
                    <CreateDecisionDecider control={control} />
                    <CreateDecisionParticipants participantType="contributors" control={control} />
                    <CreateDecisionParticipants participantType="observers" control={control} />
                  </Flex>
                  <Flex column grow>
                    <CreateDecisionDate
                      disabledDays={{ after: new Date(dueAtTime) }}
                      label="Comments due"
                      name="commentsDueAt"
                      control={control}
                    />
                    <CreateDecisionDate label="Decision due" name="dueAt" control={control} />
                  </Flex>
                </Flex>
              </ModalSection>
              <ModalSection column sx={{ paddingY: MODAL_SECTION_PADDING - 1 }}>
                <Text variant="h5" sx={{ mt: 1 }}>
                  Issue
                </Text>
                <EditableFormController {...register('description')}>
                  {(field) => (
                    <Editable
                      {...field}
                      mode="compact"
                      placeholder="State the problem"
                      hidePlaceholderWhenFocused
                      editable={!isSubmitting}
                      sx={{
                        minHeight: 10,
                        py: 1,
                        flexGrow: 1,
                      }}
                    />
                  )}
                </EditableFormController>
                {recordLoomWhenCreatingDecision && !watch('description').includes('loom.com') ? (
                  <Flex gap={1} sx={{ marginTop: 1 }}>
                    <RecordLoomButton
                      onInserted={handleInsertLoom}
                      onStart={onRecordLoomStart}
                      onComplete={onRecordLoomEnd}
                    />
                  </Flex>
                ) : null}
              </ModalSection>
            </div>
            <ModalSection sx={{ paddingY: 1 }}>
              {formError.message && formError.type !== 'title' && (
                <Text color="system-text-warning">{formError.message}</Text>
              )}
              <Flex row grow gap={2}>
                <div sx={{ flex: 1 }}>
                  {canCreateOnBehalfOfOtherUsers ? (
                    <Flex align="center" gap={1}>
                      <Text color="text-light" variant="small">
                        Author:
                      </Text>
                      <UserSelectWithCompanyMembers
                        withName
                        value={author}
                        onAdd={setAuthor}
                        targetProps={{ size: 'small' }}
                      />
                    </Flex>
                  ) : null}
                </div>
                <Button
                  size="small"
                  variant="muted"
                  onClick={() => {
                    setFormError({ type: '', message: '' })
                    reset()
                    onRequestClose?.()
                  }}
                >
                  Cancel
                </Button>

                {isRecording ? (
                  <Tooltip position="top" description="Please finish recording before adding an issue.">
                    <Button
                      size="small"
                      type="submit"
                      variant="accent"
                      loading={isSubmitting}
                      onClick={submitForm}
                      disabled={isRecording}
                    >
                      Create
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    size="small"
                    type="submit"
                    variant="accent"
                    loading={isSubmitting}
                    onClick={submitForm}
                    disabled={isRecording}
                  >
                    Create
                  </Button>
                )}
              </Flex>
            </ModalSection>
          </form>
        </MotionFlex>
      </Modal>
      <ConfirmPopover
        {...confirmControlProps}
        zIndex={10000}
        title="No loom video"
        description="Please record a Loom video describing the issue. This allows your team to watch and comment prior to the meeting."
        confirmLabel="Add anyway"
        confirmVariant="accent"
      />
    </>
  )
}

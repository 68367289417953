import { Flex, Text, formatDateDisplay, Tooltip } from '@mm/company-ui'
import { LinkIcon, LockedIcon, UnlockedIcon } from '@mm/company-ui-icons'
import React from 'react'
import { ActionsDashboardRowDataFragment } from '../../../gen/graphql/documents'
import { ActionLinkWithMenu } from './ActionLinkWithMenu'

export type ActionUpdateRowProps = {
  action: ActionsDashboardRowDataFragment
}

export function ActionUpdateRow({ action }: ActionUpdateRowProps) {
  const parentTypeLabel =
    action.parent?.__typename === 'PrototypeFeedback' ? 'Feedback' : `${action.parent?.__typename}`
  const parentTitleLabel = action.parent?.title ? `${parentTypeLabel}: ${action.parent.title}` : ''

  return (
    <ActionLinkWithMenu actionId={action.id} hasHover={false}>
      <Flex column gap={0.5} sx={{ width: '100%', padding: 1 }}>
        <Flex row gap={1} justify="space-between">
          <Text
            bold
            sx={{
              fontSize: '16px',
              lineHeight: '1.5em',
              maxHeight: '3em',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {action.title}
          </Text>

          <Flex gap={2} sx={{ alignSelf: 'flex-start', flexShrink: 0 }} align="center">
            {action.privacy === 'PRIVATE' ? (
              <Tooltip description="This action is private">
                <LockedIcon width={1.5} height={1.5} color="text-light" />
              </Tooltip>
            ) : (
              <Tooltip description="This action is public">
                <UnlockedIcon width={1.5} height={1.5} color="text-light" />
              </Tooltip>
            )}
            {action.dueAt ? (
              <Text color="text-light" variant="small" sx={{ flexShrink: 0 }}>
                Due {formatDateDisplay(action.dueAt)}
              </Text>
            ) : null}
          </Flex>
        </Flex>

        {parentTitleLabel ? (
          <Flex row gap={0.5} sx={{ flexShrink: 0 }}>
            <LinkIcon width={1.5} height={1.5} color="text-light" sx={{ marginTop: 0.5 }} />

            <Text color="text-light" overflow="ellipsis">
              {parentTitleLabel}
            </Text>
          </Flex>
        ) : null}
      </Flex>
    </ActionLinkWithMenu>
  )
}

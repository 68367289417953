import { Suspender } from '@mm/company-ui'
import React from 'react'
import { DecisionDescriptionDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { DocumentEditor } from '../../editor'

export type DecisionDescriptionProps = {
  decisionId: string
  fillHeight?: boolean
  padding?: number
}

export const DecisionDescription = ({ decisionId, fillHeight = true, padding = 3 }: DecisionDescriptionProps) => {
  const { data, loading, error } = useQuery(DecisionDescriptionDocument, {
    variables: {
      id: decisionId,
    },
  })

  const decision = data?.decision
  if (decision == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Decision not found')
  }

  return (
    <DocumentEditor
      key={decision.description.id}
      documentId={decision.description.id}
      placeholder="Enter description here..."
      mode="compact"
      sx={{
        display: 'flex',
        flexDirection: 'column',

        ...(fillHeight && {
          height: '100%',
        }),

        '.ProseMirror': {
          margin: 0,
          padding,
          minHeight: 'auto',

          ...(fillHeight && {
            padding: 5,
            overflowY: 'scroll',
            height: '100%',
            minHeight: '100%',
          }),
        },
      }}
    />
  )
}

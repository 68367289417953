import { Editor, Extension } from '@tiptap/react'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import React, { useEffect, useRef } from 'react'
import { SingleLineEditable } from '../Editable'

export type RichTextCellProps = {
  value: string
  strong?: boolean
  clickable?: boolean
  className?: string
  forceSingleLine?: boolean
}

export const RichTextCell = ({
  value,
  strong = false,
  clickable = true,
  className,
  forceSingleLine = true,
}: RichTextCellProps) => {
  const editorRef = useRef<Editor>(null)
  useEffect(() => {
    editorRef.current?.commands.setContent(value, true)
  }, [value])

  return (
    <SingleLineEditable
      variant="unstyled"
      ref={editorRef}
      readonly
      initialValue={value}
      data-clickable={clickable ? 'true' : 'false'}
      extensions={extensions}
      className={className}
      sx={{
        ...(forceSingleLine
          ? {
              '> .ProseMirror': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
            }
          : {}),

        ...(strong && {
          fontWeight: 'bold',
          strong: {
            fontWeight: 'extrabold',
          },
        }),
      }}
    />
  )
}

const DecorateLinksExtension = Extension.create({
  name: 'decorate-links',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations(state) {
            const decorations: Decoration[] = []

            state.doc.descendants((node, pos) => {
              if (node.marks.some((mark) => mark.type.name === 'link')) {
                decorations.push(Decoration.inline(pos, pos + node.nodeSize, { 'data-clickable': 'false' }))
              }
            })

            return DecorationSet.create(state.doc, decorations)
          },
        },
      }),
    ]
  },
})

const extensions = [DecorateLinksExtension]

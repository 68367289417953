import { Button, Card, Flex, formatDateDisplay, Suspender, Text } from '@mm/company-ui'
import _ from 'lodash'
import React, { useState } from 'react'
import { ActionUpdatesDocument, UpdateActionStatus } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { ActionViewActionUpdate } from '../../updates/components/UpdateViewActionUpdate'

export type ActionUpdatesProps = {
  actionId: string
  fillHeight?: boolean
  padding?: number
}

const PER_PAGE = 3

export const ActionUpdates = ({ actionId }: ActionUpdatesProps) => {
  const { data, loading, error } = useQuery(ActionUpdatesDocument, {
    variables: {
      actionId,
    },
  })
  const [isExpanded, setIsExpanded] = useState(false)

  const action = data?.action
  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('Action not found')
  }

  const updates = action.actionUpdates?.filter((actionUpdate) => actionUpdate?.status !== 'ACTIVE')

  return (
    <Flex column gap={2}>
      {(isExpanded ? updates : _.take(updates, PER_PAGE))?.map((update) => {
        if (!update) return null

        const status =
          update.done === true
            ? ('DONE' as const)
            : update.done === false
            ? ('NOT_DONE' as const)
            : (update.status as UpdateActionStatus)

        return update ? (
          <Flex key={update.id} column gap={1}>
            <Text uppercase bold variant="small" color="text-light">
              {formatDateDisplay(update.createdAt, 'SHORT_MONTH_WITH_DATE')}
            </Text>
            <Card sx={{ padding: 0, borderRadius: 'medium' }} variant="flat">
              <ActionViewActionUpdate
                actionUpdate={{
                  ...update,
                  __typename: 'UpdateAction',
                  status,
                }}
                hasParent={false}
              />
            </Card>
          </Flex>
        ) : null
      })}

      {(updates?.length || 0) > PER_PAGE ? (
        <Button
          sx={{ width: '100%', marginTop: 1 }}
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          {isExpanded ? 'View less' : 'View all'}
        </Button>
      ) : null}

      {updates?.length === 0 ? (
        <Flex justify="center">
          <Text variant="small" color="text-light">
            No updates
          </Text>
        </Flex>
      ) : null}
    </Flex>
  )
}

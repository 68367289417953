import { useFeatureFlags } from '@mm/company-ui/src'
import React from 'react'
import { AddGoalCommentBox } from './AddGoalCommentBox'
import { GoalCommentsList } from './GoalCommentsList'

export type GoalCommentsProps = {
  goalId: string
  order?: 'newest' | 'oldest'
  fixedAndScroll?: boolean
}

export const GoalComments = ({ goalId, order = 'oldest', fixedAndScroll = true }: GoalCommentsProps) => {
  const { flipComments } = useFeatureFlags()
  return (
    <GoalCommentsList
      goalId={goalId}
      order={order}
      fixedAndScroll={fixedAndScroll}
      composer={!flipComments ? <AddGoalCommentBox goalId={goalId} /> : null}
    />
  )
}

import { AvatarProps } from '@mm/company-ui'
import React, { useCallback } from 'react'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { editActionAssigneeCommand } from '../commands/editActionAssigneeComand'

export type ActionAssigneeCellContentProps = {
  actionId: string
  assigneeId: string
  avatarOnly?: boolean
  size?: AvatarProps['size']
}

export const ActionAssigneeCellContent = ({
  assigneeId,
  actionId,
  avatarOnly,
  size,
}: ActionAssigneeCellContentProps) => {
  const editActionAssignee = useCommand(editActionAssigneeCommand)

  const handleSelectedUser = useCallback(
    (assigneeId: string) => {
      void editActionAssignee.execute({ actionId, assigneeId })
    },
    [actionId, editActionAssignee],
  )

  return (
    <UserSelectWithCompanyMembers
      aria-label="Assignee"
      value={assigneeId}
      onAdd={handleSelectedUser}
      withName={!avatarOnly}
      targetProps={{ size }}
    />
  )
}

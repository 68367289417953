import { produce } from 'immer'
import { useCallback } from 'react'
import {
  CommentEmojiReactionsDataFragmentDoc,
  UseAddCommentEmojiReactionDocument,
} from '../../../gen/graphql/documents'
import { useApolloClient, useMutation } from '../../apollo'
import { useActiveUserRequired } from '../../users'

export const useAddCommentEmojiReaction = (commentId: string) => {
  const client = useApolloClient()
  const [addEmojiReaction] = useMutation(UseAddCommentEmojiReactionDocument)
  const activeUser = useActiveUserRequired()

  return useCallback(
    (emoji: string) => {
      const data = client.readFragment(
        {
          id: client.cache.identify({ __typename: 'Comment', id: commentId }),
          fragment: CommentEmojiReactionsDataFragmentDoc,
        },
        true,
      )

      return addEmojiReaction({
        variables: {
          id: commentId,
          emoji,
        },
        optimisticResponse: data
          ? {
              addCommentEmojiReaction: produce(data, (draft) => {
                if (
                  draft.emojiReactions?.every(
                    (reaction) => reaction.user.id !== activeUser.id || reaction.emoji !== emoji,
                  )
                ) {
                  draft.emojiReactions.push({
                    user: activeUser,
                    emoji,
                  })
                }
              }),
            }
          : undefined,
      })
    },
    [client, commentId, addEmojiReaction, activeUser],
  )
}

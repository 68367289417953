export type Emoji = {
  id: string
  shortcodes: string
  skinTone: number
}

export const parseEmoji = (shortCode: string): Emoji | null => {
  const [, id, tone = '1'] = shortCode.match(/^:([^:]+):(?::skin-tone-(\d):)?$/) ?? []
  if (id == null) {
    return null
  }

  const skinToneStr = tone === '1' ? '' : `:skin-tone-${tone}:`
  return {
    id,
    shortcodes: `:${id}:${skinToneStr}`,
    skinTone: +tone,
  }
}

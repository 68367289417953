import { SingleLineExtension } from '@mm/tiptap-extension'
import { Extensions } from '@tiptap/core'
import { Editor } from '@tiptap/react'
import React, { useCallback, useImperativeHandle, useState } from 'react'
import { Bindings } from '../utils'
import { FloatingSelectionToolbar } from './FloatingSelectionToolbar'
import { defaultInlineItems, SelectionToolbar, ToolbarItem } from './SelectionToolbar'
import { SingleLineEditableContent, SingleLineEditableContentProps } from './SingleLineEditable.styles'
import { useTipTapEditor } from './useTipTapEditor'

type SingleLineEditableOwnProps = {
  initialValue?: string
  placeholder?: string
  readonly?: boolean
  autofocus?: boolean
  hotkeys?: Bindings
  extensions?: Extensions
  selectionToolbar?: ToolbarItem[][] | React.ReactElement | false
  onChange?: (value: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onToolbarOpen?: (open: boolean) => void
}

export type SingleLineEditableProps = Omit<React.ComponentPropsWithoutRef<'div'>, keyof SingleLineEditableOwnProps> &
  Partial<SingleLineEditableContentProps> &
  SingleLineEditableOwnProps

export const SingleLineEditable = React.forwardRef<Editor, SingleLineEditableProps>(
  (
    {
      variant = 'default',
      invalid = false,
      initialValue,
      placeholder = '',
      readonly = false,
      autofocus = false,
      hotkeys,
      extensions = [],
      selectionToolbar = defaultInlineItems,
      onChange,
      onFocus,
      onBlur,
      onToolbarOpen,
      role,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledBy,
      ...rest
    },
    ref,
  ) => {
    const [toolbarOpen, setToolbarOpen] = useState(false)
    const editor = useTipTapEditor({
      content: initialValue,
      editable: !readonly,
      autofocus,
      hotkeys,
      onChange,
      onFocus,
      onBlur,
      extensions: [SingleLineExtension, ...extensions],
      role,
      ariaLabel,
      ariaLabelledBy,
      placeholder,
    })

    const handleToolbarOpenChange = useCallback(
      (open: boolean) => {
        setToolbarOpen(open)
        onToolbarOpen?.(open)
      },
      [onToolbarOpen],
    )

    useImperativeHandle<Editor | null, Editor | null>(ref, () => editor, [editor])

    return (
      <>
        {editor && selectionToolbar !== false && (
          <FloatingSelectionToolbar editor={editor} open={toolbarOpen} onOpenChange={handleToolbarOpenChange}>
            {React.isValidElement(selectionToolbar) ? (
              selectionToolbar
            ) : (
              <SelectionToolbar editor={editor} items={selectionToolbar} />
            )}
          </FloatingSelectionToolbar>
        )}

        <SingleLineEditableContent editor={editor} variant={variant} invalid={invalid} {...rest} />
      </>
    )
  },
)

SingleLineEditable.displayName = 'SingleLineEditable'

import { yDocFragment } from '@mm/common'
import { defaultItems, Editable, EditableProps, EditableRef, toast } from '@mm/company-ui'
import { mergeProps } from '@react-aria/utils'
import { Collaboration } from '@tiptap/extension-collaboration'
import { Extensions } from '@tiptap/react'
import React, { useCallback, useMemo } from 'react'
import uploadcare, { FileInfo } from 'uploadcare-widget/uploadcare.api'
import * as Y from 'yjs'
import { useFeatureFlags } from '../../featureFlags'
import { useWebRtcProvider, useYjsProvider } from '../collaboration'
import { useComplexToolbar } from '../hooks/useComplexToolbar'

export type DocumentEditorProps = Omit<EditableProps, 'initialValue'> & {
  documentId?: string
}

// const userColors = ['#958DF1', '#F98181', '#FBBC88', '#FAF594', '#70CFF8', '#94FADB', '#B9F18D']

export const DocumentEditor = React.forwardRef<EditableRef, DocumentEditorProps>(function DocumentEditor(
  { documentId, ...rest },
  ref,
): React.ReactElement {
  const items = useComplexToolbar(defaultItems)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const yDoc = useMemo(() => new Y.Doc(), [documentId])

  const webRtcProvider = useWebRtcProvider(yDoc, documentId)
  const yjsProps = useYjsProvider(yDoc, documentId)

  const collaboration: Extensions = [
    Collaboration.configure({
      document: yDoc,
      field: yDocFragment,
    }),
  ]

  if (webRtcProvider != null) {
    // TODO
    // extensions.push(
    //   CollaborationCursor.configure({
    //     provider: webRtcProvider,
    //     user: {
    //       name: user.name,
    //       color: userColors[Math.trunc(userColors.length * Math.random())],
    //     },
    //   }),
    // )
  }

  const { editorImageUpload } = useFeatureFlags()
  const handleUpload = useCallback(
    async (image: File) => {
      if (editorImageUpload) {
        return await new Promise<string>((resolve, reject) => {
          uploadcare
            .fileFrom('object', image, {
              publicKey: process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY,
            })
            // .progress((uploadInfo) => {
            //   console.log('Upload progress: ' + uploadInfo.progress)
            // })
            .done((fileInfo) => {
              resolve(fileInfo.originalUrl)
            })
            .fail((_errorType: string, _fileInfo?: FileInfo, error?: Error | XMLHttpRequest) => {
              const isNetworkError = error && 'readyState' in error

              // eslint-disable-next-line no-console
              console.error(error)
              toast(isNetworkError ? 'Networking error uploading image' : 'Error uploading image')
              reject()
            })
        })
      }

      return await Promise.reject()
    },
    [editorImageUpload],
  )

  return (
    <Editable
      {...mergeProps(rest, yjsProps)}
      ref={ref}
      collaboration={collaboration}
      selectionToolbar={items}
      sx={{ '.ProseMirror': { minHeight: 400, border: '1px solid transparent', margin: 3 } }}
      onUpload={handleUpload}
    />
  )
})

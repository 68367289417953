import { Button } from '@mm/company-ui'
import { ExpandMaximizeIcon } from '@mm/company-ui-icons'
import { useRouter } from 'next/router'
import React from 'react'
import { isExactRoute } from '../../utils'

export type FullscreenDrawerButtonProps = {
  resourceType:
    | 'topics'
    | 'actions'
    | 'profile'
    | 'goals'
    | 'goodThings'
    | 'decisions'
    | 'updates'
    | 'feedback'
    | 'accountability/updates'
    | 'accountability/actions'
    | 'accountability/goals'
    | 'accountability/team'
  id: string
}

export const FullscreenDrawerButton = ({ resourceType, id }: FullscreenDrawerButtonProps) => {
  const router = useRouter()
  const isFullScreen = isExactRoute(router, `/${resourceType}/[id]`)
  const handleFullScreen = () => router.push(`/${resourceType}/${id}`)

  if (!isFullScreen) {
    return (
      <Button variant="muted" textHidden size="small" startIcon={<ExpandMaximizeIcon />} onClick={handleFullScreen}>
        View fullscreen
      </Button>
    )
  }

  return null
}

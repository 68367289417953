import { Flex, Text, formatDateDisplay } from '@mm/company-ui'
import React from 'react'
import { UserGoalsDashboardRowDataFragment } from '../../../gen/graphql/documents'
import { GoalStatusPicker } from '../../goals/components/GoalStatusPicker'
import { GoalLinkWithMenu } from './GoalLinkWithMenu'

export type GoalRowProps = {
  goal: UserGoalsDashboardRowDataFragment
  withStatus?: boolean
  renderFooter?: () => React.ReactNode
}

export function GoalRow({ goal, withStatus = true, renderFooter }: GoalRowProps) {
  return (
    <GoalLinkWithMenu goalId={goal.id}>
      <Flex
        column
        gap={0.5}
        align="flex-start"
        sx={{ marginX: 1, paddingY: 2, borderBottom: '1px solid', borderBottomColor: 'border' }}
      >
        <Flex row gap={2} sx={{ width: '100%' }}>
          <Text
            bold
            sx={{
              flex: 1,
            }}
          >
            {goal.title}
          </Text>
          <Text color="text-light" sx={{ flexShrink: 0 }}>
            By {formatDateDisplay(goal.dueAt)}
          </Text>

          {withStatus ? <GoalStatusPicker goalId={goal.id} cached skipNotDoneFlow /> : null}
        </Flex>

        {/* {goal.updates && goal.updates[0]?.createdAt ? (
          <Text variant="small" color="text-light">
            Updated {formatDateDisplay(goal.updates[0]?.createdAt, 'SHORT_MONTH_WITH_DATE')}
          </Text>
        ) : null} */}

        {renderFooter?.()}
      </Flex>
    </GoalLinkWithMenu>
  )
}

import { Suspender, Tag, TagSelect, Tooltip, useFeatureFlags } from '@mm/company-ui'
import { LockedIcon, UnlockedIcon } from '@mm/company-ui-icons'
import React from 'react'
import {
  ActionPrivacyStatus,
  ActionPrivacyTagDocument,
  UpdateActionPrivacyDocument,
} from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'

export type ActionPrivacyTagProps = {
  actionId: string
  className?: string
  cached?: boolean
  disabled?: boolean
  showPrivacyLabel?: boolean
}

export const ActionPrivacyTag = ({
  actionId,
  className,
  cached,
  disabled,
  showPrivacyLabel = true,
}: ActionPrivacyTagProps) => {
  const { actionPrivacy } = useFeatureFlags()
  const { data, loading, error } = useQuery(ActionPrivacyTagDocument, {
    variables: { actionId },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const [editPrivacy] = useMutation(UpdateActionPrivacyDocument)

  const action = data?.action
  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('action not found: ' + actionId)
  }

  const isParentMeetingPublic = action.meetings?.edges?.some((meeting) => meeting.node.privacyLevel === 'PUBLIC')

  return (
    <Tooltip
      position="bottom-start"
      description={
        isParentMeetingPublic
          ? 'This action is public because it’s part of at least one public meeting. If you want to change it, update the privacy setting of the meeting.'
          : action.isPrivate
          ? 'This action is private'
          : 'Public - Visible to everyone at the company'
      }
      sx={{ maxWidth: '300px', fontSize: 1, lineHeight: 1.5 }}
    >
      {actionPrivacy ? (
        <TagSelect
          value={action.isPrivate ? 'PRIVATE' : 'PUBLIC'}
          onChange={(privacy: ActionPrivacyStatus) => {
            void editPrivacy({
              variables: {
                id: actionId,
                data: {
                  privacy,
                },
              },
            })
          }}
        >
          <Tag disabled={isParentMeetingPublic || disabled} value="PRIVATE" variant="default" className={className}>
            <LockedIcon />
            {showPrivacyLabel && 'Private'}
          </Tag>
          <Tag disabled={isParentMeetingPublic || disabled} value="PUBLIC" variant="default" className={className}>
            <UnlockedIcon />
            {showPrivacyLabel && 'Public'}
          </Tag>
        </TagSelect>
      ) : (
        <Tag className={className}>
          {action.isPrivate ? <LockedIcon /> : <UnlockedIcon />}
          {action.isPrivate ? 'Private' : 'Public'}
        </Tag>
      )}
    </Tooltip>
  )
}

import { Button, ButtonGroup, useFeatureFlags } from '@mm/company-ui'
import { DateTime } from 'luxon'
import React from 'react'
import { ActionsDashboardRowDataFragment, AddActionCommentBoxDocument } from '../../../gen/graphql/documents'
import { useMutation } from '../../apollo'
import { useCommand } from '../../commands'
import { addRootCommentToCache } from '../../comments'
import { useDoneNotDone } from '../../DoneNotDone'
import { editActionDueAtCommand } from '../../DoneNotDone/commands/editActionDueAtCommand'
import { editActionStatusCommand } from '../../DoneNotDone/commands/editActionStatusCommand'

export type ActionDoneNotDoneButtonsProps = Pick<
  ActionsDashboardRowDataFragment,
  'id' | 'status' | 'dateRescheduled' | 'dateRescheduledAt' | 'statusUpdatedAt'
> & {
  onDoneSubmitted?: (htmlBody: string) => void
  onNotDoneSubmitted?: (htmlBody: string, cancel: boolean, dueAt: number | undefined) => void
  onCancel?: () => void
  renderButtons?: (handleDone: () => void, handleNotDone: () => void) => JSX.Element
}

export function ActionDoneNotDoneButtons({
  id,
  status,
  dateRescheduled,
  dateRescheduledAt,
  statusUpdatedAt,
  onCancel,
  renderButtons,
  onDoneSubmitted,
  onNotDoneSubmitted,
}: ActionDoneNotDoneButtonsProps): JSX.Element {
  const hasBeenUpdatedInTheLastSixDays =
    (dateRescheduledAt && DateTime.fromMillis(dateRescheduledAt).diffNow('days').days > -6) ||
    (statusUpdatedAt && DateTime.fromMillis(statusUpdatedAt).diffNow('days').days > -6)

  const buttonDone = hasBeenUpdatedInTheLastSixDays && status === 'DONE'
  const buttonNotDone =
    hasBeenUpdatedInTheLastSixDays &&
    status !== 'DONE' &&
    (status === 'CANCELLED' || status === 'ON_HOLD' || dateRescheduled)
  const otherButtonVariant = buttonDone || buttonNotDone ? 'muted' : 'default'
  const { actionDoneModal } = useFeatureFlags()

  const editActionStatus = useCommand(editActionStatusCommand)
  const editActionDueAt = useCommand(editActionDueAtCommand)

  const [addComment] = useMutation(AddActionCommentBoxDocument, {
    update: (cache, { data }) => {
      const comment = data?.createCommentOnAction
      if (comment?.__typename === 'Comment') {
        addRootCommentToCache(cache, comment)
      }
    },
  })

  const { show } = useDoneNotDone()

  const handleDone = () => {
    if (status !== 'DONE') {
      if (!actionDoneModal) {
        void editActionStatus.execute({ actionId: id, status: 'DONE' })
        return
      }

      show({
        actionId: id,
        variant: 'done',
        onCancel,
        onSubmit: async ({ htmlBody }) => {
          if (htmlBody) {
            const { data } = await addComment({
              variables: {
                actionId: id,
                htmlBody: `<blockquote><p>Key Learnings: </p></blockquote>${htmlBody}`,
              },
            })

            if (data?.createCommentOnAction.__typename !== 'Comment') {
              throw new Error('failed to create a comment')
            }
          }
          void editActionStatus.execute({ actionId: id, status: 'DONE' })
          onDoneSubmitted?.(htmlBody)
        },
      })
    }
  }

  const handleNotDone = () => {
    const oneWeek = DateTime.now().plus({ week: 1 }).toMillis()
    show({
      actionId: id,
      dueAt: oneWeek,
      variant: 'not-done', // TODO: Add in 3rd option, put on hold
      onCancel,
      onSubmit: async ({ htmlBody, cancel, dueAt }) => {
        if (htmlBody) {
          const { data } = await addComment({
            variables: {
              actionId: id,
              htmlBody: `<blockquote><p>Reason for ${
                cancel ? 'cancellation' : dueAt ? 'rescheduling' : 'blocked'
              }</p></blockquote>${htmlBody}`,
            },
          })

          if (data?.createCommentOnAction.__typename !== 'Comment') {
            throw new Error('failed to create a comment')
          }
        }

        if (cancel) {
          await editActionStatus.execute({ actionId: id, status: 'CANCELLED' })
        } else if (dueAt) {
          if (status !== 'ACTIVE') {
            await editActionStatus.execute({ actionId: id, status: 'ACTIVE' })
          } else {
            await editActionDueAt.execute({ actionId: id, dueAt })
          }
        } else {
          await editActionStatus.execute({ actionId: id, status: 'ON_HOLD' })
        }
        onNotDoneSubmitted?.(htmlBody, cancel, dueAt)
      },
    })
  }

  return renderButtons ? (
    renderButtons(handleDone, handleNotDone)
  ) : (
    <ButtonGroup size="small" sx={{ fontSize: 'small', paddingX: 0.5, height: 24 }} gap={0.5}>
      <Button onClick={handleDone} variant={buttonDone ? 'accent' : otherButtonVariant}>
        Done
      </Button>
      <Button onClick={handleNotDone} variant={buttonNotDone ? 'warning' : otherButtonVariant}>
        Not Done
      </Button>
    </ButtonGroup>
  )
}

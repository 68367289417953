import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { DecisionCommentsListDocument, MarkOrUnmarkDecisionDocument } from '../../../gen/graphql/documents'
import { useMutation } from '../../apollo'
import { RegisterCapability } from '../../capabilities'
import { useGetDecidedCommentIds, useGetDecidedComments } from '../../decisions/DecidedCommentContext'
import { MARK_OR_UNMARK_COMMENT_AS_DECISION } from '../capabilities'
import { CommentsList, CommentsListProps } from './CommentsList'

export type DecisionCommentsListProps = Omit<
  CommentsListProps<unknown, unknown>,
  'query' | 'variables' | 'extractPage'
> & {
  decisionId: string
  user: {
    id: string
    firstName: string
  }
}

export const MarkOrUnmarkDecisionCapability = ({ decisionId, user }: DecisionCommentsListProps) => {
  const [decidedComments] = useGetDecidedComments()
  const [decidedCommentIds] = useGetDecidedCommentIds()

  const [markOrUnmarkDecision] = useMutation(MarkOrUnmarkDecisionDocument, {
    optimisticResponse: ({ decisionId, commentId }) => {
      const mappedDecidedComments =
        decidedComments?.map(({ user, comment, decidedAt }) => ({
          __typename: 'DecidedComment' as const,
          user,
          comment,
          decidedAt,
        })) ?? []

      return {
        markOrUnmarkDecision: {
          __typename: 'Decision' as const,
          id: decisionId,
          decidedComments: decidedCommentIds?.includes(commentId)
            ? mappedDecidedComments.filter((decidedComment) => decidedComment.comment.id !== commentId)
            : [
                ...mappedDecidedComments,
                {
                  user: {
                    firstName: user.firstName,
                    id: user.id,
                    __typename: 'User' as const,
                  },
                  comment: {
                    id: commentId,
                    __typename: 'Comment' as const,
                  },
                  decidedAt: DateTime.now().toMillis(),
                  __typename: 'DecidedComment' as const,
                },
              ],
        },
      }
    },
  })

  const handleMarkOrUnmarkCommentAsDecision = useCallback(
    (commentId: string) => {
      void markOrUnmarkDecision({
        variables: {
          commentId,
          decisionId,
        },
      })
    },
    [markOrUnmarkDecision, decisionId],
  )
  return (
    <RegisterCapability capability={MARK_OR_UNMARK_COMMENT_AS_DECISION} handler={handleMarkOrUnmarkCommentAsDecision} />
  )
}

export const DecisionCommentsList = ({ decisionId, ...rest }: DecisionCommentsListProps) => {
  return (
    <CommentsList
      query={DecisionCommentsListDocument}
      variables={{ decisionId }}
      extractPage={(data) => data.decision?.comments}
      {...rest}
    />
  )
}

import { isGraphQLError } from '@mm/graphql-helpers/src'
import { produce } from 'immer'
import { remove } from 'lodash'
import {
  DecisionMoreMenuDataFragmentDoc,
  DetachDecisionFromMeetingCommandDocument,
  GenericTopicsDataFragmentDoc,
  GetDecisionCommandDocument,
  TopicMeetingDataFragmentDoc,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type detachDecisionFromMeetingCommandValue = {
  decisionId: string
  meetingId: string
  onDecisionDetach?: () => void
}

export const detachDecisionFromMeetingCommand: Command<detachDecisionFromMeetingCommandValue> = {
  id: 'detachDecisionFromMeetingCommand',
  name: 'Detach Decision From Meeting',
  canExecute: async ({ decisionId }, { apolloClient }) => {
    const query = await apolloClient.query({ query: GetDecisionCommandDocument, variables: { id: decisionId } })
    return !!query.data.decision
  },
  execute: async ({ decisionId, meetingId, onDecisionDetach }, { apolloClient }) => {
    const meetingData = apolloClient.readFragment({
      fragment: TopicMeetingDataFragmentDoc,
      id: apolloClient.cache.identify({ __typename: 'Meeting', id: meetingId }),
      fragmentName: 'topicMeetingData',
    })

    const sectionId = meetingData?.sections.find(
      (section) =>
        section.__typename === 'MeetingTopicSection' && section.genericTopics.some((topic) => topic.id === decisionId),
    )?.id

    const { data } = await apolloClient.mutate({
      mutation: DetachDecisionFromMeetingCommandDocument,
      variables: {
        id: decisionId,
        meetingId,
      },
      update: (cache, { data }) => {
        if (data?.detachDecisionFromMeeting.__typename === 'Success') {
          cache.updateFragment(
            {
              id: cache.identify({ __typename: 'MeetingTopicSection', id: sectionId }),
              fragment: GenericTopicsDataFragmentDoc,
              fragmentName: 'genericTopicsData',
            },
            (meeting) =>
              produce(meeting, (meetingDraft) => {
                remove(meetingDraft?.genericTopics || [], (topic) => topic.id === decisionId)
              }),
          ),
            cache.updateFragment(
              {
                id: cache.identify({ __typename: 'Decision', id: decisionId }),
                fragment: DecisionMoreMenuDataFragmentDoc,
                fragmentName: 'decisionMoreMenuData',
              },
              (decision) =>
                produce(decision, (decisionDraft) => {
                  remove(decisionDraft?.meetings?.edges || [], (edge) => edge.node.id === meetingId)
                }),
            )
        }
      },
      optimisticResponse: {
        detachDecisionFromMeeting: {
          __typename: 'Success',
        },
      },
    })
    if (data?.detachDecisionFromMeeting.__typename === 'Success') {
      onDecisionDetach?.()
    } else if (data?.detachDecisionFromMeeting && isGraphQLError(data?.detachDecisionFromMeeting)) {
      alert(`Removing decision failed: ${data?.detachDecisionFromMeeting.message ?? 'unknown error'}`)
    } else {
      alert('Removing decision failed: unknown error')
    }
  },
}

import {
  UpdateActionWaitingForDocument,
  GetActionWaitingForDocument,
  GetUserDocument,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'

export type EditActionWaitingForCommandValue = {
  waitingForId: string
  actionId: string
}

export const editActionWaitingForCommand: Command<EditActionWaitingForCommandValue> = {
  id: 'editActionWaitingForCommand',
  name: 'Edit Waiting For',
  canExecute: async ({ waitingForId, actionId }, { apolloClient }) => {
    const currentAction = await apolloClient.query({ query: GetActionWaitingForDocument, variables: { id: actionId } })
    return currentAction.data.action?.waitingFor?.id !== waitingForId
  },
  execute: async ({ waitingForId, actionId }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const currentAction = await apolloClient.query({ query: GetActionWaitingForDocument, variables: { id: actionId } })
    const nextWaitingFor = await apolloClient.query({ query: GetUserDocument, variables: { id: waitingForId } })

    await apolloClient.mutate({
      mutation: UpdateActionWaitingForDocument,
      variables: {
        id: actionId,
        data: {
          waitingFor: waitingForId,
        },
      },
      ...(nextWaitingFor.data.user && {
        optimisticResponse: {
          updateAction: {
            __typename: 'Action',
            id: actionId,
            waitingFor: nextWaitingFor.data.user,
          },
        },
      }),
    })

    if (currentAction.data.action?.waitingFor?.id) {
      return {
        args: {
          waitingForId: currentAction.data.action?.waitingFor.id,
          actionId,
        },
        execute: async ({ waitingForId, actionId }, { apolloClient }) => {
          const nextWaitingFor = await apolloClient.query({ query: GetUserDocument, variables: { id: waitingForId } })

          await apolloClient.mutate({
            mutation: UpdateActionWaitingForDocument,
            variables: {
              id: actionId,
              data: {
                waitingFor: waitingForId,
              },
            },
            ...(nextWaitingFor.data.user && {
              optimisticResponse: {
                updateAction: {
                  __typename: 'Action',
                  id: actionId,
                  waitingFor: nextWaitingFor.data.user,
                },
              },
            }),
          })

          return
        },
      }
    }

    return
  },
}

import { Flex, Text, formatDateDisplay } from '@mm/company-ui'
import { CalendarRescheduleIcon, LinkIcon, TimeAlarmClockReminder } from '@mm/company-ui-icons/src'
import { DateTime } from 'luxon'
import React from 'react'
import { ActionsDashboardRowDataFragment } from '../../../gen/graphql/documents'
import { ActionAssigneeCellContent } from '../../actions/components/ActionAssigneeCell'
import { ActionLinkWithMenu } from './ActionLinkWithMenu'
import { ActionStatusSelect } from './ActionStatusSelect'

export type ActionRowProps = {
  action: ActionsDashboardRowDataFragment
  withStatus?: boolean
  renderFooter?: () => React.ReactNode
  hasBottomBorder?: boolean
  hasContext?: boolean
}

export function ActionRow({
  action,
  withStatus = true,
  renderFooter,
  hasBottomBorder = true,
  hasContext = true,
}: ActionRowProps) {
  const parentTypeLabel =
    action.parent?.__typename === 'PrototypeFeedback' ? 'Feedback' : `${action.parent?.__typename}`
  const parentTitleLabel = action.parent?.title ? `${parentTypeLabel}: ${action.parent.title}` : ''

  const isPastDue =
    !!action.dueAt &&
    DateTime.fromMillis(action.dueAt).startOf('day') <= DateTime.now().minus({ days: 1 }).startOf('day')

  return (
    <ActionLinkWithMenu actionId={action.id}>
      <Flex
        row
        gap={1}
        grow
        sx={{
          minWidth: 0,
          paddingX: 1,
          paddingY: 1.5,
          ...(hasBottomBorder
            ? {
                borderBottom: '1px solid',
                borderBottomColor: 'border',
              }
            : {}),
        }}
      >
        <Flex column sx={{ flex: 1, minWidth: 0 }}>
          <Flex row gap={1}>
            {action.dateRescheduled ? (
              <CalendarRescheduleIcon color="system-text-warning" sx={{ marginTop: 0.5 }} />
            ) : null}

            <Text
              bold
              sx={{
                flex: 1,
                lineHeight: '1.5em',
                maxHeight: '3em',
                overflow: 'hidden',
                marginTop: 0.25,
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {action.title}
            </Text>
          </Flex>
          {parentTitleLabel && hasContext ? (
            <Flex row gap={0.5} sx={{ marginTop: 0.25, flexShrink: 0 }}>
              <LinkIcon width={1.5} height={1.5} color="text-light" sx={{ marginTop: 0.5 }} />
              <Text color="text-light" overflow="ellipsis" sx={{ maxWidth: 400, flexShrink: 0 }}>
                {parentTitleLabel}
              </Text>
            </Flex>
          ) : null}
        </Flex>
        <Flex row gap={2} sx={{ minWidth: 0, flexShrink: 0 }}>
          <Text color="text-light" sx={{ flexShrink: 0, marginTop: 0.25, textAlign: 'right' }}>
            Created {formatDateDisplay(action.createdAt)}
          </Text>

          {action.dueAt ? (
            <Text
              color={isPastDue && action.status === 'ACTIVE' ? 'system-text-warning' : 'text-light'}
              sx={{ flexShrink: 0, marginTop: 0.25, textAlign: 'right' }}
            >
              {isPastDue && action.status === 'ACTIVE' ? (
                <TimeAlarmClockReminder sx={{ marginRight: 0.5 }} width={1.5} height={1.5} />
              ) : null}
              Due {formatDateDisplay(action.dueAt)}
            </Text>
          ) : null}

          {withStatus ? <ActionStatusSelect actionId={action.id} /> : null}

          <div sx={{ margin: -0.5 }}>
            <ActionAssigneeCellContent actionId={action.id} assigneeId={action.assignee.id} avatarOnly />
          </div>
        </Flex>
        {renderFooter?.()}
      </Flex>
    </ActionLinkWithMenu>
  )
}

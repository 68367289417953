import { useFeatureFlags } from '@mm/company-ui/src'
import React from 'react'
import { AddGoodThingCommentDocument, GoodThingCommentsDocument } from '../../../gen/graphql/documents'
import { useMutation } from '../../apollo'
import { AddCommentBox, addRootCommentToCache, CommentsList, CommentsListProps } from '../../comments'

type AddGoodThingCommentBoxProps = {
  goodThingId: string
}

export const AddGoodThingCommentBox = ({ goodThingId }: AddGoodThingCommentBoxProps) => {
  const [addComment] = useMutation(AddGoodThingCommentDocument, {
    update: (cache, { data }) => {
      const comment = data?.createCommentOnGoodThing
      if (comment?.__typename === 'Comment') {
        addRootCommentToCache(cache, comment)
      }
    },
  })

  return (
    <AddCommentBox
      id={`goodThing:${goodThingId}`}
      onSubmit={async ({ htmlBody, author }) => {
        const { data } = await addComment({
          variables: {
            goodThingId,
            htmlBody,
            author,
          },
        })
        if (data?.createCommentOnGoodThing.__typename !== 'Comment') {
          throw new Error('failed to create a comment')
        }
      }}
    />
  )
}

export type GoodThingCommentsProps = Omit<
  CommentsListProps<unknown, unknown>,
  'query' | 'variables' | 'extractPage'
> & {
  goodThingId: string
}

export const GoodThingComments = ({ goodThingId }: GoodThingCommentsProps) => {
  const { flipComments } = useFeatureFlags()

  return (
    <CommentsList
      query={GoodThingCommentsDocument}
      variables={{ goodThingId }}
      extractPage={(data) => data.goodThing?.comments}
      order={flipComments ? 'oldest' : 'newest'}
      composer={!flipComments ? <AddGoodThingCommentBox goodThingId={goodThingId} /> : null}
    />
  )
}

import {
  AddWaitingForRowData,
  Datepicker,
  Editable,
  EditableFormController,
  Flex,
  Tag,
  Text,
  defaultItems,
} from '@mm/company-ui'
import { DateTime } from 'luxon'
import React, { useCallback, useRef } from 'react'
import { Controller, FieldArrayWithId, FieldError, FieldErrorsImpl, Merge, useFormContext } from 'react-hook-form'
import { CrudUpdateAction } from '../../../gen/graphql/documents'
import { ADD_ACTION } from '../../actions/capabilities'
import { useRegisterCapability } from '../../capabilities'
import { useComplexToolbar } from '../../editor/hooks/useComplexToolbar'
import { ActionRowProps } from './ActionRow'
import { CreateUpdateData } from './CreateUpdateForm'
import { EditUpdateData } from './EditUpdateForm'
import { NextActionsList, NextActionsListRef } from './NextActionsList'

export type UpdateActionData = CrudUpdateAction & { action: ActionRowProps['action'] }

export type UpdateActionFormProps = {
  field: FieldArrayWithId<CreateUpdateData | EditUpdateData, 'actions', 'id'>
  fieldIdentifier: `actions.${number}`
  error?: Merge<FieldError, FieldErrorsImpl<CreateUpdateData['actions'][number]>>
}

export function UpdateActionForm({ field, fieldIdentifier, error }: UpdateActionFormProps) {
  const {
    formState: { isSubmitting },
    control,
    watch,
    register,
    setValue,
    trigger,
  } = useFormContext<CreateUpdateData | EditUpdateData>()
  const nextActionsRef = useRef<NextActionsListRef>()

  const cb = useCallback(
    (data: AddWaitingForRowData) => {
      if (!nextActionsRef.current) {
        throw new Error('NextActionsListRef not available')
      }
      return nextActionsRef.current.addAction(data)
    },
    [nextActionsRef],
  )

  useRegisterCapability(ADD_ACTION, cb)
  const selectionToolbar = useComplexToolbar(defaultItems)

  const watchStatus = watch(`${fieldIdentifier}.status`)
  const watchRepeat = watch(`${fieldIdentifier}.repeat`)

  const handleClose = async () => {
    const pass = await trigger(fieldIdentifier)

    if (pass) {
      setValue(`${fieldIdentifier}.editing`, false)
    }
  }

  return (
    <>
      <Flex
        column
        sx={{
          border: '1px solid',
          borderColor: 'border',
          borderRadius: 'medium',
          backgroundColor: 'background',
        }}
      >
        <Flex
          row
          gap={1}
          sx={{
            border: '1px solid',
            borderColor: error?.status ? 'system-border-warning' : 'transparent',
            padding: 2,
          }}
        >
          <Text color="text-light" sx={{ display: 'block', width: 220, flexShrink: 0 }}>
            Did you get it done?
          </Text>
          <Controller
            name={`${fieldIdentifier}.status`}
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, ref } }) => {
              return (
                <div ref={ref} tabIndex={0}>
                  <Flex gap={1}>
                    <Tag
                      sx={{ width: 10, fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
                      variant={!watchStatus ? 'success' : watchStatus === 'DONE' ? 'success-inverted' : 'default'}
                      onClick={() => {
                        onChange('DONE')
                      }}
                    >
                      Yes
                    </Tag>
                    <Tag
                      sx={{ width: 10, fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
                      variant={!watchStatus ? 'warning' : watchStatus === 'NOT_DONE' ? 'warning-inverted' : 'default'}
                      onClick={() => {
                        onChange('NOT_DONE')
                      }}
                    >
                      No
                    </Tag>
                  </Flex>
                </div>
              )
            }}
          />
        </Flex>

        {watchStatus === 'DONE' ? (
          <>
            <Flex
              row
              gap={1}
              sx={{
                borderTop: '1px solid',
                borderTopColor: 'border',
                padding: 2,
                backgroundColor: 'background',
              }}
            >
              <Text color="text-light" sx={{ display: 'block', width: 220, flexShrink: 0 }}>
                Any key insights? <Text variant="small">(optional)</Text>
              </Text>
              <EditableFormController
                {...register(`${fieldIdentifier}.reasonHtml`, {
                  required: false,
                })}
              >
                {(field) => (
                  <Editable
                    {...field}
                    selectionToolbar={selectionToolbar}
                    mode="compact"
                    hidePlaceholderWhenFocused
                    editable={!isSubmitting}
                    placeholder="Share any insights or realizations"
                    hotkeys={{
                      'Meta-Enter': () => {
                        void handleClose()
                        return true
                      },
                    }}
                    sx={{ width: '100%', '> .ProseMirror': { padding: 2, margin: -2 } }}
                  />
                )}
              </EditableFormController>
            </Flex>

            <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
              <Flex
                row
                gap={1}
                sx={{
                  border: '1px solid',
                  borderColor: error?.nextActions ? 'system-border-warning' : 'transparent',
                  padding: 2,
                }}
              >
                <Text color="text-light" sx={{ display: 'block', width: 220, flexShrink: 0 }}>
                  Next actions <Text variant="small">(optional)</Text>
                </Text>

                <Flex column sx={{ flex: 1, minWidth: 0 }}>
                  <Controller
                    name={`${fieldIdentifier}.nextActions`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NextActionsList
                        ref={nextActionsRef}
                        actionId={field.action.id}
                        onCreate={(action) => {
                          onChange([...(value || []), action.id])
                        }}
                      />
                    )}
                  />
                </Flex>
              </Flex>
            </div>
          </>
        ) : null}

        {watchStatus === 'NOT_DONE' ? (
          <>
            <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
              <Flex
                row
                gap={1}
                sx={{
                  border: '1px solid',
                  borderColor: error?.reasonHtml ? 'system-border-warning' : 'transparent',
                  padding: 2,
                }}
              >
                <Text color="text-light" sx={{ display: 'block', width: 220, flexShrink: 0 }}>
                  What blocked you?
                </Text>
                <EditableFormController
                  {...register(`${fieldIdentifier}.reasonHtml`, {
                    required: true,
                    validate: (value) => !!value && value !== '<p></p>',
                  })}
                >
                  {(field) => (
                    <Editable
                      {...field}
                      selectionToolbar={selectionToolbar}
                      mode="compact"
                      hidePlaceholderWhenFocused
                      editable={!isSubmitting}
                      placeholder="e.g., I forgot to schedule it."
                      hotkeys={{
                        'Meta-Enter': () => {
                          void handleClose()
                          return true
                        },
                      }}
                      sx={{ width: '100%', '> .ProseMirror': { padding: 2, margin: -2 } }}
                    />
                  )}
                </EditableFormController>
              </Flex>
            </div>

            <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
              <Flex
                row
                gap={1}
                sx={{
                  border: '1px solid',
                  borderColor: error?.repeat ? 'system-border-warning' : 'transparent',
                  padding: 2,
                }}
              >
                <Text color="text-light" sx={{ display: 'block', width: 220, flexShrink: 0 }}>
                  Do you still want to do it?
                </Text>

                <Controller
                  name={`${fieldIdentifier}.repeat`}
                  rules={{
                    validate: (value) => {
                      if (value == null) {
                        return false
                      }

                      return true
                    },
                  }}
                  control={control}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <div ref={ref} tabIndex={0}>
                        <Flex gap={1}>
                          <Tag
                            sx={{ width: 10, fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
                            variant={value === true ? 'accent-inverted' : 'default'}
                            onClick={() => {
                              onChange(true)
                            }}
                          >
                            Yes
                          </Tag>
                          <Tag
                            sx={{ width: 10, fontWeight: 'bold', justifyContent: 'center', textTransform: 'uppercase' }}
                            variant={value === false ? 'accent-inverted' : 'default'}
                            onClick={() => {
                              onChange(false)
                            }}
                          >
                            No
                          </Tag>
                        </Flex>
                      </div>
                    )
                  }}
                />
              </Flex>
            </div>

            {watchRepeat ? (
              <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
                <Flex
                  row
                  gap={1}
                  sx={{
                    border: '1px solid',
                    borderColor: error?.repeatAt ? 'system-border-warning' : 'transparent',
                    padding: 2,
                  }}
                >
                  <Text color="text-light" sx={{ display: 'block', width: 220, flexShrink: 0 }}>
                    When will you do this?
                  </Text>

                  <Controller
                    name={`${fieldIdentifier}.repeatAt`}
                    control={control}
                    render={({ field }) => {
                      const { ref: _, ...restField } = field
                      return (
                        <Datepicker
                          placeholderValue={DateTime.local().plus({ days: 7 }).toJSDate()}
                          {...restField}
                          inputContainerStyle={{
                            width: 'auto',
                            border: '1px solid',
                            borderColor: 'border',
                          }}
                          value={field.value}
                          onChange={(date) => {
                            if (date) {
                              field.onChange(DateTime.fromJSDate(date).toMillis())
                            }
                          }}
                        />
                      )
                    }}
                  />
                </Flex>
              </div>
            ) : null}

            <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
              <Flex
                row
                gap={1}
                sx={{
                  border: '1px solid',
                  borderColor: error?.nextHtml ? 'system-border-warning' : 'transparent',
                  padding: 2,
                }}
              >
                <Text color="text-light" sx={{ display: 'block', width: 220, flexShrink: 0 }}>
                  How will you avoid getting blocked again? <Text variant="small">(optional)</Text>
                </Text>
                <EditableFormController {...register(`${fieldIdentifier}.nextHtml`)}>
                  {(field) => (
                    <Editable
                      {...field}
                      selectionToolbar={selectionToolbar}
                      mode="compact"
                      hidePlaceholderWhenFocused
                      editable={!isSubmitting}
                      placeholder="Write ideas for next actions"
                      hotkeys={{
                        'Meta-Enter': () => {
                          void handleClose()
                          return true
                        },
                      }}
                      sx={{ width: '100%', '> .ProseMirror': { padding: 2, margin: -2 } }}
                    />
                  )}
                </EditableFormController>
              </Flex>
            </div>

            <div sx={{ borderTop: '1px solid', borderTopColor: 'border' }}>
              <Flex
                row
                gap={1}
                sx={{
                  border: '1px solid',
                  borderColor: error?.nextActions ? 'system-border-warning' : 'transparent',
                  padding: 2,
                }}
              >
                <Text color="text-light" sx={{ display: 'block', width: 220, flexShrink: 0 }}>
                  Next actions <Text variant="small">(optional)</Text>
                </Text>

                <Flex column sx={{ flex: 1, minWidth: 0 }}>
                  <Controller
                    name={`${fieldIdentifier}.nextActions`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NextActionsList
                        ref={nextActionsRef}
                        actionId={field.action.id}
                        onCreate={(action) => {
                          onChange([...(value || []), action.id])
                        }}
                      />
                    )}
                  />
                </Flex>
              </Flex>
            </div>
          </>
        ) : null}
      </Flex>

      {/* <ButtonGroup size="small" containerProps={{ sx: { paddingTop: 2, justifyContent: 'flex-end' } }}>
        <Button variant="accent" onClick={handleClose}>
          Complete
        </Button>
      </ButtonGroup> */}
    </>
  )
}

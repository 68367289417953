import { Editor, KeyboardShortcutCommand } from '@tiptap/core'
import _ from 'lodash'
import { Plugin, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { useEffect, useRef, MutableRefObject } from 'react'

type WhitespaceKeys = 'Enter' | 'Tab' | ' '
type NavigationKeys = 'ArrowDown' | 'ArrowLeft' | 'ArrowRight' | 'ArrowUp' | 'End' | 'Home' | 'PageDown' | 'PageUp'
type EditingKeys = 'Backspace' | 'Clear' | 'Delete' | 'Insert'
type NumericKeys = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'
type FunctionKeys =
  | 'F1'
  | 'F2'
  | 'F3'
  | 'F4'
  | 'F5'
  | 'F6'
  | 'F7'
  | 'F8'
  | 'F9'
  | 'F10'
  | 'F11'
  | 'F12'
  | 'F13'
  | 'F14'
  | 'F15'
  | 'F16'
  | 'F17'
  | 'F18'
  | 'F19'
  | 'F20'
type UpperAlpha =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z'

type WithModifiers<K extends string> = `${'Ctrl-' | ''}${'Shift-' | ''}${'Alt-' | ''}${'Meta-' | ''}${K}`
type Key = NumericKeys | UpperAlpha | WhitespaceKeys | NavigationKeys | EditingKeys | FunctionKeys
export type Hotkey = WithModifiers<Key>
export type Bindings = { [K in Hotkey]?: KeyboardShortcutCommand }

export const HotkeysPluginKey = new PluginKey('hotkeys')

function createKeydownHandler(
  editor: Editor,
  ref: MutableRefObject<Bindings | undefined>,
): (view: EditorView, event: KeyboardEvent) => boolean {
  return (view, event) => {
    if (ref.current === undefined) {
      return false
    }

    // Technically not type safe as Key is only a subset of possible KeyboardEvent.key values. However in practice
    // unsupported values will simply be ignored
    let hotkey: Hotkey = _.upperFirst(event.key) as Key

    if (event.metaKey) hotkey = `Meta-${hotkey}`
    if (event.altKey) hotkey = `Alt-${hotkey}`
    if (event.shiftKey) hotkey = `Shift-${hotkey}`
    if (event.ctrlKey) hotkey = `Ctrl-${hotkey}`

    const fn = ref.current[hotkey]
    if (fn && fn({ editor })) return true

    return false
  }
}

export function useTiptapHotkeys(editor: Editor | null, bindings?: Bindings) {
  const ref = useRef(bindings)
  ref.current = bindings

  useEffect(() => {
    if (!editor) return undefined

    if (HotkeysPluginKey.get(editor.state) !== undefined) {
      return undefined
    }

    editor.registerPlugin(
      new Plugin({
        key: HotkeysPluginKey,
        props: { handleKeyDown: createKeydownHandler(editor, ref) },
      }),
      (plugin, plugins) => [plugin, ...plugins],
    )

    return () => {
      editor.unregisterPlugin(HotkeysPluginKey)
    }
  }, [editor])
}

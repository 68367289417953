import { Flex, NotFoundBanner, Suspender, Text } from '@mm/company-ui/src'
import React from 'react'
import { UserUpdatesDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useActiveCompanyId } from '../../companies'
import { UpdateView } from '../../updates'

type UserUpdatesProps = {
  userId: string
}

export function UserUpdates({ userId }: UserUpdatesProps) {
  const { activeCompanyId } = useActiveCompanyId()

  const { data, loading } = useQuery(UserUpdatesDocument, {
    variables: { userId, companyId: activeCompanyId, first: 2 },
  })
  if (data == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }
  const [recentUpdate] = data?.user?.updates?.edges.map(({ node }) => node) || []

  return (
    <Flex column sx={{ flex: 1, minWidth: 0 }} gap={6}>
      {recentUpdate ? (
        <UpdateView updateId={recentUpdate.id} inDrawer={false} />
      ) : (
        <Text color="text-disabled">No Updates</Text>
      )}
    </Flex>
  )
}

import { openGoalStatuses } from '@mm/common'
import { useFeatureFlags, useModal } from '@mm/company-ui'
import React, { useCallback } from 'react'
import {
  EditGoalStatusCommandDocument,
  EditGoalStatusCommandGetGoalDocument,
  GoalStatus,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'
import { GoalNotDoneModal } from '../components/GoalNotDoneModal'

type EditGoalStatusCommandValue = {
  goalId: string
  status: GoalStatus
  skipNotDoneFlow?: boolean
}

export const editGoalStatusCommand: Command<EditGoalStatusCommandValue> = {
  id: 'editGoalStatusCommand',
  name: 'Edit Status',
  canExecute: () => true,
  useExecute: () => {
    const { preventOldGoalAndActionUpdates } = useFeatureFlags()
    const { showModal } = useModal()
    return useCallback(
      async ({ goalId, status, skipNotDoneFlow }, { apolloClient }) => {
        if (!skipNotDoneFlow && openGoalStatuses.includes(status) && !preventOldGoalAndActionUpdates) {
          showModal(({ onRequestClose }) => (
            <GoalNotDoneModal goalId={goalId} onRequestClose={onRequestClose} initialStatus={status} />
          ))
          return
        }

        // Get current value from cache or refetch if needed
        const currentGoal = await apolloClient.query({
          query: EditGoalStatusCommandGetGoalDocument,
          variables: { goalId },
        })
        const currentStatus = currentGoal.data?.goal?.status

        await apolloClient.mutate({
          mutation: EditGoalStatusCommandDocument,
          variables: {
            goalId,
            status,
          },
          optimisticResponse: {
            updateGoal: {
              __typename: 'Goal',
              id: goalId,
              status,
            },
          },
        })

        if (currentStatus) {
          return {
            args: {
              goalId,
              status: currentStatus,
            },
            execute: async ({ goalId, status }, { apolloClient }) => {
              await apolloClient.mutate({
                mutation: EditGoalStatusCommandDocument,
                variables: {
                  goalId,
                  status,
                },
                optimisticResponse: {
                  updateGoal: {
                    __typename: 'Goal',
                    id: goalId,
                    status,
                  },
                },
              })
            },
          }
        }

        return
      },
      [showModal, preventOldGoalAndActionUpdates],
    )
  },
}

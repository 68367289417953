import { useToggleState, ToggleState } from '@react-stately/toggle'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

export const AnimateOpen = ({ children }: { children: React.ReactNode }) => (
  <motion.div
    variants={{
      shown: {
        opacity: 1,
        transitionEnd: {
          overflow: 'unset',
        },
        height: 'auto',
      },
      hidden: {
        opacity: 0,
        overflow: 'hidden',
        height: 0,
      },
    }}
    initial="hidden"
    animate="shown"
    exit="hidden"
    transition={{
      type: 'tween',
      duration: 0.2,
    }}
  >
    {children}
  </motion.div>
)

export type TogglerProps = {
  // For uncontrolled
  defaultSelected?: boolean
  // For controlled
  isSelected?: boolean

  children: (state: ToggleState) => React.ReactNode
  controls: (state: ToggleState) => React.ReactNode
  hideControlsWhenOpen?: boolean
  onChange?: (isSelected: boolean) => void

  className?: string
}

export const Toggler = ({
  controls,
  children,
  onChange,
  defaultSelected,
  hideControlsWhenOpen = false,
  isSelected,
  className,
}: TogglerProps) => {
  const state = useToggleState({ onChange, defaultSelected, isSelected })
  const showControls = hideControlsWhenOpen === false || (hideControlsWhenOpen === true && !state.isSelected)
  return (
    <div className={className}>
      <AnimatePresence initial={false}>
        {showControls ? <AnimateOpen>{controls(state)}</AnimateOpen> : null}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {state.isSelected ? <AnimateOpen>{children(state)}</AnimateOpen> : null}
      </AnimatePresence>
    </div>
  )
}

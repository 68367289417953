import { UnlockedIcon } from '@mm/company-ui-icons/src'
import { Tag, Tooltip } from '@mm/company-ui/src'
import React from 'react'

export const UpdatePrivacyTag = ({ showPrivacyLabel = true }: { showPrivacyLabel?: boolean }) => {
  return (
    <Tooltip
      position="bottom-start"
      description="Updates are public by default"
      sx={{ maxWidth: '300px', fontSize: 1, lineHeight: 1.5 }}
    >
      <Tag sx={{ background: 'none' }}>
        <UnlockedIcon /> {showPrivacyLabel && 'Public'}
      </Tag>
    </Tooltip>
  )
}

import { Button, Flex, formatDateDisplay, Suspender, Text } from '@mm/company-ui'
import _ from 'lodash'
import React, { useState } from 'react'
import { GoalUpdatesDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { UpdateViewGoalUpdate } from '../../updates/components/UpdateViewGoalUpdate'

export type GoalUpdatesProps = {
  goalId: string
  fillHeight?: boolean
  padding?: number
}

const PER_PAGE = 3

export const GoalUpdates = ({ goalId }: GoalUpdatesProps) => {
  const { data, loading, error } = useQuery(GoalUpdatesDocument, {
    variables: {
      goalId,
    },
  })

  const [isExpanded, setIsExpanded] = useState(false)

  const goal = data?.goal
  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('Goal not found')
  }

  return (
    <Flex column gap={2}>
      {(isExpanded ? goal.updates : _.take(goal.updates, PER_PAGE))?.map((update) => {
        return update ? (
          <Flex column key={update.id} gap={1}>
            <Text uppercase bold variant="small" color="text-light">
              {formatDateDisplay(update.createdAt, 'SHORT_MONTH_WITH_DATE')}
            </Text>

            <UpdateViewGoalUpdate
              goalUpdate={{
                ...update,
                __typename: 'UpdateGoal',
              }}
            />
          </Flex>
        ) : null
      })}

      {(goal.updates?.length || 0) > PER_PAGE ? (
        <Button
          sx={{ width: '100%', marginTop: 1 }}
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          {isExpanded ? 'View less' : 'View all'}
        </Button>
      ) : null}

      {goal.updates?.length === 0 ? (
        <Flex justify="center">
          <Text variant="small" color="text-light">
            No updates
          </Text>
        </Flex>
      ) : null}
    </Flex>
  )
}

import { ContextMenu, PopupWrapper } from '@mm/company-ui'
import React, { useState } from 'react'
import { GoalMoreMenu } from '../../goals/components/GoalMoreMenu'
import { useContextualDrawers } from '../../navigation'

export type GoalLinkWithMenuProps = {
  type?: 'a-goal' | 'goal'
  goalId: string
  hasHover?: boolean
  children: React.ReactNode
}

export function GoalLinkWithMenu({ type = 'a-goal', goalId, hasHover = true, children }: GoalLinkWithMenuProps) {
  const [menuVisible, setmenuVisible] = useState(false)
  const { showDrawer } = useContextualDrawers()

  return (
    <ContextMenu
      render={() => (
        <PopupWrapper sx={{ px: 0, py: 1 }}>
          <GoalMoreMenu id={goalId} />
        </PopupWrapper>
      )}
      onOpenChange={setmenuVisible}
    >
      {({ getReferenceProps }) => (
        <div
          {...getReferenceProps({
            onClick: (event) => {
              if (
                event.target instanceof HTMLButtonElement &&
                (event.target.dataset['testid'] === 'TagSelect__target' ||
                  event.target.dataset['testid'] === 'TagSelect__selection')
              ) {
                // Dont Open Drawer if clicking Status Picker
                return
              }
              showDrawer(type, goalId, event)
            },
          })}
          sx={{
            transition: '200ms background-color',
            cursor: 'pointer',
            borderRadius: 'medium',

            '&:hover': hasHover
              ? {
                  backgroundColor: 'background-light',
                }
              : {},

            ...(menuVisible
              ? {
                  backgroundColor: 'background-light',
                }
              : {}),
          }}
        >
          {children}
        </div>
      )}
    </ContextMenu>
  )
}

import { Avatar, Flex, formatUserDisplayName } from '@mm/company-ui'
import { DecisionIcon, FilePageTemplateIcon, GoalsIcon, LockedIcon, UnlockedIcon } from '@mm/company-ui-icons/src'
import React from 'react'
import { SearchResultRowFragment } from '../../../gen/graphql/documents'
import { DecisionStatusPicker } from '../../decisions/components/DecisionStatusPicker'
import { GoalStatusPicker } from '../../goals/components/GoalStatusPicker'
import { SearchPanel } from '../../search/components/SearchPanel'
import {
  DecisionRowProps,
  GoalRowProps,
  MeetingRowProps,
  ReadOnlyStatusCheckbox,
  SearchResultRowWrapper,
  SubText,
  TitleHighlightSubRow,
  TopicRowProps,
} from '../../search/components/SearchResultRows'

export type ContextSearchPanelProps = {
  handleRowClick?: (item: SearchResultRowFragment, event?: React.MouseEvent) => void
}
export const ContextSearchPanel = ({ handleRowClick }: ContextSearchPanelProps) => {
  return (
    <SearchPanel
      searchIn={['DECISION', 'GOAL', 'MEETING', 'TOPIC']}
      renderRow={(item) => {
        return <ActionContextSearchResultRow row={item} />
      }}
      handleRowClick={handleRowClick}
      sx={{
        maxHeight: 400,
      }}
    />
  )
}

const PrivacyIcon = ({ isPrivate = false }: { isPrivate: boolean }) => {
  return isPrivate ? <LockedIcon color="text-light" /> : <UnlockedIcon color="text-light" />
}

export const ContextMeetingRow = ({ titleHighlightSubRow, isPrivate }: MeetingRowProps) => {
  return (
    <SearchResultRowWrapper sx={{ width: '100%', alignItems: 'center' }}>
      <FilePageTemplateIcon color="text-light" />
      <Flex justify="space-between" align="center" sx={{ width: '100%' }}>
        {titleHighlightSubRow}
        <PrivacyIcon isPrivate={isPrivate || false} />
      </Flex>
    </SearchResultRowWrapper>
  )
}

export const ContextGoalRow = ({ id, titleHighlightSubRow, isPrivate, assignee }: GoalRowProps) => {
  return (
    <SearchResultRowWrapper>
      <GoalsIcon color="text-light" />
      <Flex justify="space-between" sx={{ width: '100%' }} column gap={1}>
        <Flex justify="space-between" sx={{ width: '100%' }} align="center">
          {titleHighlightSubRow}
          <PrivacyIcon isPrivate={isPrivate || false} />
        </Flex>
        <Flex sx={{ width: '100%' }} align="center" gap={0.5}>
          <GoalStatusPicker goalId={id}></GoalStatusPicker>
          {assignee && <Avatar name={formatUserDisplayName(assignee)}></Avatar>}
        </Flex>
      </Flex>
    </SearchResultRowWrapper>
  )
}

export const ContextDecisionRow = ({ id, titleHighlightSubRow, isPrivate, parent }: DecisionRowProps) => {
  const inMeetingName = parent?.__typename === 'Meeting' && parent.title ? `in ${parent?.title}` : undefined
  const subText = `Created ${inMeetingName} `

  return (
    <SearchResultRowWrapper>
      <DecisionIcon color="text-light" />
      <Flex justify="space-between" sx={{ width: '100%' }} column gap={1}>
        <Flex justify="space-between" sx={{ width: '100%' }} align="center">
          {titleHighlightSubRow}
          <PrivacyIcon isPrivate={isPrivate || false} />
        </Flex>
        {inMeetingName && <SubText content={subText} />}
        <Flex align="center" sx={{ width: '100%' }}>
          <DecisionStatusPicker decisionId={id}></DecisionStatusPicker>
        </Flex>
      </Flex>
    </SearchResultRowWrapper>
  )
}

export const ContextTopicRow = ({ topicStatus, titleHighlightSubRow, isPrivate, parent }: TopicRowProps) => {
  const inMeetingName = parent?.__typename === 'Meeting' && parent.title ? `in ${parent?.title}` : undefined
  const subText = `Created ${inMeetingName} `

  return (
    <SearchResultRowWrapper>
      <ReadOnlyStatusCheckbox type="topic" value={topicStatus == 'DISCUSSED' ? 'resolved' : 'active'} />
      <Flex justify="space-between" sx={{ width: '100%' }} column gap={1}>
        <Flex justify="space-between" row align="center" sx={{ width: '100%' }}>
          {titleHighlightSubRow}
          <PrivacyIcon isPrivate={isPrivate || false} />
        </Flex>
        {inMeetingName && <SubText content={subText} />}
      </Flex>
    </SearchResultRowWrapper>
  )
}

const ActionContextSearchResultRow = ({ row }: { row: SearchResultRowFragment }) => {
  const { node, highlights } = row
  const titleHighlightSubRow = <TitleHighlightSubRow title={node.title} highlights={highlights} />

  switch (node.__typename) {
    case 'Meeting': {
      return <ContextMeetingRow {...node} titleHighlightSubRow={titleHighlightSubRow} />
    }
    case 'Goal': {
      return <ContextGoalRow {...node} titleHighlightSubRow={titleHighlightSubRow} />
    }
    case 'Decision': {
      return <ContextDecisionRow {...node} titleHighlightSubRow={titleHighlightSubRow} />
    }
    case 'Topic': {
      return <ContextTopicRow {...node} titleHighlightSubRow={titleHighlightSubRow} />
    }
    default:
      return titleHighlightSubRow
  }
}

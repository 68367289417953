import {
  Button,
  ButtonGroup,
  DRAWER_SECTION_INNER_PADDING,
  DrawerLayout,
  DrawerSection,
  Popover,
  PopupWrapper,
  Suspender,
  Text,
  useModal,
} from '@mm/company-ui'
import { ActionsIcon, CommentChatIcon, EllipsisIcon, FilePageTemplateIcon, InfoIcon } from '@mm/company-ui-icons'
import { DateTime } from 'luxon'
import React, { useCallback, useState } from 'react'
import { GoalMetadataPanelDocument } from '../../../gen/graphql/documents'
import { GoalActions } from '../../actions'
import { useQuery } from '../../apollo'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { DrawerView } from '../../components/DrawerView'
import { useFeatureFlags } from '../../featureFlags'
import { ActiveResourceContextProvider } from '../../hooks/useActiveResource'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'
import { Banner } from '../../meetings/components/MeetingUpdateRequestBanner'
import { ContextualDrawerNavigation, useContextualDrawers } from '../../navigation'
import { AddGoalCommentBox } from './AddGoalCommentBox'
import { GoalComments } from './GoalComments'
import { GoalDescription } from './GoalDescription'
import { GoalMetadata } from './GoalMetadata'
import { GoalMetadataSummary } from './GoalMetadataSummary'
import { GoalMoreMenu } from './GoalMoreMenu'
import { GoalNotDoneModal } from './GoalNotDoneModal'
import { GoalPrivacyTag } from './GoalPrivacyTag'
import { GoalTitle } from './GoalTitle'
import { GoalTitleSection } from './GoalTitleSection'
import { GoalUpdates } from './GoalUpdates'

export type SingleGoalPageProps = {
  goalId: string
}

export const SingleGoalPage = ({ goalId }: SingleGoalPageProps) => {
  const [now] = useState(() => DateTime.now())
  const { showModal } = useModal()
  const {
    metadataSummary,
    commentsActionsInDrawersCollapsedWhenEmpty,
    reconfigureDrawerViews,
    moveGoalUpdateTriggerToDrawer,
    filterGoalActions,
    flipComments,
    preventOldGoalAndActionUpdates,
  } = useFeatureFlags()

  const { active, hideDrawer } = useContextualDrawers()
  const onGoalDeleted = useCallback(() => {
    if (active) hideDrawer()
  }, [active, hideDrawer])

  const { data, loading, error } = useQuery(GoalMetadataPanelDocument, {
    variables: {
      id: goalId,
    },
  })

  const goal = data?.goal

  const { hasComments, commentsLabel } = useTotalComments(goal)
  const { hasActions, actionsLabel } = useTotalActions(goal)

  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('Goal not found')
  }

  const statusWasUpdatedSinceCreation = goal.statusUpdatedAt != null && goal.statusUpdatedAt > goal.createdAt
  const recentlyUpdated =
    statusWasUpdatedSinceCreation &&
    goal.statusUpdatedAt != null &&
    now.diff(DateTime.fromMillis(goal.statusUpdatedAt)).as('days') < 7

  if (reconfigureDrawerViews) {
    return (
      <ActiveResourceContextProvider value={{ type: 'Gaol', id: goalId, privacy: goal.privacy }}>
        <DrawerView
          resourceId={goalId}
          resourceType="goals"
          renderMoreMenu={() => <GoalMoreMenu id={goalId} onGoalDeleted={onGoalDeleted} />}
          renderExtraMenuItems={
            <>
              <GoalPrivacyTag goalId={goalId} sx={{ background: 'none' }} />
            </>
          }
          renderBanner={
            moveGoalUpdateTriggerToDrawer && !recentlyUpdated && !preventOldGoalAndActionUpdates ? (
              <Banner
                label="What's the status of this goal?"
                action={
                  <Button
                    onClick={() => {
                      showModal((props) => <GoalNotDoneModal {...props} goalId={goalId} />)
                    }}
                  >
                    Write an update
                  </Button>
                }
              />
            ) : null
          }
          renderTitle={<GoalTitle id={goalId} cached sx={{ fontSize: 2 }} />}
          renderMetadata={<GoalMetadata goalId={goalId} />}
          renderDescription={<GoalDescription goalId={goalId} fillHeight={false} padding={1} />}
          renderActions={!filterGoalActions ? <GoalActions goalId={goalId} /> : undefined}
          renderActionsWithFilter={filterGoalActions ? <GoalActions isFiltered goalId={goalId} /> : undefined}
          renderUpdates={<GoalUpdates goalId={goalId} />}
          renderComments={
            <GoalComments goalId={goalId} order={flipComments ? 'oldest' : 'newest'} fixedAndScroll={false} />
          }
          renderCommentComposer={flipComments ? <AddGoalCommentBox goalId={goalId} /> : null}
        />
      </ActiveResourceContextProvider>
    )
  }

  return (
    <DrawerLayout
      title={data?.goal?.title ?? ''}
      navigation={<ContextualDrawerNavigation />}
      renderTitleButtonGroup={(props) => (
        <ButtonGroup {...props}>
          <CloseDrawerButton resourceType="goals" />

          <ExpandCollapseToggle resourceType="goals" id={goalId} />

          <Popover
            placement="bottom-start"
            render={() => (
              <PopupWrapper sx={{ px: 0, py: 1 }}>
                <GoalMoreMenu id={goalId} onGoalDeleted={onGoalDeleted} />
              </PopupWrapper>
            )}
          >
            {({ getReferenceProps }) => (
              <Button variant="muted" startIcon={<EllipsisIcon />} textHidden size="small" {...getReferenceProps()}>
                Open menu
              </Button>
            )}
          </Popover>
        </ButtonGroup>
      )}
      renderTitleSection={(props) => <GoalTitleSection goalId={goalId} {...props} />}
    >
      <DrawerSection
        title="About"
        icon={<InfoIcon />}
        isClosedOnRender={metadataSummary}
        right={metadataSummary ? <GoalMetadataSummary goalId={goalId} /> : null}
        dangerouslyCustomizeChildrenPadding={{
          paddingY: DRAWER_SECTION_INNER_PADDING - 1, // To Account for inconsistent Y padding of Metadata
        }}
      >
        <GoalMetadata goalId={goalId} />
      </DrawerSection>

      <DrawerSection
        title="Description"
        icon={<FilePageTemplateIcon />}
        isClosedOnRender
        dangerouslyCustomizeChildrenPadding={{
          padding: 0, // Since content is a textarea, padding should be handled by that
        }}
      >
        <GoalDescription goalId={goalId} fillHeight={false} padding={DRAWER_SECTION_INNER_PADDING} />
      </DrawerSection>

      <DrawerSection
        id="actions"
        title="Actions"
        icon={<ActionsIcon />}
        isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasActions}
        right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{actionsLabel}</Text> : null}
        dangerouslyCustomizeChildrenPadding={{
          padding: 0, // Since action table has borders that are full-width, it handles its own padding
        }}
      >
        <GoalActions goalId={goalId} />
      </DrawerSection>

      <DrawerSection
        id="comments"
        title="Comments"
        icon={<CommentChatIcon />}
        isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasComments}
        right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{commentsLabel}</Text> : null}
        dangerouslyCustomizeChildrenPadding={{
          padding: DRAWER_SECTION_INNER_PADDING - 1, // Since comments have extra padding for hover effects, offset here
        }}
      >
        <GoalComments goalId={goalId} order="newest" fixedAndScroll={false} />
      </DrawerSection>
    </DrawerLayout>
  )
}

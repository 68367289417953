import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core'
import { NodeType } from 'prosemirror-model'

const LOOM_REGEX = /^https:\/\/www\.loom\.com\/embed\/(.+)$/
const LOOM_SHARE_REGEX = /^https:\/\/www\.loom\.com\/share\/(.+)$/g

export const LoomExtension = Node.create({
  name: 'iframe',
  group: 'block',
  draggable: true,
  atom: true,
  addOptions() {
    return {
      src: null,
      allowFullscreen: true,
    }
  },
  addPasteRules() {
    return [
      nodePasteRule({
        find: LOOM_SHARE_REGEX,
        type: this as unknown as NodeType,
        getAttributes: (match) => {
          const id = match[1]
          const embedURL = `https://www.loom.com/embed/${id}`
          return {
            src: embedURL,
          }
        },
      }),
    ]
  },
  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (e) => {
          const src = e.getAttribute('src')
          return src != null && LOOM_REGEX.test(src) ? src : null
        },
      },
      frameborder: {
        default: 0,
      },

      allowfullscreen: {
        // eslint-disable-next-line
        default: this['options'].allowFullscreen,
        // eslint-disable-next-line
        parseHTML: () => this['options'].allowFullscreen,
      },
      width: {
        default: 640,
      },
      height: {
        default: 400,
      },
    }
  },
  parseHTML() {
    return [
      {
        tag: 'iframe[data-loom]',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    const src = HTMLAttributes['src'] as string

    return src != null && LOOM_REGEX.test(src)
      ? // eslint-disable-next-line
        ['div', ['iframe', mergeAttributes({ 'data-loom': '' }, this.options.HTMLAttributes, HTMLAttributes)]]
      : ['p']
  },
})

import React from 'react'
import { CommentEmojiReactionsDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { EmojiReactions } from '../../components/emoji/EmojiReactions'
import { useAddCommentEmojiReaction } from '../hooks/useAddCommentEmojiReaction'
import { useRemoveCommentEmojiReaction } from '../hooks/useRemoveCommentEmojiReaction'

export type CommentEmojiReactionsProps = {
  id: string
  cached?: boolean
}

export const CommentEmojiReactions = ({ id, cached }: CommentEmojiReactionsProps) => {
  const { data } = useQuery(CommentEmojiReactionsDocument, {
    variables: { id },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const addReaction = useAddCommentEmojiReaction(id)
  const removeReaction = useRemoveCommentEmojiReaction(id)

  const emojiReactions = data?.comment?.emojiReactions

  if (emojiReactions == null || emojiReactions.length === 0) {
    return null
  }

  return (
    <EmojiReactions
      testid="CommentEmojiReactions"
      reactions={emojiReactions}
      onAdd={addReaction}
      onRemove={removeReaction}
    />
  )
}

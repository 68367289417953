import { Datepicker, Suspender } from '@mm/company-ui'
import React from 'react'
import { GoalCreatedDatePickerDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'

export type GoalCreatedDatePickerProps = {
  goalId: string
  cached?: boolean
}

export const GoalCreatedDatePicker = ({ goalId, cached }: GoalCreatedDatePickerProps) => {
  const { data, loading, error } = useQuery(GoalCreatedDatePickerDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      goalId,
    },
  })

  const goal = data?.goal
  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Goal not found')
  }

  return <Datepicker value={goal.createdAt} disabled />
}

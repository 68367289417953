import { defaultInlineItems, SingleLineEditable, useDebouncedCallback } from '@mm/company-ui'
import { Editor } from '@tiptap/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useComplexToolbar } from '../editor/hooks/useComplexToolbar'

export type ControlledHtmlTitleInputProps = {
  value: string
  placeholder?: string
  className?: string
  onDebouncedChange?: (value: string) => void
}

export const ControlledHtmlTitleInput = ({
  value,
  placeholder,
  className,
  onDebouncedChange,
}: ControlledHtmlTitleInputProps) => {
  const [handleChange, controls] = useDebouncedCallback(onDebouncedChange, {
    timeout: 500,
    onUnmount: 'flush',
  })
  const editorRef = useRef<Editor>(null)
  const [focused, setFocused] = useState(false)
  const [toolbarOpen, setToolbarOpen] = useState(false)

  const editing = focused || toolbarOpen

  useEffect(() => {
    const editor = editorRef.current
    if (!editing) {
      editor?.commands.setContent(value)
    }
  }, [value, editing])

  const handleFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setFocused(false)
    if (!toolbarOpen) {
      controls.flush()
    }
  }, [toolbarOpen, controls])

  const handleToolbarOpen = useCallback(
    (open: boolean) => {
      setToolbarOpen(open)
      if (!open && !focused) {
        controls.flush()
      }
    },
    [focused, controls],
  )

  const toolbarItems = useComplexToolbar(defaultInlineItems)

  return (
    <SingleLineEditable
      variant="underline"
      ref={editorRef}
      initialValue={value}
      placeholder={placeholder}
      selectionToolbar={toolbarItems}
      className={className}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onToolbarOpen={handleToolbarOpen}
    />
  )
}

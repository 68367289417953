import { DateTime } from 'luxon'

export type DisplayDateSchemes =
  | 'SHORT_MONTH_WITH_DATE'
  | 'SHORT_MONTH_NO_DATE'
  | 'RELATIVE'
  | 'MED_WITH_WEEKDAY'
  | 'MED_DATE_WITH_WEEKDAY'
  | 'MED_WITHOUT_YEAR'
  | 'WEEKDAY_ONLY'
  | 'DAY_ONLY'
  | 'DAY_WITH_MONTH'

export const formatDateDisplay = (
  datelike: Date | number | DateTime,
  scheme: DisplayDateSchemes = 'SHORT_MONTH_WITH_DATE',
): string => {
  const dateTime =
    typeof datelike === 'number'
      ? DateTime.fromMillis(datelike)
      : datelike instanceof Date
      ? DateTime.fromJSDate(datelike)
      : datelike

  switch (scheme) {
    case 'SHORT_MONTH_WITH_DATE': {
      const isDifferentYear = dateTime.year !== DateTime.now().year
      return dateTime.toLocaleString({ month: 'short', day: 'numeric', year: isDifferentYear ? 'numeric' : undefined })
    }
    case 'SHORT_MONTH_NO_DATE': {
      const isDifferentYear = dateTime.year !== DateTime.now().year
      return dateTime.toLocaleString({ month: 'short', day: undefined, year: isDifferentYear ? 'numeric' : undefined })
    }
    case 'RELATIVE': {
      const diff = dateTime.toMillis() - Date.now()

      if (diff > 0) {
        return dateTime.toRelative({ base: DateTime.now() }) || ''
      } else {
        return -diff < 10000 ? 'just now' : dateTime.toRelative() || ''
      }
    }
    case 'MED_WITH_WEEKDAY': {
      return dateTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
    }
    case 'MED_DATE_WITH_WEEKDAY': {
      return dateTime.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
    }
    case 'MED_WITHOUT_YEAR': {
      return dateTime.toLocaleString({
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      })
    }
    case 'WEEKDAY_ONLY': {
      return dateTime.toFormat('EEE')
    }
    case 'DAY_ONLY': {
      return dateTime.toFormat('d')
    }
    case 'DAY_WITH_MONTH': {
      return dateTime.toFormat('d MMM')
    }
  }
}

import { PanelMetadataContainer, PanelMetadataItem, Suspender } from '@mm/company-ui'
import { CalendarIcon, CircleCheckmarkIcon, UserProfileIcon } from '@mm/company-ui-icons'
import React from 'react'
import { ActionMetadataDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useFeatureFlags } from '../../featureFlags'
import { ActionMetadataFeedback } from '../../prototypeFeedback/components/ActionMetadataFeedback'
import { ActionDatepicker } from './ActionDatepicker'
import { ActionMetadataAssignee } from './ActionMetadataAssignee'
import { ActionMetadataDecisions } from './ActionMetadataDecisions'
import { ActionMetadataFollowUpTo } from './ActionMetadataFollowUpTo'
import { ActionMetadataGoals } from './ActionMetadataGoals'
import { ActionMetadataMeetings } from './ActionMetadataMeetings'
import { ActionMetadataTopics } from './ActionMetadataTopics'
import { ActionMetadataWaitingFor } from './ActionMetadataWaitingFor'
import { ActionStatusTagSelect } from './ActionStatusTagSelect'
import { AddActionContextButton } from './AddActionContextButton'

export type ActionMetadataProps = {
  actionId: string
  cached?: boolean
}

export const ActionMetadata = ({ actionId, cached }: ActionMetadataProps) => {
  const { prototypeFeedback, updateActionContext } = useFeatureFlags()
  const { data, loading, error } = useQuery(ActionMetadataDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id: actionId,
    },
  })

  const action = data?.action

  if (action == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Action not found')
  }

  return (
    <PanelMetadataContainer sx={{ gap: 1.5 }}>
      <div>
        <PanelMetadataItem title="Status" sx={{ alignItems: 'center' }} icon={<CircleCheckmarkIcon />}>
          <ActionStatusTagSelect actionId={actionId} />
        </PanelMetadataItem>

        <PanelMetadataItem title="Due date" icon={<CalendarIcon />}>
          <ActionDatepicker cached id={actionId} />
        </PanelMetadataItem>
        <PanelMetadataItem title="Assignee" icon={<UserProfileIcon />}>
          <ActionMetadataAssignee cached actionId={actionId} />
        </PanelMetadataItem>
        <PanelMetadataItem title="Waiting" icon={<UserProfileIcon />}>
          <ActionMetadataWaitingFor cached actionId={actionId} />
        </PanelMetadataItem>

        {/* <PanelMetadataItem title="Privacy" sx={{ alignItems: 'center' }} icon={<EyeViewOpen />}>
        <ActionPrivacyTag actionId={actionId} />
      </PanelMetadataItem> */}
        <ActionMetadataTopics actionId={actionId} />
        <ActionMetadataMeetings actionId={actionId} />
        <ActionMetadataGoals actionId={actionId} />
        <ActionMetadataDecisions actionId={actionId} />
        {prototypeFeedback && <ActionMetadataFeedback actionId={actionId} />}

        <ActionMetadataFollowUpTo cached actionId={actionId} />
      </div>
      {updateActionContext && (
        <div>
          <AddActionContextButton actionId={actionId} />
        </div>
      )}
    </PanelMetadataContainer>
  )
}

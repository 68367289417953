import React, { useMemo } from 'react'
import { GoalDecisionsDocument } from '../../../gen/graphql/documents'
import { useActiveCompanyId } from '../../companies'
import { CreateGoalDecisionModal } from './CreateGoalDecisionModal'
import { DecisionDashboardColumns, DecisionsDashboard, DecisionsDashboardProps } from './DecisionsDashboard'

export type GoalDecisionsProps = Pick<
  DecisionsDashboardProps<any, any>,
  'variant' | 'renderEmptyPlaceholder' | 'createdAfter'
> & {
  goalId: string
  onDecisionCreated?: (id: string) => void
}

export const GoalDecisions = ({ goalId, onDecisionCreated, ...rest }: GoalDecisionsProps) => {
  const { activeCompanyId: companyId } = useActiveCompanyId()
  const columns = useMemo(() => [DecisionDashboardColumns.Title, DecisionDashboardColumns.Status], [])
  return (
    <DecisionsDashboard
      query={GoalDecisionsDocument}
      queryField="goal"
      variables={{ goalId, companyId }}
      matchDecision={(decision) => decision.parent?.id === goalId}
      renderNewDecisionModal={(props) => (
        <CreateGoalDecisionModal goalId={goalId} onComplete={onDecisionCreated} {...props} />
      )}
      overrideColumns={columns}
      {...rest}
    />
  )
}

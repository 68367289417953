import React from 'react'
import { Flex } from '../Flex'
import { ButtonProps } from './Button'

export type ButtonGroupProps = {
  children?: React.ReactElement<ButtonProps> | Array<React.ReactElement<ButtonProps> | null | undefined | false> | null
  gap?: number
  containerProps?: React.ComponentPropsWithoutRef<'div'>
} & Omit<Partial<ButtonProps>, 'children'>

export const ButtonGroup = ({ children, gap = 1, containerProps, ...restButtonProps }: ButtonGroupProps) => {
  return (
    <Flex row align="center" gap={gap} {...containerProps}>
      {React.Children.map(children, (child) =>
        child ? React.cloneElement(child, { ...restButtonProps, ...child.props }) : null,
      )}
    </Flex>
  )
}

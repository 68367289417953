import { Flex, ModalLabel } from '@mm/company-ui'
import { useId } from '@react-aria/utils'
import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { CreateDecisionData } from '../types'

export type DecisionParticipantField = 'observers' | 'contributors'

export type CreateDecisionParticipantsProps = {
  participantType: DecisionParticipantField
  control: Control<CreateDecisionData>
}

const participantTypeMap = {
  observers: {
    label: 'Observers',
    name: 'observers',
  },
  contributors: {
    label: 'Commenters',
    name: 'contributors',
  },
}

export const CreateDecisionParticipants = ({ participantType, control }: CreateDecisionParticipantsProps) => {
  const { label } = participantTypeMap[participantType]

  const labelId = useId()

  return (
    <Flex>
      <ModalLabel id={labelId} label={label} />
      <Controller
        name={participantType}
        control={control}
        render={({ field }) => {
          return (
            <UserSelectWithCompanyMembers
              aria-labelledby={labelId}
              zIndexOverride={1000}
              multiple
              value={field.value}
              onChange={(userIds) => {
                field.onChange(userIds)
              }}
            />
          )
        }}
      />
    </Flex>
  )
}

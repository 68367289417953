import { useFeatureFlags } from '@mm/company-ui/src'
import React, { useCallback, useMemo } from 'react'
import { CreateActionInput, TopicActionsDocument } from '../../../gen/graphql/documents'
import { useCommand } from '../../commands'
import { createActionOnTopicCommand } from '../commands/createActionOnTopic'
import { ActionsDashboardFlat, createDataSource } from './ActionsDashboard'

export type TopicActionsProps = {
  id: string
  skipRegisteringCapabilities?: boolean
  renderEmptyPlaceholder?: () => React.ReactElement
}

export const TopicActions = ({
  id,
  skipRegisteringCapabilities = false,
  renderEmptyPlaceholder,
}: TopicActionsProps) => {
  const createActionOnTopic = useCommand(createActionOnTopicCommand)
  const dataSource = useTopicActionsDataSource(id)

  const handleAddAction = useCallback(
    (actionPayload: CreateActionInput) =>
      createActionOnTopic.execute({
        topicId: id,
        data: actionPayload,
      }),
    [createActionOnTopic, id],
  )

  return (
    <div sx={{ height: '100%' }}>
      <ActionsDashboardFlat
        dataSource={dataSource}
        onCreateAction={handleAddAction}
        skipRegisteringCapabilities={skipRegisteringCapabilities}
        renderEmptyPlaceholder={renderEmptyPlaceholder}
      />
    </div>
  )
}

const useTopicActionsDataSource = (topicId: string) => {
  const { updateActionContext } = useFeatureFlags()

  return useMemo(
    () =>
      createDataSource({
        query: TopicActionsDocument,
        extractActions: (data) => data?.topic?.actions,
        matchAction: (action) =>
          (updateActionContext && action.topics?.edges.some(({ node }) => node.id === topicId)) ||
          (action.parent?.__typename === 'Topic' && action.parent.id === topicId),
        variables: {
          id: topicId,
        },
      }),
    [topicId, updateActionContext],
  )
}

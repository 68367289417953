import { isGraphQLError } from '@mm/graphql-helpers/src'
import { DeleteGoodThingCommandDocument, GetGoodThingCommandDocument } from '../../../gen/graphql/documents'
import { evictCache } from '../../apollo'
import { Command } from '../../commands'

export type DeleteGoodThingCommandValue = {
  goodThingId: string
  onGoodThingDeleted?: () => void
}

export const deleteGoodThingCommand: Command<DeleteGoodThingCommandValue> = {
  id: 'deleteGoodThingCommand',
  name: 'Delete Good Thing',
  canExecute: async ({ goodThingId }, { apolloClient }) => {
    const query = await apolloClient.query({ query: GetGoodThingCommandDocument, variables: { id: goodThingId } })
    return !!query.data.goodThing
  },
  execute: async ({ goodThingId, onGoodThingDeleted }, { apolloClient }) => {
    const { data } = await apolloClient.mutate({
      mutation: DeleteGoodThingCommandDocument,
      variables: {
        id: goodThingId,
      },
      update: (cache, { data }) => {
        if (data?.deleteGoodThing.__typename === 'Success') {
          evictCache(cache, 'GoodThing', goodThingId)
          onGoodThingDeleted?.()
        }
      },
      optimisticResponse: {
        deleteGoodThing: {
          __typename: 'Success',
        },
      },
    })
    if (data?.deleteGoodThing && isGraphQLError(data?.deleteGoodThing)) {
      alert(`Delete good thing failed: ${data?.deleteGoodThing.message ?? 'unknown error'}`)
    } else if (data?.deleteGoodThing.__typename !== 'Success') {
      alert('Delete good thing failed: unknown error')
    }
  },
}

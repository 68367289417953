import { Suspender } from '@mm/company-ui'
import { convertHtmlToTextTitle, sanitizeTitleHtmlContent } from '@mm/tiptap-extension'
import React, { useCallback } from 'react'
import { GoalTitleDocument, GoalTitleUpdateDocument } from '../../../gen/graphql/documents'
import { useMutation, useQuery } from '../../apollo'
import { ControlledHtmlTitleInput } from '../../components/ControlledHtmlTitleInput'

export type GoalTitleProps = {
  id: string
  cached?: boolean
  className?: string
}

export const GoalTitle = ({ id, cached, className }: GoalTitleProps) => {
  const { data, loading, error } = useQuery(GoalTitleDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      id,
    },
  })

  const [updateTitle] = useMutation(GoalTitleUpdateDocument, {
    optimisticResponse: ({ id, title }) => ({
      updateGoal: {
        __typename: 'Goal' as const,
        id,
        title: convertHtmlToTextTitle(title),
        titleHtml: sanitizeTitleHtmlContent(title),
      },
    }),
  })

  const handleChange = useCallback(
    (title: string) => {
      void updateTitle({
        variables: {
          id,
          title,
        },
      })
    },
    [id, updateTitle],
  )

  const goal = data?.goal
  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('Goal not found')
  }

  return (
    <ControlledHtmlTitleInput
      className={className}
      sx={{ width: '100%', fontSize: 2 }}
      value={goal.titleHtml}
      placeholder="Type goal title here..."
      onDebouncedChange={handleChange}
    />
  )
}

import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const UndoIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="undo" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.534 11.6389C17.534 14.2472 15.4196 16.3616 12.8113 16.3616L7.18857 16.3616C6.69151 16.3616 6.28857 16.7646 6.28857 17.2616C6.28857 17.7587 6.69151 18.1616 7.18857 18.1616L12.8113 18.1616C16.4137 18.1616 19.334 15.2413 19.334 11.6389C19.334 8.03651 16.4137 5.1162 12.8113 5.1162L3.7385 5.1162L6.21833 2.63638C6.5698 2.2849 6.5698 1.71506 6.21833 1.36358C5.86686 1.01211 5.29701 1.01211 4.94554 1.36358L0.929317 5.3798C0.877216 5.4319 0.83216 5.48973 0.794768 5.55182C0.738738 5.64465 0.698999 5.74843 0.679511 5.85919C0.670388 5.91067 0.665712 5.96319 0.665712 6.0162C0.665712 6.0692 0.670388 6.12173 0.679511 6.17322C0.699391 6.28621 0.740352 6.39195 0.798188 6.48621C0.834897 6.54616 0.878793 6.60207 0.929317 6.65259L4.94554 10.6688C5.29701 11.0203 5.86686 11.0203 6.21833 10.6688C6.5698 10.3173 6.5698 9.74749 6.21833 9.39602L3.73851 6.9162L12.8113 6.9162C15.4196 6.9162 17.534 9.03063 17.534 11.6389Z"
    />
  </IconBase>
)

import { openDecisionStatuses } from '@mm/common'
import { Datepicker, DatepickerProps, Tooltip } from '@mm/company-ui'
import { CalendarRescheduleIcon, StopwatchTimerIcon } from '@mm/company-ui-icons'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { DecisionStatus } from '../../../gen/graphql/documents'

export type DecisionDatepickerProps = {
  value: Date
  onChange?: (value: Date | null) => void

  decisionStatus: DecisionStatus
  dateRescheduled: boolean

  showFooter?: boolean
  disabled?: boolean
  showIcon?: boolean
  redIfPastDue?: boolean
  textAlign?: 'left' | 'right'
  variant?: 'default' | 'muted' | 'underline' | 'button'
  showRescheduledPrefix?: boolean
  disabledDays?: DatepickerProps['disabledDays']
}

export const DecisionDatepicker = ({
  value,
  onChange,
  decisionStatus,
  dateRescheduled,
  textAlign,
  redIfPastDue,
  showRescheduledPrefix,
  disabledDays,
  ...props
}: DecisionDatepickerProps) => {
  const [now] = useState(() => DateTime.now())
  const isPastDue = DateTime.fromJSDate(value).startOf('day') <= now.startOf('day')

  const showPastDue =
    isPastDue && redIfPastDue && openDecisionStatuses.includes(decisionStatus) && decisionStatus !== 'IMPLEMENTING'
  const showRescheduled = showRescheduledPrefix && dateRescheduled

  return (
    <Datepicker
      {...props}
      disabledDays={disabledDays}
      value={value}
      inputContainerStyle={{ color: showPastDue || showRescheduled ? 'system-text-warning' : '', width: 100 }}
      inputStyle={{ textAlign, color: showPastDue || showRescheduled ? 'system-text-warning' : '' }}
      prefix={
        showRescheduled ? (
          <Tooltip description="Rescheduled" position="top">
            <CalendarRescheduleIcon />
          </Tooltip>
        ) : showPastDue ? (
          <Tooltip description="Past Due" position="top">
            <StopwatchTimerIcon />
          </Tooltip>
        ) : (
          ''
        )
      }
      onChange={onChange}
    />
  )
}

import { toast } from '@mm/company-ui'
import { CreateTopicCommandDocument, CreateTopicMutationInput } from '../../../gen/graphql/documents'
import { capture } from '../../analytics'
import { Command } from '../../commands'
import { addGenericTopicToCache } from '../../meetings/utils/addGenericTopicToCache'

type CreateTopicCommandValue = {
  data: CreateTopicMutationInput
}

export const createTopicCommand: Command<CreateTopicCommandValue> = {
  id: 'createTopicCommand',
  name: 'Create Topic',
  canExecute: () => {
    return true
  },
  execute: async ({ data }, { apolloClient }) => {
    const { errors } = await apolloClient.mutate({
      mutation: CreateTopicCommandDocument,
      variables: {
        data,
      },
      update: (cache, results) => {
        if (results.data?.createTopic.__typename === 'Topic') {
          capture('Topic Created', {})

          addGenericTopicToCache(cache, results.data.createTopic, data.meetingTopicSectionId)
          return
        }
      },
    })

    if (errors?.length) {
      errors.map(({ message }) => {
        toast(`There was a problem creating the topic: ${message}`)
      })
    }
  },
}

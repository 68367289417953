import React from 'react'
import { ActionCommentsListDocument } from '../../../gen/graphql/documents'
import { CommentsList, CommentsListProps } from './CommentsList'

export type ActionCommentsListProps = Omit<
  CommentsListProps<unknown, unknown>,
  'query' | 'variables' | 'extractPage'
> & {
  actionId: string
}

export const ActionCommentsList = ({ actionId, ...rest }: ActionCommentsListProps) => (
  <CommentsList
    query={ActionCommentsListDocument}
    variables={{ actionId }}
    extractPage={(data) => data.action?.comments}
    {...rest}
  />
)

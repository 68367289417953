import { useFeatureFlags } from '@mm/company-ui/src'
import React, { useCallback, useMemo } from 'react'
import { CreateActionInput, UpdateActionsDocument } from '../../../gen/graphql/documents'
import { createActionOnUpdateCommand } from '../../actions/commands/createActionOnUpdate'
import { ActionsDashboardFlat, createDataSource } from '../../actions/components/ActionsDashboard'
import { useCommand } from '../../commands'

export type UpdateActionsProps = {
  id: string
  skipRegisteringCapabilities?: boolean
  renderEmptyPlaceholder?: () => React.ReactElement
}

export const UpdateActions = ({
  id,
  skipRegisteringCapabilities = false,
  renderEmptyPlaceholder,
}: UpdateActionsProps) => {
  const createActionOnUpdate = useCommand(createActionOnUpdateCommand)
  const dataSource = useUpdateActionsDataSource(id)

  const handleAddAction = useCallback(
    (actionPayload: CreateActionInput) =>
      createActionOnUpdate.execute({
        updateId: id,
        data: actionPayload,
      }),
    [createActionOnUpdate, id],
  )

  return (
    <div sx={{ height: '100%' }}>
      <ActionsDashboardFlat
        dataSource={dataSource}
        onCreateAction={handleAddAction}
        skipRegisteringCapabilities={skipRegisteringCapabilities}
        renderEmptyPlaceholder={renderEmptyPlaceholder}
      />
    </div>
  )
}

const useUpdateActionsDataSource = (updateId: string) => {
  const { updateActionContext } = useFeatureFlags()

  return useMemo(
    () =>
      createDataSource({
        query: UpdateActionsDocument,
        extractActions: (data) => data?.update?.actions,
        matchAction: (action) =>
          (updateActionContext && action.updates?.edges.some(({ node }) => node.id === updateId)) ||
          (action.parent?.__typename === 'Update' && action.parent.id === updateId),
        variables: {
          id: updateId,
        },
      }),
    [updateId, updateActionContext],
  )
}

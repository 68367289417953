import { LoadingIcon } from '@mm/company-ui-icons'
import classnames from 'classnames'
import React, { AriaRole, Suspense } from 'react'
import { Flex } from '../Flex'
import { PanelHeaderProps } from './PanelHeader'

export type PanelProps = {
  header?: React.ReactElement<PanelHeaderProps>
  children?: React.ReactNode
  footer?: React.ReactNode
  className?: string
  overflow?: boolean
  as?: keyof React.ReactHTML
  role?: AriaRole
}

// Suspense is still experimental in Next. The component will throw an error if rendered via SSR.
const ClientSideSuspense = ({ children }: { children: React.ReactNode }) =>
  typeof window !== 'undefined' ? (
    <Suspense
      fallback={
        <Flex align="center" justify="center" sx={{ height: '100%' }}>
          <LoadingIcon width={20} height={20} color="text-disabled" />
        </Flex>
      }
    >
      {children}
    </Suspense>
  ) : (
    <>{children}</>
  )

export const Panel = ({ header, role, children, footer, className, overflow = true, as }: PanelProps) => {
  return (
    <Flex
      as={as}
      column
      sx={{
        height: '100%',
      }}
      className={classnames(Panel.name, className)}
    >
      {header}
      <Flex column role={role} sx={{ flex: 1, overflowY: overflow ? 'auto' : 'hidden' }}>
        <ClientSideSuspense>{children}</ClientSideSuspense>
      </Flex>
      {footer}
    </Flex>
  )
}

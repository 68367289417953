import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { EditorContent } from '@tiptap/react'
import { css } from 'theme-ui'
import { variant } from '../../../helpers'
import { InlineEditorStyles } from './styles'

export type SingleLineEditableContentProps = {
  variant: 'unstyled' | 'default' | 'muted' | 'underline'
  invalid: boolean
}

export const SingleLineEditableContent = styled(EditorContent, {
  shouldForwardProp: (prop) => prop === 'editor' || (isPropValid(prop) && !['variant', 'invalid'].includes(prop)),
})<SingleLineEditableContentProps>(
  InlineEditorStyles,
  css({
    display: 'inline-block',
    transition: 'box-shadow 100ms linear',
    minWidth: '20px',

    '> .ProseMirror': {
      outline: 'none',
    },
  }),
  ({ variant, invalid, theme }) => {
    if (variant === 'unstyled') return {}

    const outlineColor = invalid ? theme.colors.system.red : theme.colors.transparentBlack[10]
    const blueOutlineColor = invalid ? theme.colors.system.red : theme.colors.lightBlue.default

    const variantCSS = {
      default: {
        boxShadow: `inset 0 0 0 ${invalid ? '1px' : '0'} ${outlineColor}`,
        hoveredBoxShadow: `inset 0 0 0 1px ${outlineColor}`,
        focusedBoxShadow: `inset 0 0 0 2px ${outlineColor}`,
      },
      muted: {
        boxShadow: `inset 0 0 0 ${invalid ? '1px' : '0'} ${outlineColor}, ${theme.shadows.defaultTransparent}`,
        hoveredBoxShadow: `inset 0 0 0 ${invalid ? '1px' : '0'} ${outlineColor}, ${theme.shadows.defaultTransparent}`,
        focusedBoxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.default}`,
      },
      underline: {
        hoveredBoxShadow: `inset 0 -1px 0 ${outlineColor}`,
        focusedBoxShadow: `inset 0 -2px 0 ${blueOutlineColor}`,
      },
    }[variant]

    return css({
      boxShadow: variantCSS.boxShadow,
      '&:hover': {
        boxShadow: variantCSS.hoveredBoxShadow,
      },
      '&:focus-within': {
        boxShadow: variantCSS.focusedBoxShadow,
      },
    })
  },
  variant({
    prop: 'variant',
    variants: {
      unstyled: {},
      default: {
        backgroundColor: 'transparentBlack.5',
        borderRadius: 'default',

        '> .ProseMirror': {
          padding: 2,
        },
      },
      muted: {
        backgroundColor: 'transparent',
        borderRadius: 'default',

        '&:hover, &:focus': {
          backgroundColor: 'transparentBlack.5',
        },

        '> .ProseMirror': {
          padding: 1,
        },
      },
      underline: {
        fontSize: 3,
        fontWeight: 500,
        lineHeight: 1.4,
        fontFamily: 'heading',
        backgroundColor: 'transparent',

        '> .ProseMirror': {
          py: 1,
          px: 0,
        },
      },
    },
  }),
  () =>
    css({
      '> .ProseMirror.empty::before': {
        content: 'attr(aria-placeholder)',
        float: 'left',
        color: 'text-light',
        pointerEvents: 'none',
        height: 0,
      },
    }),
)

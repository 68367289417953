import { UpdateActionDueAtDocument, GetActionDueAtDocument } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditActionDueAtCommandValue = {
  dueAt: number
  actionId: string
}

export const editActionDueAtCommand: Command<EditActionDueAtCommandValue> = {
  id: 'editActionDueAtCommand',
  name: 'Edit Due Date',
  canExecute: async ({ dueAt, actionId }, { apolloClient }) => {
    const currentAction = await apolloClient.query({ query: GetActionDueAtDocument, variables: { id: actionId } })
    return currentAction.data.action?.dueAt !== dueAt
  },
  execute: async ({ dueAt, actionId }, { apolloClient }) => {
    // Get current value from cache or refetch if needed
    const currentAction = await apolloClient.query({ query: GetActionDueAtDocument, variables: { id: actionId } })

    await apolloClient.mutate({
      mutation: UpdateActionDueAtDocument,
      variables: {
        id: actionId,
        data: {
          dueAt,
        },
      },
      optimisticResponse: {
        updateAction: {
          __typename: 'Action',
          id: actionId,
          dueAt,
        },
      },
    })

    if (currentAction.data.action?.dueAt) {
      return {
        args: {
          dueAt: currentAction.data.action.dueAt,
          actionId,
        },
        execute: async ({ dueAt, actionId }, { apolloClient }) => {
          await apolloClient.mutate({
            mutation: UpdateActionDueAtDocument,
            variables: {
              id: actionId,
              data: {
                dueAt,
              },
            },
            optimisticResponse: {
              updateAction: {
                __typename: 'Action',
                id: actionId,
                dueAt,
              },
            },
          })

          return
        },
      }
    }

    return
  },
}

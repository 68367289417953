import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import React from 'react'
import { css, ThemeUIStyleObject } from 'theme-ui'
import { variant } from '../../helpers'

export type ExternalLinkProps = {
  href?: string
  onClick?: (event: React.MouseEvent) => void
  underlined?: boolean
  variant?: 'default' | 'colored' | 'secondary'
  sx?: ThemeUIStyleObject
  children?: React.ReactNode
}

type BaseExternalLinkProps = Pick<ExternalLinkProps, 'underlined' | 'variant'>

export const BaseLink = styled('a', { shouldForwardProp: isPropValid })<BaseExternalLinkProps>(
  ({ underlined }) =>
    css({
      fontSize: 1,
      lineHeight: 1.5,
      fontFamily: 'body',
      cursor: 'pointer',
      color: 'text',
      textDecoration: underlined ? 'underline' : 'inherit',
      ':hover': { color: 'accent-background', textDecoration: 'underline' },
    }),
  variant({
    prop: 'variant',
    variants: {
      default: {},
      secondary: {
        color: 'text-light',
      },
      colored: {
        color: 'accent-background',
      },
    },
  }),
)

export const ExternalLink = ({ href, onClick, children, ...props }: ExternalLinkProps) => {
  return (
    <BaseLink
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(event) => {
        if (!href && onClick) onClick(event)
      }}
      {...props}
    >
      {children}
    </BaseLink>
  )
}

ExternalLink.defaultProps = {
  variant: 'default',
  underlined: false,
} as const

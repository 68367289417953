import { produce } from 'immer'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { ActionOrderBy, ActionsDashboardRowDataFragment, ActionStatus } from '../../../../gen/graphql/documents'
import { useQuery } from '../../../apollo'
import { useOnEventBusEvent } from '../../../eventbus'
import { ActionsUpdatedTopic } from '../../subscriptions/GlobalActionsSubscription'
import { ActionsDashboardDataSource, ActionsDashboardFetchOptions, DataFetchResult } from '../../types'

export type ActionsDashboardQueryFilters = {
  status?: ActionStatus | ActionStatus[] | null
  waitingFor?: string
  companyId?: string
  createdAfter?: number
  updatedAfter?: number
  assignee?: string
  assignees?: string[]
  waitingFors?: string[]
}

export type ActionsDashboardQueryParams = {
  count?: number | null
  after?: string | null
  orderBy: ActionOrderBy
}

const COMPARATORS: Record<
  ActionOrderBy,
  (lhs: ActionsDashboardRowDataFragment, rhs: ActionsDashboardRowDataFragment) => boolean
> = {
  CREATED_AT_ASC: (lhs, rhs) => lhs.createdAt <= rhs.createdAt,
  CREATED_AT_DESC: (lhs, rhs) => lhs.createdAt >= rhs.createdAt,
  DATE_COMPLETED_ASC: (lhs, rhs) => (lhs.dateCompleted ?? 0) <= (rhs.dateCompleted ?? 0),
  DATE_COMPLETED_DESC: (lhs, rhs) => (lhs.dateCompleted ?? 0) >= (rhs.dateCompleted ?? 0),
  DUE_AT_ASC: (lhs, rhs) => (lhs.dueAt ?? 0) <= (rhs.dueAt ?? 0),
  DUE_AT_DESC: (lhs, rhs) => (lhs.dueAt ?? 0) >= (rhs.dueAt ?? 0),
  UPDATED_AT_DESC: (lhs, rhs) => lhs.updatedAt >= rhs.updatedAt,
  UPDATED_AT_ASC: (lhs, rhs) => lhs.updatedAt <= rhs.updatedAt,
}

export const useFetchActionsDashboardData = <TData, TVariables>(
  { query, extractActions, matchAction, filterAction, variables }: ActionsDashboardDataSource<TData, TVariables>,
  { filters, pageSize, orderBy, skip, overrideFilters }: ActionsDashboardFetchOptions,
): DataFetchResult<ActionsDashboardRowDataFragment> => {
  const { data, loading, error, networkStatus, fetchMore, updateQuery } = useQuery(query, {
    variables: {
      ...filters,
      ...(_.omitBy(variables, _.isUndefined) as typeof variables), // omit undefined values
      ...(_.omitBy(overrideFilters, _.isUndefined) as typeof overrideFilters),
      ...(pageSize ? { count: pageSize } : {}),
      orderBy,
    },
    skip,
  })

  const onRowUpdated = useCallback(
    (action: ActionsDashboardRowDataFragment, matchesOtherFilters?: boolean) => {
      updateQuery((data, { variables }) =>
        produce(data, (draft) => {
          const actions = extractActions(draft)
          if (actions == null) return

          const edges = actions.edges
          const index = edges.findIndex((edge) => edge.node.id === action.id)
          const matchesFilters =
            (matchesOtherFilters ?? true) &&
            (variables?.status == null || [variables.status].flat().includes(action.status)) &&
            (variables?.assignee == null || variables.assignee === action.assignee.id) &&
            (variables?.assignees == null || variables.assignees.includes(action.assignee.id)) &&
            (variables?.waitingFor == null || variables.waitingFor === action.waitingFor.id) &&
            (variables?.waitingFors == null || variables.waitingFors.includes(action.waitingFor.id)) &&
            (variables?.createdAfter == null || variables.createdAfter < action.createdAt) &&
            (variables?.updatedAfter == null || variables.updatedAfter < action.updatedAt)

          if (matchesFilters) {
            const lte = COMPARATORS[orderBy]

            const prevEdge = edges[index - 1]
            const nextEdge = edges[index + 1]
            const indexIsCorrect =
              index !== -1 &&
              (prevEdge == null || lte(prevEdge.node, action)) &&
              (nextEdge == null || lte(action, nextEdge.node))
            if (indexIsCorrect) return

            if (index != -1) edges.splice(index, 1)
            const correctIndex = edges.findIndex((edge) => !lte(action, edge.node))
            if (correctIndex !== -1) {
              edges.splice(correctIndex, 0, {
                node: action,
              })
            } else {
              // TODO - the action may in fact be moved beyond the loaded prefix
              //        in this case we may want to remove it from the list instead
              edges.push({
                node: action,
              })
            }
          } else if (index !== -1) {
            edges.splice(index, 1)
          }
        }),
      )
    },
    [extractActions, updateQuery, orderBy],
  )

  useOnEventBusEvent(
    ActionsUpdatedTopic,
    useCallback(
      (event) => {
        if (event.type !== 'upserted') return
        const { action } = event

        onRowUpdated(action, matchAction(action))
      },
      [matchAction, onRowUpdated],
    ),
  )

  const actions = data && extractActions(data)
  const endCursor = actions?.pageInfo.endCursor
  return {
    data: useMemo(() => actions?.edges.map(({ node }) => node), [actions])?.filter(
      (action) => (filterAction ? filterAction(action) : true),
      [filterAction],
    ),
    loading,
    error,
    networkStatus,
    hasMore: actions?.pageInfo.hasNextPage,
    fetchMore: useCallback(async () => {
      const { data } = await fetchMore({
        variables: { after: endCursor },
      })
      return (data && extractActions(data)) ?? null
    }, [fetchMore, endCursor, extractActions]),
  }
}

export const createDataSource = <TData, TVariables>(
  dataSource: ActionsDashboardDataSource<TData, TVariables>,
): ActionsDashboardDataSource => dataSource

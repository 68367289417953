import { Suspender } from '@mm/company-ui'
import React from 'react'
import { GoalAssigneePickerDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers, UserSelectWithMeetingParticipants } from '../../components/UserSelectWithQuery'
import { useMeetingContext } from '../../meetings'
import { editGoalAssigneeCommand } from '../commands/editGoalAssigneeCommand'

export type GoalAssigneePickerProps = {
  goalId: string
  cached?: boolean
}

export const GoalAssigneePicker = ({ goalId, cached }: GoalAssigneePickerProps) => {
  const { data, loading, error } = useQuery(GoalAssigneePickerDocument, {
    fetchPolicy: cached ? 'cache-only' : undefined,
    variables: {
      goalId,
    },
  })
  const meeting = useMeetingContext()

  const editGoalAssignee = useCommand(editGoalAssigneeCommand)

  const goal = data?.goal
  if (goal == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Goal not found')
  }
  return meeting ? (
    <UserSelectWithMeetingParticipants
      meetingId={meeting.id}
      aria-label="Assignee" // TODO use aria-labelledby or wrap with label to ensure it always matches visual label
      value={goal.assignee?.id}
      onAdd={(assigneeId: string) => {
        void editGoalAssignee.execute({
          goalId,
          assigneeId,
        })
      }}
      withName
    />
  ) : (
    <UserSelectWithCompanyMembers
      aria-label="Assignee" // TODO use aria-labelledby or wrap with label to ensure it always matches visual label
      value={goal.assignee?.id}
      onAdd={(assigneeId: string) => {
        void editGoalAssignee.execute({
          goalId,
          assigneeId,
        })
      }}
      withName
    />
  )
}

import { useRegisterCapability } from './CapabilitiesContext'
import { Capability, CapabilityHandler } from './Capability'

export type RegisterCapabilityProps<C extends Capability<any[], any>> = {
  capability: C
  handler: CapabilityHandler<C>
  skip?: boolean
}

export const RegisterCapability = <C extends Capability<any[], any>>({
  capability,
  handler,
  skip,
}: RegisterCapabilityProps<C>) => {
  useRegisterCapability(capability, handler, skip)

  return null
}

import { Flex, HotkeyTooltip, Tag, formatDateDisplay, useFeatureFlags } from '@mm/company-ui'
import { LinkIcon } from '@mm/company-ui-icons/src'
import _ from 'lodash'
import React from 'react'
import { TopicCollapsibleMetadataFragmentFragmentDoc } from '../../../gen/graphql/documents'
import { useFragment_experimental } from '../../apollo'
import { TagLink } from '../../components'
import { TopicDatePicker } from './TopicDatePicker'
import { TopicPresenterTagSelect } from './TopicPresenterTagSelect'
import { TopicStatusCheckbox } from './TopicStatusCheckbox'

export function TopicCollapsibleMetadata({ topicId }: { topicId: string }) {
  const { data } = useFragment_experimental({
    fragment: TopicCollapsibleMetadataFragmentFragmentDoc,
    fragmentName: 'TopicCollapsibleMetadataFragment',
    from: {
      __typename: 'Topic',
      id: topicId,
    },
  })
  const { recurringTopics } = useFeatureFlags()

  if (!data) {
    // eslint-disable-next-line no-console
    console.log('Fragment returned no data, make sure a parent of TopicCollapsibleMetadata is querying the fragment')
    return null
  }

  // TODO Resolved checkbox as a component
  return (
    <Flex row gap={1} wrap>
      <HotkeyTooltip shortcutKey="D" description="Mark discussed">
        <div
          data-targetid="status"
          sx={{
            display: 'inline-flex',
            paddingX: 1,
            height: 3,
            gap: 0.5,
            borderRadius: 'full',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            fontSize: 0,
            backgroundColor: 'background-light-alpha',
            color: 'text-light',
          }}
        >
          <TopicStatusCheckbox id={topicId} withDropdown={false} />
          Resolved
        </div>
      </HotkeyTooltip>
      {data.createdAt && <Tag>Created {formatDateDisplay(data.createdAt)}</Tag>}

      {recurringTopics && (
        <div>
          <TopicDatePicker topicId={topicId} variant="tag" />
        </div>
      )}

      <div data-targetid="presenter">
        <TopicPresenterTagSelect topicId={topicId} />
      </div>

      {data.meetings?.edges.map(({ node }) => (
        <TagLink key={node.id} href={{ pathname: '/meetings/[id]', query: { id: node.id } }}>
          <LinkIcon />
          Meeting: {_.truncate(node.title, { length: 50 })}
        </TagLink>
      ))}
    </Flex>
  )
}

import {
  Avatar,
  ButtonGroup,
  Card,
  Flex,
  formatUserDisplayName,
  Heading,
  NotFoundBanner,
  Panel,
  Suspender,
  TabBar,
  Text,
} from '@mm/company-ui'
import {
  ActionsIcon,
  ConnectionsIcon,
  GoalsIcon,
  LightbulbIdeaFeedbackIcon,
  LoadingIcon,
  ModeratorPresenterIcon,
  UserProfileIcon,
} from '@mm/company-ui-icons/src'
import React, { Suspense, useState } from 'react'
import { SingleProfileDocument, SingleProfileSubscriptionDocument } from '../../../gen/graphql/documents'
import { UserActions } from '../../actions'
import { useQuery, useSubscription } from '../../apollo'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { useFeatureFlags } from '../../featureFlags'
import { UserGoalsDashboard } from '../../goals'
import { GoodThingsUserTable } from '../../goodThing/components/GoodThingsUserTable'
import { FeedbackUserFeed } from '../../prototypeFeedback'
import { ProfileBetaStatus } from './ProfileBetaStatus'
import { UserBio } from './UserBio'
import { UserUpdates } from './UserUpdates'

export type SimpleSingleUserProps = {
  id: string
}

type TabKey = 'about' | 'actions' | 'goals' | 'feedback' | 'updates' | 'goodThings'
type ActionsSubTabKey = 'user-actions' | 'waiting-for'
type FeedbackSubTabKey = 'received' | 'given'

export const SimpleSingleUser = ({ id }: SimpleSingleUserProps) => {
  const { prototypeFeedback, mattsFirstMeetingUpdate, teamAccountabilityStats } = useFeatureFlags()
  const { data, loading } = useQuery(SingleProfileDocument, {
    variables: {
      id,
    },
  })

  useSubscription(SingleProfileSubscriptionDocument, {
    variables: {
      id,
    },
  })

  const [activeTab, setActiveTab] = useState<TabKey>(teamAccountabilityStats ? 'updates' : 'actions')
  const [actionsSubActiveTab, setActionSubActiveTab] = useState<ActionsSubTabKey>('user-actions')
  const [feedbackSubActiveTab, setFeedbackSubActiveTab] = useState<FeedbackSubTabKey>('received')

  const user = data?.user
  if (user == null) {
    if (loading) {
      return <Suspender />
    }
    return <NotFoundBanner />
  }

  return (
    <Panel
      sx={{ flex: 1, backgroundColor: 'background-light' }}
      header={
        <Flex column sx={{ paddingTop: 1, paddingX: 2 }}>
          <Flex grow justify="space-between" gap={1.5} sx={{ paddingY: 1 }}>
            <Flex row align="center" gap={2}>
              <Avatar size="medium" name={formatUserDisplayName(user)} />

              <Heading level={4}>{formatUserDisplayName(user)}</Heading>
              <Text color="text-light">{user.email}</Text>
              <ProfileBetaStatus id={id} />
            </Flex>

            <ButtonGroup>
              <CloseDrawerButton resourceType="profile" />

              <ExpandCollapseToggle resourceType="profile" id={user.id} />
            </ButtonGroup>
          </Flex>

          <TabBar
            sx={{ marginLeft: 0, gap: 2 }}
            tabs={[
              ...(teamAccountabilityStats
                ? [
                    {
                      label: 'Updates',
                      key: 'updates' as const,
                      icon: <ModeratorPresenterIcon />,
                    },
                  ]
                : []),
              {
                label: 'Goals',
                key: 'goals',
                icon: <GoalsIcon />,
              },
              {
                label: 'Actions',
                key: 'actions',
                icon: <ActionsIcon />,
              },

              ...(prototypeFeedback
                ? [
                    {
                      label: 'Feedback',
                      key: 'feedback' as const,
                      icon: <LightbulbIdeaFeedbackIcon />,
                    },
                  ]
                : []),
              ...(mattsFirstMeetingUpdate
                ? [
                    {
                      label: 'About',
                      key: 'about' as const,
                      icon: <UserProfileIcon />,
                    },
                  ]
                : []),

              {
                label: 'Good Things',
                key: 'goodThings',
                icon: <ConnectionsIcon />,
              },
            ]}
            activeTabKey={activeTab}
            onTabChange={setActiveTab}
          />
        </Flex>
      }
    >
      <Flex column sx={{ marginTop: 1, paddingX: 2, paddingBottom: 2 }}>
        {activeTab === 'about' ? (
          <CardWithSuspense sx={{ padding: 0 }}>
            <MeetingTabHeader title="About" icon={<UserProfileIcon />} />
            <UserBio userId={id} />
          </CardWithSuspense>
        ) : null}

        {activeTab === 'actions' ? (
          <CardWithSuspense sx={{ padding: 0 }}>
            <MeetingTabHeader title="Actions" icon={<ActionsIcon />}>
              <TabBar
                tabs={[
                  {
                    label: 'User Actions',
                    key: 'user-actions',
                  },
                  {
                    label: 'Waiting For',
                    key: 'waiting-for',
                  },
                ]}
                activeTabKey={actionsSubActiveTab}
                onTabChange={setActionSubActiveTab}
              />
            </MeetingTabHeader>
            <UserActions
              tab={actionsSubActiveTab === 'user-actions' ? 'actions' : 'waiting-from-others'}
              selectedUserId={user.id}
            />
          </CardWithSuspense>
        ) : null}

        {activeTab === 'goals' && (
          <CardWithSuspense sx={{ padding: 0 }}>
            <MeetingTabHeader title="Goals" icon={<GoalsIcon />} />
            <UserGoalsDashboard userId={user.id} />
          </CardWithSuspense>
        )}
        {activeTab === 'updates' && (
          <CardWithSuspense sx={{ padding: 0 }}>
            <MeetingTabHeader title="Updates" icon={<ModeratorPresenterIcon />} />
            <UserUpdates userId={user.id} />
          </CardWithSuspense>
        )}
        {activeTab === 'feedback' ? (
          <CardWithSuspense sx={{ padding: 0 }}>
            <MeetingTabHeader title="Feedback" icon={<LightbulbIdeaFeedbackIcon />}>
              <TabBar
                tabs={[
                  {
                    label: 'Received',
                    key: 'received',
                  },
                  {
                    label: 'Given',
                    key: 'given',
                  },
                ]}
                activeTabKey={feedbackSubActiveTab}
                onTabChange={setFeedbackSubActiveTab}
              />
            </MeetingTabHeader>
            <FeedbackUserFeed userId={id} type={feedbackSubActiveTab} />
          </CardWithSuspense>
        ) : null}

        {activeTab === 'goodThings' && (
          <CardWithSuspense sx={{ padding: 0 }}>
            <GoodThingsUserTable userId={user.id} />
          </CardWithSuspense>
        )}
      </Flex>
    </Panel>
  )
}

function MeetingTabHeader({
  icon,
  title,
  children,
}: {
  icon: React.ReactElement
  title: string
  children?: React.ReactNode
}) {
  return (
    <Flex
      row
      align="center"
      gap={2}
      sx={{
        color: 'text-light',
        height: 5,
        borderBottom: '1px solid',
        borderBottomColor: 'border',
        paddingX: 2,
      }}
    >
      {icon}
      <Text bold sx={{ flex: 1, color: 'text' }}>
        {title}
      </Text>

      {children}
    </Flex>
  )
}

function CardWithSuspense({ children, className }: { children?: React.ReactNode; className?: string }) {
  return (
    <Card variant="flat" className={className}>
      <Suspense
        fallback={
          <Flex align="center" justify="center" sx={{ height: '100%', minHeight: '100vh' }}>
            <LoadingIcon width={20} height={20} color="text-disabled" />
          </Flex>
        }
      >
        {children}
      </Suspense>
    </Card>
  )
}

import {
  Button,
  ButtonGroup,
  DRAWER_SECTION_INNER_PADDING,
  DrawerLayout,
  DrawerSection,
  Flyout,
  Suspender,
  Text,
} from '@mm/company-ui'
import { ActionsIcon, CommentChatIcon, EllipsisIcon, FilePageTemplateIcon, InfoIcon } from '@mm/company-ui-icons'
import Router from 'next/router'
import { route } from 'nextjs-routes'
import React from 'react'
import { SingleTopicDocument } from '../../../gen/graphql/documents'
import { TopicActions } from '../../actions'
import { useQuery } from '../../apollo'
import { RegisterCapability } from '../../capabilities'
import { CloseDrawerButton, ExpandCollapseToggle } from '../../components'
import { CREATE_ISSUE } from '../../decisions/capabilities'
import { useFeatureFlags } from '../../featureFlags'
import { useTotalActions } from '../../hooks/useTotalActions'
import { useTotalComments } from '../../hooks/useTotalComments'
import { ADD_TOPIC } from '../capabilities'
import { TopicComments } from './TopicComments'
import { TopicDescription } from './TopicDescription'
import { TopicMetadata } from './TopicMetadata'
import { TopicMetadataSummary } from './TopicMetadataSummary'
import { TopicMoreMenu } from './TopicMoreMenu'
import { TopicTitleSection } from './TopicTitleSection'

export type SingleTopicPageProps = {
  topicId: string
  meetingId?: string
}

export const SingleTopicPage = ({ topicId, meetingId }: SingleTopicPageProps) => {
  const { metadataSummary, commentsActionsInDrawersCollapsedWhenEmpty } = useFeatureFlags()

  const { data, loading, error } = useQuery(SingleTopicDocument, {
    variables: {
      id: topicId,
    },
  })

  const topic = data?.topic

  const { commentsLabel, hasComments } = useTotalComments(topic)
  const { actionsLabel, hasActions } = useTotalActions(topic)

  if (topic == null) {
    if (loading) {
      return <Suspender />
    }
    throw error || new Error('Topic not found')
  }

  return (
    <DrawerLayout
      title={data?.topic?.title ?? ''}
      renderTitleButtonGroup={(props) => (
        <ButtonGroup {...props}>
          <CloseDrawerButton resourceType="topics" />
          <ExpandCollapseToggle resourceType="topics" id={topicId} />
          <Flyout
            position="bottom-start"
            content={
              <TopicMoreMenu
                id={topicId}
                meetingId={meetingId}
                onTopicDeleted={() => {
                  if (!meetingId) {
                    void Router.push(route({ pathname: '/meetings' }))
                    return
                  }
                  void Router.push(route({ pathname: '/meetings/[id]', query: { id: meetingId } }))
                }}
              />
            }
          >
            <Button variant="muted" startIcon={<EllipsisIcon />} textHidden size="small">
              Open menu
            </Button>
          </Flyout>
        </ButtonGroup>
      )}
      renderTitleSection={(props) => <TopicTitleSection topicId={topicId} {...props} />}
    >
      <RegisterCapability capability={ADD_TOPIC} handler={() => Promise.resolve()} skip={!meetingId} />
      <RegisterCapability capability={CREATE_ISSUE} handler={() => Promise.resolve()} skip={!meetingId} />
      <DrawerSection
        title="About"
        icon={<InfoIcon />}
        isClosedOnRender={metadataSummary}
        right={metadataSummary ? <TopicMetadataSummary topicId={topicId} /> : null}
        dangerouslyCustomizeChildrenPadding={{
          paddingY: DRAWER_SECTION_INNER_PADDING - 1, // To Account for inconsistent Y padding of Metadata
        }}
      >
        <TopicMetadata topicId={topicId} meetingId={meetingId} />
      </DrawerSection>

      <DrawerSection
        title="Description"
        icon={<FilePageTemplateIcon />}
        dangerouslyCustomizeChildrenPadding={{
          padding: 0, // Since content is a textarea, padding should be handled by that
        }}
      >
        <TopicDescription topicId={topicId} fillHeight={false} padding={DRAWER_SECTION_INNER_PADDING} />
      </DrawerSection>

      <DrawerSection
        id="actions"
        title="Actions"
        icon={<ActionsIcon />}
        isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasActions}
        right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{actionsLabel}</Text> : null}
        dangerouslyCustomizeChildrenPadding={{
          padding: 0, // Since action table has borders that are full-width, it handles its own padding
        }}
      >
        <TopicActions id={topicId} />
      </DrawerSection>

      <DrawerSection
        id="comments"
        title="Comments"
        icon={<CommentChatIcon />}
        isClosedOnRender={commentsActionsInDrawersCollapsedWhenEmpty && !hasComments}
        right={commentsActionsInDrawersCollapsedWhenEmpty ? <Text color="text-light">{commentsLabel}</Text> : null}
        dangerouslyCustomizeChildrenPadding={{
          padding: DRAWER_SECTION_INNER_PADDING - 1, // Since comments have extra padding for hover effects, offset here
        }}
      >
        <TopicComments topicId={topicId} fixedComposer={false} order="newest" fixedAndScroll={false} />
      </DrawerSection>
    </DrawerLayout>
  )
}

import { isGraphQLError } from '@mm/graphql-helpers/src'
import { produce } from 'immer'
import { remove } from 'lodash'
import {
  ArchiveDecisionCommandDocument,
  DecisionMoreMenuDataFragmentDoc,
  GenericTopicsDataFragmentDoc,
  TopicMeetingDataFragmentDoc,
} from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type ArchiveDecisionCommandValue = {
  decisionId: string
  meetingId: string
  onDecisionArchive?: () => void
}

export const archiveDecisionCommand: Command<ArchiveDecisionCommandValue> = {
  id: 'archiveDecisionCommand',
  name: 'Archive Decision From Meeting',
  canExecute: () => true,
  execute: async ({ decisionId, meetingId, onDecisionArchive }, { apolloClient }) => {
    const meetingData = apolloClient.readFragment({
      fragment: TopicMeetingDataFragmentDoc,
      id: apolloClient.cache.identify({ __typename: 'Meeting', id: meetingId }),
      fragmentName: 'topicMeetingData',
    })

    const sectionId = meetingData?.sections.find(
      (section) =>
        section.__typename === 'MeetingTopicSection' && section.genericTopics.some((topic) => topic.id === decisionId),
    )?.id

    const { data } = await apolloClient.mutate({
      mutation: ArchiveDecisionCommandDocument,
      variables: {
        id: decisionId,
      },
      update: (cache, { data }) => {
        if (data?.archiveDecision.__typename === 'Success') {
          cache.updateFragment(
            {
              id: cache.identify({ __typename: 'MeetingTopicSection', id: sectionId }),
              fragment: GenericTopicsDataFragmentDoc,
              fragmentName: 'genericTopicsData',
            },
            (meeting) =>
              produce(meeting, (meetingDraft) => {
                remove(meetingDraft?.genericTopics || [], (topic) => topic.id === decisionId)
              }),
          ),
            cache.updateFragment(
              {
                id: cache.identify({ __typename: 'Decision', id: decisionId }),
                fragment: DecisionMoreMenuDataFragmentDoc,
                fragmentName: 'decisionMoreMenuData',
              },
              (decision) =>
                produce(decision, (decisionDraft) => {
                  remove(decisionDraft?.meetings?.edges || [], (edge) => edge.node.id === meetingId)
                }),
            )
        }
      },
      optimisticResponse: {
        archiveDecision: {
          __typename: 'Success',
        },
      },
    })
    if (data?.archiveDecision.__typename === 'Success') {
      onDecisionArchive?.()
    } else if (data?.archiveDecision && isGraphQLError(data?.archiveDecision)) {
      alert(`Archive decision failed: ${data?.archiveDecision.message ?? 'unknown error'}`)
    } else {
      alert('Archive decision failed: unknown error')
    }
  },
}

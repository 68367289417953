import { Avatar, formatUserDisplayName, Tag } from '@mm/company-ui'
import React from 'react'
import { TopicPresenterTagSelectFragmentFragmentDoc } from '../../../gen/graphql/documents'
import { useFragment_experimental } from '../../apollo'
import { useCommand } from '../../commands'
import { UserSelectWithCompanyMembers } from '../../components/UserSelectWithQuery'
import { editTopicOwnerCommand } from '../commands/editTopicOwnerCommand'

export type TopicPresenterTagSelectProps = {
  topicId: string
}

export const TopicPresenterTagSelect = ({ topicId }: TopicPresenterTagSelectProps) => {
  const editTopicPresenter = useCommand(editTopicOwnerCommand)

  const { data } = useFragment_experimental({
    fragment: TopicPresenterTagSelectFragmentFragmentDoc,
    fragmentName: 'TopicPresenterTagSelectFragment',
    from: {
      __typename: 'Topic',
      id: topicId,
    },
  })

  if (!data) {
    // eslint-disable-next-line no-console
    console.log(`Fragment TopicPresenterTagSelect returned no data, make sure a parent is querying for it`)
    return null
  }

  const handlePickerSelectUser = (value: string) => {
    void editTopicPresenter.execute({ topicId, ownerId: value })
  }

  return (
    <UserSelectWithCompanyMembers aria-label="Presenter" value={data.owner?.id} onAdd={handlePickerSelectUser}>
      {({ value, multiple: _, withName: __, ...restProps }) => {
        const [user] = value

        if (user) {
          return (
            <Tag {...restProps}>
              Presenter
              <Avatar name={formatUserDisplayName(user.node)} />
            </Tag>
          )
        }
        return null
      }}
    </UserSelectWithCompanyMembers>
  )
}

import { isGraphQLError } from '@mm/graphql-helpers/src'
import { EditFeedbackStatusCommandDocument, FeedbackStatus } from '../../../gen/graphql/documents'
import { Command } from '../../commands'

type EditFeedbackStatusCommandValue = {
  feedbackId: string
  status: FeedbackStatus
}

export const editFeedbackStatusCommand: Command<EditFeedbackStatusCommandValue> = {
  id: 'editFeedbackStatusCommand',
  name: 'Edit Feedback Status',
  canExecute: () => true,
  execute: async ({ feedbackId, status }, { apolloClient }) => {
    const { data } = await apolloClient.mutate({
      mutation: EditFeedbackStatusCommandDocument,
      variables: {
        feedbackId,
        status,
      },
      optimisticResponse: {
        updatePrototypeFeedback: {
          __typename: 'PrototypeFeedback',
          id: feedbackId,
          status,
          statusUpdatedAt: new Date().getTime(),
        },
      },
    })

    if (data?.updatePrototypeFeedback && isGraphQLError(data?.updatePrototypeFeedback)) {
      alert(`Feedback update failed: ${data?.updatePrototypeFeedback.message ?? 'unknown error'}`)
    } else if (data?.updatePrototypeFeedback.__typename !== 'PrototypeFeedback') {
      alert('Feedback update failed: unknown error')
    }
  },
}

import { PanelMetadataItem, Suspender, PanelMetadataContainer, Tag } from '@mm/company-ui'
import { EyeViewOpen, LinkIcon, LockedIcon, UserProfileIcon } from '@mm/company-ui-icons'
import React from 'react'
import { SingleGoodThingDocument } from '../../../gen/graphql/documents'
import { useQuery } from '../../apollo'
import { PanelMetadataItemChips } from '../../components'
import { GoodThingAssigneePicker } from './GoodThingAssigneePicker'

export type GoodThingMetadataProps = {
  goodThingId: string
  cached?: boolean
}

export const GoodThingMetadata = ({ goodThingId, cached }: GoodThingMetadataProps) => {
  const { data, loading, error } = useQuery(SingleGoodThingDocument, {
    variables: {
      goodThingId,
    },
    fetchPolicy: cached ? 'cache-only' : undefined,
  })

  const goodThing = data?.goodThing

  if (goodThing == null) {
    if (loading) {
      return <Suspender />
    }
    throw error ?? new Error('Good thing not found')
  }

  return (
    <PanelMetadataContainer>
      <PanelMetadataItem title="Author" icon={<UserProfileIcon />}>
        <GoodThingAssigneePicker goodThingId={goodThingId} />
      </PanelMetadataItem>
      <PanelMetadataItem title="Privacy" sx={{ alignItems: 'center' }} icon={<EyeViewOpen />}>
        {goodThing.parent?.privacyLevel == 'PRIVATE' ? (
          <Tag variant="default">
            <LockedIcon />
            Private
          </Tag>
        ) : null}
        {goodThing.parent?.privacyLevel == 'PUBLIC' ? (
          <Tag variant="default">
            <EyeViewOpen />
            Public
          </Tag>
        ) : null}
      </PanelMetadataItem>
      {goodThing.parent?.__typename === 'Meeting' && (
        <PanelMetadataItemChips
          title="Meeting"
          icon={<LinkIcon />}
          chips={[
            {
              id: goodThing.parent.id,
              label: goodThing.parent.title,
              href: { pathname: '/meetings/[id]', query: { id: goodThing.parent.id } },
            },
          ]}
        />
      )}
    </PanelMetadataContainer>
  )
}

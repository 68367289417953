import React from 'react'
import { IconBase, OptionalIconBaseProps } from '../components/IconBase'

export const BoldIcon = (props: OptionalIconBaseProps) => (
  <IconBase name="bold" viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.7001 1.99961C2.7001 1.50255 3.10305 1.09961 3.6001 1.09961H5.02296H11.4222C13.9018 1.09961 15.8776 3.1498 15.8776 5.63597C15.8776 6.93276 15.3401 8.11093 14.4739 8.94141C16.1566 9.81407 17.2998 11.5975 17.2998 13.636C17.2998 16.5238 15.0057 18.8996 12.1333 18.8996H3.6001C3.10305 18.8996 2.7001 18.4967 2.7001 17.9996C2.7001 17.5026 3.10305 17.0996 3.6001 17.0996H4.1223L4.1223 9.27234C4.1223 9.26069 4.12252 9.2491 4.12296 9.23756V2.89961H3.6001C3.10305 2.89961 2.7001 2.49667 2.7001 1.99961ZM5.92296 8.37234V2.89961H11.4222C12.8698 2.89961 14.0776 4.10552 14.0776 5.63597C14.0776 7.16642 12.8698 8.37234 11.4222 8.37234H5.92296ZM15.4998 13.636C15.4998 15.5681 13.9736 17.0996 12.1333 17.0996H5.9223V10.1723H12.1333C13.9736 10.1723 15.4998 11.7039 15.4998 13.636Z"
    />
  </IconBase>
)
